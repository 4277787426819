import React from "react";

import { Select } from "../../../../components/Form";
import { IUseSelectOptions } from "../../components/hooks/types";
import { IContractSellers } from "../../types";
import { useDebounce } from "../../../../hooks/async";
import { PageType } from "../types";

import styles from "./ContractSellers.module.css";

interface Props {
  data: IContractSellers;
  supervisors: number[];
  sellerOptions?: IUseSelectOptions["sellerOptions"];
  page: PageType;
  permissions?: string[];
}

const defaultSellerOptions: IUseSelectOptions["sellerOptions"] = {
  options: [],
  search: async () => {},
  searchDebounced: async () => {},
  loading: false,
};

const isDisabled = (page: PageType, permissions: string[]) => {
  switch (page) {
    case PageType.GERENTE:
      return false;
    case PageType.COMERCIAL:
      return !permissions.includes("CONTRATO_SOLICITACAO_ALTERA_VENDEDOR");
    default:
      return true;
  }
};

export function ContractSellers({
  data,
  supervisors,
  page,
  sellerOptions = defaultSellerOptions,
  permissions = [],
}: Props) {
  const { sellers } = data;

  const adjustedSellerOptions = React.useMemo(() => {
    if (sellerOptions) {
      if (!sellerOptions.loading) {
        return sellerOptions.options.filter((i) =>
          sellers.value && sellers.value.length
            ? !sellers.value.some((j) => j.value.id === i.value.id)
            : true
        );
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [sellerOptions, sellers.value]);

  const searchSellerOptionsDebounced = useDebounce(sellerOptions!.search);

  const handleRemoveSeller = (sellerId: number) => {
    sellers.setValue((old) => old.filter((i) => i.value.id !== sellerId));
  };

  return (
    <div>
      <div className={styles.fieldsContainer}>
        {!isDisabled(page, permissions) && (
          <>
            <div>
              <label htmlFor="sellers" className="label">
                Vendedores
              </label>
              <Select
                id="sellers"
                placeholder="Selecione um vendedor para adicionar"
                options={adjustedSellerOptions}
                value={sellers.value}
                error={sellers.error}
                onChange={sellers.onChange}
                onInputChange={(value, action) => {
                  if (action.action === "input-change") {
                    searchSellerOptionsDebounced(value, supervisors);
                  }
                }}
                isMulti
                isLoading={sellerOptions?.loading}
              />
            </div>
          </>
        )}
      </div>
      <div
        className={styles.listContainer}
        style={{ marginTop: isDisabled(page, permissions) ? "0px" : "" }}
      >
        {sellers.value && sellers.value.length ? (
          <ul
            className={styles.sellerListContainer}
            style={{ height: page !== PageType.GERENTE ? "fit-content" : "" }}
          >
            {sellers.value.map((seller, index) => (
              <li
                key={index}
                style={{
                  backgroundColor: isDisabled(page, permissions)
                    ? "var(--gray-10)"
                    : "",
                }}
              >
                <span>{seller.label}</span>
                {!isDisabled(page, permissions) && (
                  <span>
                    <button
                      className={styles.removeSellerButton}
                      onClick={() => handleRemoveSeller(seller.value.id)}
                    >
                      X
                    </button>
                  </span>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p className={`lineCardMessage`}>
            Nenhum vendedor adicionado ao contrato
          </p>
        )}
      </div>
    </div>
  );
}
