import React from "react";
import { Button, Input, LinkButton } from "../../../../components/Form";

import styles from "./NewPaymentMethod.module.css";
import { useForm } from "../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { useNavigate } from "react-router-dom";
import { isValid } from "../../../../helpers/validations";
import { Circle } from "../../../../components/Loading";

export function NewPaymentMethod({ searchTotalPaymentMethodRecordsAndPaymentMethods }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const savingPaymentMethod = useLoading();

  const description = useForm({ type: "text", required: true });

  const onSaveNewPaymentMethod = React.useCallback(async () => {
    if (isValid(description)) {
      try {
        savingPaymentMethod.setLoading(true);
        const json = await customFetch("/rdv/insertAndUpdatePaymentMethod", {
          body: {
            descricao: description.value,
            status: "A",
          },
        });
        if (json.status === 200) {
          searchTotalPaymentMethodRecordsAndPaymentMethods();
          await Modal.success(json.message);
          navigate("/rdv/formas-de-pagamento");
        } else {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        savingPaymentMethod.setLoading(false);
      }
    }
  }, [
    Modal,
    customFetch,
    description,
    navigate,
    savingPaymentMethod,
    searchTotalPaymentMethodRecordsAndPaymentMethods,
  ]);

  return (
    <div className="container">
      {!savingPaymentMethod.isLoading ? (
        <>
          <div className={`${styles.navContainer}`}>
            <LinkButton to="/rdv/formas-de-pagamento" buttonStyle="backButton" />
          </div>
          <div className={`${styles.fieldsContainer}`}>
            <div>
              <label className="label" htmlFor="description">
                Descrição
              </label>
              <Input
                id="description"
                placeholder="Digite a descrição da forma de pagamento"
                value={description.value}
                error={description.error}
                onChange={description.onChange}
                onBlur={description.onBlur}
              />
            </div>
          </div>
          <div className={`${styles.saveButtonContainer}`}>
            <Button onClick={onSaveNewPaymentMethod}>Salvar</Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Salvando nova forma de pagamento</span>
        </div>
      )}
    </div>
  );
}
