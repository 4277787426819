import React from "react";

import { Button, Input, LinkButton, Select } from "../../../../components/Form";

import { useForm, useSelect } from "../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useChanges, useModal } from "../../../../hooks/contexts";

import { isValid } from "../../../../helpers/validations";

import { Circle } from "../../../../components/Loading";

import styles from "./NewGroup.module.css";

export function NewGroup({ searchTotalGroupRecordsAndGroups }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const { setIsChangesDetected } = useChanges();
  const registeringGroup = useLoading();

  const group = useForm({ type: "", required: true });
  const unit = useSelect({ type: "single", required: true, emptyMessage: "Selecione uma unidade" });
  const imageURL = useForm({ type: "simple_url", required: false });

  const optionsUnit = [
    {
      value: {
        unit: "LITRO",
        abbreviation: "LT",
      },
      label: "LITRO | LT",
    },
    {
      value: {
        unit: "QUILO",
        abbreviation: "KG",
      },
      label: "QUILO | KG",
    },
    {
      value: {
        unit: "TONELADA",
        abbreviation: "TN",
      },
      label: "TONELADA | TN",
    },
  ];

  const clearForm = React.useCallback(() => {
    group.setValue("");
    group.setError(null);
    unit.setValue("");
    unit.setError(null);
    imageURL.setValue("");
    imageURL.setError(null);
    setIsChangesDetected(false);
  }, [group, imageURL, setIsChangesDetected, unit]);

  const registerGroup = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        registeringGroup.setLoading(true);
        const json = await customFetch("/products/registerAndUpdateGroup", {
          body: {
            descricao: group.value,
            tipo: unit.value.value.unit,
            abreviacao: unit.value.value.abbreviation,
            url: imageURL.value,
            ativo: true,
          },
        });
        resolve(json);
      } catch (error) {
        reject(error);
      } finally {
        registeringGroup.setLoading(false);
      }
    });
  }, [customFetch, group, imageURL, registeringGroup, unit]);

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (isValid(group, unit, imageURL)) {
        try {
          const json = await registerGroup();
          if (json.status === 200) {
            await Modal.success(json.message);
            clearForm();
            searchTotalGroupRecordsAndGroups();
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        }
      }
    },
    [Modal, clearForm, group, imageURL, registerGroup, searchTotalGroupRecordsAndGroups, unit]
  );

  return (
    <div className={`container ${styles.container}`}>
      {!registeringGroup.isLoading ? (
        <>
          <div>
            <LinkButton to="/produtos/grupos" buttonStyle="backButton" />
          </div>
          <div>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className={styles.fieldsContainer}>
                <div>
                  <label htmlFor="group" className="label">
                    Descrição
                  </label>
                  <Input
                    id="group"
                    placeholder="Digite o nome do grupo"
                    value={group.value}
                    error={group.error}
                    onChange={(e) => {
                      group.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={group.onBlur}
                  />
                </div>
                <div>
                  <label htmlFor="unit" className="label">
                    Unidade de Medida
                  </label>
                  <Select
                    id="unit"
                    placeholder="Selecione a unidade de medida"
                    options={optionsUnit}
                    value={unit.value}
                    error={unit.error}
                    onChange={(value) => {
                      unit.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={unit.onBlur}
                    isSearchable={false}
                  />
                </div>
                <div className={styles.imageURLContainer}>
                  <label htmlFor="imgURL" className="label">
                    URL da Imagem
                  </label>
                  <Input
                    id="imgURL"
                    type="url"
                    placeholder="Digite a URL da imagem desejada"
                    value={imageURL.value}
                    error={imageURL.error}
                    onChange={(e) => {
                      imageURL.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={imageURL.onBlur}
                  />
                </div>
              </div>
              <div className={styles.submitButtonContainer}>
                <Button>Cadastrar Grupo</Button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Cadastrando novo grupo</span>
        </div>
      )}
    </div>
  );
}
