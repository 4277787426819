import React from "react";

import { Routes, Route } from "react-router-dom";

import { usePagination } from "../../../hooks/pagination";
import { useCustomFetch, useLoading } from "../../../hooks/async";
import { useForm } from "../../../hooks/form";
import { useModal } from "../../../hooks/contexts";

import { GroupList } from "./GroupList/GroupList";
import { NewGroup } from "./NewGroup/NewGroup";
import { UpdateGroup } from "./UpdateGroup/UpdateGroup";

export function ProductGroups() {
  const pagination = usePagination();
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const searchedGroup = useForm({ type: "", required: false });

  const searchingGroups = useLoading();

  const [groupList, setGroupList] = React.useState([]);
  const [selectedGroup, setSelectedGroup] = React.useState(null);

  const clearGroupFilter = React.useCallback(() => {
    searchedGroup.setValue("");
  }, [searchedGroup]);

  const searchTotalGroupRecords = React.useCallback(
    (group = "") => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/products/searchTotalGroupRecords", {
            body: {
              tamanho: pagination.maxItems,
              descricao: group,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const searchGroups = React.useCallback(
    async (group = "", page) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/products/searchGroupsPaginated", {
            body: {
              pagina: page ? Number(page) - 1 : 0,
              tamanho: pagination.maxItems,
              descricao: group,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const researchGroups = React.useCallback(
    async (group, page) => {
      try {
        searchingGroups.setLoading(true);
        const json = await searchGroups(group, page);
        if (json.status === 200) {
          setGroupList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error.message);
      } finally {
        searchingGroups.setLoading(false);
      }
    },
    [Modal, searchGroups, searchingGroups]
  );

  const searchTotalGroupRecordsAndGroups = React.useCallback(
    async (group = "") => {
      if (group === "") {
        clearGroupFilter();
      }
      try {
        searchingGroups.setLoading(true);
        const jsonGroups = await searchGroups(group);
        if (jsonGroups.status === 200) {
          const jsonRecords = await searchTotalGroupRecords(group);
          if (jsonRecords.status === 200) {
            setGroupList(jsonGroups.object);
            pagination.setTotalRecords(jsonRecords.object.total);
          } else if (jsonRecords.status === 500) {
            Modal.error(jsonRecords.message, jsonRecords.object);
          } else {
            setGroupList([]);
          }
        } else if (jsonGroups.status === 500) {
          Modal.error(jsonGroups.message, jsonGroups.object);
        } else {
          setGroupList([]);
          pagination.reset();
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        pagination.setCurrentPage(1);
        searchingGroups.setLoading(false);
      }
    },
    [Modal, clearGroupFilter, pagination, searchGroups, searchTotalGroupRecords, searchingGroups]
  );

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <GroupList
              pagination={pagination}
              searchTotalGroupRecords={searchTotalGroupRecords}
              searchGroups={searchGroups}
              searchTotalGroupRecordsAndGroups={searchTotalGroupRecordsAndGroups}
              searchedGroup={searchedGroup}
              groupList={{
                value: groupList,
                setValue: setGroupList,
              }}
              setSelectedGroup={setSelectedGroup}
              searchingGroups={searchingGroups}
            />
          }
        />
        <Route path="novo" element={<NewGroup searchTotalGroupRecordsAndGroups={searchTotalGroupRecordsAndGroups} />} />
        <Route
          path="atualizar"
          element={
            <UpdateGroup
              researchGroups={(group) => researchGroups(group, pagination.currentPage)}
              selectedGroup={selectedGroup}
              filteredGroup={searchedGroup.value}
            />
          }
        />
      </Routes>
    </div>
  );
}
