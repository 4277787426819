import { useState } from "react";
import { Grupo, useGrupos } from "./useGrupos";

export function useGruposPermissoes() {
  const [selectedGrupo, setSelectedGrupo] = useState<Grupo | null>(null);

  const dadosGrupos = useGrupos();

  return {
    selectedGrupo,
    setSelectedGrupo,
    dadosGrupos,
  };
}

export type UseGruposPermissoes = ReturnType<typeof useGruposPermissoes>;
