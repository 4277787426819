import React from "react";
import { CaretDown } from "phosphor-react";
import { Button, Input, LinkButton } from "../../../../../../components/Form";
import { Circle } from "../../../../../../components/Loading";
import { Paginate } from "../../../../../../components/Paginate/Paginate";
import { useDebounce } from "../../../../../../hooks/async";
import { useAuth, useModal } from "../../../../../../hooks/contexts";
import { ICommissionTable } from "../../CommissionTables";
import { IItem, ISearchItemsResponse } from "../CommissionTableItems";
import { useNavigate } from "react-router-dom";

import styles from "./ItemsList.module.css";

interface IItemsListProps {
  searchedItems: IUseForm;
  searchingItems: IUseLoading;
  itemList: {
    value: IItem[];
    setValue: React.Dispatch<React.SetStateAction<IItem[]>>;
  };
  searchTotalItemsRecordsAndItems: (tableId: number, description?: string) => Promise<void>;
  searchItemsPromise: (tableId: number, description?: string, page?: number) => Promise<ISearchItemsResponse>;
  selectedTable: ICommissionTable | null;
  pagination: IUsePagination;
  setSelectedItem: React.Dispatch<React.SetStateAction<IItem | null>>;
}

export function ItemsList({
  searchedItems,
  itemList,
  searchingItems,
  searchItemsPromise,
  searchTotalItemsRecordsAndItems,
  selectedTable,
  pagination,
  setSelectedItem,
}: IItemsListProps) {
  const Auth = useAuth();
  const Modal = useModal();
  const navigate = useNavigate();

  const showCloneSellerButton = React.useState(false);

  const { current: searchTotalItemsRecordsAndItemsDebounced } = React.useRef(
    useDebounce(searchTotalItemsRecordsAndItems)
  );

  const handleClickItemOption = (option: "edit" | "edit-peoples" | "copy", item: IItem) => {
    setSelectedItem(item);
    if (option === "edit") navigate("atualizar");
    else if (option === "edit-peoples") navigate("pessoas");
    else if (option === "copy") navigate("copiar");
  };

  const handlePageChange = async (tableId: number, description: string = "", page: number = 0) => {
    try {
      searchingItems.setLoading(true);
      const json = await searchItemsPromise(tableId, description, page);
      if (json.status === 200) {
        itemList.setValue(json.object);
        window.scrollTo({ top: 0, behavior: "auto" });
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingItems.setLoading(false);
    }
  };

  const verifyPermission = React.useCallback(async () => {
    try {
      const permissions = await Auth.searchUserPermissions();
      if (Auth.havePermission(permissions, "CLONAR_COMISSAO_NOVOS_VENDEDORES")) {
        showCloneSellerButton[1](true);
      } else {
        showCloneSellerButton[1](false);
      }
    } catch (error) {
      showCloneSellerButton[1](false);
    }
  }, [Auth, showCloneSellerButton]);

  React.useEffect(() => {
    setSelectedItem(null);
    if (!itemList.value.length) {
      searchTotalItemsRecordsAndItems(selectedTable!.idTabela);
    }
    verifyPermission();
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.navContainer}>
        <LinkButton
          to="/comissoes/adubo/tabelas-de-comissoes"
          children={undefined}
          buttonStyle="backButton"
          variant={undefined}
          className={undefined}
          classNameContainer={undefined}
          disabled={undefined}
        />
        <h2>{selectedTable?.descricaoTabela}</h2>
        <div>{showCloneSellerButton[0] && <LinkButton to="copiar-vendedor">Copiar Vendedor</LinkButton>}</div>
      </div>
      <div className={styles.filtersContainer}>
        <label htmlFor="" className="label">
          Formulado
        </label>
        <Input
          placeholder="Digite o nome do formulado para filtrar"
          value={searchedItems.value}
          onChange={(e) => {
            searchedItems.onChange(e);
            searchTotalItemsRecordsAndItemsDebounced(selectedTable!.idTabela, e.target.value);
          }}
        />
      </div>
      <div className={styles.contentContainer}>
        {itemList.value.length && !searchingItems.isLoading ? (
          <ul>
            {itemList.value.map((item, itemIndex) => (
              <li key={itemIndex} className={styles.itemContainer}>
                <div>
                  <p className={styles.itemTitle}>
                    {item.idFormulado} | {item.descricaoFormulado}
                  </p>
                  <span className={styles.itemSeparator}></span>
                  <div className={styles.commissionsContainer}>
                    {item.itensComissaoTabela.map((commission, commissionIndex) => (
                      <div className={styles.commissionBlock} key={commissionIndex}>
                        <p>
                          {item.tipoComissao}. {commission.perMaximo}
                        </p>
                        <div className={styles.ball}>
                          <div style={{ backgroundColor: commission.rgb }}></div>
                          <span style={{ backgroundColor: commission.rgb }}></span>
                          <p>{commission.observacao}</p>
                        </div>
                        <p>Com. {commission.comissaoValor}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.itemOptionsContainer}>
                  <Button
                    onClick={() => {
                      handleClickItemOption("edit", item);
                    }}>
                    Editar
                  </Button>
                  <Button
                    onClick={() => {
                      handleClickItemOption("edit-peoples", item);
                    }}>
                    Pessoas
                  </Button>
                  <Button
                    onClick={() => {
                      handleClickItemOption("copy", item);
                    }}>
                    Copiar
                  </Button>
                  <span>
                    <CaretDown weight="light" />
                  </span>
                </div>
              </li>
            ))}
          </ul>
        ) : searchingItems.isLoading ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhum item encontrado</p>
        )}
      </div>
      <div>
        <Paginate
          classNameContainer={styles.paginationContainer}
          maxItems={pagination.maxItems}
          totalRecords={pagination.totalRecords}
          currentPage={pagination.currentPage}
          setCurrentPage={pagination.setCurrentPage}
          onPageChange={(page: number) => handlePageChange(selectedTable!.idTabela, searchedItems.value, page)}
        />
      </div>
    </div>
  );
}
