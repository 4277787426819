import React from "react";

import { useNavigate } from "react-router-dom";
import { useDebounce } from "../../../../../../hooks/async";

import { Button, Input, LinkButton, Select } from "../../../../../../components/Form";
import { Table, TBody, Td, Th, THead, Tr } from "../../../../../../components/Data/Table";

import { Circle } from "../../../../../../components/Loading";

import { Pencil } from "phosphor-react";

import styles from "./ItemsList.module.css";

export function ItemsList({
  enterprise,
  enterpriseOptions,
  searchEnterpriseOptionsAndItems,
  searchingEnterpriseOptions,
  filteredItems,
  searchItems,
  searchingItems,
  items,
  setSelectedItem,
}) {
  const navigate = useNavigate();

  const { current: searchItemsDebounced } = React.useRef(
    useDebounce((description, enterpriseId) => {
      searchItems(description, enterpriseId);
    })
  );

  const handleClickEditButton = React.useCallback(
    (item) => {
      setSelectedItem(item);
      navigate("atualizar");
    },
    [navigate, setSelectedItem]
  );

  React.useEffect(() => {
    setSelectedItem(null);
    if (!items.length) searchEnterpriseOptionsAndItems();
  }, []); // eslint-disable-line

  return (
    <div>
      <>
        <div className={styles.navigationContainer}>
          <LinkButton to="novo">Adicionar Item</LinkButton>
        </div>
        <div className={styles.itemsContainer}>
          <div>
            <label htmlFor="enterprise" className="label">
              Empresa
            </label>
            <Select
              id="enterprise"
              placeholder={searchingEnterpriseOptions.isLoading ? "Buscando empresas" : "Nenhuma empresa disponível"}
              options={enterpriseOptions}
              value={enterprise.value}
              onChange={(value) => {
                enterprise.setValue(value);
                searchItems(filteredItems.value, value.value.idEmpresa);
              }}
              isLoading={searchingEnterpriseOptions.isLoading}
              isDisabled={!enterpriseOptions.length}
              isClearable={false}
              isSearchable={false}
            />
          </div>
          <div>
            <label htmlFor="filteredItems" className="label">
              Filtrar Itens
            </label>
            <Input
              id="filteredItems"
              placeholder="Digite um item para filtrar"
              value={filteredItems.value}
              onChange={({ target }) => {
                filteredItems.setValue(target.value);
                searchItemsDebounced(target.value, enterprise.value?.value?.idEmpresa);
              }}
            />
          </div>
        </div>
        <div className={styles.tableContainer}>
          {items.length && !searchingItems.isLoading ? (
            <Table title="Itens na Tabela" className={styles.tableItems}>
              <THead>
                <Tr>
                  <Th width="3.75rem">ID</Th>
                  <Th>Nome</Th>
                  <Th>Grupo</Th>
                  <Th width="6.25rem">Preço</Th>
                  <Th>Situação</Th>
                  <Th></Th>
                </Tr>
              </THead>
              <TBody>
                {items.map((item) => {
                  return (
                    <Tr key={item.itens.idItem}>
                      <Td heading="ID">{item.itens.idItem}</Td>
                      <Td heading="Nome">{item.formulado.descricaoFormulado}</Td>
                      <Td heading="Grupo">{item.grupo.descricaoGrupo}</Td>
                      <Td heading="Preço" style={{ textAlign: "right" }}>
                        {item.itens.valorProduto.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </Td>
                      <Td heading="Situação">{item.itens.situacaoProduto === "A" ? "Ativo" : "Inativo"}</Td>
                      <Td heading="Editar" data-option width="5rem">
                        <Button
                          className={styles.deleteItemButton}
                          onClick={() => {
                            const selectedItem = {
                              ...item,
                              status: item.itens.situacaoProduto === "A",
                            };
                            handleClickEditButton(selectedItem);
                          }}
                          data-option-button
                        >
                          <Pencil weight="fill" />
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </TBody>
            </Table>
          ) : searchingItems.isLoading ? (
            <div className={`loadingContainer`}>
              <Circle size={100} />
            </div>
          ) : (
            <p className={`lineCardMessage`}>Nenhum item encontrado</p>
          )}
        </div>
      </>
    </div>
  );
}
