import React from "react";

import { useModal } from "../../../../../hooks/contexts";
import { useCustomFetch, useLoading } from "../../../../../hooks/async";

import { Table, TBody, Td, Th, THead, Tr } from "../../../../../components/Data/Table";
import { Paginate } from "../../../../../components/Paginate/Paginate";
import { formatDate } from "../../../../../helpers/format";
import { Button, LinkButton } from "../../../../../components/Form";

import { Circle } from "../../../../../components/Loading";

import { Trash } from "phosphor-react";

import styles from "./CostList.module.css";

export function CostList({
  selectedPacking,
  pagination,
  searchCosts,
  searchTotalCostRecordsAndCosts,
  costList,
  searchingCosts,
}) {
  const Modal = useModal();
  const customFecth = useCustomFetch();
  const deletingCost = useLoading();

  const removeCost = React.useCallback(
    async (marginId) => {
      const confirm = await Modal.confirm("Deseja mesmo remover este custo?");
      if (confirm) {
        try {
          deletingCost.setLoading(true);
          const json = await customFecth("/products/deletePackingCost", {
            method: "DELETE",
            body: {
              idEmbalagem: Number(selectedPacking.idEnvase),
              idMargem: marginId,
            },
          });
          if (json.status === 200) {
            await Modal.success(json.message);
            searchTotalCostRecordsAndCosts();
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          deletingCost.setLoading(false);
        }
      }
    },
    [Modal, customFecth, deletingCost, searchTotalCostRecordsAndCosts, selectedPacking]
  );

  const handlePageChange = React.useCallback(
    async (cost, page) => {
      try {
        searchingCosts.setLoading(true);
        const json = await searchCosts(cost, page);
        if (json.status === 200) {
          costList.setValue(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingCosts.setLoading(false);
      }
    },
    [Modal, costList, searchCosts, searchingCosts]
  );

  React.useEffect(() => {
    if (!costList.value.length) searchTotalCostRecordsAndCosts();
  }, []); //eslint-disable-line

  return (
    <div className={`container ${styles.container}`}>
      <div className={styles.navigationContainer}>
        <LinkButton to="/produtos/embalagens" buttonStyle="backButton" />
        <h2 className={styles.packingName}>{selectedPacking.descricaoEnvase}</h2>
        <LinkButton to="novo" className={styles.newCostButtonContainer}>
          Novo Custo
        </LinkButton>
      </div>
      <div className={`${styles.costListContainer}`}>
        {costList.value.length && !searchingCosts.isLoading && !deletingCost.isLoading ? (
          <Table title={`Custos Da Embalagem`} className={styles.tableCost}>
            <THead>
              <Tr>
                <Th>ID</Th>
                <Th>Descrição margem</Th>
                <Th>Custo</Th>
                <Th>Última modificação</Th>
                <Th></Th>
              </Tr>
            </THead>
            <TBody>
              {costList.value.map((cost, index) => {
                return (
                  <Tr key={index}>
                    <Td data-heading="ID">{cost.margemLucro.id}</Td>
                    <Td data-heading="Margem">{cost.margemLucro.descricao}</Td>
                    <Td data-heading="Custo">{`${cost.embalagemCusto.custo}`}</Td>
                    <Td data-heading="Última modificação">
                      {formatDate(cost.embalagemCusto.dataManutencao, "dd/MM/yyyy às hh:mm:ss")}
                    </Td>
                    <Td data-heading="Deletar Custo" data-option>
                      <Button
                        className={styles.deleteCostButton}
                        variant="danger"
                        onClick={() => {
                          removeCost(cost.margemLucro.id);
                        }}
                        data-option-button
                      >
                        <Trash weight="fill" />
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </TBody>
          </Table>
        ) : searchingCosts.isLoading || deletingCost.isLoading ? (
          <div className={styles.searchingCostsLoadingContainer}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={styles.noCostsMessage}>Nenhum custo cadastrado</p>
        )}
      </div>
      <div>
        <Paginate
          classNameContainer={styles.paginationContainer}
          totalRecords={pagination.totalRecords}
          maxItems={pagination.maxItems}
          currentPage={pagination.currentPage}
          setCurrentPage={pagination.setCurrentPage}
          onPageChange={(page) => handlePageChange(page)}
        />
      </div>
    </div>
  );
}
