import { useCallback, useRef, useState } from "react";
import { useCustomFetch, useLoading } from "../../../../../../../hooks/async";
import { useModal } from "../../../../../../../hooks/contexts";
import { useForm } from "../../../../../../../hooks/form";
import { isValid } from "../../../../../../../helpers/validations";
import { IPercent } from "./types";

export default function useModalEditPercents() {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const modalRef = useRef<HTMLDialogElement>(null);

  const lastPercentId = useRef<number>(-1);

  const [percentList, setPercentList] = useState<IPercent[]>([]);

  const percentColor = useForm({ required: true });
  const percent = useForm({ required: true });

  const searchingPercents = useLoading();
  const addingNewPercent = useLoading();

  const resetFields = useCallback(() => {
    setPercentList([]);
    percentColor.reset();
    percent.reset();
  }, [percent, percentColor]);

  const searchPercents = useCallback(
    async (idItemTabelaVigencia: number) => {
      try {
        searchingPercents.setLoading(true);
        const json = await customFetch("/products/searchItemPricePercent", {
          body: {
            id: 0,
            idItemTabelaVigencia: idItemTabelaVigencia,
            status: "A",
          },
        });
        if (json.status === 200) {
          setPercentList(json.object);
        } else {
          Modal.error(json.message, json.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        searchingPercents.setLoading(false);
      }
    },
    [Modal, customFetch, searchingPercents]
  );

  const addNewPercent = useCallback(
    (idItemTabelaVigencia: number) => {
      if (!isValid(percent)) return;

      setPercentList((old) => [
        ...old,
        {
          id: lastPercentId.current,
          idItemTabelaVigencia: idItemTabelaVigencia,
          percentual: Number(percent.value),
          cor: percentColor.value || "#000000",
          status: "A",
        },
      ]);

      lastPercentId.current -= 1;

      percent.reset();
      percentColor.reset();
    },
    [percent, percentColor]
  );

  const removePercent = useCallback(
    (percent: IPercent) => {
      if (percent.id < 0) {
        setPercentList((old) => old.filter((item) => item.id !== percent.id));
      } else {
        const percentsUpdated = percentList.map((item) => {
          if (item.id === percent.id) {
            return {
              ...item,
              status: "I" as "A" | "I",
            };
          }
          return {
            ...item,
          };
        });
        setPercentList(percentsUpdated);
      }
    },
    [percentList]
  );

  const savePercents = useCallback(
    async (idItemTabelaVigencia: number) => {
      const confirm = await Modal.confirm("Deseja mesmo salvar esta faixa?");
      if (!confirm) return;

      const body = percentList.map((item) => {
        if (item.id < 0) {
          return {
            ...item,
            id: 0,
          };
        }
        return { ...item };
      });

      try {
        addingNewPercent.setLoading(true);
        const json = await customFetch(
          "/products/registerAndUpdateItemPricePercent",
          {
            body,
          }
        );
        if (json.status === 200) {
          searchPercents(idItemTabelaVigencia);
          percent.reset();
          percentColor.reset();
          lastPercentId.current = -1;
          Modal.success(json.message);
        } else {
          Modal.error(json.message, json.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        addingNewPercent.setLoading(false);
      }
    },
    [
      percentList,
      addingNewPercent,
      customFetch,
      searchPercents,
      percent,
      percentColor,
      Modal,
    ]
  );

  return {
    modalRef,
    data: {
      percentList,
      percent,
      percentColor,
    },
    methods: {
      resetFields,
      searchPercents,
      addNewPercent,
      removePercent,
      savePercents,
    },
    loadings: {
      searchingPercents: searchingPercents.isLoading,
      addingNewPercent: addingNewPercent.isLoading,
    },
  };
}
