import React from "react";

/**
 * Cria uma nova função com efeito de debounce
 *
 * @param {function} func - Função que será executada no debounce
 * @param {number} milliseconds - Tempo em milissegundos(ms) do delay
 * @returns Retorna a função passada como parâmetro, mas com o efeito de debounce
 */
const useDebounce = <T extends (...args: any[]) => any>(func: T, milliseconds: number = 500) => {
  const timerRef = React.useRef<NodeJS.Timeout>();

  React.useEffect(() => {
    return () => {
      if (!timerRef.current) return;
      clearTimeout(timerRef.current);
    };
  }, []);

  return ((...args: Parameters<T>) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => func(...args), milliseconds);
  }) as T;
};

export { useDebounce };
