import React from "react";

import { useLinkManagerAndSellers } from "./hooks/useLinkManagerAndSellers";
import { Button, Select } from "../../../components/Form";
import { Circle } from "../../../components/Loading";

import styles from "./LinkManagerAndSellers.module.css";

export function SettingsLinkManagerAndSellers() {
  const {
    manager,
    sellers,
    managerOptions,
    sellerOptions,
    handleRemoveSeller,
    searchSellersLinkedToManager,
    linkManagerAndSellers,
  } = useLinkManagerAndSellers();

  return (
    <div className="container">
      {!linkManagerAndSellers.linking ? (
        <>
          <div className={styles.fieldsContainer}>
            <div>
              <label htmlFor="manager" className="label">
                Gerente
              </label>
              <Select
                id="manager"
                placeholder="Selecione um gerente"
                options={managerOptions.options}
                value={manager.value}
                error={manager.error}
                onChange={(value) => {
                  if (!value) {
                    sellers.reset();
                  } else {
                    searchSellersLinkedToManager.search(value.value.id);
                  }
                  manager.onChange(value);
                }}
                onBlur={manager.onBlur}
                onInputChange={(description) => {
                  managerOptions.searchDebounced(description);
                }}
                isLoading={managerOptions.searching}
              />
            </div>
            <div>
              <label htmlFor="sellers" className="label">
                Vendedores
              </label>
              <Select
                id="sellers"
                placeholder="Selecione os vendedores que serão vinculados ao gerente"
                options={sellerOptions.options}
                value={sellers.value}
                error={sellers.error}
                onChange={sellers.onChange}
                onBlur={sellers.onBlur}
                onInputChange={(description) => {
                  sellerOptions.searchDebounced(description);
                }}
                isMulti
                closeMenuOnSelect={false}
                isLoading={sellerOptions.searching}
                isDisabled={!manager.value}
              />
            </div>
          </div>
          <span className="separator" />
          {!searchSellersLinkedToManager.searching ? (
            <>
              <div className={styles.listContainer}>
                {sellers.value && sellers.value.length ? (
                  <ul className={styles.sellerListContainer}>
                    {sellers.value.map((seller, index) => (
                      <li key={index}>
                        <span>{seller.label}</span>
                        <span>
                          <button
                            className={styles.removeSellerButton}
                            onClick={() => handleRemoveSeller(seller.value.id)}>
                            X
                          </button>
                        </span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className={`lineCardMessage`}>Nenhum vendedor selecionado</p>
                )}
              </div>
              <div className={`stickyButtonContainer ${styles.buttonContainer}`}>
                <Button onClick={() => linkManagerAndSellers.handle()}>Confirmar Vínculo</Button>
              </div>
            </>
          ) : (
            <div className={`loadingContainer ${styles.searchLoadingContainer}`}>
              <Circle size={100} />
            </div>
          )}
        </>
      ) : (
        <div className={`loadingContainer ${styles.linkLoadingContainer}`}>
          <Circle size={100} />
          <p className="loadingMessage">Vinculando gerente e vendedores</p>
        </div>
      )}
    </div>
  );
}
