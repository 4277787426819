import React from "react";

import { useCustomFetch, useDebounce, useLoading } from "../../../../../../hooks/async";
import { useSelect } from "../../../../../../hooks/form";
import { useModal } from "../../../../../../hooks/contexts";

import { Button, Input, LinkButton, Select } from "../../../../../../components/Form";
import { Table, TBody, Td, Th, THead, Tr } from "../../../../../../components/Data/Table";

import { Circle } from "../../../../../../components/Loading";

import styles from "./ClientList.module.css";

const formatCPFCNPJ = (cpfcnpj) => {
  if (cpfcnpj) {
    if (cpfcnpj.length === 14) {
      return cpfcnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    } else if (cpfcnpj.length === 11) {
      return cpfcnpj.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export function ClientList({ selectedPriceTable, filteredClients, searchClients, searchingClients, clients }) {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const togglingClientStatus = useLoading();

  const filterBy = useSelect({ type: "single", required: true });

  const filterByOptions = [
    {
      value: {
        type: "name",
        filterPlaceholder: "Digite o nome ou o id do cliente para filtrar",
      },
      label: "Nome ou Id",
    },
    {
      value: {
        type: "city",
        filterPlaceholder: "Digite o nome da cidade para filtrar",
      },
      label: "Cidade",
    },
  ];

  const { current: searchClientsDebounced } = React.useRef(
    useDebounce((description, filterType) => {
      searchClients(description, filterType);
    })
  );

  const toggleClientStatus = React.useCallback(
    async (clientId, currentStatus) => {
      try {
        togglingClientStatus.setLoading(true);
        const json = await customFetch("/products/registerAndUpdateClientOfPriceTable", {
          body: {
            idTabela: Number(selectedPriceTable.tabela.idTabela),
            idCliente: Number(clientId),
            status: !currentStatus,
          },
        });
        if (json.status === 200) {
          Modal.success(json.message);
          searchClients(filteredClients.value, filterBy.value.value.type);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        togglingClientStatus.setLoading(false);
      }
    },
    [Modal, customFetch, filterBy, filteredClients, searchClients, selectedPriceTable, togglingClientStatus]
  );

  React.useEffect(() => {
    if (!clients.length) searchClients();
    filterBy.setValue(filterByOptions[0]);
  }, []); // eslint-disable-line

  return (
    <div>
      <div className={styles.navigationContainer}>
        <LinkButton to="novo">Adicionar Cliente</LinkButton>
      </div>
      <div className={styles.filterContainer}>
        <div>
          <label htmlFor="filterBy" className="label">
            Filtrar Por:
          </label>
          <Select
            autoComplete="off"
            id="filterBy"
            options={filterByOptions}
            value={filterBy.value}
            onChange={(value) => {
              filterBy.setValue(value);
              if (filteredClients.value.length) searchClients(filteredClients.value, value.value.type);
            }}
            isSearchable={false}
            isClearable={false}
          />
        </div>
        <div>
          <label htmlFor="filteredClients" className="label">
            Filtrar Clientes
          </label>
          <Input
            id="filteredClients"
            placeholder={filterBy.value?.value?.filterPlaceholder}
            value={filteredClients.value}
            onChange={({ target }) => {
              filteredClients.setValue(target.value);
              searchClientsDebounced(target.value, filterBy.value?.value.type);
            }}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        {clients.length && !searchingClients.isLoading && !togglingClientStatus.isLoading ? (
          <Table title="Clientes na Tabela" className={styles.tableClients}>
            <THead>
              <Tr>
                <Th>ID</Th>
                <Th>Nome</Th>
                <Th width="10.9375rem">CNPJ</Th>
                <Th>Cidade</Th>
                <Th></Th>
              </Tr>
            </THead>
            <TBody>
              {clients.map((client) => {
                return (
                  <Tr key={client.cliente.idCliente}>
                    <Td heading="ID">{client.cliente.idCliente}</Td>
                    <Td heading="Nome">{client.cliente.razaoSocial}</Td>
                    <Td heading="CNPJ">{formatCPFCNPJ(client.cliente.cadastroPessoal)}</Td>
                    <Td heading="Cidade">{client.cidade.nomeCidade}</Td>
                    <Td heading={`${client.tabelaCliente.ativo === "A" ? "Desativar" : "Ativar"}`} data-option>
                      {client.tabelaCliente.ativo === "A" ? (
                        <Button
                          className={styles.deleteClientButton}
                          variant="danger"
                          onClick={() => {
                            const status = client.tabelaCliente.ativo === "A";
                            toggleClientStatus(client.cliente.idCliente, status);
                          }}
                          data-option-button>
                          <span>Desativar</span>
                        </Button>
                      ) : (
                        <Button
                          className={styles.deleteClientButton}
                          onClick={() => {
                            const status = client.tabelaCliente.ativo === "A";
                            toggleClientStatus(client.cliente.idCliente, status);
                          }}
                          data-option-button>
                          <span>Ativar</span>
                        </Button>
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </TBody>
          </Table>
        ) : searchingClients.isLoading || togglingClientStatus.isLoading ? (
          <div className={`loadingContainer`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhum cliente encontrado</p>
        )}
      </div>
    </div>
  );
}
