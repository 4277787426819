import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, LinkButton, Select } from "../../../../../components/Form";
import { Circle } from "../../../../../components/Loading";
import { isValid } from "../../../../../helpers/validations";
import { useCustomFetch, useDebounce, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { useForm, useSelect } from "../../../../../hooks/form";
import { ISearchTablesFormulasEnterprises, ITableFormulaEnterprise } from "../PricesRange";

import styles from "./NewPriceRange.module.css";

interface IInsertNewPriceRangeResponse {
  response: number;
  message: string;
  status: number;
  object: any;
}

interface INewPriceRangeProps {
  searchTablesPromise: (description?: string) => Promise<ISearchTablesFormulasEnterprises>;
  searchFormulasPromise: (description?: string) => Promise<ISearchTablesFormulasEnterprises>;
  searchEnterprisesPromise: (description?: string) => Promise<ISearchTablesFormulasEnterprises>;
  searchTotalPriceRangeRecordsAndPricesRange: (description?: string | undefined) => Promise<void>;
}

export function NewPriceRange({
  searchTablesPromise,
  searchFormulasPromise,
  searchEnterprisesPromise,
  searchTotalPriceRangeRecordsAndPricesRange,
}: INewPriceRangeProps) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const minPrice = useForm({ type: "number", required: true, numberRange: { min: 0 } });
  const maxPrice = useForm({ type: "number", required: true, numberRange: { min: 0 } });
  const table = useSelect({ type: "single", required: true });
  const formula = useSelect({ type: "single", required: true });
  const enterprise = useSelect({ type: "single", required: true });
  const commissionValue = useForm({ type: "number", required: true, numberRange: { min: 0 } });
  const color = useForm({ type: "text", required: true });

  const searchingTableOptions = useLoading();
  const searchingFormulaOptions = useLoading();
  const searchingEnterpriseOptions = useLoading();
  const insertingNewPriceRange = useLoading();

  const [tableOptions, setTableOptions] = React.useState<{ label: string; value: ITableFormulaEnterprise }[]>([]);
  const [formulaOptions, setFormulaOptions] = React.useState<{ label: string; value: ITableFormulaEnterprise }[]>([]);
  const [enterpriseOptions, setEnterpriseOptions] = React.useState<{ label: string; value: ITableFormulaEnterprise }[]>(
    []
  );

  const searchTables = React.useCallback(
    async (description: string = "") => {
      try {
        searchingTableOptions.setLoading(true);
        const json = await searchTablesPromise(description);
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            label: `${option.id} | ${option.descricao}`,
            value: option,
          }));
          setTableOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setTableOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingTableOptions.setLoading(false);
      }
    },
    [Modal, searchTablesPromise, searchingTableOptions]
  );

  const searchFormulas = React.useCallback(
    async (description: string = "") => {
      try {
        searchingFormulaOptions.setLoading(true);
        const json = await searchFormulasPromise(description);
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            label: `${option.id} | ${option.descricao}`,
            value: option,
          }));
          setFormulaOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setFormulaOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingFormulaOptions.setLoading(false);
      }
    },
    [Modal, searchFormulasPromise, searchingFormulaOptions]
  );

  const searchEnterprises = React.useCallback(
    async (description: string = "") => {
      try {
        searchingEnterpriseOptions.setLoading(true);
        const json = await searchEnterprisesPromise(description);
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            label: `${option.id} | ${option.descricao}`,
            value: option,
          }));
          const enterprise_11 = options.find((option) => option.value.id === 11);
          if (!enterprise.value && enterprise_11) {
            enterprise.setValue(enterprise_11);
          }
          setEnterpriseOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setEnterpriseOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingEnterpriseOptions.setLoading(false);
      }
    },
    [Modal, enterprise, searchEnterprisesPromise, searchingEnterpriseOptions]
  );

  const searchTablesDebounced = useDebounce(searchTables);

  const searchFormulasDebounced = useDebounce(searchFormulas);

  const searchEnterprisesDebounced = useDebounce(searchEnterprises);

  const handleClickSaveNewPriceRange = async () => {
    if (isValid(minPrice, maxPrice, table, formula, enterprise, commissionValue, color)) {
      try {
        insertingNewPriceRange.setLoading(true);
        const json = (await customFetch("/commissions/insertAndUpdatePriceRange", {
          body: {
            idTabela: (table.value as unknown as any).value.id,
            idFormulado: (formula.value as unknown as any).value.id,
            idEmpresa: (enterprise.value as unknown as any).value.id,
            precoMinimo: Number(minPrice.value),
            precoMaximo: Number(maxPrice.value),
            status: true,
            comissaoValor: Number(commissionValue.value),
            rgb: color.value,
          },
        })) as IInsertNewPriceRangeResponse;
        if (json.status === 200) {
          searchTotalPriceRangeRecordsAndPricesRange();
          await Modal.success(json.message);
          navigate("/comissoes/foliar/faixas-de-preco");
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        insertingNewPriceRange.setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    searchTables();
    searchFormulas();
    searchEnterprises();
    color.setValue("#000000");
  }, []); // eslint-disable-line

  return (
    <div className="container">
      {!insertingNewPriceRange.isLoading ? (
        <>
          <div>
            <LinkButton
              to="/comissoes/foliar/faixas-de-preco"
              buttonStyle="backButton"
              children={undefined}
              variant={undefined}
              className={undefined}
              classNameContainer={undefined}
              disabled={undefined}
            />
          </div>
          <div className={styles.fieldsContainer}>
            <div data-grid="minPrice">
              <label htmlFor="minPrice" className="label">
                Preço Mínimo
              </label>
              <Input
                id="minPrice"
                placeholder="Digite o preço mínimo"
                type="number"
                className={`cleanInputNumber`}
                value={minPrice.value}
                error={minPrice.error as unknown as string}
                onChange={minPrice.onChange}
                onBlur={minPrice.onBlur}
                onWheel={(event) => {
                  event.preventDefault();
                  event.currentTarget.blur();
                }}
              />
            </div>
            <div data-grid="maxPrice">
              <label htmlFor="maxPrice" className="label">
                Preço Máximo
              </label>
              <Input
                id="maxPrice"
                placeholder="Digite o preço máximo"
                type="number"
                className={`cleanInputNumber`}
                value={maxPrice.value}
                error={maxPrice.error as unknown as string}
                onChange={maxPrice.onChange}
                onBlur={maxPrice.onBlur}
                onWheel={(event) => {
                  event.preventDefault();
                  event.currentTarget.blur();
                }}
              />
            </div>
            <div data-grid="commissionValue">
              <label htmlFor="commissionValue" className="label">
                Valor Comissão
              </label>
              <Input
                id="commissionValue"
                placeholder="Digite o valor da comissão"
                type="number"
                className={`cleanInputNumber`}
                value={commissionValue.value}
                error={commissionValue.error as unknown as string}
                onChange={commissionValue.onChange}
                onBlur={commissionValue.onBlur}
                onWheel={(event) => {
                  event.preventDefault();
                  event.currentTarget.blur();
                }}
              />
            </div>
            <div data-grid="table">
              <label htmlFor="table" className="label">
                Tabela
              </label>
              <Select
                id="table"
                placeholder="Selecione uma tabela"
                options={tableOptions}
                value={table.value}
                defaultValue={undefined}
                error={table.error}
                onChange={table.onChange}
                onInputChange={searchTablesDebounced}
                onBlur={table.onBlur}
                isLoading={searchingTableOptions.isLoading}
              />
            </div>
            <div data-grid="formula">
              <label htmlFor="formula" className="label">
                Formulado
              </label>
              <Select
                id="formula"
                placeholder="Selecione um formulado"
                options={formulaOptions}
                value={formula.value}
                defaultValue={undefined}
                error={formula.error}
                onChange={formula.onChange}
                onInputChange={searchFormulasDebounced}
                onBlur={formula.onBlur}
                isLoading={searchingFormulaOptions.isLoading}
              />
            </div>
            <div data-grid="enterprise">
              <label htmlFor="enterprise" className="label">
                Empresa
              </label>
              <Select
                id="enterprise"
                placeholder="Selecione uma empresa"
                options={enterpriseOptions}
                value={enterprise.value}
                defaultValue={undefined}
                error={enterprise.error}
                onChange={enterprise.onChange}
                onInputChange={searchEnterprisesDebounced}
                onBlur={enterprise.onBlur}
                isLoading={searchingEnterpriseOptions.isLoading}
              />
            </div>
            <div data-grid="color">
              <label htmlFor="color" className="label">
                Cor da Faixa
              </label>
              <input type="color" id="color" className="inputColor" value={color.value} onChange={color.onChange} />
            </div>
            <div data-grid="saveButton">
              <Button onClick={handleClickSaveNewPriceRange}>Salvar</Button>
            </div>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Salvando Nova Faixa de Preços</span>
        </div>
      )}
    </div>
  );
}
