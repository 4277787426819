import React from "react";
import { Button, Input } from "../../../../../components/Form/index";
import { TCurrentPage } from "../PremiationTypes";

import styles from "./AddAndUpdatePremiationType.module.css";

interface IAddAndUpdatePremiationTypeProps {
  setCurrentPage: React.Dispatch<React.SetStateAction<TCurrentPage>>;
}

export function AddAndUpdatePremiationType({ setCurrentPage }: IAddAndUpdatePremiationTypeProps) {
  return (
    <div>
      <div className={styles.navContainer}>
        <Button
          variant="danger"
          buttonStyle="backButton"
          children={undefined}
          onClick={() => {
            setCurrentPage("list");
          }}
        />
      </div>
      <div>
        <div>
          <label htmlFor="premiationType" className="label">
            Tipo Premiação
          </label>
          <Input id="premiationType" type="text" />
        </div>
      </div>
      <div className={styles.submitContainer}>
        <Button>Salvar</Button>
      </div>
    </div>
  );
}
