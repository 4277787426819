import React from "react";

import { IUseSolicitationList } from "../hooks/types";
import { ISolicitation } from "../types";
import {
  Table,
  TBody,
  Th,
  THead,
  Tr,
  Td,
} from "../../../../../components/Data/Table";
import { Button, Input } from "../../../../../components/Form";
import { useNavigate } from "react-router-dom";
import { Eye, FileText } from "phosphor-react";
import { Circle } from "../../../../../components/Loading";
import { Paginate } from "../../../../../components/Paginate/Paginate";

import styles from "./List.module.css";
import {
  ExcelPermissions,
  ExcelType,
  UseContractExcel,
} from "../../../hooks/useContractExcel";

interface Props {
  solicitationList: IUseSolicitationList;
  contractExcel: UseContractExcel;
  setSelectedSolicitation: React.Dispatch<
    React.SetStateAction<ISolicitation | null>
  >;
}

export function List({
  solicitationList: {
    pagination,
    filter: { description, status },
    searchSolicitations,
    searchTotalSolicitationRecordsAndSolicitationsDebounced,
    searchingSolicitations,
    solicitationList: [solicitationList],
  },
  contractExcel,
  setSelectedSolicitation,
}: Props) {
  const navigate = useNavigate();

  React.useEffect(() => {
    setSelectedSolicitation(null);
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.filtersContainer}>
        <div>
          <label htmlFor="description" className="label">
            Descrição
          </label>
          <Input
            id="description"
            placeholder="Digite a descrição"
            value={description.value}
            onChange={(e) => {
              description.onChange(e);
              searchTotalSolicitationRecordsAndSolicitationsDebounced(
                e.target.value,
                status.value?.value
              );
            }}
            autoComplete="off"
          />
        </div>
        {/* <div>
          <label htmlFor="status" className="label">
            Status
          </label>
          <Select
            id="status"
            options={statusOptions}
            value={status.value}
            onChange={(value) => {
              status.onChange(value);
              searchTotalSolicitationRecordsAndSolicitationsDebounced(
                description.value,
                value?.value
              );
            }}
            isClearable={false}
            isSearchable={false}
          />
        </div> */}
      </div>
      {!contractExcel.exportingExcel ? (
        <>
          {solicitationList.length && !searchingSolicitations ? (
            <Table title={"Solicitações de Contrato"} className={styles.table}>
              <THead>
                <Tr>
                  <Th width={".625rem"} children={undefined}></Th>
                  <Th width={".625rem"}>ID</Th>
                  <Th width={""}>Descrição Contrato</Th>
                  {contractExcel.verifyPermission(ExcelPermissions.CLIENTE) && (
                    <Th width={""} children={undefined} />
                  )}
                  <Th width={""} children={undefined} />
                </Tr>
              </THead>
              <TBody>
                {solicitationList.map((solicitation, index) => (
                  <Tr key={index}>
                    <Td heading={"Status"}>
                      <span className={styles.statusContainer}>
                        <span
                          className={styles.statusContainer__ball}
                          style={{
                            backgroundColor:
                              solicitation.nomeStatus === "Pendente"
                                ? "var(--orange-5)"
                                : "var(--green-5)",
                          }}
                        />
                        <span className={styles.statusContainer__status}>
                          {solicitation.nomeStatus}
                        </span>
                      </span>
                    </Td>
                    <Td heading={"ID"}>{solicitation.idSolicitacao}</Td>
                    <Td heading={"Descrição Contrato"}>
                      {solicitation.descricaoContrato}
                    </Td>
                    {contractExcel.verifyPermission(
                      ExcelPermissions.CLIENTE
                    ) && (
                      <Td heading="Exportar Excel" width="6.25rem" data-option>
                        <Button
                          type="button"
                          className={styles.seeRequestButton}
                          onClick={() => {
                            contractExcel.exportExcel(
                              solicitation.idSolicitacao,
                              ExcelType.MODELO_CLIENTE
                            );
                          }}
                          data-option-button
                        >
                          <FileText weight="fill" />
                        </Button>
                      </Td>
                    )}
                    <Td heading="Ver Solicitação" width="6.25rem" data-option>
                      <Button
                        type="button"
                        variant="edit"
                        className={styles.seeRequestButton}
                        onClick={() => {
                          setSelectedSolicitation(solicitation);
                          navigate("analise");
                        }}
                        data-option-button
                      >
                        <Eye weight="fill" />
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </TBody>
            </Table>
          ) : searchingSolicitations ? (
            <div className={`loadingContainer ${styles.loadingContainer}`}>
              <Circle size={100} />
            </div>
          ) : (
            <p className={`lineCardMessage`}>Nenhuma solicitação encontrada</p>
          )}
          <Paginate
            classNameContainer={styles.paginationContainer}
            maxItems={pagination.maxItems}
            totalRecords={pagination.totalRecords}
            currentPage={pagination.currentPage}
            setCurrentPage={pagination.setCurrentPage}
            onPageChange={(page) => {
              searchSolicitations(
                page - 1,
                description.value,
                status.value?.value
              );
            }}
          />
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
        </div>
      )}
    </div>
  );
}
