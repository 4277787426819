import React from "react";
import { Route, Routes } from "react-router-dom";
import { IPaymentMethod, List } from "./List/List";
import { NewPaymentMethod } from "./NewPaymentMethod/NewPaymentMethod";
import { UpdatePaymentMethod } from "./UpdatePaymentMethod/UpdatePaymentMethod";
import { useModal } from "../../../hooks/contexts";
import { useCustomFetch, useLoading } from "../../../hooks/async";
import { usePagination } from "../../../hooks/pagination";

export function FinancePaymentMethod() {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const [paymentMethodList, setPaymentMethodList] = React.useState<IPaymentMethod[]>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<IPaymentMethod | null>(null);

  const searchingPaymentMethods = useLoading();

  const pagination = usePagination(10);

  const searchPaymentMethodsPromise = React.useCallback(
    (page = 1) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/finance/searchPaymentMethodsPaginated", {
            body: {
              id: 0,
              paginacao: {
                paginaNumero: page,
                paginaTamanho: pagination.maxItems,
              },
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const searchTotalPaymentMethodRecordsPromise = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const json = await customFetch("/finance/searchTotalPaymentMethodRecords", {});
        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
  }, [customFetch]);

  const searchPaymentMethods = React.useCallback(
    async (page: number = 1) => {
      try {
        searchingPaymentMethods.setLoading(true);
        const json = (await searchPaymentMethodsPromise(page)) as DefaultFetchResponse<IPaymentMethod[]>;
        if (json.status === 200) {
          setPaymentMethodList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setPaymentMethodList([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingPaymentMethods.setLoading(false);
      }
    },
    [Modal, searchPaymentMethodsPromise, searchingPaymentMethods]
  );

  const searchTotalPaymentMethodRecordsAndPaymentMethods = React.useCallback(async () => {
    try {
      searchingPaymentMethods.setLoading(true);
      const json = (await searchPaymentMethodsPromise()) as DefaultFetchResponse<IPaymentMethod[]>;
      if (json.status === 200) {
        const records = (await searchTotalPaymentMethodRecordsPromise()) as DefaultFetchResponse<string>;
        if (records.status === 200) {
          setPaymentMethodList(json.object);
          pagination.setTotalRecords(Number(records.object));
        } else if (records.status === 500) {
          Modal.error(records.message, records.object);
        } else {
          setPaymentMethodList([]);
        }
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setPaymentMethodList([]);
        pagination.reset();
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      pagination.setCurrentPage(1);
      searchingPaymentMethods.setLoading(false);
    }
  }, [Modal, pagination, searchPaymentMethodsPromise, searchTotalPaymentMethodRecordsPromise, searchingPaymentMethods]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <List
            paymentMethodList={{ value: paymentMethodList, setValue: setPaymentMethodList }}
            searchPaymentMethods={searchPaymentMethods}
            searchTotalPaymentMethodRecordsAndPaymentMethods={{
              search: searchTotalPaymentMethodRecordsAndPaymentMethods,
              isSearching: searchingPaymentMethods.isLoading,
            }}
            pagination={pagination}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
          />
        }
      />
      <Route
        path="nova"
        element={
          <NewPaymentMethod
            searchTotalPaymentMethodRecordsAndPaymentMethods={searchTotalPaymentMethodRecordsAndPaymentMethods}
          />
        }
      />
      <Route
        path="atualizar"
        element={
          <UpdatePaymentMethod
            searchPaymentMethods={() => searchPaymentMethods(pagination.currentPage)}
            selectedPaymentMethod={selectedPaymentMethod}
          />
        }
      />
    </Routes>
  );
}
