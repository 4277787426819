import React from "react";
import { Button, Input, InputMask, LinkButton, Select } from "../../../../components/Form";

import styles from "./AdicionarAtualizar.module.css";
import { useAdicionarAtualizarHook } from "./hooks/AdicionarAtualizarHook";
import { Circle } from "../../../../components/Loading";
import { IContratados, IUseContratados } from "../hooks/types";
import { useNavigate } from "react-router-dom";

interface IProps {
  tipo: "insert" | "update";
  contratadosData: IUseContratados;
  selectedContratado?: IContratados | null;
}

export function AdicionarAtualizar({ tipo, contratadosData, selectedContratado }: IProps) {
  const navigate = useNavigate();
  const {
    carregarDados,
    form: { contratado, telefoneContato, cnpj, nomeContato, emailContato, cep, rua, numero, bairro, cidade, status },
    selectOptions: { cidadeOptions, statusOptions },
    searchs: { searchingCidadeOptions },
    interactions: { insertOrUpdateContratado, insertingOrUpdatingContratado },
  } = useAdicionarAtualizarHook({ contratadosData });

  React.useEffect(() => {
    if (tipo === "update") {
      if (!selectedContratado) navigate("/contratos-fornecedor-cliente/contratado");
      else carregarDados(selectedContratado);
    }
  }, []); // eslint-disable-line

  return (
    <div className="container">
      {!insertingOrUpdatingContratado ? (
        <>
          <div>
            <LinkButton
              to="/contratos-fornecedor-cliente/contratado"
              buttonStyle="backButton"
              className={styles.navButton}
            />
          </div>
          <span className="separator" />
          <div>
            <fieldset className={`fieldset ${styles.dadosContratoContainer}`}>
              <legend className="fieldsetLegend">Dados Contratado</legend>
              <div className={styles.dadosContratoContainer__contratado}>
                <label htmlFor="contratado" className="label">
                  Contratado
                </label>
                <Input
                  id="contratado"
                  placeholder="Digite o nome do contratado"
                  value={contratado.value}
                  onChange={contratado.onChange}
                  onBlur={contratado.onBlur}
                  error={contratado.error}
                />
              </div>
              <div>
                <label htmlFor="cnpj" className="label">
                  CNPJ
                </label>
                <InputMask
                  id="cnpj"
                  placeholder="Digite o CNPJ"
                  mask="99.999.999/9999-99"
                  value={cnpj.value}
                  onChange={cnpj.onChange}
                  onBlur={cnpj.onBlur}
                  error={cnpj.error}
                />
              </div>
              <div>
                <label htmlFor="nomeContato" className="label">
                  Nome do Contratado
                </label>
                <Input
                  id="nomeContato"
                  placeholder="Digite o nome do contratado"
                  value={nomeContato.value}
                  onChange={nomeContato.onChange}
                  onBlur={nomeContato.onBlur}
                  error={nomeContato.error}
                />
              </div>
              <div>
                <label htmlFor="emailContato" className="label">
                  Email do Contratado
                </label>
                <Input
                  id="emailContato"
                  placeholder="Digite o email do contato"
                  value={emailContato.value}
                  onChange={emailContato.onChange}
                  onBlur={emailContato.onBlur}
                  error={emailContato.error}
                />
              </div>
              <div>
                <label htmlFor="telefoneContato" className="label">
                  Telefone do Contratado
                </label>
                <InputMask
                  id="telefoneContato"
                  placeholder="Digite o telefone do contato"
                  value={telefoneContato.value}
                  onChange={telefoneContato.onChange}
                  onBlur={telefoneContato.onBlur}
                  error={telefoneContato.error}
                  mask={"(99) 99999-9999"}
                />
              </div>
            </fieldset>
            <fieldset className={`fieldset ${styles.enderecoContainer}`}>
              <legend className="fieldsetLegend">Endereço</legend>
              <div className={styles.enderecoContainer__cidadeContainer}>
                <div>
                  <label htmlFor="cidade" className="label">
                    Cidade
                  </label>
                  <Select
                    id="cidade"
                    placeholder="Selecione a cidade"
                    value={cidade.value}
                    options={cidadeOptions}
                    onChange={cidade.onChange}
                    onBlur={cidade.onBlur}
                    error={cidade.error}
                    isLoading={searchingCidadeOptions}
                  />
                </div>
                <div>
                  <label htmlFor="cep" className="label">
                    CEP
                  </label>
                  <InputMask
                    id="cep"
                    placeholder="Digite o cep"
                    mask={"99999-999"}
                    value={cep.value}
                    onChange={cep.onChange}
                    onBlur={cep.onBlur}
                    error={cep.error}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="rua" className="label">
                  Rua
                </label>
                <Input
                  id="rua"
                  placeholder="Digite o nome da rua"
                  value={rua.value}
                  onChange={rua.onChange}
                  onBlur={rua.onBlur}
                  error={rua.error}
                />
              </div>
              <div>
                <label htmlFor="numero" className="label">
                  Número ('s/n' para sem número)
                </label>
                <Input
                  id="numero"
                  placeholder="Digite o número"
                  value={numero.value}
                  onChange={numero.onChange}
                  onBlur={numero.onBlur}
                  error={numero.error}
                />
              </div>
              <div>
                <label htmlFor="bairro" className="label">
                  Bairro
                </label>
                <Input
                  id="bairro"
                  placeholder="Digite o nome do bairro"
                  value={bairro.value}
                  onChange={bairro.onChange}
                  onBlur={bairro.onBlur}
                  error={bairro.error}
                />
              </div>
            </fieldset>
            {tipo === "update" && (
              <div className={styles.statusContainer}>
                <div className={styles.statusContainer__status}>
                  <label htmlFor="status" className="label">
                    Situação
                  </label>
                  <Select
                    id="status"
                    placeholder="Selecione o status"
                    value={status.value}
                    options={statusOptions}
                    onChange={status.onChange}
                    onBlur={status.onBlur}
                    error={status.error}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => {
                if (tipo === "insert") insertOrUpdateContratado();
                else insertOrUpdateContratado(selectedContratado?.idContratado);
              }}>
              Concluir
            </Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <p className="loadingMessage">{tipo === "insert" ? "Inserindo Novo Contratado" : "Atualizando Contratado"}</p>
        </div>
      )}
    </div>
  );
}
