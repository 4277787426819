import React from "react";

interface IValidateRegex {
  regex: RegExp;
  message: string;
}

interface IValidateFunction {
  validate: (value: any) => boolean;
  message: string;
}

interface IUseFormProps {
  type?: "text" | "number" | "email" | "simple_url" | "";
  required: boolean;
  emptyMessage?: string;
  customRegex?: IValidateRegex;
  customValidations?: IValidateFunction[];
  numberRange?: {
    min?: number;
    max?: number;
  };
}

declare global {
  interface IUseForm {
    value: string;
    setValue: (value: string | ((value: string) => string)) => void;
    error: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
    onChange: ({ target }: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: () => boolean;
    validate: () => boolean;
    reset: (value?: string | ((value: string) => string)) => void;
  }
}

const typesValidate = {
  email: {
    regex:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Este email não é válido",
  },
  simple_url: {
    regex: /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/,
    message: "Esta URL não é válida",
  },
  number: {
    regex: /^\d+e\d+|\d+$/,
    message: "Este valor não é válido",
  },
} as { [key: string]: any };

const useForm = ({
  type = "",
  required,
  emptyMessage = "",
  customRegex,
  customValidations = [],
  numberRange,
}: IUseFormProps): IUseForm => {
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");

  const reset = React.useCallback((value: string | ((value: string) => string) = "") => {
    if (typeof value === "function") {
      setValue((old) => {
        const result = value(old);
        return result;
      });
      setError("");
    } else {
      setValue(value);
      setError("");
    }
  }, []);

  const validate = React.useCallback(
    (value: string) => {
      if (!type && !required) return true;
      if (value.length === 0 && !required) {
        setError("");
        return true;
      }
      if (value.length === 0) {
        setError(emptyMessage || "Preencha um valor");
        return false;
      } else if (type === "number") {
        const isRegexValid = typesValidate[type].regex.test(value);
        if (isRegexValid && numberRange) {
          if (typeof numberRange.min === "number" && Number(value) < Number(numberRange.min)) {
            setError(`Valor mínimo: ${numberRange.min}`);
            return false;
          } else if (typeof numberRange.max === "number" && Number(value) > Number(numberRange.max)) {
            setError(`Valor máximo: ${numberRange.max}`);
            return false;
          } else {
            setError("");
            return true;
          }
        } else if (!isRegexValid) {
          setError(`Este valor não é válido`);
          return false;
        } else {
          setError("");
          return true;
        }
      } else if (typesValidate[type] && !typesValidate[type].regex.test(value)) {
        setError(typesValidate[type].message);
        return false;
      } else if (customRegex && !customRegex.regex.test(value)) {
        setError(customRegex.message);
        return false;
      } else if (customValidations.length) {
        let isInvalid = false;
        customValidations.forEach((validation) => {
          if (!validation.validate(value)) {
            setError(validation.message);
            isInvalid = true;
          }
        });
        if (isInvalid) return false;
        else {
          setError("");
          return true;
        }
      } else {
        setError("");
        return true;
      }
    },
    [customRegex, customValidations, emptyMessage, numberRange, required, type]
  );

  const onChange = React.useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      if (error) validate(target.value);
      setValue(target.value);
    },
    [error, validate]
  );

  React.useEffect(() => {
    if (!required && error) setError("");
  }, [type, required, error, validate, value]);

  return {
    value,
    setValue: (value: string | ((value: string) => string)) => {
      if (typeof value === "function") {
        setValue((old) => {
          const result = value(old);
          validate(result);
          return result;
        });
      } else {
        setValue(value);
        validate(value);
      }
    },
    error,
    setError,
    onChange,
    onBlur: () => validate(value),
    validate: () => validate(value),
    reset,
  };
};

export { useForm };
