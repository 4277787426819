import React from "react";
import { useSelect } from "../../../../../hooks/form";
import { IItem } from "../CommissionTableItems/CommissionTableItems";
import { ICommissionTable } from "../CommissionTables";

import { Route, Routes } from "react-router-dom";
import { ItemPeopleList } from "./ItemPeopleList/ItemPeopleList";
import { InsertAndUpdatePeoples } from "./InsertAndUpdatePeoples/InsertAndUpdatePeoples";

export interface IPeople {
  idUsuario: number;
  nome: string;
}

export interface IItemPeopleRange {
  idFormulado: number;
  descricaoFormulado: string;
  itemComissaoTabela: {
    idFaixaPrecoproduto: number;
    idFormulado: number;
    idEmpresa: number;
    idTabela: number;
    perMinimo: number;
    perMaximo: number;
    status: "A" | "I";
    comissaoValor: number;
    rgb: string;
    dataCriacao: string;
    dataManutencao: string;
  };
  comissaoFaixa: {
    id: number;
    idVendedor: number;
    idUsuario: number;
    idFaixa: number;
    percentual: number;
    dataManutencao: string;
    dataCriacao: string;
  };
  donoFaixa: {
    idVendedor: number;
    nome: string;
  };
  pessoaGanharComissao: {
    idPessoa: number;
    nome: string;
  };
}

export interface IItemPeople {
  nomeVendedor: string;
  faixas: IItemPeopleRange[];
}

interface IProps {
  selectedTable: ICommissionTable | null;
  selectedItem: IItem | null;
}

export function UpdateItemPeoples({ selectedItem, selectedTable }: IProps) {
  const peoples = useSelect({ type: "multiple", required: false }) as unknown as IUseSelect<any, "multiple">;

  const [itemPeoples, setItemPeoples] = React.useState<IItemPeople[]>([]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ItemPeopleList
            selectedItem={selectedItem}
            selectedTable={selectedTable}
            itemPeoples={{ value: itemPeoples, setValue: setItemPeoples }}
            peoples={peoples}
          />
        }
      />
      <Route
        path="novas"
        element={
          <InsertAndUpdatePeoples
            selectedItem={selectedItem}
            selectedTable={selectedTable}
            setItemPeoples={setItemPeoples}
          />
        }
      />
    </Routes>
  );
}
