import React from "react";
import { Eye, EyeClosed } from "phosphor-react";

import styles from "./Input.module.css";

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string | undefined;
}

const Input = ({
  type = "",
  value = "",
  onChange = () => {},
  onBlur = () => {},
  error = "",
  className = "",
  ...props
}: IInputProps) => {
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  return (
    <span className={styles.wrapper}>
      {type === "password" ? (
        <span className={styles.inputContainer}>
          <input
            className={`${styles.input} ${error ? "isInvalid" : ""} ${
              String(value).length ? "isFilled" : ""
            } ${className}`}
            type={passwordVisible ? "text" : "password"}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            {...props}
          />
          <button
            className={styles.buttonTogglePasswordVisible}
            type="button"
            onClick={() => setPasswordVisible((isVisible) => !isVisible)}>
            {passwordVisible ? <Eye size={24} color="var(--gray-6)" /> : <EyeClosed size={24} color="var(--gray-6)" />}
          </button>
        </span>
      ) : (
        <input
          className={`${styles.input} ${error ? "isInvalid" : ""} ${
            String(value).length ? "isFilled" : ""
          } ${className}`}
          type={type || "text"}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          {...props}
        />
      )}
      {error && <p className={styles.error}>{error}</p>}
    </span>
  );
};

export { Input };
