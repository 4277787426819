import React from "react";
import { Route, Routes } from "react-router-dom";
import { useModal } from "../../../hooks/contexts";
import { useCustomFetch, useLoading } from "../../../hooks/async";
import { usePagination } from "../../../hooks/pagination";
import { List } from "./List/List";
import { NewUserBankAccount } from "./NewUserBankAccount/NewUserBankAccount";
import { UpdateUserBankAccount } from "./UpdateUserBankAccount/UpdateUserBankAccount";

export interface IUserBankAccount {
  usuarioContaId: number;
  bancoId: number;
  banco: {
    bancoId: number;
    descricao: string;
    status: string;
    usuarioAlteracaoId: number;
  } | null;
  usuarioId: number;
  usuario: {
    idUsuario: number;
    nome: string;
    usuario: string;
    idSiagri: number;
    idEmpresa: number;
    email: string;
    statusUsuario: string;
  } | null;
  bancoFormaPagamentoId: number;
  bancoFormaPagamento: {
    bancoFormaPagamentoId: number;
    descricao: string;
    status: string;
    usuarioAlteracaoId: number;
  } | null;
  agencia: string;
  conta: string;
  digitoVerificador: string;
  status: "A" | "I";
  usuarioAlteracaoId: number | null;
}

export function FinanceUserBankAccount() {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const [usersBankAccountList, setUsersBankAccountList] = React.useState<IUserBankAccount[]>([]);
  const [selectedUserBankAccount, setSelectedUserBankAccount] = React.useState<IUserBankAccount | null>(null);

  const searchingUsersBankAccount = useLoading();

  const pagination = usePagination(10);

  const searchUsersBankAccountPromise = React.useCallback(
    (page = 1) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/finance/searchUsersBankAccountPaginated", {
            body: {
              id: 0,
              paginacao: {
                paginaNumero: page,
                paginaTamanho: pagination.maxItems,
              },
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const searchTotalUsersBankAccountRecordsPromise = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const json = await customFetch("/finance/searchTotalUsersBankAccountRecords", {});
        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
  }, [customFetch]);

  const searchUsersBankAccount = React.useCallback(
    async (page: number = 1) => {
      try {
        searchingUsersBankAccount.setLoading(true);
        const json = (await searchUsersBankAccountPromise(page)) as DefaultFetchResponse<IUserBankAccount[]>;
        if (json.status === 200) {
          setUsersBankAccountList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setUsersBankAccountList([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingUsersBankAccount.setLoading(false);
      }
    },
    [Modal, searchUsersBankAccountPromise, searchingUsersBankAccount]
  );

  const searchTotalUsersBankAccountRecordsAndUsersBankAccount = React.useCallback(async () => {
    try {
      searchingUsersBankAccount.setLoading(true);
      const json = (await searchUsersBankAccountPromise()) as DefaultFetchResponse<IUserBankAccount[]>;
      if (json.status === 200) {
        const records = (await searchTotalUsersBankAccountRecordsPromise()) as DefaultFetchResponse<string>;
        if (records.status === 200) {
          setUsersBankAccountList(json.object);
          pagination.setTotalRecords(Number(records.object));
        } else if (records.status === 500) {
          Modal.error(records.message, records.object);
        } else {
          setUsersBankAccountList([]);
        }
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setUsersBankAccountList([]);
        pagination.reset();
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      pagination.setCurrentPage(1);
      searchingUsersBankAccount.setLoading(false);
    }
  }, [
    Modal,
    pagination,
    searchUsersBankAccountPromise,
    searchTotalUsersBankAccountRecordsPromise,
    searchingUsersBankAccount,
  ]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <List
            usersBankAccountList={{
              value: usersBankAccountList,
              setValue: setUsersBankAccountList,
            }}
            searchUsersBankAccount={searchUsersBankAccount}
            searchTotalUsersBankAccountRecordsAndUsersBankAccount={{
              search: searchTotalUsersBankAccountRecordsAndUsersBankAccount,
              isSearching: searchingUsersBankAccount.isLoading,
            }}
            pagination={pagination}
            setSelectedUserBankAccount={setSelectedUserBankAccount}
          />
        }
      />
      <Route
        path="nova"
        element={
          <NewUserBankAccount
            searchTotalUsersBankAccountRecordsAndUsersBankAccount={
              searchTotalUsersBankAccountRecordsAndUsersBankAccount
            }
          />
        }
      />
      <Route
        path="atualizar"
        element={
          <UpdateUserBankAccount
            selectedUserBankAccount={selectedUserBankAccount}
            searchUsersBankAccount={() => searchUsersBankAccount(pagination.currentPage)}
          />
        }
      />
    </Routes>
  );
}
