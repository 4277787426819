import React from "react";
import useNonconformity from "../../hooks/useNonconformity";
import { TipoPagina } from "../../hooks/types";
import { Route, Routes } from "react-router-dom";
import List from "../../components/List/List";
import Details from "../../components/Details/Details";

export default function PendingManager() {
  const data = useNonconformity([
    TipoPagina.pendingManager,
    TipoPagina.reopened,
  ]);

  return (
    <Routes>
      <Route path="/" element={<List data={data} />} />
      <Route
        path="detalhes"
        element={
          <Details
            types={[TipoPagina.pendingManager, TipoPagina.reopened]}
            data={data}
          />
        }
      />
    </Routes>
  );
}
