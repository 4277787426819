import React from "react";

interface IChangesProviderProps {
  children: React.ReactNode;
}

interface IChangesContext {
  isChangesDetected: boolean;
  setIsChangesDetected: React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * Define que há mudanças detectadas
   */
  set: () => void;
  /**
   * Define que não há mais mudanças detectadas
   */
  reset: () => void;
}

const ChangesContext = React.createContext<IChangesContext>({
  isChangesDetected: false,
  setIsChangesDetected: () => {},
  reset: () => {},
  set: () => {},
});

const ChangesProvider = ({ children }: IChangesProviderProps) => {
  const [isChangesDetected, setIsChangesDetected] = React.useState(false);

  return (
    <ChangesContext.Provider
      value={{
        isChangesDetected,
        setIsChangesDetected,
        set: () => setIsChangesDetected(true),
        reset: () => setIsChangesDetected(false),
      }}
      children={children}
    />
  );
};

export { ChangesContext, ChangesProvider };
