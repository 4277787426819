import React from "react";
import { Button } from "../../../components/Form";
import { TModalMessage, TModalOnCancel, TModalOnContinue } from "./Modal";

import modalStyles from "./styles/modal.module.css";
import confirmStyles from "./styles/confirm.module.css";

interface IModalConfirmProps {
  message: TModalMessage;
  onContinue: TModalOnContinue;
  onCancel: TModalOnCancel;
}

const ModalConfirm = ({ message, onContinue, onCancel }: IModalConfirmProps) => {
  return (
    <div className={`${modalStyles.container} ${confirmStyles.container}`}>
      {typeof message === "string" && (
        <p className={modalStyles.message} dangerouslySetInnerHTML={{ __html: message }}></p>
      )}
      <span className={modalStyles.separator}></span>
      <div className={modalStyles.buttonContainer}>
        <Button className={modalStyles.button} onClick={() => onContinue()}>
          OK
        </Button>
        <Button className={modalStyles.button} onClick={() => onCancel()} variant="neutral">
          Cancelar
        </Button>
      </div>
    </div>
  );
};

export { ModalConfirm };
