import React from "react";

import { useCustomFetch, useDebounce, useLoading } from "../../../../../../hooks/async";
import { useModal } from "../../../../../../hooks/contexts";

import { Button, Input, LinkButton } from "../../../../../../components/Form";
// import { Table, TBody, Td, Th, THead, Tr } from "../../../../../../components/Data/Table";
import { Table } from "../../../../../../components/Data/XTable";

import { Circle } from "../../../../../../components/Loading";

import styles from "./SellerList.module.css";
import { Paginate } from "../../../../../../components/Paginate/Paginate";

export function SellerList({
  selectedPriceTable,
  filteredSellers,
  searchSellers,
  searchTotalRecordsAndSellers,
  searchingSellers,
  sellers,
  pagination,
}) {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const togglingSellerStatus = useLoading();

  const searchTotalRecordsAndSellersDebounced = useDebounce(searchTotalRecordsAndSellers);

  const toggleSellerStatus = React.useCallback(
    async (sellerId, currentStatus) => {
      try {
        togglingSellerStatus.setLoading(true);
        const json = await customFetch("/products/registerAndUpdateSellerOfPriceTable", {
          body: {
            idTabela: Number(selectedPriceTable.tabela.idTabela),
            idVendedor: Number(sellerId),
            status: !currentStatus,
          },
        });
        if (json.status === 200) {
          Modal.success(json.message);
          searchSellers(filteredSellers.value, pagination.currentPage - 1);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        togglingSellerStatus.setLoading(false);
      }
    },
    [Modal, customFetch, filteredSellers, searchSellers, selectedPriceTable, togglingSellerStatus, pagination]
  );

  const tableData = React.useMemo(() => {
    const data = sellers.map((seller) => {
      return {
        ...seller.vendedor,
        acao:
          seller.vendedor.situacao === "A" ? (
            <Button
              className={styles.deleteClientButton}
              variant="danger"
              onClick={() => {
                const status = seller.vendedor.situacao === "A";
                toggleSellerStatus(seller.vendedor.idVendedor, status);
              }}
              data-option-button>
              <span>Desativar</span>
            </Button>
          ) : (
            <Button
              className={styles.deleteClientButton}
              onClick={() => {
                const status = seller.vendedor.situacao === "A";
                toggleSellerStatus(seller.vendedor.idVendedor, status);
              }}
              data-option-button>
              <span>Ativar</span>
            </Button>
          ),
      };
    });
    return {
      columns: [
        {
          title: "ID",
          objectName: "idVendedor",
          isSorted: true,
          style: { width: ".625rem" },
        },
        {
          title: "Nome",
          objectName: "nomeVendedor",
          isSorted: true,
        },
        {
          title: "",
          objectName: "acao",
          isSorted: false,
          style: { width: "5rem" },
        },
      ],
      data,
    };
  }, [sellers, toggleSellerStatus]);

  const inactivateSellers = React.useCallback(async () => {
    try {
      searchingSellers.setLoading(true);
      const json = await customFetch(
        `/products/inactivateSellersInPriceTable/${Number(selectedPriceTable.tabela.idTabela)}`,
        {}
      );
      if (json.status === 200) {
        Modal.success(json.message);
        searchSellers(filteredSellers.value, pagination.currentPage - 1);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error.message);
    } finally {
      searchingSellers.setLoading(false);
    }
  }, [Modal, customFetch, filteredSellers, pagination, searchSellers, searchingSellers, selectedPriceTable]);

  React.useEffect(() => {
    if (!sellers.length) searchTotalRecordsAndSellers("");
  }, []); // eslint-disable-line

  return (
    <div>
      <div className={styles.navigationContainer}>
        <Button
          onClick={async () => {
            const confirm = await Modal.confirm("Deseja realmente desativar todos os vendedores da tabela?");
            if (confirm) inactivateSellers();
          }}
          variant="danger"
          disabled={searchingSellers.isLoading}>
          Desativar Vendedores
        </Button>
        <LinkButton to="novo">Adicionar Vendedor</LinkButton>
      </div>
      <div className={styles.filterContainer}>
        <div>
          <label htmlFor="filteredSellers" className="label">
            Filtrar Vendedores
          </label>
          <Input
            id="filteredSellers"
            placeholder="Digite o nome ou o id do vendedor para filtrar"
            value={filteredSellers.value}
            onChange={({ target }) => {
              filteredSellers.setValue(target.value);
              searchTotalRecordsAndSellersDebounced(target.value);
            }}
            disabled={searchingSellers.isLoading}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        {sellers.length ? (
          <div className={styles.itemQuantityContainer}>
            <select
              className={styles.itemQuantityContainer__select}
              value={pagination.maxItems}
              onChange={({ target }) => {
                pagination.setMaxItems(Number(target.value));
                searchTotalRecordsAndSellers(filteredSellers.value, Number(target.value));
              }}>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <p>resultados por página</p>
          </div>
        ) : null}
        {sellers.length && !searchingSellers.isLoading && !togglingSellerStatus.isLoading ? (
          <Table tableData={tableData} className={styles.tableClients} />
        ) : searchingSellers.isLoading || togglingSellerStatus.isLoading ? (
          <div className={`loadingContainer`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhum vendedor encontrado</p>
        )}
      </div>
      <Paginate
        classNameContainer={styles.paginationContainer}
        totalRecords={pagination.totalRecords}
        maxItems={pagination.maxItems}
        currentPage={pagination.currentPage}
        setCurrentPage={pagination.setCurrentPage}
        onPageChange={(page) => searchSellers(filteredSellers.value, page - 1)}
      />
    </div>
  );
}
