import React from "react";
import { Route, Routes } from "react-router-dom";

import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { useForm } from "../../../../../hooks/form";

import { SellerList } from "./SellerList/SellerList";
import { NewSeller } from "./NewSeller/NewSeller";
import { usePagination } from "../../../../../hooks/pagination";

export function Sellers({ selectedPriceTable }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const filteredSellers = useForm({ type: "", required: false });

  const [sellers, setSellers] = React.useState([]);
  const pagination = usePagination(100);

  const searchingSellers = useLoading();

  const searchSellersPaginatedPromise = React.useCallback(
    (description, page = 0, maxItems = null) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/products/searchSellersOfPriceTablePaginated", {
            body: {
              idTabela: Number(selectedPriceTable.tabela.idTabela),
              descricao: description,
              pagina: page,
              tamanho: maxItems || pagination.maxItems,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination, selectedPriceTable]
  );

  const searchSellersPaginatedRecordsPromise = React.useCallback(
    (description, page = 0, maxItems = null) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/products/searchSellersOfPriceTablePaginatedRecords", {
            body: {
              idTabela: Number(selectedPriceTable.tabela.idTabela),
              descricao: description,
              pagina: page,
              tamanho: maxItems || pagination.maxItems,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination, selectedPriceTable]
  );

  const searchSellers = React.useCallback(
    async (description, page = 0) => {
      try {
        searchingSellers.setLoading(true);
        const json = await searchSellersPaginatedPromise(description, page);
        if (json.status === 200) {
          const mapped = json.object.map((seller) => ({
            vendedor: seller,
          }));
          setSellers(mapped);
          window.scrollTo({ top: 0, behavior: "auto" });
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setSellers([]);
        }
      } catch (error) {
        Modal.error(error.message);
      } finally {
        searchingSellers.setLoading(false);
      }
    },
    [Modal, searchSellersPaginatedPromise, searchingSellers]
  );

  const searchTotalRecordsAndSellers = React.useCallback(
    async (description, maxItems = null) => {
      try {
        searchingSellers.setLoading(true);
        const [sellers, totalRecords] = await Promise.all([
          searchSellersPaginatedPromise(description, 0, maxItems),
          searchSellersPaginatedRecordsPromise(description, 0, maxItems),
        ]);
        if (sellers.status === 200 && totalRecords.status === 200) {
          const mapped = sellers.object.map((seller) => ({
            vendedor: seller,
          }));
          setSellers(mapped);
          pagination.setTotalRecords(totalRecords.object.total);
        } else if (sellers.status !== 200) {
          if (sellers.status === 500) {
            Modal.error(sellers.message, sellers.object);
          } else {
            pagination.reset();
            setSellers([]);
          }
        } else {
          if (totalRecords.status === 500) {
            Modal.error(totalRecords.message, totalRecords.object);
          } else {
            setSellers([]);
          }
        }
      } catch (error) {
        Modal.error(error.message);
      } finally {
        pagination.setCurrentPage(1);
        searchingSellers.setLoading(false);
      }
    },
    [Modal, pagination, searchSellersPaginatedPromise, searchSellersPaginatedRecordsPromise, searchingSellers]
  );

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <SellerList
              selectedPriceTable={selectedPriceTable}
              filteredSellers={filteredSellers}
              searchSellers={searchSellers}
              searchTotalRecordsAndSellers={searchTotalRecordsAndSellers}
              searchingSellers={searchingSellers}
              sellers={sellers}
              pagination={pagination}
            />
          }
        />
        <Route
          path="novo"
          element={
            <NewSeller
              selectedPriceTable={selectedPriceTable}
              searchSellers={searchSellers}
              filteredSellers={filteredSellers.value}
            />
          }
        />
      </Routes>
    </div>
  );
}
