import React, { useCallback, useState, useMemo, useEffect } from "react";

import { usePagination } from "../../../../hooks/pagination";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { IExigencia, IUseExigencia, SearchExigenciasResponse } from "./types";
import { Button } from "../../../../components/Form";
import { useNavigate } from "react-router-dom";
import { Pencil } from "phosphor-react";

import styles from "../Lista/Lista.module.css";
import { ITableData } from "../../../../components/Data/XTable";

export function useExigencia(): IUseExigencia {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const [selectedExigencia, setSelectedExigencia] = useState<IExigencia | null>(null);

  const pagination = usePagination(0);
  const searchingExigencias = useLoading();

  const [exigencias, setExigencias] = useState<IExigencia[]>([]);

  const tableData: ITableData = useMemo(() => {
    const data = exigencias.map((exigencia) => ({
      ...exigencia,
      editar: (
        <Button
          type="button"
          variant="edit"
          className={styles.editButton}
          onClick={() => {
            setSelectedExigencia(exigencia);
            navigate("atualizar");
          }}
          data-option-button>
          <Pencil weight="fill" />
        </Button>
      ),
    }));
    return {
      columns: [
        {
          title: "ID",
          objectName: "idExigencia",
          isSorted: false,
          style: { width: ".625rem" },
        },
        {
          title: "EXIGÊNCIA",
          objectName: "nomeExigencia",
          isSorted: false,
        },
        {
          title: "",
          objectName: "editar",
          isSorted: false,
          style: { width: "5rem" },
        },
      ],
      data,
    };
  }, [exigencias, navigate]);

  const searchExigenciasPaginatedPromise = useCallback(
    (descricao: string) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/juridico/searchExigenciasPaginated", {
            body: {
              pagina: 0,
              tamanho: 0,
              descricao: descricao,
              status: "",
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch]
  );

  const searchExigenciasPaginated = useCallback(
    async (descricao: string) => {
      try {
        searchingExigencias.setLoading(true);
        const json = (await searchExigenciasPaginatedPromise(descricao)) as SearchExigenciasResponse;
        if (json.status === 200) {
          setExigencias(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setExigencias([]);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        searchingExigencias.setLoading(false);
      }
    },
    [Modal, searchExigenciasPaginatedPromise, searchingExigencias]
  );

  useEffect(() => {
    searchExigenciasPaginated("");
  }, []); // eslint-disable-line

  return {
    selectedExigencia: {
      value: selectedExigencia,
      setValue: setSelectedExigencia,
    },
    data: {
      exigencias,
      tableData,
    },
    searchs: {
      pagination,
      searchingExigencias: searchingExigencias.isLoading,
      searchExigenciasPaginated,
    },
  };
}
