import { useCallback, useMemo, useRef, useState } from "react";
import { useCustomFetch, useLoading } from "../../../../../../hooks/async";
import { useModal } from "../../../../../../hooks/contexts";
import { ICommissionPercent, IItemPercent } from "./types";
import { useForm, useSelect } from "../../../../../../hooks/form";
import { isValid } from "../../../../../../helpers/validations";

export function useUpdatePercents() {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const modalUpdateCommissionPercentRef = useRef<HTMLDialogElement>(null);

  const [selectedPercent, setSelectedPercent] = useState<IItemPercent | null>(
    null
  );
  const [selectedCommissionPercent, setSelectedCommissionPercent] =
    useState<ICommissionPercent | null>(null);

  const percent = useForm({ required: true });
  const type = useSelect({
    type: "single",
    required: true,
    defaultValue: {
      value: "V",
      label: "Vendedor",
    },
  });

  const typeOptions = useMemo(
    () => [
      { value: "V", label: "Vendedor" },
      { value: "G", label: "Gerente" },
    ],
    []
  );

  const [itemPercentList, setItemPercentList] = useState<IItemPercent[]>([]);
  const [commissionPercentList, setCommissionPercentList] = useState<
    ICommissionPercent[]
  >([]);

  const searchingItemPercents = useLoading();
  const searchingCommissionPercents = useLoading();
  const addingOrUpdatingCommissionPercent = useLoading();

  const resetModalForm = useCallback(() => {
    type.reset();
    percent.reset();
    setSelectedCommissionPercent(null);
  }, [type, percent]);

  const searchCommissionPercents = useCallback(
    async (idItemTabelaVigenciaPercentualPreco: number) => {
      try {
        searchingCommissionPercents.setLoading(true);
        const json = (await customFetch("/products/searchCommissionPercents", {
          body: {
            idItemTabelaVigenciaPercentualPreco,
            status: "A",
          },
        })) as DefaultFetchResponse<any>;
        if (json.status === 200) {
          setCommissionPercentList(json.object);
        } else {
          Modal.error(json.message, json.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        searchingCommissionPercents.setLoading(false);
      }
    },
    [Modal, customFetch, searchingCommissionPercents]
  );

  const toggleSelectedPercent = useCallback(
    (percent: IItemPercent | null) => {
      if (percent === null) {
        setCommissionPercentList([]);
        setSelectedPercent(null);
      } else
        setSelectedPercent((old) => {
          if (old?.id === percent.id) {
            setCommissionPercentList([]);
            return null;
          } else {
            searchCommissionPercents(percent.id);
            return percent;
          }
        });
    },
    [searchCommissionPercents]
  );

  const searchItemPercents = useCallback(
    async (idItemTabelaVigencia: number) => {
      try {
        searchingItemPercents.setLoading(true);
        const json = await customFetch("/products/searchItemPricePercent", {
          body: {
            id: 0,
            idItemTabelaVigencia: idItemTabelaVigencia,
            status: "A",
          },
        });
        if (json.status === 200) {
          setItemPercentList(json.object);
        } else {
          Modal.error(json.message, json.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        searchingItemPercents.setLoading(false);
      }
    },
    [Modal, customFetch, searchingItemPercents]
  );

  const addOrUpdateCommissionPercent = useCallback(
    async (idCommissionPercent: number | null, category: string) => {
      if (!isValid(type, percent)) return;
      try {
        addingOrUpdatingCommissionPercent.setLoading(true);
        const json = await customFetch(
          "/products/registerAndUpdateCommissionPercent",
          {
            body: {
              id: idCommissionPercent ?? 0,
              idItemTabelaVigenciaPercentualPreco: selectedPercent?.id,
              percentual: Number(percent.value),
              cor: selectedPercent?.cor,
              tipo: type.value?.value,
              status: "A",
              categoria: type.value?.value === "V" ? category : null,
            },
          }
        );
        if (json.status === 200) {
          searchCommissionPercents(selectedPercent!.id);
          Modal.success(json.message);
        } else {
          Modal.error(json.message, json.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        addingOrUpdatingCommissionPercent.setLoading(false);
      }
    },
    [
      type,
      percent,
      addingOrUpdatingCommissionPercent,
      customFetch,
      selectedPercent,
      searchCommissionPercents,
      Modal,
    ]
  );

  const removeCommissionPercent = useCallback(
    async (commissionPercent: ICommissionPercent) => {
      const confirm = await Modal.confirm(
        "Deseja mesmo remover este percentual?"
      );
      if (!confirm) return;
      try {
        addingOrUpdatingCommissionPercent.setLoading(true);
        const json = await customFetch(
          "/products/registerAndUpdateCommissionPercent",
          {
            body: {
              id: commissionPercent.id,
              idItemTabelaVigenciaPercentualPreco: selectedPercent?.id,
              percentual: Number(commissionPercent.percentual),
              cor: selectedPercent?.cor,
              tipo: commissionPercent.tipo,
              status: "I",
              categoria: commissionPercent.categoria,
            },
          }
        );
        if (json.status === 200) {
          searchCommissionPercents(selectedPercent!.id);
          Modal.success(json.message);
        } else {
          Modal.error(json.message, json.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        addingOrUpdatingCommissionPercent.setLoading(false);
      }
    },
    [
      addingOrUpdatingCommissionPercent,
      customFetch,
      selectedPercent,
      searchCommissionPercents,
      Modal,
    ]
  );

  return {
    modalUpdateCommissionPercentRef,
    sets: {
      setSelectedPercent,
      setSelectedCommissionPercent,
    },
    data: {
      type,
      percent,
      selectedPercent,
      selectedCommissionPercent,
      itemPercentList,
      commissionPercentList,
      typeOptions,
    },
    methods: {
      searchItemPercents,
      toggleSelectedPercent,
      searchCommissionPercents,
      resetModalForm,
      addOrUpdateCommissionPercent,
      removeCommissionPercent,
    },
    loadings: {
      searchingItemPercents: searchingItemPercents.isLoading,
      searchingCommissionPercents: searchingCommissionPercents.isLoading,
      addingOrUpdatingCommissionPercent:
        addingOrUpdatingCommissionPercent.isLoading,
    },
  };
}
