import React from "react";

export function useInfiniteScroll(
  callback,
  options = {
    root: null,
    rootMargin: "20px",
    threshold: 1.0,
  }
) {
  const elementRef = React.useRef(null);
  const observer = React.useRef(null);

  React.useEffect(() => {
    const element = elementRef.current;

    if (!element) return;

    observer.current?.unobserve(element);

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        callback();
      }
    }, options);

    observer.current.observe(element);

    return () => observer.current?.unobserve(element);
  }, [callback, options]);

  return [elementRef];
}
