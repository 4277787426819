import React from "react";

import { ISolicitation } from "../types";
import { Button, LinkButton } from "../../../../../components/Form";
import { useContractData } from "../../../components/hooks/useContractData";
import { useContractSellers } from "../../../components/hooks/useContractSellers";
import { useContractSupervisors } from "../../../components/hooks/useContractSupervisors";
import { useAnalysis } from "./hooks/useAnalysis";
import { ContractConference } from "../../../components/ContractConference/ContractConference";
import { ContractData } from "../../../components/ContractData/ContractData";
import { ContractSellers } from "../../../components/ContractSellers/ContractSellers";
import { ContractSupervisors } from "../../../components/ContractSupervisors/ContractSupervisors";
import { Circle } from "../../../../../components/Loading";
import { Navigate } from "react-router-dom";
import { ContractTableAnalysis } from "../../../components/ContractTableAnalysis/ContractTableAnalysis";
import { useContractTableAnalysis } from "../../../components/hooks/useContractTableAnalysis";
import { useChanges, useModal } from "../../../../../hooks/contexts";
import { useSelectOptions } from "../../../components/hooks/useSelectOptions";
import { useContractPremiations } from "../../../components/hooks/useContractPremiations";
import { ContractPremiations } from "../../../components/ContractPremiations/ContractPremiations";
import { IUseSolicitationList } from "../hooks/types";
import { SolicitationStatus } from "../../../types";
import {
  ExcelPermissions,
  ExcelType,
  UseContractExcel,
} from "../../../hooks/useContractExcel";

import styles from "./Analysis.module.css";
import { PageType } from "../../../components/types";

interface Props {
  selectedSolicitation: ISolicitation | null;
  solicitationList: IUseSolicitationList;
  contractExcel: UseContractExcel;
}

export function Analysis({
  selectedSolicitation,
  solicitationList,
  contractExcel,
}: Props) {
  const Modal = useModal();
  const ChangesDetected = useChanges();

  const colorOptions = React.useState<ISelectOption<string>[]>([]);

  const contractData = useContractData();
  const contractPremiations = useContractPremiations();
  const contractSellers = useContractSellers();
  const contractSupervisors = useContractSupervisors();
  const contractTable = useContractTableAnalysis();

  const { sellerOptions, supervisorOptions, tableOptions, customerOptions } =
    useSelectOptions();

  const analysis = useAnalysis({
    contractData,
    contractPremiations,
    contractSellers,
    contractSupervisors,
    contractTable,
    solicitationList,
  });

  React.useLayoutEffect(() => {
    if (!selectedSolicitation) return;
    analysis.getSolicitationData(selectedSolicitation.idSolicitacao);
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (!selectedSolicitation) return;
    contractPremiations.getPremiationTypes.handle();
  }, []); // eslint-disable-line

  if (!selectedSolicitation)
    return (
      <Navigate to={"/contratos/gerente/solicitacoes-aguardando-cliente"} />
    );

  return (
    <>
      <div className="container">
        {!analysis.gettingSolicitationData &&
        !contractExcel.exportingExcel &&
        !analysis.savingReview &&
        !analysis.approvingSolicitation &&
        !analysis.disapprovingSolicitation ? (
          <>
            <div className={styles.navContainer}>
              <div>
                <LinkButton
                  to="/contratos/gerente/solicitacoes-aguardando-cliente"
                  buttonStyle="backButton"
                  disabled={analysis.gettingSolicitationData}
                />
              </div>
              <h2>
                {selectedSolicitation.idSolicitacao} |{" "}
                {selectedSolicitation.descricaoContrato}
              </h2>
              <div />
            </div>
            <span className="separator" />
            {analysis.solicitationData ? (
              <div className={styles.conferenceContainer}>
                <ContractConference
                  pages={[
                    {
                      name: "Dados do contrato",
                      page: (
                        <ContractData
                          data={contractData}
                          premiations={contractPremiations}
                          permissions={analysis.permissions}
                          tableOptions={tableOptions}
                          customerOptions={customerOptions}
                          page={PageType.CLIENTE}
                          changesDetected={ChangesDetected}
                        />
                      ),
                    },
                    contractData.payPremiation.value?.value && {
                      name: "Tipos de Premiação",
                      page: (
                        <ContractPremiations
                          data={contractPremiations}
                          page={PageType.CLIENTE}
                          changesDetected={ChangesDetected}
                        />
                      ),
                    },
                    {
                      name: "Gerentes",
                      page: (
                        <ContractSupervisors
                          data={contractSupervisors}
                          sellers={{
                            data: contractSellers,
                            options: sellerOptions,
                          }}
                          supervisorOptions={supervisorOptions}
                          page={PageType.CLIENTE}
                        />
                      ),
                    },
                    {
                      name: "Vendedores",
                      page: (
                        <ContractSellers
                          data={contractSellers}
                          supervisors={contractSupervisors.supervisors.value.map(
                            (i) => i.value.id
                          )}
                          sellerOptions={sellerOptions}
                          page={PageType.CLIENTE}
                        />
                      ),
                    },
                    {
                      name: "Formulado do Contrato",
                      page: (
                        <ContractTableAnalysis
                          data={contractTable}
                          table={contractData.table}
                          colorOptions={colorOptions}
                          interestCalculated={
                            contractData.interestCalculated[0]
                          }
                          calculatingInterest={
                            contractData.changeInterestRelated
                              .calculatingInterest
                          }
                          disabled
                          disabledRanges
                        />
                      ),
                    },
                  ]}
                />
                <div className={styles.decisionContainer}>
                  {selectedSolicitation.status.toString() ===
                    SolicitationStatus.Pendente && (
                    <>
                      <Button
                        variant="danger"
                        onClick={async () => {
                          if (
                            !(await Modal.confirm(
                              "Tem certeza que deseja recusar o contrato?"
                            ))
                          )
                            return;
                          analysis.handleSubmit.disapprove();
                        }}
                      >
                        Recusar
                      </Button>
                      {contractExcel.verifyPermission(
                        ExcelPermissions.CLIENTE
                      ) && (
                        <Button
                          variant="neutral"
                          onClick={() => {
                            contractExcel.exportExcel(
                              selectedSolicitation.idSolicitacao,
                              ExcelType.MODELO_CLIENTE
                            );
                          }}
                          className={styles.exportExcelButton}
                        >
                          Exportar Faixa Padrão Para Excel
                        </Button>
                      )}
                      <Button
                        onClick={async () => {
                          if (
                            !(await Modal.confirm(
                              "Tem certeza que deseja aprovar o contrato?"
                            ))
                          )
                            return;
                          analysis.handleSubmit.approve();
                        }}
                      >
                        Aprovar
                      </Button>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <p className={`lineCardMessage ${styles.withoutDataMessage}`}>
                Nenhum dado foi encontrado
              </p>
            )}
          </>
        ) : analysis.gettingSolicitationData ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
            <p className="loadingMessage">Carregando Dados</p>
          </div>
        ) : contractExcel.exportingExcel ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
            <p className="loadingMessage">Exportando Para Excel</p>
          </div>
        ) : analysis.savingReview ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
            <p className="loadingMessage">Salvando Revisão</p>
          </div>
        ) : (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
            <p className="loadingMessage">
              {analysis.approvingSolicitation ? "Aprovando" : "Recusando"}{" "}
              Solicitação
            </p>
          </div>
        )}
      </div>
    </>
  );
}
