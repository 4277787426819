import React from "react";

import { ISolicitation } from "../types";
import { useContractData } from "../../components/hooks/useContractData";
import { useContractSellers } from "../../components/hooks/useContractSellers";
import { useContractSupervisors } from "../../components/hooks/useContractSupervisors";
import { useAnalysis } from "./hooks/useAnalysis";
import { ContractConference } from "../../components/ContractConference/ContractConference";
import { ContractData } from "../../components/ContractData/ContractData";
import { ContractSellers } from "../../components/ContractSellers/ContractSellers";
import { ContractSupervisors } from "../../components/ContractSupervisors/ContractSupervisors";
import { Circle } from "../../../../components/Loading";
import { Navigate } from "react-router-dom";
import { ContractTableAnalysis } from "../../components/ContractTableAnalysis/ContractTableAnalysis";
import { useContractTableAnalysis } from "../../components/hooks/useContractTableAnalysis";
import { useChanges } from "../../../../hooks/contexts";
import { useSelectOptions } from "../../components/hooks/useSelectOptions";
import { useContractPremiations } from "../../components/hooks/useContractPremiations";
import { ContractPremiations } from "../../components/ContractPremiations/ContractPremiations";
import { IUseSolicitationList } from "../hooks/types";

import styles from "./Analysis.module.css";
import { PageType } from "../../components/types";

interface Props {
  selectedSolicitation: ISolicitation | null;
  solicitationList: IUseSolicitationList;
}

export function Analysis({ selectedSolicitation, solicitationList }: Props) {
  const ChangesDetected = useChanges();

  const colorOptions = React.useState<ISelectOption<string>[]>([]);

  const contractData = useContractData();
  const contractPremiations = useContractPremiations();
  const contractSellers = useContractSellers();
  const contractSupervisors = useContractSupervisors();
  const contractTable = useContractTableAnalysis();

  const { sellerOptions, supervisorOptions, tableOptions, customerOptions } =
    useSelectOptions();

  const analysis = useAnalysis({
    contractData,
    contractPremiations,
    contractSellers,
    contractSupervisors,
    contractTable,
    solicitationList,
  });

  React.useLayoutEffect(() => {
    if (!selectedSolicitation) return;
    analysis.getSolicitationData(selectedSolicitation.idSolicitacao);
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (!selectedSolicitation) return;
    contractPremiations.getPremiationTypes.handle();
  }, []); // eslint-disable-line

  if (!selectedSolicitation)
    return (
      <Navigate to={"/contratos/gerente/solicitacoes-aguardando-cliente"} />
    );

  return (
    <>
      <div>
        {!analysis.gettingSolicitationData &&
        !analysis.savingReview &&
        !analysis.approvingSolicitation &&
        !analysis.disapprovingSolicitation ? (
          <>
            {analysis.solicitationData ? (
              <div className={styles.conferenceContainer}>
                <ContractConference
                  pages={[
                    {
                      name: "Dados do contrato",
                      page: (
                        <ContractData
                          data={contractData}
                          premiations={contractPremiations}
                          tableOptions={tableOptions}
                          customerOptions={customerOptions}
                          page={PageType.HISTORICO}
                          changesDetected={ChangesDetected}
                        />
                      ),
                    },
                    contractData.payPremiation.value?.value && {
                      name: "Tipos de Premiação",
                      page: (
                        <ContractPremiations
                          data={contractPremiations}
                          page={PageType.HISTORICO}
                          changesDetected={ChangesDetected}
                        />
                      ),
                    },
                    {
                      name: "Gerentes",
                      page: (
                        <ContractSupervisors
                          data={contractSupervisors}
                          sellers={{
                            data: contractSellers,
                            options: sellerOptions,
                          }}
                          supervisorOptions={supervisorOptions}
                          page={PageType.HISTORICO}
                        />
                      ),
                    },
                    {
                      name: "Vendedores",
                      page: (
                        <ContractSellers
                          data={contractSellers}
                          supervisors={contractSupervisors.supervisors.value.map(
                            (i) => i.value.id
                          )}
                          sellerOptions={sellerOptions}
                          page={PageType.HISTORICO}
                        />
                      ),
                    },
                    {
                      name: "Formulado do Contrato",
                      page: (
                        <ContractTableAnalysis
                          data={contractTable}
                          table={contractData.table}
                          colorOptions={colorOptions}
                          interestCalculated={
                            contractData.interestCalculated[0]
                          }
                          calculatingInterest={
                            contractData.changeInterestRelated
                              .calculatingInterest
                          }
                          disabled
                          disabledRanges
                        />
                      ),
                    },
                  ]}
                />
              </div>
            ) : (
              <p className={`lineCardMessage ${styles.withoutDataMessage}`}>
                Nenhum dado foi encontrado
              </p>
            )}
          </>
        ) : analysis.gettingSolicitationData ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
            <p className="loadingMessage">Carregando Dados</p>
          </div>
        ) : analysis.savingReview ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
            <p className="loadingMessage">Salvando Revisão</p>
          </div>
        ) : (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
            <p className="loadingMessage">
              {analysis.approvingSolicitation ? "Aprovando" : "Recusando"}{" "}
              Solicitação
            </p>
          </div>
        )}
      </div>
    </>
  );
}
