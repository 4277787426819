import React from "react";

import { Button, Input, LinkButton, Select } from "../../../../../../components/Form";
import { IItem } from "../../CommissionTableItems/CommissionTableItems";

import styles from "./InsertAndUpdatePeoples.module.css";
import { IContractComission } from "../../ContractCommissionsTable";
import { CaretLeft, CaretRight, X } from "phosphor-react";
import { useCustomFetch, useLoading } from "../../../../../../hooks/async";
import { useModal } from "../../../../../../hooks/contexts";
import { useNavigate } from "react-router-dom";
import { IItemPeople } from "../UpdateItemPeoples";
import { Circle } from "../../../../../../components/Loading";
import { useSelect } from "../../../../../../hooks/form";

interface ISeller {
  idUsuario: number;
  nome: string;
}

interface IParticipant {
  id: number;
  idUsuario: number;
  nome: string;
  comissao: number | string;
  isSeller: boolean;
}

interface IParticipantOption {
  value: IParticipant;
  label: string;
}

interface ISellerOption {
  value: ISeller;
  label: string;
}

interface IRange {
  index: number;
  idFaixaPrecoproduto: number;
  perMaximo: number;
  comissaoValor: number;
  rgb: string;
  participantes: IParticipantOption[];
}

interface ISearchItemPeoplesResponse {
  response: number;
  message: string;
  status: number;
  object: IItemPeople[];
}

interface ISearchParticipantOptionsResponse {
  response: number;
  message: string;
  status: number;
  object: IParticipant[];
}

interface IInsertPeoplesResponse {
  response: number;
  message: string;
  status: number;
  object: any;
}

interface IProps {
  selectedItem: IItem | null;
  selectedTable: IContractComission | null;
  setItemPeoples: React.Dispatch<React.SetStateAction<IItemPeople[]>>;
}

export function InsertAndUpdatePeoples({ selectedItem, selectedTable, setItemPeoples }: IProps) {
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();

  const memorizedParticipantsRef = React.useRef<{ [key: string]: { id: any; idUsuario: any }[] }>({});

  const [rangeList, setRangeList] = React.useState<IRange[]>([]);
  const [selectedRangeIndex, setSelectedRangeIndex] = React.useState<number>(0);

  const [sellerOptions, setSellerOptions] = React.useState<ISellerOption[]>([]);
  const [participantOptions, setParticipantsOptions] = React.useState<IParticipantOption[]>([]);

  const seller = useSelect({ type: "single", required: true });

  const searchingParticipantOptions = useLoading();
  const searchingItemPeoples = useLoading();
  const insertingPeoples = useLoading();

  const onSelectSeller = React.useCallback(
    (value: ISellerOption) => {
      const updateRangeList = JSON.parse(JSON.stringify(rangeList));
      if (value !== null) {
        updateRangeList.forEach((item: IRange) => {
          item.participantes = [
            {
              value: {
                ...value.value,
                id: 0,
                comissao: "0",
                isSeller: true,
              },
              label: value.label,
            },
          ];
        });
      } else {
        updateRangeList.forEach((item: IRange) => {
          item.participantes = [];
        });
      }
      setRangeList(JSON.parse(JSON.stringify(updateRangeList)));
      seller.setValue(value);
    },
    [rangeList, seller]
  );

  const calcAvailableCommission = React.useCallback((commissionValue: number, participants: IParticipantOption[]) => {
    const totalCommissions = Number(
      participants
        .reduce((acc, crr) => {
          return acc + Number(crr.value.comissao);
        }, 0)
        .toFixed(3)
    );
    return (commissionValue - totalCommissions).toFixed(3);
  }, []);

  const onSelectRange = React.useCallback((range: IRange) => {
    setSelectedRangeIndex(range.index);
  }, []);

  const onSelectRangeByArrow = React.useCallback(
    (direction: "right" | "left") => {
      if (direction === "left") {
        if (selectedRangeIndex === 0) {
          const lastIndex = rangeList.length - 1;
          setSelectedRangeIndex(lastIndex);
        } else {
          setSelectedRangeIndex((old) => old - 1);
        }
      } else {
        const lastIndex = rangeList.length - 1;
        if (selectedRangeIndex === lastIndex) {
          setSelectedRangeIndex(0);
        } else {
          setSelectedRangeIndex((old) => old + 1);
        }
      }
    },
    [rangeList, selectedRangeIndex]
  );

  const onSelectParticipants = React.useCallback(
    (rangeList: IRange[], participants: IParticipantOption[]) => {
      // Caso for necessário voltar: remover o for e substituir 'i' por 'selectedRangeIndex'
      const updateRangeList = JSON.parse(JSON.stringify(rangeList));
      const rangeListLength: number = updateRangeList.length;
      if (participants.length > updateRangeList[selectedRangeIndex].participantes.slice(1).length) {
        for (let i = 0; i < rangeListLength; i++) {
          const participantAdded = participants.filter(
            (item1) =>
              !updateRangeList[i].participantes.some((item2: any) => item2.value.idUsuario === item1.value.idUsuario)
          )[0];
          let id = 0;
          if (memorizedParticipantsRef.current[`${i}`]) {
            const participantOfAshes = memorizedParticipantsRef.current[`${i}`].find(
              (item: any) => Number(item.idUsuario) === Number(participantAdded.value.idUsuario)
            );
            if (participantOfAshes) {
              id = participantOfAshes.id;
            }
          }
          updateRangeList[i].participantes = JSON.parse(
            JSON.stringify([
              ...updateRangeList[i].participantes,
              { value: { ...participantAdded.value, id }, label: participantAdded.label },
            ])
          );
        }
      } else {
        for (let i = 0; i < rangeListLength; i++) {
          const remainingParticipants = updateRangeList[i].participantes.filter((item1: any) =>
            participants.some((item2) => item2.value.idUsuario === item1.value.idUsuario)
          );
          remainingParticipants.unshift(updateRangeList[i].participantes[0]);
          updateRangeList[i].participantes = JSON.parse(JSON.stringify([...remainingParticipants]));
        }
      }

      setRangeList(updateRangeList);
    },
    [selectedRangeIndex]
  );

  const onRemoveParticipant = React.useCallback((rangeList: IRange[], participant: IParticipant) => {
    // Caso for necessário voltar: remover o for e substituir 'i' por 'selectedRangeIndex'
    const rangeListLength = rangeList.length;
    for (let i = 0; i < rangeListLength; i++) {
      const participantList = rangeList[i].participantes.filter(
        (item1) => item1.value.idUsuario !== participant.idUsuario
      );
      rangeList[i].participantes = JSON.parse(JSON.stringify(participantList));
    }
    setRangeList(JSON.parse(JSON.stringify(rangeList)));
  }, []);

  const onChangeParticipantCommission = React.useCallback(
    (type: "commission" | "seller", participant: IParticipantOption, value: string | boolean) => {
      if (type === "commission") {
        participant.value.comissao = value as string;
      } else {
        rangeList[selectedRangeIndex].participantes.forEach((item) => {
          item.value.isSeller = false;
        });
        participant.value.isSeller = value as boolean;
      }
      setRangeList(JSON.parse(JSON.stringify(rangeList)));
    },
    [rangeList, selectedRangeIndex]
  );

  const onTabPress = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>, length: number, index: number) => {
      if (event.key === "Tab" && index === length - 1) {
        event.preventDefault();
        onSelectRangeByArrow("right");
      }
    },
    [onSelectRangeByArrow]
  );

  const searchParticipantOptions = React.useCallback(
    async (description: string = "") => {
      try {
        searchingParticipantOptions.setLoading(true);
        const json = (await customFetch("/commissions/contracts/searchContractPeoples", {
          body: { descricao: description },
        })) as ISearchParticipantOptionsResponse;
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            label: `${option.idUsuario} | ${option.nome}`,
            value: {
              ...option,
              comissao: 0,
              isSeller: false,
            },
          }));
          setParticipantsOptions(options);
          setSellerOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setParticipantsOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingParticipantOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingParticipantOptions]
  );

  const searchItemPeoples = React.useCallback(
    async (seller: ISellerOption, tableId: number, formulaId: number) => {
      memorizedParticipantsRef.current = {};
      try {
        searchingItemPeoples.setLoading(true);
        const json = (await customFetch("/commissions/contracts/searchContractPeopleItems", {
          body: {
            idEmpresa: selectedTable?.empresa.idEmpresa,
            idVendedor: [seller.value.idUsuario],
            idContrato: tableId,
            idFormulado: formulaId,
          },
        })) as ISearchItemPeoplesResponse;

        if (json.status === 200) {
          const jsonRanges = json.object;
          const finalRanges: IRange[] = [];
          selectedItem?.faixaPrecoProduto.forEach((range, index) => {
            const correspondingRanges = jsonRanges[0].faixaProduto.filter(
              (item) => Number(item.idFaixaPrecoProduto) === Number(range.idFaixaPrecoProduto)
            );
            if (correspondingRanges.length) {
              finalRanges.push({
                index: index,
                idFaixaPrecoproduto: Number(range.idFaixaPrecoProduto),
                perMaximo: Number(range.perMaximo),
                comissaoValor: Number(range.perComissao),
                rgb: String(range.rgb),
                participantes: correspondingRanges[0].faixaComissao.map((item) => ({
                  value: {
                    id: item.idFaixaComissao,
                    idUsuario: Number(item.idPessoa),
                    nome: item.nomePessoa,
                    comissao: String(item.percentual),
                    isSeller: item.donoFaixa === "S",
                  },
                  label: `${item.idPessoa} | ${item.nomePessoa}`,
                })),
              });

              memorizedParticipantsRef.current[`${index}`] = JSON.parse(
                JSON.stringify(
                  correspondingRanges[0].faixaComissao.map((item) => ({
                    id: item.idFaixaComissao,
                    idUsuario: Number(item.idPessoa),
                  }))
                )
              );
            } else {
              finalRanges.push({
                index: index,
                idFaixaPrecoproduto: Number(range.idFaixaPrecoProduto),
                perMaximo: Number(range.perMaximo),
                comissaoValor: Number(range.perComissao),
                rgb: String(range.rgb),
                participantes: [
                  {
                    value: {
                      id: 0,
                      idUsuario: Number(seller.value.idUsuario),
                      nome: seller.value.nome,
                      comissao: String(0),
                      isSeller: true,
                    },
                    label: `${seller.value.idUsuario} | ${seller.value.nome}`,
                  },
                ],
              });
            }
          });
          setRangeList(JSON.parse(JSON.stringify(finalRanges)));
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
          setRangeList([]);
        } else {
          const initialRanges = selectedItem?.faixaPrecoProduto.map((item, index) => ({
            index: index,
            idFaixaPrecoproduto: item.idFaixaPrecoProduto,
            perMaximo: item.perMaximo,
            comissaoValor: item.perComissao,
            rgb: item.rgb,
            participantes: [
              {
                value: {
                  id: 0,
                  idUsuario: Number(seller.value.idUsuario),
                  nome: seller.value.nome,
                  comissao: String(0),
                  isSeller: true,
                },
                label: `${seller.value.idUsuario} | ${seller.value.nome}`,
              },
            ],
          }));

          if (initialRanges !== undefined) {
            setRangeList(initialRanges);
          }
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingItemPeoples.setLoading(false);
      }
    },
    [Modal, customFetch, searchingItemPeoples, selectedItem, selectedTable]
  );

  const insertAndUpdatePeoples = React.useCallback(
    async (rangeList: IRange[]) => {
      const body: {
        id: number;
        idVendedor: number;
        idPessoa: number;
        idFaixa: number;
        percentual: string | number;
      }[] = [];
      for (let i = 0; i < rangeList.length; i++) {
        if (rangeList[i].participantes.length) {
          const sellerId = rangeList[i].participantes.find((participant) => participant.value.isSeller === true)?.value
            .idUsuario;
          if (sellerId === undefined) {
            Modal.error(
              `A faixa com ${selectedItem?.tipoComissao === "Des" ? "desconto" : "margem"} de ${
                rangeList[i].perMaximo
              }% e comissão de ${rangeList[i].comissaoValor}% não possui nenhum participante definido como vendedor!`
            );
            return;
          }
          const totalCommission = rangeList[i].participantes.reduce((acc, crr) => {
            return acc + Number(crr.value.comissao);
          }, 0);
          if (Number(totalCommission.toFixed(3)) > Number(rangeList[i].comissaoValor.toFixed(3))) {
            Modal.error(
              `A comissão total dos participantes da faixa com ${
                selectedItem?.tipoComissao === "Des" ? "desconto" : "margem"
              } de ${rangeList[i].perMaximo}% e comissão de ${
                rangeList[i].comissaoValor
              }% ultrapassou o total disponível de comissão!`
            );
            return;
          } else if (Number(totalCommission.toFixed(3)) < Number(rangeList[i].comissaoValor.toFixed(3))) {
            Modal.error(
              `A comissão da faixa com ${selectedItem?.tipoComissao === "Des" ? "desconto" : "margem"} de ${
                rangeList[i].perMaximo
              }% e comissão de ${rangeList[i].comissaoValor}% ainda não foi totalmente distribuída!`
            );
            return;
          }
          const rangeId = rangeList[i].idFaixaPrecoproduto;
          rangeList[i].participantes.forEach((participant) => {
            body.push({
              id: participant.value.id,
              idVendedor: sellerId,
              idPessoa: participant.value.idUsuario,
              idFaixa: rangeId,
              percentual: Number(participant.value.comissao),
            });
          });
        }
      }
      try {
        insertingPeoples.setLoading(true);
        const json = (await customFetch("/commissions/contracts/insertAndUpdatePeoplesInContractRange", {
          headers: undefined,
          body: body,
        })) as IInsertPeoplesResponse;
        if (json.status === 200) {
          setItemPeoples([]);
          await Modal.success(json.message);
          navigate("/comissoes/adubo/comissoes-de-contratos/itens/pessoas");
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        insertingPeoples.setLoading(false);
      }
    },
    [Modal, customFetch, insertingPeoples, navigate, selectedItem, setItemPeoples]
  );

  React.useEffect(() => {
    const inputs = document.querySelectorAll(
      "[data-commission-list] [data-commission-input]"
    ) as NodeListOf<HTMLInputElement>;
    if (inputs.length) {
      inputs[0].focus();
      inputs[0].select();
    }
  }, [selectedRangeIndex]);

  React.useEffect(() => {
    searchParticipantOptions("");
  }, []); // eslint-disable-line

  return (
    <div className="container">
      {!insertingPeoples.isLoading ? (
        <>
          <div className={styles.navigationContainer}>
            <LinkButton buttonStyle="backButton" to="/comissoes/adubo/comissoes-de-contratos/itens/pessoas" />
            <h2>
              {selectedTable?.descricao} - Fórmula {selectedItem?.formula.descricaoFormulado}
            </h2>
            <div></div>
          </div>
          <span className={styles.separator}></span>
          <div>
            <label htmlFor="seller" className="label">
              Vendedor
            </label>
            <Select
              id="seller"
              placeholder="Selecione um vendedor"
              options={sellerOptions}
              value={seller.value}
              onChange={(value: ISellerOption) => {
                if (value !== null) {
                  searchItemPeoples(value, selectedTable!.idContrato, selectedItem!.idFormulado);
                }
                onSelectSeller(value);
              }}
              defaultValue={undefined}
              error={seller.error}
              isLoading={searchingParticipantOptions.isLoading}
            />
          </div>
          {!searchingItemPeoples.isLoading ? (
            <>
              <div className={styles.afterSellerContainer}>
                {seller.value ? (
                  <>
                    <div>
                      <div className={styles.rangeContainer}>
                        {rangeList.map((range, rangeIndex) => (
                          <div
                            className={`${styles.commissionBlock} ${
                              rangeList[selectedRangeIndex].idFaixaPrecoproduto === range.idFaixaPrecoproduto
                                ? styles.isActive
                                : ""
                            }`}
                            key={range.index}
                            onClick={() => {
                              onSelectRange(range);
                            }}>
                            <p>
                              {selectedItem?.tipoComissao}. {range.perMaximo}%
                            </p>
                            <div className={styles.ball}>
                              <div style={{ backgroundColor: range.rgb }}></div>
                              <span style={{ backgroundColor: range.rgb }}></span>
                            </div>
                            <p>Com. {range.comissaoValor}%</p>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className={styles.commissionsMainContainer}>
                      <div className={styles.navigateRangeContainer}>
                        <button
                          className={styles.arrowButton}
                          onClick={() => {
                            onSelectRangeByArrow("left");
                          }}>
                          <CaretLeft size={"3rem"} weight="fill" />
                        </button>
                        <div className={styles.selectedRangeContainer}>
                          <p className={`label ${styles.selectedRangeContainer__text}`}>Comissão Disponível</p>
                          <div
                            className={styles.selectedRangeContainer__ball}
                            style={{ borderColor: rangeList.length ? rangeList[selectedRangeIndex].rgb : undefined }}>
                            <p
                              style={{
                                color: rangeList.length
                                  ? Number(
                                      calcAvailableCommission(
                                        Number(rangeList[selectedRangeIndex].comissaoValor.toFixed(3)),
                                        rangeList[selectedRangeIndex].participantes
                                      )
                                    ) < 0
                                    ? "#FF0000"
                                    : ""
                                  : "",
                              }}>
                              {rangeList.length
                                ? calcAvailableCommission(
                                    Number(rangeList[selectedRangeIndex].comissaoValor.toFixed(3)),
                                    rangeList[selectedRangeIndex].participantes
                                  )
                                : "0"}
                              %
                            </p>
                          </div>
                        </div>
                        <button
                          className={styles.arrowButton}
                          onClick={() => {
                            onSelectRangeByArrow("right");
                          }}>
                          <CaretRight size={"3rem"} weight="fill" />
                        </button>
                      </div>
                      <div className={styles.participantsFieldContainer}>
                        <div>
                          <label htmlFor="participants" className="label">
                            Participantes
                          </label>
                          <Select
                            id="participants"
                            placeholder="Selecione os outros participantes"
                            options={participantOptions.filter(
                              (item1) =>
                                !rangeList[selectedRangeIndex].participantes.some(
                                  (item2) => item2.value.idUsuario === item1.value.idUsuario
                                )
                            )}
                            value={rangeList.length ? rangeList[selectedRangeIndex].participantes.slice(1) : null}
                            onChange={(value: any) => {
                              onSelectParticipants(rangeList as IRange[], [...value]);
                            }}
                            defaultValue={undefined}
                            error={undefined}
                            isMulti
                            isLoading={searchingParticipantOptions.isLoading}
                          />
                        </div>
                      </div>
                      <div className={styles.commissionContainer} data-commission-list>
                        {rangeList.length && rangeList[selectedRangeIndex].participantes.length ? (
                          rangeList[selectedRangeIndex].participantes.map((item, index, array) => (
                            <div className={styles.participantContainer} key={index}>
                              <div>
                                <div className={styles.participantContainer__nameContainer}>
                                  <p className={styles.participantContainer__participantName}>{item.label}</p>
                                  <button
                                    tabIndex={-1}
                                    className={styles.participantContainer__removeParticipantButton}
                                    style={{ visibility: item.value.isSeller ? "hidden" : "visible" }}
                                    onClick={() => {
                                      onRemoveParticipant(rangeList, item.value);
                                    }}
                                    disabled={item.value.isSeller}>
                                    <X size={"2rem"} color="#a7a5a5" weight="bold" />
                                  </button>
                                </div>
                                <span className={styles.participantContainer__participantNameSeparator}></span>
                              </div>
                              <div className={styles.participantContainer__fieldsContainer}>
                                <div>
                                  <label className="label">Comissão</label>
                                  <Input
                                    data-commission-input={item.label}
                                    type="number"
                                    className="cleanInputNumber"
                                    value={item.value.comissao as string}
                                    onChange={({ target }) => {
                                      onChangeParticipantCommission("commission", item, target.value);
                                    }}
                                    onKeyDown={(event) => {
                                      onTabPress(event, array.length, index);
                                    }}
                                    onWheel={(event) => {
                                      event.preventDefault();
                                      event.currentTarget.blur();
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <span className={`lineCardMessage ${styles.participantsMessage}`}>
                            Selecione as pessoas para adicionar à faixa selecionada
                          </span>
                        )}
                      </div>
                    </div>
                    <div className={styles.concludeButtonContainer}>
                      <Button
                        onClick={() => {
                          insertAndUpdatePeoples(rangeList as IRange[]);
                        }}
                        disabled={!rangeList?.some((range) => range.participantes.length)}>
                        Concluir Alterações
                      </Button>
                    </div>
                  </>
                ) : (
                  <span className={`lineCardMessage ${styles.participantsMessage}`}>
                    Selecione um vendedor para prosseguir
                  </span>
                )}
              </div>
            </>
          ) : (
            <div className={`loadingContainer ${styles.loadingContainer}`}>
              <Circle size={100} />
            </div>
          )}
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Inserindo novas pessoas</span>
        </div>
      )}
    </div>
  );
}
