export async function downloadResponseFile({ file, type, fileName }) {
  const blob = new Blob([file], { type: type });

  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);

  // window.URL.revokeObjectURL(url);
}
