import React from "react";
import { TModalErrorDetails, TModalMessage, TModalOpened, TModalOnContinue, Modal } from "./components/Modal";

interface IModalContext {
  /**
   * Fecha o modal aberto
   */
  close: () => void;
  /**
   * Abre um modal de confirmação e retorna uma Promise que informa
   * se foi confirmado ou recusado
   *
   * @param {TModalMessage} message - Mensagem que será exibida no modal
   * @returns Retorna uma Promise contendo um boolean (confirmado ou recusado)
   */
  confirm: (message: TModalMessage) => Promise<boolean>;
  /**
   * Abre um modal de sucesso e retorna uma Promise
   * quando o botão 'OK' é clicado
   *
   * @param {TModalMessage} message - Mensagem que será exibida no modal
   * @returns Retorna uma Promise contendo true
   */
  success: (message: TModalMessage) => Promise<boolean>;
  /**
   * Abre um modal de erro e retorna uma Promise
   * quando o botão 'OK' é clicado
   *
   * @param {TModalMessage} message - Mensagem que será exibida no modal
   * @param {any} details - Detalhes do erro recebido
   * @returns Retorna uma Promise contendo true
   */
  error: (message: TModalMessage, detail?: TModalErrorDetails) => Promise<boolean>;
  /**
   * Abre um modal de alerta e retorna uma Promise
   * quando o botão 'OK' é clicado
   *
   * @param {TModalMessage} message - Mensagem que será exibida no modal
   * @returns Retorna uma Promise contendo true
   */
  alert: (message: TModalMessage) => Promise<boolean>;
}

interface IModalProviderProps {
  children: React.ReactNode;
}

const ModalContext = React.createContext<IModalContext>({
  confirm() {
    return new Promise((res) => res(true));
  },
  success() {
    return new Promise((res) => res(true));
  },
  error() {
    return new Promise((res) => res(true));
  },
  alert() {
    return new Promise((res) => res(true));
  },
  close() {},
});

const ModalProvider = ({ children }: IModalProviderProps) => {
  const [modalOpened, setModalOpened] = React.useState<TModalOpened>("");
  const [message, setMessage] = React.useState<TModalMessage>("");
  const [onContinue, setOnContinue] = React.useState<TModalOnContinue>(() => new Promise((res) => res(true)));
  const [onCancel, setOnCancel] = React.useState<TModalOnContinue>(() => new Promise((res) => res(false)));
  const [errorDetails, setErrorDetails] = React.useState<TModalErrorDetails>(null);

  const close = React.useCallback(() => {
    setModalOpened("");
    setOnContinue(() => new Promise<boolean>((res) => res(true)));
    setOnCancel(() => new Promise<boolean>((res) => res(false)));
    setErrorDetails(null);
  }, []);

  const confirm = React.useCallback(
    (message: TModalMessage) => {
      close();
      setMessage(message);
      setModalOpened("confirm");
      return new Promise<boolean>((resolve) => {
        setOnContinue(() => {
          return function () {
            close();
            resolve(true);
          };
        });
        setOnCancel(() => {
          return function () {
            close();
            resolve(false);
          };
        });
      });
    },
    [close]
  );

  const success = React.useCallback(
    (message: TModalMessage) => {
      close();
      setMessage(message);
      setModalOpened("success");
      return new Promise<boolean>((resolve) => {
        setOnContinue(() => {
          return function () {
            close();
            resolve(true);
          };
        });
      });
    },
    [close]
  );

  const error = React.useCallback(
    (message: TModalMessage, details: TModalErrorDetails) => {
      close();
      setMessage(message);
      setModalOpened("error");
      setErrorDetails(details);
      return new Promise<boolean>((resolve) => {
        setOnContinue(() => {
          return function () {
            close();
            resolve(true);
          };
        });
      });
    },
    [close]
  );

  const alert = React.useCallback(
    (message: TModalMessage) => {
      close();
      setMessage(message);
      setModalOpened("alert");
      return new Promise<boolean>((resolve) => {
        setOnContinue(() => {
          return function () {
            close();
            resolve(true);
          };
        });
      });
    },
    [close]
  );

  return (
    <ModalContext.Provider value={{ success, confirm, error, alert, close }}>
      <Modal
        modalOpened={modalOpened}
        message={message}
        onContinue={onContinue}
        onCancel={onCancel}
        errorDetails={errorDetails}
      />
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
