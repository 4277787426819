import React from "react";

import { useCustomFetch, useDebounce, useLoading } from "../../../../../../hooks/async";
import { useModal } from "../../../../../../hooks/contexts";

import { Button, Input, LinkButton } from "../../../../../../components/Form";
import { Table, TBody, Td, Th, THead, Tr } from "../../../../../../components/Data/Table";

import { Circle } from "../../../../../../components/Loading";

import styles from "./SupervisorList.module.css";

export function SupervisorList({
  selectedPriceTable,
  filteredSupervisors,
  searchSupervisors,
  searchingSupervisors,
  supervisors,
}) {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const togglingSupervisorStatus = useLoading();

  const { current: searchSupervisorsDebounced } = React.useRef(
    useDebounce((description) => {
      searchSupervisors(description);
    })
  );

  const toggleSupervisorStatus = React.useCallback(
    async (supervisorId, currentStatus) => {
      try {
        togglingSupervisorStatus.setLoading(true);
        const json = await customFetch("/products/registerAndUpdateSupervisorOfPriceTable", {
          body: {
            idTabela: Number(selectedPriceTable.tabela.idTabela),
            idSupervisor: Number(supervisorId),
            status: !currentStatus,
          },
        });
        if (json.status === 200) {
          Modal.success(json.message);
          searchSupervisors(filteredSupervisors.value);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        togglingSupervisorStatus.setLoading(false);
      }
    },
    [Modal, customFetch, filteredSupervisors, searchSupervisors, selectedPriceTable, togglingSupervisorStatus]
  );

  React.useEffect(() => {
    if (!supervisors.length) searchSupervisors();
  }, []); // eslint-disable-line

  return (
    <div>
      <div className={styles.navigationContainer}>
        <LinkButton to="novo">Adicionar Supervisor</LinkButton>
      </div>
      <div className={styles.filterContainer}>
        <div>
          <label htmlFor="filteredSupervisors" className="label">
            Filtrar Supervisores
          </label>
          <Input
            id="filteredSupervisors"
            placeholder="Digite o nome ou o id do supervisor para filtrar"
            value={filteredSupervisors.value}
            onChange={({ target }) => {
              filteredSupervisors.setValue(target.value);
              searchSupervisorsDebounced(target.value);
            }}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        {supervisors.length && !searchingSupervisors.isLoading && !togglingSupervisorStatus.isLoading ? (
          <Table title="Supervisores na Tabela" className={styles.tableClients}>
            <THead>
              <Tr>
                <Th>ID</Th>
                <Th>Nome</Th>
                <Th width="8.75rem"></Th>
              </Tr>
            </THead>
            <TBody>
              {supervisors.map((supervisor) => {
                return (
                  <Tr key={supervisor.supervisores.idSupervisor}>
                    <Td heading="ID">{supervisor.supervisores.idSupervisor}</Td>
                    <Td heading="Nome">{`${supervisor.supervisores.nomeSupervisor}${
                      !(supervisor.supervisores.situacao === "A") ? " - INATIVADO" : ""
                    }`}</Td>
                    <Td heading={`${supervisor.tabela.status === "A" ? "Desativar" : "Ativar"}`} data-option>
                      {supervisor.tabela.status === "A" ? (
                        <Button
                          className={styles.deleteClientButton}
                          variant="danger"
                          onClick={() => {
                            const status = supervisor.tabela.status === "A";
                            toggleSupervisorStatus(supervisor.supervisores.idSupervisor, status);
                          }}
                          data-option-button
                        >
                          <span>Desativar</span>
                        </Button>
                      ) : (
                        <Button
                          className={styles.deleteClientButton}
                          onClick={() => {
                            const status = supervisor.tabela.status === "A";
                            toggleSupervisorStatus(supervisor.supervisores.idSupervisor, status);
                          }}
                          data-option-button
                        >
                          <span>Ativar</span>
                        </Button>
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </TBody>
          </Table>
        ) : searchingSupervisors.isLoading || togglingSupervisorStatus.isLoading ? (
          <div className={`loadingContainer`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhum supervisor encontrado</p>
        )}
      </div>
    </div>
  );
}
