import React from "react";
import { sleep } from "../helpers/sleep";
import { useModal } from "../hooks/contexts";
import { useLocation, useNavigate } from "react-router-dom";
import { useCustomFetch } from "../hooks/async";

interface IAuthContext {
  user: string;
  signed: boolean;
  permissions: IPermission[];
  loading: boolean;
  signIn: (email: string, senha: string) => void;
  signOut: () => void;
  verifySigned: () => void;
  havePermission: (arg1: string | IPermission[], arg2?: string) => boolean;
  searchUserPermissions: () => Promise<IPermission[]>;
}

interface IAuthProviderProps {
  children: React.ReactNode;
}

interface IPermission {
  empresas: any;
  idEmpresa: number;
  permissao: string;
  tipo: string;
}

const AuthContext = React.createContext<IAuthContext>({
  user: "",
  signed: false,
  permissions: [],
  loading: false,
  signIn: (email: string, senha: string) => {},
  signOut: () => {},
  verifySigned: () => {},
  havePermission: () => false,
  searchUserPermissions: () => new Promise<IPermission[]>((resolve) => resolve([])),
});

const AuthProvider = ({ children }: IAuthProviderProps) => {
  const Modal = useModal();
  const navigate = useNavigate();
  const location = useLocation();
  const customFetch = useCustomFetch();

  const [user, setUser] = React.useState<string>("");
  const [permissions, setPermissions] = React.useState<IPermission[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const verifySigned = React.useCallback(() => {
    const storageUser = sessionStorage.getItem("user");
    const storagePermissions = sessionStorage.getItem("permissions");
    if (storageUser && storagePermissions) {
      setUser(storageUser);
      setPermissions(JSON.parse(storagePermissions));
      navigate(location);
    } else {
      navigate("/login");
    }
  }, [location, navigate]);

  const signIn = React.useCallback(
    async (email: string, senha: string) => {
      try {
        setLoading(true);
        const response = await fetch("/auth/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            senha,
          }),
        });
        const json = await response.json();
        if (json.status === 200) {
          sessionStorage.setItem("user", json.object.nome);
          sessionStorage.setItem("permissions", JSON.stringify(json.object.menuPermissions));
          setUser(json.object.nome);
          setPermissions(json.object.menuPermissions);
          navigate("/");
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error: any) {
        switch (error.message) {
          case "Failed to fetch":
            Modal.error("Sem conexão com a internet!");
            break;
          default:
            Modal.error(error);
        }
      } finally {
        setLoading(false);
      }
    },
    [Modal, navigate]
  );

  const signOut = React.useCallback(async () => {
    await sleep(0);
    Modal.close();
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("permissions");
    setUser("");
    navigate("/");
  }, [Modal, navigate]);

  const havePermission = React.useCallback(
    (arg1: string | IPermission[], arg2?: string) => {
      if (typeof arg1 === "string") {
        const permission = arg1;
        return !!permissions.find((item) => item.permissao === permission);
      } else {
        const permissions = arg1;
        return !!permissions.find((item) => item.permissao === arg2 && (item as any).status === "A");
      }
    },
    [permissions]
  );

  const searchUserPermissions = React.useCallback(() => {
    return new Promise<IPermission[]>(async (resolve, reject) => {
      try {
        const json = await customFetch(`/admin/searchUserPermissionsByToken/${4}`, {
          body: {},
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (json.status === 200) {
          resolve(json.object.permissoes);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
          resolve([]);
        } else {
          resolve([]);
        }
      } catch (error: any) {
        switch (error.message) {
          case "Failed to fetch":
            Modal.error("Sem conexão com a internet!");
            break;
          default:
            Modal.error(error.message);
        }
        resolve([]);
      }
    });
  }, [Modal, customFetch]);

  React.useEffect(() => {
    verifySigned();
  }, []); // eslint-disable-line

  return (
    <AuthContext.Provider
      value={{
        user,
        signed: !!user,
        permissions,
        loading,
        signIn,
        signOut,
        verifySigned,
        havePermission,
        searchUserPermissions,
      }}
      children={children}
    />
  );
};

export { AuthContext, AuthProvider };
