import React from "react";
import { Route, Routes } from "react-router-dom";
import { Lista } from "./Lista/Lista";
import { usePenalidades } from "./hooks/usePenalidades";
import { AdicionarAtualizar } from "./AdicionarAtualizar/AdicionarAtualizar";

export function ContratosFCPenalidades() {
  const penalidadesData = usePenalidades();

  return (
    <Routes>
      <Route path="/" element={<Lista penalidadesData={penalidadesData} />} />
      <Route path="nova" element={<AdicionarAtualizar tipo="insert" penalidadesData={penalidadesData} />} />
      <Route
        path="atualizar"
        element={
          <AdicionarAtualizar
            tipo="update"
            selectedPenalidade={penalidadesData.selectedPenalidade.value}
            penalidadesData={penalidadesData}
          />
        }
      />
    </Routes>
  );
}
