import React, { useImperativeHandle, useState } from "react";
import { Button, Input } from "../../../../../../components/Form";
import { Circle } from "../../../../../../components/Loading";
import useModalEditPercents from "./hooks/useModalEditPercents";

import styles from "./ModalEditPercents.module.css";

interface IDialogRef {
  show: (idItemTabelaVigencia: number) => void;
  close: () => void;
}

export const ModalEditPercents = React.forwardRef<IDialogRef>((_, ref) => {
  const {
    modalRef,
    data: { percentList, percent, percentColor },
    methods: {
      resetFields,
      searchPercents,
      addNewPercent,
      removePercent,
      savePercents,
    },
    loadings: { searchingPercents, addingNewPercent },
  } = useModalEditPercents();

  const [idItemTabelaVigencia, setIdItemTabelaVigencia] = useState<number>();

  useImperativeHandle(
    ref,
    () => {
      return {
        show(idItemTabelaVigencia) {
          searchPercents(idItemTabelaVigencia);
          setIdItemTabelaVigencia(idItemTabelaVigencia);
          modalRef.current?.show();
        },
        close() {
          setIdItemTabelaVigencia(undefined);
          modalRef.current?.close();
          resetFields();
        },
      };
    },
    [modalRef, searchPercents, resetFields]
  );

  return (
    <dialog className={`modal`} ref={modalRef}>
      <div className={`modalContent ${styles.modalContent}`} data-content>
        <div className={styles.header}>
          <button
            className={styles.closeButton}
            onClick={() => {
              setIdItemTabelaVigencia(undefined);
              modalRef.current?.close();
              resetFields();
            }}
          >
            X
          </button>
        </div>
        <span className="separator" />
        {!searchingPercents && !addingNewPercent ? (
          <>
            <div className={styles.percentsContainer}>
              {percentList.length > 0 ? (
                percentList
                  .filter((item) => item.status === "A")
                  .sort((a, b) => b.percentual - a.percentual)
                  .map((item) => (
                    <div key={item.id} className={styles.ballContainer}>
                      <div
                        className={styles.ball}
                        style={{ backgroundColor: item.cor }}
                      >
                        <button
                          className={styles.removeBallButton}
                          onClick={() => {
                            removePercent(item);
                          }}
                        >
                          X
                        </button>
                      </div>
                      <div
                        className={styles.ballBar}
                        style={{ backgroundColor: item.cor }}
                      />
                      <p className={styles.ballPercent}>{item.percentual}%</p>
                    </div>
                  ))
              ) : (
                <p className={`lineCardMessage ${styles.cardMessage}`}>
                  Nenhuma porcentagem registrada
                </p>
              )}
            </div>
            <span className="separator" />
            <div>
              <div className={styles.inputsContainer}>
                <input
                  className={styles.colorInput}
                  type="color"
                  value={percentColor.value}
                  onChange={percentColor.onChange}
                />
                <Input
                  type="number"
                  className={`cleanInputNumber ${styles.percentInput}`}
                  placeholder="100%"
                  value={percent.value}
                  onChange={percent.onChange}
                  error={percent.error}
                />
              </div>
              <div className={styles.buttonsContainer}>
                <Button
                  onClick={() => {
                    if (idItemTabelaVigencia) {
                      addNewPercent(idItemTabelaVigencia);
                    }
                  }}
                >
                  Adicionar
                </Button>
              </div>
              <span className="separator" />
              <Button
                className={styles.buttonSaveRange}
                onClick={() => {
                  if (idItemTabelaVigencia) {
                    savePercents(idItemTabelaVigencia);
                  }
                }}
              >
                Salvar Faixa
              </Button>
            </div>
          </>
        ) : (
          <div className={`loadingContainer`}>
            <Circle size={100} />
          </div>
        )}
      </div>
    </dialog>
  );
});
