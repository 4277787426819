import React from "react";
import { Route, Routes } from "react-router-dom";

import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { useForm } from "../../../../../hooks/form";

import { EnterpriseList } from "./EnterpriseList/EnterpriseList";
import { NewEnterprise } from "./NewEnterprise/NewEnterprise";

export function Enterprises({ selectedPriceTable }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const filteredEnterprises = useForm({ type: "", required: false });

  const [enterprises, setEnterprises] = React.useState([]);

  const searchingEnterprises = useLoading();

  const searchEnterprises = React.useCallback(
    async (description = "") => {
      if (description === "" && filteredEnterprises.value.length) filteredEnterprises.reset();
      try {
        searchingEnterprises.setLoading(true);
        const json = await customFetch("/products/searchEnterprisesOfPriceTable", {
          body: {
            idTabela: Number(selectedPriceTable.tabela.idTabela),
            descricao: description,
          },
        });
        if (json.status === 200) {
          setEnterprises(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setEnterprises([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingEnterprises.setLoading(false);
      }
    },
    [Modal, customFetch, filteredEnterprises, searchingEnterprises, selectedPriceTable]
  );

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <EnterpriseList
              selectedPriceTable={selectedPriceTable}
              filteredEnterprises={filteredEnterprises}
              searchEnterprises={searchEnterprises}
              searchingEnterprises={searchingEnterprises}
              enterprises={enterprises}
            />
          }
        />
        <Route
          path="novo"
          element={<NewEnterprise selectedPriceTable={selectedPriceTable} searchEnterprises={searchEnterprises} />}
        />
      </Routes>
    </div>
  );
}
