import React from "react";

import { IContractPremiations } from "../../types";
import { Button, InputNumber, Select } from "../../../../components/Form";
import { PageType } from "../types";
import { IUseChanges } from "../../../../hooks/contexts/useChanges";

import styles from "./ContractPremiations.module.css";
import { useForm, useSelect } from "../../../../hooks/form";
import { ArrowRight } from "phosphor-react";
import { formatMoney } from "../../../../helpers/format";

interface IProps {
  data: IContractPremiations;
  page: PageType;
  permissions?: string[];
  changesDetected?: IUseChanges;
}

const fieldIsDisabled = (page: PageType, permissions: string[]) => {
  switch (page) {
    case PageType.GERENTE:
      return false;
    case PageType.COMERCIAL:
      return !permissions.includes("CONTRATO_SOLICITACAO_ALTERA_PREMIACAO");
    default:
      return true;
  }
};

export function ContractPremiations({
  data,
  page,
  permissions = [],
  changesDetected,
}: IProps) {
  const { premiations } = data;

  const type = useSelect({ type: "single", required: true });
  const calcType = useSelect({ type: "single", required: true });
  const value = useForm({ required: true });

  return (
    <>
      {!fieldIsDisabled(page, permissions) && (
        <div className={styles.fieldsContainer}>
          <div>
            <label htmlFor="type" className="label">
              Tipo Premiação
            </label>
            <Select
              id="type"
              placeholder="Selecione um tipo de premiação"
              options={premiations.typeOptions}
              value={type.value}
              error={type.error}
              onChange={type.onChange}
            />
          </div>
          <div>
            <label htmlFor="calcType" className="label">
              Tipo Cálculo
            </label>
            <Select
              id="calcType"
              placeholder="Selecione um tipo de cálculo"
              options={premiations.calcTypeOptions}
              value={calcType.value}
              error={calcType.error}
              onChange={calcType.onChange}
            />
          </div>
          <div>
            <label htmlFor="value" className="label">
              Valor
            </label>
            <InputNumber
              autoComplete="off"
              className="cleanInputNumber"
              placeholder="Digite o valor da premiação"
              value={value.value}
              error={value.error}
              onChange={value.onChange}
            />
          </div>
          <div>
            <Button
              className={styles.insertButton}
              onClick={() => {
                premiations.insertPremiation(
                  type,
                  calcType,
                  value,
                  changesDetected
                );
              }}
            >
              Inserir Premiação
            </Button>
          </div>
        </div>
      )}
      <div
        className={styles.contentContainer}
        style={{ marginTop: fieldIsDisabled(page, permissions) ? "0px" : "" }}
      >
        {premiations.value.length > 0 ? (
          <ul className={styles.premiationItemList}>
            {premiations.value.map((premiation) => (
              <li
                key={premiation.premiationType.id}
                className={styles.premiationItem}
                style={{
                  backgroundColor: fieldIsDisabled(page, permissions)
                    ? "var(--gray-10)"
                    : "",
                }}
              >
                <div className={styles.premiationItem__info}>
                  <p className={styles.premiationItem__info__title}>
                    Tipo Premiação
                  </p>
                  <ArrowRight size={20} color="var(--gray-6)" weight="fill" />
                  <p>{premiation.premiationType.tipo}</p>
                </div>
                <div className={styles.premiationItem__info}>
                  <p className={styles.premiationItem__info__title}>
                    Tipo Cálculo
                  </p>
                  <ArrowRight size={20} color="var(--gray-6)" weight="fill" />
                  <p>
                    {premiation.calcType === "V" ? "VALOR FIXO" : "PERCENTUAL"}
                  </p>
                </div>
                <div className={styles.premiationItem__info}>
                  <p className={styles.premiationItem__info__title}>Valor</p>
                  <ArrowRight size={20} color="var(--gray-6)" weight="fill" />
                  <p>
                    {premiation.calcType === "V"
                      ? formatMoney(premiation.value)
                      : `${premiation.value} %`}
                  </p>
                </div>
                {!fieldIsDisabled(page, permissions) && (
                  <>
                    <span className={styles.separator} />
                    <div>
                      <Button
                        variant="danger"
                        className={styles.removeButton}
                        onClick={() =>
                          premiations.removePremiation(
                            premiation.premiationType.tipo,
                            changesDetected
                          )
                        }
                      >
                        Remover
                      </Button>
                    </div>
                  </>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p className={`lineCardMessage`}>Nenhuma premiação definida</p>
        )}
      </div>
    </>
  );
}
