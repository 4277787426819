import React from "react";
import { Button, Input, LinkButton } from "../../../../components/Form";

import styles from "./CategoryList.module.css";
import { TBody, THead, Table, Td, Th, Tr } from "../../../../components/Data/Table";
import { Pencil } from "phosphor-react";
import { Circle } from "../../../../components/Loading";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "../../../../hooks/async";
import { Paginate } from "../../../../components/Paginate/Paginate";
import { formatMoney } from "../../../../helpers/format";

export function CategoryList({
  filteredCategory,
  categoryList,
  setSelectedCategory,
  searchTotalCategoryRecordsAndCategories,
  searchCategories,
  pagination,
}) {
  const navigate = useNavigate();

  const { current: searchCategoriesDebounced } = React.useRef(
    useDebounce((description = "") => {
      searchCategories(description);
    })
  );

  const onClickUpdate = React.useCallback(
    (category) => {
      setSelectedCategory(category);
      navigate("atualizar");
    },
    [navigate, setSelectedCategory]
  );

  React.useEffect(() => {
    setSelectedCategory(null);
    if (!categoryList.value.length) searchTotalCategoryRecordsAndCategories();
  }, []); // eslint-disable-line

  return (
    <div className={`container`}>
      <div className={`${styles.navigationContainer}`}>
        <LinkButton to="nova">Nova Categoria</LinkButton>
      </div>
      <div>
        <label className={`label`} htmlFor="filteredCategory">
          Filtrar Categorias
        </label>
        <Input
          id="filteredCategory"
          placeholder="Busque por uma categoria"
          value={filteredCategory.value}
          onChange={({ target }) => {
            filteredCategory.setValue(target.value);
            searchCategoriesDebounced(target.value);
          }}
        />
      </div>
      <div className={`${styles.dataContainer}`}>
        {categoryList.value.length && !categoryList.isSearching ? (
          <Table title="Categorias" className={styles.table}>
            <THead>
              <Tr>
                <Th width={"1.25rem"}>ID</Th>
                <Th>Descrição</Th>
                <Th width={"11.25rem"}>Valor Máximo</Th>
                <Th width={".625rem"}>Situação</Th>
                <Th width={".625rem"}></Th>
              </Tr>
            </THead>
            <TBody>
              {categoryList.value.map((category, index) => {
                return (
                  <Tr key={index}>
                    <Td heading={"ID"}>{category.id}</Td>
                    <Td heading={"Descrição"}>{category.descricao}</Td>
                    <Td heading={"Valor Máximo"}>{formatMoney(category.valorMaximo)}</Td>
                    <Td heading={"Situação"}>{category.status === "A" ? "Ativo" : "Inativo"}</Td>
                    <Td heading={"Editar"} data-option>
                      <Button
                        className={styles.editCategoryButton}
                        onClick={() => {
                          onClickUpdate(category);
                        }}
                        data-option-button>
                        <Pencil weight="fill" />
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </TBody>
          </Table>
        ) : categoryList.isSearching ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhuma categoria encontrada</p>
        )}
        <Paginate
          classNameContainer={styles.paginationContainer}
          totalRecords={pagination.totalRecords}
          maxItems={pagination.maxItems}
          currentPage={pagination.currentPage}
          setCurrentPage={pagination.setCurrentPage}
          onPageChange={(page) => searchCategories(filteredCategory.value, page - 1)}
        />
      </div>
    </div>
  );
}
