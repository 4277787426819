import React from "react";

import { formatDate } from "../../../../helpers/format";

import { LinkButton, Button, Input } from "../../../../components/Form";
import { Table, THead, TBody, Tr, Th, Td } from "../../../../components/Data/Table";
import { Paginate } from "../../../../components/Paginate/Paginate";

import { useDebounce } from "../../../../hooks/async";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../../hooks/contexts";

import { Circle } from "../../../../components/Loading";
import { Pencil } from "phosphor-react";

import styles from "./PriceTableList.module.css";

export function PriceTableList({
  pagination,
  searchedPriceTable,
  searchPriceTables,
  searchTotalPriceTableRecordsAndPriceTables,
  priceTableList,
  searchingPriceTables,
  setSelectedPriceTable,
}) {
  const Modal = useModal();
  const navigate = useNavigate();

  const handlePageChange = React.useCallback(
    async (priceTable, page) => {
      try {
        searchingPriceTables.setLoading(true);
        const json = await searchPriceTables(priceTable, page);
        if (json.status === 200) {
          priceTableList.setValue(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingPriceTables.setLoading(false);
      }
    },
    [Modal, priceTableList, searchPriceTables, searchingPriceTables]
  );

  const handleClickEditButton = React.useCallback(
    (priceTableData) => {
      setSelectedPriceTable(priceTableData);
      navigate("atualizar");
    },
    [navigate, setSelectedPriceTable]
  );

  const { current: searchPriceTableList } = React.useRef(
    useDebounce((priceTable) => {
      searchTotalPriceTableRecordsAndPriceTables(priceTable);
    })
  );

  return (
    <div className={`container ${styles.container}`}>
      <div className={`${styles.buttonContainer}`}>
        <LinkButton to="novo">Nova Tabela de Preços</LinkButton>
      </div>
      <div className={`${styles.searchContainer}`}>
        <label className="label">Filtrar Tabela de Preços</label>
        <Input
          placeholder="Busque por uma tabela de preços"
          value={searchedPriceTable.value}
          onChange={({ target }) => {
            searchedPriceTable.setValue(target.value);
            searchPriceTableList(target.value);
          }}
        />
      </div>
      <div className={`${styles.priceTableListContainer}`}>
        {priceTableList.value.length && !searchingPriceTables.isLoading ? (
          <Table title="Tabelas de Preços Cadastradas" className={styles.priceTable}>
            <THead>
              <Tr>
                <Th>ID</Th>
                <Th>Descrição</Th>
                <Th>Juros (%)</Th>
                <Th>Situação</Th>
                <Th>Última modificação</Th>
                <Th width="6.25rem"></Th>
              </Tr>
            </THead>
            <TBody>
              {priceTableList.value.map((priceTable) => {
                return (
                  <Tr key={priceTable.tabela.idTabela}>
                    <Td heading="ID">{priceTable.tabela.idTabela}</Td>
                    <Td heading="Descrição">{priceTable.tabela.descricaoTabela}</Td>
                    <Td heading="Juros (%)">{`${priceTable.tabela.jurosTabela}`}</Td>
                    <Td heading="Situação">{priceTable.tabela.situacaoTabela === "S" ? "Ativo" : "Inativo"}</Td>
                    <Td heading="Última modificação">
                      {formatDate(priceTable.tabela.dataManutencao, "dd/MM/yyyy às hh:mm:ss")}
                    </Td>
                    <Td heading="Editar" data-option>
                      <Button
                        className={styles.editPriceTableButton}
                        onClick={() => {
                          const selectedGroup = {
                            ...priceTable,
                            status: !!(priceTable.tabela.situacaoTabela === "S"),
                          };
                          handleClickEditButton(selectedGroup);
                        }}
                        data-option-button
                      >
                        <Pencil weight="fill" />
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </TBody>
          </Table>
        ) : searchingPriceTables.isLoading ? (
          <div className={styles.searchingPriceTablesLoadingContainer}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={styles.noPriceTableMessage}>Nenhuma tabela de preços encontrada</p>
        )}
      </div>
      <div>
        <Paginate
          classNameContainer={styles.paginationContainer}
          totalRecords={pagination.totalRecords}
          maxItems={pagination.maxItems}
          currentPage={pagination.currentPage}
          setCurrentPage={pagination.setCurrentPage}
          onPageChange={(page) => handlePageChange(searchedPriceTable.value, page)}
        />
      </div>
    </div>
  );
}
