import React from "react";
import { Button, Input } from "../../../../components/Form/index";
import { Circle } from "../../../../components/Loading";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useChanges, useModal } from "../../../../hooks/contexts";
import { IGeneral } from "../GeneralSettings";

import styles from "./General.module.css";

interface IGeneralProps {
  settings: IGeneral;
  setSettings: React.Dispatch<React.SetStateAction<IGeneral>>;
  searchingGeneralSettings: IUseLoading;
}

export default function General({ settings, setSettings, searchingGeneralSettings }: IGeneralProps) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const { isChangesDetected, setIsChangesDetected } = useChanges();

  const savingSettings = useLoading();

  const handleChangeCheck = (
    setting: {
      name: string;
      label: string;
      value: boolean;
    },
    value: boolean
  ) => {
    setting.value = value;
    setSettings({ ...settings });
  };

  const handleChangeNumber = (
    setting: {
      name: string;
      label: string;
      value: string;
    },
    value: string
  ) => {
    setting.value = value;
    setSettings({ ...settings });
  };

  const saveSettings = async () => {
    const finalJson: {
      [key: string]: boolean | number;
    } = {};

    settings.checkSettings.forEach((checkList) => {
      checkList.settings.forEach((setting) => {
        finalJson[setting.name] = setting.value;
      });
    });

    settings.numberSettings.forEach((numberList) => {
      numberList.settings.forEach((setting) => {
        finalJson[setting.name] = Number(setting.value);
      });
    });

    try {
      savingSettings.setLoading(true);
      const json = await customFetch("/products/updateConfigs", {
        body: finalJson,
      });
      if (json.status === 200) {
        Modal.success(json.message);
        setIsChangesDetected(false);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      savingSettings.setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {!searchingGeneralSettings.isLoading && !savingSettings.isLoading ? (
        <>
          {settings.checkSettings.map((item, itemIndex) => (
            <div key={itemIndex} className={styles.settingsContainer}>
              <h2 className={styles.settingsTitle}>{item.title}</h2>
              <div className={styles.settingsFields}>
                {item.settings.map((setting, settingIndex) => (
                  <label className={"label " + styles.labelSetting} key={settingIndex}>
                    <input
                      type="checkbox"
                      name={setting.name}
                      checked={setting.value}
                      onChange={(e) => {
                        setIsChangesDetected(true);
                        const value = e.target.checked;
                        handleChangeCheck(setting, value);
                      }}
                    />
                    <span>{setting.label}</span>
                  </label>
                ))}
              </div>
            </div>
          ))}

          <div style={{ gridColumn: "1/-1" }}>
            {settings.numberSettings.map((item, itemIndex) => (
              <div key={itemIndex} className={styles.settingsContainer}>
                <h2 className={styles.settingsTitle}>{item.title}</h2>
                <div className={styles.settingsFields}>
                  {item.settings.map((setting, settingIndex) => (
                    <label className={`label ${styles.labelSetting} ${styles.labelNumber}`} key={settingIndex}>
                      <span>{setting.label}</span>
                      <Input
                        className="cleanInputNumber"
                        type="number"
                        value={setting.value}
                        onChange={(e) => {
                          setIsChangesDetected(true);
                          const value = e.target.value;
                          handleChangeNumber(setting, value);
                        }}
                      />
                    </label>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className={styles.saveButtonContainer}>
            <Button onClick={saveSettings} disabled={!isChangesDetected}>
              Salvar Alterações
            </Button>
          </div>
        </>
      ) : searchingGeneralSettings.isLoading ? (
        <div className={`loadingContainer ${styles.loadContainer}`}>
          <Circle size={100} />
        </div>
      ) : (
        <div className={`loadingContainer ${styles.loadContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Salvando Configurações</span>
        </div>
      )}
    </div>
  );
}
