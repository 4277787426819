import React, { useEffect } from "react";

import {
  ISeller,
  ISolicitation,
  ISupervisor,
  IUseSolicitationList,
  SearchSolicitationsResponse,
  SearchTotalSolicitationRecordsResponse,
} from "./types";
import { useModal } from "../../../../hooks/contexts";
import {
  useCustomFetch,
  useDebounce,
  useLoading,
} from "../../../../hooks/async";
import { usePagination } from "../../../../hooks/pagination";
import { useDate, useForm, useSelect } from "../../../../hooks/form";
import { SolicitationStatus } from "../../types";

const statusOptions = [
  { value: Object.values(SolicitationStatus), label: "Todos" },
  { value: [SolicitationStatus.Pendente], label: "Pendentes" },
  { value: [SolicitationStatus.AprovadoCliente], label: "Aprovado Cliente" },
  { value: [SolicitationStatus.ReprovadoCliente], label: "Reprovado Cliente" },
  { value: [SolicitationStatus.Revisado], label: "Revisado" },
  {
    value: [SolicitationStatus.ReprovadoComercial],
    label: "Reprovado Comercial",
  },
  {
    value: [SolicitationStatus.AprovadoComercial],
    label: "Aprovado Comercial",
  },
  {
    value: [SolicitationStatus.ReprovadoFinanceiro],
    label: "Reprovado Financeiro",
  },
  {
    value: [SolicitationStatus.AprovadoFinanceiro],
    label: "Aprovado Financeiro",
  },
];

export function useSolicitationList(): IUseSolicitationList {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const pagination = usePagination();

  const sellersAbortController = React.useRef(new AbortController());

  const filterDescription = useForm({ required: false });
  const filterSolicitationIds = useSelect<string, "multiple">({
    type: "multiple",
    required: false,
  });
  const filterStatus = useSelect({
    type: "single",
    required: true,
    defaultValue: statusOptions[0],
  });
  const filterInitialDate = useDate({ required: true });
  const filterFinalDate = useDate({ required: true });
  const filterSellers = useSelect<ISeller, "multiple">({
    type: "multiple",
    required: false,
  });
  const filterSupervisors = useSelect<ISupervisor, "multiple">({
    type: "multiple",
    required: false,
  });

  const [sellerOptions, setSellerOptions] = React.useState<
    ISelectOption<ISeller>[]
  >([]);
  const [supervisorOptions, setSupervisorOptions] = React.useState<
    ISelectOption<ISupervisor>[]
  >([]);

  const [solicitationList, setSolicitationList] = React.useState<
    ISolicitation[]
  >([]);

  const searchingSellerOptions = useLoading();
  const searchingSupervisorOptions = useLoading();
  const searchingSolicitations = useLoading();

  const searchSellerOptions = React.useCallback(
    async (description: string, supervisors: number[]) => {
      sellersAbortController.current.abort();
      sellersAbortController.current = new AbortController();
      try {
        searchingSellerOptions.setLoading(true);
        const json = (await customFetch("/contracts/searchSellers", {
          body: { descricao: description, supervisores: supervisors },
          signal: sellersAbortController.current.signal,
        })) as DefaultFetchResponse<ISeller[]>;
        if (json.status === 200) {
          const options = json.object.map((item) => ({
            label: `${item.id} | ${item.descricao}`,
            value: item,
          }));
          setSellerOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setSellerOptions([]);
        }
        searchingSellerOptions.setLoading(false);
      } catch (error: any) {
        if (error.name !== "AbortError") {
          Modal.error(error);
          searchingSellerOptions.setLoading(() => false);
        }
      }
    },
    [Modal, customFetch, searchingSellerOptions]
  );

  const searchSupervisorOptions = React.useCallback(
    async (description = "") => {
      try {
        searchingSupervisorOptions.setLoading(true);
        const json = (await customFetch("/contracts/searchSupervisors", {
          body: { descricao: description },
        })) as DefaultFetchResponse<ISupervisor[]>;
        if (json.status === 200) {
          const options = json.object.map((item) => ({
            label: `${item.id} | ${item.descricao}`,
            value: item,
          }));
          setSupervisorOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setSupervisorOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingSupervisorOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingSupervisorOptions]
  );

  const searchSolicitationsPromise = React.useCallback(
    (
      page = 0,
      description = "",
      status: string[] = [],
      initialDate: string = "",
      finalDate: string = "",
      sellers: number[] = [],
      supervisors: number[] = [],
      solicitationIds: number[] = []
    ) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch(
            "/contracts/searchSolicitationsPaginated",
            {
              body: {
                pagina: page,
                tamanho: pagination.maxItems,
                descricao: description,
                status,
                dataSolicitacaoInicial: initialDate,
                dataSolicitacaoFinal: finalDate,
                vendedores: sellers,
                supervisores: supervisors,
                solicitacoes: solicitationIds,
              },
            }
          );
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const searchTotalSolicitationRecordsPromise = React.useCallback(
    (
      page = 0,
      description = "",
      status: string[] = [],
      initialDate: string = "",
      finalDate: string = "",
      sellers: number[] = [],
      supervisors: number[] = [],
      solicitationIds: number[] = []
    ) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch(
            "/contracts/searchTotalSolicitationRecords",
            {
              body: {
                pagina: page,
                tamanho: pagination.maxItems,
                descricao: description,
                status,
                dataSolicitacaoInicial: initialDate,
                dataSolicitacaoFinal: finalDate,
                vendedores: sellers,
                supervisores: supervisors,
                solicitacoes: solicitationIds,
              },
            }
          );
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const searchSolicitations = React.useCallback(
    async (
      page = 0,
      description = "",
      status: string[] = [],
      initialDate: string = "",
      finalDate: string = "",
      sellers: number[] = [],
      supervisors: number[] = [],
      solicitationIds: number[] = []
    ) => {
      try {
        searchingSolicitations.setLoading(true);
        const json = (await searchSolicitationsPromise(
          page,
          description,
          status,
          initialDate,
          finalDate,
          sellers,
          supervisors,
          solicitationIds
        )) as SearchSolicitationsResponse;
        if (json.status === 200) {
          setSolicitationList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setSolicitationList([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingSolicitations.setLoading(false);
      }
    },
    [Modal, searchSolicitationsPromise, searchingSolicitations]
  );

  const searchTotalSolicitationRecordsAndSolicitations = React.useCallback(
    async (
      description = "",
      status: string[] = [],
      initialDate: string = "",
      finalDate: string = "",
      sellers: number[] = [],
      supervisors: number[] = [],
      solicitationIds: number[] = []
    ) => {
      if (!description && !status.length) {
        filterDescription.reset();
        filterStatus.reset();
        status = statusOptions[0].value;
      }
      try {
        searchingSolicitations.setLoading(true);
        const [solicitations, records] = (await Promise.all([
          searchSolicitationsPromise(
            0,
            description,
            status,
            initialDate,
            finalDate,
            sellers,
            supervisors,
            solicitationIds
          ),
          searchTotalSolicitationRecordsPromise(
            0,
            description,
            status,
            initialDate,
            finalDate,
            sellers,
            supervisors,
            solicitationIds
          ),
        ])) as [
          SearchSolicitationsResponse,
          SearchTotalSolicitationRecordsResponse
        ];
        if (solicitations.status === 200 && records.status === 200) {
          setSolicitationList(solicitations.object);
          pagination.setTotalRecords(records.object.total);
        } else if (solicitations.status !== 200) {
          if (solicitations.status === 500) {
            Modal.error(solicitations.message, solicitations.object);
          } else {
            pagination.reset();
            setSolicitationList([]);
          }
        } else {
          if (records.status === 500) {
            Modal.error(records.message, records.object);
          } else {
            setSolicitationList([]);
          }
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        pagination.setCurrentPage(1);
        searchingSolicitations.setLoading(false);
      }
    },
    [
      Modal,
      filterDescription,
      filterStatus,
      pagination,
      searchSolicitationsPromise,
      searchTotalSolicitationRecordsPromise,
      searchingSolicitations,
    ]
  );

  const searchSellerOptionsDebounced = useDebounce(searchSellerOptions);

  const searchTotalSolicitationRecordsAndSolicitationsDebounced = useDebounce(
    searchTotalSolicitationRecordsAndSolicitations
  );

  useEffect(() => {
    const today = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(today.getMonth() - 1);
    today.setDate(today.getDate());

    const lastMonthIso = lastMonth.toISOString();
    const todayIso = today.toISOString();

    filterInitialDate.setValue(lastMonthIso.split("T")[0]);
    filterFinalDate.setValue(todayIso.split("T")[0]);

    searchSellerOptions("", []);
    searchSupervisorOptions();
  }, []); // eslint-disable-line

  return {
    pagination,
    filter: {
      description: filterDescription,
      solicitationIds: filterSolicitationIds,
      status: filterStatus,
      initialDate: filterInitialDate,
      finalDate: filterFinalDate,
      sellers: filterSellers,
      supervisors: filterSupervisors,
      statusOptions,
      sellerOptions: {
        options: sellerOptions,
        search: searchSellerOptions,
        searchDebounced: searchSellerOptionsDebounced,
        loading: searchingSellerOptions.isLoading,
      },
      supervisorOptions: {
        options: supervisorOptions,
        search: searchSupervisorOptions,
        loading: searchingSupervisorOptions.isLoading,
      },
    },
    solicitationList: [solicitationList, setSolicitationList],
    searchingSolicitations: searchingSolicitations.isLoading,
    searchSolicitations,
    searchTotalSolicitationRecordsAndSolicitations,
    searchTotalSolicitationRecordsAndSolicitationsDebounced,
  };
}
