import { useCallback, useMemo } from "react";
import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { useForm, useSelect } from "../../../../../hooks/form";
import { isValid } from "../../../../../helpers/validations";
import { IUseAdicionarAtualizar, Status, StatusOptions } from "./types";
import { IPeriodicidadePagto, IUsePeriodicidadesPagto } from "../../hooks/types";
import { useNavigate } from "react-router-dom";

interface IProps {
  periodicidadesPagtoData: IUsePeriodicidadesPagto;
}

export function useAdicionarAtualizar({ periodicidadesPagtoData }: IProps): IUseAdicionarAtualizar {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const nomePeriodicidadePagto = useForm({ required: true });
  const status = useSelect<Status>({ required: true, type: "single", defaultValue: { value: "A", label: "Ativo" } });

  const statusOptions = useMemo<StatusOptions>(
    () => [
      { value: "A", label: "Ativo" },
      { value: "I", label: "Inativo" },
    ],
    []
  );

  const insertingOrUpdatingPeriodicidadePagto = useLoading();

  const carregarDados = useCallback(
    (selectedPeriodicidadePagto: IPeriodicidadePagto) => {
      nomePeriodicidadePagto.setValue(selectedPeriodicidadePagto.nomePeriodicidade);
      status.setValue(() => {
        if (selectedPeriodicidadePagto.status === "A") return statusOptions[0];
        else return statusOptions[1];
      });
    },
    [nomePeriodicidadePagto, status, statusOptions]
  );

  const insertOrUpdatePeriodicidadePagto = useCallback(
    async (id: number = 0) => {
      if (!isValid(nomePeriodicidadePagto, status)) return;
      const body = {
        idPeriodicidade: id,
        nomePeriodicidade: nomePeriodicidadePagto.value,
        status: status.value?.value,
      };
      try {
        insertingOrUpdatingPeriodicidadePagto.setLoading(true);
        const json = await customFetch(`/juridico/insertAndUpdatePeriodicidadePagto`, {
          body,
        });
        if (json.status === 200) {
          periodicidadesPagtoData.searchs.searchPeriodicidadesPagtoPaginated("");
          await Modal.success(json.message);
          navigate("/contratos-fornecedor-cliente/periodicidades-pagamento");
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        insertingOrUpdatingPeriodicidadePagto.setLoading(false);
      }
    },
    [
      Modal,
      customFetch,
      insertingOrUpdatingPeriodicidadePagto,
      nomePeriodicidadePagto,
      status,
      periodicidadesPagtoData.searchs,
      navigate,
    ]
  );

  return {
    carregarDados,
    form: {
      nomePeriodicidadePagto,
      status,
    },
    selectOptions: {
      statusOptions,
    },
    interactions: {
      insertOrUpdatePeriodicidadePagto,
      insertingOrUpdatingPeriodicidadePagto: insertingOrUpdatingPeriodicidadePagto.isLoading,
    },
  };
}
