import { useCallback, useRef, useState } from "react";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { Grupo } from "./useGrupos";
import { useModal } from "../../../../hooks/contexts";

interface Props {
  selectedGrupo: Grupo;
}

export function useAtualizarPermissoesGrupo({ selectedGrupo }: Props) {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const [permissoes, setPermissoes] = useState<ListaPermissoesTratada>([]);

  const permissoesAtivasGrupo = useRef<PermissoesAtivasGrupo>({});

  const buscandoPermissoesGrupo = useLoading();

  const getPermissoesTratadas = useCallback((permissoes: PermissaoGrupo[]) => {
    type ModeloPermissoes = {
      [sistema: string]: {
        [grupo: string]: PermissaoTradada[];
      };
    };

    const modeloPermissoes: ModeloPermissoes = {};

    permissoes.forEach((permissao) => {
      if (!(permissao.descricaoSistema in modeloPermissoes)) {
        modeloPermissoes[permissao.descricaoSistema] = {};
      }
      if (
        !(
          permissao.descricaoTipo in
          modeloPermissoes[permissao.descricaoSistema]
        )
      ) {
        modeloPermissoes[permissao.descricaoSistema][permissao.descricaoTipo] =
          [];
      }
      modeloPermissoes[permissao.descricaoSistema][
        permissao.descricaoTipo
      ].push({
        descricaoPermissao: permissao.descricaoPermissao,
        idPermissao: permissao.idPermissao,
        statusPermissaoGrupo: permissao.statusPermissaoGrupo,
        observacaoPermissao: permissao.observacaoPermissao,
      });
    });

    const permissoesTratadas: ListaPermissoesTratada = [];

    Object.entries(modeloPermissoes).forEach(([sistema, grupos]) => {
      permissoesTratadas.push({
        sistema,
        grupos: Object.entries(grupos).map(([grupo, permissoes]) => ({
          grupo,
          permissoes,
        })),
      });
    });

    return permissoesTratadas;
  }, []);

  const definirPermissoesAtivasGrupo = useCallback(
    (permissoes: PermissoesTratadas[]) => {
      const permissoesAtivas: PermissoesAtivasGrupo = {};

      permissoes.forEach((sistema) => {
        sistema.grupos.forEach((grupo) => {
          grupo.permissoes.forEach((permissao) => {
            if (permissao.statusPermissaoGrupo === "A") {
              permissoesAtivas[permissao.descricaoPermissao] = permissao;
            }
          });
        });
      });

      permissoesAtivasGrupo.current = permissoesAtivas;
    },
    []
  );

  const buscarPermissoes = useCallback(async () => {
    try {
      buscandoPermissoesGrupo.setLoading(true);
      const json = (await customFetch("/admin/buscarPermissoesGrupo", {
        body: {
          idGrupo: selectedGrupo.id,
        },
      })) as DefaultFetchResponse<PermissaoGrupo[]>;
      if (json.status === 200) {
        const permissoesTratadas = getPermissoesTratadas(json.object);
        setPermissoes(JSON.parse(JSON.stringify(permissoesTratadas)));
        definirPermissoesAtivasGrupo(
          JSON.parse(JSON.stringify(permissoesTratadas))
        );
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setPermissoes([]);
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      buscandoPermissoesGrupo.setLoading(false);
    }
  }, [
    Modal,
    buscandoPermissoesGrupo,
    customFetch,
    definirPermissoesAtivasGrupo,
    getPermissoesTratadas,
    selectedGrupo.id,
  ]);

  const alterarStatusPermissao = useCallback((permissao: PermissaoTradada) => {
    if (permissao.statusPermissaoGrupo === "A")
      permissao.statusPermissaoGrupo = "I";
    else permissao.statusPermissaoGrupo = "A";

    if (permissao.descricaoPermissao in permissoesAtivasGrupo.current) {
      permissoesAtivasGrupo.current[
        permissao.descricaoPermissao
      ].statusPermissaoGrupo = permissao.statusPermissaoGrupo;
    }

    setPermissoes((old) => JSON.parse(JSON.stringify(old)));
  }, []);

  const getPermissoesAtivasInativas = useCallback(() => {
    const permissoesAtivas: number[] = [];
    const permissoesInativas: number[] = [];

    permissoes.forEach((sistema) => {
      sistema.grupos.forEach((grupo) => {
        grupo.permissoes.forEach((permissao) => {
          if (
            permissao.statusPermissaoGrupo === "A" &&
            !(permissao.descricaoPermissao in permissoesAtivasGrupo.current)
          )
            permissoesAtivas.push(permissao.idPermissao);
          if (
            permissao.descricaoPermissao in permissoesAtivasGrupo.current &&
            permissao.statusPermissaoGrupo === "I"
          )
            permissoesInativas.push(permissao.idPermissao);
        });
      });
    });

    return [permissoesAtivas, permissoesInativas];
  }, [permissoes]);

  const salvarPermissoesGrupo = useCallback(async () => {
    const [permissoesAtivas, permissoesInativas] =
      getPermissoesAtivasInativas();
    buscandoPermissoesGrupo.setLoading(true);
    try {
      const json = (await customFetch("/admin/atualizarPermissoesGrupo", {
        body: {
          idGrupo: selectedGrupo.id,
          permissoesAtivas: permissoesAtivas,
          permissoesInativas: permissoesInativas,
        },
      })) as DefaultFetchResponse<any>;
      if (json.status === 200) {
        definirPermissoesAtivasGrupo(JSON.parse(JSON.stringify(permissoes)));
        Modal.success(json.message);
      } else {
        Modal.error(json.message, json.object);
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      buscandoPermissoesGrupo.setLoading(false);
    }
  }, [
    Modal,
    buscandoPermissoesGrupo,
    customFetch,
    definirPermissoesAtivasGrupo,
    getPermissoesAtivasInativas,
    permissoes,
    selectedGrupo.id,
  ]);

  return {
    permissoes,
    permissoesAtivasGrupo,
    buscarPermissoes,
    buscandoPermissoesGrupo: buscandoPermissoesGrupo.isLoading,
    alterarStatusPermissao,
    salvarPermissoesGrupo,
  };
}

export type UseAtualizarPermissoesGrupo = ReturnType<
  typeof useAtualizarPermissoesGrupo
>;

export type PermissaoGrupo = {
  idPermissao: number;
  descricaoPermissao: string;
  descricaoSistema: string;
  descricaoTipo: string;
  observacaoPermissao: string;
  statusPermissaoGrupo: "A" | "I";
};

export type PermissoesTratadas = SistemaTratado;

export type SistemaTratado = {
  sistema: string;
  grupos: GrupoTratado[];
};

export type GrupoTratado = {
  grupo: string;
  permissoes: PermissaoTradada[];
};

export type PermissaoTradada = {
  idPermissao: number;
  descricaoPermissao: string;
  observacaoPermissao: string;
  statusPermissaoGrupo: "A" | "I";
};

export type ListaPermissoesTratada = PermissoesTratadas[];

export type PermissoesAtivasGrupo = {
  [nomePermissao: string]: PermissaoTradada;
};
