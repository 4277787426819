import React from "react";
import { Button, InputMask, LinkButton, Select } from "../../../../components/Form";
import { useForm, useSelect } from "../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useChanges, useModal } from "../../../../hooks/contexts";
import { useNavigate } from "react-router-dom";
import { isValid } from "../../../../helpers/validations";
import { Circle } from "../../../../components/Loading";
import { IPeople } from "../../types";

import styles from "./UpdateUserDocument.module.css";
import { IUserDocument } from "../UserDocument";

interface IProps {
  selectedUserDocument: IUserDocument | null;
  searchUserDocuments: () => void;
}

export function UpdateUserDocument({ selectedUserDocument, searchUserDocuments }: IProps) {
  const Modal = useModal();
  const Changes = useChanges();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();

  const user = useSelect({ type: "single", required: true });
  const cpf = useForm({ required: false });
  const cnpj = useForm({ required: false });
  const status = useSelect({ type: "single", required: true });

  const [userOptions, setUserOptions] = React.useState<ISelectOption<IPeople>[]>([]);

  const searchingUsers = useLoading();
  const savingUserDocument = useLoading();

  const statusOptions = React.useMemo(
    () => [
      {
        value: "A",
        label: "Ativo",
      },
      {
        value: "I",
        label: "Inativo",
      },
    ],
    []
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const searchUsers = React.useCallback(async () => {
    try {
      searchingUsers.setLoading(true);
      const json = (await customFetch("/finance/searchUsers", {
        body: {
          id: 0,
          paginacao: {
            paginaNumero: 0,
            paginaTamanho: 0,
          },
        },
      })) as DefaultFetchResponse<IPeople[]>;
      if (json.status === 200) {
        const options = json.object.map((item) => ({
          value: item,
          label: `${item.idUsuario} | ${item.nome}`,
        }));
        setUserOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setUserOptions([]);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingUsers.setLoading(false);
    }
  }, [Modal, customFetch, searchingUsers]);

  const saveUserDocument = React.useCallback(async () => {
    if (!isValid(user, cpf, cnpj)) return;
    try {
      savingUserDocument.setLoading(true);
      const json = (await customFetch("/finance/insertAndUpdateUserDocument", {
        body: {
          usuarioDocumentoId: selectedUserDocument?.usuarioDocumentoId,
          usuarioId: user.value?.value.idUsuario,
          cpf: cpf.value.replace(/\D/g, ""),
          cnpj: cnpj.value.replace(/\D/g, ""),
          status: status.value?.value,
        },
      })) as DefaultFetchResponse<null>;
      if (json.status === 200) {
        Changes.reset();
        searchUserDocuments();
        await Modal.success(json.message);
        navigate("/financeiro/documentos");
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      savingUserDocument.setLoading(false);
    }
  }, [
    selectedUserDocument,
    user,
    cpf,
    cnpj,
    status,
    savingUserDocument,
    customFetch,
    searchUserDocuments,
    Modal,
    Changes,
    navigate,
  ]);

  React.useEffect(() => {
    // searchUsers();
    if (selectedUserDocument) {
      user.setValue({
        value: {
          idUsuario: selectedUserDocument.usuario?.idUsuario,
          nome: selectedUserDocument.usuario?.nome,
        } as IPeople,
        label: `${selectedUserDocument.usuario?.idUsuario} | ${selectedUserDocument.usuario?.nome}`,
      });
      cpf.setValue(selectedUserDocument.cpf);
      cnpj.setValue(selectedUserDocument.cnpj);
      status.setValue(() => {
        if (selectedUserDocument.status === "A") return statusOptions[0];
        else return statusOptions[1];
      });
    }
  }, []); // eslint-disable-line

  return (
    <div className="container">
      {!savingUserDocument.isLoading ? (
        <>
          <div>
            <LinkButton to={"/financeiro/documentos"} buttonStyle="backButton" />
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fieldsContainer__userField}>
              <label htmlFor="user" className="label">
                Usuário
              </label>
              <Select
                id="user"
                placeholder="Selecione um usuário"
                value={user.value}
                error={user.error}
                onBlur={user.onBlur}
                options={userOptions}
                isLoading={searchingUsers.isLoading}
                isDisabled
              />
            </div>
            <div>
              <label htmlFor="cpf" className="label">
                CPF
              </label>
              <InputMask
                id="cpf"
                placeholder="Digite o CPF do usuário selecionado"
                mask={"999.999.999-99"}
                value={cpf.value}
                error={cpf.error}
                onChange={(e) => {
                  cpf.onChange(e);
                  Changes.set();
                }}
                onBlur={cpf.onChange}
              />
            </div>
            <div>
              <label htmlFor="cnpj" className="label">
                CNPJ
              </label>
              <InputMask
                id="cnpj"
                placeholder="Digite o CNPJ do usuário selecionado"
                mask={"99.999.999/9999-99"}
                value={cnpj.value}
                error={cnpj.error}
                onChange={(e) => {
                  cnpj.onChange(e);
                  Changes.set();
                }}
                onBlur={cnpj.onChange}
              />
            </div>
            <div className={styles.fieldsContainer__statusField}>
              <label htmlFor="status" className="label">
                Situação
              </label>
              <Select
                options={statusOptions}
                value={status.value}
                onChange={(value) => {
                  Changes.set();
                  status.onChange(value);
                }}
                isSearchable={false}
                isClearable={false}
              />
            </div>
          </div>
          <div className={styles.saveButtonContainer}>
            <Button onClick={saveUserDocument} disabled={!Changes.isChangesDetected}>
              Salvar Documento
            </Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Atualizando Documento</span>
        </div>
      )}
    </div>
  );
}
