import React from "react";

import { useCustomFetch, useDebounce, useLoading } from "../../../../hooks/async";
import { useSelect } from "../../../../hooks/form";
import { IManager, ISeller, ISellersLinkedToManager, IUseLinkManagerAndSellers } from "./types";
import { useModal } from "../../../../hooks/contexts";
import { isValid } from "../../../../helpers/validations";

export function useLinkManagerAndSellers(): IUseLinkManagerAndSellers {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const manager = useSelect({ type: "single", required: true });
  const sellers = useSelect({ type: "multiple", required: true });

  const managerOptions = React.useState<ISelectOption<IManager>[]>([]);
  const sellerOptions = React.useState<ISelectOption<ISeller>[]>([]);

  const searchingManagerOptions = useLoading();
  const searchingSellerOptions = useLoading();
  const searchingSellersLinkedToManager = useLoading();
  const linkingManagerAndSellers = useLoading();

  const sellerOptionsFiltered = React.useMemo(() => {
    return sellerOptions[0].filter((i) => !sellers.value.some((j) => j.value.id === i.value.id));
  }, [sellerOptions, sellers.value]);

  const resetFields = React.useCallback(() => {
    manager.reset();
    sellers.reset();
  }, [manager, sellers]);

  const searchManagerOptions = React.useCallback(
    async (description: string = "") => {
      try {
        searchingManagerOptions.setLoading(true);
        const json = (await customFetch("/contracts/searchSupervisors", {
          body: { descricao: description },
        })) as DefaultFetchResponse<IManager[]>;
        if (json.status === 200) {
          const options = json.object.map((item) => ({
            label: `${item.id} | ${item.descricao}`,
            value: item,
          }));
          managerOptions[1](options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          managerOptions[1]([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingManagerOptions.setLoading(false);
      }
    },
    [Modal, customFetch, managerOptions, searchingManagerOptions]
  );

  const searchSellerOptions = React.useCallback(
    async (description: string = "") => {
      try {
        searchingSellerOptions.setLoading(true);
        const json = (await customFetch("/contracts/searchSellers", {
          body: { descricao: description },
        })) as DefaultFetchResponse<ISeller[]>;
        if (json.status === 200) {
          const options = json.object.map((item) => ({
            label: `${item.id} | ${item.descricao}`,
            value: item,
          }));
          sellerOptions[1](options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          sellerOptions[1]([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingSellerOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingSellerOptions, sellerOptions]
  );

  const searchManagerOptionsDebounced = useDebounce(searchManagerOptions);

  const searchSellerOptionsDebounced = useDebounce(searchSellerOptions);

  const handleRemoveSeller = (sellerId: number) => {
    sellers.setValue((old) => old.filter((i) => i.value.id !== sellerId));
  };

  const searchSellersLinkedToManager = React.useCallback(
    async (managerId: number) => {
      try {
        searchingSellersLinkedToManager.setLoading(true);
        const json = (await customFetch("/contracts/settings/searchSellersLinkedToManager", {
          body: { idSupervisor: managerId },
        })) as DefaultFetchResponse<ISellersLinkedToManager[]>;
        if (json.status === 200) {
          sellers.setValue(
            json.object[0].vendedor.map((item) => ({
              label: `${item.idVendedor} | ${item.nomeVendedor}`,
              value: { id: item.idVendedor, descricao: item.nomeVendedor },
            }))
          );
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
          sellers.reset();
        } else {
          sellers.reset();
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingSellersLinkedToManager.setLoading(false);
      }
    },
    [Modal, customFetch, searchingSellersLinkedToManager, sellers]
  );

  const linkManagerAndSellers = React.useCallback(async () => {
    if (!isValid(manager, sellers)) return;
    try {
      linkingManagerAndSellers.setLoading(true);
      const json = (await customFetch("/contracts/settings/linkManagerAndSellers", {
        body: {
          idSupervisor: manager.value?.value.id,
          vendedores: sellers.value.map((i) => i.value.id),
        },
      })) as DefaultFetchResponse<any>;
      if (json.status === 200) {
        await Modal.success(json.message);
        resetFields();
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      linkingManagerAndSellers.setLoading(false);
    }
  }, [Modal, customFetch, linkingManagerAndSellers, manager, sellers, resetFields]);

  React.useEffect(() => {
    searchManagerOptions();
    searchSellerOptions();
  }, []); // eslint-disable-line

  return {
    manager,
    sellers,
    managerOptions: {
      options: managerOptions[0],
      search: searchManagerOptions,
      searchDebounced: searchManagerOptionsDebounced,
      searching: searchingManagerOptions.isLoading,
    },
    sellerOptions: {
      options: sellerOptionsFiltered,
      search: searchSellerOptions,
      searchDebounced: searchSellerOptionsDebounced,
      searching: searchingSellerOptions.isLoading,
    },
    handleRemoveSeller,
    searchSellersLinkedToManager: {
      search: searchSellersLinkedToManager,
      searching: searchingSellersLinkedToManager.isLoading,
    },
    linkManagerAndSellers: {
      handle: linkManagerAndSellers,
      linking: linkingManagerAndSellers.isLoading,
    },
  };
}
