import React from "react";

import { Navigate, useNavigate } from "react-router-dom";

import { Button, Input, LinkButton, Select } from "../../../../components/Form";

import { useForm, useSelect } from "../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useChanges, useModal } from "../../../../hooks/contexts";

import { isValid } from "../../../../helpers/validations";

import { Circle } from "../../../../components/Loading";

import styles from "./UpdateFormula.module.css";

export function UpdateFormula({ researchFormulas, selectedFormula, groupOptions, groupFilters }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const { setIsChangesDetected } = useChanges();
  const updatingFormula = useLoading();
  const navigate = useNavigate();

  const formula = useForm({ type: "", required: true });
  const belongedGroup = useSelect({ type: "single", required: true, emptyMessage: "Selecione o grupo pertencente" });
  const status = useSelect({ type: "single", required: true });

  const optionsStatus = [
    {
      value: true,
      label: "ATIVO",
    },
    {
      value: false,
      label: "INATIVO",
    },
  ];

  const updateFormula = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const json = await customFetch("/products/registerAndUpdateFormula", {
          body: {
            idFormulado: selectedFormula.formulado.idFormulado,
            descricao: formula.value,
            idGrupo: belongedGroup.value.value.idGrupo,
            ativo: status.value.value,
          },
        });
        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
  }, [customFetch, selectedFormula, formula, belongedGroup, status]);

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (isValid(formula, belongedGroup)) {
        try {
          updatingFormula.setLoading(true);
          const json = await updateFormula();
          if (json.status === 200) {
            researchFormulas(groupFilters.formula, groupFilters.group, groupFilters.status);
            setIsChangesDetected(false);
            await Modal.success(json.message);
            navigate("/produtos/formulas");
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          updatingFormula.setLoading(false);
        }
      }
    },
    [
      formula,
      belongedGroup,
      updatingFormula,
      updateFormula,
      researchFormulas,
      groupFilters,
      setIsChangesDetected,
      Modal,
      navigate,
    ]
  );

  React.useEffect(() => {
    if (selectedFormula) {
      formula.setValue(selectedFormula.formulado.descricaoFormulado || "");
      belongedGroup.setValue(() => {
        return groupOptions.find((option) => {
          return option.value.idGrupo === selectedFormula.grupo.idGrupo;
        });
      });
      status.setValue(() => {
        return selectedFormula.formulado.situacaoFormulado ? optionsStatus[0] : optionsStatus[1];
      });
    }
  }, []); //eslint-disable-line

  if (!selectedFormula || !selectedFormula.formulado.idFormulado) {
    return <Navigate to="/produtos/formulas" />;
  }

  return (
    <div className={`container ${styles.container}`}>
      {!updatingFormula.isLoading ? (
        <>
          <div>
            <LinkButton to="/produtos/formulas" buttonStyle="backButton" />
          </div>
          <div>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className={styles.fieldsContainer}>
                <div>
                  <label htmlFor="formula" className="label">
                    Descrição
                  </label>
                  <Input
                    id="formula"
                    placeholder="Digite a fórmula"
                    value={formula.value}
                    error={formula.error}
                    onChange={(e) => {
                      formula.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={formula.onBlur}
                  />
                </div>
                <div>
                  <label htmlFor="belongedGroup" className="label">
                    Grupo
                  </label>
                  <Select
                    id="belongedGroup"
                    placeholder="Selecione o grupo"
                    options={groupOptions}
                    value={belongedGroup.value}
                    error={belongedGroup.error}
                    onChange={(value) => {
                      belongedGroup.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={belongedGroup.onBlur}
                  />
                </div>
                <div>
                  <label htmlFor="status" className="label">
                    Status da Fórmula
                  </label>
                  <Select
                    id="status"
                    options={optionsStatus}
                    value={status.value}
                    onChange={(value) => {
                      status.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
              </div>
              <div className={styles.submitButtonContainer}>
                <Button>Atualizar Fórmula</Button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Atualizando fórmula</span>
        </div>
      )}
    </div>
  );
}
