import React from "react";
import { Route, Routes } from "react-router-dom";

import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { useForm } from "../../../../../hooks/form";

import { ClientList } from "./ClientList/ClientList";
import { NewClient } from "./NewClient/NewClient";

export function Clients({ selectedPriceTable }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const filteredClients = useForm({ type: "", required: false });

  const [clients, setClients] = React.useState([]);

  const searchingClients = useLoading();

  const searchClients = React.useCallback(
    async (description = "", filterType) => {
      if (description === "" && filteredClients.value.length) filteredClients.reset();
      try {
        searchingClients.setLoading(true);
        const json = await customFetch("/products/searchClientsOfPriceTable", {
          body: {
            idTabela: Number(selectedPriceTable.tabela.idTabela),
            descricao: filterType === "name" ? description : "",
            descricaoCidade: filterType === "city" ? description : "",
          },
        });
        if (json.status === 200) {
          setClients(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setClients([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingClients.setLoading(false);
      }
    },
    [Modal, customFetch, filteredClients, searchingClients, selectedPriceTable]
  );

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <ClientList
              selectedPriceTable={selectedPriceTable}
              filteredClients={filteredClients}
              searchClients={searchClients}
              searchingClients={searchingClients}
              clients={clients}
            />
          }
        />
        <Route
          path="novo"
          element={<NewClient selectedPriceTable={selectedPriceTable} searchClients={searchClients} />}
        />
      </Routes>
    </div>
  );
}
