import React from "react";

import { UserPermissions } from "./UserPermissions/UserPermissions";

import { Select } from "../../../../components/Form/Select/Select";
import { Button, LinkButton } from "../../../../components/Form";
import { Input } from "../../../../components/Form";
import { InputMask } from "../../../../components/Form/InputMask/InputMask";

import { useDebounce, useCustomFetch } from "../../../../hooks/async";
import { useChanges, useModal } from "../../../../hooks/contexts";
import { useForm, useSelect } from "../../../../hooks/form";

import { isValid } from "../../../../helpers/validations";

import { Circle } from "../../../../components/Loading/Circle/Circle";

import styles from "./UpdateUser.module.css";
import { Route, Routes, useMatch, useNavigate } from "react-router-dom";
import { sleep } from "../../../../helpers/sleep";

export function UpdateUser({
  optionsEnterprise,
  optionsPortal,
  optionsSupervisor,
  isSearchingOptionsEnterprise,
  isSearchingOptionsPortal,
  isSearchingOptionsSupervisor,
}) {
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();
  const { isChangesDetected, setIsChangesDetected } = useChanges();

  const isUsersMainPage = useMatch({ path: "/admin/usuarios", end: true });
  const isPermissionsPage = useMatch({ path: "/admin/usuarios/permissoes", end: true });

  const [user, setUser] = React.useState(null);
  const [optionsUser, setOptionsUser] = React.useState([]);
  const [isSearchingOptionsUser, setIsSearchingOptionsUser] = React.useState(false);

  const enterprises = useSelect({ type: "multiple", required: true, emptyMessage: "Selecione ao menos uma empresa" });
  const portals = useSelect({ type: "multiple", required: true, emptyMessage: "Selecione ao menos um portal" });
  const supervisor = useSelect({ type: "single", required: true, emptyMessage: "Selecione um supervisor" });
  const defaultEnterprise = useSelect({
    type: "single",
    required: !!enterprises.value?.length,
    emptyMessage: "Selecione uma empresa padrão",
  });
  const defaultPortal = useSelect({
    type: "single",
    required: !!portals.value?.length,
    emptyMessage: "Selecione um portal padrão",
  });
  const name = useForm({ type: "", required: true });
  const email = useForm({ type: "email", required: true });
  const idSiagri = useForm({ type: "", required: false });
  const password = useForm({ type: "", required: false });
  const passwordConfirm = useForm({
    type: "",
    required: !!password.value?.length,
    customValidations: [
      {
        validate: (value) => value === password.value,
        message: "As senhas não correspondem",
      },
    ],
  });
  const [isSupervisor, setIsSupervisor] = React.useState(false);
  const [resetPassword, setResetPassword] = React.useState(false);
  const [isDeactivatedUser, setIsdeactivatedUser] = React.useState(false);
  const registration = useForm({ type: "number", required: false });
  const codeHPS = useForm({ type: "number", required: false });
  const countryCode = useForm({ type: "", required: false });
  const ddd = useForm({ type: "", required: false });
  const phone = useForm({ type: "", required: false });

  const [isSearchingUserData, setIsSearchingUserData] = React.useState(false);
  const [isUpdatingUser, setIsUpdatingUser] = React.useState(false);

  const clearForm = React.useCallback(() => {
    setUser(null);
    enterprises.setValue([]);
    enterprises.setError(null);
    portals.setValue([]);
    portals.setError(null);
    defaultEnterprise.setValue("");
    defaultEnterprise.setError(null);
    defaultPortal.setValue("");
    defaultPortal.setError(null);
    name.setValue("");
    name.setError(null);
    email.setValue("");
    email.setError(null);
    idSiagri.setValue("");
    idSiagri.setError(null);
    password.setValue("");
    password.setError(null);
    passwordConfirm.setValue("");
    passwordConfirm.setError(null);
    setIsSupervisor(false);
    setResetPassword(false);
    setIsdeactivatedUser(false);
    setIsChangesDetected(false);
    supervisor.reset();
    registration.reset();
    codeHPS.reset();
    countryCode.reset();
    ddd.reset();
    phone.reset();
  }, [
    defaultEnterprise,
    defaultPortal,
    email,
    enterprises,
    idSiagri,
    name,
    password,
    passwordConfirm,
    portals,
    setIsChangesDetected,
    supervisor,
    registration,
    codeHPS,
    countryCode,
    ddd,
    phone,
  ]);

  const searchUsers = useDebounce(async (value) => {
    try {
      setIsSearchingOptionsUser(true);
      const json = await customFetch("/admin/searchUsers", {
        body: {
          pesquisa: value || "%%%%",
        },
      });
      if (json.status === 200) {
        setOptionsUser(json.object);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      setIsSearchingOptionsUser(false);
    }
  });

  const setUserData = React.useCallback(
    (userData) => {
      const data = userData;
      name.setValue(data.usuario.nome || "");
      email.setValue(data.usuario.email || "");
      idSiagri.setValue(data.usuario.idSiagre || "");
      setIsSupervisor(data.usuario.flagSupervisor === "S");
      setIsdeactivatedUser(!(data.usuario.statusUsuario === "A"));

      const userEnterprises = optionsEnterprise.filter((item) => {
        return data.empresas.some((enterprise) => enterprise.id === item.value.idEmpresa);
      });

      const userPortals = optionsPortal.filter((item) => {
        return data.portais.some((portal) => portal.id === item.value.idPortal);
      });

      enterprises.setValue(userEnterprises);
      portals.setValue(userPortals);

      const userDefaultEnterprise = userEnterprises.find(
        (item) => item.value.idEmpresa === data.usuario.idEmpresaPadrao
      );

      const userDefaultPortal = userPortals.find((item) => item.value.idPortal === data.usuario.portalPadrao);

      defaultEnterprise.setValue(userDefaultEnterprise, false);
      defaultPortal.setValue(userDefaultPortal, false);

      const userSupervisor = optionsSupervisor.find((item) => data.usuario.idSupervisor === item.value.id);

      supervisor.setValue(userSupervisor || "", false);
      registration.setValue(data.usuario.matricula || "");
      codeHPS.setValue(data.usuario.idUsuarioHPS || "");

      countryCode.setValue(data.usuario.codigoPais || "");
      ddd.setValue(data.usuario.ddd || "");
      phone.setValue(data.usuario.telefone || "");
    },
    [
      defaultEnterprise,
      defaultPortal,
      email,
      enterprises,
      idSiagri,
      name,
      optionsEnterprise,
      optionsPortal,
      optionsSupervisor,
      portals,
      supervisor,
      registration,
      codeHPS,
      countryCode,
      ddd,
      phone,
    ]
  );

  const searchUserData = React.useCallback(
    async (user) => {
      try {
        setIsSearchingUserData(true);
        const json = await customFetch("/admin/searchUserData", {
          body: {
            idUsuario: user.value.idUsuario,
          },
        });
        if (json.status === 200) {
          setUserData(json.object);
          const storedData = {
            user,
            ...json.object,
          };
          window.sessionStorage.setItem("userData", JSON.stringify(storedData));
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        setIsSearchingUserData(false);
      }
    },
    [customFetch, setUserData, Modal]
  );

  const handleChangeUser = React.useCallback(
    async (target) => {
      if (isChangesDetected) {
        const confirm = await Modal.confirm(
          "Você realizou mudanças que ainda não foram salvas!<br>Deseja continuar mesmo assim?"
        );
        if (confirm) {
          window.sessionStorage.removeItem("userData");
          clearForm();
          setUser(target);
        } else {
          return;
        }
      } else {
        window.sessionStorage.removeItem("userData");
        setUser(target);
      }
      if (target) searchUserData(target);
      else {
        window.sessionStorage.removeItem("userData");
        clearForm();
      }
      setIsChangesDetected(false);
      await sleep(0);
      if (!isUsersMainPage) {
        navigate("/admin/usuarios");
      }
    },
    [isChangesDetected, searchUserData, setIsChangesDetected, Modal, clearForm, isUsersMainPage, navigate]
  );

  const handleChangeEnterprises = React.useCallback(
    async (value) => {
      const options = value.sort((a, b) => a.value.idEmpresa - b.value.idEmpresa);
      enterprises.setValue(options);

      if (
        (defaultEnterprise.value && !options.some((option) => option.label === defaultEnterprise.value.label)) ||
        !options.length
      ) {
        defaultEnterprise.setValue("");
        defaultEnterprise.setError(null);
      }

      setIsChangesDetected(true);
    },
    [enterprises, defaultEnterprise, setIsChangesDetected]
  );

  const handleChangePortals = React.useCallback(
    (value) => {
      const options = value.sort((a, b) => a.value.idPortal - b.value.idPortal);
      portals.setValue(options);

      if (
        (defaultPortal.value && !options.some((option) => option.label === defaultPortal.value.label)) ||
        !options.length
      ) {
        defaultPortal.setValue("");
        defaultPortal.setError(null);
      }

      setIsChangesDetected(true);
    },
    [portals, defaultPortal, setIsChangesDetected]
  );

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      setIsUpdatingUser(true);
      if (
        isValid(
          name,
          email,
          enterprises,
          portals,
          defaultEnterprise,
          defaultPortal,
          passwordConfirm,
          registration,
          codeHPS,
          countryCode,
          ddd,
          phone
        )
      ) {
        try {
          const json = await customFetch("/admin/registerAndUpdateUser", {
            body: {
              idSiagri: Number(idSiagri.value),
              idUsuario: user.value.idUsuario,
              empresaPadraoPortalAntigo: defaultEnterprise.value?.value.idEmpresa,
              portalPadraoAntigo: defaultPortal.value?.value.idPortal,
              empresasPadroes: enterprises.value?.map((enterprise) => enterprise.value.idEmpresa) || [],
              portalPadroes: portals.value?.map((portal) => portal.value.idPortal) || [],
              email: email.value,
              nome: name.value,
              senhaNova: password.value || null,
              senhaNovaNovamente: passwordConfirm.value || null,
              resetarSenhaAntiga: resetPassword,
              eSupervisor: isSupervisor,
              statusUsuario: !isDeactivatedUser,
              matricula: registration.value,
              idSupervisor: supervisor?.value?.value?.id ?? null,
              idUsuarioHPS: Number(codeHPS.value),
              codigoPaisTelefone: countryCode.value,
              dddTelefone: ddd.value,
              telefone: phone.value,
            },
          });
          if (json.status === 200) {
            Modal.success(json.message);
            clearForm();
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        }
      }
      setIsUpdatingUser(false);
    },
    [
      Modal,
      defaultEnterprise,
      defaultPortal,
      email,
      enterprises,
      idSiagri,
      isSupervisor,
      isDeactivatedUser,
      resetPassword,
      name,
      password,
      passwordConfirm,
      portals,
      user,
      clearForm,
      customFetch,
      supervisor,
      registration,
      codeHPS,
      countryCode,
      ddd,
      phone,
    ]
  );

  React.useEffect(() => {
    if (!isUsersMainPage) {
      navigate("/admin/usuarios");
    }
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (isUsersMainPage) {
      const userData = sessionStorage.getItem("userData");
      if (userData !== null) {
        const json = JSON.parse(userData);
        setUser(json.user);
        setUserData(json);
      }
    }
  }, [navigate]); // eslint-disable-line

  return (
    <div className={styles.container}>
      {!isUpdatingUser ? (
        <>
          <div className={styles.navigationButton}>
            {isPermissionsPage ? <LinkButton to="/admin/usuarios" buttonStyle="backButton" /> : null}
            <LinkButton to="/admin/usuarios/novo" classNameContainer={styles.newUserButton}>
              Novo Usuário
            </LinkButton>
          </div>
          <div>
            <div className={styles.selectUserContainer}>
              <Select
                placeholder="Pesquise um usuário..."
                options={optionsUser.map((option) => {
                  return { value: { ...option }, label: `${option.idUsuario} | ${option.nome}` };
                })}
                value={user}
                onChange={handleChangeUser}
                onInputChange={(value) => searchUsers(value)}
                noOptionsMessage={() => "Nenhum usuário encontrado"}
                loadingMessage={() => "Buscando..."}
                isLoading={isSearchingOptionsUser}
              />
            </div>
            <div className={styles.formsContainer}>
              {user && !isSearchingUserData ? (
                <Routes>
                  <Route
                    path="/"
                    element={
                      <>
                        <div>
                          <LinkButton
                            to="permissoes"
                            classNameContainer={styles.alterPermissionsButtonContainer}
                            className={styles.alterPermissionsButton}>
                            Alterar Permissões
                          </LinkButton>
                        </div>
                        <form className={styles.form} onSubmit={handleSubmit} autoComplete="off">
                          <div>
                            <fieldset className={`${styles.userDetailsContainer} ${styles.fieldset}`}>
                              <legend className={styles.fieldsetLegend}>Detalhes do Usuário</legend>
                              <div>
                                <label className={`label`}>Nome</label>
                                <Input
                                  placeholder="Digite o nome do usuário"
                                  value={name.value}
                                  onChange={(e) => {
                                    name.onChange(e);
                                    setIsChangesDetected(true);
                                  }}
                                  onBlur={name.onBlur}
                                  error={name.error}
                                />
                              </div>
                              <div>
                                <label className={`label`}>E-mail</label>
                                <Input
                                  placeholder="Digite o email do usuário"
                                  value={email.value}
                                  onChange={(e) => {
                                    email.onChange(e);
                                    setIsChangesDetected(true);
                                  }}
                                  onBlur={email.onBlur}
                                  error={email.error}
                                />
                              </div>
                              <div className={styles.userOptionsContainer}>
                                <div>
                                  <label className={`label`}>ID do Siagri</label>
                                  <Input
                                    className={`cleanInputNumber`}
                                    type="number"
                                    placeholder="ID"
                                    value={idSiagri.value}
                                    onChange={(e) => {
                                      idSiagri.onChange(e);
                                      setIsChangesDetected(true);
                                    }}
                                    onBlur={idSiagri.onBlur}
                                    error={idSiagri.error}
                                    disabled
                                  />
                                </div>
                                <div className={`${styles.checkboxContainer}`}>
                                  <label>
                                    <input
                                      type="checkbox"
                                      value={isSupervisor}
                                      onChange={() => {
                                        setIsSupervisor(!isSupervisor);
                                        setIsChangesDetected(true);
                                      }}
                                      checked={isSupervisor}
                                    />
                                    Definir usuário como Supervisor
                                  </label>
                                  <label>
                                    <input
                                      type="checkbox"
                                      value={resetPassword}
                                      onChange={() => {
                                        setResetPassword(!resetPassword);
                                        setIsChangesDetected(true);
                                      }}
                                      checked={resetPassword}
                                    />
                                    Resetar senha antiga do usuário
                                  </label>
                                  <label>
                                    <input
                                      type="checkbox"
                                      value={isDeactivatedUser}
                                      onChange={() => {
                                        setIsdeactivatedUser(!isDeactivatedUser);
                                        setIsChangesDetected(true);
                                      }}
                                      checked={isDeactivatedUser}
                                    />
                                    Desativar usuario
                                  </label>
                                </div>
                              </div>
                              <div className={styles.userPasswordContainer}>
                                <div>
                                  <label className={`label`}>Nova Senha</label>
                                  <Input
                                    type="password"
                                    placeholder="Digite a nova senha do usuário"
                                    value={password.value}
                                    onChange={(e) => {
                                      password.onChange(e);
                                      setIsChangesDetected(true);
                                    }}
                                    onBlur={password.onBlur}
                                    error={password.error}
                                    autoComplete="new-password"
                                  />
                                </div>
                                <div>
                                  <label className={`label`}>Confirmar Nova Senha</label>
                                  <Input
                                    type="password"
                                    placeholder="Confirme a nova senha do usuário"
                                    value={passwordConfirm.value}
                                    onChange={(e) => {
                                      passwordConfirm.onChange(e);
                                      setIsChangesDetected(true);
                                    }}
                                    onBlur={passwordConfirm.onBlur}
                                    error={passwordConfirm.error}
                                    autoComplete="new-password"
                                  />
                                </div>
                              </div>
                            </fieldset>
                          </div>
                          <div className={`${styles.permissionsContainer}`}>
                            <fieldset className={`${styles.fieldset} ${styles.optionsPermissionsContainer}`}>
                              <legend className={styles.fieldsetLegend}>Permissões de Empresas</legend>
                              <div className={styles.selectMultipleContainer}>
                                <label className={`label`}>Empresas</label>
                                <Select
                                  placeholder="Selecione as empresas que serão liberadas para o usuário"
                                  value={enterprises.value}
                                  error={enterprises.error}
                                  onChange={handleChangeEnterprises}
                                  onBlur={enterprises.onBlur}
                                  options={optionsEnterprise}
                                  noOptionsMessage={() => "Nenhuma empresa foi encontrada"}
                                  closeMenuOnSelect={false}
                                  isLoading={isSearchingOptionsEnterprise}
                                  isMulti
                                />
                              </div>
                              <div>
                                <label className={`label`}>Empresa Padrão</label>
                                <Select
                                  placeholder="Selecione uma empresa"
                                  options={enterprises.value}
                                  value={defaultEnterprise.value}
                                  error={defaultEnterprise.error}
                                  onChange={(value) => {
                                    defaultEnterprise.setValue(value);
                                    setIsChangesDetected(true);
                                  }}
                                  onBlur={defaultEnterprise.onBlur}
                                  noOptionsMessage={() => "Nenhuma empresa foi permitida ao usuário"}
                                  isDisabled={!enterprises.value.length}
                                />
                              </div>
                            </fieldset>
                            <fieldset className={`${styles.fieldset} ${styles.optionsPermissionsContainer}`}>
                              <legend className={styles.fieldsetLegend}>Permissões de Portais</legend>
                              <div className={styles.selectMultipleContainer}>
                                <label className={`label`}>Portais</label>
                                <Select
                                  placeholder="Selecione os portais que serão liberados para o usuário"
                                  value={portals.value}
                                  error={portals.error}
                                  onChange={handleChangePortals}
                                  onBlur={portals.onBlur}
                                  options={optionsPortal}
                                  noOptionsMessage={() => "Nenhum portal foi encontrado"}
                                  closeMenuOnSelect={false}
                                  isLoading={isSearchingOptionsPortal}
                                  isMulti
                                />
                              </div>
                              <div>
                                <label className={`label`}>Portal Padrão</label>
                                <Select
                                  placeholder="Selecione um portal"
                                  options={portals.value}
                                  value={defaultPortal.value}
                                  error={defaultPortal.error}
                                  onChange={(value) => {
                                    defaultPortal.setValue(value);
                                    setIsChangesDetected(true);
                                  }}
                                  onBlur={defaultPortal.onBlur}
                                  noOptionsMessage={() => "Nenhum portal foi permitido ao usuário"}
                                  isDisabled={!portals.value.length}
                                />
                              </div>
                            </fieldset>
                          </div>
                          <div className={styles.othersContainer}>
                            <fieldset className={`${styles.fieldset}`}>
                              <legend className={styles.fieldsetLegend}>Outras Opções</legend>
                              <div className={styles.supervisorContainer}>
                                <label className="label">Supervisor</label>
                                <Select
                                  placeholder="Selecione o supervisor do usuário"
                                  value={supervisor.value}
                                  //error={supervisor.error}
                                  onChange={(value) => {
                                    supervisor.onChange(value);
                                    setIsChangesDetected(true);
                                  }}
                                  onBlur={supervisor.onBlur}
                                  options={optionsSupervisor}
                                  noOptionsMessage={() => "Nenhum supervisor foi encontrado"}
                                  isLoading={isSearchingOptionsSupervisor}
                                />
                              </div>
                              <div>
                                <label className="label">Matrícula</label>
                                <Input
                                  placeholder="Digite a matrícula do usuário"
                                  type="number"
                                  className="cleanInputNumber"
                                  value={registration.value}
                                  error={registration.error}
                                  onChange={(e) => {
                                    registration.onChange(e);
                                    setIsChangesDetected(true);
                                  }}
                                  onBlur={registration.onBlur}
                                />
                              </div>
                              <div>
                                <label className="label">Código HPS</label>
                                <Input
                                  placeholder="Digite o código HPS do usuário"
                                  type="number"
                                  className="cleanInputNumber"
                                  value={codeHPS.value}
                                  error={codeHPS.error}
                                  onChange={(e) => {
                                    codeHPS.onChange(e);
                                    setIsChangesDetected(true);
                                  }}
                                  onBlur={codeHPS.onBlur}
                                />
                              </div>
                            </fieldset>
                          </div>
                          <div className={styles.phoneContainer}>
                            <fieldset className={`${styles.fieldset}`}>
                              <legend className={styles.fieldsetLegend}>Telefone</legend>
                              <div>
                                <label className="label">Código do País</label>
                                <InputMask
                                  mask={"+99"}
                                  placeholder="+XX"
                                  value={countryCode.value}
                                  onChange={(e) => {
                                    countryCode.onChange(e);
                                    setIsChangesDetected(true);
                                  }}
                                />
                              </div>
                              <div>
                                <label className="label">DDD</label>
                                <InputMask
                                  mask={"99"}
                                  placeholder="XX"
                                  value={ddd.value}
                                  onChange={(e) => {
                                    ddd.onChange(e);
                                    setIsChangesDetected(true);
                                  }}
                                />
                              </div>
                              <div className={styles.phoneFieldContainer}>
                                <label className="label">Telefone</label>
                                <InputMask
                                  mask={"\\9 9999-9999"}
                                  placeholder="X XXXX-XXXX"
                                  value={phone.value}
                                  onChange={(e) => {
                                    phone.onChange(e);
                                    setIsChangesDetected(true);
                                  }}
                                />
                              </div>
                            </fieldset>
                          </div>
                          <div className={styles.submitButtonContainer}>
                            <Button disabled={!isChangesDetected}>Atualizar Usuário</Button>
                          </div>
                        </form>
                      </>
                    }
                  />
                  <Route path="permissoes" element={<UserPermissions user={user} />} />
                </Routes>
              ) : isSearchingUserData ? (
                <div className={styles.searchingUserDataLoading}>
                  <Circle size={100} />
                </div>
              ) : (
                <div className={styles.unselectedUserMessage}>Selecione um usuário</div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={styles.updatingUserLoadingContainer}>
          <Circle size={100} />
          <span>Atualizando usuário</span>
        </div>
      )}
    </div>
  );
}
