import { useState } from "react";
import { IUseContratoAnexado } from "./types";
import { useModal } from "../../../../../hooks/contexts";
import { useLoading } from "../../../../../hooks/async";
import { useNavigate } from "react-router-dom";
import { IUseContrato } from "../../hooks/types";

interface IProps {
  contratosData: IUseContrato;
}

export function useContratoAnexado({ contratosData }: IProps): IUseContratoAnexado {
  const Modal = useModal();
  const navigate = useNavigate();

  const [file, setFile] = useState<File | null>(null);

  const uploading = useLoading();

  const handleUpload = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!file) return;
    const data = new FormData(e.target as HTMLFormElement);

    try {
      uploading.setLoading(true);
      const response = await fetch("/juridico/uploadContrato", {
        method: "POST",
        body: data,
      });
      const json = (await response.json()) as DefaultFetchResponse<string>;
      if (json.status === 200) {
        contratosData.searchs.searchContratosPaginated(contratosData.searchs.pagination.currentPage - 1);
        await Modal.success(json.message);
        navigate("/contratos-fornecedor-cliente/contrato");
      } else {
        Modal.error(json.message, json.object);
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      uploading.setLoading(false);
    }
  };

  return {
    file: {
      value: file,
      onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
        setFile(event.target.files ? event.target.files[0] : null),
    },
    handleUpload,
    uploading: uploading.isLoading,
  };
}
