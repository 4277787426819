import React from "react";

import styles from "./ContractConference.module.css";

interface Props {
  pages: ({
    name: string;
    page: React.ReactNode;
  } | null)[];
}

export function ContractConference({ pages }: Props) {
  const childrens = pages.map(
    (page) =>
      page && (
        <fieldset className="fieldset" key={page.name}>
          <legend className="fieldsetLegend">{page.name}</legend>
          {page.page}
        </fieldset>
      )
  );

  return <div className={styles.conferenceContainer}>{childrens}</div>;
}
