import React from "react";
import { Button, Input, LinkButton, Select } from "../../../../components/Form";

import styles from "./UpdateCategory.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import { useForm, useSelect } from "../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { isValid } from "../../../../helpers/validations";
import { Circle } from "../../../../components/Loading";

export function UpdateCategory({ category, searchCategories, filteredCategory }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const updatingCategory = useLoading();

  const description = useForm({ type: "text", required: true });
  const maxValue = useForm({ type: "number", required: true });
  const situation = useSelect({ type: "single", required: true });

  const situationOptions = [
    {
      value: "A",
      label: "ATIVO",
    },
    {
      value: "I",
      label: "INATIVO",
    },
  ];

  const onUpdateCategory = React.useCallback(async () => {
    if (isValid(description, maxValue)) {
      try {
        updatingCategory.setLoading(true);
        const json = await customFetch("/rdv/insertAndUpdateCategory", {
          body: {
            id: category.id,
            descricao: description.value,
            valorMaximo: Number(maxValue.value),
            status: situation.value.value,
          },
        });
        if (json.status === 200) {
          searchCategories(filteredCategory);
          await Modal.success(json.message);
          navigate("/rdv/categorias");
        } else {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        updatingCategory.setLoading(false);
      }
    }
  }, [
    Modal,
    category,
    customFetch,
    description,
    maxValue,
    navigate,
    searchCategories,
    situation,
    updatingCategory,
    filteredCategory,
  ]);

  React.useEffect(() => {
    if (category) {
      description.setValue(category.descricao);
      maxValue.setValue(category.valorMaximo);
      if (category.status === "A" || category.status === "Ativo") {
        situation.setValue(situationOptions[0]);
      } else {
        situation.setValue(situationOptions[1]);
      }
    }
  }, []); // eslint-disable-line

  if (!category) return <Navigate to={"/rdv/categorias"} />;

  return (
    <div className="container">
      {!updatingCategory.isLoading ? (
        <>
          <div className={`${styles.navContainer}`}>
            <LinkButton to="/rdv/categorias" buttonStyle="backButton" />
            <h2>{category.descricao}</h2>
            <div></div>
          </div>
          <div className={`${styles.fieldsContainer}`}>
            <div>
              <label className="label" htmlFor="description">
                Descrição
              </label>
              <Input
                id="description"
                placeholder="Digite a descrição da categoria"
                value={description.value}
                error={description.error}
                onChange={description.onChange}
                onBlur={description.onBlur}
              />
            </div>
            <div>
              <label className="label" htmlFor="maxValue">
                Valor Máximo
              </label>
              <Input
                id="maxValue"
                className="cleanInputNumber"
                placeholder="Digite o valor máximo"
                type="number"
                value={maxValue.value}
                error={maxValue.error}
                onChange={maxValue.onChange}
                onBlur={maxValue.onBlur}
              />
            </div>
            <div>
              <label className="label" htmlFor="situation">
                Situação
              </label>
              <Select
                id="situation"
                options={situationOptions}
                value={situation.value}
                onChange={(value) => {
                  situation.onChange(value);
                }}
                isSearchable={false}
                isClearable={false}
              />
            </div>
          </div>
          <div className={`${styles.saveButtonContainer}`}>
            <Button onClick={onUpdateCategory}>Salvar</Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Atualizando categoria</span>
        </div>
      )}
    </div>
  );
}
