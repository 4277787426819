import React from "react";
import { Button } from "../../../components/Form";
import { TModalMessage, TModalOnContinue } from "./Modal";

import modalStyles from "./styles/modal.module.css";
import alertStyles from "./styles/alert.module.css";

interface IModalAlertProps {
  message: TModalMessage;
  onContinue: TModalOnContinue;
}

const ModalAlert = ({ message, onContinue }: IModalAlertProps) => {
  return (
    <div className={`${modalStyles.container} ${alertStyles.container}`}>
      {typeof message === "string" && (
        <p className={modalStyles.message} dangerouslySetInnerHTML={{ __html: message }}></p>
      )}
      <div className={modalStyles.buttonContainer}>
        <Button className={modalStyles.button} onClick={() => onContinue()} variant="alert">
          OK
        </Button>
      </div>
    </div>
  );
};

export { ModalAlert };
