import React from "react";
import { ReactComponent as ArrowUp } from "../assets/up-arrow.svg";
import { ReactComponent as ArrowDown } from "../assets/down-arrow.svg";

import { TSortedState } from "..";

import styles from "./Arrows.module.css";

interface IProps extends React.HTMLProps<HTMLSpanElement> {
  sortTypeActivated: TSortedState;
  width?: number;
  height?: number;
}

export function Arrows({ sortTypeActivated, height = 16, width = 16 }: IProps) {
  return (
    <span className={styles.main} style={{ width: `${width / 16}rem`, height: `${height / 16}rem` }}>
      <ArrowUp fill={sortTypeActivated === "ascending" ? "#000000" : "#cccccc"} className={styles.arrowUp} />
      <ArrowDown
        fill={sortTypeActivated === "descending" ? "#000000" : "#cccccc"}
        className={styles.arrowDown}
        style={{ left: `${width / 2 / 16}rem` }}
      />
    </span>
  );
}
