import React, { useRef } from "react";

import { Route, Routes } from "react-router-dom";

import { useForm, useSelect } from "../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { usePagination } from "../../../../hooks/pagination";

import { PendingRequestsList } from "./PendingRequestsList/PendingRequestsList";
import { RequestedPremiations } from "./RequestedPremiations/RequestedPremiations";

export function PremiationsCommercialRequests() {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const scrollPosition = useRef(0);

  const pagination = usePagination(100);

  const filteredManager = useSelect({ type: "single", required: false });
  const filteredCustomer = useSelect({ type: "single", required: false });
  const filteredSolicitation = useForm({ required: false });

  const [managerOptions, setManagerOptions] = React.useState([]);
  const [customerOptions, setCustomerOptions] = React.useState([]);
  const [requestList, setRequestList] = React.useState([]);

  const [selectedRequest, setSelectedRequest] = React.useState(null);

  const searchingManagers = useLoading();
  const searchingCustomers = useLoading();
  const searchingRequests = useLoading();

  const searchManagers = React.useCallback(
    async (manager = "") => {
      try {
        searchingManagers.setLoading(true);
        const json = await customFetch(
          "/premiations/commercial/searchManagers",
          {
            body: {
              nome: manager,
            },
          }
        );
        if (json.status === 200) {
          const options = json.object.map((option) => {
            return {
              value: option,
              label: `${option.idUsuario} | ${option.nome}`,
            };
          });
          setManagerOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setManagerOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingManagers.setLoading(false);
      }
    },
    [Modal, customFetch, searchingManagers]
  );

  const searchCustomers = React.useCallback(
    async (customer = "") => {
      try {
        searchingCustomers.setLoading(true);
        const json = await customFetch("/premiations/manager/searchClients", {
          body: {
            descricao: customer,
          },
        });
        if (json.status === 200) {
          const options = json.object.map((option) => {
            return {
              value: option,
              label: `${option.cliente.idCliente} | ${option.cliente.razaoSocial}`,
            };
          });
          setCustomerOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setCustomerOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingCustomers.setLoading(false);
      }
    },
    [Modal, customFetch, searchingCustomers]
  );

  const searchTotalRequestRecordsPromise = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const json = await customFetch(
          "/premiations/commercial/searchTotalRequestRecords",
          {
            body: {
              tamanho: pagination.maxItems,
              idUsuario: filteredManager.value?.value
                ? Number(filteredManager.value?.value.idUsuario)
                : null,
              idSolicitacao: filteredSolicitation.value
                ? Number(filteredSolicitation.value)
                : null,
              idCliente: filteredCustomer.value?.value
                ? Number(filteredCustomer.value?.value.cliente.idCliente)
                : null,
            },
          }
        );
        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
  }, [
    customFetch,
    filteredCustomer.value,
    filteredManager.value,
    filteredSolicitation.value,
    pagination.maxItems,
  ]);

  const searchRequestsPromise = React.useCallback(
    (page = 1) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch(
            "/premiations/commercial/searchRequestsPaginated",
            {
              body: {
                pagina: page - 1,
                tamanho: 100,
                idUsuario: filteredManager.value?.value
                  ? Number(filteredManager.value?.value.idUsuario)
                  : null,
                idSolicitacao: filteredSolicitation.value
                  ? Number(filteredSolicitation.value)
                  : null,
                idCliente: filteredCustomer.value?.value
                  ? Number(filteredCustomer.value?.value.cliente.idCliente)
                  : null,
              },
            }
          );
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [
      customFetch,
      filteredManager.value,
      filteredSolicitation.value,
      filteredCustomer.value,
    ]
  );

  const searchRequests = React.useCallback(
    async (page) => {
      try {
        searchingRequests.setLoading(true);
        const json = await searchRequestsPromise(page);
        if (json.status === 200) {
          setRequestList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setRequestList([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingRequests.setLoading(false);
      }
    },
    [Modal, searchRequestsPromise, searchingRequests]
  );

  const searchTotalRequestRecordsAndRequests = React.useCallback(async () => {
    try {
      searchingRequests.setLoading(true);
      const jsonRequests = await searchRequestsPromise();
      if (jsonRequests.status === 200) {
        const jsonRecords = await searchTotalRequestRecordsPromise();
        if (jsonRecords.status === 200) {
          setRequestList(jsonRequests.object);
          pagination.setTotalRecords(jsonRecords.object.total);
        } else if (jsonRecords.status === 500) {
          Modal.error(jsonRecords.message, jsonRecords.object);
        } else {
          setRequestList([]);
        }
      } else if (jsonRequests.status === 500) {
        Modal.error(jsonRequests.message, jsonRequests.object);
      } else {
        setRequestList([]);
        pagination.reset();
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      pagination.setCurrentPage(1);
      searchingRequests.setLoading(false);
    }
  }, [
    Modal,
    pagination,
    searchRequestsPromise,
    searchTotalRequestRecordsPromise,
    searchingRequests,
  ]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PendingRequestsList
            scrollPosition={scrollPosition}
            filteredManager={filteredManager}
            filterCustomer={filteredCustomer}
            filteredSolicitation={filteredSolicitation}
            managerOptions={{
              value: managerOptions,
              setValue: setManagerOptions,
              isSearching: searchingManagers.isLoading,
            }}
            customerOptions={{
              value: customerOptions,
              setValue: setCustomerOptions,
              isSearching: searchingCustomers.isLoading,
            }}
            requestList={{
              value: requestList,
              setValue: setRequestList,
              isSearching: searchingRequests.isLoading,
            }}
            searchManagers={searchManagers}
            searchCustomers={searchCustomers}
            searchTotalRequestRecordsAndRequests={
              searchTotalRequestRecordsAndRequests
            }
            searchRequests={searchRequests}
            pagination={pagination}
            setSelectedRequest={setSelectedRequest}
          />
        }
      />
      <Route
        path="premiacoes"
        element={
          <RequestedPremiations
            selectedRequest={selectedRequest}
            searchTotalRequestRecordsAndRequests={
              searchTotalRequestRecordsAndRequests
            }
          />
        }
      />
    </Routes>
  );
}
