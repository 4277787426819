import React from "react";
import { Button, InputMask, LinkButton, Select } from "../../../../components/Form";
import { useForm, useSelect } from "../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { useNavigate } from "react-router-dom";
import { isValid } from "../../../../helpers/validations";
import { Circle } from "../../../../components/Loading";
import { IPeople } from "../../types";

import styles from "./NewUserDocument.module.css";

interface IProps {
  searchTotalUserDocumentRecordsAndUserDocuments: () => void;
}

export function NewUserDocument({ searchTotalUserDocumentRecordsAndUserDocuments }: IProps) {
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();

  const user = useSelect({ type: "single", required: true });
  const cpf = useForm({ required: false });
  const cnpj = useForm({ required: false });

  const [userOptions, setUserOptions] = React.useState<ISelectOption<IPeople>[]>([]);

  const searchingUsers = useLoading();
  const savingUserDocument = useLoading();

  const searchUsers = React.useCallback(async () => {
    try {
      searchingUsers.setLoading(true);
      const json = (await customFetch("/finance/searchUsers", {
        body: {
          id: 0,
          paginacao: {
            paginaNumero: 0,
            paginaTamanho: 0,
          },
        },
      })) as DefaultFetchResponse<IPeople[]>;
      if (json.status === 200) {
        const options = json.object.map((item) => ({
          value: item,
          label: `${item.idUsuario} | ${item.nome}`,
        }));
        setUserOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setUserOptions([]);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingUsers.setLoading(false);
    }
  }, [Modal, customFetch, searchingUsers]);

  const saveUserDocument = React.useCallback(async () => {
    if (!isValid(user, cpf, cnpj)) return;
    try {
      savingUserDocument.setLoading(true);
      const json = (await customFetch("/finance/insertAndUpdateUserDocument", {
        body: {
          usuarioDocumentoId: 0,
          usuarioId: user.value?.value.idUsuario,
          cpf: cpf.value.replace(/\D/g, ""),
          cnpj: cnpj.value.replace(/\D/g, ""),
          status: "A",
        },
      })) as DefaultFetchResponse<null>;
      if (json.status === 200) {
        searchTotalUserDocumentRecordsAndUserDocuments();
        await Modal.success(json.message);
        navigate("/financeiro/documentos");
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      savingUserDocument.setLoading(false);
    }
  }, [
    user,
    cpf,
    cnpj,
    savingUserDocument,
    customFetch,
    searchTotalUserDocumentRecordsAndUserDocuments,
    Modal,
    navigate,
  ]);

  React.useEffect(() => {
    searchUsers();
  }, []); // eslint-disable-line

  return (
    <div className="container">
      {!savingUserDocument.isLoading ? (
        <>
          <div>
            <LinkButton to={"/financeiro/documentos"} buttonStyle="backButton" />
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fieldsContainer__userField}>
              <label htmlFor="user" className="label">
                Usuário
              </label>
              <Select
                id="user"
                placeholder="Selecione um usuário"
                value={user.value}
                error={user.error}
                onChange={user.onChange}
                onBlur={user.onBlur}
                options={userOptions}
                isLoading={searchingUsers.isLoading}
              />
            </div>
            <div>
              <label htmlFor="cpf" className="label">
                CPF
              </label>
              <InputMask
                id="cpf"
                placeholder="Digite o CPF do usuário selecionado"
                mask={"999.999.999-99"}
                value={cpf.value}
                error={cpf.error}
                onChange={cpf.onChange}
                onBlur={cpf.onChange}
              />
            </div>
            <div>
              <label htmlFor="cnpj" className="label">
                CNPJ
              </label>
              <InputMask
                id="cnpj"
                placeholder="Digite o CNPJ do usuário selecionado"
                mask={"99.999.999/9999-99"}
                value={cnpj.value}
                error={cnpj.error}
                onChange={cnpj.onChange}
                onBlur={cnpj.onChange}
              />
            </div>
          </div>
          <div className={styles.saveButtonContainer}>
            <Button onClick={saveUserDocument}>Salvar Documento</Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Salvando Novo Documento</span>
        </div>
      )}
    </div>
  );
}
