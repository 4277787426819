import React from "react";

import { Route, Routes } from "react-router-dom";

import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { useForm, useSelect } from "../../../../../hooks/form";

import { ItemsList } from "./ItemsList/ItemsList";
import { NewItem } from "./NewItem/NewItem";
import { UpdateItem } from "./UpdateItem/UpdateItem";

export function Items({ selectedPriceTable }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const enterprise = useSelect({ type: "single", required: true });
  const filteredItems = useForm({ type: "", required: false });

  const [selectedItem, setSelectedItem] = React.useState(null);
  const [enterpriseOptions, setEnterpriseOptions] = React.useState([]);
  const [items, setItems] = React.useState([]);

  const searchingEnterpriseOptions = useLoading();
  const searchingItems = useLoading();

  const searchEnterpriseOptions = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const json = await customFetch("/products/searchReleasedEnterprisesInItems", {
          body: {
            descricao: "",
          },
        });
        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
  }, [customFetch]);

  const searchItems = React.useCallback(
    async (description = "", enterpriseId) => {
      if (description === "") {
        filteredItems.reset();
      }
      try {
        searchingItems.setLoading(true);
        const json = await customFetch("/products/searchItemsOfPriceTable", {
          body: {
            idTabela: Number(selectedPriceTable.tabela.idTabela),
            idEmpresa: enterpriseId,
            descricaoItem: description,
          },
        });
        if (json.status === 200) {
          setItems(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setItems([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingItems.setLoading(false);
      }
    },
    [Modal, customFetch, searchingItems, selectedPriceTable, filteredItems]
  );

  const searchEnterpriseOptionsAndItems = React.useCallback(async () => {
    try {
      searchingEnterpriseOptions.setLoading(true);
      const jsonEnterprise = await searchEnterpriseOptions();
      if (jsonEnterprise.status === 200) {
        const options = jsonEnterprise.object.empresas.map((option) => {
          return {
            value: { ...option },
            label: `${option.idEmpresa} - ${option.nomeEmpresa}`,
          };
        });
        setEnterpriseOptions(options);

        let defaultEnterprise = null;

        if (jsonEnterprise.object.empresaPadrao) {
          defaultEnterprise = options.find((option) => option.value.idEmpresa === jsonEnterprise.object.empresaPadrao);
          enterprise.setValue(defaultEnterprise);
        } else {
          enterprise.setValue(options[0]);
        }

        searchItems("", defaultEnterprise.value.idEmpresa);
      } else if (jsonEnterprise.status === 500) {
        Modal.error(jsonEnterprise.message, jsonEnterprise.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingEnterpriseOptions.setLoading(false);
    }
  }, [Modal, enterprise, searchEnterpriseOptions, searchItems, searchingEnterpriseOptions]);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <ItemsList
              enterprise={enterprise}
              enterpriseOptions={enterpriseOptions}
              searchEnterpriseOptionsAndItems={searchEnterpriseOptionsAndItems}
              searchingEnterpriseOptions={searchingEnterpriseOptions}
              filteredItems={filteredItems}
              searchItems={searchItems}
              searchingItems={searchingItems}
              items={items}
              setSelectedItem={setSelectedItem}
            />
          }
        />
        <Route
          path="novo"
          element={
            <NewItem selectedPriceTable={selectedPriceTable} enterprise={enterprise} searchItems={searchItems} />
          }
        />
        <Route
          path="atualizar"
          element={
            <UpdateItem
              selectedPriceTable={selectedPriceTable}
              selectedItem={selectedItem}
              enterprise={enterprise}
              searchedItems={filteredItems.value}
              searchItems={searchItems}
            />
          }
        />
      </Routes>
    </div>
  );
}
