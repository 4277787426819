import React from "react";
import { IItem } from "../CommissionTableItems";
import {
  IContractComission,
  ISearchContractsCommissionResponse,
} from "../../ContractCommissionsTable";
import { Button, LinkButton, Select } from "../../../../../../components/Form";
import { useSelect } from "../../../../../../hooks/form";
import {
  useCustomFetch,
  useDebounce,
  useLoading,
} from "../../../../../../hooks/async";
import { useModal } from "../../../../../../hooks/contexts";

import styles from "./CopyRange.module.css";
import { isValid } from "../../../../../../helpers/validations";
import { Circle } from "../../../../../../components/Loading";
import { useNavigate } from "react-router-dom";

// interface IFormula {
//   idFormulado: number;
//   descricaoFormulado: string;
//   dataFormulado: string;
//   usuarioCadastro: string;
//   situacaoFormulado: string;
//   tipoFormulado: number;
// }

// interface ISearchFormulasResponse {
//   response: number;
//   message: string;
//   status: number;
//   object: IFormula[];
// }

interface IFormula {
  codigoEmpresa: number;
  formula: {
    descricaoFormulado: string;
    idFormulado: number;
  };
  idContrato: number;
  idFormulado: number;
  status: "A" | "I";
}

interface ISearchFormulaResponse {
  response: number;
  message: string;
  status: number;
  object: IFormula[];
}

interface ICopyRangeResponse {
  response: number;
  message: string;
  status: number;
  object: any;
}

interface ICopyRangeProps {
  selectedContract: IContractComission | null;
  selectedItem: IItem | null;
  searchTotalItemsRecordsAndItems: (
    tableId: number,
    enterpriseId: number
  ) => void;
}

export function CopyRange({
  selectedItem,
  selectedContract,
  searchTotalItemsRecordsAndItems,
}: ICopyRangeProps) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const contractToSendCopy = useSelect<IContractComission | null>({
    type: "single",
    required: true,
    defaultValue: {
      label: `${selectedContract?.idContrato} | ${selectedContract?.descricao}`,
      value: selectedContract,
    },
  });
  const formulas = useSelect<IFormula, "multiple">({
    type: "multiple",
    required: true,
    defaultValue: [],
  });
  const [copyPeoples, setCopyPeoples] = React.useState<boolean>(true);

  const [contractOptions, setContractOptions] = React.useState<
    { label: string; value: IContractComission }[]
  >([]);
  const [formulaOptions, setFormulaOptions] = React.useState<
    { label: string; value: IFormula }[]
  >([]);

  const isAllFormulasSelected = React.useMemo(() => {
    return (
      formulas.value.length > 0 &&
      formulas.value.length === formulaOptions.length
    );
  }, [formulaOptions.length, formulas.value.length]);

  const searchingContractOptions = useLoading();
  const searchingFormulaOptions = useLoading();
  const copyingRange = useLoading();

  const searchContractOptions = React.useCallback(
    async (description: string = "") => {
      try {
        searchingContractOptions.setLoading(true);
        const json = (await customFetch(
          "/commissions/contracts/searchContractsCommissionPaginated",
          {
            body: {
              pagina: 0,
              tamanho: 100,
              descricao: description,
            },
          }
        )) as ISearchContractsCommissionResponse;
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            label: `${option.idContrato} | ${option.descricao}`,
            value: option,
          }));
          setContractOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setContractOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingContractOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingContractOptions]
  );

  const searchContractOptionsDebounced = useDebounce(searchContractOptions);

  const searchFormulas = React.useCallback(
    async (contractId: number, enterpriseId: number) => {
      try {
        searchingFormulaOptions.setLoading(true);
        const json = (await customFetch(
          "/commissions/contracts/searchContractFormulas",
          {
            body: { idContrato: contractId, idEmpresa: enterpriseId },
          }
        )) as ISearchFormulaResponse;
        if (json.status === 200) {
          const options = json.object.map((item) => ({
            value: item,
            label: `${item.formula.idFormulado} | ${item.formula.descricaoFormulado}`,
          }));
          setFormulaOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setFormulaOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingFormulaOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingFormulaOptions]
  );

  // const searchFormulas = React.useCallback(
  //   async (copiedTableId: number, description: string = "") => {
  //     try {
  //       searchingFormulaOptions.setLoading(true);
  //       const json = (await customFetch("/commissions/searchCommissionTableFormulas", {
  //         body: {
  //           idTabela: copiedTableId,
  //           descricao: description,
  //         },
  //       })) as ISearchFormulasResponse;
  //       if (json.status === 200) {
  //         const options = json.object.map((option) => ({
  //           label: `${option.idFormulado} | ${option.descricaoFormulado}`,
  //           value: option,
  //         }));
  //         setFormulaOptions(options);
  //       } else if (json.status === 500) {
  //         Modal.error(json.message, json.object);
  //       } else {
  //         setFormulaOptions([]);
  //       }
  //     } catch (error) {
  //       Modal.error(error);
  //     } finally {
  //       searchingFormulaOptions.setLoading(false);
  //     }
  //   },
  //   [Modal, customFetch, searchingFormulaOptions]
  // );

  const handleClickCopyRange = async () => {
    if (isValid(formulas, contractToSendCopy)) {
      const confirm = await Modal.confirm("Deseja mesmo copiar esta faixa?");
      if (confirm) {
        try {
          copyingRange.setLoading(true);
          const json = (await customFetch(
            "/commissions/contracts/copyContractRange",
            {
              body: {
                idEmpresa: selectedContract?.empresa.idEmpresa,
                idContratoOrigem: selectedContract?.idContrato,
                idFormulaOrigem: selectedItem?.formula.idFormulado,
                idContratoDestino: contractToSendCopy.value?.value?.idContrato,
                idFormulasDestino: formulas.value.map(
                  (item) => item.value.formula.idFormulado
                ),
                copiarPessoas: copyPeoples,
              },
            }
          )) as ICopyRangeResponse;
          if (json.status === 200) {
            searchTotalItemsRecordsAndItems(
              Number(selectedContract?.idContrato),
              selectedContract!.empresa.idEmpresa
            );
            await Modal.success(json.message);
            navigate("/comissoes/adubo/comissoes-de-contratos/itens");
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          copyingRange.setLoading(false);
        }
      }
    }
  };

  React.useEffect(() => {
    searchContractOptions();
    searchFormulas(
      selectedContract?.idContrato || 0,
      selectedContract?.empresa.idEmpresa || 0
    );
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.navContainer}>
        <LinkButton
          to="/comissoes/adubo/comissoes-de-contratos/itens"
          buttonStyle="backButton"
        />
        <h2 className={styles.navContainer__title}>
          {selectedContract?.descricao} - Fórmula{" "}
          {selectedItem?.formula.descricaoFormulado}
        </h2>
        <div></div>
      </div>
      <div>
        <div className={styles.rangeContainer}>
          <div>
            <p className={styles.itemTitle}>{}</p>
            <span className={styles.itemSeparator}></span>
            <div className={styles.commissionsContainer}>
              {selectedItem?.faixaPrecoProduto.map(
                (commission, commissionIndex) => (
                  <div
                    className={`${styles.commissionBlock}`}
                    key={commissionIndex}
                  >
                    <p>
                      {selectedItem.tipoComissao}. {commission.perMaximo}
                    </p>
                    <div className={styles.ball}>
                      <div style={{ backgroundColor: commission.rgb }}></div>
                      <span style={{ backgroundColor: commission.rgb }}></span>
                    </div>
                    <p>Com. {commission.perComissao}</p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <div>
          {!copyingRange.isLoading ? (
            <div className={styles.fieldsContainer}>
              <div>
                <label htmlFor="" className="label">
                  Contrato Destino
                </label>
                <Select
                  placeholder={"Selecione um contrato para enviar a cópia"}
                  options={contractOptions}
                  value={contractToSendCopy.value}
                  onChange={contractToSendCopy.onChange}
                  onInputChange={(value) => {
                    searchContractOptionsDebounced(value);
                  }}
                  onBlur={contractToSendCopy.onBlur}
                  defaultValue={undefined}
                  error={contractToSendCopy.error}
                  isLoading={searchingContractOptions.isLoading}
                />
              </div>
              <div>
                <label htmlFor="" className="label">
                  Fórmulas a Copiar
                  <button
                    className={styles.btnSelectAllFormulas}
                    onClick={() => {
                      if (isAllFormulasSelected) {
                        formulas.reset();
                      } else {
                        formulas.setValue(
                          JSON.parse(JSON.stringify(formulaOptions))
                        );
                      }
                    }}
                    disabled={
                      searchingFormulaOptions.isLoading ||
                      formulaOptions.length === 0
                    }
                  >
                    {isAllFormulasSelected
                      ? "Remover Todas"
                      : "Selecionar Todas"}
                  </button>
                </label>
                <Select
                  placeholder={"Selecione as fórmulas que deseja copiar"}
                  options={formulaOptions.filter(
                    (option) =>
                      !(
                        formulas.value as unknown as {
                          label: string;
                          value: IFormula;
                        }[]
                      ).some(
                        (i) => i.value.idFormulado === option.value.idFormulado
                      )
                  )}
                  value={formulas.value}
                  onChange={formulas.onChange}
                  onBlur={formulas.onBlur}
                  defaultValue={undefined}
                  error={formulas.error}
                  isMulti={true}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  isLoading={searchingFormulaOptions.isLoading}
                />
              </div>
              <div className={styles.fieldsContainer__copyPeoplesField}>
                <label className="label">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setCopyPeoples(e.target.checked);
                    }}
                    checked={copyPeoples}
                  />
                  <span>Copiar Pessoas da Faixa</span>
                </label>
              </div>
              <div className={styles.fieldsContainer__copyButton}>
                <Button onClick={handleClickCopyRange}>Copiar</Button>
              </div>
            </div>
          ) : (
            <div className={`loadingContainer ${styles.loadingContainer}`}>
              <Circle size={100} />
              <span className="loadingMessage">Copiando Faixa de Comissão</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
