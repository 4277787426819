import { useCallback } from "react";
import { useForm, useSelect } from "../../../../../hooks/form";
import { IAction, IEnterprise, IProduct } from "../../hooks/types";
import { IUseInsertOrDeduct } from "./types";
import { useModal } from "../../../../../hooks/contexts";
import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { isValid } from "../../../../../helpers/validations";

export function useInsertOrDeduct(): IUseInsertOrDeduct {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const product = useSelect<IProduct>({ type: "single", required: true });
  const enterprise = useSelect<IEnterprise>({ type: "single", required: true });
  const action = useSelect<IAction>({ type: "single", required: true, defaultValue: { label: "Entrada", value: "E" } });
  const quantity = useForm({ required: true });

  const insertingOrDeducting = useLoading();

  const clearInputs = useCallback(() => {
    product.reset();
    enterprise.reset();
    action.reset();
    quantity.reset();
  }, [action, enterprise, product, quantity]);

  const insertOrDeductInventory = useCallback(async () => {
    if (!isValid(product, enterprise, action, quantity)) return;
    try {
      insertingOrDeducting.setLoading(true);
      const json = (await customFetch("/factory/insertAndUpdateInventoryRecord", {
        body: {
          idEmpresa: enterprise.value?.value.idEmpresa,
          dataEstoque: new Date().toISOString(),
          idProduto: String(product.value?.value.idProduto),
          tipoMovimento: action.value?.value,
          quantidade: Number(quantity.value),
        },
      })) as DefaultFetchResponse<any>;
      if (json.status === 200) {
        clearInputs();
        Modal.success(json.message);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      insertingOrDeducting.setLoading(false);
    }
  }, [Modal, action, customFetch, enterprise, insertingOrDeducting, product, quantity, clearInputs]);

  return {
    product,
    enterprise,
    action,
    quantity,
    insertOrDeductInventory: {
      handle: insertOrDeductInventory,
      loading: insertingOrDeducting.isLoading,
    },
  };
}
