import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { useForm } from "../../../../../hooks/form";
import { usePagination } from "../../../../../hooks/pagination";
import { ICommissionTable } from "../CommissionTables";
import { UpdateItemPeoples } from "../UpdateItemPeoples/UpdateItemPeoples";
import { CopyRange } from "./CopyRange/CopyRange";
import { ItemsList } from "./ItemsList/ItemsList";
import { UpdateItem } from "../UpdateItem/UpdateItem";
import { CloneCommissionsToSellers } from "./CloneCommissionsToSellers/CloneCommissionsToSellers";

interface ISearchTotalItemsRecordsResponse {
  response: number;
  message: string;
  status: number;
  object: {
    paginas: number;
    total: number;
  };
}

export interface IItem {
  idFormulado: number;
  descricaoFormulado: string;
  itensComissaoTabela: {
    idFaixaPrecoproduto: number;
    idFormulado: number;
    idEmpresa: number;
    idTabela: number;
    perMinimo: number;
    perMaximo: number;
    status: "A" | "I";
    comissaoValor: number;
    rgb: string;
    dataCriacao: string;
    dataManutencao: string;
    observacao: string;
    ocultar: "S" | "N";
  }[];
  tipoComissao: "Mar" | "Des";
}

export interface ISearchItemsResponse {
  response: number;
  message: string;
  status: number;
  object: IItem[];
}

interface CommissionTableItemsProps {
  selectedTable: ICommissionTable | null;
}

export function CommissionTableItems({ selectedTable }: CommissionTableItemsProps) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const pagination = usePagination();

  const searchedItems = useForm({ type: "text", required: false });
  const [itemList, setItemList] = React.useState<IItem[]>([]);

  const searchingItems = useLoading();

  const [selectedItem, setSelectedItem] = React.useState<IItem | null>(null);

  const searchTotalItemsRecordsPromise = React.useCallback(
    (tableId: number, description: string = "", page: number = 0) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/commissions/searchTotalCommissionTableItemsRecords", {
            body: {
              idTabela: tableId,
              descricao: description,
              pagina: page ? page - 1 : 0,
              tamanho: pagination.maxItems,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      }) as Promise<ISearchTotalItemsRecordsResponse>;
    },
    [customFetch, pagination.maxItems]
  );

  const searchItemsPromise = React.useCallback(
    (tableId: number, description: string = "", page: number = 0) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/commissions/searchCommissionTableItemsPaginated", {
            body: {
              idTabela: tableId,
              descricao: description,
              pagina: page ? page - 1 : 0,
              tamanho: pagination.maxItems,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      }) as Promise<ISearchItemsResponse>;
    },
    [customFetch, pagination.maxItems]
  );

  const researchItems = async (tableId: number, description: string = "", page: number = 0) => {
    try {
      searchingItems.setLoading(true);
      const json = await searchItemsPromise(tableId, description, page);
      if (json.status === 200) {
        setItemList(json.object);
        window.scrollTo({ top: 0, behavior: "auto" });
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingItems.setLoading(false);
    }
  };

  const searchTotalItemsRecordsAndItems = React.useCallback(
    async (tableId: number, description: string = "") => {
      if (description === "") {
        searchedItems.setValue("");
      }
      try {
        searchingItems.setLoading(true);
        const jsonItems = await searchItemsPromise(tableId, description);
        if (jsonItems.status === 200) {
          const jsonRecords = await searchTotalItemsRecordsPromise(tableId, description);
          if (jsonRecords.status === 200) {
            setItemList(jsonItems.object);
            pagination.setTotalRecords(jsonRecords.object.total);
          } else if (jsonRecords.status === 500) {
            Modal.error(jsonRecords.message, jsonRecords.object);
          } else {
            setItemList([]);
          }
        } else if (jsonItems.status === 500) {
          Modal.error(jsonItems.message, jsonItems.object);
        } else {
          pagination.reset();
          setItemList([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        pagination.setCurrentPage(1);
        searchingItems.setLoading(false);
      }
    },
    [Modal, pagination, searchItemsPromise, searchTotalItemsRecordsPromise, searchedItems, searchingItems]
  );

  if (!selectedTable) {
    return <Navigate to={"/comissoes/adubo/tabelas-de-comissoes"} />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ItemsList
            itemList={{ value: itemList, setValue: setItemList }}
            searchedItems={searchedItems as unknown as IUseForm}
            searchingItems={searchingItems}
            searchItemsPromise={searchItemsPromise}
            searchTotalItemsRecordsAndItems={searchTotalItemsRecordsAndItems}
            selectedTable={selectedTable}
            pagination={pagination}
            setSelectedItem={setSelectedItem}
          />
        }
      />
      <Route
        path="atualizar"
        element={
          <UpdateItem
            selectedTable={selectedTable}
            selectedItem={selectedItem}
            researchItems={(tableId, formula) => researchItems(tableId, formula, pagination.currentPage)}
            filteredFormula={searchedItems.value}
          />
        }
      />
      <Route
        path="pessoas/*"
        element={<UpdateItemPeoples selectedTable={selectedTable} selectedItem={selectedItem} />}
      />
      <Route
        path="copiar"
        element={
          <CopyRange
            selectedTable={selectedTable}
            selectedItem={selectedItem}
            searchTotalItemsRecordsAndItems={searchTotalItemsRecordsAndItems}
          />
        }
      />
      <Route path="copiar-vendedor" element={<CloneCommissionsToSellers selectedTable={selectedTable} />} />
    </Routes>
  );
}
