import { useCallback, useMemo } from "react";
import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { useForm, useSelect } from "../../../../../hooks/form";
import { isValid } from "../../../../../helpers/validations";
import { IUseAdicionarAtualizar, Status, StatusOptions } from "./types";
import { IObjeto, IUseObjetos } from "../../hooks/types";
import { useNavigate } from "react-router-dom";

interface IProps {
  objetosData: IUseObjetos;
}

export function useAdicionarAtualizar({ objetosData }: IProps): IUseAdicionarAtualizar {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const nomeObjeto = useForm({ required: true });
  const status = useSelect<Status>({ required: true, type: "single", defaultValue: { value: "A", label: "Ativo" } });

  const statusOptions = useMemo<StatusOptions>(
    () => [
      { value: "A", label: "Ativo" },
      { value: "I", label: "Inativo" },
    ],
    []
  );

  const insertingOrUpdatingObjeto = useLoading();

  const carregarDados = useCallback(
    (selectedObjeto: IObjeto) => {
      nomeObjeto.setValue(selectedObjeto.nomeObjeto);
      status.setValue(() => {
        if (selectedObjeto.status === "A") return statusOptions[0];
        else return statusOptions[1];
      });
    },
    [nomeObjeto, status, statusOptions]
  );

  const insertOrUpdateObjeto = useCallback(
    async (id: number = 0) => {
      if (!isValid(nomeObjeto, status)) return;
      const body = {
        idObjeto: id,
        nomeObjeto: nomeObjeto.value,
        status: status.value?.value,
      };
      try {
        insertingOrUpdatingObjeto.setLoading(true);
        const json = await customFetch(`/juridico/insertAndUpdateObjeto`, {
          body,
        });
        if (json.status === 200) {
          objetosData.searchs.searchObjetosPaginated("");
          await Modal.success(json.message);
          navigate("/contratos-fornecedor-cliente/objetos");
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        insertingOrUpdatingObjeto.setLoading(false);
      }
    },
    [Modal, customFetch, insertingOrUpdatingObjeto, nomeObjeto, status, objetosData.searchs, navigate]
  );

  return {
    carregarDados,
    form: {
      nomeObjeto,
      status,
    },
    selectOptions: {
      statusOptions,
    },
    interactions: {
      insertOrUpdateObjeto,
      insertingOrUpdatingObjeto: insertingOrUpdatingObjeto.isLoading,
    },
  };
}
