import React from "react";

import { useLoading } from "../../../hooks/async";
import { useCustomFetch } from "../../../hooks/async/useCustomFetch";
import { useModal } from "../../../hooks/contexts/useModal";

import General from "./General/General";
import Tablet from "./Tablet/Tablet";
import PremiationTypes from "./PremiationTypes/PremiationTypes";

import styles from "./GeneralSettings.module.css";

type TPage = "general" | "tablet" | "premiation";

export interface IGeneral {
  checkSettings: [
    {
      title: "pedidos";
      settings: [
        {
          name: "desativarPedidos";
          label: "Desativar pedidos (contrato)";
          value: boolean;
        },
        {
          name: "desativarPedidosNormal";
          label: "Desativar pedidos (tabela)";
          value: boolean;
        },

        {
          name: "desativarPedidosFarelado";
          label: "Desativar pedidos (farelado)";
          value: boolean;
        },
        {
          name: "desativarPedidosGranulado";
          label: "Desativar pedidos (granulado)";
          value: boolean;
        },
        {
          name: "desativarPedidosSais";
          label: "Desativar pedidos (sais)";
          value: boolean;
        },
        {
          name: "desativarPedidosFareladoContrato";
          label: "Desativar pedidos (farelado contrato)";
          value: boolean;
        },
        {
          name: "desativarPedidosGranuladoContrato";
          label: "Desativar pedidos (granulado contrato)";
          value: boolean;
        },
        {
          name: "desativarPedidosSaisContrato";
          label: "Desativar pedidos (sais contrato)";
          value: boolean;
        }
      ];
    },
    {
      title: "comercial";
      settings: [
        {
          name: "mandarPedidosParaComercial";
          label: "Mandar pedidos para o comercial (tabela)";
          value: boolean;
        },
        {
          name: "mandarPedidosParaComercialContrato";
          label: "Mandar pedidos para o comercial (contrato)";
          value: boolean;
        },
        {
          name: "mandarPedidosParaComercialFarelado";
          label: "Mandar pedidos para o comercial (farelado)";
          value: boolean;
        },
        {
          name: "mandarPedidosParaComercialGranulado";
          label: "Mandar pedidos para o comercial (granulado)";
          value: boolean;
        },
        {
          name: "mandarPedidosParaComercialSais";
          label: "Mandar pedidos para o comercial (sais)";
          value: boolean;
        },
        {
          name: "mandarPedidosParaComercialFareladoContrato";
          label: "Mandar pedidos para o comercial (farelado contrato)";
          value: boolean;
        },
        {
          name: "mandarPedidosParaComercialGranuladoContrato";
          label: "Mandar pedidos para o comercial (granulado contrato)";
          value: boolean;
        },
        {
          name: "mandarPedidosParaComercialSaisContrato";
          label: "Mandar pedidos para o comercial (sais contrato)";
          value: boolean;
        },
        {
          name: "enviarPedidoBonificacaoParaComercial";
          label: "Enviar pedidos de bonificação para o comercial";
          value: boolean;
        },
        {
          name: "enviarPedidoCreditoRuralParaComercial";
          label: "Enviar pedidos de crédito para o comercial";
          value: boolean;
        },
        {
          name: "enviarPedidoTrocaCafeParaComercial";
          label: "Enviar pedidos de troca café para o comercial";
          value: boolean;
        },
        {
          name: "enviarPedidoComDescontoAntecipacaoParaComercial";
          label: "Enviar pedidos com desconto de antecipação para o comercial";
          value: boolean;
        },
        {
          name: "enviarPedidoComPagamentoEmDiaParaComercial";
          label: "Enviar pedidos com desconto de pagamento em dia para o comercial";
          value: boolean;
        }
      ];
    }
  ];
  numberSettings: [
    {
      title: "juros";
      settings: [
        {
          name: "diasCobrarJuros";
          label: "Dia mínimo para cobrança de juros";
          value: string;
        },
        {
          name: "diasCobrarJurosFoliar";
          label: "Dia mínimo para cobrança de juros (foliar)";
          value: string;
        }
      ];
    },
    {
      title: "icms";
      settings: [
        {
          name: "icmsPedidoVenda";
          label: "ICMS aplicado aos pedidos de tabela (%)";
          value: string;
        },
        {
          name: "icmsPedidoVendaContrato";
          label: "ICMS aplicado aos pedidos de contrato (%)";
          value: string;
        }
      ];
    }
  ];
}

export type TPremiationTypesList = {
  id: number;
  tipo: string;
  situacao: string;
  dataAlteracao: string;
}[];

const defaultGeneralSettings: IGeneral = {
  checkSettings: [
    {
      title: "pedidos",
      settings: [
        {
          name: "desativarPedidos",
          label: "Desativar pedidos (contrato)",
          value: false,
        },
        {
          name: "desativarPedidosNormal",
          label: "Desativar pedidos (tabela)",
          value: false,
        },
        {
          name: "desativarPedidosFarelado",
          label: "Desativar pedidos (farelado)",
          value: false,
        },
        {
          name: "desativarPedidosGranulado",
          label: "Desativar pedidos (granulado)",
          value: false,
        },
        {
          name: "desativarPedidosSais",
          label: "Desativar pedidos (sais)",
          value: false,
        },
        {
          name: "desativarPedidosFareladoContrato",
          label: "Desativar pedidos (farelado contrato)",
          value: false,
        },
        {
          name: "desativarPedidosGranuladoContrato",
          label: "Desativar pedidos (granulado contrato)",
          value: false,
        },
        {
          name: "desativarPedidosSaisContrato",
          label: "Desativar pedidos (sais contrato)",
          value: false,
        },
      ],
    },
    {
      title: "comercial",
      settings: [
        {
          name: "mandarPedidosParaComercial",
          label: "Mandar pedidos para o comercial (tabela)",
          value: false,
        },
        {
          name: "mandarPedidosParaComercialContrato",
          label: "Mandar pedidos para o comercial (contrato)",
          value: false,
        },
        {
          name: "mandarPedidosParaComercialFarelado",
          label: "Mandar pedidos para o comercial (farelado)",
          value: false,
        },
        {
          name: "mandarPedidosParaComercialGranulado",
          label: "Mandar pedidos para o comercial (granulado)",
          value: false,
        },
        {
          name: "mandarPedidosParaComercialSais",
          label: "Mandar pedidos para o comercial (sais)",
          value: false,
        },
        {
          name: "mandarPedidosParaComercialFareladoContrato",
          label: "Mandar pedidos para o comercial (farelado contrato)",
          value: false,
        },
        {
          name: "mandarPedidosParaComercialGranuladoContrato",
          label: "Mandar pedidos para o comercial (granulado contrato)",
          value: false,
        },
        {
          name: "mandarPedidosParaComercialSaisContrato",
          label: "Mandar pedidos para o comercial (sais contrato)",
          value: false,
        },
        {
          name: "enviarPedidoBonificacaoParaComercial",
          label: "Enviar pedidos de bonificação para o comercial",
          value: false,
        },
        {
          name: "enviarPedidoCreditoRuralParaComercial",
          label: "Enviar pedidos de crédito para o comercial",
          value: false,
        },
        {
          name: "enviarPedidoTrocaCafeParaComercial",
          label: "Enviar pedidos de troca café para o comercial",
          value: false,
        },
        {
          name: "enviarPedidoComDescontoAntecipacaoParaComercial",
          label: "Enviar pedidos com desconto de antecipação para o comercial",
          value: false,
        },
        {
          name: "enviarPedidoComPagamentoEmDiaParaComercial",
          label: "Enviar pedidos com desconto de pagamento em dia para o comercial",
          value: false,
        },
      ],
    },
  ],
  numberSettings: [
    {
      title: "juros",
      settings: [
        {
          name: "diasCobrarJuros",
          label: "Dia mínimo para cobrança de juros",
          value: "",
        },
        {
          name: "diasCobrarJurosFoliar",
          label: "Dia mínimo para cobrança de juros (foliar)",
          value: "",
        },
      ],
    },
    {
      title: "icms",
      settings: [
        {
          name: "icmsPedidoVenda",
          label: "ICMS aplicado aos pedidos de tabela (%)",
          value: "",
        },
        {
          name: "icmsPedidoVendaContrato",
          label: "ICMS aplicado aos pedidos de contrato (%)",
          value: "",
        },
      ],
    },
  ],
};

interface ISearchGeneralConfigsResponse {
  response: number;
  message: string;
  status: number;
  object: {
    idConfiguracao: number;
    desativarPedidos: string;
    desativarPedidosNormal: string;
    mandarPedidosParaComercial: string;
    mandarPedidosParaComercialContrato: string;
    mandarPedidosParaComercialFarelado: string;
    mandarPedidosParaComercialGranulado: string;
    mandarPedidosParaComercialSais: string;
    mandarPedidosParaComercialFareladoContrato: string;
    mandarPedidosParaComercialGranuladoContrato: string;
    mandarPedidosParaComercialSaisContrato: string;
    desativarPedidosFarelado: string;
    desativarPedidosGranulado: string;
    desativarPedidosSais: string;
    desativarPedidosFareladoContrato: string;
    desativarPedidosGranuladoContrato: string;
    desativarPedidosSaisContrato: string;
    enviarPedidoBonificacaoParaComercial: string;
    enviarPedidoCreditoRuralParaComercial: string;
    enviarPedidoTrocaCafeParaComercial: string;
    enviarPedidoComDescontoAntecipacaoParaComercial: string;
    enviarPedidoComPagamentoEmDiaParaComercial: string;
    diasCobrarJuros: number;
    diasCobrarJurosFoliar: number;
    icmsPedidoVenda: number;
    icmsPedidoVendaContrato: number;
  };
}

export function SettingsGeneralSettings() {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const [currentPage, setCurrentPage] = React.useState<TPage>("general");

  const [generalSettings, setGeneralSettings] = React.useState(defaultGeneralSettings);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [premiationTypesList, setPremiationTypesList] = React.useState<TPremiationTypesList>([
    {
      id: 1,
      situacao: "Ativo",
      tipo: "Nao sei",
      dataAlteracao: "20/10/1981",
    },
    {
      id: 1,
      situacao: "Ativo",
      tipo: "Nao sei",
      dataAlteracao: "20/10/1981",
    },
    {
      id: 1,
      situacao: "Ativo",
      tipo: "Nao sei",
      dataAlteracao: "20/10/1981",
    },
    {
      id: 1,
      situacao: "Ativo",
      tipo: "Nao sei",
      dataAlteracao: "20/10/1981",
    },
    {
      id: 1,
      situacao: "Ativo",
      tipo: "Nao sei",
      dataAlteracao: "20/10/1981",
    },
    {
      id: 1,
      situacao: "Ativo",
      tipo: "Nao sei",
      dataAlteracao: "20/10/1981",
    },
    {
      id: 1,
      situacao: "Ativo",
      tipo: "Nao sei",
      dataAlteracao: "20/10/1981",
    },
    {
      id: 1,
      situacao: "Ativo",
      tipo: "Nao sei",
      dataAlteracao: "20/10/1981",
    },
    {
      id: 1,
      situacao: "Ativo",
      tipo: "Nao sei",
      dataAlteracao: "20/10/1981",
    },
    {
      id: 1,
      situacao: "Ativo",
      tipo: "Nao sei",
      dataAlteracao: "20/10/1981",
    },
  ]);

  const searchingGeneralSettings = useLoading();

  function changePage(e: React.MouseEvent<HTMLLIElement, MouseEvent>, page: TPage) {
    const ul = document.querySelector<HTMLUListElement>("[data-navlink]");
    if (ul !== null) {
      ul.className = "";
      ul.classList.add(page);
      const lis = ul.querySelectorAll("li");
      lis.forEach((item) => item.classList.remove("active"));
      e.currentTarget.classList.add("active");
    }
    setCurrentPage(page);
  }

  async function searchGeneralConfigs() {
    try {
      searchingGeneralSettings.setLoading(true);
      const json = (await customFetch("/products/searchGeneralConfigs", {
        body: null,
      })) as ISearchGeneralConfigsResponse;
      if (json.status === 200) {
        setGeneralSettings({
          checkSettings: [
            {
              title: "pedidos",
              settings: [
                {
                  name: "desativarPedidos",
                  label: "Desativar pedidos (contrato)",
                  value: json.object.desativarPedidos === "S",
                },
                {
                  name: "desativarPedidosNormal",
                  label: "Desativar pedidos (tabela)",
                  value: json.object.desativarPedidosNormal === "S",
                },
                {
                  name: "desativarPedidosFarelado",
                  label: "Desativar pedidos (farelado)",
                  value: json.object.desativarPedidosFarelado === "S",
                },
                {
                  name: "desativarPedidosGranulado",
                  label: "Desativar pedidos (granulado)",
                  value: json.object.desativarPedidosGranulado === "S",
                },
                {
                  name: "desativarPedidosSais",
                  label: "Desativar pedidos (sais)",
                  value: json.object.desativarPedidosSais === "S",
                },
                {
                  name: "desativarPedidosFareladoContrato",
                  label: "Desativar pedidos (farelado contrato)",
                  value: json.object.desativarPedidosFareladoContrato === "S",
                },
                {
                  name: "desativarPedidosGranuladoContrato",
                  label: "Desativar pedidos (granulado contrato)",
                  value: json.object.desativarPedidosGranuladoContrato === "S",
                },
                {
                  name: "desativarPedidosSaisContrato",
                  label: "Desativar pedidos (sais contrato)",
                  value: json.object.desativarPedidosSaisContrato === "S",
                },
              ],
            },
            {
              title: "comercial",
              settings: [
                {
                  name: "mandarPedidosParaComercial",
                  label: "Mandar pedidos para o comercial (tabela)",
                  value: json.object.mandarPedidosParaComercial === "S",
                },
                {
                  name: "mandarPedidosParaComercialContrato",
                  label: "Mandar pedidos para o comercial (contrato)",
                  value: json.object.mandarPedidosParaComercialContrato === "S",
                },
                {
                  name: "mandarPedidosParaComercialFarelado",
                  label: "Mandar pedidos para o comercial (farelado)",
                  value: json.object.mandarPedidosParaComercialFarelado === "S",
                },
                {
                  name: "mandarPedidosParaComercialGranulado",
                  label: "Mandar pedidos para o comercial (granulado)",
                  value: json.object.mandarPedidosParaComercialGranulado === "S",
                },
                {
                  name: "mandarPedidosParaComercialSais",
                  label: "Mandar pedidos para o comercial (sais)",
                  value: json.object.mandarPedidosParaComercialSais === "S",
                },
                {
                  name: "mandarPedidosParaComercialFareladoContrato",
                  label: "Mandar pedidos para o comercial (farelado contrato)",
                  value: json.object.mandarPedidosParaComercialFareladoContrato === "S",
                },
                {
                  name: "mandarPedidosParaComercialGranuladoContrato",
                  label: "Mandar pedidos para o comercial (granulado contrato)",
                  value: json.object.mandarPedidosParaComercialGranuladoContrato === "S",
                },
                {
                  name: "mandarPedidosParaComercialSaisContrato",
                  label: "Mandar pedidos para o comercial (sais contrato)",
                  value: json.object.mandarPedidosParaComercialSaisContrato === "S",
                },
                {
                  name: "enviarPedidoBonificacaoParaComercial",
                  label: "Enviar pedidos de bonificação para o comercial",
                  value: json.object.enviarPedidoBonificacaoParaComercial === "S",
                },
                {
                  name: "enviarPedidoCreditoRuralParaComercial",
                  label: "Enviar pedidos de crédito para o comercial",
                  value: json.object.enviarPedidoCreditoRuralParaComercial === "S",
                },
                {
                  name: "enviarPedidoTrocaCafeParaComercial",
                  label: "Enviar pedidos de troca café para o comercial",
                  value: json.object.enviarPedidoTrocaCafeParaComercial === "S",
                },
                {
                  name: "enviarPedidoComDescontoAntecipacaoParaComercial",
                  label: "Enviar pedidos com desconto de antecipação para o comercial",
                  value: json.object.enviarPedidoComDescontoAntecipacaoParaComercial === "S",
                },
                {
                  name: "enviarPedidoComPagamentoEmDiaParaComercial",
                  label: "Enviar pedidos com desconto de pagamento em dia para o comercial",
                  value: json.object.enviarPedidoComPagamentoEmDiaParaComercial === "S",
                },
              ],
            },
          ],
          numberSettings: [
            {
              title: "juros",
              settings: [
                {
                  name: "diasCobrarJuros",
                  label: "Dia mínimo para cobrança de juros",
                  value: String(json.object.diasCobrarJuros),
                },
                {
                  name: "diasCobrarJurosFoliar",
                  label: "Dia mínimo para cobrança de juros (foliar)",
                  value: String(json.object.diasCobrarJurosFoliar),
                },
              ],
            },
            {
              title: "icms",
              settings: [
                {
                  name: "icmsPedidoVenda",
                  label: "ICMS aplicado aos pedidos de tabela (%)",
                  value: String(json.object.icmsPedidoVenda),
                },
                {
                  name: "icmsPedidoVendaContrato",
                  label: "ICMS aplicado aos pedidos de contrato (%)",
                  value: String(json.object.icmsPedidoVendaContrato),
                },
              ],
            },
          ],
        });
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingGeneralSettings.setLoading(false);
    }
  }

  React.useEffect(() => {
    searchGeneralConfigs();
  }, []); //eslint-disable-line

  return (
    <div className={"container " + styles.container}>
      <nav className={styles.navContainer}>
        <ul className="general" data-navlink>
          <li
            className="active"
            onClick={(e) => {
              changePage(e, "general");
            }}>
            Configurações Gerais
          </li>
          {/* <li
            onClick={(e) => {
              changePage(e, "tablet");
            }}
          >
            Tablet
          </li>
          <li
            onClick={(e) => {
              changePage(e, "premiation");
            }}
          >
            Premiações Tipo
          </li> */}
        </ul>
      </nav>
      <div className={styles.contentContainer}>
        {currentPage === "general" ? (
          <General
            settings={generalSettings}
            setSettings={setGeneralSettings}
            searchingGeneralSettings={searchingGeneralSettings}
          />
        ) : currentPage === "tablet" ? (
          <Tablet />
        ) : (
          <PremiationTypes premiationTypesList={premiationTypesList} />
        )}
      </div>
    </div>
  );
}
