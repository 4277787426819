import React from "react";
import { Button } from "../../../components/Form";
import { TModalErrorDetails, TModalMessage, TModalOnContinue } from "./Modal";

import modalStyles from "./styles/modal.module.css";
import errorStyles from "./styles/error.module.css";

interface IModalErrorProps {
  message: TModalMessage;
  details: TModalErrorDetails;
  onContinue: TModalOnContinue;
}

const ModalError = ({ message, onContinue, details }: IModalErrorProps) => {
  const [showingDetails, setShowingDetails] = React.useState(false);

  const detailsButton = React.useCallback(() => {
    setShowingDetails((value) => !value);
  }, []);

  return (
    <div className={`${modalStyles.container} ${errorStyles.container} ${showingDetails ? "showingDetails" : ""}`}>
      {typeof message === "string" && (
        <p className={modalStyles.message} dangerouslySetInnerHTML={{ __html: message }}></p>
      )}
      {details ? (
        <span className={errorStyles.details}>
          <button className={errorStyles.detailsButton} onClick={detailsButton}>
            {showingDetails ? "Menos Detalhes" : "Mais Detalhes"}
          </button>
          <div className={`${errorStyles.detailsContainer} ${showingDetails ? "opened" : ""}`}>
            <ul className={errorStyles.detailsList}>
              <div className={errorStyles.detailsListContainer}>
                <li>
                  <p>Code: {details.code.trim()}</p>
                </li>
                <li>
                  <p>Internal Class: {details.internalclass}</p>
                </li>
              </div>
              <div className={errorStyles.detailsListContainer}>
                <li>
                  <p>Priority: {details.priority}</p>
                </li>
                <li>
                  <p>Web Service: {details.webservice}</p>
                </li>
              </div>
            </ul>
          </div>
        </span>
      ) : null}
      <span className={`${modalStyles.separator} ${errorStyles.separator}`}></span>
      <div className={modalStyles.buttonContainer}>
        <Button className={modalStyles.button} onClick={() => onContinue()} variant="danger">
          OK
        </Button>
      </div>
    </div>
  );
};

export { ModalError };
