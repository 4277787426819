import React from "react";

import { Navigate, useNavigate } from "react-router-dom";

import { Button, Input, LinkButton, Select } from "../../../../components/Form";

import { useForm, useSelect } from "../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useChanges, useModal } from "../../../../hooks/contexts";

import { isValid } from "../../../../helpers/validations";

import { Circle } from "../../../../components/Loading";

import styles from "./UpdateMicro.module.css";

export function UpdateMicro({ researchMicros, enterpriseOptions, selectedMicro, filteredMicro }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const { setIsChangesDetected } = useChanges();
  const updatingMicro = useLoading();
  const navigate = useNavigate();

  const micro = useForm({ type: "", required: true });
  const microSymbol = useForm({ type: "", required: true });
  const enterprise = useSelect({ type: "single", required: true, emptyMessage: "Selecione uma empresa" });
  const defaultValue = useForm({ type: "number", required: true });
  const maxDiscount = useForm({ type: "number", numberRange: { min: 0, max: 100 }, required: true });
  const status = useSelect({ type: "single", required: true });

  const optionsStatus = [
    {
      value: true,
      label: "ATIVO",
    },
    {
      value: false,
      label: "INATIVO",
    },
  ];

  const updateMicro = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const json = await customFetch("/products/registerAndUpdateMicro", {
          body: {
            idMicro: Number(selectedMicro.idComplementar),
            descricao: micro.value,
            simbolo: microSymbol.value,
            valorPadrao: Number(defaultValue.value),
            descontoMaximo: Number(maxDiscount.value),
            idEmpresa: enterprise.value.value.idEmpresa,
            ativo: status.value.value,
          },
        });
        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
  }, [customFetch, defaultValue, enterprise, maxDiscount, micro, microSymbol, selectedMicro, status]);

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (isValid(micro, microSymbol, enterprise, defaultValue, maxDiscount)) {
        try {
          updatingMicro.setLoading(true);
          const json = await updateMicro();
          if (json.status === 200) {
            researchMicros(filteredMicro);
            setIsChangesDetected(false);
            await Modal.success(json.message);
            navigate("/produtos/micros");
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          updatingMicro.setLoading(false);
        }
      }
    },
    [
      micro,
      microSymbol,
      enterprise,
      defaultValue,
      maxDiscount,
      updatingMicro,
      updateMicro,
      researchMicros,
      filteredMicro,
      setIsChangesDetected,
      Modal,
      navigate,
    ]
  );

  React.useEffect(() => {
    if (selectedMicro) {
      micro.setValue(selectedMicro.descricaoComplementar || "");
      microSymbol.setValue(selectedMicro.simbolo || "");
      defaultValue.setValue(selectedMicro.valor || "");
      maxDiscount.setValue(selectedMicro.descontoMaximo || "");
      enterprise.setValue(enterpriseOptions.find((item) => item.value.idEmpresa === selectedMicro.idEmpresa) || "");
      status.setValue(() => {
        return selectedMicro.situacaoComplementar ? optionsStatus[0] : optionsStatus[1];
      });
    }
  }, []); //eslint-disable-line

  if (!selectedMicro || !selectedMicro.idComplementar) {
    return <Navigate to="/produtos/micros" />;
  }

  return (
    <div className={`container ${styles.container}`}>
      {!updatingMicro.isLoading ? (
        <>
          <div>
            <LinkButton to="/produtos/micros" buttonStyle="backButton" />
          </div>
          <div>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className={styles.fieldsContainer}>
                <div className={styles.fieldsGroup}>
                  <div>
                    <label htmlFor="micro" className="label">
                      Descrição
                    </label>
                    <Input
                      id="micro"
                      placeholder="Digite o nome do micro"
                      value={micro.value}
                      error={micro.error}
                      onChange={(e) => {
                        micro.onChange(e);
                        setIsChangesDetected(true);
                      }}
                      onBlur={micro.onBlur}
                    />
                  </div>
                  <div>
                    <label htmlFor="microSymbol" className="label">
                      Símbolo
                    </label>
                    <Input
                      id="microSymbol"
                      type="text"
                      placeholder="Digite o símbolo do micro"
                      value={microSymbol.value}
                      error={microSymbol.error}
                      onChange={(e) => {
                        microSymbol.onChange(e);
                        setIsChangesDetected(true);
                      }}
                      onBlur={microSymbol.onBlur}
                    />
                  </div>
                </div>
                <div className={styles.fieldsGroup}>
                  <div>
                    <label htmlFor="defaultValue" className="label">
                      Valor Padrão
                    </label>
                    <Input
                      id="defaultValue"
                      type="number"
                      className={`cleanInputNumber`}
                      placeholder="Digite o valor padrão do micro"
                      value={defaultValue.value}
                      error={defaultValue.error}
                      onChange={(e) => {
                        defaultValue.onChange(e);
                        setIsChangesDetected(true);
                      }}
                      onBlur={defaultValue.onBlur}
                    />
                  </div>
                  <div>
                    <label htmlFor="maxDiscount" className="label">
                      Desconto Máximo (%)
                    </label>
                    <Input
                      id="maxDiscount"
                      type="number"
                      className={`cleanInputNumber`}
                      placeholder="Digite a porcentagem máxima de desconto"
                      value={maxDiscount.value}
                      error={maxDiscount.error}
                      onChange={(e) => {
                        maxDiscount.onChange(e);
                        setIsChangesDetected(true);
                      }}
                      onBlur={maxDiscount.onBlur}
                    />
                  </div>
                  <div>
                    <label htmlFor="enterprise" className="label">
                      Empresa
                    </label>
                    <Select
                      id="enterprise"
                      placeholder="Selecione uma empresa"
                      options={enterpriseOptions}
                      value={enterprise.value}
                      error={enterprise.error}
                      onChange={(value) => {
                        enterprise.onChange(value);
                        setIsChangesDetected(true);
                      }}
                      onBlur={enterprise.onBlur}
                      isSearchable={false}
                    />
                  </div>
                  <div>
                    <label htmlFor="status" className="label">
                      Status do Micro
                    </label>
                    <Select
                      id="status"
                      options={optionsStatus}
                      value={status.value}
                      onChange={(value) => {
                        status.onChange(value);
                        setIsChangesDetected(true);
                      }}
                      isSearchable={false}
                      isClearable={false}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.submitButtonContainer}>
                <Button>Atualizar Micro</Button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Atualizando micro</span>
        </div>
      )}
    </div>
  );
}
