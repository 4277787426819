/**
 *
 * @param a ISelectOption[]
 * @param b ISelectOption[]
 * @returns Irá retornar um array com os items contidos em 'a' que não estão em 'b'
 */
const selectOptionsDifference = (a: ISelectOption<any>[], b: ISelectOption<any>[]) => {
  return a.filter((x) => !b.some((y) => y.label === x.label));
};

export default selectOptionsDifference;
