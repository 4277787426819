import React from "react";

import { isValid } from "../../../../../../helpers/validations";

import { Button, LinkButton, Select } from "../../../../../../components/Form";
import { useCustomFetch, useDebounce, useLoading } from "../../../../../../hooks/async";
import { useModal } from "../../../../../../hooks/contexts";
import { useSelect } from "../../../../../../hooks/form";

import styles from "./NewSeller.module.css";
import { Circle } from "../../../../../../components/Loading";
import { selectOptionsDifference } from "../../../../../../helpers/List";

export function NewSeller({ selectedPriceTable, searchSellers: searchSellersInPriceTable, filteredSellers }) {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const sellers = useSelect({ type: "multiple", required: true, emptyMessage: "Selecione ao menos um vendedor" });

  const searchingSellers = useLoading();
  const registeringSeller = useLoading();

  const [sellerOptions, setSellerOptions] = React.useState([]);

  const clearForm = React.useCallback(() => {
    sellers.reset();
    setSellerOptions([]);
  }, [sellers]);

  const searchSellers = React.useCallback(
    async (description = "") => {
      searchingSellers.setLoading(true);
      try {
        const json = await customFetch("/products/searchSellersToAddInPriceTable", {
          body: {
            idTabela: Number(selectedPriceTable.tabela.idTabela),
            descricao: description,
          },
        });
        if (json.status === 200) {
          const options = json.object.map((option) => {
            return {
              value: { ...option },
              label: `${option.vendedor.idVendedor} - ${option.vendedor.nomeVendedor}`,
            };
          });
          setSellerOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setSellerOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingSellers.setLoading(false);
      }
    },
    [Modal, customFetch, searchingSellers, selectedPriceTable]
  );

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (isValid(sellers)) {
        try {
          registeringSeller.setLoading(true);
          const json = await customFetch("/products/registerAndUpdateManySellersOfPriceTable", {
            body: {
              idTabela: Number(selectedPriceTable.tabela.idTabela),
              idVendedores: sellers.value.map((seller) => Number(seller.value.vendedor.idVendedor)),
            },
          });
          if (json.status === 200) {
            searchSellersInPriceTable(filteredSellers);
            await Modal.success(json.message);
            clearForm();
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          registeringSeller.setLoading(false);
        }
      }
    },
    [
      sellers,
      registeringSeller,
      customFetch,
      selectedPriceTable,
      Modal,
      searchSellersInPriceTable,
      clearForm,
      filteredSellers,
    ]
  );

  const { current: searchSellerDebounced } = React.useRef(
    useDebounce((description) => {
      searchSellers(description);
    })
  );

  React.useEffect(() => {
    searchSellers();
  }, []); // eslint-disable-line

  return (
    <div>
      {!registeringSeller.isLoading ? (
        <>
          <div>
            <LinkButton to="/produtos/tabela-de-precos/atualizar/vendedores" buttonStyle="backButton" />
          </div>
          <div className={styles.formContainer}>
            <form onSubmit={onSubmit}>
              <div className={styles.sellerContainer}>
                <div>
                  <label htmlFor="sellers" className="label">
                    Vendedores
                  </label>
                  <Select
                    id="sellers"
                    placeholder="Selecione os vendedores para adicionar"
                    options={selectOptionsDifference(sellerOptions, sellers.value)}
                    value={sellers.value}
                    error={sellers.error}
                    onChange={sellers.onChange}
                    onBlur={sellers.onBlur}
                    onInputChange={(value) => {
                      searchSellerDebounced(value);
                    }}
                    isMulti
                    isLoading={searchingSellers.isLoading}
                  />
                </div>
              </div>
              <div className={styles.submitButtonContainer}>
                <Button>Adicionar Vendedores</Button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className={`loadingContainer`}>
          <Circle size={100} />
          <span className={`loadingMessage`}>Adicionando vendedores à tabela</span>
        </div>
      )}
    </div>
  );
}
