import React from "react";
import { Button, Input, LinkButton, Select } from "../../../../components/Form";
import { useForm, useSelect } from "../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useChanges, useModal } from "../../../../hooks/contexts";
import { Navigate, useNavigate } from "react-router-dom";
import { isValid } from "../../../../helpers/validations";
import { IBank } from "../List/List";
import { Circle } from "../../../../components/Loading";

import styles from "./UpdateBank.module.css";

interface IProps {
  searchBanks: () => void;
  selectedBank: IBank | null;
}

export function UpdateBank({ searchBanks, selectedBank }: IProps) {
  const Changes = useChanges();
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();

  const description = useForm({ required: true });
  const status = useSelect({ type: "single", required: true });

  const savingBank = useLoading();

  const statusOptions = React.useMemo(
    () => [
      {
        value: "A",
        label: "Ativo",
      },
      {
        value: "I",
        label: "Inativo",
      },
    ],
    []
  );

  const saveBank = React.useCallback(async () => {
    if (!isValid(description, status)) return;
    try {
      savingBank.setLoading(true);
      const json = (await customFetch("/finance/insertAndUpdateBank", {
        body: {
          bancoId: selectedBank?.bancoId,
          descricao: description.value,
          status: status.value?.value,
        },
      })) as DefaultFetchResponse<null>;
      if (json.status === 200) {
        Changes.reset();
        searchBanks();
        await Modal.success(json.message);
        navigate("/financeiro/bancos");
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      savingBank.setLoading(false);
    }
  }, [Modal, customFetch, description, navigate, savingBank, selectedBank, searchBanks, status, Changes]);

  React.useEffect(() => {
    if (selectedBank) {
      description.setValue(selectedBank.descricao);
      status.setValue(() => {
        if (selectedBank.status === "A") return statusOptions[0];
        else return statusOptions[1];
      });
    }
  }, []); // eslint-disable-line

  if (selectedBank === null) return <Navigate to={"/financeiro/bancos"} />;

  return (
    <div className="container">
      {!savingBank.isLoading ? (
        <>
          <div>
            <LinkButton to={"/financeiro/bancos"} buttonStyle="backButton" />
          </div>
          <div className={styles.fieldsContainer}>
            <div>
              <label htmlFor="description" className="label">
                Descrição
              </label>
              <Input
                id="description"
                placeholder="Digite a descrição do banco"
                value={description.value}
                error={description.error}
                onChange={(e) => {
                  Changes.set();
                  description.onChange(e);
                }}
                onBlur={description.onChange}
              />
            </div>
            <div className={styles.fieldsContainer__statusField}>
              <label htmlFor="status" className="label">
                Situação
              </label>
              <Select
                options={statusOptions}
                value={status.value}
                onChange={(value) => {
                  Changes.set();
                  status.onChange(value);
                }}
                isSearchable={false}
                isClearable={false}
              />
            </div>
          </div>
          <div className={styles.saveButtonContainer}>
            <Button onClick={saveBank} disabled={!Changes.isChangesDetected}>
              Atualizar Banco
            </Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Atualizando Banco</span>
        </div>
      )}
    </div>
  );
}
