import { useCallback, useState } from "react";

import { useSelect } from "../../../../../../../hooks/form";
import { useCustomFetch, useDebounce, useLoading } from "../../../../../../../hooks/async";
import { useModal } from "../../../../../../../hooks/contexts";
import { isValid } from "../../../../../../../helpers/validations";
import { useNavigate } from "react-router-dom";

import { ISeller, IUseCloneCommissionsToSellers } from "./types";

export function useCloneCommissionsToSellers(): IUseCloneCommissionsToSellers {
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();

  const copiedSeller = useSelect<ISeller, "single">({ type: "single", required: true });
  const sellers = useSelect<ISeller, "multiple">({ type: "multiple", required: true });
  const supervisor = useSelect<ISeller, "single">({ type: "single", required: false });

  const [copiedSellerOptions, setCopiedSellerOptions] = useState<ISelectOption<ISeller>[]>([]);
  const [sellerOptions, setSellerOptions] = useState<ISelectOption<ISeller>[]>([]);
  const [supervisorOptions, setSupervisorOptions] = useState<ISelectOption<ISeller>[]>([]);

  const searchingCopiedSellerOptions = useLoading();
  const searchingSellerOptions = useLoading();
  const searchingSupervisorOptions = useLoading();
  const cloningCommissions = useLoading();

  const searchSellerOptionsPromise = useCallback(
    (description = "") => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await customFetch("/commissions/searchPeoples", {
            body: { descricao: description },
          });
          resolve(response);
        } catch (error) {
          reject(error);
        }
      }) as Promise<DefaultFetchResponse<ISeller[]>>;
    },
    [customFetch]
  );

  const searchCopiedSellerOptions = useCallback(
    async (description: string = "") => {
      try {
        searchingCopiedSellerOptions.setLoading(true);
        const json = await searchSellerOptionsPromise(description);
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            label: `${option.idUsuario} | ${option.nome}`,
            value: option,
          }));
          setCopiedSellerOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setCopiedSellerOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingCopiedSellerOptions.setLoading(false);
      }
    },
    [Modal, searchSellerOptionsPromise, searchingCopiedSellerOptions]
  );

  const searchSellerOptions = useCallback(
    async (description: string = "") => {
      try {
        searchingSellerOptions.setLoading(true);
        const json = await searchSellerOptionsPromise(description);
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            label: `${option.idUsuario} | ${option.nome}`,
            value: option,
          }));
          setSellerOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setSellerOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingSellerOptions.setLoading(false);
      }
    },
    [Modal, searchSellerOptionsPromise, searchingSellerOptions]
  );

  const searchSupervisorOptions = useCallback(
    async (description: string = "") => {
      try {
        searchingSupervisorOptions.setLoading(true);
        const json = await searchSellerOptionsPromise(description);
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            label: `${option.idUsuario} | ${option.nome}`,
            value: option,
          }));
          setSupervisorOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setSupervisorOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingSupervisorOptions.setLoading(false);
      }
    },
    [Modal, searchSellerOptionsPromise, searchingSupervisorOptions]
  );

  const searchCopiedSellerOptionsDebounced = useDebounce(searchCopiedSellerOptions);
  const searchSellerOptionsDebounced = useDebounce(searchSellerOptions);
  const searchSupervisorOptionsDebounced = useDebounce(searchSupervisorOptions);

  const cloneCommissionsToSellers = useCallback(
    async (tableId: number) => {
      if (!isValid(copiedSeller, sellers, supervisor)) return;
      try {
        cloningCommissions.setLoading(true);
        customFetch("/commissions/cloneCommissionsToSellers", {
          body: {
            idTabela: tableId,
            idVendedor: copiedSeller.value?.value.idUsuario,
            novosVendedores: sellers.value?.map((seller) => seller.value?.idUsuario),
            novoSupervisor: supervisor.value?.value.idUsuario,
          },
        });
        await Modal.success(
          "As comissões do vendedor estão sendo copiadas!<br/>Você será avisado por e-mail ao término do processo!"
        );
        navigate("/comissoes/adubo/tabelas-de-comissoes/itens");
      } catch (error) {
        Modal.error(error);
      } finally {
        cloningCommissions.setLoading(false);
      }
    },
    [Modal, cloningCommissions, copiedSeller, customFetch, sellers, supervisor, navigate]
  );

  return {
    copiedSeller,
    sellers,
    supervisor,
    searchSellerOptions: {
      search: searchSellerOptions,
      searchDebounced: searchSellerOptionsDebounced,
      options: sellerOptions,
      searching: searchingSellerOptions.isLoading,
    },
    searchCopiedSellerOptions: {
      search: searchCopiedSellerOptions,
      searchDebounced: searchCopiedSellerOptionsDebounced,
      options: copiedSellerOptions,
      searching: searchingCopiedSellerOptions.isLoading,
    },
    searchSupervisorOptions: {
      search: searchSupervisorOptions,
      searchDebounced: searchSupervisorOptionsDebounced,
      options: supervisorOptions,
      searching: searchingSupervisorOptions.isLoading,
    },
    cloneCommissions: {
      clone: cloneCommissionsToSellers,
      cloning: cloningCommissions.isLoading,
    },
  };
}
