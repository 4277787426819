import { useCallback, useMemo } from "react";
import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { useForm, useSelect } from "../../../../../hooks/form";
import { isValid } from "../../../../../helpers/validations";
import { IUseAdicionarAtualizar, Status, StatusOptions } from "./types";
import { IPenalidade, IUsePenalidades } from "../../hooks/types";
import { useNavigate } from "react-router-dom";

interface IProps {
  penalidadesData: IUsePenalidades;
}

export function useAdicionarAtualizar({ penalidadesData }: IProps): IUseAdicionarAtualizar {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const nomePenalidade = useForm({ required: true });
  const status = useSelect<Status>({ required: true, type: "single", defaultValue: { value: "A", label: "Ativo" } });

  const statusOptions = useMemo<StatusOptions>(
    () => [
      { value: "A", label: "Ativo" },
      { value: "I", label: "Inativo" },
    ],
    []
  );

  const insertingOrUpdatingPenalidade = useLoading();

  const carregarDados = useCallback(
    (selectedPenalidade: IPenalidade) => {
      nomePenalidade.setValue(selectedPenalidade.nomePenalidade);
      status.setValue(() => {
        if (selectedPenalidade.status === "A") return statusOptions[0];
        else return statusOptions[1];
      });
    },
    [nomePenalidade, status, statusOptions]
  );

  const insertOrUpdatePenalidade = useCallback(
    async (id: number = 0) => {
      if (!isValid(nomePenalidade, status)) return;
      const body = {
        idPenalidade: id,
        nomePenalidade: nomePenalidade.value,
        status: status.value?.value,
      };
      try {
        insertingOrUpdatingPenalidade.setLoading(true);
        const json = await customFetch(`/juridico/insertAndUpdatePenalidade`, {
          body,
        });
        if (json.status === 200) {
          penalidadesData.searchs.searchPenalidadesPaginated("");
          await Modal.success(json.message);
          navigate("/contratos-fornecedor-cliente/penalidades");
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        insertingOrUpdatingPenalidade.setLoading(false);
      }
    },
    [Modal, customFetch, insertingOrUpdatingPenalidade, nomePenalidade, status, penalidadesData.searchs, navigate]
  );

  return {
    carregarDados,
    form: {
      nomePenalidade,
      status,
    },
    selectOptions: {
      statusOptions,
    },
    interactions: {
      insertOrUpdatePenalidade,
      insertingOrUpdatingPenalidade: insertingOrUpdatingPenalidade.isLoading,
    },
  };
}
