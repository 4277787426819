import React from "react";

import { Button, Input, LinkButton, Select } from "../../../../../components/Form";

import { useForm, useSelect } from "../../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useChanges, useModal } from "../../../../../hooks/contexts";

import { isValid } from "../../../../../helpers/validations";

import { Circle } from "../../../../../components/Loading";

import styles from "./NewCost.module.css";

export function NewCost({ searchTotalCostRecordsAndCosts, selectedMicro }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const { setIsChangesDetected } = useChanges();
  const registeringCost = useLoading();
  const searchingMarginOptions = useLoading();

  const margin = useSelect({ type: "single", required: true, emptyMessage: "Selecione uma margem" });
  const cost = useForm({ type: "", required: true });
  const company = useForm({ type: "", required: true });

  const [marginOptions, setMarginOptions] = React.useState([]);

  const clearForm = React.useCallback(() => {
    margin.setValue("");
    margin.setError(null);
    cost.setValue("");
    cost.setError(null);
    company.setValue("");
    company.setError(null);
  }, [cost, margin, company]);

  const searchMarginOptions = React.useCallback(async () => {
    try {
      searchingMarginOptions.setLoading(true);
      const json = await customFetch("/products/searchProfitMargins", {
        body: {
          descricao: "",
        },
      });
      if (json.status === 200) {
        const options = json.object.map((option) => {
          return {
            value: option,
            label: `${option.id} | ${option.descricao}`,
          };
        });
        setMarginOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingMarginOptions.setLoading(false);
    }
  }, [Modal, customFetch, searchingMarginOptions]);

  const registerCost = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        registeringCost.setLoading(true);
        const json = await customFetch("/products/registerAndUpdateMicroCost", {
          body: {
            idMicro: selectedMicro.idComplementar,
            idMargem: margin.value.value.id,
            custo: Number(cost.value),
            idEmpresa: Number(company.value)
          },
        });
        resolve(json);
      } catch (error) {
        reject(error);
      } finally {
        registeringCost.setLoading(false);
      }
    });
  }, [registeringCost, customFetch, selectedMicro, cost, margin, company]);

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (isValid(margin, cost, company)) {
        try {
          const json = await registerCost();
          if (json.status === 200) {
            await Modal.success(json.message);
            clearForm();
            setIsChangesDetected(false);
            searchTotalCostRecordsAndCosts();
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        }
      }
    },
    [margin, cost, registerCost, company, Modal, clearForm, setIsChangesDetected, searchTotalCostRecordsAndCosts]
  );

  React.useEffect(() => {
    searchMarginOptions();
  }, []); //eslint-disable-line

  return (
    <div className={`container ${styles.container}`}>
      {!registeringCost.isLoading ? (
        <>
          <div className={styles.navigationContainer}>
            <LinkButton to="/produtos/micros/custos" buttonStyle="backButton" />
            <h2 className={styles.packingName}>{selectedMicro.descricaoEnvase}</h2>
          </div>
          <div>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className={styles.fieldsContainer}>
                <div>
                  <label htmlFor="margin" className="label">
                    Margem
                  </label>
                  <Select
                    id="margin"
                    placeholder="Selecione uma margem de lucro"
                    options={marginOptions}
                    value={margin.value}
                    error={margin.error}
                    onChange={(value) => {
                      margin.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={margin.onBlur}
                    isSearchable={false}
                    isLoading={searchingMarginOptions.isLoading}
                  />
                </div>
                <div>
                  <label htmlFor="cost" className="label">
                    Custo
                  </label>
                  <Input
                    className={`cleanInputNumber`}
                    type="number"
                    id="cost"
                    placeholder="Digite o custo"
                    value={cost.value}
                    error={cost.error}
                    onChange={(value) => {
                      cost.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={cost.onBlur}
                  />
                </div>
                <div>
                  <label htmlFor="empresa" className="label">
                    Empresa
                  </label>
                  <Input
                    className={`cleanInputNumber`}
                    type="number"
                    id="cost"
                    placeholder="Digite a Empresa"
                    value={company.value}
                    error={company.error}
                    onChange={(value) => {
                      company.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={company.onBlur}
                  />
                </div>
              </div>
              <div className={styles.submitButtonContainer}>
                <Button>Cadastrar Custo</Button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Cadastrando Custo</span>
        </div>
      )}
    </div>
  );
}
