import React from "react";

import { Navigate, Routes, Route, NavLink } from "react-router-dom";

import { LinkButton } from "../../../../components/Form";
import { useSelect } from "../../../../hooks/form";

import styles from "./UpdatePriceTable.module.css";
import { Table } from "./Table/Table";
import { Clients } from "./Clients/Clients";
import { Supervisors } from "./Supervisors/Supervisors";
import { Sellers } from "./Sellers/Sellers";
import { Items } from "./Items/Items";
import { Enterprises } from "./Enterprises/Enterprises";

export function UpdatePriceTable({ selectedPriceTable, researchPriceTables, filteredPriceTable }) {
  const selectedPage = useSelect({ type: "single", required: true });
  const pageOptions = [
    {
      value: "/produtos/tabela-de-precos/atualizar",
      label: "Tabela de Preços",
    },
    {
      value: "/produtos/tabela-de-precos/atualizar/clientes",
      label: "Clientes",
    },
    {
      value: "/produtos/tabela-de-precos/atualizar/itens",
      label: "Itens",
    },
  ];

  React.useEffect(() => {
    selectedPage.setValue(pageOptions[0]);
  }, []); // eslint-disable-line

  if (!selectedPriceTable) {
    return <Navigate to="/produtos/tabela-de-precos" />;
  }

  return (
    <div className={`container`}>
      <div className={`${styles.navigationContainer}`}>
        <LinkButton to="/produtos/tabela-de-precos" buttonStyle="backButton" />
        <h2 className={styles.packingName}>{selectedPriceTable.tabela.descricaoTabela}</h2>
      </div>
      <div className={styles.selectedPageContainer}>
        <div className={styles.pageNavigationContainer}>
          <NavLink to="/produtos/tabela-de-precos/atualizar" end>
            Tabela de Preços
          </NavLink>
          <NavLink to="/produtos/tabela-de-precos/atualizar/itens">Itens</NavLink>
          <NavLink to="/produtos/tabela-de-precos/atualizar/clientes">Clientes</NavLink>
          <NavLink to="/produtos/tabela-de-precos/atualizar/supervisores">Supervisores</NavLink>
          <NavLink to="/produtos/tabela-de-precos/atualizar/vendedores">Vendedores</NavLink>
          <NavLink to="/produtos/tabela-de-precos/atualizar/empresas">Empresas</NavLink>
        </div>
      </div>
      <div className={`container ${styles.pageContainer}`}>
        <Routes>
          <Route
            path="/"
            element={
              <Table
                selectedPriceTable={selectedPriceTable}
                researchPriceTables={researchPriceTables}
                filteredPriceTable={filteredPriceTable}
              />
            }
          />
          <Route path="itens/*" element={<Items selectedPriceTable={selectedPriceTable} />} />
          <Route path="clientes/*" element={<Clients selectedPriceTable={selectedPriceTable} />} />
          <Route path="supervisores/*" element={<Supervisors selectedPriceTable={selectedPriceTable} />} />
          <Route path="vendedores/*" element={<Sellers selectedPriceTable={selectedPriceTable} />} />
          <Route path="empresas/*" element={<Enterprises selectedPriceTable={selectedPriceTable} />} />
        </Routes>
      </div>
    </div>
  );
}
