import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDate, useForm, useSelect } from "../../../hooks/form";
import { IEmpresa, INonconformity, ISeller, TipoPagina } from "./types";
import { Button } from "../../../components/Form";
import { ITableData } from "../../../components/Data/XTable";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../hooks/contexts";
import { useCustomFetch, useDebounce, useLoading } from "../../../hooks/async";
import { isValid } from "../../../helpers/validations";
import { usePagination } from "../../../hooks/pagination";
import { usePreviousRoute } from "../../../hooks/navigation";

const useNonconformity = (pageType: TipoPagina[]) => {
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();

  const previousRoute = usePreviousRoute();

  const filterOrder = useForm({ required: true });
  const filterInitialDate = useDate({ required: true });
  const filterFinalDate = useDate({ required: true });
  const filterEnterprise = useSelect<IEmpresa, "multiple">({
    type: "multiple",
    required: true,
  });
  const filterSellers = useSelect<ISeller, "multiple">({
    type: "multiple",
    required: true,
  });

  const pagination = usePagination();

  const [enterpriseOptions, setEnterpriseOptions] = useState<
    ISelectOption<IEmpresa>[]
  >([]);
  const [sellerOptions, setSellerOptions] = useState<ISelectOption<ISeller>[]>(
    []
  );

  const [selectedOrder, setSelectedOrder] = useState<INonconformity | null>(
    null
  );

  const [nonconformingOrders, setNonconformingOrders] = useState<
    INonconformity[]
  >([]);

  const tableData = useMemo<ITableData>(() => {
    const data = nonconformingOrders.map((item) => ({
      ...item,
      nomeFantasiaCliente: item.pedido.cliente.nomeFantasia,
      nomeVendedor: item.vendedor.nome,
      detalhes: (
        <>
          <Button
            onClick={() => {
              setSelectedOrder(item);
              navigate("detalhes");
            }}
          >
            Detalhes
          </Button>
        </>
      ),
    }));

    return {
      columns: [
        {
          title: "Id",
          objectName: "idNaoConformidade",
          isSorted: false,
          style: { width: "10px" },
        },
        {
          title: "IdPedido",
          objectName: "idPedido",
          isSorted: false,
          style: { width: "10px" },
        },
        {
          title: "Cliente",
          objectName: "nomeFantasiaCliente",
          isSorted: false,
          style: { textAlign: "center" },
        },
        {
          title: "Vendedor",
          objectName: "nomeVendedor",
          isSorted: false,
          style: { textAlign: "center" },
        },
        {
          title: "",
          objectName: "detalhes",
          isSorted: false,
          style: { width: "10px" },
        },
      ],
      data: data,
    };
  }, [navigate, nonconformingOrders]);

  const searchingNonconformingOrders = useLoading();
  const searchingEnterprises = useLoading();
  const searchingSellers = useLoading();
  const approvingOrReprovingNonconformity = useLoading();

  const searchEnterprises = useCallback(async () => {
    try {
      searchingEnterprises.setLoading(true);
      const json = (await customFetch("/pedidosAPI/searchEnterprises", {
        body: {},
      })) as DefaultFetchResponse<IEmpresa[]>;
      if (json.status === 200) {
        const options: ISelectOption<IEmpresa>[] = json.object.map((item) => ({
          label: `${item.idEmpresa} | ${item.nomeEmpresa}`,
          value: item,
        }));
        setEnterpriseOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      searchingEnterprises.setLoading(false);
    }
  }, [Modal, customFetch, searchingEnterprises]);

  const searchSellers = useCallback(
    async (description = "") => {
      try {
        searchingSellers.setLoading(true);
        const json = (await customFetch("/pedidosAPI/searchSellers", {
          body: {
            descricao: description,
          },
        })) as DefaultFetchResponse<ISeller[]>;
        if (json.status === 200) {
          const options = json.object.map((seller) => ({
            label: `${seller.idVendedor} | ${seller.nome}`,
            value: seller,
          }));
          setSellerOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        searchingSellers.setLoading(false);
      }
    },
    [Modal, customFetch, searchingSellers]
  );

  const searchSellersDebounced = useDebounce(searchSellers);

  const searchNonconformingOrdersPromise = useCallback(
    (page = 1) => {
      return new Promise<DefaultFetchResponse<INonconformity[]>>(
        async (resolve, reject) => {
          try {
            const json = await customFetch(
              "/pedidosAPI/searchNonconformingOrders",
              {
                body: {
                  tamanhoPagina: pagination.maxItems,
                  paginaAtual: page,
                  dataInicial: filterInitialDate.toISOString(),
                  dataFinal: filterFinalDate.toISOStringNextDay(),
                  empresas: filterEnterprise.value?.map(
                    (enterprise) => enterprise.value.idEmpresa
                  ),
                  vendedores: filterSellers.value?.map(
                    (seller) => seller.value.idVendedor
                  ),
                  listaStatus: pageType,
                  descricao: filterOrder.value,
                },
              }
            );
            resolve(json);
          } catch (error) {
            reject(error);
          }
        }
      );
    },
    [
      customFetch,
      filterEnterprise.value,
      filterFinalDate,
      filterInitialDate,
      filterOrder.value,
      filterSellers.value,
      pageType,
      pagination.maxItems,
    ]
  );

  const searchTotalNonconformingOrderRecordsPromise = useCallback(
    (page = 1) => {
      return new Promise<DefaultFetchResponse<number>>(
        async (resolve, reject) => {
          try {
            const json = await customFetch(
              "/pedidosAPI/searchTotalNonconformingOrderRecords",
              {
                body: {
                  tamanhoPagina: pagination.maxItems,
                  paginaAtual: page,
                  dataInicial: filterInitialDate.toISOString(),
                  dataFinal: filterFinalDate.toISOStringNextDay(),
                  empresas: filterEnterprise.value?.map(
                    (enterprise) => enterprise.value.idEmpresa
                  ),
                  vendedores: filterSellers.value?.map(
                    (seller) => seller.value.idVendedor
                  ),
                  listaStatus: pageType,
                  descricao: filterOrder.value,
                },
              }
            );
            resolve(json);
          } catch (error) {
            reject(error);
          }
        }
      );
    },
    [
      customFetch,
      filterEnterprise.value,
      filterFinalDate,
      filterInitialDate,
      filterOrder.value,
      filterSellers.value,
      pageType,
      pagination.maxItems,
    ]
  );

  const searchNonconformingOrders = useCallback(
    async (page: number) => {
      if (!isValid(filterInitialDate, filterFinalDate, filterEnterprise))
        return;
      try {
        searchingNonconformingOrders.setLoading(true);
        const json = await searchNonconformingOrdersPromise(page);
        if (json.status === 200) {
          setNonconformingOrders(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setNonconformingOrders([]);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        searchingNonconformingOrders.setLoading(false);
      }
    },
    [
      Modal,
      filterFinalDate,
      filterInitialDate,
      searchNonconformingOrdersPromise,
      searchingNonconformingOrders,
      filterEnterprise,
    ]
  );

  const searchTotalRecordsAndNonconformingOrders = useCallback(async () => {
    if (!isValid(filterInitialDate, filterFinalDate, filterEnterprise)) return;
    try {
      searchingNonconformingOrders.setLoading(true);
      const [records, json] = await Promise.all([
        searchTotalNonconformingOrderRecordsPromise(),
        searchNonconformingOrdersPromise(),
      ]);
      if (json.status === 200) {
        if (records.status === 200) {
          setNonconformingOrders(json.object);
          pagination.setTotalRecords(records.object);
        } else if (records.status === 500) {
          Modal.error(records.message, records.object);
        } else {
          setNonconformingOrders([]);
        }
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        pagination.reset();
        setNonconformingOrders([]);
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      searchingNonconformingOrders.setLoading(false);
    }
  }, [
    Modal,
    filterFinalDate,
    filterInitialDate,
    pagination,
    searchNonconformingOrdersPromise,
    searchTotalNonconformingOrderRecordsPromise,
    searchingNonconformingOrders,
    filterEnterprise,
  ]);

  const approveAndReproveNonconformity = useCallback(
    async (
      action: "approve" | "reprove",
      reason: string,
      qtdApproved: number
    ) => {
      const body = JSON.parse(JSON.stringify(selectedOrder)) as INonconformity;
      body.observacao = reason;
      if (action === "approve") {
        body.qtdProdutoAprovada = qtdApproved;
        if (
          pageType.some((type) =>
            [TipoPagina.pendingManager, TipoPagina.reopened].includes(type)
          )
        )
          body.status = TipoPagina.pendingQuality;
        else if (pageType.includes(TipoPagina.pendingQuality))
          body.status = TipoPagina.approvedQuality;
      } else {
        if (
          pageType.some((type) =>
            [TipoPagina.pendingManager, TipoPagina.reopened].includes(type)
          )
        )
          body.status = TipoPagina.reprovedManager;
        else if (pageType.includes(TipoPagina.pendingQuality))
          body.status = TipoPagina.reprovedQuality;
      }

      try {
        approvingOrReprovingNonconformity.setLoading(true);
        const json = (await customFetch(
          "/pedidosAPI/approveAndReproveNonconformity",
          {
            body,
          }
        )) as DefaultFetchResponse<any>;
        if (json.status === 200) {
          searchTotalRecordsAndNonconformingOrders();
          await Modal.success(json.message);
          navigate(previousRoute);
        } else {
          Modal.error(json.message, json.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        approvingOrReprovingNonconformity.setLoading(false);
      }
    },
    [
      Modal,
      approvingOrReprovingNonconformity,
      customFetch,
      navigate,
      pageType,
      previousRoute,
      searchTotalRecordsAndNonconformingOrders,
      selectedOrder,
    ]
  );

  function setInitialAndFinalDates() {
    const currentDate = new Date();
    const stringCurrentDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

    const initialDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      currentDate.getDate()
    );
    const stringInitialDate = `${initialDate.getFullYear()}-${String(
      initialDate.getMonth() + 1
    ).padStart(2, "0")}-${String(initialDate.getDate()).padStart(2, "0")}`;

    filterFinalDate.setValue(stringCurrentDate);
    filterInitialDate.setValue(stringInitialDate);
  }

  useMemo(() => {
    if (filterEnterprise.value.length === 0 && enterpriseOptions.length > 0) {
      filterEnterprise.setValue(
        enterpriseOptions.filter((enterprise) =>
          [9, 11, 14].includes(enterprise.value.idEmpresa)
        )
      );
    }
  }, [enterpriseOptions]); // eslint-disable-line

  useEffect(() => {
    searchSellers();
    searchEnterprises();
    setInitialAndFinalDates();
  }, []); // eslint-disable-line

  return {
    pagination,
    data: {
      tableData,
      selectedOrder,
    },
    sets: {
      setSelectedOrder,
    },
    filters: {
      filterOrder,
      filterInitialDate,
      filterFinalDate,
      filterEnterprise,
      filterSellers,
    },
    options: {
      enterpriseOptions,
      sellerOptions,
    },
    methods: {
      searchNonconformingOrders,
      searchTotalRecordsAndNonconformingOrders,
      searchEnterprises,
      searchSellers,
      searchSellersDebounced,
      approveAndReproveNonconformity,
    },
    loadings: {
      searchingNonconformingOrders: searchingNonconformingOrders.isLoading,
      searchingEnterprises: searchingEnterprises.isLoading,
      searchingSellers: searchingSellers.isLoading,
      approvingOrReprovingNonconformity:
        approvingOrReprovingNonconformity.isLoading,
    },
  };
};

export default useNonconformity;
