import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import { usePagination } from "../../../../hooks/pagination";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";

import { CostList } from "./CostList/CostList";
import { NewCost } from "./NewCost/NewCost";

export function Costs({ selectedPacking }) {
  const pagination = usePagination();
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const searchingCosts = useLoading();

  const [costList, setCostList] = React.useState([]);

  const searchTotalCostRecords = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const json = await customFetch("/products/searchTotalPackingCostRecords", {
          body: {
            tamanho: pagination.maxItems,
            embalagem: Number(selectedPacking.idEnvase),
          },
        });
        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
  }, [customFetch, pagination, selectedPacking]);

  const searchCosts = React.useCallback(
    async (page) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/products/searchPackingCostsPaginated", {
            body: {
              pagina: page ? Number(page) - 1 : 0,
              tamanho: pagination.maxItems,
              embalagem: Number(selectedPacking.idEnvase),
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination, selectedPacking]
  );

  const searchTotalCostRecordsAndCosts = React.useCallback(async () => {
    try {
      searchingCosts.setLoading(true);
      const jsonGroups = await searchCosts();
      if (jsonGroups.status === 200) {
        const jsonRecords = await searchTotalCostRecords();
        if (jsonRecords.status === 200) {
          setCostList(jsonGroups.object);
          pagination.setTotalRecords(jsonRecords.object.total);
        } else if (jsonRecords.status === 500) {
          Modal.error(jsonRecords.message, jsonRecords.object);
        } else {
          setCostList([]);
        }
      } else if (jsonGroups.status === 500) {
        Modal.error(jsonGroups.message, jsonGroups.object);
      } else {
        setCostList([]);
        pagination.reset();
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      pagination.setCurrentPage(1);
      searchingCosts.setLoading(false);
    }
  }, [Modal, pagination, searchCosts, searchTotalCostRecords, searchingCosts]);

  if (!selectedPacking || !selectedPacking.idEnvase) {
    return <Navigate to="/produtos/embalagens" />;
  }

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <CostList
              selectedPacking={selectedPacking}
              pagination={pagination}
              costList={{
                value: costList,
                setValue: setCostList,
              }}
              searchCosts={searchCosts}
              searchTotalCostRecordsAndCosts={searchTotalCostRecordsAndCosts}
              searchingCosts={searchingCosts}
            />
          }
        />
        <Route
          path="novo"
          element={
            <NewCost
              selectedPacking={selectedPacking}
              searchTotalCostRecordsAndCosts={searchTotalCostRecordsAndCosts}
            />
          }
        />
      </Routes>
    </div>
  );
}
