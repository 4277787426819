import React, { TableHTMLAttributes } from "react";
import styles from "./index.module.css";
import { Arrows } from "./components/Arrows";

interface IColumn {
  title: string;
  objectName: string;
  isSorted: boolean;
  formatting?: (value: any) => any;
  style?: React.CSSProperties;
}

export interface IData {
  [key: string]: any;
}

export interface ITableData {
  columns: IColumn[];
  data: IData[];
}

type TSortedIndex = null | number;
export type TSortedState = null | "ascending" | "descending";

interface ICurrentSorted {
  sortedIndex: TSortedIndex;
  sortedState: TSortedState;
}

interface IProps extends TableHTMLAttributes<HTMLTableElement> {
  tableData: ITableData;
  onClickLineCallback?: (data: IData) => void;
  lineOptions?: {
    style?: (data: IData) => React.CSSProperties;
    onClick?: (data: IData) => void;
  };
}

export function Table({
  tableData: { columns, data },
  onClickLineCallback,
  lineOptions,
  className = "",
  ...rest
}: IProps) {
  const [currrentSorted, setCurrentSorted] = React.useState<ICurrentSorted>({
    sortedIndex: null,
    sortedState: null,
  });

  const sortFunction = React.useCallback((rows: IData[], objectName: string, sortedState: TSortedState) => {
    const newArray = Array.from(rows);
    newArray.sort((a, b) => {
      if (
        !isNaN(parseFloat(a[objectName])) &&
        isFinite(a[objectName]) &&
        !isNaN(parseFloat(b[objectName])) &&
        isFinite(b[objectName])
      ) {
        return parseFloat(a[objectName]) - parseFloat(b[objectName]);
      }
      if (typeof a[objectName] === "string" && typeof b[objectName] === "string") {
        return a[objectName].localeCompare(b[objectName]);
      }
      if (!isNaN(parseFloat(a[objectName])) && isFinite(b[objectName])) {
        return -1;
      }
      return 1;
    });
    if (sortedState === "ascending") {
      return Array.from(newArray);
    } else {
      return Array.from(newArray).reverse();
    }
  }, []);

  const onChangeSort = React.useCallback(
    (index: number) => {
      if (currrentSorted.sortedIndex !== index) {
        setCurrentSorted({
          sortedIndex: index,
          sortedState: "ascending",
        });
      } else if (currrentSorted.sortedState === "ascending") {
        setCurrentSorted({
          sortedIndex: index,
          sortedState: "descending",
        });
      } else if (currrentSorted.sortedState === "descending") {
        setCurrentSorted({
          sortedIndex: null,
          sortedState: null,
        });
      }
    },
    [currrentSorted.sortedIndex, currrentSorted.sortedState]
  );

  return (
    <table className={`${styles.table} ${className}`} {...rest}>
      <thead>
        <tr>
          {columns.map((item, index) => {
            if (item.isSorted) {
              return (
                <th
                  key={index}
                  onClick={() => {
                    onChangeSort(index);
                  }}
                  style={item.style}>
                  <span className={styles.thWithSort}>
                    {item.title}{" "}
                    <Arrows
                      sortTypeActivated={currrentSorted.sortedIndex === index ? currrentSorted.sortedState : null}
                    />
                  </span>
                </th>
              );
            } else {
              return (
                <th key={index} style={item.style}>
                  {item.title}
                </th>
              );
            }
          })}
        </tr>
      </thead>
      <tbody>
        {currrentSorted.sortedIndex !== null
          ? sortFunction(data, columns[currrentSorted.sortedIndex].objectName, currrentSorted.sortedState).map(
              (item, index) => {
                return (
                  <tr
                    key={index}
                    className={
                      onClickLineCallback !== undefined || lineOptions?.onClick !== undefined
                        ? styles.clickableLine
                        : null
                    }
                    style={lineOptions?.style !== undefined ? lineOptions.style(item) : undefined}
                    onClick={() => {
                      if (onClickLineCallback !== undefined) onClickLineCallback(item);
                      else if (lineOptions?.onClick !== undefined) lineOptions.onClick(item);
                    }}>
                    {columns.map(({ objectName: name, formatting }, index) => (
                      <td key={index}>{formatting ? formatting(item[name]) : item[name]}</td>
                    ))}
                  </tr>
                );
              }
            )
          : data.map((item, index) => {
              return (
                <tr
                  key={index}
                  className={onClickLineCallback !== undefined ? styles.clickableLine : null}
                  style={lineOptions?.style !== undefined ? lineOptions.style(item) : undefined}
                  onClick={() => {
                    if (onClickLineCallback !== undefined) onClickLineCallback(item);
                    else if (lineOptions?.onClick !== undefined) lineOptions.onClick(item);
                  }}>
                  {columns.map(({ objectName: name, formatting }, index) => (
                    <td key={index}>{formatting ? formatting(item[name]) : item[name]}</td>
                  ))}
                </tr>
              );
            })}
      </tbody>
    </table>
  );
}
