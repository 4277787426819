import React, { useRef } from "react";

import { Route, Routes } from "react-router-dom";

import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { usePagination } from "../../../../hooks/pagination";

import { RequestsList } from "./RequestsList/RequestsList";
import { Historical } from "./Historical/Historical";
import { useForm } from "../../../../hooks/form";

export function PremiationsSituationRequestsSituation({ sector, page }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const scrollPosition = useRef(0);

  const pagination = usePagination(100);

  const filteredSolicitation = useForm({ required: false });

  const [requestList, setRequestList] = React.useState([]);

  const [selectedRequest, setSelectedRequest] = React.useState(null);

  const searchingRequests = useLoading();

  const getStatusList = React.useCallback(() => {
    if (page === "general") {
      return ["2", "3", "4", "5", "6"];
    } else if (page === "analysisCommercial") {
      return ["2"];
    } else if (page === "analysisFinancial") {
      return ["4"];
    } else if (page === "paid") {
      return ["6"];
    } else if (page === "rejected") {
      return ["3", "5"];
    }
  }, [page]);

  const searchTotalRequestRecordsPromise = React.useCallback(() => {
    let url = "";
    if (sector === "manager") {
      url = "/premiations/historical/manager/searchAllRequestRecords";
    } else if (sector === "commercial") {
      url = "/premiations/historical/commercial/searchAllRequestRecords";
    } else if (sector === "financial") {
      url = "/premiations/historical/financial/searchAllRequestRecords";
    }

    return new Promise(async (resolve, reject) => {
      try {
        const json = await customFetch(url, {
          body: {
            tamanho: pagination.maxItems,
            status: getStatusList(),
            idSolicitacao: filteredSolicitation.value
              ? Number(filteredSolicitation.value)
              : null,
          },
        });
        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
  }, [customFetch, pagination, sector, getStatusList, filteredSolicitation]);

  const searchRequestsPromise = React.useCallback(
    (page = 1) => {
      let url = "";
      if (sector === "manager") {
        url = "/premiations/historical/manager/searchAllRequestsPaginated";
      } else if (sector === "commercial") {
        url = "/premiations/historical/commercial/searchAllRequestsPaginated";
      } else if (sector === "financial") {
        url = "/premiations/historical/financial/searchAllRequestsPaginated";
      }

      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch(url, {
            body: {
              pagina: page - 1,
              tamanho: 100,
              status: getStatusList(),
              idSolicitacao: filteredSolicitation.value
                ? Number(filteredSolicitation.value)
                : null,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [sector, customFetch, getStatusList, filteredSolicitation.value]
  );

  const searchRequests = React.useCallback(
    async (page) => {
      try {
        searchingRequests.setLoading(true);
        const json = await searchRequestsPromise(page);
        if (json.status === 200) {
          setRequestList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setRequestList([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingRequests.setLoading(false);
      }
    },
    [Modal, searchRequestsPromise, searchingRequests]
  );

  const searchTotalRequestRecordsAndRequests = React.useCallback(async () => {
    try {
      searchingRequests.setLoading(true);
      const jsonRequests = await searchRequestsPromise();
      if (jsonRequests.status === 200) {
        const jsonRecords = await searchTotalRequestRecordsPromise();
        if (jsonRecords.status === 200) {
          setRequestList(jsonRequests.object);
          pagination.setTotalRecords(jsonRecords.object.total);
        } else if (jsonRecords.status === 500) {
          Modal.error(jsonRecords.message, jsonRecords.object);
        } else {
          setRequestList([]);
        }
      } else if (jsonRequests.status === 500) {
        Modal.error(jsonRequests.message, jsonRequests.object);
      } else {
        setRequestList([]);
        pagination.reset();
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      pagination.setCurrentPage(1);
      searchingRequests.setLoading(false);
    }
  }, [
    Modal,
    pagination,
    searchRequestsPromise,
    searchTotalRequestRecordsPromise,
    searchingRequests,
  ]);

  React.useMemo(() => {
    setRequestList([]);
  }, [sector, page]); // eslint-disable-line

  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequestsList
            scrollPosition={scrollPosition}
            sector={sector}
            page={page}
            requestList={{
              value: requestList,
              setValue: setRequestList,
              isSearching: searchingRequests.isLoading,
            }}
            searchTotalRequestRecordsAndRequests={
              searchTotalRequestRecordsAndRequests
            }
            searchRequests={searchRequests}
            pagination={pagination}
            setSelectedRequest={setSelectedRequest}
            filteredSolicitation={filteredSolicitation}
          />
        }
      />
      <Route
        path="historico"
        element={
          <Historical
            selectedRequest={selectedRequest}
            getStatusList={getStatusList}
            sector={sector}
            page={page}
          />
        }
      />
    </Routes>
  );
}
