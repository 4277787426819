import React, { ChangeEvent } from "react";

import styles from "./UpdateItem.module.css";

import { ICommissionTable } from "../CommissionTables";
import { IItem } from "../CommissionTableItems/CommissionTableItems";
import { Navigate, useNavigate } from "react-router-dom";
import { Button, LinkButton } from "../../../../../components/Form";
// import { CaretLeft, CaretRight, Plus } from "phosphor-react";
import { CaretLeft, CaretRight } from "phosphor-react";
import { useModal } from "../../../../../hooks/contexts";
import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { Circle } from "../../../../../components/Loading";
import Decimal from "decimal.js";

interface IRangeItems {
  idFaixaPrecoproduto: number | string | null;
  idFormulado: number | string | null;
  idEmpresa: number | string | null;
  idTabela: number | string;
  perMinimo: number | string;
  perMaximo: number | string;
  status: "A" | "I";
  comissaoValor: number | string;
  rgb: string;
  dataCriacao: string | null;
  dataManutencao: string | null;
  novaFaixa?: "S" | "N";
  observacao: string;
  ocultar: "S" | "N";
}

interface IUpdateRangesResponse {
  response: number;
  message: string;
  status: number;
  object: any;
}

interface IProps {
  selectedTable: ICommissionTable | null;
  selectedItem: IItem | null;
  researchItems: (tableId: number, description?: string) => any;
  filteredFormula: string;
}

export function UpdateItem({ selectedItem, selectedTable, researchItems, filteredFormula }: IProps) {
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();

  const [rangeItems, setRangeItems] = React.useState<IRangeItems[] | undefined>(
    JSON.parse(JSON.stringify(selectedItem?.itensComissaoTabela))
  );

  const [selectedRange, setSelectedRange] = React.useState<IRangeItems | null>(null);

  const [percentToApply, setPercentToApply] = React.useState<string>("");

  const sendingUpdate = useLoading();

  const onSelectRange = React.useCallback(
    (range: IRangeItems | null = null) => {
      if (range !== null) {
        setSelectedRange(range);
      } else {
        const lastNegativeId = rangeItems?.reduce((prev, cur) => {
          const val = prev > Number(cur.idFaixaPrecoproduto) ? Number(cur.idFaixaPrecoproduto) : prev;
          return val;
        }, 0);

        let idFaixaPrecoproduto = -1;

        if (lastNegativeId !== undefined) {
          idFaixaPrecoproduto = lastNegativeId - 1;
        }

        setSelectedRange({
          idFaixaPrecoproduto: idFaixaPrecoproduto,
          idFormulado: selectedItem ? selectedItem.idFormulado : null,
          idEmpresa: selectedItem ? selectedItem.itensComissaoTabela[0].idEmpresa : 0,
          idTabela: selectedTable ? selectedTable.idTabela : "",
          perMinimo: "0",
          perMaximo: "0",
          status: "A",
          comissaoValor: "0",
          rgb: "#E6E6E6",
          dataCriacao: null,
          dataManutencao: null,
          novaFaixa: "S",
          observacao: "",
          ocultar: "N",
        });
      }
    },
    [rangeItems, selectedTable, selectedItem]
  );

  const onSelectRangeByArrow = React.useCallback(
    (direction: "right" | "left") => {
      const qtdItems = rangeItems!!.length;
      if (rangeItems !== undefined && qtdItems > 0) {
        const currentIndex = rangeItems!!.findIndex(
          (item) => item.idFaixaPrecoproduto === selectedRange?.idFaixaPrecoproduto
        );
        if (direction === "right") {
          if (currentIndex === qtdItems - 1) {
            setSelectedRange(rangeItems[0]);
          } else {
            setSelectedRange(rangeItems[currentIndex + 1]);
          }
        } else {
          if (currentIndex === 0) {
            setSelectedRange(rangeItems[qtdItems - 1]);
          } else if (currentIndex === -1) {
            setSelectedRange(rangeItems[qtdItems - 1]);
          } else {
            setSelectedRange(rangeItems[currentIndex - 1]);
          }
        }
      }
    },
    [rangeItems, selectedRange]
  );

  const onChangeSelectedRangeValues = React.useCallback(
    (
      type: "letter" | "minDiscount" | "maxDiscount" | "commission" | "color" | "hideRange",
      event: ChangeEvent<HTMLInputElement>
    ) => {
      let object = selectedRange;
      if (type === "letter") {
        object = { ...(selectedRange as IRangeItems), observacao: event.target.value.toUpperCase() };
      } else if (type === "minDiscount") {
        object = { ...(selectedRange as IRangeItems), perMinimo: event.target.value };
      } else if (type === "maxDiscount") {
        object = { ...(selectedRange as IRangeItems), perMaximo: event.target.value };
      } else if (type === "commission") {
        object = { ...(selectedRange as IRangeItems), comissaoValor: event.target.value };
      } else if (type === "color") {
        object = { ...(selectedRange as IRangeItems), rgb: event.target.value };
      } else {
        object = { ...(selectedRange as IRangeItems), ocultar: event.target.checked ? "S" : "N" };
      }
      setSelectedRange(object);
    },
    [selectedRange]
  );

  // const onRemoveRange = React.useCallback(() => {
  //   const array = rangeItems?.filter((item) => item.idFaixaPrecoproduto !== selectedRange?.idFaixaPrecoproduto);
  //   const qtdItems = rangeItems?.length;
  //   const currentIndex = rangeItems?.findIndex(
  //     (item) => item.idFaixaPrecoproduto === selectedRange?.idFaixaPrecoproduto
  //   );
  //   if (array !== undefined) {
  //     setRangeItems(array);
  //     if (qtdItems !== undefined && currentIndex !== undefined) {
  //       if (qtdItems === 1) {
  //         setSelectedRange({
  //           idFaixaPrecoproduto: 0,
  //           idFormulado: selectedItem ? selectedItem.idFormulado : null,
  //           idEmpresa: selectedItem ? selectedItem.itensComissaoTabela[0].idEmpresa : 0,
  //           idTabela: selectedTable ? selectedTable.idTabela : "",
  //           perMinimo: "0",
  //           perMaximo: "0",
  //           status: "A",
  //           comissaoValor: "0",
  //           rgb: "#E6E6E6",
  //           dataCriacao: null,
  //           dataManutencao: null,
  //           novaFaixa: "S",
  //         });
  //       } else {
  //         if (currentIndex === qtdItems - 1) {
  //           setSelectedRange(array[currentIndex - 1]);
  //         } else {
  //           setSelectedRange(array[currentIndex]);
  //         }
  //       }
  //     }
  //   }
  // }, [rangeItems, selectedItem, selectedRange, selectedTable]);

  const applyToAllRanges = (type: "increment" | "decrement", percent: number) => {
    const currentIndex = rangeItems?.findIndex(
      (item) => Number(item.idFaixaPrecoproduto) === Number(selectedRange?.idFaixaPrecoproduto)
    );
    const decimalPercent = new Decimal(percent);
    rangeItems?.forEach((range) => {
      const decimalPerMinimo = new Decimal(range.perMinimo);
      const decimalPerMaximo = new Decimal(range.perMaximo);

      if (type === "increment") {
        range.perMinimo = String(decimalPerMinimo.plus(decimalPercent));
        range.perMaximo = String(decimalPerMaximo.plus(decimalPercent));
      } else {
        range.perMinimo = String(decimalPerMinimo.minus(decimalPercent));
        range.perMaximo = String(decimalPerMaximo.minus(decimalPercent));
      }
    });
    setRangeItems(JSON.parse(JSON.stringify(rangeItems)));
    setSelectedRange(JSON.parse(JSON.stringify(rangeItems))[currentIndex || 0]);
  };

  const onConcludeRangeChanges = React.useCallback(() => {
    if (!selectedRange?.perMinimo || !selectedRange?.perMaximo || !selectedRange?.comissaoValor || !selectedRange.rgb) {
      return;
    }
    const array = rangeItems?.filter(
      (item) => String(item.idFaixaPrecoproduto) !== String(selectedRange?.idFaixaPrecoproduto)
    );
    // const hasSameItem = array?.findIndex((item) => String(item.perMaximo) === String(selectedRange?.perMaximo));
    const hasSameItem = -1;

    if (array !== undefined && hasSameItem !== undefined) {
      if (hasSameItem < 0) {
        setRangeItems(
          [
            ...array,
            {
              ...selectedRange,
              novaFaixa: "N",
            },
          ].sort((a, b) => {
            if (selectedItem?.tipoComissao === "Mar") return Number(b.perMaximo) - Number(a.perMaximo);
            else return Number(a.perMaximo) - Number(b.perMaximo);
          }) as IRangeItems[]
        );
        // setSelectedRange({
        //   idFaixaPrecoproduto: 0,
        //   idFormulado: null,
        //   idEmpresa: null,
        //   idTabela: "",
        //   perMinimo: "0",
        //   perMaximo: "0",
        //   status: "A",
        //   comissaoValor: "0",
        //   rgb: "#E6E6E6",
        //   dataCriacao: null,
        //   dataManutencao: null,
        //   novaFaixa: "S",
        // } as IRangeItems);
      } else {
        Modal.error(`Já há um item com desconto máximo de ${selectedRange?.perMaximo}!`);
      }
    }
  }, [rangeItems, selectedRange, Modal, selectedItem]);

  const onUpdateRanges = React.useCallback(
    async (rangeItems: IRangeItems[], defaultRangeLits: IItem["itensComissaoTabela"]) => {
      // Id Empresa Gambiarra
      // const idEmpresa = defaultRangeLits[0].idEmpresa;
      // Id Empresa Gambiarra

      try {
        sendingUpdate.setLoading(true);
        const rangesRemoved = defaultRangeLits
          .filter(
            (item1) =>
              !rangeItems.some((item2) => Number(item1.idFaixaPrecoproduto) === Number(item2.idFaixaPrecoproduto))
          )
          .map((item) => ({
            ...item,
            status: "I",
          }));
        const rangesAlteredAndAdded = rangeItems.map((item, index, array) => {
          if (Number(item.idFaixaPrecoproduto) < 0) {
            return {
              ...item,
              idFaixaPrecoproduto: null,
              idFormulado: Number(selectedItem?.idFormulado),
              idTabela: Number(selectedTable?.idTabela),
              // idEmpresa: idEmpresa,
              // perMinimo: array[index - 1] ? Number(array[index - 1].perMaximo) + 0.00001 : 0,
              perMinimo: Number(item.perMinimo),
              perMaximo: Number(item.perMaximo),
              comissaoValor: Number(item.comissaoValor),
              status: "A",
              observacao: item.observacao,
              ocultar: item.ocultar,
            };
          } else {
            return {
              ...item,
              idFaixaPrecoproduto: Number(item.idFaixaPrecoproduto),
              idFormulado: Number(selectedItem?.idFormulado),
              idTabela: Number(selectedTable?.idTabela),
              // idEmpresa: idEmpresa,
              // perMinimo: array[index - 1] ? Number(array[index - 1].perMaximo) + 0.00001 : 0,
              perMinimo: Number(item.perMinimo),
              perMaximo: Number(item.perMaximo),
              comissaoValor: Number(item.comissaoValor),
              status: "A",
              observacao: item.observacao,
              ocultar: item.ocultar,
            };
          }
        });
        const json = (await customFetch("/commissions/insertAndUpdateRange/false", {
          headers: undefined,
          body: [...rangesRemoved, ...rangesAlteredAndAdded],
        })) as IUpdateRangesResponse;
        if (json.status === 200) {
          researchItems(selectedTable!.idTabela, filteredFormula);
          await Modal.success(json.message);
          navigate("/comissoes/adubo/tabelas-de-comissoes/itens");
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        sendingUpdate.setLoading(false);
      }
    },
    [sendingUpdate, customFetch, selectedItem, selectedTable, researchItems, filteredFormula, Modal, navigate]
  );

  React.useEffect(() => {
    if (selectedItem) {
      setSelectedRange(selectedItem.itensComissaoTabela[0]);
    }
  }, []); // eslint-disable-line

  if (!selectedItem || !selectedTable) {
    return <Navigate to={"/comissoes/adubo/tabelas-de-comissoes/itens"} />;
  }

  return (
    <div className="container">
      {!sendingUpdate.isLoading ? (
        <>
          <div className={styles.navigationContainer}>
            <LinkButton
              to="/comissoes/adubo/tabelas-de-comissoes/itens"
              buttonStyle="backButton"
              children={undefined}
              variant={undefined}
              className={undefined}
              classNameContainer={undefined}
              disabled={undefined}
            />
            <h2>
              {selectedTable?.descricaoTabela} - Fórmula {selectedItem?.descricaoFormulado}
            </h2>
            <div></div>
          </div>
          <span className={styles.separator}></span>
          <div>
            <div className={styles.rangeContainer}>
              {rangeItems !== undefined &&
                rangeItems.map((commission, commissionIndex) => (
                  <div
                    className={`${styles.commissionBlock} ${
                      selectedRange?.idFaixaPrecoproduto === commission.idFaixaPrecoproduto ? styles.isActive : ""
                    }`}
                    key={commission.idFaixaPrecoproduto}
                    onClick={() => {
                      onSelectRange(commission);
                    }}>
                    <p>
                      {selectedItem.tipoComissao}. {commission.perMaximo}%
                    </p>
                    <div className={styles.ball}>
                      <div style={{ backgroundColor: commission.rgb }}></div>
                      <span style={{ backgroundColor: commission.rgb }}></span>
                      <p>{commission.observacao}</p>
                    </div>
                    <p>Com. {commission.comissaoValor}%</p>
                  </div>
                ))}
              {/* <div
                className={styles.newBallContainer}
                onClick={() => {
                  onSelectRange();
                }}>
                <div className={styles.newBall}>
                  <div>
                    <Plus weight="bold" size={"2rem"} color="var(--gray-5)" />
                  </div>
                  <span></span>
                </div>
              </div> */}
            </div>
            <div className={styles.allRangesContainer}>
              <div className={styles.allRangesContainer__applyPercentContainer}>
                <Button
                  className={styles.allRangesContainer__applyPercentContainer__button}
                  onClick={() => {
                    applyToAllRanges("decrement", Number(percentToApply));
                  }}>
                  Subtrair
                </Button>
                <div>
                  <label className="label">Porcentagem</label>
                  <input
                    className={`cleanInputNumber ${styles.customInputNumber}`}
                    type="number"
                    value={percentToApply}
                    onChange={(e) => {
                      setPercentToApply(e.target.value);
                    }}
                    onWheel={(event) => {
                      event.preventDefault();
                      event.currentTarget.blur();
                    }}
                  />
                </div>
                <Button
                  className={styles.allRangesContainer__applyPercentContainer__button}
                  onClick={() => {
                    applyToAllRanges("increment", Number(percentToApply));
                  }}>
                  Somar
                </Button>
              </div>
              <p className={styles.allRangesContainer__obs}>obs: será aplicado em todas as faixas</p>
            </div>
            <div className={styles.editableContainer}>
              <div className={styles.editableContainer__mainContainer}>
                <button
                  className={styles.arrowButton}
                  onClick={() => {
                    onSelectRangeByArrow("left");
                  }}>
                  <CaretLeft size={"3rem"} weight="fill" />
                </button>
                <div className={styles.editableContainer__range}>
                  <div className={styles.fieldsContainer}>
                    <div className={styles.field}>
                      <label className="label">Letra</label>
                      <input
                        type="text"
                        className={`${styles.customInputNumber}`}
                        maxLength={1}
                        value={selectedRange?.observacao || ""}
                        onChange={(event) => {
                          if (event.target.value.length > 1 || !/^[a-zA-Z]*$/.test(event.target.value)) return;
                          onChangeSelectedRangeValues("letter", event);
                        }}
                      />
                    </div>
                    <div className={styles.fieldsContainer__ballContainer}>
                      <div className={styles.field}>
                        <label htmlFor="discount" className="label">
                          Percentual Mínimo (%)
                        </label>
                        <input
                          type="number"
                          className={`cleanInputNumber ${styles.customInputNumber}`}
                          value={selectedRange?.perMinimo}
                          onChange={(event) => {
                            onChangeSelectedRangeValues("minDiscount", event);
                          }}
                          onWheel={(event) => {
                            event.preventDefault();
                            event.currentTarget.blur();
                          }}
                        />
                      </div>
                      <div>
                        <input
                          type="color"
                          className={`${styles.customInputColor}`}
                          value={selectedRange?.rgb}
                          onChange={(event) => {
                            onChangeSelectedRangeValues("color", event);
                          }}
                        />
                      </div>
                      <div className={styles.field}>
                        <label htmlFor="discount" className="label">
                          Percentual Máximo (%)
                        </label>
                        <input
                          type="number"
                          className={`cleanInputNumber ${styles.customInputNumber}`}
                          value={selectedRange?.perMaximo}
                          onChange={(event) => {
                            onChangeSelectedRangeValues("maxDiscount", event);
                          }}
                          onWheel={(event) => {
                            event.preventDefault();
                            event.currentTarget.blur();
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.field}>
                      <label htmlFor="commission" className="label">
                        Valor Comissão (%)
                      </label>
                      <input
                        type="number"
                        className={`cleanInputNumber ${styles.customInputNumber}`}
                        value={selectedRange?.comissaoValor}
                        disabled={true}
                        onWheel={(event) => {
                          event.preventDefault();
                          event.currentTarget.blur();
                        }}
                      />
                    </div>
                    <div className={styles.field}>
                      <label className={`label ${styles.fieldsContainer__hideRangeInput}`}>
                        <input
                          type="checkbox"
                          checked={selectedRange?.ocultar === "S"}
                          onChange={(event) => {
                            onChangeSelectedRangeValues("hideRange", event);
                          }}
                        />
                        <span>Ocultar Faixa</span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.buttonsRangeContainer}>
                    {/* {selectedRange?.novaFaixa !== "S" ? (
                      <div className={styles.buttonRemoveContainer} data-confirm="false">
                        <Button
                          className={styles.buttonRemove}
                          variant="danger"
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();
                            if (e.target instanceof HTMLElement) {
                              const father = e.target.parentElement;
                              if (father) {
                                if (father.dataset.confirm === "true") return;
                                father.dataset.confirm = "true";
                                const closeConfirm = () => {
                                  father.dataset.confirm = "false";
                                  document.removeEventListener("click", closeConfirm);
                                };
                                document.addEventListener("click", closeConfirm);
                              }
                            }
                          }}>
                          Remover
                        </Button>
                        <button
                          className={styles.buttonConfirmRemove}
                          onClick={() => {
                            onRemoveRange();
                          }}>
                          Confirmar
                        </button>
                      </div>
                    ) : null} */}
                    <Button
                      onClick={() => {
                        onConcludeRangeChanges();
                      }}
                      disabled={!selectedRange?.perMaximo || !selectedRange?.comissaoValor || !selectedRange?.rgb}>
                      Salvar
                    </Button>
                  </div>
                </div>
                <button
                  className={styles.arrowButton}
                  onClick={() => {
                    onSelectRangeByArrow("right");
                  }}>
                  <CaretRight size={"3rem"} weight="fill" />
                </button>
              </div>
            </div>
            <div className={styles.finishButtonContainer}>
              <Button
                className={styles.concludeButton}
                onClick={() => {
                  onUpdateRanges(rangeItems!, selectedItem!.itensComissaoTabela);
                }}>
                Concluir Alterações
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Atualizando Faixas</span>
        </div>
      )}
    </div>
  );
}
