import React, { useEffect } from "react";
import { Button, Input, LinkButton, Select } from "../../../../components/Form";
import { usePreviousRoute } from "../../../../hooks/navigation";

import styles from "./InserirAtualizarGrupo.module.css";
import { useInserirAtualizarGrupo } from "../hooks/useInserirAtualizarGrupo";
import { Circle } from "../../../../components/Loading";
import { UseGruposPermissoes } from "../hooks/useGruposPermissoes";

interface Props {
  dadosGruposPermissoes: UseGruposPermissoes;
}

export default function InserirAtualizarGrupo({
  dadosGruposPermissoes,
}: Props) {
  const backRoute = usePreviousRoute();

  const { selectedGrupo } = dadosGruposPermissoes;

  const dados = useInserirAtualizarGrupo({ dadosGruposPermissoes });

  useEffect(() => {
    if (selectedGrupo) {
      dados.nome.setValue(selectedGrupo.nome);
      dados.status.setValue(
        dados.statusOptions.find((x) => x.value === selectedGrupo.status)!!
      );
    }
  }, []); // eslint-disable-line

  return (
    <div className="container">
      {!dados.inserindoAtualizandoGrupo ? (
        <>
          <nav className={styles.navigationContainer}>
            <LinkButton to={backRoute} buttonStyle="backButton" />
            {selectedGrupo !== null ? (
              <h1>
                {selectedGrupo?.id} | {selectedGrupo?.nome}
              </h1>
            ) : (
              <span />
            )}
            <span />
          </nav>
          <span className="separator" />
          <div>
            <div className={styles.formContainer}>
              <div className={styles.formContainer__nome}>
                <label htmlFor="nome" className="label">
                  Nome
                </label>
                <Input
                  id="nome"
                  placeholder="Digite o nome do grupo"
                  className={styles.nomeInput}
                  value={dados.nome.value}
                  onChange={dados.nome.onChange}
                  onBlur={dados.nome.onBlur}
                  error={dados.nome.error}
                />
              </div>
              {dadosGruposPermissoes.selectedGrupo != null && (
                <div className={styles.formContainer__status}>
                  <label htmlFor="status" className="label">
                    Status
                  </label>
                  <Select
                    id="status"
                    placeholder="Selecione o status do grupo"
                    options={dados.statusOptions}
                    value={dados.status.value}
                    onChange={dados.status.onChange}
                    onBlur={dados.status.onBlur}
                    error={dados.status.error}
                  />
                </div>
              )}
            </div>
            <Button
              className={styles.buttonSalvar}
              onClick={() => {
                dados.inserirAtualizarGrupo(selectedGrupo?.id || undefined);
              }}
            >
              Salvar
            </Button>
          </div>
        </>
      ) : (
        <div className="loadingContainer">
          <Circle size={100} />
          <p className="loadingMessage">Salvando Grupo</p>
        </div>
      )}
    </div>
  );
}
