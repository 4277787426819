import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, Input, LinkButton, Select } from "../../../../components/Form";
import { Circle } from "../../../../components/Loading";
import { formatDate } from "../../../../helpers/format";
import { isValid } from "../../../../helpers/validations";
import { useCustomFetch, useDebounce, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { useForm, useSelect } from "../../../../hooks/form";

import styles from "./NewValidityTable.module.css";

export function NewValidityTable({ setValidityList }) {
  const customFetch = useCustomFetch();
  const navigate = useNavigate();
  const Modal = useModal();

  const [basedTableOptions, setBasedTableOptions] = React.useState([]);
  const searchingBasedTableOptions = useLoading();
  const savingValidityTable = useLoading();

  const addFreightOptions = [
    {
      label: "Sim",
      value: true,
    },
    {
      label: "Não",
      value: false,
    },
  ];

  const interestTypeOptions = [
    {
      label: "Médio",
      value: "M",
    },
    {
      label: "Corrido",
      value: "C",
    },
  ];

  const basedTable = useSelect({ type: "single", required: true });
  const tableName = useForm({ type: "", required: true });
  const interest = useForm({ type: "number", required: true });
  const discount = useForm({ type: "number", required: true });
  const addFreight = useSelect({ type: "single", required: true });
  const freightValue = useForm({ type: "number", required: !!addFreight.value?.value });
  const interestType = useSelect({ type: "single", required: true });
  const daysChargeInterest = useForm({ type: "number", required: true });

  const handleChangeBasedTable = (value) => {
    const today = new Date();
    if (value) {
      const name = `Tabela: ${formatDate(today, "Month dd yyyy")} - ${value.value.tabela.descricaoTabela}`;
      tableName.setValue(name);
    } else {
      tableName.reset();
    }
    basedTable.setValue(value);
  };

  const searchBasedTableOptions = React.useCallback(
    async (description = "") => {
      try {
        searchingBasedTableOptions.setLoading(true);
        const json = await customFetch("/products/searchTablesToAddValidity", {
          body: {
            tamanho: 100,
            descricao: description,
          },
        });
        if (json.status === 200) {
          const options = json.object.map((option) => {
            return {
              label: option.tabela.descricaoTabela,
              value: option,
            };
          });
          setBasedTableOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingBasedTableOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingBasedTableOptions]
  );

  const searchBasedTableOptionsDebounced = useDebounce(searchBasedTableOptions);

  const handleSubmit = React.useCallback(async () => {
    if (
      isValid(basedTable, tableName, interest, discount, freightValue, addFreight, interestType, daysChargeInterest)
    ) {
      try {
        savingValidityTable.setLoading(true);
        let json;
        json = await customFetch("/products/registerAndUpdateValidity", {
          body: {
            idTabela: basedTable.value.value.tabela.idTabela,
            descricao: tableName.value,
            jurosTabela: Number(interest.value),
            situacao: true,
            descontoTabela: Number(discount.value),
            freteValor: Number(freightValue.value),
            adicionarFrete: addFreight.value.value,
            tipoDeJuros: interestType.value.value,
            diasCobrarJuros: Number(daysChargeInterest.value),
          },
        });
        if (json.status === 200) {
          await Modal.success(json.message);
          setValidityList([]);
          navigate("/produtos/tabelas-de-vigencia");
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        savingValidityTable.setLoading(false);
      }
    }
  }, [
    Modal,
    addFreight,
    basedTable,
    customFetch,
    daysChargeInterest,
    discount,
    freightValue,
    interest,
    interestType,
    tableName,
    navigate,
    setValidityList,
    savingValidityTable,
  ]);

  React.useEffect(() => {
    searchBasedTableOptions("");
    addFreight.setValue(addFreightOptions[0]);
  }, []); // eslint-disable-line

  return (
    <div className={`container ${styles.container}`}>
      <div>
        <LinkButton buttonStyle="backButton" to="/produtos/tabelas-de-vigencia" />
      </div>
      <form
        className={styles.formContainer}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}>
        {!savingValidityTable.isLoading ? (
          <>
            <div className={styles.gridContainer}>
              <div data-grid="basedTable">
                <label htmlFor="basedTable" className="label">
                  Tabela à Basear
                </label>
                <Select
                  id="basedTable"
                  placeholder="Selecione uma tabela para basear"
                  options={basedTableOptions}
                  value={basedTable.value}
                  error={basedTable.error}
                  onChange={handleChangeBasedTable}
                  onBlur={basedTable.onBlur}
                  onInputChange={searchBasedTableOptionsDebounced}
                  isLoading={searchingBasedTableOptions.isLoading}
                />
              </div>
              <div data-grid="tableName">
                <label htmlFor="tableName" className="label">
                  Nome
                </label>
                <Input
                  id="tableName"
                  placeholder="Digite o nome da tabela de vigência"
                  value={tableName.value}
                  error={tableName.error}
                  onChange={tableName.onChange}
                  onBlur={tableName.onBlur}
                />
              </div>
              <div data-grid="interest">
                <label htmlFor="interest" className="label">
                  Juros do mês (%)
                </label>
                <Input
                  id="interest"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite o juros do mês"
                  value={interest.value}
                  error={interest.error}
                  onChange={interest.onChange}
                  onBlur={interest.onBlur}
                />
              </div>
              <div data-grid="discount">
                <label htmlFor="discount" className="label">
                  Desconto (%)
                </label>
                <Input
                  id="discount"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite o desconto"
                  value={discount.value}
                  error={discount.error}
                  onChange={discount.onChange}
                  onBlur={discount.onBlur}
                />
              </div>
              <div data-grid="addFreight">
                <label htmlFor="addFreight" className="label">
                  Adicionar Frete
                </label>
                <Select
                  id="addFreight"
                  options={addFreightOptions}
                  value={addFreight.value}
                  error={addFreight.error}
                  onChange={(value) => {
                    if (!value.value) {
                      freightValue.reset();
                    }
                    addFreight.onChange(value);
                  }}
                  onBlur={addFreight.onBlur}
                  isClearable={false}
                  isSearchable={false}
                />
              </div>
              <div data-grid="freightValue">
                <label htmlFor="freightValue" className="label">
                  Valor do Frete (R$)
                </label>
                <Input
                  id="freightValue"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite o valor do frete"
                  value={freightValue.value}
                  error={freightValue.error}
                  onChange={freightValue.onChange}
                  onBlur={freightValue.onBlur}
                  disabled={!addFreight.value?.value}
                />
              </div>
              <div data-grid="interestType">
                <label htmlFor="interestType" className="label">
                  Tipo de Juros
                </label>
                <Select
                  id="interestType"
                  placeholder="Selecione um tipo de juros"
                  options={interestTypeOptions}
                  value={interestType.value}
                  error={interestType.error}
                  onChange={interestType.onChange}
                  onBlur={interestType.onBlur}
                  isSearchable={false}
                />
              </div>
              <div data-grid="daysChargeInterest">
                <label htmlFor="daysChargeInterest" className="label">
                  Dias até Cobrança de Juros
                </label>
                <Input
                  id="daysChargeInterest"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite os dias até a cobrança de juros"
                  value={daysChargeInterest.value}
                  error={daysChargeInterest.error}
                  onChange={daysChargeInterest.onChange}
                  onBlur={daysChargeInterest.onBlur}
                />
              </div>
            </div>
            <div className={styles.submitButtonContainer}>
              <Button>Concluir</Button>
            </div>
          </>
        ) : (
          <div className={`loadingContainer`}>
            <Circle size={100} />
            <span className="loadingMessage">Salvando Tabela de Vigências</span>
          </div>
        )}
      </form>
    </div>
  );
}
