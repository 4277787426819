import React from "react";

import { IContractPremiations } from "../../types";
import { useModal } from "../../../../hooks/contexts";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { ICalcType, IPremiation, IPremiationType } from "./types";
import { isValid } from "../../../../helpers/validations";
import { IUseChanges } from "../../../../hooks/contexts/useChanges";

export function useContractPremiations(): IContractPremiations {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const [premiationTypeOptions, setPremiationTypeOptions] = React.useState<ISelectOption<IPremiationType>[]>([]);
  const calcTypeOptions = [
    { label: "PERCENTUAL", value: "P" },
    { label: "VALOR FIXO", value: "V" },
  ] as ISelectOption<ICalcType>[];
  const [premiations, setPremiations] = React.useState<IPremiation[]>([]);

  const gettingPremiationTypes = useLoading();

  const getPremiationTypesPromise = React.useCallback(() => {
    return new Promise<DefaultFetchResponse<IPremiationType[]>>(async (resolve, reject) => {
      try {
        const response = (await customFetch("/contracts/getPremiationTypes", {
          method: "GET",
        })) as DefaultFetchResponse<IPremiationType[]>;
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }, [customFetch]);

  const getPremiationTypes = React.useCallback(async () => {
    try {
      gettingPremiationTypes.setLoading(true);
      const json = await getPremiationTypesPromise();
      if (json.status === 200) {
        const options = json.object.map((item) => ({
          label: item.tipo,
          value: item,
        }));
        setPremiationTypeOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setPremiationTypeOptions([]);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      gettingPremiationTypes.setLoading(false);
    }
  }, [gettingPremiationTypes, getPremiationTypesPromise, Modal]);

  const insertPremiations = React.useCallback((premiations: IPremiation[]) => {
    setPremiations(premiations);
  }, []);

  const insertPremiation = React.useCallback(
    (
      premiationType: IUseSelect<ISelectOption<IPremiationType>>,
      calcType: IUseSelect<ISelectOption<ICalcType>>,
      value: IUseForm,
      changesDetected?: IUseChanges
    ) => {
      if (isValid(premiationType, calcType, value)) {
        changesDetected?.set();
        const premiation = {
          premiationType: premiationType.value?.value as unknown as IPremiationType,
          calcType: calcType.value?.value as unknown as ICalcType,
          value: value.value,
        };
        setPremiations((premiations) => [...premiations, premiation]);
        premiationType.reset();
        calcType.reset();
        value.reset();
      }
    },
    []
  );

  const removePremiation = React.useCallback((premiationType: string, changesDetected?: IUseChanges) => {
    changesDetected?.set();
    setPremiations((premiations) =>
      premiations.filter((premiation) => premiation.premiationType.tipo !== premiationType)
    );
  }, []);

  const validate = React.useCallback(() => {
    const isValid = premiations.length > 0;
    if (!isValid) Modal.error("Ao menos uma premiação deve ter seu valor definido, sendo este maior que zero.");
    return isValid;
  }, [Modal, premiations]);

  const reset = React.useCallback(() => {
    setPremiations([]);
  }, []);

  return {
    premiations: {
      typeOptions: premiationTypeOptions.filter(
        (item) => !premiations.some((premiation) => premiation.premiationType.id === item.value.id)
      ),
      calcTypeOptions,
      value: premiations,
      insertPremiations,
      insertPremiation,
      removePremiation,
    },
    getPremiationTypes: {
      handlePromise: getPremiationTypesPromise,
      handle: getPremiationTypes,
      loading: gettingPremiationTypes.isLoading,
    },
    validate,
    reset,
  };
}
