import { useCallback, useState } from "react";
import { useModal } from "../../../../../hooks/contexts";
import {
  useCustomFetch,
  useDebounce,
  useLoading,
} from "../../../../../hooks/async";
import { IValidityTable, IValidityTableItem } from "./types";
import { useForm, useSelect } from "../../../../../hooks/form";

export default function useCommissionPercent() {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const validityTable = useSelect<IValidityTable>({
    type: "single",
    required: true,
  });
  const [selectedItem, setSelectedItem] = useState<IValidityTableItem | null>(
    null
  );
  const itemFilter = useForm({ required: false });

  const [validityTableOptions, setValidityTableOptions] = useState<
    ISelectOption<IValidityTable>[]
  >([]);
  const [validityTableItems, setValidityTableItems] = useState<
    IValidityTableItem[]
  >([]);

  const searchingValidityTableOptions = useLoading();
  const searchingValidityTableItems = useLoading();

  const searchValidityTableOptions = useCallback(
    async (description = "") => {
      try {
        searchingValidityTableOptions.setLoading(true);
        const json = (await customFetch("/products/searchValidityList", {
          body: {
            descricao: description,
            tamanho: 100,
            pagina: 0,
          },
        })) as DefaultFetchResponse<IValidityTable[]>;
        if (json.status === 200) {
          const options = json.object.map((item) => ({
            label: item.tabela.nome,
            value: item,
          }));
          setValidityTableOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setValidityTableOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingValidityTableOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingValidityTableOptions]
  );

  const searchValidityTableOptionsDebounced = useDebounce(
    searchValidityTableOptions
  );

  const searchValidityTableItems = useCallback(
    async (validityTableId: number) => {
      if (validityTableId === 0) {
        setValidityTableItems([]);
        return;
      }

      try {
        searchingValidityTableItems.setLoading(true);
        const json = await customFetch("/products/searchValidityItems", {
          body: {
            idTabelaVigencia: validityTableId,
            descricao: "",
          },
        });
        if (json.status === 200) {
          setValidityTableItems(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingValidityTableItems.setLoading(false);
      }
    },
    [Modal, customFetch, searchingValidityTableItems]
  );

  return {
    filters: {
      itemFilter,
    },
    sets: {
      setSelectedItem,
    },
    data: {
      validityTable,
      validityTableOptions,
      validityTableItems,
      selectedItem,
    },
    methods: {
      searchValidityTableOptionsDebounced,
      searchValidityTableItems,
    },
    loadings: {
      searchingValidityTableOptions: searchingValidityTableOptions.isLoading,
      searchingValidityTableItems: searchingValidityTableItems.isLoading,
    },
  };
}
