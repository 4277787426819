import React from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";

import styles from "./ApproveAndDisapproveExpense.module.css";
import { Button, Input, LinkButton, Textarea } from "../../../../../../components/Form";
import { useNavigate } from "react-router-dom";
import { formatDate, formatMoney } from "../../../../../../helpers/format";
import { Circle } from "../../../../../../components/Loading";
import { useForm } from "../../../../../../hooks/form";
import { isValid } from "../../../../../../helpers/validations";
import { useModal } from "../../../../../../hooks/contexts";
import { useCustomFetch, useLoading } from "../../../../../../hooks/async";
import { usePreviousRoute } from "../../../../../../hooks/navigation";

export function ApproveAndDisapproveExpense({ selectedExpense, setTable }) {
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();

  const backUrl = usePreviousRoute();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyASWecwQ20vj9Mbu_oO0nwK2NLoFSNn0B0",
  });

  const [centerLocation, setCenterLocation] = React.useState({ lat: 0, lng: 0 });

  const [loadingImage, setLoadingImage] = React.useState(true);

  const modalReasonRef = React.useRef(null);
  const value = useForm({ type: "number", required: true });
  const reason = useForm({ type: "", required: false });
  const [approve, setApprove] = React.useState(false);

  const processingRequest = useLoading();

  const onLoadImage = React.useCallback(() => {
    setLoadingImage(false);
  }, []);

  const openImage = React.useCallback(() => {
    if (selectedExpense.linkImagem) {
      window.open(selectedExpense.linkImagem, "_blank");
    }
  }, [selectedExpense]);

  const handleClickApproveOrReproveRequest = React.useCallback(
    async (approve, value = null, reason = "", toReverse = false) => {
      try {
        processingRequest.setLoading(true);
        const json = await customFetch("/rdv/approveAndDisapproveExpense", {
          body: {
            codigoEmpresa: selectedExpense.codigoEmpresa,
            idDespesa: selectedExpense.idDespesa,
            idStatus: toReverse ? 0 : approve ? 3 : 1,
            observacao: reason,
            valorPago: value,
          },
        });
        if (json.status === 200) {
          setTable((old) => {
            return {
              columns: old.columns,
              data: [],
            };
          });
          await Modal.success(json.message);
          navigate(backUrl);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        processingRequest.setLoading(false);
      }
    },
    [Modal, customFetch, navigate, processingRequest, selectedExpense, setTable, backUrl]
  );

  React.useEffect(() => {
    if (selectedExpense) {
      setCenterLocation({
        lat: Number(selectedExpense.latitude),
        lng: Number(selectedExpense.longitude),
      });
    }
  }, []); // eslint-disable-line

  return (
    <>
      <div className="container">
        {!processingRequest.isLoading ? (
          <>
            <div className={styles.navigationContainer}>
              <LinkButton to={backUrl} buttonStyle="backButton" />
              <h2>Despesa nº: {selectedExpense.idDespesa}</h2>
              <div></div>
            </div>
            <span className={styles.separator}></span>
            <div className={styles.dataContainer}>
              <fieldset className="fieldset">
                <legend className="fieldsetLegend">Dados</legend>
                <ul className={styles.dataContainer__expense}>
                  <li>
                    <strong>Data de Emissão:</strong> {formatDate(selectedExpense.dataEmissao, "dd/MM/yyyy")}
                  </li>
                  <li>
                    <strong>Vendedor:</strong> {selectedExpense.vendedor}
                  </li>
                  <li>
                    <strong>Categoria:</strong> {selectedExpense.categoria}
                  </li>
                  <li>
                    <strong>Forma de Pagamento:</strong> {selectedExpense.formaPagto}
                  </li>
                  <li>
                    <strong>Nº do Documento:</strong> {selectedExpense.documento ? selectedExpense.documento : "---"}
                  </li>
                  <li>
                    <strong>Fornecedor:</strong> {selectedExpense.fornecedor}
                  </li>
                  <li>
                    <strong>OBS:</strong> {selectedExpense.observacao ? selectedExpense.observacao : "---"}
                  </li>
                </ul>
              </fieldset>
              <fieldset className="fieldset">
                <legend className="fieldsetLegend">Comprovante</legend>
                <div className={styles.dataContainer__voucherContainer}>
                  <div className={styles.dataContainer__voucher}>
                    <img
                      src={selectedExpense.linkImagem}
                      alt="foto-comprovante"
                      style={{ visibility: loadingImage ? "hidden" : "visible" }}
                      onClick={openImage}
                      onLoad={onLoadImage}
                      onError={onLoadImage}
                    />
                    {loadingImage && (
                      <div className={styles.imageLoader}>
                        <Circle size={80} color="var(--gray-8)" />
                      </div>
                    )}
                  </div>
                  <div className={styles.dataContainer__voucherValue}>
                    <strong>Valor da Despesa</strong> {formatMoney(selectedExpense.valorDespesa)}
                  </div>
                </div>
              </fieldset>
              <fieldset className={`fieldset ${styles.mapsFieldset}`}>
                <legend className="fieldsetLegend">Localização</legend>
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={{
                      width: "100%",
                      height: "25rem",
                    }}
                    center={centerLocation}
                    zoom={15}>
                    <MarkerF position={centerLocation} />
                  </GoogleMap>
                ) : (
                  <></>
                )}
              </fieldset>
            </div>
            <div className={styles.buttonContainer}>
              <Button
                variant="edit"
                className={styles.historyButton}
                onClick={() => {
                  navigate("historico");
                }}>
                Ver Histórico
              </Button>
              {selectedExpense.idStatus === 0 ? (
                <>
                  <Button
                    variant="danger"
                    className={styles.disapproveButton}
                    onClick={() => {
                      setApprove(false);
                      modalReasonRef.current.show();
                    }}>
                    Reprovar
                  </Button>
                  <Button
                    className={styles.approveButton}
                    onClick={() => {
                      setApprove(true);
                      value.setValue(selectedExpense.valorDespesa);
                      modalReasonRef.current.show();
                    }}>
                    Aprovar
                  </Button>
                </>
              ) : (
                <Button
                  variant="danger"
                  className={styles.cancelButton}
                  onClick={() => {
                    setApprove(false);
                    modalReasonRef.current.show();
                  }}>
                  Estornar
                </Button>
              )}
            </div>
          </>
        ) : (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
            <span className="loadingMessage">Processando requisição</span>
          </div>
        )}
      </div>
      <dialog
        className={`modal ${styles.modalReason}`}
        ref={modalReasonRef}
        onClose={() => {
          reason.reset();
          value.reset();
        }}>
        <div className="modalContent">
          <div className={styles.modalReasonFields}>
            {approve ? (
              <div>
                <label htmlFor="value" className="label">
                  Valor a Pagar (R$)
                </label>
                <Input
                  id="value"
                  type="number"
                  placeholder="R$ 00,00"
                  className="cleanInputNumber"
                  value={value.value}
                  error={value.error}
                  onChange={value.onChange}
                />
              </div>
            ) : null}
            <div>
              <label htmlFor="reason" className="label">
                Observação
              </label>
              <Textarea
                id="reason"
                className={styles.textareaReason}
                value={reason.value}
                error={reason.error}
                onChange={reason.onChange}></Textarea>
            </div>
          </div>
          <span className={styles.modalReason__separator} />
          <div className={styles.modalReason__buttonContainer}>
            <Button
              type="button"
              variant="danger"
              onClick={() => {
                setApprove(false);
                modalReasonRef.current.close();
              }}>
              Cancelar
            </Button>
            <Button
              type="button"
              onClick={() => {
                if (approve) {
                  if (isValid(reason, value)) {
                    modalReasonRef.current.close();
                    handleClickApproveOrReproveRequest(
                      approve,
                      Number(value.value),
                      reason.value,
                      selectedExpense.idStatus !== 0 ? true : false
                    );
                  }
                } else if (isValid(reason)) {
                  modalReasonRef.current.close();
                  handleClickApproveOrReproveRequest(
                    approve,
                    null,
                    reason.value,
                    selectedExpense.idStatus !== 0 ? true : false
                  );
                }
              }}>
              Concluir
            </Button>
          </div>
        </div>
      </dialog>
    </>
  );
}
