export function Th({ children, className = "", width, style = {}, ...props }) {
  return (
    <th
      className={`${className}`}
      style={{ width: width, ...style }}
      {...props}
    >
      {children}
    </th>
  );
}
