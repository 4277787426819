import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../hooks/contexts";
import { Home } from "../pages/Home/Home";

export function Private() {
  const Auth = useAuth();

  if (Auth.signed)
    return (
      <Home>
        <Outlet />
      </Home>
    );
  else return <Navigate to="/login" />;
}
