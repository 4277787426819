import { CaretRight } from "phosphor-react";
import React from "react";

import styles from "./Collapse.module.css";

export function Collapse({
  label,
  className,
  classNameMainContainer,
  classNameButton,
  children,
  iconColor,
  key,
  startOpened = false,
}) {
  const [isOpen, setIsOpen] = React.useState(startOpened);

  return (
    <div className={`${classNameMainContainer || ""} ${isOpen ? "isOpen" : ""}`} key={key}>
      <span
        className={`${styles.button} ${classNameButton || ""} ${isOpen ? "isOpen" : ""}`}
        onClick={() => setIsOpen(!isOpen)}>
        <button type="button" className={`${className || ""} ${isOpen ? "isOpen" : ""}`}>
          {label}
        </button>
        <CaretRight
          className={`${styles.icon} ${isOpen ? "open" : ""}`}
          size={20}
          color={iconColor || "#ffffff"}
          weight="fill"
        />
      </span>
      <span className={`${styles.content} ${isOpen ? "open" : ""}`}>{children}</span>
    </div>
  );
}
