import React from "react";
import { Modal } from "../../components/Commons/Modal/Modal";
import { useAuth, useChanges } from "../../hooks/contexts";

import styles from "./autoLogout.module.css";

class CounterObject {
  constructor({ auth, changes, openAlert, countdown, lifetime }) {
    this._lifetime = lifetime * 60;
    this._auth = auth;
    this._changes = changes;
    this._modal = {
      show() {
        openAlert(true);
      },
      hide() {
        openAlert(false);
      },
    };
    this._counter = null;
    this._timer = this._lifetime;

    this._setCountdown = countdown;
    this.startCounter = this.startCounter.bind(this);
    this.resetCounter = this.resetCounter.bind(this);
    this.stopCounter = this.stopCounter.bind(this);
  }

  get events() {
    return ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];
  }

  startCounter() {
    clearInterval(this._counter);
    this._counter = setInterval(() => {
      this._timer -= 1;
      if (this._timer === 0) {
        clearInterval(this._counter);
        this._changes.reset();
        this._auth.signOut();
      } else if (this._timer <= 60) {
        this._setCountdown(this._timer);
        this._modal.show();
      }
    }, 1000);
    this.events.forEach((event) => {
      window.addEventListener(event, this.resetCounter);
    });
  }

  resetCounter() {
    this._modal.hide();
    clearInterval(this._counter);
    this._timer = this._lifetime;
    this.startCounter();
  }

  stopCounter() {
    clearInterval(this._counter);
    this._timer = this._lifetime;
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetCounter);
    });
    this._modal.hide();
  }
}

export const AutoLogout = React.createContext();

export function AutoLogoutProvider({ children }) {
  const Auth = useAuth();
  const Changes = useChanges();

  const [isAlertOpened, setIsAlertOpened] = React.useState(false);
  const [countdown, setCountdown] = React.useState(null);

  const { current: counter } = React.useRef(
    new CounterObject({
      auth: Auth,
      changes: Changes,
      openAlert: setIsAlertOpened,
      countdown: setCountdown,
      lifetime: 20,
    })
  );

  React.useEffect(() => {
    if (Auth.signed) {
      counter.startCounter();
    } else {
      counter.stopCounter();
    }
  }, [Auth, counter]);

  return (
    <AutoLogout.Provider value="">
      {children}
      <Modal className={styles.modalAlert} open={isAlertOpened}>
        <h1 className={styles.alertTitle}>Atenção</h1>
        <p className={styles.alertMessage}>
          Você será deslogado em cerca de {countdown} segundos devido a inatividade.
          <br /> Mova o mouse ou precione qualquer tecla para se manter conectado.
        </p>
      </Modal>
    </AutoLogout.Provider>
  );
}
