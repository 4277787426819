import React from "react";

import { Button, Input, LinkButton, Select } from "../../../../components/Form";

import { useForm, useSelect } from "../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useChanges, useModal } from "../../../../hooks/contexts";

import { isValid } from "../../../../helpers/validations";

import { Circle } from "../../../../components/Loading";

import styles from "./NewFormula.module.css";

export function NewFormula({ searchTotalFormulaRecordsAndFormulas, groupOptions }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const { setIsChangesDetected } = useChanges();
  const registeringFormula = useLoading();

  const formula = useForm({ type: "", required: true });
  const belongedGroup = useSelect({ type: "single", required: true, emptyMessage: "Selecione o grupo pertencente" });

  const clearForm = React.useCallback(() => {
    formula.setValue("");
    formula.setError(null);
    belongedGroup.setValue("");
    belongedGroup.setError(null);
    setIsChangesDetected(false);
  }, [belongedGroup, formula, setIsChangesDetected]);

  const registerFormula = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        registeringFormula.setLoading(true);
        const json = await customFetch("/products/registerAndUpdateFormula", {
          body: {
            descricao: formula.value,
            idGrupo: belongedGroup.value.value.idGrupo,
            ativo: true,
          },
        });
        resolve(json);
      } catch (error) {
        reject(error);
      } finally {
        registeringFormula.setLoading(false);
      }
    });
  }, [belongedGroup, customFetch, formula, registeringFormula]);

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (isValid(formula, belongedGroup)) {
        try {
          const json = await registerFormula();
          if (json.status === 200) {
            await Modal.success(json.message);
            clearForm();
            searchTotalFormulaRecordsAndFormulas();
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        }
      }
    },
    [Modal, belongedGroup, clearForm, formula, registerFormula, searchTotalFormulaRecordsAndFormulas]
  );

  return (
    <div className={`container ${styles.container}`}>
      {!registeringFormula.isLoading ? (
        <>
          <div>
            <LinkButton to="/produtos/formulas" buttonStyle="backButton" />
          </div>
          <div>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className={styles.fieldsContainer}>
                <div>
                  <label htmlFor="formula" className="label">
                    Descrição
                  </label>
                  <Input
                    id="formula"
                    placeholder="Digite a fórmula"
                    value={formula.value}
                    error={formula.error}
                    onChange={(e) => {
                      formula.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={formula.onBlur}
                  />
                </div>
                <div>
                  <label htmlFor="group" className="label">
                    Grupo
                  </label>
                  <Select
                    id="group"
                    placeholder="Selecione o grupo"
                    options={groupOptions}
                    value={belongedGroup.value}
                    error={belongedGroup.error}
                    onChange={(value) => {
                      belongedGroup.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={belongedGroup.onBlur}
                  />
                </div>
              </div>
              <div className={styles.submitButtonContainer}>
                <Button>Cadastrar Fórmula</Button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Cadastrando nova fórmula</span>
        </div>
      )}
    </div>
  );
}
