import React from "react";
import { Route, Routes } from "react-router-dom";
import { Lista } from "./Lista/Lista";
import { AdicionarAtualizar } from "./AdicionarAtualizar/AdicionarAtualizar";
import { useContratado } from "./hooks/useContratado";

export function ContratosFCContratado() {
  const contratadosData = useContratado();

  return (
    <Routes>
      <Route path="/" element={<Lista contratadosData={contratadosData} />} />
      <Route path="/novo" element={<AdicionarAtualizar tipo="insert" contratadosData={contratadosData} />} />
      <Route
        path="/atualizar"
        element={
          <AdicionarAtualizar
            tipo="update"
            contratadosData={contratadosData}
            selectedContratado={contratadosData.selectedContratado.value}
          />
        }
      />
    </Routes>
  );
}
