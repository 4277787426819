import React from "react";

import { Button } from "../../../../../../components/Form";

import styles from "./StepsController.module.css";

interface Props {
  step: [number, React.Dispatch<React.SetStateAction<number>>];
  pages: {
    name: string;
    page: React.ReactNode;
    validate?: () => boolean;
  }[];
  onFinish: (setStep: React.Dispatch<React.SetStateAction<number>>) => void;
}

export function StepsController({ pages, onFinish, step: [step, setStep] }: Props) {
  pages = pages.filter((page) => page);

  const totalPages = pages.length;

  const changeStep = (newStep: number) => {
    if (newStep < 0 || newStep > totalPages - 1) return;
    setStep(newStep);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className={styles.stepsContainer}>
        {pages.map((page, index) => (
          <button
            type="button"
            key={page.name}
            className={`${styles.stepsContainer__step} ${index <= step ? styles.active : null}`}
            onClick={() => {
              for (let i = 0; i < index; i++) {
                if (pages[i].validate && !pages[i].validate!()) {
                  changeStep(i);
                  return;
                }
              }
              changeStep(index);
            }}>
            <p className={styles.stepsContainer__step__page}>{index + 1}</p>
            <p className={styles.stepsContainer__step__name}>{page.name}</p>
          </button>
        ))}
      </div>
      <div className={styles.stepsContainer__page}>{pages[step].page}</div>
      <div className={styles.stepsButtonNavigation}>
        {step > 0 && (
          <Button variant="danger" onClick={() => changeStep(step - 1)}>
            Voltar
          </Button>
        )}
        {step < totalPages - 1 ? (
          <Button
            className={styles.stepsButtonNavigation__next}
            onClick={() => {
              if (pages[step].validate && !pages[step].validate!()) return;
              changeStep(step + 1);
            }}>
            Próximo
          </Button>
        ) : (
          <Button
            className={styles.stepsButtonNavigation__next}
            onClick={() => {
              onFinish(setStep);
            }}>
            Concluir Solicitação
          </Button>
        )}
      </div>
    </>
  );
}
