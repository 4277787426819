import { useState, useMemo, useCallback, useEffect } from "react";
import { IAction, IEnterprise, IProduct, IUseSelectOptions } from "./types";
import { useModal } from "../../../../hooks/contexts";
import { useCustomFetch, useDebounce, useLoading } from "../../../../hooks/async";

export function useSelectOptions(): IUseSelectOptions {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const [productOptions, setProductOptions] = useState<ISelectOption<IProduct>[]>([]);
  const [enterpriseOptions, setEnterpriseOptions] = useState<ISelectOption<IEnterprise>[]>([]);
  const actionOptions = useMemo<ISelectOption<IAction>[]>(
    () => [
      { label: "Entrada", value: "E" },
      { label: "Saída", value: "S" },
    ],
    []
  );

  const searchingProductOptions = useLoading();
  const searchingEnterpriseOptions = useLoading();

  const searchProductOptions = useCallback(
    async (description: string = "") => {
      try {
        searchingProductOptions.setLoading(true);
        const json = (await customFetch("/factory/searchProducts", {
          body: {
            pagina: 0,
            tamanho: 100,
            idProduto: "",
            nomeProduto: "",
            classificacao: "M",
            generico: description,
          },
        })) as DefaultFetchResponse<IProduct[]>;
        if (json.status === 200) {
          const options = json.object.map((product) => ({
            label: `${product.idProduto} | ${product.nomeProduto}`,
            value: product,
          }));
          setProductOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setProductOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingProductOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingProductOptions]
  );

  const searchEnterpriseOptions = useCallback(async () => {
    try {
      searchingEnterpriseOptions.setLoading(true);
      const json = (await customFetch("/products/searchEnterprises", {})) as DefaultFetchResponse<IEnterprise[]>;
      if (json.status === 200) {
        const options = json.object.map((enterprise) => ({
          label: `${enterprise.idEmpresa} | ${enterprise.nomeEmpresa}`,
          value: enterprise,
        }));
        setEnterpriseOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setEnterpriseOptions([]);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingEnterpriseOptions.setLoading(false);
    }
  }, [Modal, customFetch, searchingEnterpriseOptions]);

  const searchProductOptionsDebounced = useDebounce(searchProductOptions);

  useEffect(() => {
    searchProductOptions();
    searchEnterpriseOptions();
  }, []); // eslint-disable-line

  return {
    productOptions: {
      options: productOptions,
      searching: searchingProductOptions.isLoading,
      search: searchProductOptions,
      searchDebounced: searchProductOptionsDebounced,
    },
    enterpriseOptions: {
      options: enterpriseOptions,
      searching: searchingEnterpriseOptions.isLoading,
      search: searchEnterpriseOptions,
    },
    actionOptions: {
      options: actionOptions,
    },
  };
}
