import React from "react";
import { useNavigate, Navigate } from "react-router-dom";

import { Button, Input, LinkButton, Select } from "../../../../components/Form";
import { Circle } from "../../../../components/Loading";
import { isValid } from "../../../../helpers/validations";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { useForm, useSelect } from "../../../../hooks/form";

import styles from "./UpdateValidityTable.module.css";

export function UpdateValidityTable({ selectedTable, setValidityList }) {
  const customFetch = useCustomFetch();
  const navigate = useNavigate();
  const Modal = useModal();

  const savingValidityTable = useLoading();

  const addFreightOptions = [
    {
      label: "Sim",
      value: true,
    },
    {
      label: "Não",
      value: false,
    },
  ];

  const interestTypeOptions = [
    {
      label: "Médio",
      value: "M",
    },
    {
      label: "Corrido",
      value: "C",
    },
  ];

  const situationOptions = [
    {
      label: "Ativo",
      value: true,
    },
    {
      label: "Inativo",
      value: false,
    },
  ];

  const tableName = useForm({ type: "", required: true });
  const interest = useForm({ type: "number", required: true });
  const discount = useForm({ type: "number", required: true });
  const addFreight = useSelect({ type: "single", required: true });
  const freightValue = useForm({ type: "number", required: !!addFreight.value?.value });
  const interestType = useSelect({ type: "single", required: true });
  const daysChargeInterest = useForm({ type: "number", required: true });
  const situation = useSelect({ type: "single", required: true });

  const handleSubmit = React.useCallback(async () => {
    if (isValid(tableName, interest, discount, freightValue, addFreight, interestType, daysChargeInterest)) {
      try {
        savingValidityTable.setLoading(true);
        let json;
        json = await customFetch("/products/registerAndUpdateValidity", {
          body: {
            idTabelaVigencia: selectedTable.value.tabela.id,
            idTabela: selectedTable.value.tabela.tabela,
            descricao: tableName.value,
            jurosTabela: Number(interest.value),
            situacao: situation.value.value,
            descontoTabela: Number(discount.value),
            freteValor: Number(freightValue.value),
            adicionarFrete: addFreight.value.value,
            tipoDeJuros: interestType.value.value,
            diasCobrarJuros: Number(daysChargeInterest.value),
          },
        });
        if (json.status === 200) {
          await Modal.success(json.message);
          setValidityList([]);
          navigate("/produtos/tabelas-de-vigencia");
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        savingValidityTable.setLoading(false);
      }
    }
  }, [
    tableName,
    interest,
    discount,
    freightValue,
    addFreight,
    interestType,
    daysChargeInterest,
    savingValidityTable,
    customFetch,
    selectedTable,
    situation,
    Modal,
    setValidityList,
    navigate,
  ]);

  React.useEffect(() => {
    if (selectedTable.value) {
      tableName.setValue(selectedTable.value.tabela.nome);
      interest.setValue(selectedTable.value.tabela.jurosMeses);
      discount.setValue(selectedTable.value.tabela.desconto || 0);
      freightValue.setValue(selectedTable.value.tabela.valorFrete || 0);
      interestType.setValue(
        interestTypeOptions.find((option) => option.value === selectedTable.value.tabela.tipoJuros)
      );
      addFreight.setValue(
        selectedTable.value.tabela.adicionarFrete === "S" ? addFreightOptions[0] : addFreightOptions[1]
      );
      daysChargeInterest.setValue(
        selectedTable.value.tabela.diasCobrarJuros !== null ? selectedTable.value.tabela.diasCobrarJuros : ""
      );
      situation.setValue(selectedTable.value.tabela.ativo === "A" ? situationOptions[0] : situationOptions[1]);
    }
  }, []); // eslint-disable-line

  if (!selectedTable.value) {
    return <Navigate to="/produtos/tabelas-de-vigencia" />;
  }

  return (
    <div className={`container ${styles.container}`}>
      <div>
        <LinkButton buttonStyle="backButton" to="/produtos/tabelas-de-vigencia" />
      </div>
      <form
        className={styles.formContainer}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}>
        {!savingValidityTable.isLoading ? (
          <>
            <div className={styles.gridContainer}>
              <div data-grid="tableName">
                <label htmlFor="tableName" className="label">
                  Nome
                </label>
                <Input id="tableName" value={tableName.value} readOnly disabled />
              </div>
              <div data-grid="interest">
                <label htmlFor="interest" className="label">
                  Juros do mês (%)
                </label>
                <Input
                  id="interest"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite o juros do mês"
                  value={interest.value}
                  error={interest.error}
                  onChange={interest.onChange}
                  onBlur={interest.onBlur}
                />
              </div>
              <div data-grid="discount">
                <label htmlFor="discount" className="label">
                  Desconto (%)
                </label>
                <Input
                  id="discount"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite o desconto"
                  value={discount.value}
                  error={discount.error}
                  onChange={discount.onChange}
                  onBlur={discount.onBlur}
                />
              </div>
              <div data-grid="addFreight">
                <label htmlFor="addFreight" className="label">
                  Adicionar Frete
                </label>
                <Select
                  id="addFreight"
                  options={addFreightOptions}
                  value={addFreight.value}
                  error={addFreight.error}
                  onChange={(value) => {
                    if (!value.value) {
                      freightValue.reset();
                    }
                    addFreight.onChange(value);
                  }}
                  onBlur={addFreight.onBlur}
                  isClearable={false}
                  isSearchable={false}
                />
              </div>
              <div data-grid="freightValue">
                <label htmlFor="freightValue" className="label">
                  Valor do Frete (R$)
                </label>
                <Input
                  id="freightValue"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite o valor do frete"
                  value={freightValue.value}
                  error={freightValue.error}
                  onChange={freightValue.onChange}
                  onBlur={freightValue.onBlur}
                  disabled={!addFreight.value?.value}
                />
              </div>
              <div data-grid="interestType">
                <label htmlFor="interestType" className="label">
                  Tipo de Juros
                </label>
                <Select
                  id="interestType"
                  placeholder="Selecione um tipo de juros"
                  options={interestTypeOptions}
                  value={interestType.value}
                  error={interestType.error}
                  onChange={interestType.onChange}
                  onBlur={interestType.onBlur}
                  isSearchable={false}
                />
              </div>
              <div data-grid="daysChargeInterest">
                <label htmlFor="daysChargeInterest" className="label">
                  Dias até Cobrança de Juros
                </label>
                <Input
                  id="daysChargeInterest"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite os dias até a cobrança de juros"
                  value={daysChargeInterest.value}
                  error={daysChargeInterest.error}
                  onChange={daysChargeInterest.onChange}
                  onBlur={daysChargeInterest.onBlur}
                />
              </div>
              <div data-grid="situation">
                <label htmlFor="situation" className="label">
                  Situação
                </label>
                <Select
                  id="situation"
                  options={situationOptions}
                  value={situation.value}
                  error={situation.error}
                  onChange={situation.onChange}
                  onBlur={situation.onBlur}
                  isClearable={false}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className={styles.submitButtonContainer}>
              <Button>Concluir</Button>
            </div>
          </>
        ) : (
          <div className={`loadingContainer`}>
            <Circle size={100} />
            <span className="loadingMessage">Salvando Tabela de Vigências</span>
          </div>
        )}
      </form>
    </div>
  );
}
