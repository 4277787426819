import React from "react";
import { Route, Routes } from "react-router-dom";
import { List } from "./List/List";
import { useSolicitationList } from "./hooks/useSolicitationList";
import { ISolicitation } from "./hooks/types";
import { Historical } from "./Historical/Historical";
import { useHistorical } from "./hooks/useHistorical";
import useContractExcel from "../hooks/useContractExcel";

export function ContractsSolicitationsHistorical() {
  const selectedSolicitation = React.useRef<ISolicitation | null>(null);

  const solicitationList = useSolicitationList();
  const historical = useHistorical();
  const contractExcel = useContractExcel();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <List
            solicitationList={solicitationList}
            selectedSolicitation={selectedSolicitation}
            contractExcel={contractExcel}
          />
        }
      />
      <Route
        path="visualizar"
        element={
          <Historical
            selectedSolicitation={selectedSolicitation.current}
            useHistorical={historical}
            solicitationList={solicitationList}
            contractExcel={contractExcel}
          />
        }
      />
    </Routes>
  );
}
