import { useCallback, useState } from "react";

import { useSelect } from "../../../../../../../hooks/form";
import { useCustomFetch, useDebounce, useLoading } from "../../../../../../../hooks/async";
import { useModal } from "../../../../../../../hooks/contexts";
import { isValid } from "../../../../../../../helpers/validations";
import { useNavigate } from "react-router-dom";

import { ISeller, IUseCloneCommissionsToSellers } from "./types";

export function useCloneCommissionsToSellers(): IUseCloneCommissionsToSellers {
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();

  const copiedSeller = useSelect<ISeller, "single">({ type: "single", required: true });
  const sellers = useSelect<ISeller, "multiple">({ type: "multiple", required: true });

  const [copiedSellerOptions, setCopiedSellerOptions] = useState<ISelectOption<ISeller>[]>([]);
  const [sellerOptions, setSellerOptions] = useState<ISelectOption<ISeller>[]>([]);

  const searchingCopiedSellerOptions = useLoading();
  const searchingSellerOptions = useLoading();
  const cloningCommissions = useLoading();

  const searchSellerOptionsPromise = useCallback(
    (description = "") => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await customFetch("/commissions/contracts/searchContractPeoples", {
            body: { descricao: description },
          });
          resolve(response);
        } catch (error) {
          reject(error);
        }
      }) as Promise<DefaultFetchResponse<ISeller[]>>;
    },
    [customFetch]
  );

  const searchCopiedSellerOptions = useCallback(
    async (description: string = "") => {
      try {
        searchingCopiedSellerOptions.setLoading(true);
        const json = await searchSellerOptionsPromise(description);
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            label: `${option.idUsuario} | ${option.nome}`,
            value: option,
          }));
          setCopiedSellerOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setCopiedSellerOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingCopiedSellerOptions.setLoading(false);
      }
    },
    [Modal, searchSellerOptionsPromise, searchingCopiedSellerOptions]
  );

  const searchSellerOptions = useCallback(
    async (description: string = "") => {
      try {
        searchingSellerOptions.setLoading(true);
        const json = await searchSellerOptionsPromise(description);
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            label: `${option.idUsuario} | ${option.nome}`,
            value: option,
          }));
          setSellerOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setSellerOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingSellerOptions.setLoading(false);
      }
    },
    [Modal, searchSellerOptionsPromise, searchingSellerOptions]
  );

  const searchCopiedSellerOptionsDebounced = useDebounce(searchCopiedSellerOptions);
  const searchSellerOptionsDebounced = useDebounce(searchSellerOptions);

  const cloneCommissionsToSellers = useCallback(
    async (contractId: number, enterpriseId: number) => {
      if (!isValid(copiedSeller, sellers)) return;
      try {
        cloningCommissions.setLoading(true);
        customFetch("/commissions/contracts/cloneContractCommissionsToSellers", {
          body: {
            idEmpresa: enterpriseId,
            idContrato: contractId,
            idVendedor: copiedSeller.value?.value.idUsuario,
            novosVendedores: sellers.value?.map((seller) => seller.value?.idUsuario),
          },
        });
        await Modal.success(
          "As comissões do vendedor estão sendo copiadas!<br/>Você será avisado por e-mail ao término do processo!"
        );
        navigate("/comissoes/adubo/comissoes-de-contratos/itens");
      } catch (error) {
        Modal.error(error);
      } finally {
        cloningCommissions.setLoading(false);
      }
    },
    [Modal, cloningCommissions, copiedSeller, customFetch, sellers, navigate]
  );

  return {
    copiedSeller,
    sellers,
    searchSellerOptions: {
      search: searchSellerOptions,
      searchDebounced: searchSellerOptionsDebounced,
      options: sellerOptions,
      searching: searchingSellerOptions.isLoading,
    },
    searchCopiedSellerOptions: {
      search: searchCopiedSellerOptions,
      searchDebounced: searchCopiedSellerOptionsDebounced,
      options: copiedSellerOptions,
      searching: searchingCopiedSellerOptions.isLoading,
    },
    cloneCommissions: {
      clone: cloneCommissionsToSellers,
      cloning: cloningCommissions.isLoading,
    },
  };
}
