import React from "react";
import { Button, Select } from "../../../../components/Form";
import { useSelect } from "../../../../hooks/form";
import { useModal } from "../../../../hooks/contexts";
import { useCustomFetch, useDebounce, useLoading } from "../../../../hooks/async";

import styles from "./BySeller.module.css";
import { isValid } from "../../../../helpers/validations";
import { Circle } from "../../../../components/Loading";
import { Collapse } from "../../../../components/Collapse/Collapse";

interface ISeller {
  idUsuario: number;
  nome: string;
}

interface IReportData {
  idFormulado: number;
  nomeFormulado: string;
  statusFormulado: "A" | "I";
  nomeGrupoFormulado: string;
  statusGrupoFormulado: "A" | "I";
  tabela: {
    idTabela: number;
    idFormulado: number;
    codigoEmpresa: number;
    nomeEmpresa: string;
    nomeTabela: string;
    dataBaseTabela: string;
    nomeVigencia: string;
    statusVigencia: "A" | "I";
    tipoComissao: "M" | "D";
    faixa: {
      codigoEmpresa: number;
      idTabela: number;
      idFormulado: number;
      letra: string;
      cor: string;
      dataManutencao: string;
      percComissao: number;
    }[];
  }[];
}

export function CommissionsReportsBySeller() {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const seller = useSelect({ type: "single", required: true });

  const [reportData, setReportData] = React.useState<IReportData[]>([]);

  const [sellerOptions, setSellerOptions] = React.useState<ISelectOption<ISeller>[]>([]);

  const searchingSellers = useLoading();
  const searchingReportData = useLoading();

  const searchSellerOptions = React.useCallback(
    async (description: string = "") => {
      try {
        searchingSellers.setLoading(true);
        const json = (await customFetch("/commissions/searchPeoples", {
          body: { descricao: description },
        })) as DefaultFetchResponse<ISeller[]>;
        if (json.status === 200) {
          const options = json.object.map((item) => ({
            value: item,
            label: `${item.idUsuario} | ${item.nome}`,
          }));
          setSellerOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setSellerOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingSellers.setLoading(false);
      }
    },
    [Modal, customFetch, searchingSellers]
  );

  const searchSellerOptionsDebounced = useDebounce(searchSellerOptions);

  const searchFormulasBySeller = React.useCallback(async () => {
    if (!isValid(seller)) return;
    try {
      searchingReportData.setLoading(true);
      const json = (await customFetch("/commissions/searchFormulasBySeller", {
        body: { idVendedor: seller.value?.value.idUsuario },
      })) as DefaultFetchResponse<IReportData[]>;
      if (json.status === 200) {
        setReportData(json.object);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        Modal.alert("Nenhum dado foi encontrado");
        setReportData([]);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingReportData.setLoading(false);
    }
  }, [Modal, customFetch, searchingReportData, seller]);

  React.useEffect(() => {
    searchSellerOptions();
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.fieldsContainer}>
        <div>
          <label htmlFor="seller" className="label">
            Vendedores
          </label>
          <Select
            id="seller"
            placeholder="Selecione o vendedor"
            value={seller.value}
            onChange={seller.onChange}
            onInputChange={searchSellerOptionsDebounced}
            options={sellerOptions}
            isLoading={searchingSellers.isLoading}
          />
        </div>
        <div>
          <Button className={styles.buttonSearchData} onClick={() => searchFormulasBySeller()}>
            Buscar Dados
          </Button>
        </div>
      </div>
      {reportData.length && !searchingReportData.isLoading ? (
        <div className={styles.dataContainer}>
          {reportData.map((formula, formulaIndex) => (
            <Collapse
              key={formulaIndex}
              label={
                <div className={styles.dataContainer__formulaInfo}>
                  <p className={styles.dataContainer__formulaInfo__formula}>
                    {formula.idFormulado} | {formula.nomeFormulado}
                  </p>
                </div>
              }
              className={undefined}
              classNameMainContainer={styles.formulaCollapse__container}
              classNameButton={undefined}
              iconColor={"var(--gray-8)"}>
              <div>
                <span className={styles.separator} />
                <div className={styles.rangesContainer}>
                  <h2 className={styles.title}>Tabelas</h2>
                  {formula.tabela.map((table, tableIndex) => (
                    <Collapse
                      key={tableIndex}
                      label={
                        <div className={styles.dataContainer__tableInfo}>
                          <p className={styles.dataContainer__tableInfo__table}>{table.nomeTabela}</p>
                          <p className={styles.dataContainer__tableInfo__validity}>{table.nomeVigencia}</p>
                        </div>
                      }
                      className={undefined}
                      classNameMainContainer={styles.rangeCollapse__container}
                      classNameButton={undefined}
                      iconColor={"var(--gray-8)"}>
                      <span className={styles.separator} />
                      <div className={styles.rangesContainer__ranges}>
                        {table.faixa.map((range, rangeIndex) => (
                          <div className={`${styles.commissionBlock}`} key={rangeIndex}>
                            <div className={styles.ball}>
                              <div style={{ backgroundColor: range.cor }}></div>
                              <span style={{ backgroundColor: range.cor }}></span>
                              <p>{range.letra}</p>
                            </div>
                            <p>Com. {range.percComissao}%</p>
                          </div>
                        ))}
                      </div>
                    </Collapse>
                  ))}
                </div>
              </div>
            </Collapse>
          ))}
        </div>
      ) : searchingReportData.isLoading ? (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <p className="loadingMessage">
            Buscando Dados
            <br />
            (Este processo pode levar algum tempo)
          </p>
        </div>
      ) : null}
    </div>
  );
}
