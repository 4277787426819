import React from "react";
import { Button, Input, LinkButton } from "../../../../components/Form";
import { useForm } from "../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { useNavigate } from "react-router-dom";
import { isValid } from "../../../../helpers/validations";
import { Circle } from "../../../../components/Loading";

import styles from "./NewBank.module.css";

interface IProps {
  searchTotalBankRecordsAndBanks: () => void;
}

export function NewBank({ searchTotalBankRecordsAndBanks }: IProps) {
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();

  const description = useForm({ required: true });

  const savingBank = useLoading();

  const saveBank = React.useCallback(async () => {
    if (!isValid(description)) return;
    try {
      savingBank.setLoading(true);
      const json = (await customFetch("/finance/insertAndUpdateBank", {
        body: {
          bancoId: 0,
          descricao: description.value,
          status: "A",
        },
      })) as DefaultFetchResponse<null>;
      if (json.status === 200) {
        searchTotalBankRecordsAndBanks();
        await Modal.success(json.message);
        navigate("/financeiro/bancos");
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      savingBank.setLoading(false);
    }
  }, [Modal, customFetch, description, navigate, savingBank, searchTotalBankRecordsAndBanks]);

  return (
    <div className="container">
      {!savingBank.isLoading ? (
        <>
          <div>
            <LinkButton to={"/financeiro/bancos"} buttonStyle="backButton" />
          </div>
          <div className={styles.fieldsContainer}>
            <div>
              <label htmlFor="description" className="label">
                Descrição
              </label>
              <Input
                id="description"
                placeholder="Digite a descrição do banco"
                value={description.value}
                error={description.error}
                onChange={description.onChange}
                onBlur={description.onChange}
              />
            </div>
          </div>
          <div className={styles.saveButtonContainer}>
            <Button onClick={saveBank}>Salvar Banco</Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Salvando Novo Banco</span>
        </div>
      )}
    </div>
  );
}
