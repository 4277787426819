import React from "react";

import styles from "./Textarea.module.css";

export function Textarea({ value, onChange, onBlur, error, className, cols = "", rows = "", ...props }) {
  return (
    <span className={styles.wrapper}>
      <textarea
        className={`${styles.textarea} ${error ? "isInvalid" : ""} ${
          String(value).length ? "isFilled" : ""
        } ${className}`}
        value={value}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        cols={cols || ""}
        rows={rows || "8"}
        {...props}
      />
      {error && <p className={styles.error}>{error}</p>}
    </span>
  );
}
