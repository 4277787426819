import React from "react";
import { Button, Select } from "../../../../components/Form";
import { useSelect } from "../../../../hooks/form";
import { useModal } from "../../../../hooks/contexts";
import { useCustomFetch, useDebounce, useLoading } from "../../../../hooks/async";
import { Collapse } from "../../../../components/Collapse/Collapse";
import { Circle } from "../../../../components/Loading";

import styles from "./ByFormula.module.css";
import { isValid } from "../../../../helpers/validations";

interface IFormula {
  id: number;
  descricao: string;
}

interface ITable {
  id: number;
  descricao: string;
}

interface IReportData {
  codigoEmpresa: number;
  nomeEmpresa: string;
  nomeTabela: string;
  dataBaseTabela: string;
  nomeVigencia: string;
  statusVigencia: "A" | "I";
  tipoComissao: "M" | "D";
  faixa: {
    letra: string;
    cor: string;
    dataManutencao: string;
    percComissao: number;
    vendedor: {
      idVendedor: number;
      nomeVendedor: string;
      percComissao: number;
      supervisor: {
        idSupervisor: number;
        nomeSupervisor: string;
        percComissao: number;
      }[];
    }[];
  }[];
}

export function CommissionsReportsByFormula() {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const formula = useSelect({ type: "single", required: true });
  const table = useSelect({ type: "single", required: false });

  const [reportData, setReportData] = React.useState<IReportData[]>([]);

  const [formulaOptions, setFormulaOptions] = React.useState<ISelectOption<IFormula>[]>([]);
  const [tableOptions, setTableOptions] = React.useState<ISelectOption<ITable>[]>([]);

  const searchingFormulaOptions = useLoading();
  const searchingTableOptions = useLoading();
  const searchingReportData = useLoading();

  const searchFormulaOptions = React.useCallback(
    async (description: string = "") => {
      try {
        searchingFormulaOptions.setLoading(true);
        const json = await customFetch("/commissions/searchFormulas", {
          body: { descricao: description },
        });
        if (json.status === 200) {
          const options = json.object.map((item: IFormula) => ({
            value: item,
            label: `${item.id} | ${item.descricao}`,
          }));
          setFormulaOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setFormulaOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingFormulaOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingFormulaOptions]
  );

  const searchFormulaOptionsDebounced = useDebounce(searchFormulaOptions);

  const searchTableOptions = React.useCallback(
    async (description: string = "") => {
      try {
        searchingTableOptions.setLoading(true);
        const json = await customFetch("/commissions/searchTables", {
          body: { descricao: description },
        });
        if (json.status === 200) {
          const options = json.object.map((item: ITable) => ({
            value: item,
            label: `${item.id} | ${item.descricao}`,
          }));
          setTableOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setTableOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingTableOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingTableOptions]
  );

  const searchTableOptionsDebounced = useDebounce(searchTableOptions);

  const searchSellersByFormula = React.useCallback(async () => {
    if (!isValid(formula, table)) return;
    try {
      searchingReportData.setLoading(true);
      const json = (await customFetch("/commissions/searchSellersByFormula", {
        body: { idformulado: formula.value?.value.id, idTabela: table.value?.value.id || 0 },
      })) as DefaultFetchResponse<IReportData[]>;
      if (json.status === 200) {
        setReportData(json.object);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        Modal.alert("Nenhum dado foi encontrado");
        setReportData([]);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingReportData.setLoading(false);
    }
  }, [Modal, customFetch, formula, searchingReportData, table]);

  React.useEffect(() => {
    searchFormulaOptions();
    searchTableOptions();
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.fieldsContainer}>
        <div>
          <label htmlFor="formula" className="label">
            Fórmula
          </label>
          <Select
            id="formula"
            placeholder="Selecione a fórmula"
            value={formula.value}
            onChange={formula.onChange}
            onInputChange={searchFormulaOptionsDebounced}
            options={formulaOptions}
            isLoading={searchingFormulaOptions.isLoading}
          />
        </div>
        <div>
          <label htmlFor="table" className="label">
            Tabela
          </label>
          <Select
            id="table"
            placeholder="Selecione uma tabela"
            value={table.value}
            onChange={table.onChange}
            onInputChange={searchTableOptionsDebounced}
            options={tableOptions}
            isLoading={searchingTableOptions.isLoading}
          />
        </div>
        <div className={styles.fieldsContainer__buttonContainer}>
          <Button className={styles.fieldsContainer__buttonContainer__button} onClick={searchSellersByFormula}>
            Buscar Dados
          </Button>
        </div>
      </div>
      {reportData.length && !searchingReportData.isLoading ? (
        <div className={styles.dataContainer}>
          <h2 className={styles.title}>Tabelas</h2>
          {reportData.map((validity, validityIndex) => (
            <Collapse
              key={validityIndex}
              label={
                <div className={styles.dataContainer__tableInfo}>
                  <p className={styles.dataContainer__tableInfo__table}>{validity.nomeTabela}</p>
                  <p className={styles.dataContainer__tableInfo__validity}>{validity.nomeVigencia}</p>
                </div>
              }
              className={undefined}
              classNameMainContainer={styles.validityCollapse__container}
              classNameButton={undefined}
              iconColor={"var(--gray-8)"}>
              <div>
                <span className={styles.separator} />
                <div className={styles.rangesContainer}>
                  <h2 className={styles.title}>Faixas</h2>
                  {validity.faixa.map((range, rangeIndex) => (
                    <Collapse
                      key={rangeIndex}
                      label={
                        <div className={styles.dataContainer__rangeInfo}>
                          <span
                            className={styles.dataContainer__rangeInfo__ball}
                            style={{ backgroundColor: range.cor }}>
                            {range.letra}
                          </span>
                          <p className={styles.dataContainer__rangeInfo__percent}>Percentual: {range.percComissao}%</p>
                        </div>
                      }
                      className={undefined}
                      classNameMainContainer={styles.rangeCollapse__container}
                      classNameButton={undefined}
                      iconColor={"var(--gray-8)"}>
                      <div>
                        <span className={styles.separator} />
                        <div className={styles.sellersContainer}>
                          <h2 className={styles.title}>Vendedores</h2>
                          {range.vendedor.map((seller, sellerIndex) => (
                            <Collapse
                              key={sellerIndex}
                              label={
                                <div className={styles.dataContainer__sellerInfo}>
                                  <p className={styles.dataContainer__sellerInfo__seller}>{seller.nomeVendedor}</p>
                                  <p className={styles.dataContainer__sellerInfo__percent}>
                                    Percentual: {seller.percComissao}%
                                  </p>
                                </div>
                              }
                              className={undefined}
                              classNameMainContainer={styles.sellerCollapse__container}
                              classNameButton={undefined}
                              iconColor={"var(--gray-8)"}>
                              <div>
                                <span className={styles.separator} />
                                <div className={styles.supervisorContainer}>
                                  <h2 className={styles.title}>Supervisores</h2>
                                  {seller.supervisor.map((supervisor, supervisorIndex) => (
                                    <div className={styles.dataContainer__supervisorInfo}>
                                      <p className={styles.dataContainer__supervisorInfo__supervisor}>
                                        {supervisor.nomeSupervisor}
                                      </p>
                                      <p className={styles.dataContainer__supervisorInfo__percent}>
                                        Percentual: {supervisor.percComissao}%
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </Collapse>
                          ))}
                        </div>
                      </div>
                    </Collapse>
                  ))}
                </div>
              </div>
            </Collapse>
          ))}
        </div>
      ) : searchingReportData.isLoading ? (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <p className="loadingMessage">
            Buscando Dados
            <br />
            (Este processo pode levar algum tempo)
          </p>
        </div>
      ) : null}
    </div>
  );
}
