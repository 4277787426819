import React from "react";
import { Route, Routes } from "react-router-dom";

import { Lista } from "./Lista/Lista";
import { AdicionarAtualizar } from "./AdicionarAtualizar/AdicionarAtualizar";
import { useContrato } from "./hooks/useContrato";
import { ContratoAnexado } from "./ContratoAnexado/ContratoAnexado";

export function ContratosFCContrato() {
  const contratosData = useContrato();

  return (
    <>
      <Routes>
        <Route path="/" element={<Lista contratosData={contratosData} />} />
        <Route path="/novo" element={<AdicionarAtualizar tipo="insert" contratosData={contratosData} />} />
        <Route
          path="/atualizar"
          element={
            <AdicionarAtualizar
              tipo="update"
              contratosData={contratosData}
              selectedContrato={contratosData.selectedContrato.value}
            />
          }
        />
        <Route
          path="/anexo"
          element={
            <ContratoAnexado selectedContrato={contratosData.selectedContrato.value} contratosData={contratosData} />
          }
        />
      </Routes>
    </>
  );
}
