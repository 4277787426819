import React, { useEffect, useMemo } from "react";

import { Button, Input, Select } from "../../../../../components/Form";
import { IUseCommissionPercent } from "../hooks/types";
import { Circle } from "../../../../../components/Loading";

import styles from "./Index.module.css";
import { useNavigate } from "react-router-dom";
import { CaretDown } from "phosphor-react";
import { CommissionBall } from "../../../../../components/Commons/CommissionBall";

interface IProps {
  rootData: IUseCommissionPercent;
}

export function Index({ rootData }: IProps) {
  const {
    filters: { itemFilter },
    sets: { setSelectedItem },
    data: { validityTable, validityTableOptions, validityTableItems },
    methods: { searchValidityTableOptionsDebounced, searchValidityTableItems },
    loadings: { searchingValidityTableOptions, searchingValidityTableItems },
  } = rootData;

  const navigate = useNavigate();

  const validityTableItemsFiltered = useMemo(() => {
    return validityTableItems.filter((item) => {
      if (itemFilter.value) {
        return (
          item.formulado.idFormulado.toString().includes(itemFilter.value) ||
          item.formulado.descricaoFormulado
            .toLowerCase()
            .includes(itemFilter.value.toLowerCase())
        );
      }
      return true;
    });
  }, [itemFilter.value, validityTableItems]);

  useEffect(() => {
    setSelectedItem(null);
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.formContainer}>
        <div>
          <label htmlFor="validityTable" className="label">
            Tabela de Vigência
          </label>
          <Select
            id="validityTable"
            placeholder="Selecione uma tabela de vigência"
            value={validityTable.value}
            options={validityTableOptions}
            onChange={(value) => {
              validityTable.onChange(value);
              searchValidityTableItems(value?.value.tabela.id || 0);
            }}
            onBlur={validityTable.onBlur}
            error={validityTable.error}
            onInputChange={searchValidityTableOptionsDebounced}
            isLoading={searchingValidityTableOptions}
          />
        </div>
      </div>
      <div>
        <span className="separator" />
        {!searchingValidityTableItems && validityTableItems.length ? (
          <>
            <div>
              <label htmlFor="itemFilter" className="label">
                Filtrar Item
              </label>
              <Input
                id="itemFilter"
                placeholder="Digite um item para filtrar"
                value={itemFilter.value}
                onChange={itemFilter.onChange}
              />
            </div>
            <ul className={styles.itemsContainer}>
              {validityTableItemsFiltered.length > 0 ? (
                validityTableItemsFiltered.map((item) => (
                  <li
                    key={item.itensTabela.idItemTabela}
                    className={styles.item}
                  >
                    <div>
                      <p className={styles.itemTitle}>
                        {item.formulado.idFormulado} |{" "}
                        {item.formulado.descricaoFormulado}
                      </p>
                      <span className={styles.itemSeparator} />
                      <div className={styles.commissionsContainer}>
                        {item.itensTabela.itensTabelaVigenciaPercentualPreco
                          .length > 0 ? (
                          item.itensTabela.itensTabelaVigenciaPercentualPreco
                            .filter((commission) => commission.status === "A")
                            .sort((a, b) => b.percentual - a.percentual)
                            .map((commission) => (
                              <CommissionBall.Root key={commission.id}>
                                <CommissionBall.Ball
                                  color={commission.cor}
                                  letter={item.itensTabela.categoria}
                                  barStyle={{ borderRadius: "4px" }}
                                />
                                <CommissionBall.Info
                                  className={styles.ballInfo}
                                >
                                  {commission.percentual}%
                                </CommissionBall.Info>
                              </CommissionBall.Root>
                            ))
                        ) : (
                          <p className="lineCardMessage">
                            Nenhum percentual cadastrado
                          </p>
                        )}
                      </div>
                    </div>
                    <div className={styles.itemOptionsContainer}>
                      <Button
                        className={styles.item_button}
                        onClick={() => {
                          setSelectedItem(item);
                          navigate("atualizar-percentuais");
                        }}
                      >
                        Editar Percentuais
                      </Button>
                      <span>
                        <CaretDown weight="light" />
                      </span>
                    </div>
                  </li>
                ))
              ) : (
                <li>
                  <p className="lineCardMessage">Nenhum item correspondente</p>
                </li>
              )}
            </ul>
          </>
        ) : searchingValidityTableItems ? (
          <div className={`loadingContainer`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className="lineCardMessage">
            Selecione uma tabela para buscar os itens
          </p>
        )}
      </div>
    </div>
  );
}
