import React from "react";

import { Button, Input, Select } from "../../../../components/Form";
import { useSelectOptions } from "../hooks/useSelectOptions";
import { useInventory } from "./hooks/useInventory";
import { Circle } from "../../../../components/Loading";
import { Paginate } from "../../../../components/Paginate/Paginate";
import { ArrowRight } from "phosphor-react";
import { formatDate } from "../../../../helpers/format";

import styles from "./Inventory.module.css";
import { selectOptionsDifference } from "../../../../helpers/List";

export function FactoryStockSchedulingInventory() {
  const { productOptions, enterpriseOptions, actionOptions } = useSelectOptions();
  const { initialDate, finalDate, products, enterprises, moveType, inventoryRecords, searchs, confirmChange } =
    useInventory();

  return (
    <div className="container">
      <div className={styles.fieldsContainer}>
        <div>
          <label htmlFor="initialDate" className="label">
            Data Inicial
          </label>
          <Input
            type="date"
            id="initialDate"
            value={initialDate.value}
            error={initialDate.error}
            onChange={(e) => {
              confirmChange(initialDate, e);
            }}
            onBlur={initialDate.onBlur}
          />
        </div>
        <div>
          <label htmlFor="finalDate" className="label">
            Data Final
          </label>
          <Input
            type="date"
            id="finalDate"
            value={finalDate.value}
            error={finalDate.error}
            onChange={async (e) => {
              confirmChange(finalDate, e);
            }}
            onBlur={finalDate.onBlur}
          />
        </div>
        <div className={styles.fieldProduct}>
          <label htmlFor="products" className="label">
            Produtos
          </label>
          <Select
            id="products"
            placeholder="Selecione os produtos que deseja buscar"
            options={selectOptionsDifference(productOptions.options, products.value)}
            value={products.value}
            error={products.error}
            onChange={async (value) => {
              confirmChange(products, value);
            }}
            onInputChange={productOptions.searchDebounced}
            isLoading={productOptions.searching}
            isMulti
          />
        </div>
        <div>
          <label htmlFor="enterprises" className="label">
            Empresas
          </label>
          <Select
            id="enterprises"
            placeholder="Selecione as empresas que deseja filtrar"
            options={selectOptionsDifference(enterpriseOptions.options, enterprises.value)}
            value={enterprises.value}
            error={enterprises.error}
            onChange={async (value) => {
              confirmChange(enterprises, value);
            }}
            isLoading={enterpriseOptions.searching}
            isMulti
          />
        </div>
        <div>
          <label htmlFor="moveType" className="label">
            Tipo de Movimento
          </label>
          <Select id="moveType" options={actionOptions.options} value={moveType.value} onChange={moveType.onChange} />
        </div>
        <div className={styles.searchButtonContainer}>
          <Button
            className={styles.searchButton}
            onClick={() => {
              searchs.searchTotalInventoryRecordsAndInventoryRecords();
            }}>
            Buscar Registros
          </Button>
        </div>
      </div>
      <div className={styles.contentContainer}>
        {Object.keys(inventoryRecords.value).length && !searchs.searching ? (
          Object.keys(inventoryRecords.value).map((key) => (
            <div className={styles.productContainer}>
              <h1 className={styles.productContainer__title}>{inventoryRecords.value[key][0].produto.nomeProduto}</h1>
              <ul className={styles.productContainer__listData}>
                {inventoryRecords.value[key].map((record) => (
                  <>
                    <li className={styles.productContainer__data}>
                      <div className={styles.data__info}>
                        <p className={styles.data__info__title}>Quantidade Estoque</p>
                        <ArrowRight size={20} color="var(--gray-6)" weight="fill" />
                        <p>
                          {record.quantidade} {record.produto.unidade}
                        </p>
                      </div>
                      <div className={styles.data__info}>
                        <p className={styles.data__info__title}>Empresa</p>
                        <ArrowRight size={20} color="var(--gray-6)" weight="fill" />
                        <p>{record.empresa.nomeEmpresa}</p>
                      </div>
                      <div className={styles.data__info}>
                        <p className={styles.data__info__title}>Data Estoque</p>
                        <ArrowRight size={20} color="var(--gray-6)" weight="fill" />
                        <p>{formatDate(record.dataEstoque, "dd/MM/yyyy")}</p>
                      </div>
                    </li>
                  </>
                ))}
              </ul>
            </div>
          ))
        ) : searchs.searching ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nada encontrado no estoque</p>
        )}
      </div>
      <Paginate
        classNameContainer={styles.paginationContainer}
        maxItems={searchs.pagination.maxItems}
        totalRecords={searchs.pagination.totalRecords}
        currentPage={searchs.pagination.currentPage}
        setCurrentPage={searchs.pagination.setCurrentPage}
        onPageChange={(page) => {
          searchs.searchInventoryRecords(page - 1);
        }}
      />
    </div>
  );
}
