import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelect } from "../../../../hooks/form";
import {
  useCustomFetch,
  useDebounce,
  useLoading,
} from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";

import {
  ICategory,
  IUseReplicateValidity,
  IValidityItem,
  IValidityTable,
  changeType,
} from "./types";

export default function useReplicateValidity(): IUseReplicateValidity {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const modalReplicationRef = useRef<HTMLDialogElement>(null);

  const validityTable = useSelect<IValidityTable>({
    type: "single",
    required: true,
  });
  const [validityItems, setValidityItems] = useState<IValidityItem[]>([]);
  const [validityTableOptions, setValidityTableOptions] = useState<
    ISelectOption<IValidityTable>[]
  >([]);
  const [categoryOptions, setCategoryOptions] = useState<
    ISelectOption<string>[]
  >([]);
  const [isCampaign, setIsCampaign] = useState(false);

  const isFoliarTable = useMemo(() => {
    return !!validityTable.value?.value.tabela.cabecalhoTabela.grupo?.descricaoGrupo
      .toLowerCase()
      .includes("foliar");
  }, [validityTable.value?.value.tabela.cabecalhoTabela.grupo]);

  const allItemsChecked = useMemo(() => {
    return (
      validityItems.length > 0 && validityItems.every((item) => item.checkItem)
    );
  }, [validityItems]);

  const searchingValidityTableOptions = useLoading();
  const searchingCategoryOptions = useLoading();
  const searchingValidityItems = useLoading();
  const replicatingValidity = useLoading();

  const searchCategoryOptions = useCallback(async () => {
    try {
      searchingCategoryOptions.setLoading(true);
      const json = (await customFetch("/products/searchCategories", {
        body: {
          id: 0,
          categoria: "",
          status: "A",
        },
      })) as DefaultFetchResponse<ICategory[]>;
      if (json.status === 200) {
        const options = json.object.map((category) => ({
          label: category.categoria,
          value: category.categoria,
        }));
        setCategoryOptions(options);
      } else {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingCategoryOptions.setLoading(false);
    }
  }, [Modal, customFetch, searchingCategoryOptions]);

  const searchValidityTableOptions = useCallback(
    async (description = "") => {
      try {
        searchingValidityTableOptions.setLoading(true);
        const json = await customFetch("/products/searchValidityList", {
          body: {
            descricao: description,
            tamanho: 100,
            pagina: 0,
          },
        });
        if (json.status === 200) {
          const options = json.object.map((table: IValidityTable) => ({
            label: table.tabela.nome,
            value: table,
          }));
          setValidityTableOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setValidityTableOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingValidityTableOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingValidityTableOptions]
  );

  const searchValidityTableOptionsDebounced = useDebounce(
    searchValidityTableOptions
  );

  const searchValidityItems = useCallback(
    async (tableId: number) => {
      try {
        searchingValidityItems.setLoading(true);
        const json = await customFetch("/products/searchValidityItems", {
          body: {
            idTabelaVigencia: tableId,
            descricao: "",
          },
        });
        if (json.status === 200) {
          const items = json.object.map((item: IValidityItem) => ({
            ...item,
            checkItem: false,
          }));
          setValidityItems(items);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
          setValidityItems([]);
        }
      } catch (error) {
        Modal.error(error);
        setValidityItems([]);
      } finally {
        searchingValidityItems.setLoading(false);
      }
    },
    [Modal, customFetch, searchingValidityItems]
  );

  const setAllItemsState = useCallback((state: "checked" | "unchecked") => {
    setValidityItems((items) => [
      ...items.map((item) => ({ ...item, checkItem: state === "checked" })),
    ]);
  }, []);

  const changeItem = useCallback(
    (item: IValidityItem, value: string | boolean, type: changeType) => {
      switch (type) {
        case "check":
          item.checkItem = value as boolean;
          break;
        case "costPrice":
          item.itensTabela.precoCusto = parseFloat(value as string);
          break;
        case "sellPrice":
          item.itensTabela.precoOriginal = parseFloat(value as string);
          break;
        case "category":
          item.itensTabela.categoria = value as string;
          break;
        case "ggf":
          item.itensTabela.ggf = parseFloat(value as string);
          break;
        case "packaging":
          item.itensTabela.embalagem = parseFloat(value as string);
          break;
        case "profitMargin":
          item.itensTabela.margemSugerida = parseFloat(value as string);
          break;
        case "icms":
          item.itensTabela.icms = parseFloat(value as string);
          break;
        case "freight":
          item.itensTabela.frete = parseFloat(value as string);
          break;
        case "premiation":
          item.itensTabela.premiacao = parseFloat(value as string);
          break;
        case "grossMargin":
          item.itensTabela.margemBruta = parseFloat(value as string);
          break;
      }
      setValidityItems([...validityItems]);
    },
    [validityItems]
  );

  const replicateValidityAndTheirItems = useCallback(
    async (replicateAndApply: "S" | "N") => {
      replicatingValidity.setLoading(true);

      const items = validityItems
        .filter((item) => item.checkItem)
        .map((item) => ({
          idTabelaVigencia: item.itensTabela.idTabelaVigente,
          idItemTabela: item.itensTabela.idItemTabela,
          idProduto: item.itensTabela.idProduto,
          precoCusto: item.itensTabela.precoCusto,
          precoOriginal: item.itensTabela.precoOriginal,
          ggf: item.itensTabela.ggf,
          frete: item.itensTabela.frete,
          embalagem: item.itensTabela.embalagem,
          margemSugerida: item.itensTabela.margemSugerida,
          margemBruta: item.itensTabela.margemBruta,
          icms: item.itensTabela.icms,
          premiacao: item.itensTabela.premiacao,
          categoria: item.itensTabela.categoria,
          itensVigenciaPercentualPreco:
            item.itensTabela.itensTabelaVigenciaPercentualPreco.map(
              (subItem: any) => {
                subItem.itensVigenciaPercentualComissao = [
                  ...subItem.itensTabelaVigenciaComissao,
                ];
                return subItem;
              }
            ),
        }));

      try {
        const json = await customFetch(
          "/products/replicateValidityAndTheirItems",
          {
            body: {
              idTabela: validityTable.value?.value.tabela.id,
              itensVigencia: items,
              campanha: isCampaign ? "S" : "N",
              aplicaVigenciaTabela: replicateAndApply,
            },
          }
        );
        if (json.status === 200) {
          Modal.success(json.message);
          validityTable.reset();
          setIsCampaign(false);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        replicatingValidity.setLoading(false);
      }
    },
    [
      Modal,
      customFetch,
      replicatingValidity,
      validityItems,
      validityTable,
      isCampaign,
    ]
  );

  useEffect(() => {
    searchValidityTableOptions("");
    searchCategoryOptions();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (validityTable.value) {
      searchValidityItems(validityTable.value.value.tabela.id);
    } else {
      setValidityItems([]);
    }
  }, [validityTable.value]); // eslint-disable-line

  return {
    refs: {
      modalReplicationRef,
    },
    data: {
      isFoliarTable,
      allItemsChecked,
      validityTable,
      validityTableOptions,
      categoryOptions,
      isCampaign: {
        value: isCampaign,
        setValue: setIsCampaign,
      },
      validityItems: {
        value: validityItems,
        setValue: setValidityItems,
      },
    },
    actions: {
      searchValidityTableOptionsDebounced,
      setAllItemsState,
      changeItem,
      replicateValidityAndTheirItems,
    },
    loadings: {
      searchingValidityItems: searchingValidityItems.isLoading,
      searchingValidityTableOptions: searchingValidityTableOptions.isLoading,
      replicatingValidity: replicatingValidity.isLoading,
      searchingCategoryOptions: searchingCategoryOptions.isLoading,
    },
  };
}
