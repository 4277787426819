import React from "react";
import styles from "./Button.module.css";
import { CaretLeft } from "phosphor-react";

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Define a cor do botão */
  variant?: "danger" | "edit" | "alert" | "neutral" | "default";
  /** Define o estilo do botão */
  buttonStyle?: "backButton" | "";
}

const Button = ({
  type = "submit",
  children,
  variant = "default",
  className = "",
  buttonStyle = "",
  ...props
}: IButtonProps) => {
  return (
    <button
      className={`${styles.button} ${styles[variant]} ${buttonStyle} ${className}`}
      type={type || "submit"}
      {...props}>
      {buttonStyle === "backButton" ? (
        <>
          <CaretLeft weight="fill" />
          {children}
        </>
      ) : (
        <>{children}</>
      )}
    </button>
  );
};

export { Button };
