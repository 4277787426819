import React from "react";

import { IUseSolicitationList } from "../hooks/types";
import { ISolicitation } from "../types";
import {
  Table,
  TBody,
  Th,
  THead,
  Tr,
  Td,
} from "../../../../../components/Data/Table";
import { Button, Input, Select } from "../../../../../components/Form";
import { useNavigate } from "react-router-dom";
import { Eye, FileText } from "phosphor-react";
import { Circle } from "../../../../../components/Loading";
import { Paginate } from "../../../../../components/Paginate/Paginate";

import styles from "./List.module.css";
import { SolicitationStatus } from "../../../types";
import {
  ExcelPermissions,
  ExcelType,
  UseContractExcel,
} from "../../../hooks/useContractExcel";

interface Props {
  solicitationList: IUseSolicitationList;
  setSelectedSolicitation: React.Dispatch<
    React.SetStateAction<ISolicitation | null>
  >;
  contractExcel: UseContractExcel;
}

let temporarySelectedSolicitationToExportExcel: number | null = null;

export function List({
  solicitationList: {
    pagination,
    filter: { description, status, statusOptions },
    searchSolicitations,
    searchTotalSolicitationRecordsAndSolicitationsDebounced,
    searchingSolicitations,
    solicitationList: [solicitationList],
  },
  setSelectedSolicitation,
  contractExcel,
}: Props) {
  const navigate = useNavigate();

  const modalExcelType = React.useRef<HTMLDialogElement>(null);

  const [_, resetInterval] = React.useState(true);

  React.useEffect(() => {
    const fetchData = () => {
      searchTotalSolicitationRecordsAndSolicitationsDebounced(
        description.value,
        status.value?.value
      );
    };

    const newIntervalId = setInterval(fetchData, 60 * 1000);

    return () => clearInterval(newIntervalId);
  }, [_, description.value, status.value]); // eslint-disable-line

  React.useEffect(() => {
    setSelectedSolicitation(null);
  }, []); // eslint-disable-line

  return (
    <>
      <div className="container">
        {!contractExcel.exportingExcel ? (
          <>
            <div className={styles.filtersContainer}>
              <div>
                <label htmlFor="description" className="label">
                  Descrição
                </label>
                <Input
                  id="description"
                  placeholder="Digite a descrição"
                  value={description.value}
                  onChange={(e) => {
                    description.onChange(e);
                    searchTotalSolicitationRecordsAndSolicitationsDebounced(
                      e.target.value,
                      status.value?.value
                    );
                  }}
                  autoComplete="off"
                />
              </div>
              <div>
                <label htmlFor="status" className="label">
                  Status
                </label>
                <Select
                  id="status"
                  options={statusOptions}
                  value={status.value}
                  onChange={(value) => {
                    status.onChange(value);
                    searchTotalSolicitationRecordsAndSolicitationsDebounced(
                      description.value,
                      value?.value
                    );
                  }}
                  isClearable={false}
                  isSearchable={false}
                />
              </div>
            </div>
            {solicitationList.length && !searchingSolicitations ? (
              <Table title={"Solicitações Pendentes"} className={styles.table}>
                <THead>
                  <Tr>
                    <Th width={".625rem"} children={undefined}></Th>
                    <Th width={".625rem"}>ID</Th>
                    <Th width={""}>Descrição Contrato</Th>
                    {contractExcel.getExcelPermissions().permissions.length >
                      0 && <Th width={""} children={undefined} />}
                    <Th width={""} children={undefined} />
                  </Tr>
                </THead>
                <TBody>
                  {solicitationList.map((solicitation, index) => (
                    <Tr key={index}>
                      <Td heading={"Status"}>
                        <span className={styles.statusContainer}>
                          <span
                            className={styles.statusContainer__ball}
                            style={{
                              backgroundColor:
                                Number(solicitation.status) ===
                                Number(SolicitationStatus.AprovadoCliente)
                                  ? "var(--orange-5)"
                                  : "var(--green-5)",
                            }}
                          />
                          <span className={styles.statusContainer__status}>
                            {solicitation.nomeStatus}
                          </span>
                        </span>
                      </Td>
                      <Td heading={"ID"}>{solicitation.idSolicitacao}</Td>
                      <Td heading={"Descrição Contrato"}>
                        {solicitation.descricaoContrato}
                      </Td>
                      {contractExcel.getExcelPermissions().permissions.length >
                        0 && (
                        <Td
                          heading="Exportar Excel"
                          width="6.25rem"
                          data-option
                        >
                          <Button
                            type="button"
                            className={styles.seeRequestButton}
                            onClick={() => {
                              const permissionsAllowed =
                                contractExcel.getExcelPermissions();

                              if (permissionsAllowed.permissions.length > 1) {
                                temporarySelectedSolicitationToExportExcel =
                                  solicitation.idSolicitacao;
                                modalExcelType.current?.show();
                              } else if (
                                permissionsAllowed.permissions.length === 1
                              ) {
                                contractExcel.exportExcel(
                                  solicitation.idSolicitacao,
                                  permissionsAllowed.type!!
                                );
                              }
                            }}
                            data-option-button
                          >
                            <FileText weight="fill" />
                          </Button>
                        </Td>
                      )}
                      <Td heading="Ver Solicitação" width="6.25rem" data-option>
                        <Button
                          type="button"
                          variant="edit"
                          className={styles.seeRequestButton}
                          onClick={() => {
                            setSelectedSolicitation(solicitation);
                            navigate("analise");
                          }}
                          data-option-button
                        >
                          <Eye weight="fill" />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </TBody>
              </Table>
            ) : searchingSolicitations ? (
              <div className={`loadingContainer ${styles.loadingContainer}`}>
                <Circle size={100} />
              </div>
            ) : (
              <p className={`lineCardMessage`}>
                Nenhuma solicitação encontrada
              </p>
            )}
            <Paginate
              classNameContainer={styles.paginationContainer}
              maxItems={pagination.maxItems}
              totalRecords={pagination.totalRecords}
              currentPage={pagination.currentPage}
              setCurrentPage={pagination.setCurrentPage}
              onPageChange={(page) => {
                resetInterval((crr) => !crr);
                searchSolicitations(
                  page - 1,
                  description.value,
                  status.value?.value
                );
              }}
            />
          </>
        ) : (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
          </div>
        )}
      </div>
      <dialog
        className={`modal ${styles.modalExcelType}`}
        onClose={() => (temporarySelectedSolicitationToExportExcel = null)}
        ref={modalExcelType}
      >
        <div className="modalContent">
          <div className={styles.modalExcelType__buttonsContainer}>
            {contractExcel.verifyPermission(ExcelPermissions.CLIENTE) && (
              <Button
                className={styles.modalExcelType__buttonsContainer__button}
                onClick={() => {
                  contractExcel.exportExcel(
                    temporarySelectedSolicitationToExportExcel!!,
                    ExcelType.MODELO_CLIENTE
                  );
                  modalExcelType.current?.close();
                }}
              >
                Modelo Cliente
              </Button>
            )}
            {contractExcel.verifyPermission(ExcelPermissions.ANALITICO) && (
              <Button
                className={styles.modalExcelType__buttonsContainer__button}
                onClick={() => {
                  contractExcel.exportExcel(
                    temporarySelectedSolicitationToExportExcel!!,
                    ExcelType.MODELO_ANALITICO
                  );
                  modalExcelType.current?.close();
                }}
              >
                Modelo Analítico
              </Button>
            )}
            {contractExcel.verifyPermission(ExcelPermissions.JURIDICO) && (
              <Button
                className={styles.modalExcelType__buttonsContainer__button}
                onClick={() => {
                  contractExcel.exportExcel(
                    temporarySelectedSolicitationToExportExcel!!,
                    ExcelType.MODELO_JURIDICO
                  );
                  modalExcelType.current?.close();
                }}
              >
                Modelo Jurídico
              </Button>
            )}
          </div>
          <Button
            className={styles.modalExcelType__buttonCancel}
            variant="danger"
            onClick={() => {
              modalExcelType.current?.close();
            }}
          >
            Cancelar
          </Button>
        </div>
      </dialog>
    </>
  );
}
