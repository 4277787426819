import React from "react";
import { Button } from "../../../components/Form";
import { TModalMessage, TModalOnContinue } from "./Modal";

import modalStyles from "./styles/modal.module.css";
import successStyles from "./styles/success.module.css";

interface IModalSuccessProps {
  message: TModalMessage;
  onContinue: TModalOnContinue;
}

const ModalSuccess = ({ message, onContinue }: IModalSuccessProps) => {
  return (
    <div className={`${modalStyles.container} ${successStyles.container}`}>
      {typeof message === "string" ? (
        <p className={modalStyles.message} dangerouslySetInnerHTML={{ __html: message }}></p>
      ) : (
        message
      )}
      <span className={`${modalStyles.separator} ${successStyles.separator}`}></span>
      <div className={modalStyles.buttonContainer}>
        <Button className={modalStyles.button} onClick={() => onContinue()}>
          OK
        </Button>
      </div>
    </div>
  );
};

export { ModalSuccess };
