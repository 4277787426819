import React from "react";
import { Button, Input, LinkButton } from "../../../../components/Form";

import styles from "./SupplierList.module.css";
import { TBody, THead, Table, Td, Th, Tr } from "../../../../components/Data/Table";
import { Pencil } from "phosphor-react";
import { Circle } from "../../../../components/Loading";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "../../../../hooks/async";
import { Paginate } from "../../../../components/Paginate/Paginate";

export function SupplierList({
  filteredSupplier,
  supplierList,
  setSelectedSupplier,
  searchSuppliers,
  searchTotalSupplierRecordsAndSuppliers,
  pagination,
}) {
  const navigate = useNavigate();

  const { current: searchSuppliersDebounced } = React.useRef(
    useDebounce((description = "") => {
      searchSuppliers(description);
    })
  );

  const onClickUpdate = React.useCallback(
    (supplier) => {
      setSelectedSupplier(supplier);
      navigate("atualizar");
    },
    [navigate, setSelectedSupplier]
  );

  React.useEffect(() => {
    setSelectedSupplier(null);
    if (!supplierList.value.length) searchTotalSupplierRecordsAndSuppliers();
  }, []); // eslint-disable-line

  return (
    <div className={`container`}>
      <div className={`${styles.navigationContainer}`}>
        <LinkButton to="nova">Novo Fornecedor</LinkButton>
      </div>
      <div>
        <label className={`label`} htmlFor="filteredSupplier">
          Filtrar Fornecedores
        </label>
        <Input
          id="filteredSupplier"
          placeholder="Busque por um fornecedor"
          value={filteredSupplier.value}
          onChange={({ target }) => {
            filteredSupplier.setValue(target.value);
            searchSuppliersDebounced(target.value);
          }}
        />
      </div>
      <div className={`${styles.dataContainer}`}>
        {supplierList.value.length && !supplierList.isSearching ? (
          <Table title="Fornecedores" className={styles.table}>
            <THead>
              <Tr>
                <Th width={"20px"}>ID</Th>
                <Th>Razão Social</Th>
                <Th width={"20px"}>Situação</Th>
                <Th width={"10px"}></Th>
              </Tr>
            </THead>
            <TBody>
              {supplierList.value.map((supplier, index) => {
                return (
                  <Tr key={index}>
                    <Td heading={"ID"}>{supplier.id}</Td>
                    <Td heading={"Razão Social"}>{supplier.nome}</Td>
                    <Td heading={"Situação"}>{supplier.status === "A" ? "Ativo" : "Inativo"}</Td>
                    <Td heading={"Editar"} data-option>
                      <Button
                        className={styles.editSupplierButton}
                        onClick={() => {
                          onClickUpdate(supplier);
                        }}
                        data-option-button>
                        <Pencil weight="fill" />
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </TBody>
          </Table>
        ) : supplierList.isSearching ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhum fornecedor encontrado</p>
        )}
        <Paginate
          classNameContainer={styles.paginationContainer}
          totalRecords={pagination.totalRecords}
          maxItems={pagination.maxItems}
          currentPage={pagination.currentPage}
          setCurrentPage={pagination.setCurrentPage}
          onPageChange={(page) => searchSuppliers(filteredSupplier.value, page - 1)}
        />
      </div>
    </div>
  );
}
