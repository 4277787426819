import React from "react";
import { Route, Routes } from "react-router-dom";
import { PaymentMethodList } from "./PaymentMethodList/PaymentMethodList";
import { useCustomFetch, useLoading } from "../../../hooks/async";
import { NewPaymentMethod } from "./NewPaymentMethod/NewPaymentMethod";
import { UpdatePaymentMethod } from "./UpdatePaymentMethod/UpdatePaymentMethod";
import { useModal } from "../../../hooks/contexts";
import { useForm } from "../../../hooks/form";
import { usePagination } from "../../../hooks/pagination";

export function RdvPaymentMethods() {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const pagination = usePagination(10);

  const searchingPaymentMethods = useLoading();

  const filteredPaymentMethod = useForm({ type: "", required: false });

  const [paymentMethodList, setPaymentMethodList] = React.useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(null);

  const searchPaymentMethodsPromise = React.useCallback(
    (description = "", page = 0) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/rdv/searchPaymentMethodsPaginated", {
            body: {
              pagina: page,
              tamanho: 10,
              descricao: description,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch]
  );

  const searchPaymentMethodRecordsPromise = React.useCallback(
    (description = "", page = 0) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/rdv/searchTotalPaymentMethodRecords", {
            body: {
              pagina: page,
              tamanho: 10,
              descricao: description,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch]
  );

  const searchPaymentMethods = React.useCallback(
    async (description = "", page) => {
      try {
        searchingPaymentMethods.setLoading(true);
        const json = await searchPaymentMethodsPromise(description, page);
        if (json.status === 200) {
          setPaymentMethodList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setPaymentMethodList([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingPaymentMethods.setLoading(false);
      }
    },
    [Modal, searchPaymentMethodsPromise, searchingPaymentMethods]
  );

  const searchTotalPaymentMethodRecordsAndPaymentMethods = React.useCallback(
    async (description = "") => {
      if (description === "") {
        filteredPaymentMethod.reset();
      }
      try {
        searchingPaymentMethods.setLoading(true);
        const jsonPaymentMethods = await searchPaymentMethodsPromise(description);
        if (jsonPaymentMethods.status === 200) {
          const jsonRecords = await searchPaymentMethodRecordsPromise(description);
          if (jsonRecords.status === 200) {
            setPaymentMethodList(jsonPaymentMethods.object);
            pagination.setTotalRecords(jsonRecords.object.total);
          } else if (jsonRecords.status === 500) {
            Modal.error(jsonRecords.message, jsonRecords.object);
          } else {
            setPaymentMethodList([]);
          }
        } else if (jsonPaymentMethods.status === 500) {
          Modal.error(jsonPaymentMethods.message, jsonPaymentMethods.object);
        } else {
          setPaymentMethodList([]);
          pagination.reset();
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        pagination.setCurrentPage(1);
        searchingPaymentMethods.setLoading(false);
      }
    },
    [
      Modal,
      filteredPaymentMethod,
      pagination,
      searchPaymentMethodsPromise,
      searchPaymentMethodRecordsPromise,
      searchingPaymentMethods,
    ]
  );

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <PaymentMethodList
              filteredPaymentMethod={filteredPaymentMethod}
              paymentMethodList={{
                value: paymentMethodList,
                setValue: setPaymentMethodList,
                isSearching: searchingPaymentMethods.isLoading,
              }}
              searchPaymentMethods={searchPaymentMethods}
              searchTotalPaymentMethodRecordsAndPaymentMethods={searchTotalPaymentMethodRecordsAndPaymentMethods}
              pagination={pagination}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
            />
          }
        />
        <Route
          path="nova"
          element={
            <NewPaymentMethod
              searchTotalPaymentMethodRecordsAndPaymentMethods={searchTotalPaymentMethodRecordsAndPaymentMethods}
            />
          }
        />
        <Route
          path="atualizar"
          element={
            <UpdatePaymentMethod
              selectedPaymentMethod={selectedPaymentMethod}
              searchPaymentMethods={(paymentMethod) => searchPaymentMethods(paymentMethod, pagination.currentPage - 1)}
              filteredPaymentMethod={filteredPaymentMethod.value}
            />
          }
        />
      </Routes>
    </div>
  );
}
