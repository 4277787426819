import React from "react";
import { Button, Input, Select } from "../../../components/Form";
import useReplicateValidity from "./hooks/useReplicateValidity";
import { Circle } from "../../../components/Loading";
import { Collapse } from "../../../components/Collapse/Collapse";

import styles from "./ReplicateValidity.module.css";

export function ProductReplicateValidity() {
  const {
    refs: { modalReplicationRef },
    data: {
      isFoliarTable,
      allItemsChecked,
      validityTableOptions,
      categoryOptions,
      validityTable,
      validityItems,
      isCampaign,
    },
    actions: {
      searchValidityTableOptionsDebounced,
      changeItem,
      setAllItemsState,
      replicateValidityAndTheirItems,
    },
    loadings: {
      searchingValidityTableOptions,
      searchingValidityItems,
      replicatingValidity,
      searchingCategoryOptions,
    },
  } = useReplicateValidity();

  return (
    <>
      <div className="container">
        {!replicatingValidity ? (
          <>
            <div>
              <label htmlFor="validityTable" className="label">
                Tabela de Vigência
              </label>
              <Select
                id="validityTable"
                placeholder="Selecione a tabela de vigência"
                options={
                  !searchingValidityTableOptions ? validityTableOptions : []
                }
                value={validityTable.value}
                error={validityTable.error}
                onChange={validityTable.onChange}
                onBlur={validityTable.onBlur}
                onInputChange={(description) => {
                  searchValidityTableOptionsDebounced(description);
                }}
                isLoading={searchingValidityTableOptions}
              />
            </div>
            <span className="separator" />
            <div className={styles.itemListContainer}>
              {validityItems.value.length > 0 && !searchingValidityItems ? (
                <>
                  <div className={styles.checksContainer}>
                    <label className={`label ${styles.checkSelectAll}`}>
                      <input
                        type="checkbox"
                        checked={allItemsChecked}
                        onChange={(e) => {
                          setAllItemsState(
                            e.target.checked ? "checked" : "unchecked"
                          );
                        }}
                      />{" "}
                      Selecionar todos
                    </label>
                    <label className={`label ${styles.checkSelectAll}`}>
                      <input
                        type="checkbox"
                        checked={isCampaign.value}
                        onChange={(e) => {
                          isCampaign.setValue(e.target.checked);
                        }}
                      />{" "}
                      Campanha
                    </label>
                  </div>
                  {validityItems.value.map((item, index) => (
                    <div key={index} className={styles.itemContainer}>
                      <label className={styles.checkContainer}>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            changeItem(item, e.target.checked, "check");
                          }}
                          checked={item.checkItem}
                        />
                      </label>
                      <div className={styles.item}>
                        <Collapse
                          label={<>{item.formulado.descricaoFormulado}</>}
                          key={undefined}
                          className={undefined}
                          classNameMainContainer={styles.mainCollapseContent}
                          classNameButton={styles.clickableCollapseArea}
                          iconColor={"var(--gray-8)"}
                        >
                          <>
                            <span
                              className="separator"
                              style={{ margin: "0 5px", width: "auto" }}
                            />
                            <div className={styles.formContainer}>
                              <div data-grid="costPrice">
                                <label htmlFor="costPrice" className="label">
                                  Preço de Custo (R$)
                                </label>
                                <Input
                                  id="costPrice"
                                  type="number"
                                  className="cleanInputNumber"
                                  placeholder="Digite o preço de custo do item"
                                  value={item.itensTabela.precoCusto}
                                  onChange={(e) => {
                                    changeItem(
                                      item,
                                      e.target.value,
                                      "costPrice"
                                    );
                                  }}
                                />
                              </div>
                              <div data-grid="sellPrice">
                                <label htmlFor="sellPrice" className="label">
                                  Preço de Venda (R$)
                                </label>
                                <Input
                                  id="sellPrice"
                                  type="number"
                                  className="cleanInputNumber"
                                  placeholder="Digite o preço de venda do item"
                                  value={item.itensTabela.precoOriginal}
                                  onChange={(e) => {
                                    changeItem(
                                      item,
                                      e.target.value,
                                      "sellPrice"
                                    );
                                  }}
                                />
                              </div>
                              {isFoliarTable && (
                                <>
                                  <div data-grid="category">
                                    <label htmlFor="category" className="label">
                                      Categoria
                                    </label>
                                    <Select
                                      id="category"
                                      placeholder="Selecione a categoria"
                                      options={categoryOptions}
                                      value={
                                        categoryOptions.find(
                                          (option) =>
                                            option.value ===
                                            item.itensTabela.categoria
                                        ) || null
                                      }
                                      onChange={(option) => {
                                        changeItem(
                                          item,
                                          option.value,
                                          "category"
                                        );
                                      }}
                                      isSearchable={false}
                                      isLoading={searchingCategoryOptions}
                                    />
                                  </div>
                                  <div data-grid="ggf">
                                    <label htmlFor="ggf" className="label">
                                      GGF (%)
                                    </label>
                                    <Input
                                      id="ggf"
                                      type="number"
                                      className="cleanInputNumber"
                                      placeholder="Digite o GGF"
                                      value={item.itensTabela.ggf}
                                      onChange={(e) => {
                                        changeItem(item, e.target.value, "ggf");
                                      }}
                                    />
                                  </div>
                                  <div data-grid="packaging">
                                    <label
                                      htmlFor="packaging"
                                      className="label"
                                    >
                                      Embalagem (%)
                                    </label>
                                    <Input
                                      id="packaging"
                                      type="number"
                                      className="cleanInputNumber"
                                      placeholder="Digite a embalagem"
                                      value={item.itensTabela.embalagem}
                                      onChange={(e) => {
                                        changeItem(
                                          item,
                                          e.target.value,
                                          "packaging"
                                        );
                                      }}
                                    />
                                  </div>
                                  <div data-grid="profitMargin">
                                    <label
                                      htmlFor="profitMargin"
                                      className="label"
                                    >
                                      Margem Sugerida (%)
                                    </label>
                                    <Input
                                      id="profitMargin"
                                      type="number"
                                      className="cleanInputNumber"
                                      placeholder="Digite a margem sugerida"
                                      value={item.itensTabela.margemSugerida}
                                      onChange={(e) => {
                                        changeItem(
                                          item,
                                          e.target.value,
                                          "profitMargin"
                                        );
                                      }}
                                    />
                                  </div>
                                  <div data-grid="icms">
                                    <label htmlFor="icms" className="label">
                                      ICMS (%)
                                    </label>
                                    <Input
                                      id="icms"
                                      type="number"
                                      className="cleanInputNumber"
                                      placeholder="Digite a porcentagem de ICMS"
                                      value={item.itensTabela.icms}
                                      onChange={(e) => {
                                        changeItem(
                                          item,
                                          e.target.value,
                                          "icms"
                                        );
                                      }}
                                    />
                                  </div>
                                  <div data-grid="freight">
                                    <label htmlFor="freight" className="label">
                                      Frete (%)
                                    </label>
                                    <Input
                                      id="freight"
                                      type="number"
                                      className="cleanInputNumber"
                                      placeholder="Digite a porcentagem de frete"
                                      value={item.itensTabela.frete}
                                      onChange={(e) => {
                                        changeItem(
                                          item,
                                          e.target.value,
                                          "freight"
                                        );
                                      }}
                                    />
                                  </div>
                                  <div data-grid="premiation">
                                    <label
                                      htmlFor="premiation"
                                      className="label"
                                    >
                                      Premiação (%)
                                    </label>
                                    <Input
                                      id="premiation"
                                      type="number"
                                      className="cleanInputNumber"
                                      placeholder="Digite a premiação"
                                      value={item.itensTabela.premiacao}
                                      onChange={(e) => {
                                        changeItem(
                                          item,
                                          e.target.value,
                                          "premiation"
                                        );
                                      }}
                                    />
                                  </div>
                                  <div data-grid="grossMargin">
                                    <label
                                      htmlFor="grossMargin"
                                      className="label"
                                    >
                                      Margem Bruta (%)
                                    </label>
                                    <Input
                                      id="grossMargin"
                                      type="number"
                                      className="cleanInputNumber"
                                      placeholder="Digite a margem bruta"
                                      value={item.itensTabela.margemBruta}
                                      onChange={(e) => {
                                        changeItem(
                                          item,
                                          e.target.value,
                                          "grossMargin"
                                        );
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        </Collapse>
                      </div>
                    </div>
                  ))}
                  <div className={styles.saveButtonContainer}>
                    <Button
                      className={styles.saveButton}
                      onClick={() => {
                        modalReplicationRef.current?.showModal();
                      }}
                    >
                      Replicar
                    </Button>
                  </div>
                </>
              ) : searchingValidityItems ? (
                <div className={`loadingContainer`}>
                  <Circle size={100} />
                </div>
              ) : (
                <p className="lineCardMessage">
                  Selecione uma tabela com itens
                </p>
              )}
            </div>
          </>
        ) : (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
            <span className="loadingMessage">Replicando Vigência</span>
          </div>
        )}
      </div>
      <dialog
        className={`modal ${styles.modalReplicate}`}
        ref={modalReplicationRef}
      >
        <div className="modalContent">
          <h1 className={styles.modalReplicate__title}>
            Deseja aplicar a vigência replicada na tabela de preços atual ou
            apenas replicar a tabela?
          </h1>
          <span className="separator" />
          <div className={styles.modalReplicate__buttonsContainer}>
            <Button
              className={styles.modalReplicate__buttonsContainer__button}
              onClick={() => {
                replicateValidityAndTheirItems("S");
                modalReplicationRef.current?.close();
              }}
            >
              Replicar e Aplicar
            </Button>
            <Button
              className={styles.modalReplicate__buttonsContainer__button}
              onClick={() => {
                replicateValidityAndTheirItems("N");
                modalReplicationRef.current?.close();
              }}
            >
              Apenas Replicar
            </Button>
            <Button
              className={styles.modalReplicate__buttonsContainer__button}
              variant="danger"
              onClick={() => {
                modalReplicationRef.current?.close();
              }}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </dialog>
    </>
  );
}
