import React from "react";
import { IUsePenalidades } from "../hooks/types";
import styles from "./Lista.module.css";
import { LinkButton } from "../../../../components/Form";
import { Table } from "../../../../components/Data/XTable";
import { Circle } from "../../../../components/Loading";

interface IProps {
  penalidadesData: IUsePenalidades;
}

export function Lista({ penalidadesData }: IProps) {
  const {
    selectedPenalidade,
    data: { tableData },
    searchs: { searchingPenalidades },
  } = penalidadesData;

  React.useEffect(() => {
    selectedPenalidade.setValue(null);
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.navContainer}>
        <LinkButton to="/contratos-fornecedor-cliente/penalidades/nova" className={styles.navButton}>
          Nova Penalidade
        </LinkButton>
      </div>
      <div className={styles.dataContainer}>
        {tableData.data.length && !searchingPenalidades ? (
          <div className={`${styles.tableContainer} customScrollbar`}>
            <Table tableData={tableData} />
          </div>
        ) : searchingPenalidades ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhuma Penalidade Encontrada</p>
        )}
      </div>
    </div>
  );
}
