import React from "react";

import { ISolicitation } from "../types";
import { Button, LinkButton } from "../../../../../components/Form";
import { useContractData } from "../../../components/hooks/useContractData";
import { useContractSellers } from "../../../components/hooks/useContractSellers";
import { useContractSupervisors } from "../../../components/hooks/useContractSupervisors";
import { useAnalysis } from "./hooks/useAnalysis";
import { ContractConference } from "../../../components/ContractConference/ContractConference";
import { ContractData } from "../../../components/ContractData/ContractData";
import { ContractSellers } from "../../../components/ContractSellers/ContractSellers";
import { ContractSupervisors } from "../../../components/ContractSupervisors/ContractSupervisors";
import { Circle } from "../../../../../components/Loading";
import { Navigate } from "react-router-dom";
import { ContractTableAnalysis } from "../../../components/ContractTableAnalysis/ContractTableAnalysis";
import { useContractTableAnalysis } from "../../../components/hooks/useContractTableAnalysis";
import { useChanges, useModal } from "../../../../../hooks/contexts";
import { useSelectOptions } from "../../../components/hooks/useSelectOptions";
import { useContractPremiations } from "../../../components/hooks/useContractPremiations";
import { ContractPremiations } from "../../../components/ContractPremiations/ContractPremiations";
import { IUseSolicitationList } from "../hooks/types";

import styles from "./Analysis.module.css";
import { SolicitationStatus } from "../../../types";
import {
  ExcelPermissions,
  ExcelType,
  UseContractExcel,
} from "../../../hooks/useContractExcel";
import { PageType } from "../../../components/types";
import { isValid } from "../../../../../helpers/validations";

interface Props {
  selectedSolicitation: ISolicitation | null;
  solicitationList: IUseSolicitationList;
  contractExcel: UseContractExcel;
}

export function Analysis({
  selectedSolicitation,
  solicitationList,
  contractExcel,
}: Props) {
  const Modal = useModal();
  const ChangesDetected = useChanges();

  const colorOptions = React.useState<ISelectOption<string>[]>([]);

  const contractData = useContractData();
  const contractPremiations = useContractPremiations();
  const contractSellers = useContractSellers();
  const contractSupervisors = useContractSupervisors();
  const contractTable = useContractTableAnalysis();

  const { sellerOptions, supervisorOptions, tableOptions, customerOptions } =
    useSelectOptions();

  const analysis = useAnalysis({
    contractData,
    contractPremiations,
    contractSellers,
    contractSupervisors,
    contractTable,
    solicitationList,
  });

  const modalExcelType = React.useRef<HTMLDialogElement>(null);

  React.useLayoutEffect(() => {
    if (!selectedSolicitation) return;
    analysis.getSolicitationData(selectedSolicitation.idSolicitacao);
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (!selectedSolicitation) return;
    contractPremiations.getPremiationTypes.handle();
  }, []); // eslint-disable-line

  if (!selectedSolicitation)
    return <Navigate to={"/contratos/comercial/solicitacoes-pendentes"} />;

  return (
    <>
      <div className="container">
        {!contractExcel.exportingExcel ? (
          <>
            {!analysis.gettingSolicitationData &&
            !analysis.savingReview &&
            !analysis.approvingSolicitation &&
            !analysis.disapprovingSolicitation ? (
              <>
                <div className={styles.navContainer}>
                  <div>
                    <LinkButton
                      to="/contratos/comercial/solicitacoes-pendentes"
                      buttonStyle="backButton"
                      disabled={analysis.gettingSolicitationData}
                    />
                  </div>
                  <h2>
                    {selectedSolicitation.idSolicitacao} |{" "}
                    {selectedSolicitation.descricaoContrato}
                  </h2>
                  <div />
                </div>
                <span className="separator" />
                {analysis.solicitationData ? (
                  <div className={styles.conferenceContainer}>
                    <ContractConference
                      pages={[
                        {
                          name: "Dados do contrato",
                          page: (
                            <ContractData
                              data={contractData}
                              premiations={contractPremiations}
                              tableOptions={tableOptions}
                              customerOptions={customerOptions}
                              page={PageType.COMERCIAL}
                              permissions={analysis.permissions}
                              changesDetected={ChangesDetected}
                            />
                          ),
                        },
                        contractData.payPremiation.value?.value && {
                          name: "Tipos de Premiação",
                          page: (
                            <ContractPremiations
                              data={contractPremiations}
                              permissions={analysis.permissions}
                              page={PageType.COMERCIAL}
                              changesDetected={ChangesDetected}
                            />
                          ),
                        },
                        {
                          name: "Gerentes",
                          page: (
                            <ContractSupervisors
                              data={contractSupervisors}
                              sellers={{
                                data: contractSellers,
                                options: sellerOptions,
                              }}
                              supervisorOptions={supervisorOptions}
                              page={PageType.COMERCIAL}
                              permissions={analysis.permissions}
                            />
                          ),
                        },
                        {
                          name: "Vendedores",
                          page: (
                            <ContractSellers
                              data={contractSellers}
                              supervisors={contractSupervisors.supervisors.value.map(
                                (i) => i.value.id
                              )}
                              sellerOptions={sellerOptions}
                              page={PageType.COMERCIAL}
                              permissions={analysis.permissions}
                            />
                          ),
                        },
                        !ChangesDetected.isChangesDetected &&
                        Number(selectedSolicitation.status) ===
                          Number(SolicitationStatus.Revisado)
                          ? {
                              name: "Formulado do Contrato",
                              page: (
                                <ContractTableAnalysis
                                  data={contractTable}
                                  table={contractData.table}
                                  colorOptions={colorOptions}
                                  interestCalculated={
                                    contractData.interestCalculated[0]
                                  }
                                  calculatingInterest={
                                    contractData.changeInterestRelated
                                      .calculatingInterest
                                  }
                                  disabled
                                />
                              ),
                            }
                          : null,
                      ]}
                    />
                    {analysis.permissions.includes(
                      "CONTRATO_SOLICITACAO_APROVA_REVISAO"
                    ) &&
                      (ChangesDetected.isChangesDetected ||
                        Number(selectedSolicitation.status) ===
                          Number(SolicitationStatus.AprovadoCliente)) && (
                        <div className={styles.decisionContainer}>
                          <Button
                            style={{ margin: "auto" }}
                            variant="edit"
                            onClick={async () => {
                              if (!isValid(contractData.deliveryDoc)) {
                                Modal.error(
                                  `<p>
                                      O campo Entrega Documentação deve ser
                                      preenchido.
                                    </p>
                                    <br />
                                    <p>
                                      Caso não tenha permissão para alterar, por
                                      favor contate o suporte.
                                    </p>
                                  `
                                );

                                return;
                              }

                              if (
                                !(await Modal.confirm(
                                  ChangesDetected.isChangesDetected
                                    ? "Tem certeza que deseja salvar essa revisão?"
                                    : "Tem certeza que deseja marcar essa solicitação como revisada?"
                                ))
                              )
                                return;

                              analysis.handleSubmit.saveReview(
                                ChangesDetected,
                                selectedSolicitation
                              );
                            }}
                          >
                            {ChangesDetected.isChangesDetected
                              ? "Salvar Revisão"
                              : "Marcar Revisado"}
                          </Button>
                        </div>
                      )}
                    {!ChangesDetected.isChangesDetected &&
                      Number(selectedSolicitation.status) ===
                        Number(SolicitationStatus.Revisado) && (
                        <div className={styles.decisionContainer}>
                          <>
                            <Button
                              variant="danger"
                              onClick={async () => {
                                if (
                                  !(await Modal.confirm(
                                    "Tem certeza que deseja recusar a solicitação?"
                                  ))
                                )
                                  return;
                                analysis.handleSubmit.disapprove();
                              }}
                            >
                              Recusar
                            </Button>
                            <Button
                              variant="neutral"
                              onClick={() => {
                                const permissionsAllowed =
                                  contractExcel.getExcelPermissions();
                                if (permissionsAllowed.permissions.length > 1) {
                                  modalExcelType.current?.show();
                                } else if (
                                  permissionsAllowed.permissions.length === 1
                                ) {
                                  contractExcel.exportExcel(
                                    selectedSolicitation.idSolicitacao,
                                    permissionsAllowed.type!!
                                  );
                                }
                              }}
                              className={styles.exportExcelButton}
                            >
                              Exportar Faixa Padrão Para Excel
                            </Button>
                            <Button
                              onClick={async () => {
                                if (
                                  !(await Modal.confirm(
                                    "Tem certeza que deseja aprovar a solicitação?"
                                  ))
                                )
                                  return;
                                analysis.handleSubmit.approve();
                              }}
                            >
                              Aprovar
                            </Button>
                          </>
                        </div>
                      )}
                  </div>
                ) : (
                  <p className={`lineCardMessage ${styles.withoutDataMessage}`}>
                    Nenhum dado foi encontrado
                  </p>
                )}
              </>
            ) : analysis.gettingSolicitationData ? (
              <div className={`loadingContainer ${styles.loadingContainer}`}>
                <Circle size={100} />
                <p className="loadingMessage">Carregando Dados</p>
              </div>
            ) : analysis.savingReview ? (
              <div className={`loadingContainer ${styles.loadingContainer}`}>
                <Circle size={100} />
                <p className="loadingMessage">Salvando Revisão</p>
              </div>
            ) : (
              <div className={`loadingContainer ${styles.loadingContainer}`}>
                <Circle size={100} />
                <p className="loadingMessage">
                  {analysis.approvingSolicitation ? "Aprovando" : "Recusando"}{" "}
                  Solicitação
                </p>
              </div>
            )}
          </>
        ) : (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
          </div>
        )}
      </div>
      <dialog className={`modal ${styles.modalExcelType}`} ref={modalExcelType}>
        <div className="modalContent">
          <div className={styles.modalExcelType__buttonsContainer}>
            {contractExcel.verifyPermission(ExcelPermissions.CLIENTE) && (
              <Button
                className={styles.modalExcelType__buttonsContainer__button}
                onClick={() => {
                  contractExcel.exportExcel(
                    selectedSolicitation.idSolicitacao,
                    ExcelType.MODELO_CLIENTE
                  );
                  modalExcelType.current?.close();
                }}
              >
                Modelo Cliente
              </Button>
            )}
            {contractExcel.verifyPermission(ExcelPermissions.ANALITICO) && (
              <Button
                className={styles.modalExcelType__buttonsContainer__button}
                onClick={() => {
                  contractExcel.exportExcel(
                    selectedSolicitation.idSolicitacao,
                    ExcelType.MODELO_ANALITICO
                  );
                  modalExcelType.current?.close();
                }}
              >
                Modelo Analítico
              </Button>
            )}
            {contractExcel.verifyPermission(ExcelPermissions.JURIDICO) && (
              <Button
                className={styles.modalExcelType__buttonsContainer__button}
                onClick={() => {
                  contractExcel.exportExcel(
                    selectedSolicitation.idSolicitacao,
                    ExcelType.MODELO_JURIDICO
                  );
                  modalExcelType.current?.close();
                }}
              >
                Modelo Jurídico
              </Button>
            )}
          </div>
          <Button
            className={styles.modalExcelType__buttonCancel}
            variant="danger"
            onClick={() => {
              modalExcelType.current?.close();
            }}
          >
            Cancelar
          </Button>
        </div>
      </dialog>
    </>
  );
}
