import styles from "./Table.module.css";

export function Table({
  className = "",
  containerClassName = "",
  title,
  children,
  responsiveType = "HeaderOnLeft",
  ...props
}) {
  return (
    <div className={`${styles.container} ${containerClassName}`}>
      {title && (
        <header className={styles.header}>
          <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
        </header>
      )}
      <div className={`${styles.tableContainer} responsiveType${responsiveType}`}>
        <table className={`${styles.table} ${className}`} {...props}>
          {children}
        </table>
      </div>
    </div>
  );
}
