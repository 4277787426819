import { Bell, Check } from "phosphor-react";
import React, { useState, useEffect } from "react";

import styles from "./Notifications.module.css";

const notifications = [
  "Novidades em Breve",
];

// const notifications: string[] = [];

export function Notifications() {
  const [showNotifications, setShowNotifications] = useState(false);

  const notificationsRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleOutsideClick(event: MouseEvent) {
      if (event.target instanceof HTMLElement && !notificationsRef.current?.contains(event.target)) {
        setShowNotifications(false);
        window.removeEventListener("click", handleOutsideClick);
      }
    }

    if (showNotifications) window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [showNotifications]);

  return (
    <div className={styles.container} ref={notificationsRef}>
      <div
        className={styles.indicator}
        onClick={(event) => {
          event.stopPropagation();
          setShowNotifications((current) => !current);
        }}>
        <div className={styles.iconContainer}>
          <Bell size={26} color="var(--gray-4)" />
          {notifications.length > 0 && (
            <span className={styles.notificationsIndicators}>
              {notifications.length <= 9 ? notifications.length : "9+"}
            </span>
          )}
        </div>
      </div>
      <div
        className={`${styles.notificationsContainer} customScrollbar`}
        style={{ display: showNotifications ? "block" : undefined }}>
        {notifications.map((notification) => (
          <div key={notification} className={styles.notification}>
            <p className={styles.notification__message}>{notification}</p>
            <button className={styles.notification__button}>
              <Check size={26} color="var(--green-2)" weight="bold" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
