import { useCallback, useMemo } from "react";
import { useForm, useSelect } from "../../../../hooks/form";
import { useModal } from "../../../../hooks/contexts";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { isValid } from "../../../../helpers/validations";
import { UseGruposPermissoes } from "./useGruposPermissoes";
import { useNavigate } from "react-router-dom";
import { usePreviousRoute } from "../../../../hooks/navigation";

interface Props {
  dadosGruposPermissoes: UseGruposPermissoes;
}

export function useInserirAtualizarGrupo({ dadosGruposPermissoes }: Props) {
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();
  const backRoute = usePreviousRoute();

  const statusOptions = useMemo<ISelectOption<string>[]>(
    () => [
      {
        label: "Ativo",
        value: "A",
      },
      {
        label: "Inativo",
        value: "I",
      },
    ],
    []
  );

  const nome = useForm({ required: true });
  const status = useSelect({
    type: "single",
    required: true,
    defaultValue: statusOptions[0],
  });

  const inserindoAtualizandoGrupo = useLoading();

  const inserirAtualizarGrupo = useCallback(
    async (idGrupo?: number) => {
      if (!isValid(nome, status)) return;
      try {
        inserindoAtualizandoGrupo.setLoading(true);
        const json = (await customFetch(
          "/admin/inserirAtualizarGrupoPermissoes",
          {
            body: {
              id: idGrupo || null,
              nome: nome.value.trim().toUpperCase(),
              status: status.value?.value,
            },
          }
        )) as DefaultFetchResponse<any>;
        if (json.status === 200) {
          dadosGruposPermissoes.dadosGrupos.buscarGrupos();
          await Modal.success(json.message);
          navigate(backRoute);
        } else {
          Modal.error(json.message, json.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        inserindoAtualizandoGrupo.setLoading(false);
      }
    },
    [
      Modal,
      backRoute,
      customFetch,
      dadosGruposPermissoes.dadosGrupos,
      inserindoAtualizandoGrupo,
      navigate,
      nome,
      status,
    ]
  );

  return {
    nome,
    status,
    inserirAtualizarGrupo,
    inserindoAtualizandoGrupo: inserindoAtualizandoGrupo.isLoading,
    statusOptions,
  };
}

export type UseInserirAtualizarGrupo = ReturnType<
  typeof useInserirAtualizarGrupo
>;
