import React from "react";
import { Navigate } from "react-router-dom";

import { Button, Input, LinkButton, Select } from "../../../../components/Form";
import { Circle } from "../../../../components/Loading";
import { formatMoney } from "../../../../helpers/format";
import { isValid } from "../../../../helpers/validations";
import {
  useCustomFetch,
  useDebounce,
  useLoading,
} from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { useForm, useSelect } from "../../../../hooks/form";
import { ModalEditPercents } from "./components/ModalEditPercents/ModalEditPercents";

import styles from "./ValidityTableItems.module.css";

export function ValidityTableItems({ selectedTable }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const isFoliarTable = React.useMemo(() => {
    return (
      selectedTable.value?.tabela?.cabecalhoTabela?.grupo &&
      selectedTable.value?.tabela?.cabecalhoTabela?.grupo?.descricaoGrupo ===
        "FOLIAR"
    );
  }, [selectedTable]);

  const [lastModifiedId, setLastModifiedId] = React.useState(null);

  const itemFilter = useForm({ required: false });

  const searchingItems = useLoading();
  const searchingItemOptions = useLoading();
  const searchingCategoryOptions = useLoading();
  const savingItems = useLoading();

  const modalEditPercentsRef = React.useRef();

  const [items, setItems] = React.useState([]);
  const [itemOptions, setItemOptions] = React.useState([]);

  const item = useSelect({ type: "single", required: true });
  const costPrice = useForm({ type: "number", required: true });
  const sellPrice = useForm({ type: "number", required: true });
  const category = useSelect({ type: "single", required: isFoliarTable });
  const ggf = useForm({ type: "number", required: isFoliarTable });
  const packaging = useForm({ type: "number", required: isFoliarTable });
  const profitMargin = useForm({ type: "number", required: isFoliarTable });
  const icms = useForm({ type: "number", required: isFoliarTable });
  const freight = useForm({ type: "number", required: false });
  const premiation = useForm({ type: "number", required: isFoliarTable });
  const grossMargin = useForm({ type: "number", required: isFoliarTable });

  const resetForm = React.useCallback(() => {
    item.reset();
    costPrice.reset();
    sellPrice.reset();
    category.reset();
    ggf.reset();
    packaging.reset();
    profitMargin.reset();
    icms.reset();
    freight.reset();
    premiation.reset();
    grossMargin.reset();
  }, [
    item,
    category,
    costPrice,
    ggf,
    grossMargin,
    icms,
    freight,
    packaging,
    premiation,
    profitMargin,
    sellPrice,
  ]);

  const [categoryOptions, setCategoryOptions] = React.useState([]);

  const isUpdatingItem = React.useCallback(() => {
    return items.some(
      (i) =>
        i.formulado.idFormulado === item.value?.value?.formulado.idFormulado
    );
  }, [item, items]);

  const searchCategoryOptions = React.useCallback(async () => {
    try {
      searchingCategoryOptions.setLoading(true);
      const json = await customFetch("/products/searchCategories", {
        body: {
          id: 0,
          categoria: "",
          status: "A",
        },
      });
      if (json.status === 200) {
        const options = json.object.map((category) => ({
          label: category.categoria,
          value: category.categoria,
        }));
        setCategoryOptions(options);
      } else {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingCategoryOptions.setLoading(false);
    }
  }, [Modal, customFetch, searchingCategoryOptions]);

  const searchItemOptions = React.useCallback(
    async (description = "") => {
      try {
        searchingItemOptions.setLoading(true);
        const json = await customFetch("/products/searchItemsToAddInValidity", {
          body: {
            idTabelaVigencia: selectedTable.value.tabela.id,
            descricao: description,
          },
        });
        if (json.status === 200) {
          const options = json.object.map((option) => {
            return {
              value: option,
              label: `${option.formulado.idFormulado} | ${option.formulado.descricaoFormulado}`,
            };
          });
          setItemOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingItemOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingItemOptions, selectedTable]
  );

  const searchItemOptionsDebounced = useDebounce(searchItemOptions);

  const searchValidityTableItems = React.useCallback(async () => {
    try {
      searchingItems.setLoading(true);
      const json = await customFetch("/products/searchValidityItems", {
        body: {
          idTabelaVigencia: selectedTable.value.tabela.id,
          descricao: "",
        },
      });
      if (json.status === 200) {
        setItems(json.object);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingItems.setLoading(false);
    }
  }, [Modal, customFetch, searchingItems, selectedTable]);

  const filterItemOptions = React.useCallback(() => {
    return itemOptions.filter(
      (option) =>
        !items.some(
          (item) =>
            item.formulado.idFormulado === option.value.formulado.idFormulado
        )
    );
  }, [itemOptions, items]);

  const handleClickEditItem = React.useCallback(
    (selectedItem) => {
      const selected = {
        value: selectedItem,
        label: `${selectedItem.formulado.idFormulado} | ${selectedItem.formulado.descricaoFormulado}`,
      };

      if (isFoliarTable) {
        item.setValue(selected);
        costPrice.setValue(selectedItem.itensTabela.precoCusto);
        sellPrice.setValue(selectedItem.itensTabela.precoOriginal);
        category.setValue({
          label: selectedItem.itensTabela.categoria,
          value: selectedItem.itensTabela.categoria,
        });
        ggf.setValue(selectedItem.itensTabela.ggf);
        packaging.setValue(selectedItem.itensTabela.embalagem);
        profitMargin.setValue(selectedItem.itensTabela.margemSugerida);
        icms.setValue(selectedItem.itensTabela.icms);
        freight.setValue(selectedItem.itensTabela.frete | 0);
        premiation.setValue(selectedItem.itensTabela.premiacao);
        grossMargin.setValue(selectedItem.itensTabela.margemBruta);
      } else {
        item.setValue(selected);
        costPrice.setValue(selectedItem.itensTabela.precoCusto);
        sellPrice.setValue(selectedItem.itensTabela.precoOriginal);
      }

      document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
    },
    [
      isFoliarTable,
      item,
      costPrice,
      sellPrice,
      category,
      ggf,
      packaging,
      profitMargin,
      icms,
      freight,
      premiation,
      grossMargin,
    ]
  );

  const handleSaveItem = React.useCallback(
    (
      item,
      costPrice,
      sellPrice,
      category,
      ggf,
      packaging,
      profitMargin,
      icms,
      freight,
      premiation,
      grossMargin
    ) => {
      return new Promise(async (resolve, reject) => {
        let ok = false;
        try {
          savingItems.setLoading(true);
          const json = await customFetch(
            "/products/registerAndUpdateValidityItems",
            {
              body: {
                idTabelaVigencia: selectedTable.value.tabela.id,
                idItemTabela:
                  item.itemTabela?.idItemTabela ||
                  item.itensTabela?.idItemTabela,
                idProduto: item.formulado.idFormulado,
                precoCusto: costPrice,
                precoOriginal: sellPrice,
                categoria: category,
                ggf: ggf,
                frete: freight,
                embalagem: packaging,
                margemSugerida: profitMargin,
                icms: icms,
                premiacao: premiation,
                margemBruta: grossMargin,
              },
            }
          );
          if (json.status === 200) {
            await Modal.success(json.message);
            ok = true;
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          savingItems.setLoading(false);
          resolve(ok);
        }
      });
    },
    [Modal, customFetch, savingItems, selectedTable]
  );

  const handleAddItem = React.useCallback(async () => {
    let params;
    if (isFoliarTable) {
      if (
        !isValid(
          item,
          costPrice,
          sellPrice,
          category,
          ggf,
          packaging,
          profitMargin,
          icms,
          freight,
          premiation,
          grossMargin
        )
      )
        return;
      params = [
        item.value.value,
        Number(costPrice.value),
        Number(sellPrice.value),
        category.value?.value,
        Number(ggf.value),
        Number(packaging.value),
        Number(profitMargin.value),
        Number(icms.value),
        Number(freight.value),
        Number(premiation.value),
        Number(grossMargin.value),
      ];
    } else {
      if (!isValid(item, costPrice, sellPrice)) return;
      params = [
        item.value.value,
        Number(costPrice.value),
        Number(sellPrice.value),
      ];
    }

    const confirm = await Modal.confirm(
      "Tem certeza que deseja adicionar este item à tabela?"
    );
    if (confirm) {
      const success = await handleSaveItem(...params);

      if (success) {
        if (!isUpdatingItem()) {
          setLastModifiedId(null);
        }

        searchValidityTableItems();

        resetForm();
      }
    }
  }, [
    Modal,
    isFoliarTable,
    handleSaveItem,
    item,
    costPrice,
    sellPrice,
    category,
    ggf,
    packaging,
    profitMargin,
    icms,
    freight,
    premiation,
    searchValidityTableItems,
    resetForm,
    grossMargin,
    isUpdatingItem,
  ]);

  React.useEffect(() => {
    if (selectedTable.value) {
      searchValidityTableItems();
      searchCategoryOptions();
      searchItemOptions();
    }
  }, []); // eslint-disable-line

  if (!selectedTable.value) {
    return <Navigate to="/produtos/tabelas-de-vigencia" />;
  }

  return (
    <>
      <div className="container">
        <div className={styles.navigationContainer}>
          <LinkButton
            to="/produtos/tabelas-de-vigencia"
            buttonStyle="backButton"
          />
          <h2>{selectedTable.value.tabela.nome}</h2>
          <span></span>
        </div>
        <div className={styles.fieldsContainer}>
          <div data-grid="item">
            <label htmlFor="item" className="label">
              Item
            </label>
            <Select
              id="item"
              placeholder="Selecione um item para adicionar"
              options={filterItemOptions()}
              value={item.value}
              error={item.error}
              onChange={(value) => {
                if (!value) {
                  resetForm();
                }
                item.onChange(value);
              }}
              onInputChange={searchItemOptionsDebounced}
              isLoading={searchingItemOptions.isLoading}
            />
          </div>
          <div data-grid="costPrice">
            <label htmlFor="costPrice" className="label">
              Preço de Custo (R$)
            </label>
            <Input
              id="costPrice"
              type="number"
              className="cleanInputNumber"
              placeholder="Digite o preço de custo do item"
              value={costPrice.value}
              error={costPrice.error}
              onChange={costPrice.onChange}
            />
          </div>
          <div data-grid="sellPrice">
            <label htmlFor="sellPrice" className="label">
              Preço de Venda (R$)
            </label>
            <Input
              id="sellPrice"
              type="number"
              className="cleanInputNumber"
              placeholder="Digite o preço de venda do item"
              value={sellPrice.value}
              error={sellPrice.error}
              onChange={sellPrice.onChange}
            />
          </div>
          {isFoliarTable && (
            <>
              <div data-grid="category">
                <label htmlFor="category" className="label">
                  Categoria
                </label>
                <Select
                  id="category"
                  placeholder="Selecione a categoria"
                  options={categoryOptions}
                  value={category.value}
                  error={category.error}
                  onChange={category.onChange}
                  isSearchable={false}
                  isLoading={searchingCategoryOptions.isLoading}
                />
              </div>
              <div data-grid="ggf">
                <label htmlFor="ggf" className="label">
                  GGF (%)
                </label>
                <Input
                  id="ggf"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite o GGF"
                  value={ggf.value}
                  error={ggf.error}
                  onChange={ggf.onChange}
                />
              </div>
              <div data-grid="packaging">
                <label htmlFor="packaging" className="label">
                  Embalagem (%)
                </label>
                <Input
                  id="packaging"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite a embalagem"
                  value={packaging.value}
                  error={packaging.error}
                  onChange={packaging.onChange}
                />
              </div>
              <div data-grid="profitMargin">
                <label htmlFor="profitMargin" className="label">
                  Margem Sugerida (%)
                </label>
                <Input
                  id="profitMargin"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite a margem sugerida"
                  value={profitMargin.value}
                  error={profitMargin.error}
                  onChange={profitMargin.onChange}
                />
              </div>
              <div data-grid="freight">
                <label htmlFor="freight" className="label">
                  Frete (%)
                </label>
                <Input
                  id="freight"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite a porcentagem de frete"
                  value={freight.value}
                  error={freight.error}
                  onChange={freight.onChange}
                />
              </div>
              <div data-grid="icms">
                <label htmlFor="icms" className="label">
                  ICMS (%)
                </label>
                <Input
                  id="icms"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite a porcentagem de ICMS"
                  value={icms.value}
                  error={icms.error}
                  onChange={icms.onChange}
                />
              </div>
              <div data-grid="premiation">
                <label htmlFor="premiation" className="label">
                  Premiação
                </label>
                <Input
                  id="premiation"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite a premiação"
                  value={premiation.value}
                  error={premiation.error}
                  onChange={premiation.onChange}
                />
              </div>
              <div data-grid="grossMargin">
                <label htmlFor="grossMargin" className="label">
                  Margem Bruta (%)
                </label>
                <Input
                  id="grossMargin"
                  type="number"
                  className="cleanInputNumber"
                  placeholder="Digite a margem bruta"
                  value={grossMargin.value}
                  error={grossMargin.error}
                  onChange={grossMargin.onChange}
                />
              </div>
            </>
          )}
          <div data-grid="buttonSubmit">
            <Button className={styles.addItemButton} onClick={handleAddItem}>
              {isUpdatingItem() ? "Atualizar Item" : "Adicionar Item"}
            </Button>
          </div>
        </div>
        <span className="separator" />
        <div className={styles.dataContainer}>
          {items.length &&
          !searchingItems.isLoading &&
          !savingItems.isLoading ? (
            <>
              <div>
                <label htmlFor="itemFilter" className="label">
                  Filtrar Item
                </label>
                <Input
                  id="itemFilter"
                  placeholder="Digite um item para filtrar"
                  value={itemFilter.value}
                  onChange={itemFilter.onChange}
                />
              </div>
              <div className={styles.itensContainer}>
                <ul>
                  {items
                    .filter((item) => {
                      if (itemFilter.value) {
                        return item.formulado.descricaoFormulado.includes(
                          itemFilter.value
                        );
                      }
                      return true;
                    })
                    .map((item, index) => (
                      <li
                        className={`${styles.item} ${
                          item.formulado.idFormulado === lastModifiedId
                            ? styles.item_selected
                            : ""
                        }`}
                        key={index}
                      >
                        <div>
                          <div>
                            <span className={styles.item__name}>
                              {item.formulado.descricaoFormulado}
                            </span>
                          </div>
                          <ul className={styles.item__prices}>
                            <li>
                              Preço de Venda:{" "}
                              {formatMoney(item.itensTabela.precoOriginal)}
                            </li>
                          </ul>
                          <span className={styles.item_buttonSeparator}></span>
                          <div className={styles.editButtonsContainer}>
                            <Button
                              variant="neutral"
                              className={styles.item_button}
                              onClick={() => {
                                setLastModifiedId(item.formulado.idFormulado);
                                handleClickEditItem(item);
                              }}
                            >
                              Editar Item
                            </Button>
                            {isFoliarTable && (
                              <Button
                                variant="neutral"
                                className={styles.item_button}
                                onClick={() => {
                                  setLastModifiedId(item.formulado.idFormulado);
                                  modalEditPercentsRef.current.show(
                                    item.itensTabela.id
                                  );
                                }}
                              >
                                Editar Percentuais
                              </Button>
                            )}
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </>
          ) : searchingItems.isLoading ? (
            <div className={`loadingContainer`}>
              <Circle size={100} />
              <span className="loadingMessage">Buscando Itens da Tabela</span>
            </div>
          ) : savingItems.isLoading ? (
            <div className={`loadingContainer`}>
              <Circle size={100} />
              <span className="loadingMessage">Salvando Itens na Tabela</span>
            </div>
          ) : (
            <p className={`lineCardMessage`}>
              Nenhum item encontrado na tabela
            </p>
          )}
        </div>
      </div>
      <ModalEditPercents ref={modalEditPercentsRef} />
    </>
  );
}
