import React from "react";
import { Route, Routes } from "react-router-dom";
import { useCustomFetch, useLoading } from "../../../hooks/async";
import { useModal } from "../../../hooks/contexts";
import { usePagination } from "../../../hooks/pagination";
import { NewPeopleCategory } from "./NewPeopleCategory/NewPeopleCategory";
import { PeopleCategoriesList } from "./PeopleCategoriesList/PeopleCategoriesList";
import { UpdatePeopleCategory } from "./UpdatePeopleCategory/UpdatePeopleCategory";

export interface IPeopleCategory {
  idCategoriaPessoa: number;
  nomeCategoriaPessoa: string;
  status: string;
  dataManutencao: string;
}

interface ISearchTotalPeopleCategoriesRecordsResponse {
  response: number;
  message: string;
  status: number;
  object: {
    paginas: number;
    total: number;
  };
}

export interface ISearchPeopleCategoriesResponse {
  response: number;
  message: string;
  status: number;
  object: IPeopleCategory[];
}

export function SettingsPeopleCategories() {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const pagination = usePagination();

  const [selectedPeopleCategory, setSelectedPeopleCategory] = React.useState<IPeopleCategory | null>(null);
  const [peopleCategoriesList, setPeopleCategoriesList] = React.useState<IPeopleCategory[]>([]);

  const searchingPeopleCategories = useLoading();

  const searchTotalPeopleCategoriesRecords = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const json = await customFetch("/orderPermissions/searchTotalPeopleCategoriesRecords", {
          body: {
            tamanho: pagination.maxItems,
          },
        });
        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
  }, [customFetch, pagination]);

  const searchPeopleCategories = React.useCallback(
    (page: number = 0) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = customFetch("/orderPermissions/searchPeopleCategories", {
            body: {
              pagina: page ? Number(page) - 1 : 0,
              tamanho: pagination.maxItems,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const searchTotalPeopleCategoriesRecordsAndPeopleCategories = React.useCallback(async () => {
    try {
      searchingPeopleCategories.setLoading(true);
      const jsonPeopleCategories = (await searchPeopleCategories()) as ISearchPeopleCategoriesResponse;
      if (jsonPeopleCategories.status === 200) {
        const jsonRecords = (await searchTotalPeopleCategoriesRecords()) as ISearchTotalPeopleCategoriesRecordsResponse;
        if (jsonRecords.status === 200) {
          setPeopleCategoriesList(jsonPeopleCategories.object);
          pagination.setTotalRecords(jsonRecords.object.total);
        } else if (jsonRecords.status === 500) {
          Modal.error(jsonRecords.message, jsonRecords.object);
        } else {
          setPeopleCategoriesList([]);
        }
      } else if (jsonPeopleCategories.status === 500) {
        Modal.error(jsonPeopleCategories.message, jsonPeopleCategories.object);
      } else {
        setPeopleCategoriesList([]);
        pagination.reset();
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      pagination.setCurrentPage(1);
      searchingPeopleCategories.setLoading(false);
    }
  }, [Modal, pagination, searchPeopleCategories, searchTotalPeopleCategoriesRecords, searchingPeopleCategories]);

  React.useEffect(() => {
    searchTotalPeopleCategoriesRecordsAndPeopleCategories();
  }, []); // eslint-disable-line

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PeopleCategoriesList
            peopleCategories={{
              value: peopleCategoriesList,
              setValue: setPeopleCategoriesList,
            }}
            searchingPeopleCategories={searchingPeopleCategories}
            searchPeopleCategories={searchPeopleCategories}
            setSelectedPeopleCategory={setSelectedPeopleCategory}
            pagination={pagination}
          />
        }
      />
      <Route
        path="nova"
        element={
          <NewPeopleCategory
            searchTotalPeopleCategoriesRecordsAndPeopleCategories={
              searchTotalPeopleCategoriesRecordsAndPeopleCategories
            }
          />
        }
      />
      <Route
        path="atualizar"
        element={
          <UpdatePeopleCategory
            searchTotalPeopleCategoriesRecordsAndPeopleCategories={
              searchTotalPeopleCategoriesRecordsAndPeopleCategories
            }
            selectedPeopleCategory={selectedPeopleCategory}
          />
        }
      />
    </Routes>
  );
}
