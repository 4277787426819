/**
 * Verifica se o valor passado é um objeto JSON
 *
 * @param value Parâmetro para testar se é um objeto JSON
 * @returns Retorna true se for um objeto JSON e false se não for
 */
const isJSONObject = (value: any): boolean => {
  try {
    const jsonString = JSON.stringify(value);
    return typeof jsonString === "string" && jsonString !== "";
  } catch (error) {
    return false;
  }
};

/**
 * Verifica se a string é um JSON válido
 *
 * @param value Parâmetro para testar se é um JSON válido
 * @returns Retorna true se a string for um JSON válido e false se não for
 */
const isJSON = (value: string): boolean => {
  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
};

export { isJSONObject, isJSON };
