import React from "react";

import { ISolicitationHistorical, IUseHistorical } from "./types";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";

export function useHistorical(): IUseHistorical {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const [historicalList, setHistoricalList] =
    React.useState<ISolicitationHistorical>([]);

  const searchingSolicitationHistorical = useLoading();

  const searchSolicitationsHistorical = React.useCallback(
    async (solicitationId: number) => {
      try {
        searchingSolicitationHistorical.setLoading(true);
        const json = (await customFetch(
          "/contracts/searchSolicitationHistorical",
          {
            body: {
              idSolicitacao: solicitationId,
            },
          }
        )) as DefaultFetchResponse<ISolicitationHistorical>;
        if (json.status === 200) {
          setHistoricalList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingSolicitationHistorical.setLoading(false);
      }
    },
    [Modal, customFetch, searchingSolicitationHistorical]
  );

  return {
    historicalList,
    searchSolicitationsHistorical: {
      search: searchSolicitationsHistorical,
      searching: searchingSolicitationHistorical.isLoading,
    },
  };
}
