import React from "react";

import styles from "./Lista.module.css";
import { Button, Input, LinkButton, Select } from "../../../../components/Form";
import { Table } from "../../../../components/Data/XTable";
import { IUseContrato, QtdDiasVencimentoOption } from "../hooks/types";
import { Circle } from "../../../../components/Loading";
import { Paginate } from "../../../../components/Paginate/Paginate";

interface IProps {
  contratosData: IUseContrato;
}

export function Lista({ contratosData }: IProps) {
  const {
    selectedContrato,
    data: { tableData },
    filters: {
      nomeCnpj,
      qtdDiasVencimento,
      statusContrato,
      periodoVigencia: { inicio, termino },
    },
    searchs: { pagination, searchingContratos, searchContratosPaginated, searchContratosTotalRecordsAndContratos },
  } = contratosData;

  React.useEffect(() => {
    selectedContrato.setValue(null);
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.navContainer}>
        <LinkButton to="/contratos-fornecedor-cliente/contrato/novo" className={styles.navButton}>
          Novo Contrato
        </LinkButton>
      </div>
      <div className={styles.filtersContainer}>
        <div className={styles.filtersContainer__nomecnpjContainer}>
          <label htmlFor="nomeCnpj" className="label">
            Nome/CNPJ
          </label>
          <Input
            id="nomeCnpj"
            placeholder="Digite o nome/cnpj que deseja filtrar"
            value={nomeCnpj.value}
            onChange={nomeCnpj.onChange}
            disabled={searchingContratos}
          />
        </div>
        <div className={styles.filtersContainer__qtdDiasContainer}>
          <label htmlFor="qtdDiasVencimento" className="label">
            Qtd. Dias Vencimento
          </label>
          <div
            className={`${styles.qtdDiasVencimentoField} ${qtdDiasVencimento.qtd.value !== "" ? styles.filled : null}`}>
            <select
              value={qtdDiasVencimento.option[0]}
              onChange={(e) => qtdDiasVencimento.option[1](e.target.value as QtdDiasVencimentoOption)}>
              <option value=">">{">"}</option>
              <option value=">=">{">="}</option>
              <option value="<">{"<"}</option>
              <option value="<=">{"<="}</option>
              <option value="=">{"="}</option>
            </select>
            <input
              type="number"
              id="qtdDiasVencimento"
              placeholder="Digite a quantidade de dias"
              value={qtdDiasVencimento.qtd.value}
              onChange={qtdDiasVencimento.qtd.onChange}
              className="cleanInputNumber"
            />
          </div>
        </div>
        <div>
          <label htmlFor="statusContrato" className="label">
            Status do Contrato
          </label>
          <Select
            id="statusContrato"
            options={statusContrato.options}
            value={statusContrato.status.value}
            onChange={statusContrato.status.onChange}
            isSearchable={false}
            isClearable={false}
            isDisabled={searchingContratos}
          />
        </div>
        <div className={styles.filtersContainer__periodoContainer}>
          <label className="label">Período Vigência</label>
          <div>
            <Input
              id="inicioVigencia"
              type="date"
              value={inicio.value}
              onChange={inicio.onChange}
              disabled={searchingContratos}
            />
            <span>à</span>
            <Input
              id="terminoVigencia"
              type="date"
              value={termino.value}
              onChange={termino.onChange}
              disabled={searchingContratos}
            />
          </div>
        </div>
        <div className={styles.filtersContainer__buttonContainer}>
          <Button onClick={searchContratosTotalRecordsAndContratos} disabled={searchingContratos}>
            Buscar Contratos
          </Button>
        </div>
      </div>
      <span className="separator" />
      <div className={styles.dataContainer}>
        <div
          className={`${styles.tableContainer} customScrollbar`}
          style={{ display: tableData.data.length && !searchingContratos ? "block" : "none" }}>
          <Table
            tableData={tableData}
            lineOptions={{
              style: (data) => {
                if (data.qtdDiasVencimento < 0) {
                  return {
                    backgroundColor: "rgba(255, 0, 0, 0.1)",
                  };
                } else if (data.qtdDiasVencimento <= 30) {
                  return {
                    backgroundColor: "rgba(255, 255, 0, 0.2)",
                  };
                } else {
                  return {};
                }
              },
            }}
          />
        </div>
        {searchingContratos ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
          </div>
        ) : !tableData.data.length ? (
          <p className={`lineCardMessage`}>Nenhum Contrato Encontrado</p>
        ) : null}
      </div>
      <Paginate
        classNameContainer={styles.paginationContainer}
        maxItems={pagination.maxItems}
        totalRecords={pagination.totalRecords}
        currentPage={pagination.currentPage}
        setCurrentPage={pagination.setCurrentPage}
        onPageChange={(page) => {
          searchContratosPaginated(page - 1);
        }}
      />
    </div>
  );
}
