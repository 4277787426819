import { Pencil } from "phosphor-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Table, TBody, Td, Th, THead, Tr } from "../../../../components/Data/Table";
import { Button, LinkButton } from "../../../../components/Form";
import { Circle } from "../../../../components/Loading";
import { Paginate } from "../../../../components/Paginate/Paginate";
import { formatDate } from "../../../../helpers/format";
import { useModal } from "../../../../hooks/contexts";
import { IPeopleCategory, ISearchPeopleCategoriesResponse } from "../PeopleCategories";

import styles from "./PeopleCategoriesList.module.css";

interface IPeopleCategoriesListProps {
  peopleCategories: {
    value: IPeopleCategory[];
    setValue: React.Dispatch<React.SetStateAction<IPeopleCategory[]>>;
  };
  searchingPeopleCategories: IUseLoading;
  searchPeopleCategories: (page: number) => Promise<unknown>;
  setSelectedPeopleCategory: React.Dispatch<React.SetStateAction<IPeopleCategory | null>>;
  pagination: IUsePagination;
}

export function PeopleCategoriesList({
  peopleCategories,
  searchingPeopleCategories,
  searchPeopleCategories,
  setSelectedPeopleCategory,
  pagination,
}: IPeopleCategoriesListProps) {
  const navigate = useNavigate();
  const Modal = useModal();

  const handleClickEditCategory = (category: IPeopleCategory) => {
    setSelectedPeopleCategory(category);
    navigate("atualizar");
  };

  const handlePageChange = async (page: number) => {
    try {
      searchingPeopleCategories.setLoading(true);
      const json = (await searchPeopleCategories(page)) as ISearchPeopleCategoriesResponse;
      if (json.status === 200) {
        peopleCategories.setValue(json.object);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingPeopleCategories.setLoading(false);
    }
  };

  React.useEffect(() => {
    setSelectedPeopleCategory(null);
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.navContainer}>
        <LinkButton
          to="nova"
          variant={undefined}
          className={undefined}
          classNameContainer={undefined}
          disabled={undefined}
        >
          Nova Categoria
        </LinkButton>
      </div>
      <div className={styles.contentContainer}>
        {peopleCategories.value.length && !searchingPeopleCategories.isLoading ? (
          <Table title={"Categorias Pessoas"} className={styles.table}>
            <THead>
              <Tr>
                <Th width={".625rem"}>ID</Th>
                <Th width={""}>Descrição</Th>
                <Th width={".625rem"}>Situação</Th>
                <Th width={"11.25rem"}>Última Alteração</Th>
                <Th children={null} width={"1.25rem"}></Th>
              </Tr>
            </THead>
            <TBody>
              {peopleCategories.value.map((item, index) => (
                <Tr key={index}>
                  <Td heading={"ID"}>{item.idCategoriaPessoa}</Td>
                  <Td heading={"Descrição"}>{item.nomeCategoriaPessoa}</Td>
                  <Td heading={"Situação"}>{item.status === "A" ? "Ativo" : "Inativo"}</Td>
                  <Td heading={"Última Alteração"}>{formatDate(item.dataManutencao, "dd/MM/yyyy")}</Td>
                  <Td heading={"Editar"} data-option>
                    <Button
                      className={styles.editCategoryButton}
                      type="button"
                      variant="edit"
                      onClick={() => {
                        handleClickEditCategory(item);
                      }}
                      data-option-button
                    >
                      <Pencil weight="fill" />
                    </Button>
                  </Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        ) : searchingPeopleCategories.isLoading ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhuma categoria encontrada</p>
        )}
      </div>
      <div>
        <Paginate
          classNameContainer={styles.paginationContainer}
          maxItems={pagination.maxItems}
          totalRecords={pagination.totalRecords}
          currentPage={pagination.currentPage}
          setCurrentPage={pagination.setCurrentPage}
          onPageChange={(page: number) => handlePageChange(page)}
        />
      </div>
    </div>
  );
}
