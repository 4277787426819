import React from "react";

import { Table, TBody, Td, Th, THead, Tr } from "../../../../components/Data/Table";
import { Button, LinkButton } from "../../../../components/Form";
import { Pencil } from "phosphor-react";
import { Circle } from "../../../../components/Loading";
import { useNavigate } from "react-router-dom";
import { Paginate } from "../../../../components/Paginate/Paginate";
import { IUserBankAccount } from "../UserBankAccount";

import styles from "./List.module.css";

interface IProps {
  usersBankAccountList: {
    value: IUserBankAccount[];
    setValue: React.Dispatch<IUserBankAccount[]>;
  };
  searchUsersBankAccount: (page: number) => void;
  searchTotalUsersBankAccountRecordsAndUsersBankAccount: {
    search: () => void;
    isSearching: boolean;
  };
  pagination: IUsePagination;
  setSelectedUserBankAccount: React.Dispatch<IUserBankAccount | null>;
}

export function List({
  usersBankAccountList,
  searchUsersBankAccount,
  searchTotalUsersBankAccountRecordsAndUsersBankAccount,
  pagination,
  setSelectedUserBankAccount,
}: IProps) {
  const navigate = useNavigate();

  React.useEffect(() => {
    setSelectedUserBankAccount(null);
    if (!usersBankAccountList.value.length) {
      searchTotalUsersBankAccountRecordsAndUsersBankAccount.search();
    }
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.navigationContainer}>
        <LinkButton to={"nova"}>Nova Conta</LinkButton>
      </div>
      <div className={styles.mainContainer}>
        {usersBankAccountList.value.length && !searchTotalUsersBankAccountRecordsAndUsersBankAccount.isSearching ? (
          <div className={`${styles.tableContainer}`}>
            <Table title="Contas dos Usuários" className={styles.table}>
              <THead>
                <Tr>
                  <Th width=".625rem">ID</Th>
                  <Th width={undefined}>Nome</Th>
                  <Th children={undefined} width=".625rem" />
                </Tr>
              </THead>
              <TBody>
                {usersBankAccountList.value.map((item) => (
                  <Tr key={item.usuarioContaId}>
                    <Td heading="ID">{item.usuarioContaId}</Td>
                    <Td heading="Nome">{item.usuario?.nome}</Td>
                    <Td heading="Editar" data-option>
                      <Button
                        type="button"
                        variant="edit"
                        className={styles.editBankButton}
                        onClick={() => {
                          setSelectedUserBankAccount(item);
                          navigate("atualizar");
                        }}
                        data-option-button>
                        <Pencil weight="fill" />
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </TBody>
            </Table>
          </div>
        ) : searchTotalUsersBankAccountRecordsAndUsersBankAccount.isSearching ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhuma conta encontrada</p>
        )}
        <Paginate
          classNameContainer={styles.paginateContainer}
          maxItems={pagination.maxItems}
          totalRecords={pagination.totalRecords}
          currentPage={pagination.currentPage}
          setCurrentPage={pagination.setCurrentPage}
          onPageChange={(page) => searchUsersBankAccount(page)}
        />
      </div>
    </div>
  );
}
