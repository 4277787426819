import React, { useEffect } from "react";

import styles from "./UpdatePercents.module.css";
import { IUseCommissionPercent } from "../hooks/types";
import {
  Button,
  Input,
  LinkButton,
  Select,
} from "../../../../../components/Form";
import { Navigate } from "react-router-dom";
import { useUpdatePercents } from "./hooks/useUpdatePercents";
import { Circle } from "../../../../../components/Loading";
import { Pencil, X } from "phosphor-react";
import { CommissionBall } from "../../../../../components/Commons/CommissionBall";

interface IProps {
  rootData: IUseCommissionPercent;
}

export function UpdatePercents({ rootData }: IProps) {
  const {
    data: { selectedItem },
  } = rootData;

  const {
    modalUpdateCommissionPercentRef,
    sets: { setSelectedCommissionPercent },
    data: {
      type,
      percent,
      itemPercentList,
      selectedPercent,
      selectedCommissionPercent,
      commissionPercentList,
      typeOptions,
    },
    methods: {
      searchItemPercents,
      toggleSelectedPercent,
      resetModalForm,
      addOrUpdateCommissionPercent,
      removeCommissionPercent,
    },
    loadings: {
      searchingItemPercents,
      searchingCommissionPercents,
      addingOrUpdatingCommissionPercent,
    },
  } = useUpdatePercents();

  useEffect(() => {
    if (selectedItem) searchItemPercents(selectedItem?.itensTabela.id);
  }, []); // eslint-disable-line

  if (!selectedItem) {
    return <Navigate to="/comissoes/foliar/percentual-comissao" />;
  }

  return (
    <>
      <div className="container">
        <div className={styles.navContainer}>
          <LinkButton
            to="/comissoes/foliar/percentual-comissao"
            buttonStyle="backButton"
          />
          <h1>| {selectedItem?.formulado.descricaoFormulado} |</h1>
          <span />
        </div>
        <span className="separator" />
        <div>
          {!searchingItemPercents && itemPercentList.length ? (
            <ul className={styles.percentsContainer}>
              {itemPercentList
                .sort((a, b) => b.percentual - a.percentual)
                .map((item) => (
                  <li
                    key={item.id}
                    className={`${styles.ballContainer} ${
                      selectedPercent && selectedPercent.id === item.id
                        ? styles.ballContainer_selected
                        : ""
                    }`}
                    onClick={() => {
                      if (addingOrUpdatingCommissionPercent) return;
                      toggleSelectedPercent(item);
                    }}
                  >
                    <CommissionBall.Root>
                      <CommissionBall.Ball
                        color={item.cor}
                        letter={selectedItem.itensTabela.categoria}
                        rootClassName={styles.ballRoot}
                        ballClassName={styles.ball}
                        barClassName={styles.ballBar}
                        letterClassName={styles.ballLetter}
                      />
                      <CommissionBall.Info className={styles.ballPercent}>
                        {item.percentual}%
                      </CommissionBall.Info>
                    </CommissionBall.Root>
                  </li>
                ))}
            </ul>
          ) : searchingItemPercents ? (
            <div className={`loadingContainer`}>
              <Circle size={100} />
            </div>
          ) : (
            <p className="lineCardMessage">
              Não há percentuais registrados nesse item
            </p>
          )}
        </div>
        <span className="separator" />
        {!searchingCommissionPercents && !addingOrUpdatingCommissionPercent ? (
          <div>
            {selectedPercent ? (
              <div className={styles.commissionPercentsContainer}>
                <table className={styles.commissionPercentsTable}>
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Percentual (%)</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {commissionPercentList.map((commissionPercent) => (
                      <tr key={commissionPercent.id}>
                        <td>
                          {commissionPercent.tipo === "V"
                            ? "Vendedor"
                            : "Gerente"}
                        </td>
                        <td>{commissionPercent.percentual}%</td>
                        <td className={styles.tdButtonOptions}>
                          <button
                            className={styles.buttonEditCommissionPercent}
                            onClick={() => {
                              setSelectedCommissionPercent(commissionPercent);
                              percent.setValue(
                                commissionPercent.percentual.toString()
                              );
                              const typeOption = typeOptions.find(
                                (item) => item.value === commissionPercent.tipo
                              );
                              if (typeOption) type.setValue(typeOption);
                              modalUpdateCommissionPercentRef.current?.show();
                            }}
                          >
                            <Pencil
                              size={20}
                              weight="fill"
                              color="var(--gray-8)"
                            />
                          </button>
                          <button
                            className={styles.buttonRemoveCommissionPercent}
                            onClick={() => {
                              removeCommissionPercent(commissionPercent);
                            }}
                          >
                            <X size={20} weight="fill" color="var(--gray-8)" />
                          </button>
                        </td>
                      </tr>
                    ))}
                    {commissionPercentList.length === 0 && (
                      <tr>
                        <td colSpan={3} style={{ textAlign: "center" }}>
                          Nenhum percentual registrado
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <button
                  className={styles.buttonAddCommissionPercent}
                  onClick={() => {
                    modalUpdateCommissionPercentRef.current?.show();
                  }}
                >
                  Adicionar Percentual
                </button>
              </div>
            ) : (
              <p className="lineCardMessage">Selecione algum percentual</p>
            )}
          </div>
        ) : (
          <div className={`loadingContainer`}>
            <Circle size={100} />
          </div>
        )}
      </div>
      <dialog
        className={`modal ${styles.modalUpdateCommissionPercent}`}
        ref={modalUpdateCommissionPercentRef}
        onClose={() => {
          resetModalForm();
        }}
      >
        <div className="modalContent">
          <div className={styles.modalBallExample}>
            <span className={styles.modalBallExample__line} />
            <span
              className={styles.modalBallExample__ball}
              style={{ backgroundColor: selectedPercent?.cor }}
            />
            <span className={styles.modalBallExample__line} />
          </div>
          <div className={styles.modalFormContainer}>
            <div>
              <label htmlFor="type" className="label">
                Tipo
              </label>
              <Select
                id="type"
                value={type.value}
                onChange={(value) => {
                  type.onChange(value);
                }}
                options={typeOptions}
                isClearable={false}
                isSearchable={false}
              />
            </div>
            <div className={styles.modalFormContainer__percent}>
              <label htmlFor="percent" className="label">
                Percentual
              </label>
              <Input
                id="percent"
                type="number"
                className="cleanInputNumber"
                placeholder="Digite o percentual"
                value={percent.value}
                onChange={percent.onChange}
                error={percent.error}
              />
            </div>
          </div>
          <span className="separator" />
          <div className={styles.modalButtonsContainer}>
            <Button
              onClick={() => {
                modalUpdateCommissionPercentRef.current?.close();
              }}
              variant="danger"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                addOrUpdateCommissionPercent(
                  selectedCommissionPercent?.id || null,
                  selectedItem.itensTabela.categoria
                );
                modalUpdateCommissionPercentRef.current?.close();
              }}
            >
              Concluir
            </Button>
          </div>
        </div>
      </dialog>
    </>
  );
}
