import React from "react";

import { formatDate, formatMoney } from "../../../../helpers/format";
import { isValid } from "../../../../helpers/validations";
import { downloadResponseFile } from "../../../../helpers/responses";

import { useModal } from "../../../../hooks/contexts";
import { useCustomFetch, useDebounce, useInfiniteScroll, useLoading } from "../../../../hooks/async";

import { Button, Input, InputMultiple, Select, SeparatorList } from "../../../../components/Form";
import { useDate, useSelect } from "../../../../hooks/form";

import { Circle } from "../../../../components/Loading";
import styles from "./RequestPayment.module.css";
import { CaretDown } from "phosphor-react";

const messageColor = (premiation) => {
  if (premiation.permitidoPagar <= 0) {
    return "#bd0000";
  } else if (premiation.permitidoPagar < premiation.valorSaldo) {
    return "#d65600";
  } else {
    return "";
  }
};

const ContentModalSuccess = ({ message, requestId }) => {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const generatingRequestReceipt = useLoading();

  const handleClickGenerateRequestReceipt = React.useCallback(
    async (requestId) => {
      const url = `/premiations/report/generateRequestReceipt/${requestId}`;

      try {
        generatingRequestReceipt.setLoading(true);

        const response = await customFetch(url, {
          method: "GET",
        });
        if (response.status === 200) {
          downloadResponseFile({
            file: response.object,
            type: "application/pdf",
            fileName: "comprovante-da-solicitacao.pdf",
          });
        } else if (response.status === 500) {
          Modal.error(response.message, response.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        generatingRequestReceipt.setLoading(false);
      }
    },
    [Modal, customFetch, generatingRequestReceipt]
  );

  return (
    <div style={{ textAlign: "center" }}>
      <p dangerouslySetInnerHTML={{ __html: message }}></p>
      {!generatingRequestReceipt.isLoading ? (
        <p
          onClick={() => handleClickGenerateRequestReceipt(requestId)}
          style={{ color: "blue", marginTop: "20px", cursor: "pointer" }}
          data-button-generate-request-receipt>
          Gerar Comprovante
        </p>
      ) : (
        <p
          style={{ display: "flex", justifyContent: "center", gap: "5px", marginTop: "20px" }}
          data-loading-generate-request-receipt>
          <span style={{ color: "blue" }}>Gerando</span> <Circle size={20} color="blue" />
        </p>
      )}
    </div>
  );
};

export function PremiationsManagerRequest() {
  const [premiationList, setPremiationList] = React.useState({});

  const [loadMoreRef] = useInfiniteScroll(() => {
    if (searchingPremiations.isLoading) return;
    searchPremiations(
      premiationList,
      currentPage,
      premiationType,
      sellers,
      clients,
      enterprises,
      initialDate,
      finalDate,
      orderNumber,
      operationTypes,
      formulaGroups
    );
  });

  const Modal = useModal();
  const customFetch = useCustomFetch();

  const [currentPage, setCurrentPage] = React.useState(0);

  const [currentSearching, setCurrentSearching] = React.useState(null);

  const [currentStep, setCurrentStep] = React.useState(1);

  const searchingClientOptions = useLoading();
  const searchingSellerOptions = useLoading();
  const searchingEnterpriseOptions = useLoading();
  const searchingPaymentTypeOptions = useLoading();
  const searchingOperationTypeOptions = useLoading();
  const searchingPremiations = useLoading();
  const searchingFormulaGroupOptions = useLoading();
  const generatingReport = useLoading();
  const requestingPayment = useLoading();
  const [noMorePremiations, setNoMorePremiations] = React.useState(false);

  const clients = useSelect({ type: "multiple", required: false, defaultValue: [] });
  const sellers = useSelect({ type: "multiple", required: false, defaultValue: [] });
  const enterprises = useSelect({ type: "multiple", required: true, emptyMessage: "Selecione ao menos uma empresa" });
  const premiationType = useSelect({ type: "single", required: true });
  const orderNumber = useSelect({ type: "multiple", required: false });
  const operationTypes = useSelect({ type: "multiple", required: false });
  const formulaGroups = useSelect({ type: "multiple", required: false });
  const initialDate = useDate({ type: "", required: false });
  const finalDate = useDate({ type: "", required: false });

  const defaultPaymentType = useSelect({ type: "single", required: true });

  const [clientOptions, setClientOptions] = React.useState([]);
  const [sellerOptions, setSellerOptions] = React.useState([]);
  const [enterpriseOptions, setEnterpriseOptions] = React.useState([]);
  const [paymentTypeOptions, setPaymentTypeOptions] = React.useState([]);
  const [operationTypeOptions, setOperationTypeOptions] = React.useState([]);
  const [formulaGroupOptions, setFormulaGroupOptions] = React.useState([]);

  const premiationTypeOptions = [
    {
      value: "T",
      label: "Premiações e Bonificações",
    },
    {
      value: "P",
      label: "Premiações",
    },
    {
      value: "B",
      label: "Bonificações",
    },
  ];

  const dataInformations = {
    totalSelectedPremiations: 0,
    totalPaymentValue: 0,
    totalReleased: 0,
    totalTons: 0,
  };
  if (premiationList.premiacoes) {
    let totalSelectedPremiations = 0;
    let totalPaymentValue = 0;
    let totalReleased = 0;
    let totalTons = 0;
    premiationList.premiacoes.forEach((clientItem) => {
      clientItem.pedidos.forEach((orderItem) => {
        orderItem.premiacoes.forEach((premiationItem) => {
          if (premiationItem.controll.selected) {
            totalSelectedPremiations++;
            totalPaymentValue += Number(premiationItem.controll.paymentValue);
            totalReleased += Number(premiationItem.permitidoPagar);
            totalTons += Number(premiationItem.qntPedida);
          }
        });
      });
    });
    dataInformations.totalSelectedPremiations = totalSelectedPremiations;
    dataInformations.totalPaymentValue = totalPaymentValue;
    dataInformations.totalReleased = totalReleased;
    dataInformations.totalTons = totalTons;
  }

  const searchClientOptions = React.useCallback(
    async (description = "") => {
      try {
        searchingClientOptions.setLoading(true);
        const json = await customFetch("/premiations/manager/searchClients", {
          body: {
            descricao: description,
          },
        });
        if (json.status === 200) {
          const options = json.object
            .filter(
              (option) => !clients.value.some((item) => item.value.cliente.idCliente === option.cliente.idCliente)
            )
            .map((option) => {
              return {
                value: option,
                label: `
                  <div class="${styles.separatorListLabel}">
                    <p>${option.cliente.idCliente} | ${option.cliente.razaoSocial}</p>
                    <p style="margin-top: 4px;"><i>${option.cidade.nomeCidade}</i></p>
                  </div>
                `,
              };
            });

          setClientOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingClientOptions.setLoading(false);
      }
    },
    [Modal, clients, customFetch, searchingClientOptions]
  );

  const searchClientOptionsDebounced = useDebounce((description = "") => {
    searchClientOptions(description);
  });

  const searchSellerOptions = React.useCallback(
    async (description = "") => {
      try {
        searchingSellerOptions.setLoading(true);
        const json = await customFetch("/premiations/manager/searchSellers", {
          body: {
            descricao: description,
          },
        });
        if (json.status === 200) {
          const options = json.object
            .filter((option) => !sellers.value.some((item) => item.value.idVendedor === option.idVendedor))
            .map((option) => {
              return {
                value: option,
                label: `
                  <div class="${styles.separatorListLabel}">
                    <p>${option.idVendedor} | ${option.nomeVendedor}</p>
                  </div>
                `,
              };
            });
          setSellerOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingSellerOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingSellerOptions, sellers]
  );

  const searchSellerOptionsDebounced = useDebounce((description = "") => {
    searchSellerOptions(description);
  });

  const searchEnterpriseOptions = React.useCallback(async () => {
    try {
      searchingEnterpriseOptions.setLoading(true);
      const json = await customFetch("/premiations/manager/searchEnterprises", {
        body: {
          descricao: "",
        },
      });
      if (json.status === 200) {
        const options = json.object.empresas.map((option) => {
          return {
            value: option,
            label: `${option.idEmpresa} | ${option.nomeEmpresa}`,
          };
        });

        setEnterpriseOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingEnterpriseOptions.setLoading(false);
    }
  }, [Modal, customFetch, searchingEnterpriseOptions]);

  const searchPaymentTypes = React.useCallback(async () => {
    try {
      searchingPaymentTypeOptions.setLoading(true);
      const json = await customFetch("/premiations/manager/searchPaymentTypes", {
        method: "GET",
        headers: { Accept: "application/json" },
      });
      if (json.status === 200) {
        const options = json.object.map((option) => {
          return {
            value: option,
            label: `${option.descricaoPagamento}`,
          };
        });
        setPaymentTypeOptions(options);
        defaultPaymentType.setValue(options[0]);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingPaymentTypeOptions.setLoading(false);
    }
  }, [Modal, customFetch, defaultPaymentType, searchingPaymentTypeOptions]);

  const searchOperationTypeOptions = React.useCallback(async () => {
    try {
      searchingOperationTypeOptions.setLoading(true);
      const json = await customFetch("/premiations/manager/searchOperationTypes", {
        method: "GET",
        headers: { Accept: "application/json" },
      });
      if (json.status === 200) {
        const options = json.object.map((option) => {
          return {
            value: option,
            label: `${option.descricao}`,
          };
        });
        setOperationTypeOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingOperationTypeOptions.setLoading(false);
    }
  }, [Modal, customFetch, searchingOperationTypeOptions]);

  const searchFormulaGroupOptions = React.useCallback(async () => {
    try {
      searchingFormulaGroupOptions.setLoading(true);
      const json = await customFetch("/premiations/manager/searchFormulaGroups", {
        method: "GET",
        headers: { Accept: "application/json" },
      });
      if (json.status === 200) {
        const options = json.object.map((option) => {
          return {
            value: option,
            label: `${option.descricao}`,
          };
        });
        setFormulaGroupOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingFormulaGroupOptions.setLoading(false);
    }
  }, [Modal, customFetch, searchingFormulaGroupOptions]);

  const searchPremiationsPromisse = React.useCallback(
    async (
      currentPage,
      premiationType,
      sellers,
      clients,
      enterprises,
      initialDate,
      finalDate,
      orderNumber,
      operationTypes,
      formulaGroups
    ) => {
      return new Promise(async (resolve, reject) => {
        setCurrentSearching({
          abort: () => {
            resolve({ status: 400 });
          },
        });
        try {
          const json = await customFetch(
            "/premiations/manager/searchPremiations",
            {
              body: {
                pagina: currentPage,
                tamanho: 10,
                tipo: premiationType.value.value,
                vendedores: sellers.value.map(
                  (seller) => seller.value.idVendedor
                ),
                clientes: clients.value.map(
                  (client) => client.value.cliente.idCliente
                ),
                empresas: enterprises.value.map(
                  (enterprise) => enterprise.value.idEmpresa
                ),
                dataInicial: initialDate.value
                  ? initialDate.toISOString()
                  : null,
                dataFinal: finalDate.value
                  ? finalDate.toISOStringNextDay()
                  : null,
                idPedido: orderNumber.value.map((orderNumber) =>
                  Number(orderNumber.value)
                ),
                idOperacaoPedido: operationTypes.value.map(
                  (operationType) => operationType.value.idOperacao
                ),
                idGrupoFormulado: formulaGroups.value.map(
                  (formulaGroup) => formulaGroup.value.codigo
                ),
              },
            }
          );
          resolve(json);
        } catch (error) {
          reject(error);
        } finally {
          setCurrentSearching(null);
        }
      });
    },
    [customFetch]
  );

  const searchPremiations = React.useCallback(
    async (
      premiationList,
      currentPage,
      premiationType,
      sellers,
      clients,
      enterprises,
      initialDate,
      finalDate,
      orderNumber,
      operationTypes,
      formulaGroups
    ) => {
      try {
        searchingPremiations.setLoading(true);
        const json = await searchPremiationsPromisse(
          currentPage,
          premiationType,
          sellers,
          clients,
          enterprises,
          initialDate,
          finalDate,
          orderNumber,
          operationTypes,
          formulaGroups
        );
        if (json.status === 200) {
          const adjustedJson = { ...json.object };
          adjustedJson.premiacoes.forEach((item) => {
            item.pedidos.forEach((item) => {
              item.premiacoes.forEach((item) => {
                item.controll = {
                  selected: false,
                  paymentValue: "",
                  paymentType: defaultPaymentType.value.value.idTipoPagamento,
                };
              });
            });
          });

          let currentPremiations = { ...adjustedJson };
          if (currentPage > 0) {
            currentPremiations = { ...premiationList };
            currentPremiations.premiacoes.forEach((currentPremiationsClient) => {
              adjustedJson.premiacoes.every((jsonPremiationsClient) => {
                if (currentPremiationsClient.idCliente === jsonPremiationsClient.idCliente) {
                  currentPremiationsClient.pedidos.push(...jsonPremiationsClient.pedidos);
                  return false;
                }
                return true;
              });
            });
            adjustedJson.premiacoes.forEach((adjustedItem) => {
              if (
                !currentPremiations.premiacoes.some((currentItem) => currentItem.idCliente === adjustedItem.idCliente)
              ) {
                currentPremiations.premiacoes.push({ ...adjustedItem });
              }
            });
          }
          setPremiationList(currentPremiations);
          setCurrentPage(currentPage + 1);
          setNoMorePremiations(false);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setNoMorePremiations(true);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingPremiations.setLoading(false);
      }
    },
    [Modal, searchPremiationsPromisse, searchingPremiations, defaultPaymentType]
  );

  const gotoStepOne = React.useCallback(() => {
    setCurrentStep(1);

    const listContainer = document.querySelector("#dataFormContainer__listDiv");
    listContainer.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const gotoStepTwo = React.useCallback(() => {
    let haveSelectedItems = false;
    premiationList.premiacoes.every((clientItem) => {
      clientItem.pedidos.every((orderItem) => {
        orderItem.premiacoes.every((premiationItem) => {
          if (premiationItem.controll.selected) {
            haveSelectedItems = true;
          }
          return !haveSelectedItems;
        });
        return !haveSelectedItems;
      });
      return !haveSelectedItems;
    });
    if (haveSelectedItems) {
      setCurrentStep(2);
      const listContainer = document.querySelector("#dataFormContainer__listDiv");
      listContainer.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      Modal.error("É necessário selecionar ao menos um item para prosseguir.");
    }
  }, [Modal, premiationList]);

  const gotoStepThree = React.useCallback(() => {
    let haveSelectedItems = false;
    premiationList.premiacoes.every((clientItem) => {
      clientItem.pedidos.every((orderItem) => {
        orderItem.premiacoes.every((premiationItem) => {
          if (premiationItem.controll.selected) {
            haveSelectedItems = true;
          }
          return !haveSelectedItems;
        });
        return !haveSelectedItems;
      });
      return !haveSelectedItems;
    });
    if (haveSelectedItems) {
      let haveInvalidValue = false;
      let invalidMessage = "";
      premiationList.premiacoes.every((clientItem) => {
        clientItem.pedidos.every((orderItem) => {
          orderItem.premiacoes.every((premiationItem) => {
            if (premiationItem.controll.selected) {
              if (premiationItem.controll.paymentValue > premiationItem.permitidoPagar) {
                haveInvalidValue = true;
                invalidMessage = "Não é possível prosseguir com valores acima do liberado!";
              } else if (premiationItem.controll.paymentValue <= 0) {
                haveInvalidValue = true;
                invalidMessage = "Não é possível prosseguir com valores zerados!";
              }
            }
            return !haveInvalidValue;
          });
          return !haveInvalidValue;
        });
        return !haveInvalidValue;
      });
      if (!haveInvalidValue) {
        setCurrentStep(3);
        const listContainer = document.querySelector("#dataFormContainer__listDiv");
        listContainer.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        Modal.error(invalidMessage);
      }
    } else {
      Modal.error("É necessário selecionar ao menos um item para prosseguir.");
    }
  }, [Modal, premiationList]);

  const handleChangeClients = React.useCallback(
    (value) => {
      clients.setValue((old) => {
        if (value.length < old.length) {
          const removedValue = old.filter((obj1) => {
            return !value.some((obj2) => {
              return obj1.value.cliente.idCliente === obj2.value.cliente.idCliente;
            });
          });
          setClientOptions((oldOptions) => {
            return [
              ...removedValue,
              ...oldOptions.filter(
                (i) => !removedValue.some((j) => j.value.cliente.idCliente === i.value.cliente.idCliente)
              ),
            ];
          });
        }
        return value;
      });
    },
    [clients]
  );

  const handleChangeSellers = React.useCallback(
    (value) => {
      sellers.setValue((old) => {
        if (value.length < old.length) {
          const removedValue = old.filter((obj1) => {
            return !value.some((obj2) => {
              return obj1.value.idVendedor === obj2.value.idVendedor;
            });
          });
          setSellerOptions((oldOptions) => {
            return [
              ...removedValue,
              ...oldOptions.filter((i) => !removedValue.some((j) => j.value.idVendedor === i.value.idVendedor)),
            ];
          });
        }
        return value;
      });
    },
    [sellers]
  );

  const handleChangeCheckPremiation = (e, premiationItem) => {
    if (e.target.checked) {
      premiationItem.controll.selected = true;
      premiationItem.controll.paymentValue = premiationItem.permitidoPagar;
    } else {
      premiationItem.controll.selected = false;
      premiationItem.controll.paymentValue = "";
    }
    const changedList = { ...premiationList };
    setPremiationList(changedList);
  };

  const handleChangePaymentValue = (e, premiationItem) => {
    premiationItem.controll.paymentValue = e.target.value;
    const changedList = { ...premiationList };
    setPremiationList(changedList);
  };

  const handleChangePaymentType = (e, premiationItem) => {
    premiationItem.controll.paymentType = e.target.value;
    const changedList = { ...premiationList };
    setPremiationList(changedList);
  };

  const handleChangeDefaultPaymentType = async (value) => {
    const confirm = await Modal.confirm(
      "<p>Esta ação irá mudar o método de pagamento de todos os itens.</p><p>Deseja continuar?</p>"
    );
    if (confirm) {
      defaultPaymentType.setValue(value);
      const list = { ...premiationList };
      list.premiacoes.forEach((clientItem) => {
        clientItem.pedidos.forEach((orderItem) => {
          orderItem.premiacoes.forEach((premiationItem) => {
            premiationItem.controll.paymentType = value.value.idTipoPagamento;
          });
        });
      });
      setPremiationList(list);
    }
  };

  const handleClickGenerateReport = React.useCallback(
    async (fileType, justSelected = false) => {
      const url =
        fileType === "excel"
          ? "/premiations/report/generatePremiationsReport/excel"
          : "/premiations/report/generatePremiationsReport/pdf";

      let selectedPremiations = [];
      if (justSelected) {
        premiationList.premiacoes?.forEach((clientItem) => {
          clientItem.pedidos.forEach((orderItem) => {
            orderItem.premiacoes.forEach((premiationItem) => {
              if (premiationItem.controll.selected) {
                selectedPremiations.push(Number(premiationItem.idPremiacao));
              }
            });
          });
        });

        if (!selectedPremiations.length) {
          Modal.error("É necessário haver ao menos uma premiação selecionada.");
          return;
        }
      }

      if (isValid(enterprises, premiationType) && !generatingReport.isLoading) {
        try {
          generatingReport.setLoading(true);
          const response = await customFetch(url, {
            timeout: 1000 * 60 * 40,
            body: {
              premiacoesSelecionadas: selectedPremiations.length ? selectedPremiations : null,
              pagina: 0,
              tamanho: 10,
              tipo: premiationType.value.value,
              vendedores: sellers.value.map((seller) => seller.value.idVendedor),
              clientes: clients.value.map((client) => client.value.cliente.idCliente),
              empresas: enterprises.value.map((enterprise) => enterprise.value.idEmpresa),
              dataInicial: initialDate.value ? initialDate.toISOString() : null,
              dataFinal: finalDate.value ? finalDate.toISOStringNextDay() : null,
            },
          });
          if (response.status === 200) {
            downloadResponseFile({
              file: response.object,
              type: "application/pdf",
              fileName: "relatorio-premiacoes-selecionadas.pdf",
            });
          } else if (response.status === 500) {
            Modal.error(response.message, response.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          generatingReport.setLoading(false);
        }
      }
    },
    [
      enterprises,
      premiationType,
      generatingReport,
      premiationList,
      Modal,
      customFetch,
      sellers,
      clients,
      initialDate,
      finalDate,
    ]
  );

  const handleSubmitSearchPremiations = React.useCallback(
    async (e) => {
      e.preventDefault();

      if (!premiationList.premiacoes) {
        if (searchingPremiations.isLoading || generatingReport.isLoading) {
          return;
        }
        if (isValid(enterprises, premiationType)) {
          setCurrentPage(0);
          setPremiationList({});
          searchPremiations(
            {},
            0,
            premiationType,
            sellers,
            clients,
            enterprises,
            initialDate,
            finalDate,
            orderNumber,
            operationTypes,
            formulaGroups
          );
        }
      } else {
        const confirm = await Modal.confirm("<p>Deseja mesmo limpar as premiações?</p>");
        if (confirm) {
          if (currentSearching) {
            currentSearching.abort();
          }
          setCurrentStep(1);
          setPremiationList({});
        }
      }
    },
    [
      searchingPremiations,
      premiationList,
      enterprises,
      premiationType,
      searchPremiations,
      sellers,
      clients,
      initialDate,
      finalDate,
      Modal,
      currentSearching,
      generatingReport,
      orderNumber,
      operationTypes,
      formulaGroups,
    ]
  );

  const handleSubmitRequestPayment = React.useCallback(
    async (e, premiationList) => {
      e.preventDefault();

      if (currentSearching) {
        currentSearching.abort();
      }

      const sendedArray = [];
      premiationList.premiacoes.forEach((clientItem) => {
        clientItem.pedidos.forEach((orderItem) => {
          orderItem.premiacoes.forEach((premiationItem) => {
            if (premiationItem.controll.selected) {
              sendedArray.push({
                idPremiacao: Number(premiationItem.idPremiacao),
                saldo: Number(premiationItem.controll.paymentValue),
                idFormaPagamento: Number(premiationItem.controll.paymentType),
              });
            }
          });
        });
      });

      const confirm = await Modal.confirm("Tem certeza que deseja concluir a solicitação?");

      if (confirm) {
        try {
          requestingPayment.setLoading(true);
          const json = await customFetch("/premiations/manager/requestPayment", {
            body: sendedArray,
          });
          if (json.status === 200) {
            let message = `<p>${json.object.message}</p>`;

            if (json.object.erroList) {
              var errorList = (() => {
                let string = "";
                json.object.erroList.forEach((error) => {
                  string += `<p>${error}</p><br>`;
                });
                return string
                  ? `<div style="margin-top: 10px;">
                        <span style="margin: auto; margin-bottom: 10px; display: block; height: 1px; background-color: var(--gray-8); width: 60%; border-radius: 100%;"></span>
                        ${string}
                      </div>`
                  : "";
              })();

              message += errorList;
            }

            await Modal.success(<ContentModalSuccess message={message} requestId={json.object.solicitacao} />);
            setPremiationList({});
            setCurrentStep(1);
            searchPremiations(
              {},
              0,
              premiationType,
              sellers,
              clients,
              enterprises,
              initialDate,
              finalDate,
              orderNumber,
              operationTypes,
              formulaGroups
            );
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          requestingPayment.setLoading(false);
        }
      }
    },
    [
      Modal,
      clients,
      currentSearching,
      customFetch,
      enterprises,
      finalDate,
      initialDate,
      premiationType,
      requestingPayment,
      searchPremiations,
      sellers,
      orderNumber,
      operationTypes,
      formulaGroups,
    ]
  );

  React.useEffect(() => {
    if (!premiationType.value) premiationType.setValue(premiationTypeOptions[0]);
    searchClientOptions();
    searchSellerOptions();
    searchEnterpriseOptions();
    searchPaymentTypes();
    searchOperationTypeOptions();
    searchFormulaGroupOptions();
  }, []); //eslint-disable-line

  return (
    <div className="container">
      <div>
        <form className={styles.filterContainer} onSubmit={handleSubmitSearchPremiations}>
          <div className={styles.fieldDiv} data-grid="clients">
            <label htmlFor="clients" className="label">
              Clientes
            </label>
            <SeparatorList
              id="clients"
              placeholder="Selecione os clientes para filtrar"
              options={clientOptions}
              value={clients.value}
              onChange={handleChangeClients}
              onInputChange={searchClientOptionsDebounced}
              isLoading={searchingClientOptions.isLoading}
              isDisabled={premiationList.premiacoes || searchingPremiations.isLoading || generatingReport.isLoading}
            />
          </div>
          <div className={styles.fieldDiv} data-grid="sellers">
            <label htmlFor="sellers" className="label">
              Vendedores
            </label>
            <SeparatorList
              id="sellers"
              placeholder="Selecione os vendedores para filtrar"
              options={sellerOptions}
              value={sellers.value}
              onChange={handleChangeSellers}
              onInputChange={searchSellerOptionsDebounced}
              isLoading={searchingSellerOptions.isLoading}
              isDisabled={premiationList.premiacoes || searchingPremiations.isLoading || generatingReport.isLoading}
            />
          </div>
          <div className={styles.fieldDiv} data-grid="enterprises">
            <label htmlFor="enterprises" className="label">
              Empresas
            </label>
            <Select
              id="enterprises"
              placeholder="Selecione as empresas para filtrar"
              options={enterpriseOptions}
              value={enterprises.value}
              error={enterprises.error}
              onChange={enterprises.onChange}
              onBlur={enterprises.onBlur}
              closeMenuOnSelect={false}
              isLoading={searchingEnterpriseOptions.isLoading}
              isMulti
              isDisabled={premiationList.premiacoes || searchingPremiations.isLoading || generatingReport.isLoading}
            />
          </div>
          <div className={styles.fieldDiv} data-grid="operationTypes">
            <label htmlFor="operationTypes" className="label">
              Tipos de Operação
            </label>
            <Select
              id="operationTypes"
              placeholder="Selecione os tipos de operação para filtrar"
              options={operationTypeOptions}
              value={operationTypes.value}
              onChange={operationTypes.onChange}
              closeMenuOnSelect={false}
              isClearable
              isMulti
              isLoading={searchingOperationTypeOptions.isLoading}
              isDisabled={premiationList.premiacoes || searchingPremiations.isLoading || generatingReport.isLoading}
            />
          </div>
          <div className={styles.fieldDiv} data-grid="premiationType">
            <label htmlFor="premiationType" className="label">
              Tipo de Premiação
            </label>
            <Select
              id="premiationType"
              options={premiationTypeOptions}
              value={premiationType.value}
              onChange={premiationType.onChange}
              isClearable={false}
              isSearchable={false}
              isDisabled={premiationList.premiacoes || searchingPremiations.isLoading || generatingReport.isLoading}
            />
          </div>
          <div className={styles.fieldDiv} data-grid="formulaGroups">
            <label htmlFor="formulaGroups" className="label">
              Grupos de Formulado
            </label>
            <Select
              id="formulaGroups"
              placeholder="Selecione os grupos para filtrar"
              options={formulaGroupOptions}
              value={formulaGroups.value}
              onChange={formulaGroups.onChange}
              closeMenuOnSelect={false}
              isSearchable={false}
              isMulti
              isLoading={searchingFormulaGroupOptions.isLoading}
              isDisabled={premiationList.premiacoes || searchingPremiations.isLoading || generatingReport.isLoading}
            />
          </div>
          <div className={styles.fieldDiv} data-grid="orderNumber">
            <label htmlFor="orderNumber" className="label">
              Número do Pedido
            </label>
            <InputMultiple
              id="orderNumber"
              placeholder="Digite o número do pedido"
              value={orderNumber}
              validate={(value) => /^\d+$/.test(value)}
              isDisabled={premiationList.premiacoes || searchingPremiations.isLoading || generatingReport.isLoading}
            />
          </div>
          <div className={styles.fieldDiv} data-grid="initialDate">
            <label htmlFor="initialDate" className="label">
              Data Inicial
            </label>
            <Input
              id="initialDate"
              type="date"
              value={initialDate.value}
              onChange={initialDate.onChange}
              disabled={premiationList.premiacoes || searchingPremiations.isLoading || generatingReport.isLoading}
            />
          </div>
          <div className={styles.fieldDiv} data-grid="finalDate">
            <label htmlFor="finalDate" className="label">
              Data Final
            </label>
            <Input
              id="finalDate"
              type="date"
              value={finalDate.value}
              onChange={finalDate.onChange}
              disabled={premiationList.premiacoes || searchingPremiations.isLoading || generatingReport.isLoading}
            />
          </div>
          <div className={styles.submitButtonContainer}>
            <div className={styles.generateReportContainer}>
              <nav>
                <span>
                  Gerar PDF <CaretDown weight="fill" />
                </span>
                <div>
                  <ul>
                    <li>
                      <Button
                        type="button"
                        variant="neutral"
                        onClick={() => {
                          handleClickGenerateReport("pdf", true);
                        }}>
                        Apenas Selecionados
                      </Button>
                    </li>
                    <li>
                      <Button
                        type="button"
                        variant="neutral"
                        onClick={() => {
                          handleClickGenerateReport("pdf");
                        }}>
                        Total Liberado
                      </Button>
                    </li>
                  </ul>
                </div>
              </nav>
              {/* <Button type="button" variant="neutral" disabled={true}>
                Gerar Excel
              </Button> */}
            </div>
            <Button variant={premiationList.premiacoes ? "danger" : ""}>
              {!premiationList.premiacoes ? "Buscar Premiações" : "Limpar Premiações"}
            </Button>
          </div>
        </form>
      </div>
      <span
        style={{
          display: "block",
          height: "4px",
          width: "100%",
          backgroundColor: "var(--gray-10)",
          margin: "20px 0 10px 0",
          borderRadius: "100%",
        }}></span>
      <div>
        <form className={`${styles.dataFormContainer}`} onSubmit={(e) => handleSubmitRequestPayment(e, premiationList)}>
          {premiationList.premiacoes && !requestingPayment.isLoading && !generatingReport.isLoading ? (
            <>
              <div className={styles.stepsContainer}>
                <button
                  type="button"
                  className={`${(currentStep === 1) | (currentStep === 2) | (currentStep === 3) ? "active" : ""}`}
                  onClick={gotoStepOne}>
                  <p>1</p>
                  <p>Seleção dos Itens</p>
                </button>
                <button
                  type="button"
                  className={`${(currentStep === 2) | (currentStep === 3) ? "active" : ""}`}
                  onClick={gotoStepTwo}>
                  <p>2</p>
                  <p>Detalhes de Pagamento</p>
                </button>
                <button type="button" className={`${currentStep === 3 ? "active" : ""}`} onClick={gotoStepThree}>
                  <p>3</p>
                  <p>Conferência</p>
                </button>
              </div>
              <div className={`${styles.dataFormContainer__main}`}>
                <div id="dataFormContainer__listDiv" className={`${styles.dataFormContainer__listDiv} customScrollbar`}>
                  {currentStep === 2 && (
                    <div className={styles.defaultPaymentTypeContainer}>
                      <label htmlFor="defaultPaymentType" className="label">
                        Método de Pagamento Padrão
                      </label>
                      <Select
                        id="defaultPaymentType"
                        options={paymentTypeOptions}
                        value={defaultPaymentType.value}
                        onChange={handleChangeDefaultPaymentType}
                        isClearable={false}
                      />
                    </div>
                  )}
                  <ul className={styles.dataForm__clientList}>
                    {currentStep === 1
                      ? premiationList.premiacoes.map((clientItem, index) => {
                          return (
                            <li key={index}>
                              <header>
                                <p>
                                  {clientItem.idCliente} - {clientItem.razaoSocial} - {clientItem.cidade}
                                </p>
                              </header>
                              <ul className={styles.dataForm__clientList__orderList}>
                                {clientItem.pedidos.map((orderItem, index) => {
                                  return (
                                    <li key={index}>
                                      <header>
                                        <p>
                                          <span>
                                            Pedido - {orderItem.idPedido} / {orderItem.idPedidoSiagri}
                                          </span>
                                        </p>
                                      </header>
                                      <ul className={styles.dataForm__clientList__orderList__premiationList}>
                                        {orderItem.premiacoes.map((premiationItem, index) => {
                                          return (
                                            <li key={index}>
                                              <div style={{ opacity: premiationItem.permitidoPagar <= 0 ? "0.4" : "" }}>
                                                <label
                                                  className={`${styles.premiatedCheckLabelContainer} ${
                                                    premiationItem.permitidoPagar <= 0 ? "disabled" : ""
                                                  }`}>
                                                  <div>
                                                    <input
                                                      className={styles.premiatedCheckInput}
                                                      type="checkbox"
                                                      onChange={(event) => {
                                                        handleChangeCheckPremiation(event, premiationItem);
                                                      }}
                                                      checked={premiationItem.controll.selected}
                                                      disabled={premiationItem.permitidoPagar <= 0}
                                                    />
                                                    <span />
                                                  </div>
                                                  <div>
                                                    <p
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "10px",
                                                        textTransform: "capitalize",
                                                      }}>
                                                      <span>{premiationItem.descricao}</span>
                                                      <span
                                                        style={{
                                                          display: "block",
                                                          height: "2px",
                                                          width: "30px",
                                                          backgroundColor: "var(--gray-8)",
                                                          borderRadius: "2px",
                                                        }}
                                                      />
                                                      <span>
                                                        {formatMoney(premiationItem.valorOriginal)} (Liberado:{" "}
                                                        {formatMoney(premiationItem.permitidoPagar)})
                                                      </span>
                                                    </p>
                                                    {premiationItem.motivo && (
                                                      <p
                                                        style={{
                                                          color: messageColor(premiationItem),
                                                          marginTop: "5px",
                                                        }}>
                                                        {premiationItem.motivo}
                                                      </p>
                                                    )}
                                                  </div>
                                                </label>
                                              </div>
                                              {premiationItem.notas !== null && (
                                                <div className={styles.notesContainer}>
                                                  <span className={styles.notesButton}>Notas</span>
                                                  <ul>
                                                    {premiationItem.notas.map((note, index) => {
                                                      return (
                                                        <li key={index}>
                                                          ID: {note.idNota} | Parcelas: {note.parcela} |{" "}
                                                          {formatDate(note.dataInsercao, "dd/MM/yy")} |{" "}
                                                          {formatMoney(note.valor)}
                                                        </li>
                                                      );
                                                    })}
                                                  </ul>
                                                </div>
                                              )}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </li>
                                  );
                                })}
                              </ul>
                            </li>
                          );
                        })
                      : premiationList.premiacoes
                          .filter((clientItem) => {
                            return clientItem.pedidos.some((orderItem) =>
                              orderItem.premiacoes.some((premiationItem) => premiationItem.controll.selected)
                            );
                          })
                          .map((clientItem, index) => {
                            return (
                              <li key={index}>
                                <header>
                                  <p>
                                    {clientItem.idCliente} - {clientItem.razaoSocial}
                                  </p>
                                </header>
                                <ul className={styles.dataForm__clientList__orderList}>
                                  {clientItem.pedidos
                                    .filter((orderItem) => {
                                      return orderItem.premiacoes.some(
                                        (premiationItem) => premiationItem.controll.selected
                                      );
                                    })
                                    .map((orderItem, index) => {
                                      return (
                                        <li key={index}>
                                          <header>
                                            <p>
                                              Pedido - {orderItem.idPedido} / {orderItem.idPedidoSiagri}
                                            </p>
                                          </header>
                                          <ul className={styles.dataForm__clientList__orderList__premiationList}>
                                            {orderItem.premiacoes
                                              .filter((premiationItem) => {
                                                return premiationItem.controll.selected;
                                              })
                                              .map((premiationItem, index) => {
                                                return (
                                                  <li
                                                    key={index}
                                                    style={{
                                                      opacity: premiationItem.permitidoPagar <= 0 ? "0.4" : "",
                                                    }}>
                                                    <div>
                                                      <label
                                                        className={`${styles.premiatedCheckLabelContainer} ${
                                                          premiationItem.permitidoPagar <= 0 ? "disabled" : ""
                                                        }`}>
                                                        <div>
                                                          <p
                                                            style={{
                                                              display: "flex",
                                                              alignItems: "center",
                                                              gap: "10px",
                                                              textTransform: "capitalize",
                                                            }}>
                                                            <span>{premiationItem.descricao}</span>
                                                            <span
                                                              style={{
                                                                display: "block",
                                                                height: "2px",
                                                                width: "30px",
                                                                backgroundColor: "var(--gray-8)",
                                                                borderRadius: "2px",
                                                              }}
                                                            />
                                                            <span>
                                                              {formatMoney(premiationItem.valorOriginal)} (Liberado:{" "}
                                                              {formatMoney(premiationItem.permitidoPagar)})
                                                            </span>
                                                          </p>
                                                          {premiationItem.motivo && (
                                                            <p
                                                              style={{
                                                                color: messageColor(premiationItem),
                                                                marginTop: "5px",
                                                              }}>
                                                              {premiationItem.motivo}
                                                            </p>
                                                          )}
                                                        </div>
                                                      </label>
                                                    </div>
                                                    {!(premiationItem.permitidoPagar <= 0) && (
                                                      <>
                                                        <div>
                                                          <label className={`label ${styles.paymentValueLabel}`}>
                                                            <span>Pagar:</span>
                                                            <input
                                                              className={`cleanInputNumber ${
                                                                premiationItem.controll.paymentValue >
                                                                  premiationItem.permitidoPagar ||
                                                                premiationItem.controll.paymentValue <= 0
                                                                  ? "isInvalid"
                                                                  : ""
                                                              }`}
                                                              type="number"
                                                              value={premiationItem.controll.paymentValue}
                                                              onWheel={(event) => {
                                                                event.target.blur();
                                                              }}
                                                              onKeyDown={(event) => {
                                                                const keys = ["e", "+", "-", "ArrowUp", "ArrowDown"];
                                                                if (keys.includes(event.key)) {
                                                                  event.preventDefault();
                                                                }
                                                              }}
                                                              onChange={(event) => {
                                                                handleChangePaymentValue(event, premiationItem);
                                                              }}
                                                              disabled={currentStep === 3}
                                                            />
                                                          </label>
                                                          <label className={`label ${styles.paymentTypeLabel}`}>
                                                            <span>Método:</span>
                                                            <select
                                                              value={premiationItem.controll.paymentType}
                                                              onChange={(event) => {
                                                                handleChangePaymentType(event, premiationItem);
                                                              }}
                                                              disabled={currentStep === 3}>
                                                              {paymentTypeOptions.map((option, index) => {
                                                                return (
                                                                  <option
                                                                    key={index}
                                                                    value={option.value.idTipoPagamento}>
                                                                    {option.label}
                                                                  </option>
                                                                );
                                                              })}
                                                            </select>
                                                          </label>
                                                        </div>
                                                      </>
                                                    )}
                                                  </li>
                                                );
                                              })}
                                          </ul>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </li>
                            );
                          })}
                  </ul>
                  {currentStep === 1 && !noMorePremiations && (
                    <div className={`loadingContainer ${styles.searchingMorePremiationsLoadingContainer}`}>
                      <p className="loadingMessage" ref={loadMoreRef}>
                        Aguarde enquanto buscamos mais itens liberados
                      </p>
                      <Circle size={30} />
                    </div>
                  )}
                </div>
                <div className={styles.dataFormContainer__infoDiv}>
                  <header>
                    <div>
                      <div className={styles.selectedInfo}>
                        <p>
                          <span className="label">Itens Selecionados </span> {dataInformations.totalSelectedPremiations}
                        </p>
                      </div>
                      <div className={styles.selectedInfo}>
                        <p>
                          <span className="label">Total Liberado </span> {formatMoney(dataInformations.totalReleased)}
                        </p>
                      </div>
                      <div className={styles.selectedInfo}>
                        <p>
                          <span className="label">Total à Solicitar </span>{" "}
                          {formatMoney(dataInformations.totalPaymentValue)}
                        </p>
                      </div>
                      <div className={styles.selectedInfo}>
                        <p>
                          <span className="label">Total TN </span> {Math.round(dataInformations.totalTons * 100) / 100}{" "}
                          Tn
                        </p>
                      </div>
                    </div>
                  </header>
                  <footer>
                    {currentStep === 1 ? (
                      <div>
                        <span></span>
                        <Button type="button" onClick={gotoStepTwo}>
                          Próximo
                        </Button>
                      </div>
                    ) : currentStep === 2 ? (
                      <div>
                        <Button type="button" variant="danger" onClick={gotoStepOne}>
                          Voltar
                        </Button>
                        <Button type="button" onClick={gotoStepThree}>
                          Próximo
                        </Button>
                      </div>
                    ) : (
                      <>
                        <div>
                          <Button type="button" variant="danger" onClick={gotoStepTwo}>
                            Voltar
                          </Button>
                          <Button type="submit">Solicitar</Button>
                        </div>
                      </>
                    )}
                  </footer>
                </div>
              </div>
            </>
          ) : requestingPayment.isLoading ? (
            <div className={`loadingContainer`}>
              <Circle size={100} />
              <span className="loadingMessage">Solicitando Pagamento</span>
            </div>
          ) : generatingReport.isLoading ? (
            <div className={`loadingContainer`}>
              <Circle size={100} />
              <span className="loadingMessage">
                Gerando Relatório
                <br />
                (Este processo pode levar até 15 minutos)
              </span>
            </div>
          ) : searchingPremiations.isLoading ? (
            <div className={`loadingContainer`}>
              <Circle size={100} />
              <span className="loadingMessage">
                Aguarde enquanto buscamos os itens liberados
                <br />
                (Este processo pode levar alguns minutos)
              </span>
            </div>
          ) : (
            <p className={`lineCardMessage`}>Nenhum item encontrado</p>
          )}
        </form>
      </div>
    </div>
  );
}
