import React from "react";
import ReactInputMask, { Props as ReactInputMaskProps } from "react-input-mask";

import styles from "./InputMask.module.css";

interface IProps extends ReactInputMaskProps {
  error: string | null;
}

export function InputMask({ mask, className = "", value, onChange, onBlur, error, ...props }: IProps) {
  return (
    <div className={styles.wrapper}>
      <ReactInputMask
        className={`${styles.input} ${error ? "isInvalid" : ""} ${String(value) ? "isFilled" : ""} ${className}`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        mask={mask}
        {...props}
      />
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
}
