import React from "react";

import { isValid } from "../../../../../../helpers/validations";

import { Button, LinkButton, Select } from "../../../../../../components/Form";
import { useCustomFetch, useDebounce, useLoading } from "../../../../../../hooks/async";
import { useModal } from "../../../../../../hooks/contexts";
import { useSelect } from "../../../../../../hooks/form";

import styles from "./NewClient.module.css";
import { Circle } from "../../../../../../components/Loading";

export function NewClient({ selectedPriceTable, searchClients: searchClientsInPriceTable }) {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const client = useSelect({ type: "single", required: true, emptyMessage: "Selecione um cliente" });
  const filterBy = useSelect({ type: "single", required: true });

  const searchingClients = useLoading();
  const registeringClient = useLoading();

  const [clientOptions, setClientOptions] = React.useState([]);

  const filterByOptions = [
    {
      value: "name",
      label: "Nome ou Id",
    },
    {
      value: "city",
      label: "Cidade",
    },
  ];

  const clearForm = React.useCallback(() => {
    client.reset();
    setClientOptions([]);
  }, [client]);

  const searchClients = React.useCallback(
    async (description = "", filterType) => {
      searchingClients.setLoading(true);
      try {
        const json = await customFetch("/products/searchClientsToAddInPriceTable", {
          body: {
            idTabela: Number(selectedPriceTable.tabela.idTabela),
            descricao: filterType === "name" ? description : "",
            descricaoCidade: filterType === "city" ? description : "",
          },
        });
        if (json.status === 200) {
          const options = json.object.map((option) => {
            return {
              value: { ...option },
              label: `${option.cliente.idCliente} - ${option.cliente.razaoSocial} | ${option.cidade.nomeCidade}`,
            };
          });
          setClientOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setClientOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingClients.setLoading(false);
      }
    },
    [Modal, customFetch, searchingClients, selectedPriceTable]
  );

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (isValid(client)) {
        try {
          registeringClient.setLoading(true);
          const json = await customFetch("/products/registerAndUpdateClientOfPriceTable", {
            body: {
              idTabela: Number(selectedPriceTable.tabela.idTabela),
              idCliente: Number(client.value.value.cliente.idCliente),
              status: true,
            },
          });
          if (json.status === 200) {
            await Modal.success(json.message);
            searchClientsInPriceTable();
            clearForm();
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          registeringClient.setLoading(false);
        }
      }
    },
    [Modal, clearForm, client, customFetch, registeringClient, searchClientsInPriceTable, selectedPriceTable]
  );

  const { current: searchClientDebounced } = React.useRef(
    useDebounce((description, filterType) => {
      searchClients(description, filterType);
    })
  );

  React.useEffect(() => {
    filterBy.setValue(filterByOptions[0]);
  }, []); // eslint-disable-line

  return (
    <div>
      {!registeringClient.isLoading ? (
        <>
          <div>
            <LinkButton to="/produtos/tabela-de-precos/atualizar/clientes" buttonStyle="backButton" />
          </div>
          <div className={styles.formContainer}>
            <form onSubmit={onSubmit}>
              <div className={styles.clientContainer}>
                <div>
                  <label htmlFor="filterBy" className="label">
                    Filtrar Por:
                  </label>
                  <Select
                    id="filterBy"
                    options={filterByOptions}
                    value={filterBy.value}
                    onChange={filterBy.onChange}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
                <div>
                  <label htmlFor="client" className="label">
                    Cliente
                  </label>
                  <Select
                    id="client"
                    placeholder="Selecione um cliente para adicionar"
                    options={clientOptions}
                    value={client.value}
                    error={client.error}
                    onChange={client.onChange}
                    onBlur={client.onBlur}
                    onInputChange={(value) => {
                      searchClientDebounced(value, filterBy.value.value);
                    }}
                    isLoading={searchingClients.isLoading}
                  />
                </div>
              </div>
              <div className={styles.submitButtonContainer}>
                <Button>Adicionar Cliente</Button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className={`loadingContainer`}>
          <Circle size={100} />
          <span className={`loadingMessage`}>Adicionando cliente à tabela</span>
        </div>
      )}
    </div>
  );
}
