import React from "react";
import { ArrowRight, CaretLeft, CaretRight } from "phosphor-react";
import { LinkButton, Select } from "../../../../../../components/Form";
import { useCustomFetch, useLoading } from "../../../../../../hooks/async";
import { useModal } from "../../../../../../hooks/contexts";
import { IItem } from "../../CommissionTableItems/CommissionTableItems";
import { IContractComission } from "../../ContractCommissionsTable";

import styles from "./ItemPeopleList.module.css";
import { Circle } from "../../../../../../components/Loading";
import { IItemPeople } from "../UpdateItemPeoples";

interface IPeople {
  idUsuario: number;
  nome: string;
}

interface ISearchPeopleOptionsResponse {
  response: number;
  message: string;
  status: number;
  object: IPeople[];
}

interface ISearchItemPeoplesResponse {
  response: number;
  message: string;
  status: number;
  object: IItemPeople[];
}

interface IEditItemProps {
  selectedTable: IContractComission | null;
  selectedItem: IItem | null;
  itemPeoples: {
    value: IItemPeople[];
    setValue: React.Dispatch<React.SetStateAction<IItemPeople[]>>;
  };
  peoples: IUseSelect<IPeople, "multiple">;
}

export function ItemPeopleList({ selectedItem, selectedTable, itemPeoples, peoples }: IEditItemProps) {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const [selectedRange, setSelectedRange] = React.useState(selectedItem?.faixaPrecoProduto[0] || null);

  const [peopleOptions, setPeopleOptions] = React.useState<{ label: string; value: IPeople }[]>([]);

  const searchingPeopleOptions = useLoading();
  const searchingItemPeoples = useLoading();

  const searchPeopleOptions = React.useCallback(
    async (description: string = "") => {
      try {
        searchingPeopleOptions.setLoading(true);
        const json = (await customFetch("/commissions/contracts/searchContractPeoples", {
          body: { descricao: description },
        })) as ISearchPeopleOptionsResponse;
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            label: `${option.idUsuario} | ${option.nome}`,
            value: option,
          }));
          setPeopleOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setPeopleOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingPeopleOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingPeopleOptions]
  );

  const searchItemPeoples = async (peopleIds: number[], tableId: number, formulaId: number) => {
    try {
      searchingItemPeoples.setLoading(true);
      const json = (await customFetch("/commissions/contracts/searchContractPeopleItems", {
        body: {
          idEmpresa: selectedTable?.empresa.idEmpresa,
          idVendedor: peopleIds,
          idContrato: tableId,
          idFormulado: formulaId,
        },
      })) as ISearchItemPeoplesResponse;

      if (json.status === 200) {
        itemPeoples.setValue(json.object);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        itemPeoples.setValue([]);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingItemPeoples.setLoading(false);
    }
  };

  const handleClickChangeRange = (action: "prev" | "next") => {
    const ranges = selectedItem!.faixaPrecoProduto;
    const rangesLength = ranges.length;
    const currentIndex = ranges.findIndex((i) => i.idFaixaPrecoProduto === selectedRange?.idFaixaPrecoProduto);
    if (action === "prev") {
      if (currentIndex === 0) {
        setSelectedRange(ranges[rangesLength - 1]);
      } else {
        setSelectedRange(ranges[currentIndex - 1]);
      }
    } else {
      if (currentIndex === rangesLength - 1) {
        setSelectedRange(ranges[0]);
      } else {
        setSelectedRange(ranges[currentIndex + 1]);
      }
    }
  };

  React.useEffect(() => {
    searchPeopleOptions();
    if (!itemPeoples.value.length && peoples.value.length) {
      const peopleIds = peoples.value.map((item) => item.value.idUsuario);
      searchItemPeoples(peopleIds, selectedTable!.idContrato, selectedItem!.idFormulado);
    }
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.navContainer}>
        <LinkButton to="/comissoes/adubo/comissoes-de-contratos/itens" buttonStyle="backButton" />
        <h2 className={styles.navContainer__title}>
          {selectedTable?.descricao} - Fórmula {selectedItem?.formula.descricaoFormulado}
        </h2>
        <div>
          <LinkButton
            to="novas"
            variant={undefined}
            className={undefined}
            classNameContainer={undefined}
            disabled={undefined}>
            Adicionar/Atualizar Pessoas
          </LinkButton>
        </div>
      </div>
      <div>
        <div className={styles.filterContainer}>
          <label htmlFor="" className="label">
            Pessoas
          </label>
          <Select
            placeholder={"Selecione as pessoas para buscar as faixas"}
            options={peopleOptions}
            value={peoples.value}
            onChange={(value: any) => {
              peoples.onChange(value);
              if (value.length) {
                const peopleIds = value.map((item: { label: string; value: IPeople }) => item.value.idUsuario);
                searchItemPeoples(peopleIds, selectedTable!.idContrato, selectedItem!.idFormulado);
              } else {
                itemPeoples.setValue([]);
              }
            }}
            defaultValue={undefined}
            error={undefined}
            isLoading={searchingPeopleOptions.isLoading}
            isMulti={true}
          />
        </div>
        <div className={styles.contentContainer}>
          {itemPeoples.value.length && !searchingItemPeoples.isLoading ? (
            <>
              <div className={styles.rangeContainer}>
                <div>
                  <p className={styles.itemTitle}>{}</p>
                  <span className={styles.itemSeparator}></span>
                  <div className={styles.commissionsContainer}>
                    {selectedItem?.faixaPrecoProduto.map((commission, commissionIndex) => (
                      <div
                        className={`${styles.commissionBlock} ${
                          selectedRange?.idFaixaPrecoProduto === commission.idFaixaPrecoProduto ? "isActive" : ""
                        }`}
                        key={commissionIndex}
                        onClick={() => {
                          setSelectedRange(commission);
                        }}>
                        <p>
                          {selectedItem.tipoComissao}. {commission.perMaximo}
                        </p>
                        <div className={styles.ball}>
                          <div style={{ backgroundColor: commission.rgb }}></div>
                          <span style={{ backgroundColor: commission.rgb }}></span>
                        </div>
                        <p>Com. {commission.perComissao}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={styles.detailsContainer}>
                <p className={styles.detailsContainer__title}>Detalhes da Faixa</p>
                <span className={styles.detailsContainer__separator}></span>
                <div className={styles.detailsContainer__contentContainer + " customScrollbar"}>
                  {itemPeoples.value.map((item, index) => (
                    <div key={index} className={styles.detailsContainer__content}>
                      <p className={styles.detailsContainer__content__title}>{item.nomeVendedor}</p>
                      <div className={styles.detailsContainer__content__rangeDetailsContainer}>
                        <button
                          className={styles.detailsContainer__content__rangeDetailsButton}
                          onClick={() => handleClickChangeRange("prev")}>
                          <CaretLeft weight="fill" width={"2rem"} height={"2rem"} />
                        </button>
                        <div className={styles.detailsContainer__content__rangeDetails}>
                          <div className={styles.detailsContainer__content__rangeDetails__detail}>
                            <p>
                              {selectedItem?.tipoComissao && selectedItem?.tipoComissao === "Mar"
                                ? "Margem"
                                : "Desconto"}
                            </p>
                            <p>{selectedRange?.perMaximo} %</p>
                          </div>
                          <div
                            className={styles.detailsContainer__content__rangeDetails__ball}
                            style={{ backgroundColor: selectedRange?.rgb }}></div>
                          <div className={styles.detailsContainer__content__rangeDetails__detail}>
                            <p>Comissão</p>
                            <p>{selectedRange?.perComissao} %</p>
                          </div>
                        </div>
                        <button
                          className={styles.detailsContainer__content__rangeDetailsButton}
                          onClick={() => handleClickChangeRange("next")}>
                          <CaretRight weight="fill" width={"2rem"} height={"2rem"} />
                        </button>
                      </div>
                      <span className={styles.detailsContainer__content__separator}></span>
                      <div className={styles.detailsContainer__content__participantsDetails}>
                        <div className={styles.detailsContainer__content__participantsDetails__detail}>
                          <p>Vendedor</p>
                          <p>{item.nomeVendedor}</p>
                        </div>
                        <div className={styles.detailsContainer__content__participantsDetails__detail}>
                          <p>Participantes</p>
                          {item.faixaProduto
                            .filter((range) => selectedRange?.idFaixaPrecoProduto === range.idFaixaPrecoProduto)[0]
                            .faixaComissao.sort((a, b) => {
                              return b.percentual - a.percentual;
                            })
                            .map((range, index) => (
                              <p key={index} style={{ display: "flex", gap: "10px" }}>
                                {range.nomePessoa}{" "}
                                <span style={{ display: "flex", alignItems: "center" }}>
                                  <ArrowRight />
                                </span>{" "}
                                {range.percentual} %
                              </p>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : searchingItemPeoples.isLoading ? (
            <div className={`loadingContainer ${styles.loadingContainer}`}>
              <Circle size={100} />
            </div>
          ) : (
            <p className={`lineCardMessage`}>Selecione ao menos uma pessoa para buscar as faixas</p>
          )}
        </div>
      </div>
    </div>
  );
}
