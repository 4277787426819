import React from "react";

import { Select } from "../../../../components/Form/Select/Select";
import { Button, LinkButton } from "../../../../components/Form";
import { Input } from "../../../../components/Form";
import { InputMask } from "../../../../components/Form/InputMask/InputMask";

import { useForm, useSelect } from "../../../../hooks/form";
import { useChanges, useModal } from "../../../../hooks/contexts";
import { useCustomFetch } from "../../../../hooks/async";

import { isValid } from "../../../../helpers/validations";

import { Circle } from "../../../../components/Loading/Circle/Circle";

import styles from "./NewUser.module.css";

export function NewUser({
  optionsEnterprise,
  optionsPortal,
  optionsSupervisor,
  isSearchingOptionsEnterprise,
  isSearchingOptionsPortal,
  isSearchingOptionsSupervisor,
}) {
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const { isChangesDetected, setIsChangesDetected } = useChanges();

  const enterprises = useSelect({ type: "multiple", required: true, emptyMessage: "Selecione ao menos uma empresa" });
  const portals = useSelect({ type: "multiple", required: true, emptyMessage: "Selecione ao menos um portal" });
  const supervisor = useSelect({ type: "single", required: true, emptyMessage: "Selecione um supervisor" });
  const defaultEnterprise = useSelect({
    type: "single",
    required: !!enterprises.value?.length,
    emptyMessage: "Selecione uma empresa padrão",
  });
  const defaultPortal = useSelect({
    type: "single",
    required: !!portals.value?.length,
    emptyMessage: "Selecione um portal padrão",
  });
  const name = useForm({ type: "", required: true });
  const email = useForm({ type: "email", required: true });
  const idSiagri = useForm({ type: "", required: true });
  const password = useForm({ type: "", required: true });
  const passwordConfirm = useForm({
    type: "",
    required: true,
    customValidations: [
      {
        validate: (value) => value === password.value,
        message: "As senhas não correspondem",
      },
    ],
  });
  const [isSupervisor, setIsSupervisor] = React.useState(false);
  const registration = useForm({ type: "number", required: false });
  const codeHPS = useForm({ type: "number", required: false });
  const countryCode = useForm({ type: "", required: false });
  const ddd = useForm({ type: "", required: false });
  const phone = useForm({ type: "", required: false });

  const [isRegisteringUser, setIsRegisteringUser] = React.useState(false);

  const clearForm = React.useCallback(() => {
    enterprises.setValue([]);
    portals.setValue([]);
    defaultEnterprise.setValue("");
    defaultPortal.setValue("");
    name.setValue("");
    email.setValue("");
    idSiagri.setValue("");
    password.setValue("");
    passwordConfirm.setValue("");
    setIsSupervisor(false);
    setIsChangesDetected(false);
    supervisor.reset();
    registration.reset();
    codeHPS.reset();
    countryCode.reset();
    ddd.reset();
    phone.reset();
  }, [
    defaultEnterprise,
    defaultPortal,
    email,
    enterprises,
    idSiagri,
    name,
    password,
    passwordConfirm,
    portals,
    setIsChangesDetected,
    supervisor,
    registration,
    codeHPS,
    countryCode,
    ddd,
    phone,
  ]);

  const handleChangeEnterprises = React.useCallback(
    (value) => {
      const options = value.sort((a, b) => a.value.idEmpresa - b.value.idEmpresa);
      enterprises.setValue(options);

      if (
        (defaultEnterprise.value && !options.some((option) => option.label === defaultEnterprise.value.label)) ||
        !options.length
      ) {
        defaultEnterprise.setValue("");
        defaultEnterprise.setError(null);
      }

      setIsChangesDetected(true);
    },
    [enterprises, defaultEnterprise, setIsChangesDetected]
  );

  const handleChangePortals = React.useCallback(
    (value) => {
      const options = value.sort((a, b) => a.value.idPortal - b.value.idPortal);
      portals.setValue(options);

      if (
        (defaultPortal.value && !options.some((option) => option.label === defaultPortal.value.label)) ||
        !options.length
      ) {
        defaultPortal.setValue("");
        defaultPortal.setError(null);
      }

      setIsChangesDetected(true);
    },
    [portals, defaultPortal, setIsChangesDetected]
  );

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      setIsRegisteringUser(true);
      if (
        isValid(
          name,
          email,
          idSiagri,
          password,
          passwordConfirm,
          enterprises,
          portals,
          defaultEnterprise,
          defaultPortal,
          registration,
          codeHPS,
          countryCode,
          ddd,
          phone
        )
      ) {
        try {
          const json = await customFetch("/admin/registerAndUpdateUser", {
            body: {
              idSiagri: Number(idSiagri.value),
              idUsuario: null,
              empresaPadraoPortalAntigo: defaultEnterprise.value?.value.idEmpresa,
              portalPadraoAntigo: defaultPortal.value?.value.idPortal,
              empresasPadroes: enterprises.value?.map((enterprise) => enterprise.value.idEmpresa) || [],
              portalPadroes: portals.value?.map((portal) => portal.value.idPortal) || [],
              email: email.value,
              nome: name.value,
              senhaNova: password.value,
              senhaNovaNovamente: passwordConfirm.value,
              resetarSenhaAntiga: false,
              eSupervisor: isSupervisor,
              matricula: registration.value,
              idSupervisor: supervisor?.value?.value?.id ?? null,
              idUsuarioHPS: Number(codeHPS.value),
              codigoPaisTelefone: countryCode.value,
              dddTelefone: ddd.value,
              telefone: phone.value,
            },
          });
          if (json.status === 200) {
            Modal.success(json.message);
            clearForm();
          } else if (json.status === 500) {
            throw json.message;
          }
        } catch (error) {
          Modal.error(error);
        }
      }
      setIsRegisteringUser(false);
    },
    [
      Modal,
      defaultEnterprise,
      defaultPortal,
      email,
      enterprises,
      idSiagri,
      isSupervisor,
      name,
      password,
      passwordConfirm,
      portals,
      clearForm,
      customFetch,
      supervisor,
      registration,
      codeHPS,
      countryCode,
      ddd,
      phone,
    ]
  );

  return (
    <div className={styles.container}>
      {!isRegisteringUser ? (
        <>
          <div>
            <LinkButton to="/admin/usuarios" buttonStyle="backButton" />
          </div>
          <form className={styles.form} onSubmit={handleSubmit} autoComplete="off">
            <>
              <div>
                <fieldset className={`${styles.userDetailsContainer} ${styles.fieldset}`}>
                  <legend className={styles.fieldsetLegend}>Detalhes do Usuário</legend>
                  <div>
                    <label className={`label`}>Nome</label>
                    <Input
                      placeholder="Digite o nome do usuário"
                      value={name.value}
                      onChange={(e) => {
                        name.onChange(e);
                        setIsChangesDetected(true);
                      }}
                      onBlur={name.onBlur}
                      error={name.error}
                    />
                  </div>
                  <div>
                    <label className={`label`}>E-mail</label>
                    <Input
                      placeholder="Digite o email do usuário"
                      value={email.value}
                      onChange={(e) => {
                        email.onChange(e);
                        setIsChangesDetected(true);
                      }}
                      onBlur={email.onBlur}
                      error={email.error}
                    />
                  </div>
                  <div className={styles.userOptionsContainer}>
                    <div>
                      <label className={`label`}>ID do Siagri</label>
                      <Input
                        className={`cleanInputNumber`}
                        type="number"
                        placeholder="ID"
                        value={idSiagri.value}
                        onChange={(e) => {
                          idSiagri.onChange(e);
                          setIsChangesDetected(true);
                        }}
                        onBlur={idSiagri.onBlur}
                        error={idSiagri.error}
                      />
                    </div>
                    <div className={`${styles.checkboxContainer}`}>
                      <label>
                        <input
                          type="checkbox"
                          value={isSupervisor}
                          onChange={() => setIsSupervisor(!isSupervisor)}
                          checked={isSupervisor}
                        />
                        Definir usuário como Supervisor
                      </label>
                    </div>
                  </div>
                  <div className={styles.userPasswordContainer}>
                    <div>
                      <label className={`label`}>Senha</label>
                      <Input
                        type="password"
                        placeholder="Digite a senha do usuário"
                        value={password.value}
                        onChange={(e) => {
                          password.onChange(e);
                          setIsChangesDetected(true);
                        }}
                        onBlur={password.onBlur}
                        error={password.error}
                        autoComplete="new-password"
                      />
                    </div>
                    <div>
                      <label className={`label`}>Confirmar Senha</label>
                      <Input
                        type="password"
                        placeholder="Digite a senha do usuário"
                        value={passwordConfirm.value}
                        onChange={(e) => {
                          passwordConfirm.onChange(e);
                          setIsChangesDetected(true);
                        }}
                        onBlur={passwordConfirm.onBlur}
                        error={passwordConfirm.error}
                        autoComplete="new-password"
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className={`${styles.permissionsContainer}`}>
                <fieldset className={`${styles.fieldset} ${styles.optionsPermissionsContainer}`}>
                  <legend className={styles.fieldsetLegend}>Permissões de Empresas</legend>
                  <div className={styles.selectMultipleContainer}>
                    <label className={`label`}>Empresas</label>
                    <Select
                      placeholder="Selecione as empresas que serão liberadas para o usuário"
                      value={enterprises.value}
                      error={enterprises.error}
                      onChange={handleChangeEnterprises}
                      onBlur={enterprises.onBlur}
                      options={optionsEnterprise}
                      noOptionsMessage={() => "Nenhuma empresa foi encontrada"}
                      closeMenuOnSelect={false}
                      isLoading={isSearchingOptionsEnterprise}
                      isMulti
                    />
                  </div>
                  <div>
                    <label className={`label`}>Empresa Padrão</label>
                    <Select
                      placeholder="Selecione uma empresa"
                      options={enterprises.value}
                      value={defaultEnterprise.value}
                      error={defaultEnterprise.error}
                      onChange={(value) => defaultEnterprise.setValue(value)}
                      onBlur={defaultEnterprise.onBlur}
                      noOptionsMessage={() => "Nenhuma empresa foi permitida ao usuário"}
                      isDisabled={!enterprises.value.length}
                    />
                  </div>
                </fieldset>
                <fieldset className={`${styles.fieldset} ${styles.optionsPermissionsContainer}`}>
                  <legend className={styles.fieldsetLegend}>Permissões de Portais</legend>
                  <div className={styles.selectMultipleContainer}>
                    <label className={`label`}>Portais</label>
                    <Select
                      placeholder="Selecione os portais que serão liberados para o usuário"
                      value={portals.value}
                      error={portals.error}
                      onChange={handleChangePortals}
                      onBlur={portals.onBlur}
                      options={optionsPortal}
                      noOptionsMessage={() => "Nenhum portal foi encontrado"}
                      closeMenuOnSelect={false}
                      isLoading={isSearchingOptionsPortal}
                      isMulti
                    />
                  </div>
                  <div>
                    <label className={`label`}>Portal Padrão</label>
                    <Select
                      placeholder="Selecione um portal"
                      options={portals.value}
                      value={defaultPortal.value}
                      error={defaultPortal.error}
                      onChange={(value) => defaultPortal.setValue(value)}
                      onBlur={defaultPortal.onBlur}
                      noOptionsMessage={() => "Nenhum portal foi permitido ao usuário"}
                      isDisabled={!portals.value.length}
                    />
                  </div>
                </fieldset>
              </div>
              <div className={styles.othersContainer}>
                <fieldset className={`${styles.fieldset}`}>
                  <legend className={styles.fieldsetLegend}>Outras Opções</legend>
                  <div className={styles.supervisorContainer}>
                    <label className="label">Supervisor</label>
                    <Select
                      placeholder="Selecione o supervisor do usuário"
                      value={supervisor.value}
                      //error={supervisor.error}
                      onChange={(value) => {
                        supervisor.onChange(value);
                        setIsChangesDetected(true);
                      }}
                      onBlur={supervisor.onBlur}
                      options={optionsSupervisor}
                      noOptionsMessage={() => "Nenhum supervisor foi encontrado"}
                      isLoading={isSearchingOptionsSupervisor}
                    />
                  </div>
                  <div>
                    <label className="label">Matrícula</label>
                    <Input
                      placeholder="Digite a matrícula do usuário"
                      type="number"
                      className="cleanInputNumber"
                      value={registration.value}
                      error={registration.error}
                      onChange={(e) => {
                        registration.onChange(e);
                        setIsChangesDetected(true);
                      }}
                      onBlur={registration.onBlur}
                    />
                  </div>
                  <div>
                    <label className="label">Código HPS</label>
                    <Input
                      placeholder="Digite o código HPS do usuário"
                      type="number"
                      className="cleanInputNumber"
                      value={codeHPS.value}
                      error={codeHPS.error}
                      onChange={(e) => {
                        codeHPS.onChange(e);
                        setIsChangesDetected(true);
                      }}
                      onBlur={codeHPS.onBlur}
                    />
                  </div>
                </fieldset>
              </div>
              <div className={styles.phoneContainer}>
                <fieldset className={`${styles.fieldset}`}>
                  <legend className={styles.fieldsetLegend}>Telefone</legend>
                  <div>
                    <label className="label">Código do País</label>
                    <InputMask
                      mask={"+99"}
                      placeholder="+XX"
                      value={countryCode.value}
                      onChange={(e) => {
                        countryCode.onChange(e);
                        setIsChangesDetected(true);
                      }}
                    />
                  </div>
                  <div>
                    <label className="label">DDD</label>
                    <InputMask
                      mask={"99"}
                      placeholder="XX"
                      value={ddd.value}
                      onChange={(e) => {
                        ddd.onChange(e);
                        setIsChangesDetected(true);
                      }}
                    />
                  </div>
                  <div className={styles.phoneFieldContainer}>
                    <label className="label">Telefone</label>
                    <InputMask
                      mask={"\\9 9999-9999"}
                      placeholder="X XXXX-XXXX"
                      value={phone.value}
                      onChange={(e) => {
                        phone.onChange(e);
                        setIsChangesDetected(true);
                      }}
                    />
                  </div>
                </fieldset>
              </div>
              <div className={styles.submitButtonContainer}>
                <Button disabled={!isChangesDetected}>Cadastrar Usuário</Button>
              </div>
            </>
          </form>
        </>
      ) : (
        <div className={styles.registeringUserLoadingContainer}>
          <Circle size={100} />
          <span>Cadastrando novo usuário</span>
        </div>
      )}
    </div>
  );
}
