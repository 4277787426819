import React from "react";

import { Route, Routes } from "react-router-dom";

import { useForm } from "../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../hooks/async";
import { useModal } from "../../../hooks/contexts";
import { usePagination } from "../../../hooks/pagination";

import { PriceTableList } from "./PriceTableList/PriceTableList";
import { NewPriceTable } from "./NewPriceTable/NewPriceTable";
import { UpdatePriceTable } from "./UpdatePriceTable/UpdatePriceTable";

export function ProductPriceTable() {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const searchedPriceTable = useForm({ type: "", required: false });

  const searchingPriceTables = useLoading();
  const pagination = usePagination();

  const [selectedPriceTable, setSelectedPriceTable] = React.useState(null);
  const [priceTableList, setTableList] = React.useState([]);

  const clearMicroFilter = React.useCallback(() => {
    searchedPriceTable.setValue("");
  }, [searchedPriceTable]);

  const searchTotalPriceTableRecords = React.useCallback(
    (table = "") => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/products/searchTotalPriceTableRecords", {
            body: {
              tamanho: pagination.maxItems,
              descricao: table,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const searchPriceTables = React.useCallback(
    async (table = "", page) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/products/searchPriceTablesPaginated", {
            body: {
              pagina: page ? Number(page) - 1 : 0,
              tamanho: pagination.maxItems,
              descricao: table,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const researchPriceTables = React.useCallback(
    async (priceTable, page) => {
      try {
        searchingPriceTables.setLoading(true);
        const json = await searchPriceTables(priceTable, page);
        if (json.status === 200) {
          setTableList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingPriceTables.setLoading(false);
      }
    },
    [Modal, searchPriceTables, searchingPriceTables]
  );

  const searchTotalPriceTableRecordsAndPriceTables = React.useCallback(
    async (table = "") => {
      if (table === "") {
        clearMicroFilter();
      }
      try {
        searchingPriceTables.setLoading(true);
        const jsonGroups = await searchPriceTables(table);
        if (jsonGroups.status === 200) {
          const jsonRecords = await searchTotalPriceTableRecords(table);
          if (jsonRecords.status === 200) {
            setTableList(jsonGroups.object);
            pagination.setTotalRecords(jsonRecords.object.total);
          } else if (jsonRecords.status === 500) {
            Modal.error(jsonRecords.message, jsonRecords.object);
          } else {
            setTableList([]);
          }
        } else if (jsonGroups.status === 500) {
          Modal.error(jsonGroups.message, jsonGroups.object);
        } else {
          setTableList([]);
          pagination.reset();
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        pagination.setCurrentPage(1);
        searchingPriceTables.setLoading(false);
      }
    },
    [Modal, clearMicroFilter, pagination, searchPriceTables, searchTotalPriceTableRecords, searchingPriceTables]
  );

  React.useEffect(() => {
    searchTotalPriceTableRecordsAndPriceTables();
  }, []); // eslint-disable-line

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <PriceTableList
              searchedPriceTable={searchedPriceTable}
              searchPriceTables={searchPriceTables}
              searchTotalPriceTableRecordsAndPriceTables={searchTotalPriceTableRecordsAndPriceTables}
              priceTableList={{ value: priceTableList, setValue: setTableList }}
              searchingPriceTables={searchingPriceTables}
              setSelectedPriceTable={setSelectedPriceTable}
              pagination={pagination}
            />
          }
        />
        <Route
          path="novo"
          element={
            <NewPriceTable searchTotalPriceTableRecordsAndPriceTables={searchTotalPriceTableRecordsAndPriceTables} />
          }
        />
        <Route
          path="atualizar/*"
          element={
            <UpdatePriceTable
              selectedPriceTable={selectedPriceTable}
              researchPriceTables={(priceTable) => researchPriceTables(priceTable, pagination.currentPage)}
              filteredPriceTable={searchedPriceTable.value}
            />
          }
        />
      </Routes>
    </div>
  );
}
