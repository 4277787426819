import React from "react";
import { Button, Input, LinkButton, Select } from "../../../../components/Form";

import styles from "./AdicionarAtualizar.module.css";
import { useAdicionarAtualizar } from "./hooks/useAdicionarAtualizar";
import { Circle } from "../../../../components/Loading";
import { IObjeto, IUseObjetos } from "../hooks/types";
import { useNavigate } from "react-router-dom";

interface IProps {
  tipo: "insert" | "update";
  objetosData: IUseObjetos;
  selectedObjeto?: IObjeto | null;
}

export function AdicionarAtualizar({ tipo, objetosData, selectedObjeto }: IProps) {
  const navigate = useNavigate();

  const {
    carregarDados,
    form: { nomeObjeto, status },
    selectOptions: { statusOptions },
    interactions: { insertOrUpdateObjeto, insertingOrUpdatingObjeto },
  } = useAdicionarAtualizar({ objetosData });

  React.useEffect(() => {
    if (tipo === "update" && selectedObjeto) {
      if (!selectedObjeto) navigate("/contratos-fornecedor-cliente/objetos");
      else carregarDados(selectedObjeto);
    }
  }, []); // eslint-disable-line

  return (
    <div className="container">
      {!insertingOrUpdatingObjeto ? (
        <>
          <div>
            <LinkButton
              to="/contratos-fornecedor-cliente/objetos"
              buttonStyle="backButton"
              className={styles.navButton}
            />
          </div>
          <span className="separator" />
          <div className={styles.fieldsContainer}>
            <div>
              <label htmlFor="nomeObjeto" className="label">
                Nome Objeto
              </label>
              <Input
                id="nomeObjeto"
                placeholder="Digite o nome do objeto"
                value={nomeObjeto.value}
                error={nomeObjeto.error}
                onChange={nomeObjeto.onChange}
                onBlur={nomeObjeto.onBlur}
              />
            </div>
            {tipo === "update" && (
              <div className={styles.statusContainer}>
                <label htmlFor="status" className="label">
                  Situação
                </label>
                <Select
                  id="status"
                  options={statusOptions}
                  value={status.value}
                  onChange={status.onChange}
                  isClearable={false}
                  isSearchable={false}
                />
              </div>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => {
                if (tipo === "insert") insertOrUpdateObjeto();
                else insertOrUpdateObjeto(selectedObjeto?.idObjeto);
              }}>
              Concluir
            </Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <p className="loadingMessage">{tipo === "insert" ? "Inserindo Novo Objeto" : "Atualizando Objeto"}</p>
        </div>
      )}
    </div>
  );
}
