import React from "react";
import { Button, Input, InputMask, LinkButton, Select } from "../../../../components/Form";
import { useForm, useSelect } from "../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useChanges, useModal } from "../../../../hooks/contexts";
import { Navigate, useNavigate } from "react-router-dom";
import { isValid } from "../../../../helpers/validations";
import { Circle } from "../../../../components/Loading";
import { IBank } from "../../Bank/List/List";
import { IPaymentMethod } from "../../PaymentMethod/List/List";
import { IUserBankAccount } from "../UserBankAccount";
import { IPeople } from "../../types";

import styles from "./UpdateUserBankAccount.module.css";

interface IProps {
  selectedUserBankAccount: IUserBankAccount | null;
  searchUsersBankAccount: () => void;
}

export function UpdateUserBankAccount({ selectedUserBankAccount, searchUsersBankAccount }: IProps) {
  const Modal = useModal();
  const Changes = useChanges();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();

  const user = useSelect({ type: "single", required: true });
  const bank = useSelect({ type: "single", required: true });
  const paymentMethod = useSelect({ type: "single", required: true });
  const bankBranch = useForm({ required: true });
  const bankAccountNumber = useForm({ required: true });
  const bankCheckDigit = useForm({ required: true });
  const status = useSelect({ type: "single", required: true });

  const [userOptions, setUserOptions] = React.useState<ISelectOption<IPeople>[]>([]);
  const [bankOptions, setBankOptions] = React.useState<ISelectOption<IBank>[]>([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = React.useState<ISelectOption<IPaymentMethod>[]>([]);

  const statusOptions = React.useMemo(
    () => [
      {
        value: "A",
        label: "Ativo",
      },
      {
        value: "I",
        label: "Inativo",
      },
    ],
    []
  );

  const searchingUsers = useLoading();
  const searchingBanks = useLoading();
  const searchingPaymentMethods = useLoading();
  const savingUserBankAccount = useLoading();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const searchUsers = React.useCallback(async () => {
    try {
      searchingUsers.setLoading(true);
      const json = (await customFetch("/finance/searchUsers", {
        body: {
          id: 0,
          paginacao: {
            paginaNumero: 0,
            paginaTamanho: 0,
          },
        },
      })) as DefaultFetchResponse<IPeople[]>;
      if (json.status === 200) {
        const options = json.object.map((item) => ({
          value: item,
          label: `${item.idUsuario} | ${item.nome}`,
        }));
        setUserOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setUserOptions([]);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingUsers.setLoading(false);
    }
  }, [Modal, customFetch, searchingUsers]);

  const searchBanks = React.useCallback(async () => {
    try {
      searchingBanks.setLoading(true);
      const json = (await customFetch("/finance/searchBanksPaginated", {
        body: { id: 0, paginacao: { paginaNumero: 0, paginaTamanho: 0 } },
      })) as DefaultFetchResponse<IBank[]>;
      if (json.status === 200) {
        const options = json.object.map((item) => ({
          value: item,
          label: `${item.bancoId} | ${item.descricao}`,
        }));
        setBankOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setBankOptions([]);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingBanks.setLoading(false);
    }
  }, [Modal, customFetch, searchingBanks]);

  const searchPaymentMethods = React.useCallback(async () => {
    try {
      searchingPaymentMethods.setLoading(true);
      const json = (await customFetch("/finance/searchPaymentMethodsPaginated", {
        body: { id: 0, paginacao: { paginaNumero: 0, paginaTamanho: 0 } },
      })) as DefaultFetchResponse<IPaymentMethod[]>;
      if (json.status === 200) {
        const options = json.object.map((item) => ({
          value: item,
          label: `${item.bancoFormaPagamentoId} | ${item.descricao}`,
        }));
        setPaymentMethodOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setPaymentMethodOptions([]);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingPaymentMethods.setLoading(false);
    }
  }, [Modal, customFetch, searchingPaymentMethods]);

  const saveUserBankAccount = React.useCallback(async () => {
    if (!isValid(user, bank, paymentMethod, bankBranch, bankAccountNumber, bankCheckDigit, status)) return;
    try {
      savingUserBankAccount.setLoading(true);
      const json = (await customFetch("/finance/insertAndUpdateUserBankAccount", {
        body: {
          usuarioContaId: selectedUserBankAccount?.usuarioContaId,
          usuarioId: user.value?.value.idUsuario,
          bancoId: bank.value?.value.bancoId,
          bancoFormaPagamentoId: paymentMethod.value?.value.bancoFormaPagamentoId,
          agencia: bankBranch.value.replace(/\D/g, ""),
          conta: bankAccountNumber.value.replace(/\D/g, ""),
          digitoVerificador: bankCheckDigit.value,
          status: status.value?.value,
        },
      })) as DefaultFetchResponse<null>;
      if (json.status === 200) {
        Changes.reset();
        searchUsersBankAccount();
        await Modal.success(json.message);
        navigate("/financeiro/contas");
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      savingUserBankAccount.setLoading(false);
    }
  }, [
    user,
    bank,
    paymentMethod,
    bankBranch,
    bankAccountNumber,
    bankCheckDigit,
    savingUserBankAccount,
    status,
    customFetch,
    searchUsersBankAccount,
    Modal,
    Changes,
    navigate,
    selectedUserBankAccount,
  ]);

  React.useEffect(() => {
    // searchUsers();
    searchBanks();
    searchPaymentMethods();

    if (selectedUserBankAccount) {
      user.setValue({
        value: { idUsuario: selectedUserBankAccount.usuario?.idUsuario, nome: selectedUserBankAccount.usuario?.nome },
        label: `${selectedUserBankAccount.usuario?.idUsuario} | ${selectedUserBankAccount.usuario?.nome}`,
      });
      bank.setValue({
        value: {
          bancoId: selectedUserBankAccount.banco?.bancoId,
          descricao: selectedUserBankAccount.banco?.descricao,
        },
        label: `${selectedUserBankAccount.banco?.bancoId} | ${selectedUserBankAccount.banco?.descricao}`,
      });
      paymentMethod.setValue({
        value: {
          bancoFormaPagamentoId: selectedUserBankAccount.bancoFormaPagamento?.bancoFormaPagamentoId,
          descricao: selectedUserBankAccount.bancoFormaPagamento?.descricao,
        },
        label: `${selectedUserBankAccount.bancoFormaPagamento?.bancoFormaPagamentoId} | ${selectedUserBankAccount.bancoFormaPagamento?.descricao}`,
      });
      bankBranch.setValue(selectedUserBankAccount.agencia || "");
      bankAccountNumber.setValue(selectedUserBankAccount.conta || "");
      bankCheckDigit.setValue(selectedUserBankAccount.digitoVerificador || "");
      status.setValue(() => {
        if (selectedUserBankAccount.status === "A") {
          return statusOptions[0];
        } else {
          return statusOptions[1];
        }
      });
    }
  }, []); // eslint-disable-line

  if (!selectedUserBankAccount) return <Navigate to={"/financeiro/contas"} />;

  return (
    <div className="container">
      {!savingUserBankAccount.isLoading ? (
        <>
          <div>
            <LinkButton to={"/financeiro/contas"} buttonStyle="backButton" />
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fieldsContainer__userField}>
              <label htmlFor="user" className="label">
                Usuário
              </label>
              <Select
                id="user"
                placeholder="Selecione um usuário"
                value={user.value}
                error={user.error}
                options={userOptions}
                isLoading={searchingUsers.isLoading}
                isDisabled
              />
            </div>
            <div>
              <label htmlFor="bank" className="label">
                Banco
              </label>
              <Select
                id="bank"
                placeholder="Selecione um banco"
                value={bank.value}
                error={bank.error}
                onChange={(value) => {
                  bank.onChange(value);
                  Changes.set();
                }}
                onBlur={bank.onBlur}
                options={bankOptions}
                isLoading={searchingBanks.isLoading}
              />
            </div>
            <div>
              <label htmlFor="paymentMethod" className="label">
                Método de Pagamento
              </label>
              <Select
                id="paymentMethod"
                placeholder="Selecione um método de pagamento"
                value={paymentMethod.value}
                error={paymentMethod.error}
                onChange={(value) => {
                  paymentMethod.onChange(value);
                  Changes.set();
                }}
                onBlur={paymentMethod.onBlur}
                options={paymentMethodOptions}
                isLoading={searchingPaymentMethods.isLoading}
              />
            </div>
            <div className={styles.fieldsContainer__bankFieldsContainer}>
              <div>
                <label htmlFor="bankBranch" className="label">
                  Agência
                </label>
                <InputMask
                  id="bankBranch"
                  placeholder="Digite o número da agência"
                  mask={"9999"}
                  value={bankBranch.value}
                  error={bankBranch.error}
                  onChange={bankBranch.onChange}
                  onBlur={bankBranch.onBlur}
                />
              </div>
              <div>
                <label htmlFor="bankAccountNumber" className="label">
                  Número da Conta
                </label>
                <Input
                  id="bankAccountNumber"
                  placeholder="Digite o número da conta"
                  value={bankAccountNumber.value}
                  error={bankAccountNumber.error}
                  onChange={bankAccountNumber.onChange}
                  onBlur={bankAccountNumber.onBlur}
                />
              </div>
              <div>
                <label htmlFor="bankCheckDigit" className="label">
                  Digito Verificador
                </label>
                <InputMask
                  id="bankCheckDigit"
                  placeholder="Digite o digito verificador"
                  mask={"9"}
                  value={bankCheckDigit.value}
                  error={bankCheckDigit.error}
                  onChange={bankCheckDigit.onChange}
                  onBlur={bankCheckDigit.onBlur}
                />
              </div>
            </div>
            <div className={styles.fieldsContainer__statusField}>
              <label htmlFor="status" className="label">
                Situação
              </label>
              <Select
                id="status"
                value={status.value}
                error={status.error}
                onChange={(value) => {
                  status.onChange(value);
                  Changes.set();
                }}
                options={statusOptions}
                isSearchable={false}
                isClearable={false}
              />
            </div>
          </div>
          <div className={styles.saveButtonContainer}>
            <Button onClick={saveUserBankAccount}>Atualizar Conta</Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Atualizando Conta</span>
        </div>
      )}
    </div>
  );
}
