import React from "react";
import styles from "./styles.module.css";

interface Props {
  color: string;
  letter: string;
  rootClassName?: string;
  rootStyle?: React.CSSProperties;
  ballClassName?: string;
  ballStyle?: React.CSSProperties;
  barClassName?: string;
  barStyle?: React.CSSProperties;
  letterClassName?: string;
  letterStyle?: React.CSSProperties;
}

export default function CommissionBallBall({ color, letter, ...props }: Props) {
  return (
    <div
      className={`${styles.commissionBallBall} ${props.rootClassName}`}
      style={props.rootStyle}
    >
      <div
        style={{ backgroundColor: color, ...props.ballStyle }}
        className={`${styles.commissionBallBall__ball} ${props.ballClassName}`}
      />
      <span
        style={{ backgroundColor: color, ...props.barStyle }}
        className={`${styles.commissionBallBall__bar} ${props.barClassName}`}
      />
      <p
        style={props.letterStyle}
        className={`${styles.commissionBallBall__letter} ${props.letterClassName}`}
      >
        {letter}
      </p>
    </div>
  );
}
