import React from "react";
import { Button, Input, LinkButton, Select } from "../../../../components/Form";

import styles from "./UpdatePaymentMethod.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import { useForm, useSelect } from "../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { isValid } from "../../../../helpers/validations";
import { Circle } from "../../../../components/Loading";

export function UpdatePaymentMethod({ selectedPaymentMethod, searchPaymentMethods, filteredPaymentMethod }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const updatingPaymentMethod = useLoading();

  const description = useForm({ type: "text", required: true });
  const situation = useSelect({ type: "single", required: true });

  const situationOptions = [
    {
      value: "A",
      label: "ATIVO",
    },
    {
      value: "I",
      label: "INATIVO",
    },
  ];

  const onUpdatePaymentMethod = React.useCallback(async () => {
    if (isValid(description)) {
      try {
        updatingPaymentMethod.setLoading(true);
        const json = await customFetch("/rdv/insertAndUpdatePaymentMethod", {
          body: {
            id: selectedPaymentMethod.id,
            descricao: description.value,
            status: situation.value.value,
          },
        });
        if (json.status === 200) {
          searchPaymentMethods(filteredPaymentMethod);
          await Modal.success(json.message);
          navigate("/rdv/formas-de-pagamento");
        } else {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        updatingPaymentMethod.setLoading(false);
      }
    }
  }, [
    Modal,
    customFetch,
    description,
    navigate,
    searchPaymentMethods,
    selectedPaymentMethod,
    situation,
    updatingPaymentMethod,
    filteredPaymentMethod,
  ]);

  React.useEffect(() => {
    if (selectedPaymentMethod) {
      description.setValue(selectedPaymentMethod.descricao);
      if (selectedPaymentMethod.status === "A" || selectedPaymentMethod.status === "Ativo") {
        situation.setValue(situationOptions[0]);
      } else {
        situation.setValue(situationOptions[1]);
      }
    }
  }, []); // eslint-disable-line

  if (!selectedPaymentMethod) return <Navigate to={"/rdv/formas-de-pagamento"} />;

  return (
    <div className="container">
      {!updatingPaymentMethod.isLoading ? (
        <>
          <div className={`${styles.navContainer}`}>
            <LinkButton to="/rdv/formas-de-pagamento" buttonStyle="backButton" />
            <h2>{selectedPaymentMethod.descricao}</h2>
            <div></div>
          </div>
          <div className={`${styles.fieldsContainer}`}>
            <div>
              <label className="label" htmlFor="description">
                Descrição
              </label>
              <Input
                id="description"
                placeholder="Digite a descrição da categoria"
                value={description.value}
                error={description.error}
                onChange={description.onChange}
                onBlur={description.onBlur}
              />
            </div>
            <div>
              <label className="label" htmlFor="situation">
                Situação
              </label>
              <Select
                id="situation"
                options={situationOptions}
                value={situation.value}
                onChange={(value) => {
                  situation.onChange(value);
                }}
                isSearchable={false}
                isClearable={false}
              />
            </div>
          </div>
          <div className={`${styles.saveButtonContainer}`}>
            <Button onClick={onUpdatePaymentMethod}>Salvar</Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Atualizando forma de pagamento</span>
        </div>
      )}
    </div>
  );
}
