import React from "react";
import { useCallback, useState, useEffect, useMemo } from "react";
import { useCustomFetch, useDebounce, useLoading } from "../../../../hooks/async";
import { usePagination } from "../../../../hooks/pagination";
import {
  SearchContratadosResponse,
  IContratados,
  SearchContratadosTotalRecordsResponse,
  IUseContratados,
} from "./types";
import { useModal } from "../../../../hooks/contexts";
import { ITableData } from "../../../../components/Data/XTable";
import { useForm } from "../../../../hooks/form";
import { Button } from "../../../../components/Form";
import { Pencil } from "phosphor-react";
import { useNavigate } from "react-router-dom";

import styles from "../Lista/Lista.module.css";

export function useContratado(): IUseContratados {
  const navigate = useNavigate();
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const pagination = usePagination(10);

  const searchedContratado = useForm({ required: false });

  const [selectedContratado, setSelectedContratado] = useState<IContratados | null>(null);

  const [contratados, setContratados] = useState<IContratados[]>([]);

  const tableData: ITableData = useMemo(() => {
    const data = contratados.map((contratado) => ({
      ...contratado,
      nomeCidade: contratado.cidade.nomeCidade,
      siglaEstado: contratado.cidade.uf,
      editar: (
        <Button
          type="button"
          variant="edit"
          className={styles.editButton}
          onClick={() => {
            setSelectedContratado(contratado);
            navigate("atualizar");
          }}
          data-option-button>
          <Pencil weight="fill" />
        </Button>
      ),
    }));
    return {
      columns: [
        {
          title: "CONTRATADO",
          objectName: "nomeContratado",
          isSorted: true,
        },
        {
          title: "CNPJ/CPF",
          objectName: "cnpjContratado",
          isSorted: true,
          formatting: (data: string) => (data ? data : "Não Informado"),
        },
        {
          title: "NOME DO CONTRATADO",
          objectName: "nomeContato",
          isSorted: true,
        },
        {
          title: "EMAIL",
          objectName: "emailContato",
          isSorted: true,
        },
        {
          title: "ENDEREÇO",
          objectName: "endereco",
          isSorted: true,
        },
        {
          title: "CIDADE",
          objectName: "nomeCidade",
          isSorted: true,
        },
        {
          title: "ESTADO",
          objectName: "siglaEstado",
          isSorted: true,
        },
        {
          title: "",
          objectName: "editar",
          isSorted: false,
          style: { minWidth: "5rem" },
        },
      ],
      data,
    };
  }, [contratados, navigate]);

  const searchingContratados = useLoading();

  const searchContratadosPaginatedPromise = useCallback(
    (pagina: number, descricao: string) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/juridico/searchContratadosPaginated", {
            body: {
              pagina: pagina,
              tamanho: pagination.maxItems,
              descricao: descricao,
              status: "",
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination.maxItems]
  );

  const searchContratadosTotalRecordsPromise = useCallback(
    (pagina: number, descricao: string) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/juridico/searchContratadosTotalRecords", {
            body: {
              pagina: pagina,
              tamanho: pagination.maxItems,
              descricao: descricao,
              status: "",
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination.maxItems]
  );

  const searchContratadosPaginated = useCallback(
    async (pagina: number, descricao: string) => {
      try {
        searchingContratados.setLoading(true);
        const json = (await searchContratadosPaginatedPromise(pagina, descricao)) as SearchContratadosResponse;
        if (json.status === 200) {
          setContratados(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setContratados([]);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        searchingContratados.setLoading(false);
      }
    },
    [Modal, searchContratadosPaginatedPromise, searchingContratados]
  );

  const searchContratadosTotalRecordsAndContratados = useCallback(
    async (descricao: string) => {
      try {
        searchingContratados.setLoading(true);
        const [contratados, records] = (await Promise.all([
          searchContratadosPaginatedPromise(0, descricao),
          searchContratadosTotalRecordsPromise(0, descricao),
        ])) as [SearchContratadosResponse, SearchContratadosTotalRecordsResponse];
        if (contratados.status === 200 && records.status === 200) {
          setContratados(contratados.object);
          pagination.setTotalRecords(records.object.total);
        } else if (contratados.status !== 200) {
          if (contratados.status === 500) {
            Modal.error(contratados.message, contratados.object);
          } else {
            pagination.reset();
            setContratados([]);
          }
        } else {
          if (records.status === 500) {
            Modal.error(records.message, records.object);
          } else {
            setContratados([]);
          }
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        searchingContratados.setLoading(false);
      }
    },
    [Modal, pagination, searchContratadosPaginatedPromise, searchContratadosTotalRecordsPromise, searchingContratados]
  );

  const searchContratadosTotalRecordsAndContratadosDebounced = useDebounce(searchContratadosTotalRecordsAndContratados);

  useEffect(() => {
    searchContratadosTotalRecordsAndContratados("");
  }, []); // eslint-disable-line

  return {
    selectedContratado: {
      value: selectedContratado,
      setValue: setSelectedContratado,
    },
    data: {
      contratados,
      tableData,
    },
    filters: {
      searchedContratado,
    },
    searchs: {
      pagination,
      searchingContratados: searchingContratados.isLoading,
      searchContratadosPaginated,
      searchContratadosTotalRecordsAndContratados,
      searchContratadosTotalRecordsAndContratadosDebounced,
    },
  };
}
