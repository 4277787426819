import React from "react";
import { Route, Routes } from "react-router-dom";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { useForm } from "../../../../hooks/form";
import { usePagination } from "../../../../hooks/pagination";
import { CommissionTableItems } from "./CommissionTableItems/CommissionTableItems";
import { CommissionTablesList } from "./CommissionTablesList/CommissionTablesList";
import { NewRange } from "./NewRange/NewRange";

export interface ICommissionTable {
  idTabela: number;
  descricaoTabela: string;
  situacaoTabela: "S" | "N";
  dataManutencao: string;
}

interface ISearchTotalCommissionTableRecordsResponse {
  response: number;
  message: string;
  status: number;
  object: {
    paginas: number;
    total: number;
  };
}

export interface ISearchCommissionTablesResponse {
  response: number;
  message: string;
  status: number;
  object: ICommissionTable[];
}

export function CommissionTables() {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const pagination = usePagination();

  const [maxItems, setMaxItems] = React.useState<number>(10);

  const [selectedTable, setSelectedTable] = React.useState<ICommissionTable | null>(null);

  const searchedCommissionTable = useForm({ type: "text", required: false });
  const [commissionTableList, setCommissionTableList] = React.useState<ICommissionTable[]>([]);

  const searchingCommissionTables = useLoading();

  const searchTotalCommissionTableRecordsPromise = React.useCallback(
    (description: string = "", page: number = 0, maxItems: number = 10) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/commissions/searchTotalCommissionTableRecords", {
            body: {
              descricao: description,
              pagina: page ? page - 1 : 0,
              tamanho: maxItems,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      }) as Promise<ISearchTotalCommissionTableRecordsResponse>;
    },
    [customFetch]
  );

  const searchCommissionTablesPromise = React.useCallback(
    (description: string = "", page: number = 0, maxItems: number = 10) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/commissions/searchCommissionTablesPaginated", {
            body: {
              descricao: description,
              pagina: page ? page - 1 : 0,
              tamanho: maxItems,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      }) as Promise<ISearchCommissionTablesResponse>;
    },
    [customFetch]
  );

  const searchTotalCommissionTableRecordsAndCommissionTables = React.useCallback(
    async (description: string = "", maxItems: number = 10) => {
      if (description === "") {
        searchedCommissionTable.setValue("");
      }
      if (maxItems !== pagination.maxItems) {
        pagination.setMaxItems(maxItems);
      }
      try {
        searchingCommissionTables.setLoading(true);
        const jsonTables = await searchCommissionTablesPromise(description, 0, maxItems);
        if (jsonTables.status === 200) {
          const jsonRecords = await searchTotalCommissionTableRecordsPromise(description, 0, maxItems);
          if (jsonRecords.status === 200) {
            setCommissionTableList(jsonTables.object);
            pagination.setTotalRecords(jsonRecords.object.total);
          } else if (jsonRecords.status === 500) {
            Modal.error(jsonRecords.message, jsonRecords.object);
          } else {
            setCommissionTableList([]);
          }
        } else if (jsonTables.status === 500) {
          Modal.error(jsonTables.message, jsonTables.object);
        } else {
          pagination.reset();
          setCommissionTableList([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        pagination.setCurrentPage(1);
        searchingCommissionTables.setLoading(false);
      }
    },
    [
      Modal,
      pagination,
      searchCommissionTablesPromise,
      searchTotalCommissionTableRecordsPromise,
      searchedCommissionTable,
      searchingCommissionTables,
    ]
  );

  return (
    <Routes>
      <Route
        path="/"
        element={
          <CommissionTablesList
            maxItems={{ value: maxItems, setValue: setMaxItems }}
            commissionTableList={{
              value: commissionTableList,
              setValue: setCommissionTableList,
            }}
            searchCommissionTablesPromise={searchCommissionTablesPromise}
            searchTotalCommissionTableRecordsAndCommissionTables={searchTotalCommissionTableRecordsAndCommissionTables}
            searchingCommissionTables={searchingCommissionTables}
            searchedCommissionTable={searchedCommissionTable as unknown as IUseForm}
            pagination={pagination}
            setSelectedTable={setSelectedTable}
          />
        }
      />
      <Route
        path="nova"
        element={
          <NewRange
            searchTotalCommissionTableRecordsAndCommissionTables={searchTotalCommissionTableRecordsAndCommissionTables}
          />
        }
      />
      <Route path="itens/*" element={<CommissionTableItems selectedTable={selectedTable} />} />
    </Routes>
  );
}
