import React from "react";

import { Navigate, useNavigate } from "react-router-dom";

import { Button, Input, LinkButton, Select } from "../../../../components/Form";

import { useForm, useSelect } from "../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useChanges, useModal } from "../../../../hooks/contexts";

import { isValid } from "../../../../helpers/validations";

import { Circle } from "../../../../components/Loading";

import styles from "./UpdateGroup.module.css";

export function UpdateGroup({ researchGroups, selectedGroup, filteredGroup }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const { setIsChangesDetected } = useChanges();
  const updatingGroup = useLoading();
  const navigate = useNavigate();

  const group = useForm({ type: "", required: true });
  const unit = useSelect({ type: "single", required: true, emptyMessage: "Selecione uma unidade de medida" });
  const imageURL = useForm({ type: "simple_url", required: false });
  const status = useSelect({ type: "single", required: true });

  const optionsUnit = [
    {
      value: {
        unit: "LITRO",
        abbreviation: "LT",
      },
      label: "LITRO | LT",
    },
    {
      value: {
        unit: "QUILO",
        abbreviation: "KG",
      },
      label: "QUILO | KG",
    },
    {
      value: {
        unit: "TONELADA",
        abbreviation: "TN",
      },
      label: "TONELADA | TN",
    },
  ];

  const optionsStatus = [
    {
      value: true,
      label: "ATIVO",
    },
    {
      value: false,
      label: "INATIVO",
    },
  ];

  const updateGroup = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const json = await customFetch("/products/registerAndUpdateGroup", {
          body: {
            idGrupo: selectedGroup.idGrupo,
            descricao: group.value,
            tipo: unit.value.value.unit,
            abreviacao: unit.value.value.abbreviation,
            url: imageURL.value,
            ativo: status.value.value,
          },
        });
        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
  }, [customFetch, selectedGroup, group, unit, imageURL, status]);

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (isValid(group, unit, imageURL)) {
        try {
          updatingGroup.setLoading(true);
          const json = await updateGroup();
          if (json.status === 200) {
            researchGroups(filteredGroup);
            setIsChangesDetected(false);
            await Modal.success(json.message);
            navigate("/produtos/grupos");
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          updatingGroup.setLoading(false);
        }
      }
    },
    [
      group,
      unit,
      imageURL,
      updatingGroup,
      updateGroup,
      researchGroups,
      setIsChangesDetected,
      Modal,
      navigate,
      filteredGroup,
    ]
  );

  React.useEffect(() => {
    if (selectedGroup) {
      group.setValue(selectedGroup.descricaoGrupo || "");
      unit.setValue(() => {
        if (selectedGroup.tipoGrupo && selectedGroup.tipoAbreviadoGrupo) {
          return optionsUnit.find((option) => {
            return (
              option.value.unit === selectedGroup.tipoGrupo &&
              option.value.abbreviation === selectedGroup.tipoAbreviadoGrupo
            );
          });
        } else {
          return "";
        }
      });
      imageURL.setValue(selectedGroup.imagemURL || "");
      status.setValue(() => {
        return selectedGroup.status ? optionsStatus[0] : optionsStatus[1];
      });
    }
  }, []); //eslint-disable-line

  if (!selectedGroup || !selectedGroup.idGrupo) {
    return <Navigate to="/produtos/grupos" />;
  }

  return (
    <div className={`container ${styles.container}`}>
      {!updatingGroup.isLoading ? (
        <>
          <div>
            <LinkButton to="/produtos/grupos" buttonStyle="backButton" />
          </div>
          <div>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className={styles.fieldsContainer}>
                <div>
                  <label htmlFor="group" className="label">
                    Descrição
                  </label>
                  <Input
                    id="group"
                    placeholder="Digite o nome do grupo"
                    value={group.value}
                    error={group.error}
                    onChange={(e) => {
                      group.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={group.onBlur}
                  />
                </div>
                <div>
                  <label htmlFor="unit" className="label">
                    Unidade de Medida
                  </label>
                  <Select
                    id="unit"
                    placeholder="Selecione a unidade de medida"
                    options={optionsUnit}
                    value={unit.value}
                    error={unit.error}
                    onChange={(value) => {
                      unit.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={unit.onBlur}
                    isSearchable={false}
                  />
                </div>
                <div>
                  <label htmlFor="imgURL" className="label">
                    URL da Imagem
                  </label>
                  <Input
                    id="imgURL"
                    type="url"
                    placeholder="Digite a URL da imagem desejada"
                    value={imageURL.value}
                    error={imageURL.error}
                    onChange={(e) => {
                      imageURL.onChange(e);
                      setIsChangesDetected(true);
                    }}
                    onBlur={imageURL.onBlur}
                  />
                </div>
                <div>
                  <label htmlFor="status" className="label">
                    Status do Grupo
                  </label>
                  <Select
                    id="status"
                    options={optionsStatus}
                    value={status.value}
                    onChange={(value) => {
                      status.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
              </div>
              <div className={styles.submitButtonContainer}>
                <Button>Atualizar Grupo</Button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Atualizando grupo</span>
        </div>
      )}
    </div>
  );
}
