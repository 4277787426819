import React from "react";
import { Route, Routes } from "react-router-dom";
import { CategoryList } from "./CategoryList/CategoryList";
import { useCustomFetch, useLoading } from "../../../hooks/async";
import { NewCategory } from "./NewCategory/NewCategory";
import { UpdateCategory } from "./UpdateCategory/UpdateCategory";
import { useModal } from "../../../hooks/contexts";
import { useForm } from "../../../hooks/form";
import { usePagination } from "../../../hooks/pagination";

export function RdvCategories() {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const filteredCategory = useForm({ type: "", required: false });

  const searchingCategories = useLoading();

  const [categoryList, setCategoryList] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState(null);

  const pagination = usePagination(10);

  const searchCategoriesPromise = React.useCallback(
    (description = "", page = 0) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/rdv/searchCategoriesPaginated", {
            body: {
              pagina: page,
              tamanho: 10,
              descricao: description,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch]
  );

  const searchTotalCategoryRecordsPromise = React.useCallback(
    (description = "", page = 0) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/rdv/searchTotalCategoryRecords", {
            body: {
              pagina: page,
              tamanho: 10,
              descricao: description,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch]
  );

  const searchCategories = React.useCallback(
    async (description = "", page) => {
      try {
        searchingCategories.setLoading(true);
        const json = await searchCategoriesPromise(description, page);
        if (json.status === 200) {
          setCategoryList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setCategoryList([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingCategories.setLoading(false);
      }
    },
    [Modal, searchCategoriesPromise, searchingCategories]
  );

  const searchTotalCategoryRecordsAndCategories = React.useCallback(
    async (description = "") => {
      if (description === "") {
        filteredCategory.reset();
      }
      try {
        searchingCategories.setLoading(true);
        const jsonCategories = await searchCategoriesPromise(description);
        if (jsonCategories.status === 200) {
          const jsonRecords = await searchTotalCategoryRecordsPromise(description);
          if (jsonRecords.status === 200) {
            setCategoryList(jsonCategories.object);
            pagination.setTotalRecords(jsonRecords.object.total);
          } else if (jsonRecords.status === 500) {
            Modal.error(jsonRecords.message, jsonRecords.object);
          } else {
            setCategoryList([]);
          }
        } else if (jsonCategories.status === 500) {
          Modal.error(jsonCategories.message, jsonCategories.object);
        } else {
          setCategoryList([]);
          pagination.reset();
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        pagination.setCurrentPage(1);
        searchingCategories.setLoading(false);
      }
    },
    [
      Modal,
      filteredCategory,
      pagination,
      searchCategoriesPromise,
      searchTotalCategoryRecordsPromise,
      searchingCategories,
    ]
  );

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <CategoryList
              filteredCategory={filteredCategory}
              categoryList={{
                value: categoryList,
                setValue: setCategoryList,
                isSearching: searchingCategories.isLoading,
              }}
              searchTotalCategoryRecordsAndCategories={searchTotalCategoryRecordsAndCategories}
              searchCategories={searchCategories}
              pagination={pagination}
              setSelectedCategory={setSelectedCategory}
            />
          }
        />
        <Route
          path="nova"
          element={<NewCategory searchTotalCategoryRecordsAndCategories={searchTotalCategoryRecordsAndCategories} />}
        />
        <Route
          path="atualizar"
          element={
            <UpdateCategory
              category={selectedCategory}
              searchCategories={(category) => searchCategories(category, pagination.currentPage - 1)}
              filteredCategory={filteredCategory.value}
            />
          }
        />
      </Routes>
    </div>
  );
}
