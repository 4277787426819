import React from "react";

import { isValid } from "../../../../../../helpers/validations";

import { Button, LinkButton, Select } from "../../../../../../components/Form";
import { useCustomFetch, useDebounce, useLoading } from "../../../../../../hooks/async";
import { useModal } from "../../../../../../hooks/contexts";
import { useSelect } from "../../../../../../hooks/form";

import styles from "./NewSupervisor.module.css";
import { Circle } from "../../../../../../components/Loading";

export function NewSupervisor({ selectedPriceTable, searchSupervisors: searchSupervisorsInPriceTable }) {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const supervisor = useSelect({ type: "single", required: true, emptyMessage: "Selecione um supervisor" });

  const searchingSupervisors = useLoading();
  const registeringSupervisor = useLoading();

  const [supervisorOptions, setSupervisorOptions] = React.useState([]);

  const clearForm = React.useCallback(() => {
    supervisor.reset();
    setSupervisorOptions([]);
  }, [supervisor]);

  const searchSupervisors = React.useCallback(
    async (description = "") => {
      searchingSupervisors.setLoading(true);
      try {
        const json = await customFetch("/products/searchSupervisorsToAddInPriceTable", {
          body: {
            idTabela: Number(selectedPriceTable.tabela.idTabela),
            descricao: description,
          },
        });
        if (json.status === 200) {
          const options = json.object.map((option) => {
            return {
              value: { ...option },
              label: `${option.supervisor.idSupervisor} - ${option.supervisor.nomeSupervisor}`,
            };
          });
          setSupervisorOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setSupervisorOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingSupervisors.setLoading(false);
      }
    },
    [Modal, customFetch, searchingSupervisors, selectedPriceTable]
  );

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (isValid(supervisor)) {
        try {
          registeringSupervisor.setLoading(true);
          const json = await customFetch("/products/registerAndUpdateSupervisorOfPriceTable", {
            body: {
              idTabela: Number(selectedPriceTable.tabela.idTabela),
              idSupervisor: Number(supervisor.value.value.supervisor.idSupervisor),
              status: true,
            },
          });
          if (json.status === 200) {
            await Modal.success(json.message);
            searchSupervisorsInPriceTable();
            clearForm();
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          registeringSupervisor.setLoading(false);
        }
      }
    },
    [
      supervisor,
      registeringSupervisor,
      customFetch,
      selectedPriceTable,
      Modal,
      searchSupervisorsInPriceTable,
      clearForm,
    ]
  );

  const { current: searchSupervisorDebounced } = React.useRef(
    useDebounce((description) => {
      searchSupervisors(description);
    })
  );

  return (
    <div>
      {!registeringSupervisor.isLoading ? (
        <>
          <div>
            <LinkButton to="/produtos/tabela-de-precos/atualizar/supervisores" buttonStyle="backButton" />
          </div>
          <div className={styles.formContainer}>
            <form onSubmit={onSubmit}>
              <div className={styles.supervisorContainer}>
                <div>
                  <label htmlFor="supervisor" className="label">
                    Supervisor
                  </label>
                  <Select
                    id="supervisor"
                    placeholder="Selecione um supervisor para adicionar"
                    options={supervisorOptions}
                    value={supervisor.value}
                    error={supervisor.error}
                    onChange={supervisor.onChange}
                    onBlur={supervisor.onBlur}
                    onInputChange={(value, { action }) => {
                      searchSupervisorDebounced(value);
                    }}
                    isLoading={searchingSupervisors.isLoading}
                  />
                </div>
              </div>
              <div className={styles.submitButtonContainer}>
                <Button>Adicionar Supervisor</Button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className={`loadingContainer`}>
          <Circle size={100} />
          <span className={`loadingMessage`}>Adicionando supervisor à tabela</span>
        </div>
      )}
    </div>
  );
}
