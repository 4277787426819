import React from "react";
import { Button, Input, LinkButton } from "../../../../components/Form";

import styles from "./NewCategory.module.css";
import { useForm } from "../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { isValid } from "../../../../helpers/validations";
import { useNavigate } from "react-router-dom";
import { Circle } from "../../../../components/Loading";

export function NewCategory({ searchTotalCategoryRecordsAndCategories }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const savingCategory = useLoading();

  const description = useForm({ type: "text", required: true });
  const maxValue = useForm({ type: "number", required: true });

  const onSaveNewCategory = React.useCallback(async () => {
    if (isValid(description, maxValue)) {
      try {
        savingCategory.setLoading(true);
        const json = await customFetch("/rdv/insertAndUpdateCategory", {
          body: {
            descricao: description.value,
            valorMaximo: Number(maxValue.value),
            status: "A",
          },
        });
        if (json.status === 200) {
          searchTotalCategoryRecordsAndCategories();
          await Modal.success(json.message);
          navigate("/rdv/categorias");
        } else {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        savingCategory.setLoading(false);
      }
    }
  }, [Modal, customFetch, description, maxValue, navigate, savingCategory, searchTotalCategoryRecordsAndCategories]);

  return (
    <div className="container">
      {!savingCategory.isLoading ? (
        <>
          <div className={`${styles.navContainer}`}>
            <LinkButton to="/rdv/categorias" buttonStyle="backButton" />
          </div>
          <div className={`${styles.fieldsContainer}`}>
            <div>
              <label className="label" htmlFor="description">
                Descrição
              </label>
              <Input
                id="description"
                placeholder="Digite a descrição da categoria"
                value={description.value}
                error={description.error}
                onChange={description.onChange}
                onBlur={description.onBlur}
              />
            </div>
            <div>
              <label className="label" htmlFor="maxValue">
                Valor Máximo
              </label>
              <Input
                id="maxValue"
                className="cleanInputNumber"
                placeholder="Digite o valor máximo"
                type="number"
                value={maxValue.value}
                error={maxValue.error}
                onChange={maxValue.onChange}
                onBlur={maxValue.onBlur}
              />
            </div>
          </div>
          <div className={`${styles.saveButtonContainer}`}>
            <Button onClick={onSaveNewCategory}>Salvar</Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Salvando nova categoria</span>
        </div>
      )}
    </div>
  );
}
