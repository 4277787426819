import React from "react";

import { IData, ITableData, Table } from "../../../../../components/Data/XTable";
import { Paginate } from "../../../../../components/Paginate/Paginate";
import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { Circle } from "../../../../../components/Loading";
import { formatMoney } from "../../../../../helpers/format";

import styles from "./index.module.css";
import { isValid } from "../../../../../helpers/validations";
import { MagnifyingGlass } from "phosphor-react";
import { Select } from "../../../../../components/Form";

interface CustomCSSProperties extends React.CSSProperties {
  "--date-input-color"?: string;
  "--date-svg-image"?: string;
}

interface ISearchTotalExpenseRecordsResponse {
  response: number;
  message: string;
  status: number;
  object: {
    total: number;
  };
}

interface ISearchExpensesResponse {
  response: number;
  message: string;
  status: number;
  object: {
    dataEmissao: string;
    vendedor: string;
    categoria: string;
    valorDespesa: number;
    valorPago: number;
  }[];
}

interface IProps {
  maxItems: {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
  };
  tipoDespesa: "pendentes" | "liberadas" | "recusadas";
  tableData: ITableData;
  setTableData: React.Dispatch<React.SetStateAction<ITableData>>;
  onClickTableLine?: (data: IData) => void;
  pagination: IUsePagination;
  initialDate: IUseDate;
  finalDate: IUseDate;
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<String>>;
  enterpriseOptions: { [key: string]: any }[];
  enterprise: IUseSelect<any, "multiple">;
}

export function DespesasRdv({
  maxItems,
  tipoDespesa,
  tableData,
  setTableData,
  onClickTableLine,
  pagination,
  initialDate,
  finalDate,
  filter,
  setFilter,
  enterpriseOptions,
  enterprise,
}: IProps) {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const searchingExpenses = useLoading();

  const searchTotalExpenseRecordsPromise = React.useCallback(
    (
      dataInicial: string,
      dataFinal: string,
      empresas: number[],
      maxItems: number,
      pagina: number = 0,
      filtro: string = ""
    ) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/rdv/searchTotalExpenseRecords", {
            headers: undefined,
            body: {
              pagina: pagina,
              tamanho: maxItems,
              idStatus: tipoDespesa === "pendentes" ? "0" : tipoDespesa === "liberadas" ? "3" : "1",
              dataInicial: dataInicial,
              dataFinal: dataFinal,
              codigoEmpresas: empresas,
              descricao: filtro,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, tipoDespesa]
  );

  const searchExpensesPromise = React.useCallback(
    (
      dataInicial: string,
      dataFinal: string,
      empresas: number[],
      maxItems: number,
      pagina: number = 0,
      filtro: string = ""
    ) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/rdv/searchExpensesPaginated", {
            headers: undefined,
            body: {
              pagina: pagina,
              tamanho: maxItems,
              idStatus: tipoDespesa === "pendentes" ? "0" : tipoDespesa === "liberadas" ? "3" : "1",
              dataInicial: dataInicial,
              dataFinal: dataFinal,
              codigoEmpresas: empresas,
              descricao: filtro,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, tipoDespesa]
  );

  const searchExpenses = React.useCallback(
    async (dataInicial: string, dataFinal: string, empresas: number[], pagina: number, filtro: string = "") => {
      try {
        searchingExpenses.setLoading(true);
        const json = (await searchExpensesPromise(
          dataInicial,
          dataFinal,
          empresas,
          pagination.maxItems,
          pagina,
          filtro
        )) as ISearchExpensesResponse;
        if (json.status === 200) {
          setTableData((old) => ({
            columns: old.columns,
            data: json.object,
          }));
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setTableData((old) => ({
            columns: old.columns,
            data: [],
          }));
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingExpenses.setLoading(false);
      }
    },
    [Modal, searchExpensesPromise, searchingExpenses, setTableData, pagination]
  );

  const searchTotalExpenseRecordsAndExpenses = React.useCallback(
    async (dataInicial: string, dataFinal: string, empresas: number[], maxItems: number, filtro: string = "") => {
      try {
        searchingExpenses.setLoading(true);
        const jsonExpenses = (await searchExpensesPromise(
          dataInicial,
          dataFinal,
          empresas,
          maxItems,
          0,
          filtro
        )) as ISearchExpensesResponse;
        if (jsonExpenses.status === 200) {
          const jsonRecords = (await searchTotalExpenseRecordsPromise(
            dataInicial,
            dataFinal,
            empresas,
            maxItems,
            0,
            filtro
          )) as ISearchTotalExpenseRecordsResponse;
          if (jsonRecords.status === 200) {
            setTableData((old) => ({
              columns: old.columns,
              data: jsonExpenses.object,
            }));
            pagination.setTotalRecords(jsonRecords.object.total);
          } else if (jsonRecords.status === 500) {
            Modal.error(jsonRecords.message, jsonRecords.object);
          } else {
            setTableData((old) => ({
              columns: old.columns,
              data: [],
            }));
          }
        } else if (jsonExpenses.status === 500) {
          Modal.error(jsonExpenses.message, jsonExpenses.object);
        } else {
          setTableData((old) => ({
            columns: old.columns,
            data: [],
          }));
          pagination.reset();
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        pagination.setCurrentPage(1);
        searchingExpenses.setLoading(false);
      }
    },
    [Modal, pagination, searchExpensesPromise, searchTotalExpenseRecordsPromise, searchingExpenses, setTableData]
  );

  React.useEffect(() => {
    const today = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(today.getMonth() - 1);
    today.setDate(today.getDate());

    const lastMonthIso = lastMonth.toISOString();
    const todayIso = today.toISOString();

    if (!tableData.data.length) {
      let initial, final;
      if (!initialDate.value) {
        initialDate.setValue(lastMonthIso.split("T")[0]);
        initial = lastMonthIso.split("T")[0].replace(/(\d{4}-\d{2}-\d{2})/, "$1T00:00:00.000Z");
      } else {
        initial = initialDate.toISOString();
      }
      if (!finalDate.value) {
        finalDate.setValue(todayIso.split("T")[0]);
        final = todayIso.split("T")[0].replace(/(\d{4}-\d{2}-\d{2})/, "$1T00:00:00.000Z");
      } else {
        final = finalDate.toISOString();
      }
      let currentEnterprises = enterprise.value;
      if (!enterprise.value || !enterprise.value.length) {
        currentEnterprises = [];
      }
      // setFilter("");
      searchTotalExpenseRecordsAndExpenses(
        initial,
        final,
        Array.isArray(currentEnterprises) ? currentEnterprises.map((item: any) => item.value.idEmpresa) : [],
        pagination.maxItems,
        filter
      );
    }
  }, []); // eslint-disable-line

  return (
    <div
      className={styles.container}
      style={{
        background:
          tipoDespesa === "pendentes"
            ? "linear-gradient(20deg, #ffa726, #fb8c00)"
            : tipoDespesa === "liberadas"
            ? "linear-gradient(20deg, #06b700c9, #008f07)"
            : "linear-gradient(20deg, #ef5350, #e53935)",
      }}>
      <header className={styles.header}>
        <h1>
          {tipoDespesa === "pendentes"
            ? "Despesas Pendentes"
            : tipoDespesa === "liberadas"
            ? "Despesas Liberadas"
            : "Despesas Recusadas"}
        </h1>
      </header>
      <div className={styles.contentContainer}>
        <div className={styles.filterContainer}>
          <div className={styles.periodContainer}>
            <div>
              <div className={styles.periodContainer__period}>
                <span>De</span>{" "}
                <input
                  type="date"
                  style={
                    {
                      "--date-input-color": initialDate.error ? "#8c0101" : "white",
                      "--date-svg-image": initialDate.error
                        ? `url('data:image/svg+xml;utf8,${encodeURIComponent(
                            '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="#8c0101" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>'
                          )}')`
                        : `url('data:image/svg+xml;utf8,${encodeURIComponent(
                            '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="#FFFFFF" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>'
                          )}')`,
                    } as CustomCSSProperties
                  }
                  value={initialDate.value}
                  onChange={initialDate.onChange}
                />{" "}
                <span>até</span>{" "}
                <input
                  type="date"
                  style={
                    {
                      "--date-input-color": finalDate.error ? "#8c0101" : "white",
                      "--date-svg-image": finalDate.error
                        ? `url('data:image/svg+xml;utf8,${encodeURIComponent(
                            '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="#8c0101" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>'
                          )}')`
                        : `url('data:image/svg+xml;utf8,${encodeURIComponent(
                            '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="#FFFFFF" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>'
                          )}')`,
                    } as CustomCSSProperties
                  }
                  value={finalDate.value}
                  onChange={finalDate.onChange}
                />
              </div>
              <div className={styles.enterprisesContainer}>
                <Select
                  placeholder="Selecione as empresas"
                  options={enterpriseOptions}
                  value={enterprise.value}
                  onChange={enterprise.onChange}
                  defaultValue={undefined}
                  error={undefined}
                  isMulti={true}
                  defaultBorderColor="var(--gray-9)"
                />
              </div>
            </div>
            <span className={styles.searchButtonContainer}>
              <button
                className="button"
                onClick={() => {
                  if (isValid(initialDate, finalDate)) {
                    searchTotalExpenseRecordsAndExpenses(
                      initialDate.toISOString(),
                      finalDate.toISOString(),
                      (enterprise.value || []).map((item: any) => item.value.idEmpresa),
                      pagination.maxItems,
                      filter
                    );
                  }
                }}>
                <MagnifyingGlass size={16} color="var(--gray-2)" weight="bold" />
              </button>
            </span>
          </div>
          <div className={styles.searchContainer}>
            <span>Filtro</span>{" "}
            <input
              type="text"
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchTotalExpenseRecordsAndExpenses(
                    initialDate.toISOString(),
                    finalDate.toISOString(),
                    enterprise.value.map((item: any) => item.value.idEmpresa),
                    pagination.maxItems,
                    filter
                  );
                }
              }}
            />
          </div>
        </div>
        <span className={styles.separator}></span>
        <div className={styles.tableContainer}>
          {tableData.data.length ? (
            <div className={styles.itemQuantityContainer}>
              <select
                className={styles.itemQuantityContainer__select}
                value={maxItems.value}
                onChange={({ target }) => {
                  maxItems.setValue(Number(target.value));
                  searchTotalExpenseRecordsAndExpenses(
                    initialDate.toISOString(),
                    finalDate.toISOString(),
                    enterprise.value.map((item: any) => item.value.idEmpresa),
                    Number(target.value),
                    filter
                  );
                }}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={0}>Todos</option>
              </select>
              <p>resultados por página</p>
            </div>
          ) : null}
          {!searchingExpenses.isLoading && tableData.data.length ? (
            <Table tableData={tableData} onClickLineCallback={onClickTableLine} className={styles.table} />
          ) : searchingExpenses.isLoading ? (
            <div className={styles.loadingContainer}>
              <Circle size={60} color="var(--gray-10)" />
            </div>
          ) : (
            <div className={styles.emptyTableMessage}>Não foram encontradas despesas {tipoDespesa}</div>
          )}
        </div>
        <div>
          {!searchingExpenses.isLoading ? (
            <Paginate
              classNameContainer={styles.paginationContainer}
              defaultColor={
                tipoDespesa === "pendentes"
                  ? "var(--orange-2)"
                  : tipoDespesa === "liberadas"
                  ? "var(--green-2)"
                  : "var(--red-2)"
              }
              defaultLightColor={
                tipoDespesa === "pendentes"
                  ? "var(--orange-6)"
                  : tipoDespesa === "liberadas"
                  ? "var(--input-color)"
                  : "var(--red-6)"
              }
              totalRecords={pagination.totalRecords}
              maxItems={pagination.maxItems}
              currentPage={pagination.currentPage}
              setCurrentPage={pagination.setCurrentPage}
              onPageChange={(page: any) => {
                searchExpenses(
                  initialDate.toISOString(),
                  finalDate.toISOString(),
                  enterprise.value.map((item: any) => item.value.idEmpresa),
                  page - 1,
                  filter
                );
              }}
            />
          ) : null}
        </div>
        <div className={styles.totalContainer}>
          <span></span>
          <h2>
            {tipoDespesa === "pendentes"
              ? "Total Contas Pendentes"
              : tipoDespesa === "liberadas"
              ? "Total Contas Liberadas"
              : "Total Contas Recusadas"}
            :{" "}
            {tableData.data.length
              ? formatMoney(
                  tableData.data.reduce((prev, current) => {
                    return prev + current.valorDespesa;
                  }, 0)
                )
              : formatMoney(0)}
          </h2>
        </div>
      </div>
    </div>
  );
}
