import { Pencil } from "phosphor-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Table, TBody, Td, Th, THead, Tr } from "../../../../../components/Data/Table";
import { Button, Input, LinkButton, Select } from "../../../../../components/Form";
import { Circle } from "../../../../../components/Loading";
import { Paginate } from "../../../../../components/Paginate/Paginate";
import { useDebounce } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { IPriceRange, ISearchPricesRangeResponse, ITableFormulaEnterprise } from "../PricesRange";

import styles from "./PricesRangeList.module.css";

interface IPricesRangeListProps {
  pagination: IUsePagination;
  searchedPriceRange: IUseForm;
  priceRangesList: {
    value: IPriceRange[];
    setValue: React.Dispatch<React.SetStateAction<IPriceRange[]>>;
  };
  searchingPriceRanges: IUseLoading;
  searchPriceRangesPromise: (
    description?: string,
    page?: number,
    tableId?: number,
    formulaId?: number
  ) => Promise<unknown>;
  searchTotalPriceRangeRecordsAndPricesRange: (
    description?: string,
    tableId?: number,
    formulaId?: number
  ) => Promise<void>;
  setSelectedPriceRange: React.Dispatch<React.SetStateAction<IPriceRange | null>>;
  tables: {
    search: (description?: string) => Promise<void>;
    searchDebounced: (description?: string) => Promise<void>;
    searching: boolean;
    options: ISelectOption<ITableFormulaEnterprise>[];
    table: IUseSelect<any, "single">;
  };
  formulas: {
    search: (description?: string) => Promise<void>;
    searchDebounced: (description?: string) => Promise<void>;
    searching: boolean;
    options: ISelectOption<ITableFormulaEnterprise>[];
    formula: IUseSelect<any, "single">;
  };
}

export function PricesRangeList({
  pagination,
  priceRangesList,
  searchedPriceRange,
  searchingPriceRanges,
  searchPriceRangesPromise,
  searchTotalPriceRangeRecordsAndPricesRange,
  setSelectedPriceRange,
  tables,
  formulas,
}: IPricesRangeListProps) {
  const Modal = useModal();
  const navigate = useNavigate();

  const { current: searchTotalPriceRangeRecordsAndPricesRangeDebounced } = React.useRef(
    useDebounce(searchTotalPriceRangeRecordsAndPricesRange)
  );

  const handleClickEditPriceRange = (priceRange: IPriceRange) => {
    setSelectedPriceRange(priceRange);
    navigate("atualizar");
  };

  const handlePageChange = async (description: string, page: number) => {
    try {
      searchingPriceRanges.setLoading(true);
      const json = (await searchPriceRangesPromise(
        description,
        page,
        tables.table.value?.value.id,
        formulas.formula.value?.value.id
      )) as ISearchPricesRangeResponse;
      if (json.status === 200) {
        priceRangesList.setValue(json.object);
        window.scrollTo({ top: 0, behavior: "auto" });
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingPriceRanges.setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!priceRangesList.value.length) {
      tables.search();
      formulas.search();
      searchTotalPriceRangeRecordsAndPricesRange();
    }
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.navContainer}>
        <LinkButton
          to="nova"
          variant={undefined}
          className={undefined}
          classNameContainer={undefined}
          disabled={undefined}>
          Nova Faixa de Preços
        </LinkButton>
      </div>
      <div className={styles.filterContainer}>
        <div className={styles.filterContainer__priceRange}>
          <label htmlFor="priceRange" className="label">
            Faixa de Preços
          </label>
          <Input
            id="priceRange"
            placeholder="Digite o Id da faixa de preços ou a fórmula para filtrar"
            value={searchedPriceRange.value}
            onChange={(e) => {
              searchedPriceRange.onChange(e);
              searchTotalPriceRangeRecordsAndPricesRangeDebounced(
                e.target.value,
                tables.table.value?.value.id,
                formulas.formula.value?.value.id
              );
            }}
          />
        </div>
        <div>
          <label htmlFor="table" className="label">
            Tabela
          </label>
          <Select
            id="table"
            placeholder="Selecione uma tabela para filtrar"
            options={tables.options}
            value={tables.table.value}
            onChange={(value) => {
              tables.table.onChange(value);
              if (value) {
                searchTotalPriceRangeRecordsAndPricesRange(
                  searchedPriceRange.value,
                  value.value?.id,
                  formulas.formula.value?.value.id
                );
              } else {
                searchTotalPriceRangeRecordsAndPricesRange(
                  searchedPriceRange.value,
                  0,
                  formulas.formula.value?.value.id
                );
              }
            }}
            onInputChange={tables.searchDebounced}
            isLoading={tables.searching}
          />
        </div>
        <div>
          <label htmlFor="formula" className="label">
            Formulado
          </label>
          <Select
            id="formula"
            placeholder="Selecione um formulado para filtrar"
            options={formulas.options}
            value={formulas.formula.value}
            onChange={(value) => {
              formulas.formula.onChange(value);
              if (value) {
                searchTotalPriceRangeRecordsAndPricesRange(
                  searchedPriceRange.value,
                  tables.table.value?.value.id,
                  value.value?.id
                );
              } else {
                searchTotalPriceRangeRecordsAndPricesRange(searchedPriceRange.value, tables.table.value?.value.id, 0);
              }
            }}
            onInputChange={formulas.searchDebounced}
            isLoading={formulas.searching}
          />
        </div>
      </div>
      <div className={styles.contentContainer}>
        {priceRangesList.value.length && !searchingPriceRanges.isLoading ? (
          <Table title="Faixas de Preços" className={styles.table}>
            <THead>
              <Tr>
                <Th width={".625rem"}>ID</Th>
                <Th width={".625rem"}>Preço Mínimo</Th>
                <Th width={".625rem"}>Preço Máximo</Th>
                <Th width={""}>Tabela</Th>
                <Th width={""}>Formulado</Th>
                <Th width={""}>Cor</Th>
                <Th width={""}>Situação</Th>
                <Th width={".625rem"} children={undefined}></Th>
              </Tr>
            </THead>
            <TBody>
              {priceRangesList.value.map((item, index) => (
                <Tr key={index}>
                  <Td heading={"ID"}>{item.id}</Td>
                  <Td heading={"Preço Mínimo"}>{item.precoMinimo}</Td>
                  <Td heading={"Preço Máximo"}>{item.precoMaximo}</Td>
                  <Td heading={"Tabela"}>{item.tabela.descricao}</Td>
                  <Td heading={"Formulado"}>{item.formulado.descricao}</Td>
                  <Td heading={"Cor"}>
                    {
                      <span
                        style={{
                          display: "block",
                          backgroundColor: item.rgb,
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          margin: "auto",
                        }}></span>
                    }
                  </Td>
                  <Td heading={"Situação"}>{item.status === "A" ? "Ativo" : "Inativo"}</Td>
                  <Td heading={"Editar"} data-option>
                    <Button
                      className={styles.editPriceRangeButton}
                      type="button"
                      variant="edit"
                      onClick={() => {
                        handleClickEditPriceRange(item);
                      }}
                      data-option-button>
                      <Pencil weight="fill" />
                    </Button>
                  </Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        ) : searchingPriceRanges.isLoading ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhuma faixa de preços encontrada</p>
        )}
      </div>
      <div>
        <Paginate
          classNameContainer={styles.paginationContainer}
          maxItems={pagination.maxItems}
          totalRecords={pagination.totalRecords}
          currentPage={pagination.currentPage}
          setCurrentPage={pagination.setCurrentPage}
          onPageChange={(page: number) => handlePageChange(searchedPriceRange.value, page)}
        />
      </div>
    </div>
  );
}
