import React from "react";

import {
  IUseSolicitationList,
  SearchSolicitationsResponse,
  SearchTotalSolicitationRecordsResponse,
} from "./types";
import { useModal } from "../../../../../hooks/contexts/useModal";
import {
  useCustomFetch,
  useDebounce,
  useLoading,
} from "../../../../../hooks/async";
import { usePagination } from "../../../../../hooks/pagination";
import { ISolicitation } from "../types";
import { useForm } from "../../../../../hooks/form";
import { SolicitationStatus } from "../../../types";

export function useSolicitationList(): IUseSolicitationList {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const abortController = React.useRef(new AbortController());

  const pagination = usePagination();

  const filterDescription = useForm({ required: false });

  const [solicitationList, setSolicitationList] = React.useState<
    ISolicitation[]
  >([]);

  const searchingSolicitations = useLoading();

  const searchSolicitationsPromise = React.useCallback(
    (page = 0, description = "", abortController: AbortController) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch(
            "/contracts/searchSolicitationsPaginated",
            {
              body: {
                pagina: page,
                tamanho: pagination.maxItems,
                descricao: description,
                status: [SolicitationStatus.AprovadoComercial],
              },
              signal: abortController.signal,
            }
          );
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const searchTotalSolicitationRecordsPromise = React.useCallback(
    (page = 0, description = "", abortController: AbortController) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch(
            "/contracts/searchTotalSolicitationRecords",
            {
              body: {
                pagina: page,
                tamanho: pagination.maxItems,
                descricao: description,
                status: [SolicitationStatus.AprovadoComercial],
              },
              signal: abortController.signal,
            }
          );
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const searchSolicitations = React.useCallback(
    async (page = 0, description = "") => {
      abortController.current.abort();
      abortController.current = new AbortController();
      try {
        searchingSolicitations.setLoading(true);
        const json = (await searchSolicitationsPromise(
          page,
          description,
          abortController.current
        )) as SearchSolicitationsResponse;
        if (json.status === 200) {
          setSolicitationList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setSolicitationList([]);
        }
        searchingSolicitations.setLoading(() => false);
      } catch (error: any) {
        if (error.name !== "AbortError") {
          Modal.error(error);
          searchingSolicitations.setLoading(() => false);
        }
      }
    },
    [Modal, searchSolicitationsPromise, searchingSolicitations]
  );

  const searchTotalSolicitationRecordsAndSolicitations = React.useCallback(
    async (description = "") => {
      abortController.current.abort();
      abortController.current = new AbortController();
      if (!description) filterDescription.reset();
      try {
        searchingSolicitations.setLoading(true);
        const [solicitations, records] = (await Promise.all([
          searchSolicitationsPromise(0, description, abortController.current),
          searchTotalSolicitationRecordsPromise(
            0,
            description,
            abortController.current
          ),
        ])) as [
          SearchSolicitationsResponse,
          SearchTotalSolicitationRecordsResponse
        ];
        if (solicitations.status === 200 && records.status === 200) {
          setSolicitationList(solicitations.object);
          pagination.setTotalRecords(records.object.total);
        } else if (solicitations.status !== 200) {
          if (solicitations.status === 500) {
            Modal.error(solicitations.message, solicitations.object);
          } else {
            pagination.reset();
            setSolicitationList([]);
          }
        } else {
          if (records.status === 500) {
            Modal.error(records.message, records.object);
          } else {
            setSolicitationList([]);
          }
        }
        searchingSolicitations.setLoading(() => false);
      } catch (error: any) {
        if (error.name !== "AbortError") {
          Modal.error(error);
          searchingSolicitations.setLoading(() => false);
        }
      } finally {
        pagination.setCurrentPage(1);
      }
    },
    [
      Modal,
      filterDescription,
      pagination,
      searchSolicitationsPromise,
      searchTotalSolicitationRecordsPromise,
      searchingSolicitations,
    ]
  );

  const searchTotalSolicitationRecordsAndSolicitationsDebounced = useDebounce(
    searchTotalSolicitationRecordsAndSolicitations
  );

  return {
    pagination,
    filter: {
      description: filterDescription,
    },
    solicitationList: [solicitationList, setSolicitationList],
    searchingSolicitations: searchingSolicitations.isLoading,
    searchSolicitations,
    searchTotalSolicitationRecordsAndSolicitations,
    searchTotalSolicitationRecordsAndSolicitationsDebounced,
  };
}
