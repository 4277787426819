import React from "react";

import { useNavigate } from "react-router-dom";
import { useModal } from "../../../../hooks/contexts";
import { useDebounce } from "../../../../hooks/async";

import { Table, TBody, Td, Th, THead, Tr } from "../../../../components/Data/Table";
import { Input } from "../../../../components/Form";
import { Paginate } from "../../../../components/Paginate/Paginate";
import { formatDate } from "../../../../helpers/format";
import { Button, LinkButton } from "../../../../components/Form";

import { Circle } from "../../../../components/Loading";

import { Pencil, CurrencyDollar } from "phosphor-react";

import styles from "./MicroList.module.css";

export function MicroList({
  pagination,
  searchMicros,
  searchTotalMicroRecordsAndMicros,
  searchedMicro,
  microList,
  setSelectedMicro,
  searchingMicros,
}) {
  const Modal = useModal();
  const navigate = useNavigate();

  const handleClickEditButton = React.useCallback(
    (microData) => {
      setSelectedMicro(microData);
      navigate("atualizar");
    },
    [navigate, setSelectedMicro]
  );

  const handleClickEditCostsButton = React.useCallback(
    (microData) => {
      setSelectedMicro(microData);
      navigate("custos");
    },
    [navigate, setSelectedMicro]
  );

  const handlePageChange = React.useCallback(
    async (micro, page) => {
      try {
        searchingMicros.setLoading(true);
        const json = await searchMicros(micro, page);
        if (json.status === 200) {
          microList.setValue(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingMicros.setLoading(false);
      }
    },
    [Modal, microList, searchMicros, searchingMicros]
  );

  const { current: searchMicroList } = React.useRef(
    useDebounce((micro) => {
      searchTotalMicroRecordsAndMicros(micro);
    })
  );

  React.useEffect(() => {
    if (!microList.value.length) searchTotalMicroRecordsAndMicros();
  }, []); //eslint-disable-line

  return (
    <div className={`container ${styles.container}`}>
      <div className={`${styles.newMicroButtonContainer}`}>
        <LinkButton to="novo">Novo Micro</LinkButton>
      </div>
      <div className={`${styles.searchContainer}`}>
        <label className="label">Filtrar Micros</label>
        <Input
          placeholder="Busque por um micro"
          value={searchedMicro.value}
          onChange={({ target }) => {
            searchedMicro.setValue(target.value);
            searchMicroList(target.value);
          }}
        />
      </div>
      <div className={`${styles.packingListContainer}`}>
        {microList.value.length && !searchingMicros.isLoading ? (
          <Table title="Micros Cadastrados" className={styles.tablePacking}>
            <THead>
              <Tr>
                <Th>ID</Th>
                <Th>Descrição</Th>
                <Th>Valor</Th>
                <Th>Desconto Máximo (%)</Th>
                <Th>Situação</Th>
                <Th>Última modificação</Th>
                <Th></Th>
                {/* <Th></Th> */}
              </Tr>
            </THead>
            <TBody>
              {microList.value.map((micro) => {
                return (
                  <Tr key={micro.idComplementar}>
                    <Td data-heading="ID">{micro.idComplementar}</Td>
                    <Td data-heading="Descrição / Sigla">
                      {micro.descricaoComplementar} | {micro.simbolo}
                    </Td>
                    <Td data-heading="Valor">{`${micro.valor}`}</Td>
                    <Td data-heading="Desconto Máximo (%)">{`${micro.descontoMaximo}`}</Td>
                    <Td data-heading="Situação">{micro.situacaoComplementar === "A" ? "Ativo" : "Inativo"}</Td>
                    <Td data-heading="Última modificação">
                      {formatDate(micro.dataManutencao, "dd/MM/yyyy às hh:mm:ss")}
                    </Td>
                    <Td data-heading="Editar Micro" data-option>
                      <Button
                        className={styles.editPackingButton}
                        onClick={() => {
                          const selectedMicro = {
                            ...micro,
                            situacaoComplementar: !!(micro.situacaoComplementar === "A"),
                          };
                          handleClickEditButton(selectedMicro);
                        }}
                        data-option-button>
                        <Pencil weight="fill" />
                      </Button>
                    </Td>
                    <Td data-heading="Editar Custos" data-option>
                      <Button
                        className={styles.editPackingButton}
                        onClick={() => {
                          const selectedMicro = {
                            ...micro,
                            situacao: !!(micro.situacao === "A"),
                          };
                          handleClickEditCostsButton(selectedMicro);
                        }}
                        data-option-button>
                        <CurrencyDollar weight="fill" />
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </TBody>
          </Table>
        ) : searchingMicros.isLoading ? (
          <div className={styles.searchingPackagingLoadingContainer}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={styles.noPackagingMessage}>Nenhum micro encontrado</p>
        )}
      </div>
      <div>
        <Paginate
          classNameContainer={styles.paginationContainer}
          totalRecords={pagination.totalRecords}
          maxItems={pagination.maxItems}
          currentPage={pagination.currentPage}
          setCurrentPage={pagination.setCurrentPage}
          onPageChange={(page) => handlePageChange(searchedMicro.value, page)}
        />
      </div>
    </div>
  );
}
