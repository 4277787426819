import React from "react";
import { Route, Routes } from "react-router-dom";
import { SupplierList } from "./SupplierList/SupplierList";
import { useCustomFetch, useLoading } from "../../../hooks/async";
import { NewSupplier } from "./NewSupplier/NewSupplier";
import { UpdateSupplier } from "./UpdateSupplier/UpdateSupplier";
import { useModal } from "../../../hooks/contexts";
import { usePagination } from "../../../hooks/pagination";
import { useForm } from "../../../hooks/form";

const supplierListMock = [
  {
    id: 1,
    nome: "Joao Berrante",
    cnpj: "12.123.123/1234-123",
    endereco: "Rua Joaquin Phoenix, 310",
    cidade: "Vargem",
    status: "Ativo",
  },
];

export function RdvSuppliers() {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const pagination = usePagination(10);

  const filteredSupplier = useForm({ type: "", required: false });

  const searchingSuppliers = useLoading();

  const [supplierList, setSupplierList] = React.useState([]);
  const [selectedSupplier, setSelectedSupplier] = React.useState(null);

  const searchSuppliersPromise = React.useCallback(
    (description = "", page = 0) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/rdv/searchSuppliersPaginated", {
            body: {
              pagina: page,
              tamanho: 10,
              nome: description,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch]
  );

  const searchTotalSupplierRecordsPromise = React.useCallback(
    (description = "", page = 0) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/rdv/searchTotalSupplierRecords", {
            body: {
              pagina: page,
              tamanho: 10,
              nome: description,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch]
  );

  const searchSuppliers = React.useCallback(
    async (description = "", page) => {
      try {
        searchingSuppliers.setLoading(true);
        const json = await searchSuppliersPromise(description, page);
        if (json.status === 200) {
          setSupplierList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setSupplierList([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingSuppliers.setLoading(false);
      }
    },
    [Modal, searchSuppliersPromise, searchingSuppliers]
  );

  const searchTotalSupplierRecordsAndSuppliers = React.useCallback(
    async (description = "") => {
      if (description === "") {
        filteredSupplier.reset();
      }
      try {
        searchingSuppliers.setLoading(true);
        const jsonSuppliers = await searchSuppliersPromise(description);
        if (jsonSuppliers.status === 200) {
          const jsonRecords = await searchTotalSupplierRecordsPromise(description);
          if (jsonRecords.status === 200) {
            setSupplierList(jsonSuppliers.object);
            pagination.setTotalRecords(jsonRecords.object.total);
          } else if (jsonRecords.status === 500) {
            Modal.error(jsonRecords.message, jsonRecords.object);
          } else {
            setSupplierList([]);
          }
        } else if (jsonSuppliers.status === 500) {
          Modal.error(jsonSuppliers.message, jsonSuppliers.object);
        } else {
          setSupplierList([]);
          pagination.reset();
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        pagination.setCurrentPage(1);
        searchingSuppliers.setLoading(false);
      }
    },
    [Modal, filteredSupplier, pagination, searchSuppliersPromise, searchTotalSupplierRecordsPromise, searchingSuppliers]
  );

  React.useEffect(() => {
    setSupplierList(supplierListMock);
  }, []);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <SupplierList
              filteredSupplier={filteredSupplier}
              searchSuppliers={searchSuppliers}
              searchTotalSupplierRecordsAndSuppliers={searchTotalSupplierRecordsAndSuppliers}
              supplierList={{
                value: supplierList,
                setValue: setSupplierList,
                isSearching: searchingSuppliers.isLoading,
              }}
              pagination={pagination}
              setSelectedSupplier={setSelectedSupplier}
            />
          }
        />
        <Route
          path="nova"
          element={<NewSupplier searchTotalSupplierRecordsAndSuppliers={searchTotalSupplierRecordsAndSuppliers} />}
        />
        <Route
          path="atualizar"
          element={
            <UpdateSupplier
              supplier={selectedSupplier}
              searchSuppliers={(supplier) => searchSuppliers(supplier, pagination.currentPage - 1)}
              filteredSupplier={filteredSupplier.value}
            />
          }
        />
      </Routes>
    </div>
  );
}
