import React, { useEffect } from "react";

import styles from "./AtualizarPermissoesGrupo.module.css";
import { Button, LinkButton } from "../../../../components/Form";
import { usePreviousRoute } from "../../../../hooks/navigation";
import { UseGruposPermissoes } from "../hooks/useGruposPermissoes";
import {
  GrupoTratado,
  PermissaoTradada,
  SistemaTratado,
  useAtualizarPermissoesGrupo,
} from "../hooks/useAtualizarPermissoesGrupo";
import { Collapse } from "../../../../components/Collapse/Collapse";
import { Circle } from "../../../../components/Loading";

const PermissaoItem = ({
  permissao,
  alterarStatusPermissao,
}: {
  permissao: PermissaoTradada;
  alterarStatusPermissao: (permissao: PermissaoTradada) => void;
}) => {
  return (
    <li>
      <p>{permissao.idPermissao}</p>
      <span className={styles.permissionInfoSeparator} />
      <div className={styles.permissionInfoData}>
        <p className={styles.permissionInfoName}>
          {permissao.descricaoPermissao}
        </p>
        <p className={styles.permissionInfoObs}>
          {permissao.observacaoPermissao}
        </p>
      </div>
      <span className={styles.permissionInfoSeparator} />
      {permissao.statusPermissaoGrupo === "A" ? (
        <button
          className={`${styles.permissionInfoButton} ${styles.permissionInfoButtonRemove}`}
          onClick={() => {
            alterarStatusPermissao(permissao);
          }}
        >
          Remover
        </button>
      ) : (
        <button
          className={`${styles.permissionInfoButton} ${styles.permissionInfoButtonAdd}`}
          onClick={() => {
            alterarStatusPermissao(permissao);
          }}
        >
          Adicionar
        </button>
      )}
    </li>
  );
};

const GrupoPermissoes = ({
  grupo,
  situacaoPermissoes,
  alterarStatusPermissao,
}: {
  grupo: GrupoTratado;
  situacaoPermissoes: "A" | "I";
  alterarStatusPermissao: (permissao: PermissaoTradada) => void;
}) => {
  const permissoes = grupo.permissoes.filter(
    (permissao) => permissao.statusPermissaoGrupo === situacaoPermissoes
  );

  if (permissoes.length === 0) {
    return null;
  }

  return (
    <li>
      <h4 className={styles.permissionGroupsTitle}>{grupo.grupo}</h4>
      <ul className={styles.permissions}>
        {permissoes.map((perm, index) => (
          <PermissaoItem
            key={index}
            permissao={perm}
            alterarStatusPermissao={alterarStatusPermissao}
          />
        ))}
      </ul>
    </li>
  );
};

const SistemaPermissoes = ({
  sistema,
  situacaoPermissoes,
  alterarStatusPermissao,
}: {
  sistema: SistemaTratado;
  situacaoPermissoes: "A" | "I";
  alterarStatusPermissao: (permissao: PermissaoTradada) => void;
}) => {
  const grupos = sistema.grupos.filter((grupo) =>
    grupo.permissoes.some(
      (permissao) => permissao.statusPermissaoGrupo === situacaoPermissoes
    )
  );

  if (grupos.length === 0) {
    return null;
  }

  return (
    <li>
      <Collapse
        label={<h3 className={styles.systemTitle}>{sistema.sistema}</h3>}
        iconColor={"var(--gray-8)"}
        key={undefined}
        className={undefined}
        classNameMainContainer={undefined}
        classNameButton={undefined}
      >
        <span className="separator" />
        <ul className={styles.permissionGroups}>
          {grupos.map((grupo, index) => (
            <GrupoPermissoes
              key={index}
              grupo={grupo}
              situacaoPermissoes={situacaoPermissoes}
              alterarStatusPermissao={alterarStatusPermissao}
            />
          ))}
        </ul>
      </Collapse>
    </li>
  );
};

const ListaSistemasPermissoes = ({
  permissoes,
  situacaoPermissoes,
  alterarStatusPermissao,
}: {
  permissoes: SistemaTratado[];
  situacaoPermissoes: "A" | "I";
  alterarStatusPermissao: (permissao: PermissaoTradada) => void;
}) => {
  const sistemas = permissoes.filter((sistema) => {
    return sistema.grupos.some((grupo) => {
      return grupo.permissoes.some(
        (permissao) => permissao.statusPermissaoGrupo === situacaoPermissoes
      );
    });
  });

  if (sistemas.length === 0) {
    return (
      <p className="lineCardMessage">
        {situacaoPermissoes === "A"
          ? "Nenhuma permissão ativa no grupo"
          : "Nenhuma permissão inativa no grupo"}
      </p>
    );
  }

  return (
    <>
      {sistemas.map((sistema) => (
        <SistemaPermissoes
          key={sistema.sistema}
          sistema={sistema}
          situacaoPermissoes={situacaoPermissoes}
          alterarStatusPermissao={alterarStatusPermissao}
        />
      ))}
    </>
  );
};

interface Props {
  dadosGruposPermissoes: UseGruposPermissoes;
}

export default function AtualizarPermissoesGrupo({
  dadosGruposPermissoes,
}: Props) {
  const backRoute = usePreviousRoute();

  const { selectedGrupo } = dadosGruposPermissoes;

  const {
    permissoes,
    buscarPermissoes,
    buscandoPermissoesGrupo,
    alterarStatusPermissao,
    salvarPermissoesGrupo,
  } = useAtualizarPermissoesGrupo({
    selectedGrupo: selectedGrupo!,
  });

  useEffect(() => {
    buscarPermissoes();
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <header>
        <nav className={styles.navigationContainer}>
          <LinkButton to={backRoute} buttonStyle="backButton" />
          <h1>
            {selectedGrupo?.id} | {selectedGrupo?.nome}
          </h1>
          <span />
        </nav>
      </header>
      <span className="separator" />
      {!buscandoPermissoesGrupo ? (
        <>
          <main className={styles.permissionsContainer}>
            <div>
              <span className={styles.permissionsTitle}>
                Permissões Ativas No Grupo
              </span>
              <ul className={styles.permissionGroups}>
                <ListaSistemasPermissoes
                  permissoes={permissoes}
                  situacaoPermissoes="A"
                  alterarStatusPermissao={alterarStatusPermissao}
                />
              </ul>
            </div>
            <div>
              <span className={styles.permissionsTitle}>
                Permissões Inativas No Grupo
              </span>
              <ul className={styles.permissionGroups}>
                <ListaSistemasPermissoes
                  permissoes={permissoes}
                  situacaoPermissoes="I"
                  alterarStatusPermissao={alterarStatusPermissao}
                />
              </ul>
            </div>
          </main>
          <footer>
            <Button
              className={styles.buttonSalvar}
              onClick={() => {
                salvarPermissoesGrupo();
              }}
            >
              Salvar
            </Button>
          </footer>
        </>
      ) : (
        <div className="loadingContainer">
          <Circle size={100} />
        </div>
      )}
    </div>
  );
}
