import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { CaretLeft } from "phosphor-react";
import styles from "./LinkButton.module.css";

interface ILinkButtonProps extends LinkProps {
  variant?: "danger" | "alert" | "neutral" | "default";
  classNameContainer?: string;
  buttonStyle?: "common" | "backButton";
  disabled?: boolean;
}

const LinkButton = ({
  children,
  to = "",
  variant = "default",
  className,
  classNameContainer,
  buttonStyle = "common",
  disabled,
  ...props
}: ILinkButtonProps) => {
  return (
    <div className={`${styles.buttonContainer} ${disabled ? "isDisabled" : ""} ${classNameContainer}`}>
      <Link
        className={`${styles.button} ${styles[variant]} ${buttonStyle === "backButton" ? "backButton" : ""} ${
          disabled ? "isDisabled" : ""
        } ${className}`}
        to={to}
        {...props}>
        {buttonStyle === "backButton" ? (
          <>
            <CaretLeft weight="fill" />
            {children}
          </>
        ) : (
          <>{children}</>
        )}
      </Link>
    </div>
  );
};

export { LinkButton };
