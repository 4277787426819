import React from "react";
import { SingleSelectValueType, MultipleSelectValueType } from "../../components/Form/Select/Select";

type TypeProp = "single" | "multiple";
type SelectValueType<S = any, T = "single"> = T extends "single"
  ? SingleSelectValueType<S>
  : MultipleSelectValueType<S>;

interface IUseSelectProps<S = any, T extends TypeProp = "single"> {
  type: T;
  required: boolean;
  emptyMessage?: string;
  defaultValue?: SelectValueType<S, T>;
}

declare global {
  interface IUseSelect<S = any, T extends TypeProp = "single"> {
    value: SelectValueType<S, T>;
    setValue: (
      value: SelectValueType<S, T> | ((old: SelectValueType<S, T>) => SelectValueType<S, T>),
      validateValue?: boolean
    ) => void;
    error: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
    onChange: (value: SelectValueType<S, T>) => void;
    onBlur: () => void;
    validate: () => boolean;
    reset: (value?: SelectValueType<S, T> | ((old: SelectValueType<S, T>) => SelectValueType<S, T>)) => void;
  }
}

const typesValidate = {
  multiple: (value: SelectValueType<any, TypeProp>) =>
    value && (Array.isArray(value) || typeof value === "string") && value.length,
  single: (value: SelectValueType<any, TypeProp>) => !!value,
} as { [key: string]: (value: SelectValueType<any, TypeProp>) => boolean };

const useSelect = <T extends TypeProp, S = any>({
  type,
  required,
  emptyMessage = "Selecione uma opção",
  defaultValue,
}: IUseSelectProps<S, T>): IUseSelect<S, T> => {
  const [value, setValue] = React.useState<SelectValueType<S, T>>(defaultValue!);
  const [error, setError] = React.useState("");

  const reset = React.useCallback(
    (value: SelectValueType<S, T> | ((old: SelectValueType<S, T>) => SelectValueType<S, T>) = defaultValue!) => {
      if (typeof value === "function") {
        setValue((old) => {
          const result = value(old);
          return result;
        });
        setError("");
      } else {
        setValue(value);
        setError("");
      }
    },
    [defaultValue]
  );

  const validate = React.useCallback(
    (value: SelectValueType<S, T>) => {
      if (!required) return true;
      if (typesValidate[type] && !typesValidate[type](value)) {
        setError(emptyMessage);
        return false;
      } else {
        setError("");
        return true;
      }
    },
    [emptyMessage, required, type]
  );

  const onChange = React.useCallback(
    (value: SelectValueType<S, T>) => {
      if (error) validate(value);
      setValue(value);
    },
    [error, validate]
  );

  const onBlur = React.useCallback(() => {
    validate(value);
  }, [validate, value]);

  return {
    value,
    setValue: (
      value: SelectValueType<S, T> | ((old: SelectValueType<S, T>) => SelectValueType<S, T>),
      validateValue: boolean = true
    ) => {
      if (typeof value === "function") {
        setValue((old) => {
          const result = value(old);
          if (validateValue) validate(result);
          return result;
        });
      } else {
        setValue(value);
        if (validateValue) validate(value);
      }
    },
    error,
    setError,
    onChange,
    onBlur,
    validate: () => validate(value),
    reset,
  };
};

const useSelectInstance = <S = any, T extends TypeProp = "single">(props: IUseSelectProps<S, T>): IUseSelect<S, T> => {
  let defaultValue = props.defaultValue;
  if (defaultValue === undefined && props.type === "single") {
    defaultValue = null as SelectValueType<S, T>;
  } else if (defaultValue === undefined && props.type === "multiple") {
    defaultValue = [] as unknown as SelectValueType<S, T>;
  }
  return useSelect({ ...props, defaultValue });
};

export { useSelectInstance as useSelect };
