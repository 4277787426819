import React, { useEffect } from "react";
import { Button, Input, Select } from "../../../../components/Form";

import styles from "./List.module.css";
import { Table } from "../../../../components/Data/XTable";
import { IUseNonconformity, TipoPagina } from "../../hooks/types";
import { Circle } from "../../../../components/Loading";
import { Paginate } from "../../../../components/Paginate/Paginate";

interface Props {
  data: IUseNonconformity;
}

export default function List({ data }: Props) {
  const {
    filters: {
      filterOrder,
      filterInitialDate,
      filterFinalDate,
      filterEnterprise,
      filterSellers,
    },
    options: { enterpriseOptions, sellerOptions },
    data: { tableData },
    sets: { setSelectedOrder },
    methods: {
      searchSellersDebounced,
      searchNonconformingOrders,
      searchTotalRecordsAndNonconformingOrders,
    },
    loadings: {
      searchingNonconformingOrders,
      searchingEnterprises,
      searchingSellers,
    },
    pagination,
  } = data;

  useEffect(() => {
    setSelectedOrder(null);
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.filtersContainer}>
        <div className={styles.filtersContainer__filterOrder}>
          <label htmlFor="filterOrder" className="label">
            Pedido
          </label>
          <Input
            id="filterOrder"
            placeholder="Digite o id ou a descrição do pedido"
            value={filterOrder.value}
            onChange={filterOrder.onChange}
            error={filterOrder.error}
          />
        </div>
        <div className={styles.filtersContainer__filterSellers}>
          <label htmlFor="filterSellers" className="label">
            Vendedores
          </label>
          <Select
            id="filterSellers"
            placeholder="Selecione os vendedores que deseja filtrar"
            options={sellerOptions}
            value={filterSellers.value}
            onChange={filterSellers.onChange}
            onInputChange={(text) => {
              searchSellersDebounced(text);
            }}
            error={filterSellers.error}
            isMulti
            isLoading={searchingSellers}
          />
        </div>
        <div className={styles.filtersContainer__filterEnterprise}>
          <label htmlFor="filterEnterprise" className="label">
            Empresa
          </label>
          <Select
            id="filterEnterprise"
            placeholder="Selecione as empresas"
            options={enterpriseOptions.sort(
              (a, b) => a.value.idEmpresa - b.value.idEmpresa
            )}
            value={filterEnterprise.value}
            onChange={filterEnterprise.onChange}
            error={filterEnterprise.error}
            isMulti
            closeMenuOnSelect={false}
            isSearchable={false}
            isLoading={searchingEnterprises}
          />
        </div>
        <div>
          <label htmlFor="filterInitialDate" className="label">
            Data Inicial
          </label>
          <Input
            id="filterInitialDate"
            type="date"
            value={filterInitialDate.value}
            onChange={filterInitialDate.onChange}
            error={filterInitialDate.error}
          />
        </div>
        <div>
          <label htmlFor="filterFinallDate" className="label">
            Data Final
          </label>
          <Input
            id="filterFinallDate"
            type="date"
            value={filterFinalDate.value}
            onChange={filterFinalDate.onChange}
            error={filterFinalDate.error}
          />
        </div>
        <Button
          className={styles.filtersContainer__search}
          onClick={() => {
            searchTotalRecordsAndNonconformingOrders();
          }}
        >
          Buscar Pedidos
        </Button>
      </div>
      <span className="separator" />
      <div>
        {tableData.data.length > 0 && !searchingNonconformingOrders ? (
          <Table
            tableData={tableData}
            lineOptions={{
              style: (data) => ({
                backgroundColor:
                  data.status === TipoPagina.reopened
                    ? "rgba(255, 205, 0, 0.2)"
                    : "",
              }),
            }}
          />
        ) : searchingNonconformingOrders ? (
          <div className={`loadingContainer`}>
            <Circle size={100} />
          </div>
        ) : (
          <span className={`lineCardMessage`}>Nenhum pedido encontrado</span>
        )}
        <Paginate
          classNameContainer={styles.paginationContainer}
          maxItems={pagination.maxItems}
          totalRecords={pagination.totalRecords}
          currentPage={pagination.currentPage}
          setCurrentPage={pagination.setCurrentPage}
          onPageChange={(page: number) => searchNonconformingOrders(page)}
        />
      </div>
    </div>
  );
}
