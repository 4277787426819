const start = () => {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      navigator.serviceWorker.ready.then((registration) => {
        registration.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey:
              "BA3asJobXgcQZNqoCCeV9br9CC67dxN-s1frlyfA3DiZi0cVATcFyGoQ5JSrYiPMUQ65v8e1j9shL4nezmRkURg",
          })
          .then((subscription) => {
            fetch("/test/add-inscricao", {
              method: "POST",
              body: JSON.stringify(subscription),
              headers: {
                "content-type": "application/json",
              },
            }).then((response) => {
              response.json().then((data) => {
                console.log(data);
              });
            });
          });
      });
    }
  });
};

export const notifications = {
  start,
};
