import React from "react";

import {
  Customer,
  IProductGroup,
  ISeller,
  ISupervisor,
  ITable,
} from "../../types";
import { IUseSelectOptions } from "./types";

import { useModal } from "../../../../hooks/contexts";
import {
  useCustomFetch,
  useDebounce,
  useLoading,
} from "../../../../hooks/async";
import { formatCNPJ } from "../../../../helpers/format";

export function useSelectOptions(): IUseSelectOptions {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const sellersAbortController = React.useRef(new AbortController());

  const [productGroupOptions, setProductGroupOptions] = React.useState<
    ISelectOption<IProductGroup>[]
  >([]);
  const [sellerOptions, setSellerOptions] = React.useState<
    ISelectOption<ISeller>[]
  >([]);
  const [supervisorOptions, setSupervisorOptions] = React.useState<
    ISelectOption<ISupervisor>[]
  >([]);
  const [tableOptions, setTableOptions] = React.useState<
    ISelectOption<ITable>[]
  >([]);
  const [customerOptions, setCustomerOptions] = React.useState<
    ISelectOption<Customer>[]
  >([]);

  const searchingProductGroupOptions = useLoading();
  const searchingSellerOptions = useLoading();
  const searchingSupervisorOptions = useLoading();
  const searchingTableOptions = useLoading();
  const searchingCustomerOptions = useLoading();

  const searchProductGroupOptions = React.useCallback(
    async (description = "") => {
      try {
        searchingProductGroupOptions.setLoading(true);
        const json = (await customFetch("/contracts/searchProductGroups", {
          body: { descricao: description },
        })) as DefaultFetchResponse<IProductGroup[]>;
        if (json.status === 200) {
          const options = json.object.map((item) => ({
            label: item.descricao,
            value: item,
          }));
          setProductGroupOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingProductGroupOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingProductGroupOptions]
  );

  const searchSellerOptions = React.useCallback(
    async (description: string, supervisors: number[]) => {
      sellersAbortController.current.abort();
      sellersAbortController.current = new AbortController();
      try {
        searchingSellerOptions.setLoading(true);
        const json = (await customFetch("/contracts/searchSellers", {
          body: { descricao: description, supervisores: supervisors },
          signal: sellersAbortController.current.signal,
        })) as DefaultFetchResponse<ISeller[]>;
        if (json.status === 200) {
          const options = json.object.map((item) => ({
            label: `${item.id} | ${item.descricao}`,
            value: item,
          }));
          setSellerOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setSellerOptions([]);
        }
        searchingSellerOptions.setLoading(false);
      } catch (error: any) {
        if (error.name !== "AbortError") {
          Modal.error(error);
          searchingSellerOptions.setLoading(() => false);
        }
      }
    },
    [Modal, customFetch, searchingSellerOptions]
  );

  const searchSupervisorOptions = React.useCallback(
    async (description = "") => {
      try {
        searchingSupervisorOptions.setLoading(true);
        const json = (await customFetch("/contracts/searchSupervisors", {
          body: { descricao: description },
        })) as DefaultFetchResponse<ISupervisor[]>;
        if (json.status === 200) {
          const options = json.object.map((item) => ({
            label: `${item.id} | ${item.descricao}`,
            value: item,
          }));
          setSupervisorOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setSupervisorOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingSupervisorOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingSupervisorOptions]
  );

  const searchTableOptions = React.useCallback(
    async (description = "", groupId?: number) => {
      try {
        searchingTableOptions.setLoading(true);
        const json = (await customFetch(
          "/contracts/validityTable/searchValidityTables",
          {
            body: {
              descricao: description,
              idGrupo: groupId,
            },
          }
        )) as DefaultFetchResponse<ITable[]>;
        if (json.status === 200) {
          const options = json.object.map((option) => {
            return {
              value: option,
              label: `${option.idTabelaVigencia} | ${option.nomeTabelaVigencia}`,
            };
          });
          setTableOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setTableOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingTableOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingTableOptions, setTableOptions]
  );

  const searchCustomers = React.useCallback(
    async (description = "") => {
      try {
        searchingCustomerOptions.setLoading(true);
        const json = (await customFetch("/contracts/searchCustomers", {
          body: { descricao: description },
        })) as DefaultFetchResponse<Customer[]>;
        if (json.status === 200) {
          const options = json.object.map((item) => ({
            label: `${item.razaoSocial} (${item.nomeFantasia}) | ${
              formatCNPJ(item.cnpj) || "SEM CNPJ"
            }`,
            value: item,
          }));
          setCustomerOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setCustomerOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingCustomerOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingCustomerOptions]
  );

  const searchSellerOptionsDebounced = useDebounce(searchSellerOptions);
  const searchTableOptionsDebounced = useDebounce(searchTableOptions);
  const searchCustomersDebounced = useDebounce(searchCustomers);

  React.useEffect(() => {
    searchCustomers();
    searchProductGroupOptions();
    searchSupervisorOptions();
    // searchTableOptions();
  }, []); // eslint-disable-line

  return {
    productGroupOptions: {
      options: productGroupOptions,
      search: searchProductGroupOptions,
      loading: searchingProductGroupOptions.isLoading,
    },
    sellerOptions: {
      options: sellerOptions,
      search: searchSellerOptions,
      searchDebounced: searchSellerOptionsDebounced,
      loading: searchingSellerOptions.isLoading,
    },
    supervisorOptions: {
      options: supervisorOptions,
      search: searchSupervisorOptions,
      loading: searchingSupervisorOptions.isLoading,
    },
    tableOptions: {
      options: tableOptions,
      search: searchTableOptions,
      searchDebounced: searchTableOptionsDebounced,
      loading: searchingTableOptions.isLoading,
    },
    customerOptions: {
      options: customerOptions,
      search: searchCustomers,
      searchDebounced: searchCustomersDebounced,
      loading: searchingCustomerOptions.isLoading,
    },
  };
}
