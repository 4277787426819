import React from "react";
import { IUsePeriodicidadesPagto } from "../hooks/types";
import styles from "./Lista.module.css";
import { LinkButton } from "../../../../components/Form";
import { Table } from "../../../../components/Data/XTable";
import { Circle } from "../../../../components/Loading";

interface IProps {
  periodicidadesPagtoData: IUsePeriodicidadesPagto;
}

export function Lista({ periodicidadesPagtoData }: IProps) {
  const {
    selectedPeriodicidadePagto,
    data: { tableData },
    searchs: { searchingPeriodicidadesPagto },
  } = periodicidadesPagtoData;

  React.useEffect(() => {
    selectedPeriodicidadePagto.setValue(null);
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.navContainer}>
        <LinkButton to="/contratos-fornecedor-cliente/periodicidades-pagamento/nova" className={styles.navButton}>
          Nova Periodicidade Pagamento
        </LinkButton>
      </div>
      <div className={styles.dataContainer}>
        {tableData.data.length && !searchingPeriodicidadesPagto ? (
          <div className={`${styles.tableContainer} customScrollbar`}>
            <Table tableData={tableData} />
          </div>
        ) : searchingPeriodicidadesPagto ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhuma Periodicidade Pagamento Encontrada</p>
        )}
      </div>
    </div>
  );
}
