import { useCallback, useState } from "react";
import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { useDate, useSelect } from "../../../../../hooks/form";
import { IAction, IEnterprise, IProduct } from "../../hooks/types";
import { usePagination } from "../../../../../hooks/pagination";
import {
  IInventoryRecords,
  IMappedRecords,
  IUseInventory,
  SearchInventoryRecordsResponse,
  SearchTotalInventoryRecordsResponse,
} from "./types";
import { isValid } from "../../../../../helpers/validations";

const mapProducts = (products: IInventoryRecords[]) => {
  const final: IMappedRecords = {};

  products.forEach((item) => {
    if (!final[item.idProduto]) {
      final[item.idProduto] = [item];
    } else {
      final[item.idProduto].push(item);
    }
  });

  return final;
};

export function useInventory(): IUseInventory {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const initialDate = useDate({ required: true });
  const finalDate = useDate({ required: true });
  const products = useSelect<IProduct, "multiple">({ type: "multiple", required: true });
  const enterprises = useSelect<IEnterprise, "multiple">({ type: "multiple", required: true });
  const moveType = useSelect<IAction>({
    type: "single",
    required: true,
    defaultValue: { label: "Entrada", value: "E" },
  });

  const [inventoryRecords, setInventoryRecords] = useState<IMappedRecords>({});
  const pagination = usePagination(100);

  const searchingInventoryRecords = useLoading();

  const confirmChange = useCallback(
    async (variable: IUseSelect<any, any> | IUseDate | IUseForm, value: any) => {
      if (Object.keys(inventoryRecords).length) {
        const confirm = await Modal.confirm(
          "Esta ação irá limpar os dados buscados anteriormente.<br/>Deseja continuar?"
        );
        if (confirm) {
          setInventoryRecords({});
          variable.onChange(value);
        }
      } else {
        variable.onChange(value);
      }
    },
    [Modal, inventoryRecords]
  );

  const searchTotalInventoryRecordsPromise = useCallback(
    (page = 0) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await customFetch("/factory/searchInventoryTotalRecords", {
            body: {
              pagina: page,
              tamanho: pagination.maxItems,
              dataInicial: initialDate.value ? initialDate.toISOString() : "",
              dataFinal: finalDate.value ? finalDate.toISOStringNextDay() : "",
              produto: products.value.map((product) => String(product.value.idProduto)),
              idEmpresa: enterprises.value.map((enterprise) => enterprise.value.idEmpresa),
              tipoMovimento: moveType.value?.value,
            },
          });
          resolve(response);
        } catch (error) {
          reject(error);
        }
      }) as Promise<SearchTotalInventoryRecordsResponse>;
    },
    [customFetch, enterprises.value, finalDate, initialDate, moveType.value, products.value, pagination.maxItems]
  );

  const searchInventoryRecordsPromise = useCallback(
    (page = 0) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await customFetch("/factory/searchInventoryRecordsPaginated", {
            body: {
              pagina: page,
              tamanho: pagination.maxItems,
              dataInicial: initialDate.value ? initialDate.toISOString() : "",
              dataFinal: finalDate.value ? finalDate.toISOStringNextDay() : "",
              produto: products.value.map((product) => String(product.value.idProduto)),
              idEmpresa: enterprises.value.map((enterprise) => enterprise.value.idEmpresa),
              tipoMovimento: moveType.value?.value,
            },
          });
          resolve(response);
        } catch (error) {
          reject(error);
        }
      }) as Promise<SearchInventoryRecordsResponse>;
    },
    [customFetch, enterprises.value, finalDate, initialDate, moveType.value, products.value, pagination.maxItems]
  );

  const searchInventoryRecords = useCallback(
    async (page = 0) => {
      if (!isValid(initialDate, finalDate, products, enterprises)) return;
      try {
        searchingInventoryRecords.setLoading(true);
        const response = await searchInventoryRecordsPromise(page);
        if (response.status === 200) {
          const productsMapped = mapProducts(response.object);
          setInventoryRecords(productsMapped);
        } else if (response.status === 500) {
          setInventoryRecords({});
          Modal.error(response.message, response.object);
        } else {
          setInventoryRecords({});
          Modal.error(response.message, response.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        searchingInventoryRecords.setLoading(false);
      }
    },
    [Modal, enterprises, finalDate, initialDate, products, searchInventoryRecordsPromise, searchingInventoryRecords]
  );

  const searchTotalInventoryRecordsAndInventoryRecords = useCallback(async () => {
    if (!isValid(initialDate, finalDate, products, enterprises)) return;
    try {
      searchingInventoryRecords.setLoading(true);
      const [inventory, records] = (await Promise.all([
        searchInventoryRecordsPromise(),
        searchTotalInventoryRecordsPromise(),
      ])) as [SearchInventoryRecordsResponse, SearchTotalInventoryRecordsResponse];
      if (inventory.status === 200 && records.status === 200) {
        const productsMapped = mapProducts(inventory.object);
        setInventoryRecords(productsMapped);
        pagination.setTotalRecords(records.object.total);
      } else if (inventory.status !== 200) {
        if (inventory.status === 500) {
          Modal.error(inventory.message, inventory.object);
        } else {
          pagination.reset();
          setInventoryRecords({});
        }
      } else {
        if (records.status === 500) {
          Modal.error(records.message, records.object);
        } else {
          setInventoryRecords({});
        }
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      pagination.setCurrentPage(1);
      searchingInventoryRecords.setLoading(false);
    }
  }, [
    Modal,
    enterprises,
    finalDate,
    initialDate,
    pagination,
    products,
    searchInventoryRecordsPromise,
    searchTotalInventoryRecordsPromise,
    searchingInventoryRecords,
  ]);

  return {
    initialDate,
    finalDate,
    products,
    enterprises,
    moveType,
    inventoryRecords: {
      value: inventoryRecords,
      setValue: setInventoryRecords,
    },
    searchs: {
      searchTotalInventoryRecordsAndInventoryRecords,
      searchInventoryRecords,
      pagination,
      searching: searchingInventoryRecords.isLoading,
    },
    confirmChange,
  };
}
