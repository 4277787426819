import React from "react";
import { IUseExigencia } from "../hooks/types";
import styles from "./Lista.module.css";
import { LinkButton } from "../../../../components/Form";
import { Table } from "../../../../components/Data/XTable";
import { Circle } from "../../../../components/Loading";

interface IProps {
  exigenciaData: IUseExigencia;
}

export function Lista({ exigenciaData }: IProps) {
  const {
    selectedExigencia,
    data: { tableData },
    searchs: { searchingExigencias },
  } = exigenciaData;

  React.useEffect(() => {
    selectedExigencia.setValue(null);
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.navContainer}>
        <LinkButton to="/contratos-fornecedor-cliente/exigencias/nova" className={styles.navButton}>
          Nova Exigência
        </LinkButton>
      </div>
      <div className={styles.dataContainer}>
        {tableData.data.length && !searchingExigencias ? (
          <div className={`${styles.tableContainer} customScrollbar`}>
            <Table tableData={tableData} />
          </div>
        ) : searchingExigencias ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhuma Exigência Encontrada</p>
        )}
      </div>
    </div>
  );
}
