import React from "react";
import { Button, Input, Select } from "../../../components/Form";
import { Circle } from "../../../components/Loading";
import { useCustomFetch, useDebounce, useLoading } from "../../../hooks/async";
import { useChanges, useModal } from "../../../hooks/contexts";
import { useSelect } from "../../../hooks/form";

import styles from "./OrderPermissions.module.css";

interface IUser {
  email: string | null;
  idUsuario: number;
  nome: string;
}

interface ISearchUsersResponse {
  response: number;
  message: string;
  status: number;
  object: IUser[];
}

interface ISubCategoryPeople {
  idTipoSubcategoria: number;
  idSubcategoria: number;
  descricao: string;
  simbolosPermitidos: string;
  tipoCampo: string;
  itensSelect:
    | {
        label: string;
        value: any;
      }[]
    | null;
  valorAtual: string;
}

interface ISearchCategoryResponse {
  response: number;
  message: string;
  status: number;
  object: ISubCategoryPeople[];
}

interface IInsertAndUpdateSubcategoryPeopleResponse {
  response: number;
  message: string;
  status: number;
  object: any;
}

// const categoriesMock: ISubCategoryPeople[] = [];

const getSymbolOptions = (symbols: string) => {
  return symbols
    .split(";")
    .filter((item) => !!item)
    .map((item) => ({
      label: item,
      value: item,
    }));
};

export function SettingsOrderPermissions() {
  const { isChangesDetected, setIsChangesDetected } = useChanges();

  const customFetch = useCustomFetch();
  const Modal = useModal();

  const user = useSelect({ type: "single", required: true });
  const [userOptions, setUserOptions] = React.useState<{ label: string; value: IUser }[]>([]);

  const [permissionsValues, setPermissionsValues] = React.useState<{ [key: string]: any }>({});
  const [categoryTypes, setCategoryTypes] = React.useState<ISubCategoryPeople[]>([]);

  const searchingUsers = useLoading();
  const searchingCategoryTypes = useLoading();
  const savingSubcategoriesPeople = useLoading();

  const searchUsers = React.useCallback(
    async (search: string = "") => {
      try {
        searchingUsers.setLoading(true);
        const json = (await customFetch("/orderPermissions/searchUsers", {
          body: {
            pesquisa: search,
          },
        })) as ISearchUsersResponse;

        if (json.status === 200) {
          const options = json.object.map((opt) => {
            return {
              label: opt.nome,
              value: opt,
            };
          });
          setUserOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setUserOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingUsers.setLoading(false);
      }
    },
    [Modal, customFetch, searchingUsers]
  );

  const searchUsersDebounced = useDebounce(searchUsers);

  const searchCategoryTypes = async (userId: number) => {
    try {
      searchingCategoryTypes.setLoading(true);
      const json = (await customFetch("/orderPermissions/searchCategoryTypes", {
        body: {
          idPessoa: userId,
        },
      })) as ISearchCategoryResponse;

      if (json.status === 200) {
        json.object.forEach((item) => {
          if (item.tipoCampo === "input") {
            if (item.valorAtual) {
              const values = item.valorAtual.split(";");
              setPermissionsValues((old) => ({
                ...old,
                [item.descricao]: {
                  ...old[item.descricao],
                  selfCategory: item,
                  symbol: {
                    label: values[0],
                    value: values[0],
                  },
                  value: values[1],
                },
              }));
            } else {
              const allowedSymbols = getSymbolOptions(item.simbolosPermitidos);
              setPermissionsValues((old) => ({
                ...old,
                [item.descricao]: {
                  ...old[item.descricao],
                  selfCategory: item,
                  symbol: {
                    label: allowedSymbols[0].label,
                    value: allowedSymbols[0].value,
                  },
                  value: 0,
                },
              }));
            }
          } else if (item.tipoCampo === "select") {
            if (item.valorAtual) {
              setPermissionsValues((old) => ({
                ...old,
                [item.descricao]: {
                  ...old[item.descricao],
                  selfCategory: item,
                  value: item.itensSelect?.find((i) => i.value === item.valorAtual),
                },
              }));
            } else {
              setPermissionsValues((old) => ({
                ...old,
                [item.descricao]: {
                  ...old[item.descricao],
                  selfCategory: item,
                  value: item.itensSelect ? item.itensSelect[0] : null,
                },
              }));
            }
          } else if (item.tipoCampo === "multi-select") {
            if (item.valorAtual) {
              const values = item.valorAtual.split(";");

              if (!values[values.length - 1]) {
                values.pop();
              }

              setPermissionsValues((old) => ({
                ...old,
                [item.descricao]: {
                  ...old[item.descricao],
                  selfCategory: item,
                  value: item.itensSelect?.filter((i) => {
                    return values.includes(String(i.value));
                  }),
                },
              }));
            } else {
              setPermissionsValues((old) => ({
                ...old,
                [item.descricao]: {
                  ...old[item.descricao],
                  selfCategory: item,
                },
              }));
            }
          }
        });

        setCategoryTypes(json.object);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingCategoryTypes.setLoading(false);
    }
  };

  const insertAndUpdateSubcategoryPeople = async () => {
    const jsonToSend = [];

    try {
      savingSubcategoriesPeople.setLoading(true);
      for (const key in permissionsValues) {
        if (permissionsValues[key].error) {
          throw new Error("Há campos inválidos!");
        }
        jsonToSend.push({
          id: permissionsValues[key].selfCategory.idSubcategoria,
          nome: key,
          status: "A",
          idPessoa: (user.value as unknown as { [key: string]: any }).value.idUsuario,
          valor: (() => {
            if (permissionsValues[key].selfCategory.tipoCampo === "input") {
              return `${permissionsValues[key].symbol.value};${permissionsValues[key].value}`;
            } else if (permissionsValues[key].selfCategory.tipoCampo === "select") {
              return permissionsValues[key].value.value;
            } else if (permissionsValues[key].selfCategory.tipoCampo === "multi-select" && key === "Tipo Grupo") {
              const ids = permissionsValues[key]?.value.map((i: any) => i.value);
              return ids.join(";");
            } else {
              return "";
            }
          })(),
          idTipoSubCategoria: permissionsValues[key].selfCategory.idTipoSubcategoria,
        });
      }

      const json = (await customFetch("/orderPermissions/insertAndUpdateSubcategoryPeople", {
        body: jsonToSend,
      })) as IInsertAndUpdateSubcategoryPeopleResponse;

      if (json.status === 200) {
        Modal.success(json.message);
        setIsChangesDetected(false);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      savingSubcategoriesPeople.setLoading(false);
    }
  };

  React.useEffect(() => {
    searchUsers();
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div>
        <label htmlFor="people" className="label">
          Pessoa
        </label>
        <Select
          id={"people"}
          placeholder={"Selecione uma pessoa para definir as permissões de pedido"}
          value={user.value}
          options={userOptions}
          defaultValue={null}
          error={user.error}
          onBlur={user.onBlur}
          onChange={async (value: { label: string; value: IUser }) => {
            if (isChangesDetected) {
              const confirm = await Modal.confirm("Há mudanças não salvas.<br />Deseja continuar mesmo assim?");
              if (confirm) {
                user.onChange(value);
                if (value) {
                  searchCategoryTypes(value.value.idUsuario);
                } else {
                  setCategoryTypes([]);
                }
                setIsChangesDetected(false);
              }
            } else {
              user.onChange(value);
              if (value) {
                searchCategoryTypes(value.value.idUsuario);
              } else {
                setCategoryTypes([]);
              }
            }
          }}
          onInputChange={(value: string) => {
            searchUsersDebounced(value);
          }}
          isLoading={searchingUsers.isLoading}
          isDisabled={searchingCategoryTypes.isLoading || savingSubcategoriesPeople.isLoading}
          noOptionsMessage={() => "Nenhum usuário encontrado"}
          loadingMessage={() => "Buscando..."}
        />
      </div>
      <div className={`${styles.contentContainer}`}>
        {categoryTypes.length && !searchingCategoryTypes.isLoading && !savingSubcategoriesPeople.isLoading ? (
          <>
            <div className={styles.permissionsContainer__separator}></div>
            <div className={`${styles.permissionsContainer}`}>
              {categoryTypes.map((item, index) => (
                <div key={index}>
                  <label htmlFor="" className="label">
                    {item.descricao}
                  </label>
                  {item.tipoCampo === "input" ? (
                    <div className={styles.numericPermissionContainer}>
                      <Select
                        options={getSymbolOptions(item.simbolosPermitidos)}
                        value={permissionsValues[item.descricao]?.symbol}
                        onChange={(e: any) => {
                          setPermissionsValues((old) => ({
                            ...old,
                            [item.descricao]: {
                              ...old[item.descricao],
                              symbol: e,
                            },
                          }));
                          setIsChangesDetected(true);
                        }}
                        defaultValue={null}
                        isClearable={false}
                        error={""}
                      />
                      <Input
                        type="number"
                        className="cleanInputNumber"
                        value={permissionsValues[item.descricao]?.value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setPermissionsValues((old) => {
                            return {
                              ...old,
                              [item.descricao]: {
                                ...old[item.descricao],
                                value: e.target.value,
                              },
                            };
                          });
                          if (permissionsValues[item.descricao]?.error && e.target.value) {
                            setPermissionsValues((old) => {
                              return {
                                ...old,
                                [item.descricao]: {
                                  ...old[item.descricao],
                                  error: "",
                                },
                              };
                            });
                          }
                          setIsChangesDetected(true);
                        }}
                        error={permissionsValues[item.descricao]?.error}
                        onBlur={() => {
                          if (!permissionsValues[item.descricao]?.value) {
                            setPermissionsValues((old) => {
                              return {
                                ...old,
                                [item.descricao]: {
                                  ...old[item.descricao],
                                  error: "Preencha um valor!",
                                },
                              };
                            });
                          }
                        }}
                      />
                    </div>
                  ) : item.tipoCampo === "select" ? (
                    <div>
                      <Select
                        options={item.itensSelect}
                        value={permissionsValues[item.descricao]?.value}
                        error={""}
                        onChange={(e: any) => {
                          setPermissionsValues((old) => ({
                            ...old,
                            [item.descricao]: {
                              ...old[item.descricao],
                              value: e,
                            },
                          }));
                          setIsChangesDetected(true);
                        }}
                        isSearchable={false}
                        defaultValue={null}
                        isClearable={false}
                      />
                    </div>
                  ) : item.tipoCampo === "multi-select" ? (
                    <div>
                      <Select
                        options={item.itensSelect}
                        value={permissionsValues[item.descricao]?.value}
                        error={""}
                        onChange={(e: any) => {
                          setPermissionsValues((old) => ({
                            ...old,
                            [item.descricao]: {
                              ...old[item.descricao],
                              value: e,
                            },
                          }));
                          setIsChangesDetected(true);
                        }}
                        defaultValue={null}
                        isClearable={false}
                        isMulti={true}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ))}
              <div className={styles.submitButtonContainer}>
                <Button onClick={insertAndUpdateSubcategoryPeople}>Salvar</Button>
              </div>
            </div>
          </>
        ) : searchingCategoryTypes.isLoading ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
          </div>
        ) : savingSubcategoriesPeople.isLoading ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
            <span className="loadingMessage">Salvando Subcategorias</span>
          </div>
        ) : (
          <p className={`lineCardMessage`}>Selecione uma pessoa para configurar</p>
        )}
      </div>
    </div>
  );
}
