import React from "react";
import { ModalConfirm } from "./ModalConfirm";
import { ModalSuccess } from "./ModalSuccess";
import { ModalError } from "./ModalError";
import { ModalAlert } from "./ModalAlert";

import containerStyles from "./styles/container.module.css";

export type TModalOpened = "confirm" | "success" | "error" | "alert" | "";
export type TModalMessage = any;
export type TModalOnContinue = () => any;
export type TModalOnCancel = () => any;
export type TModalErrorDetails = any;

interface IModalProps {
  modalOpened: TModalOpened;
  message: TModalMessage;
  onContinue: TModalOnContinue;
  onCancel: TModalOnCancel;
  errorDetails: any;
}

function preventTab(e: any) {
  if (e.key === "Tab") {
    e.preventDefault();
  }
}

const Modal = ({ modalOpened, message, onContinue, onCancel, errorDetails }: IModalProps) => {
  React.useEffect(() => {
    const focusedElement = document.querySelector("*:focus") as any;

    if (modalOpened) {
      document.addEventListener("keydown", preventTab);
      focusedElement?.blur();
    } else document.removeEventListener("keydown", preventTab);
  }, [modalOpened]);

  function renderModal() {
    if (modalOpened === "confirm") {
      return <ModalConfirm message={message} onContinue={onContinue} onCancel={onCancel} />;
    } else if (modalOpened === "success") {
      return <ModalSuccess message={message} onContinue={onContinue} />;
    } else if (modalOpened === "error") {
      return <ModalError message={message} onContinue={onContinue} details={errorDetails} />;
    } else if (modalOpened === "alert") {
      return <ModalAlert message={message} onContinue={onContinue} />;
    } else {
      return null;
    }
  }

  return <div className={`${containerStyles.container} ${modalOpened ? "opened" : ""}`}>{renderModal()}</div>;
};

export { Modal };
