import React from "react";
import { ChangesContext } from "../../contexts/changes";

const useChanges = () => {
  return React.useContext(ChangesContext);
};

export type IUseChanges = ReturnType<typeof useChanges>;

export { useChanges };
