import React from "react";

import { IContractTableAnalysis } from "../../types";
import { IUseSelectOptions } from "../../components/hooks/types";
import { Button, Input, Select } from "../../../../components/Form";
import { useLoading } from "../../../../hooks/async";
import { Circle } from "../../../../components/Loading";
import { Collapse } from "../../../../components/Collapse/Collapse";
import { formatMoney } from "../../../../helpers/format";
import { useForm } from "../../../../hooks/form";
import { isValid } from "../../../../helpers/validations";
import Decimal from "decimal.js";

import styles from "./ContractTableAnalysis.module.css";

interface Props {
  data: IContractTableAnalysis;
  table: IUseSelect<any, "single">;
  tableOptions?: IUseSelectOptions["tableOptions"];
  colorOptions: [
    ISelectOption<string>[],
    React.Dispatch<React.SetStateAction<ISelectOption<string>[]>>
  ];
  interestCalculated: number;
  calculatingInterest: boolean;
  disabled?: boolean;
  disabledRanges?: boolean;
}

const defaultTableOptions: IUseSelectOptions["tableOptions"] = {
  options: [],
  search: async () => {},
  searchDebounced: async () => {},
  loading: false,
};

const isAllItemsSelected = (
  items: IContractTableAnalysis["items"]["value"]
) => {
  return items.every((item) => item.usarFormula);
};

export function ContractTableAnalysis({
  data,
  table,
  tableOptions = defaultTableOptions,
  colorOptions: [colorOptions, setColorOptions],
  interestCalculated,
  calculatingInterest,
  disabled = false,
  disabledRanges = false,
}: Props) {
  const { items, defaultColor, defaultLetter } = data;

  const searchingTableItems = useLoading();

  const modalSearchFormulaRef = React.useRef<HTMLDialogElement>(null);
  const searchedFormula = useForm({ required: true });

  const scrollToFormula = React.useCallback((search: string) => {
    const formulas = document.querySelectorAll(`[data-formula]`);
    if (formulas.length) {
      const formula = Array.from(formulas).find((formula) =>
        formula.getAttribute("data-formula")?.includes(search.toUpperCase())
      );
      if (formula)
        formula.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, []);

  const itemsNotInStandard = React.useMemo(() => {
    return items.value.filter((item) => {
      const range = item.faixasVigencia.find(
        (range) => range.faixasPrecoProdutoAdubo.escolhida
      );
      return (
        (range?.faixasPrecoProdutoAdubo.rgb !== defaultColor.value?.value ||
          range?.faixasPrecoProdutoAdubo.observacao !== defaultLetter.value) &&
        item.usarFormula
      );
    });
  }, [defaultColor.value?.value, defaultLetter.value, items.value]);

  const defineColorOptions = React.useCallback(
    (items: IContractTableAnalysis["items"]["value"]) => {
      const colors: { [key: string]: string } = {};
      items.forEach((item) => {
        item.faixasVigencia.forEach((range) => {
          if (!colors[`${range.faixasPrecoProdutoAdubo.rgb}`])
            colors[`${range.faixasPrecoProdutoAdubo.rgb}`] =
              range.faixasPrecoProdutoAdubo.rgb;
        });
      });
      const colorOptions = [];
      for (const key in colors) {
        colorOptions.push({
          value: key,
          label: (
            <div className={styles.selectColorLabel}>
              <span
                className={styles.selectOptionBall}
                style={{ backgroundColor: key }}
              />
              <p>{key}</p>
            </div>
          ),
        });
      }
      setColorOptions(colorOptions);
    },
    [setColorOptions]
  );

  const onSelectRangesByColorAndLetter = (color: string, letter: string) => {
    items.value.forEach((item) => {
      item.faixasVigencia.forEach((range) => {
        if (color && letter) {
          if (
            range.faixasPrecoProdutoAdubo.rgb === color &&
            range.faixasPrecoProdutoAdubo.observacao === letter
          ) {
            range.faixasPrecoProdutoAdubo.escolhida = true;
            // item.precoVenda = range.precoVenda;
          } else range.faixasPrecoProdutoAdubo.escolhida = false;
        } else {
          range.faixasPrecoProdutoAdubo.escolhida = false;
          // item.precoVenda = 0;
        }
      });
    });
    items.setValue(JSON.parse(JSON.stringify(items.value)));
  };

  const onSelectRange = (
    item: IContractTableAnalysis["items"]["value"][0],
    range: IContractTableAnalysis["items"]["value"][0]["faixasVigencia"][0]
  ) => {
    if (range.faixasPrecoProdutoAdubo.escolhida) {
      range.faixasPrecoProdutoAdubo.escolhida = false;
      // item.precoVenda = 0;
      items.setValue(JSON.parse(JSON.stringify(items.value)));
      return;
    }
    item.faixasVigencia.forEach(
      (range) => (range.faixasPrecoProdutoAdubo.escolhida = false)
    );
    range.faixasPrecoProdutoAdubo.escolhida =
      !range.faixasPrecoProdutoAdubo.escolhida;
    // item.precoVenda = range.escolhida ? range.precoVenda : 0;
    items.setValue(JSON.parse(JSON.stringify(items.value)));
  };

  const handleChangeCheckItem = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: IContractTableAnalysis["items"]["value"][0]
  ) => {
    item.usarFormula = event.currentTarget.checked;
    items.setValue([...items.value]);
  };

  const handleChangeSelectAll = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    items.value.forEach(
      (item) => (item.usarFormula = event.currentTarget.checked)
    );
    items.setValue([...items.value]);
  };

  React.useEffect(() => {
    defineColorOptions(items.value);
  }, []); // eslint-disable-line

  return (
    <>
      <div>
        <div>
          <label htmlFor="table" className="label">
            Tabela de Vigência
          </label>
          <Select
            id="table"
            placeholder="Selecione uma tabela de vigência"
            options={tableOptions.options}
            value={table.value}
            error={table.error}
            isLoading={tableOptions.loading}
            isDisabled={disabled}
          />
        </div>
        <div className={styles.contentContainer}>
          {items.value.length && !searchingTableItems.isLoading ? (
            <div className={styles.itemsContainer}>
              {!disabledRanges && (
                <>
                  <div>
                    <button
                      className={styles.itemContainer__searchFormulaButton}
                      onClick={() => {
                        modalSearchFormulaRef.current?.showModal();
                      }}
                    >
                      Buscar Fórmula
                    </button>
                  </div>
                  <span className="separator" />
                </>
              )}
              {!disabledRanges && itemsNotInStandard.length > 0 && (
                <div>
                  <Collapse
                    label={<p className={styles.collapseTitle}>Observações</p>}
                    key={undefined}
                    className={undefined}
                    classNameMainContainer={styles.collapseContainer}
                    classNameButton={styles.collapseButton}
                    iconColor={"var(--gray-6)"}
                    startOpened
                  >
                    <div className={styles.collapseContent}>
                      <p className={styles.collapseContent__infoParagraphy}>
                        Os seguintes itens apresentam faixas fora do padrão
                        escolhido:
                      </p>
                      <ul className={styles.collapseContent__itemList}>
                        {itemsNotInStandard.map((item, index) => (
                          <li
                            key={index}
                            className={styles.collapseContent__item}
                          >
                            <p className={styles.collapseContent__itemName}>
                              {item.formulado.descricaoFormulado}
                            </p>
                            <div>
                              <div className={`${styles.commissionBlock}`}>
                                <div className={styles.ball}>
                                  <div
                                    style={{
                                      backgroundColor: item.faixasVigencia.find(
                                        (range) =>
                                          range.faixasPrecoProdutoAdubo
                                            .escolhida
                                      )?.faixasPrecoProdutoAdubo.rgb,
                                    }}
                                  ></div>
                                  <span
                                    style={{
                                      backgroundColor: item.faixasVigencia.find(
                                        (range) =>
                                          range.faixasPrecoProdutoAdubo
                                            .escolhida
                                      )?.faixasPrecoProdutoAdubo.rgb,
                                    }}
                                  ></span>
                                  <p>
                                    {
                                      item.faixasVigencia.find(
                                        (range) =>
                                          range.faixasPrecoProdutoAdubo
                                            .escolhida
                                      )?.faixasPrecoProdutoAdubo.observacao
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Collapse>
                </div>
              )}
              <div className={styles.itemListContainer__selectByColorContainer}>
                <div>
                  <label htmlFor="defaultColor" className="label">
                    Cor Padrão
                  </label>
                  <Select
                    id="defaultColor"
                    placeholder="Selecione a cor"
                    value={defaultColor.value}
                    error={defaultColor.error}
                    onChange={defaultColor.onChange}
                    options={colorOptions}
                    isSearchable={false}
                    isDisabled={disabledRanges}
                  />
                </div>
                <div>
                  <label htmlFor="defaultLetter" className="label">
                    Letra Padrão
                  </label>
                  <Input
                    id="defaultLetter"
                    placeholder="Digite a letra"
                    maxLength={1}
                    value={defaultLetter.value}
                    error={defaultLetter.error}
                    onChange={(e) => {
                      if (!/^[A-Za-z]+$/.test(e.target.value)) return;
                      defaultLetter.setValue(e.target.value.toUpperCase());
                    }}
                    disabled={disabledRanges}
                    autoComplete="off"
                  />
                </div>
                {!disabledRanges && (
                  <Button
                    onClick={() => {
                      onSelectRangesByColorAndLetter(
                        defaultColor.value?.value,
                        defaultLetter.value
                      );
                    }}
                  >
                    Selecionar
                  </Button>
                )}
              </div>
              <div className={styles.selectAllContainer}>
                <label className="label">
                  <input
                    type="checkbox"
                    style={{ cursor: "pointer" }}
                    onChange={handleChangeSelectAll}
                    checked={isAllItemsSelected(items.value)}
                    disabled={disabledRanges}
                  />
                  <span>Selecionar Todos os Itens</span>
                </label>
              </div>
              <div className={styles.itemsContainer__itemList}>
                {items.value
                  .sort((a, b) =>
                    a.usarFormula === b.usarFormula ? 0 : a.usarFormula ? -1 : 1
                  )
                  .map((item, index) => (
                    <div
                      key={index}
                      className={styles.itemsContainer__itemList__item}
                      data-formula={item.formulado.descricaoFormulado}
                    >
                      <div>
                        <div
                          className={
                            styles.itemsContainer__itemList__item__title
                          }
                        >
                          <label className="label">
                            <input
                              type="checkbox"
                              style={{ cursor: "pointer" }}
                              onChange={(event) => {
                                handleChangeCheckItem(event, item);
                              }}
                              checked={item.usarFormula}
                              disabled={disabledRanges}
                            />
                            <h2
                              className={
                                styles.itemsContainer__itemList__item__title__header
                              }
                            >
                              {item.formulado.descricaoFormulado}
                            </h2>
                            <span />
                          </label>
                        </div>
                        <span className={styles.separator} />
                        <div
                          className={
                            styles.itemsContainer__itemList__item__info
                          }
                        >
                          {/* <div className={styles.itemsContainer__itemList__item__info__div}>
                        <h3 className={styles.itemsContainer__itemList__item__info__div__title}>Grupo</h3>
                        <span className={styles.itemsContainer__itemList__item__info__div____description}>
                          {item.formulado.grupoForm.descricaoGrupo}
                        </span>
                      </div> */}
                          <div
                            className={
                              styles.itemsContainer__itemList__item__info__div
                            }
                          >
                            <h3
                              className={
                                styles.itemsContainer__itemList__item__info__div__title
                              }
                            >
                              Preço de Venda (com juros)
                            </h3>
                            <span
                              className={
                                styles.itemsContainer__itemList__item__info__div__description
                              }
                            >
                              {calculatingInterest ? (
                                <Circle size={20} />
                              ) : item.faixasVigencia.find(
                                  (range) =>
                                    range.faixasPrecoProdutoAdubo.escolhida
                                ) ? (
                                formatMoney(
                                  (item.faixasVigencia.find(
                                    (range) =>
                                      range.faixasPrecoProdutoAdubo.escolhida
                                  )?.faixasPrecoProdutoAdubo.precoVenda || 0) /
                                    interestCalculated
                                )
                              ) : (
                                "---"
                              )}
                            </span>
                          </div>
                          <div
                            className={
                              styles.itemsContainer__itemList__item__info__div
                            }
                          >
                            <h3
                              className={
                                styles.itemsContainer__itemList__item__info__div__title
                              }
                            >
                              Preço de Custo
                            </h3>
                            <span
                              className={
                                styles.itemsContainer__itemList__item__info__div__description
                              }
                            >
                              {item.precoCusto
                                ? formatMoney(item.precoCusto)
                                : "---"}
                            </span>
                          </div>
                          <div
                            className={
                              styles.itemsContainer__itemList__item__info__div
                            }
                          >
                            <h3
                              className={
                                styles.itemsContainer__itemList__item__info__div__title
                              }
                            >
                              Margem
                            </h3>
                            <span
                              className={
                                styles.itemsContainer__itemList__item__info__div__description
                              }
                            >
                              {item.faixasVigencia.find(
                                (range) =>
                                  range.faixasPrecoProdutoAdubo.escolhida
                              )
                                ? `${String(
                                    new Decimal(
                                      item.faixasVigencia.find(
                                        (range) =>
                                          range.faixasPrecoProdutoAdubo
                                            .escolhida
                                      )?.faixasPrecoProdutoAdubo.perMinimo || 0
                                    ).toDecimalPlaces(2, Decimal.ROUND_DOWN)
                                  ).replace(".", ",")} %`
                                : "---"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <span className={styles.separator} />
                        {item.faixasVigencia?.length ? (
                          <div className={styles.commissionsContainer}>
                            {item.faixasVigencia.map(
                              (range, commissionIndex) => (
                                <div
                                  className={`${styles.commissionBlock} ${
                                    !range.faixasPrecoProdutoAdubo.escolhida
                                      ? styles["commissionBlock--notSelected"]
                                      : null
                                  }`}
                                  onClick={() => {
                                    if (disabledRanges) return;
                                    onSelectRange(item, range);
                                  }}
                                  key={commissionIndex}
                                >
                                  <div className={styles.ball}>
                                    <div
                                      style={{
                                        backgroundColor:
                                          range.faixasPrecoProdutoAdubo.rgb,
                                      }}
                                    ></div>
                                    <span
                                      style={{
                                        backgroundColor:
                                          range.faixasPrecoProdutoAdubo.rgb,
                                      }}
                                    ></span>
                                    <p>
                                      {range.faixasPrecoProdutoAdubo.observacao}
                                    </p>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          <p
                            className={
                              styles.itemListContainer__li__withoutRange
                            }
                          >
                            Vigência sem comissões cadastradas
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : searchingTableItems.isLoading ? (
            <div className={`loadingContainer ${styles.loadingContainer}`}>
              <Circle size={100} />
              <span className="loadingMessage">Buscando Dados da Tabela</span>
            </div>
          ) : (
            <p className={`lineCardMessage`}>Busque por uma tabela</p>
          )}
        </div>
      </div>
      <dialog
        className={`modal ${styles.modalSearchFormula}`}
        ref={modalSearchFormulaRef}
        onClose={() => {
          searchedFormula.reset();
        }}
      >
        <div className="modalContent">
          <div className={styles.modalSearchFormula_closeContainer}>
            <button
              onClick={() => {
                modalSearchFormulaRef.current?.close();
              }}
            >
              x
            </button>
          </div>
          <div className={styles.modalSearchFormula_input}>
            <label htmlFor="searchedFormula" className="label">
              Fórmula
            </label>
            <Input
              id="searchedFormula"
              placeholder="Digite a fórmula que deseja buscar"
              value={searchedFormula.value}
              error={searchedFormula.error}
              onChange={searchedFormula.onChange}
            />
          </div>
          <div>
            <Button
              type="button"
              className={styles.modalSearchFormula_searchButton}
              onClick={() => {
                if (isValid(searchedFormula)) {
                  scrollToFormula(searchedFormula.value);
                  modalSearchFormulaRef.current?.close();
                }
              }}
            >
              Buscar
            </Button>
          </div>
        </div>
      </dialog>
    </>
  );
}
