import React from "react";

import { Table, TBody, Td, Th, THead, Tr } from "../../../../components/Data/Table";
import { Button, LinkButton } from "../../../../components/Form";
import { Pencil } from "phosphor-react";

import styles from "./List.module.css";
import { Circle } from "../../../../components/Loading";
import { useNavigate } from "react-router-dom";
import { Paginate } from "../../../../components/Paginate/Paginate";

export interface IBank {
  bancoId: number;
  descricao: string;
  status: "A" | "I";
  usuarioAlteracaoId: number | null;
}

interface IProps {
  bankList: {
    value: IBank[];
    setValue: React.Dispatch<IBank[]>;
  };
  searchBanks: (page: number) => void;
  searchTotalBankRecordsAndBanks: {
    search: () => void;
    isSearching: boolean;
  };
  pagination: IUsePagination;
  setSelectedBank: React.Dispatch<IBank | null>;
}

export function List({ bankList, searchBanks, searchTotalBankRecordsAndBanks, pagination, setSelectedBank }: IProps) {
  const navigate = useNavigate();

  React.useEffect(() => {
    setSelectedBank(null);
    if (!bankList.value.length) {
      searchTotalBankRecordsAndBanks.search();
    }
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.navigationContainer}>
        <LinkButton to={"novo"}>Novo Banco</LinkButton>
      </div>
      <div className={styles.mainContainer}>
        {bankList.value.length && !searchTotalBankRecordsAndBanks.isSearching ? (
          <div className={`${styles.tableContainer}`}>
            <Table title="Bancos Cadastrados" className={styles.table}>
              <THead>
                <Tr>
                  <Th width=".625rem">ID</Th>
                  <Th width={undefined}>Descrição</Th>
                  <Th children={undefined} width=".625rem" />
                </Tr>
              </THead>
              <TBody>
                {bankList.value.map((item) => (
                  <Tr key={item.bancoId}>
                    <Td heading="ID">{item.bancoId}</Td>
                    <Td heading="Descrição">{item.descricao}</Td>
                    <Td heading="Editar" data-option>
                      <Button
                        type="button"
                        variant="edit"
                        className={styles.editBankButton}
                        onClick={() => {
                          setSelectedBank(item);
                          navigate("atualizar");
                        }}
                        data-option-button>
                        <Pencil weight="fill" />
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </TBody>
            </Table>
          </div>
        ) : searchTotalBankRecordsAndBanks.isSearching ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhum banco encontrado</p>
        )}
        <Paginate
          classNameContainer={styles.paginateContainer}
          maxItems={pagination.maxItems}
          totalRecords={pagination.totalRecords}
          currentPage={pagination.currentPage}
          setCurrentPage={pagination.setCurrentPage}
          onPageChange={(page) => searchBanks(page)}
        />
      </div>
    </div>
  );
}
