import React from "react";

import { Button, Input, Select } from "../../../../components/Form";
import { useCustomFetch, useLoading, useDebounce } from "../../../../hooks/async";
import { useDate, useSelect } from "../../../../hooks/form";
import { useModal } from "../../../../hooks/contexts";

import { downloadResponseFile } from "../../../../helpers/responses";

import { Circle } from "../../../../components/Loading";

import styles from "./ReportsCommissionsAnalytical.module.css";

export function ReportsCommissionsAnalytical() {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const searchingSellerOptions = useLoading();
  const generatingReport = useLoading();

  const sellers = useSelect({ type: "multiple", required: true, emptyMessage: "Selecione ao menos um vendedor" });
  const initialDate = useDate({ type: "", required: true, emptyMessage: "Defina uma data inicial" });
  const finalDate = useDate({ type: "", required: true, emptyMessage: "Defina uma data final" });

  const [sellerOptions, setSellerOptions] = React.useState([]);

  const searchSellers = React.useCallback(
    async (value) => {
      try {
        searchingSellerOptions.setLoading(true);
        const json = await customFetch("/reports/searchSellers", {
          body: {
            descricao: value || "",
          },
        });
        if (json.status === 200) {
          let options;
          if (sellers.value.length) {
            options = json.object
              .filter((item) => !sellers.value.some((i) => i.value.idVendedor === item.idVendedor))
              .map((option) => {
                return { value: { ...option }, label: `${option.idVendedor} | ${option.nomeVendedor}` };
              });
          } else {
            options = json.object.map((option) => {
              return { value: { ...option }, label: `${option.idVendedor} | ${option.nomeVendedor}` };
            });
          }

          setSellerOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingSellerOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingSellerOptions, sellers]
  );

  const handleSearchSellers = useDebounce(searchSellers);

  const handleChangeSellers = React.useCallback(
    (value) => {
      sellers.setValue((old) => {
        if (value.length < old.length) {
          const removedValue = old.filter((obj1) => {
            return !value.some((obj2) => {
              return obj1.value.idVendedor === obj2.value.idVendedor;
            });
          })[0];
          setSellerOptions((oldOptions) => {
            return [removedValue, ...oldOptions.filter((i) => i.value.idVendedor !== removedValue.value.idVendedor)];
          });
        }
        return value;
      });
    },
    [sellers]
  );

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      try {
        generatingReport.setLoading(true);
        const response = await customFetch("/reports/generateCommissionAnalyticalReport/pdf", {
          body: {
            idUsuarios: sellers.value.map((item) => {
              return Number(item.value.idVendedor);
            }),
            dataInicio: initialDate.toISOString(),
            dataFim: finalDate.toISOStringNextDay(),
          },
        });

        if (response.status === 200) {
          downloadResponseFile({
            file: response.object,
            type: "application/pdf",
            fileName: "relatorio-comissao-analitico.pdf",
          });
        } else if (response.status === 500) {
          Modal.error(response.message, response.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        generatingReport.setLoading(false);
      }
    },
    [generatingReport, customFetch, sellers, initialDate, finalDate, Modal]
  );

  React.useEffect(() => {
    searchSellers();
  }, []); //eslint-disable-line

  return (
    <div className="container">
      {!generatingReport.isLoading ? (
        <form onSubmit={handleSubmit} className={styles.form}>
          <div>
            <label htmlFor="sellers" className="label">
              Filtrar Vendedores
            </label>
            <Select
              id="sellers"
              placeholder="Pesquise por vendedores..."
              options={sellerOptions}
              value={sellers.value}
              onChange={handleChangeSellers}
              onInputChange={(value) => handleSearchSellers(value)}
              onBlur={sellers.onBlur}
              error={sellers.error}
              noOptionsMessage={() => "Nenhum vendedor encontrado"}
              loadingMessage={() => "Buscando..."}
              isLoading={searchingSellerOptions.isLoading}
              isMulti
              cacheOptions={false}
            />
          </div>
          <div>
            <label htmlFor="initialDate" className="label">
              Data Inicial
            </label>
            <Input
              id="initialDate"
              type="date"
              value={initialDate.value}
              error={initialDate.error}
              onChange={initialDate.onChange}
              onBlur={initialDate.onBlur}
            />
          </div>
          <div>
            <label htmlFor="finalDate" className="label">
              Data Final
            </label>
            <Input
              id="finalDate"
              type="date"
              value={finalDate.value}
              error={finalDate.error}
              onChange={finalDate.onChange}
              onBlur={finalDate.onBlur}
            />
          </div>
          <div className={styles.submitButtonContainer}>
            <Button>Gerar Relatório</Button>
          </div>
        </form>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Gerando Relatório</span>
        </div>
      )}
    </div>
  );
}
