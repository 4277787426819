import React from "react";

import { useCustomFetch, useDebounce } from "../../../hooks/async";
import { useModal } from "../../../hooks/contexts";
// import { useDate, useForm, useSelect } from "../../../hooks/form";
import { useDate, useSelect } from "../../../hooks/form";
import { usePagination } from "../../../hooks/pagination";

import { Select } from "../../../components/Form/Select/Select";
import { Input } from "../../../components/Form";
import { Button } from "../../../components/Form";
import { Table, THead, TBody, Tr, Th, Td } from "../../../components/Data/Table";
import { Paginate } from "../../../components/Paginate/Paginate";

import { Circle } from "../../../components/Loading/Circle/Circle";

import { isValid } from "../../../helpers/validations";

import styles from "./Logs.module.css";
import { formatDate } from "../../../helpers/format";

export function Logs() {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const pagination = usePagination();

  // const [isSearchingSystemOptions, setSearchingSystemOptions] = React.useState(false);
  const [isSearchingOptionsUser, setIsSearchingOptionsUser] = React.useState(false);
  const [optionsUser, setOptionsUser] = React.useState([]);
  const [isSearchingOptionsArea, setIsSearchingOptionsArea] = React.useState(false);
  const [optionsArea, setOptionsArea] = React.useState([]);
  const [isSearchingLogs, setIsSearchingLogs] = React.useState(false);
  const [logs, setLogs] = React.useState([]);

  const user = useSelect({ type: "single", required: true, emptyMessage: "Selecione um usuário" });

  // const logDescription = useForm({ type: "", required: false });
  // const system = useSelect({ type: "single", required: false });
  const area = useSelect({ type: "single", required: false });
  const initialDate = useDate({ type: "", required: true, emptyMessage: "Defina uma data inicial" });
  const finalDate = useDate({ type: "", required: true, emptyMessage: "Defina uma data final" });

  const clearLogs = React.useCallback(() => {
    setLogs([]);
    pagination.reset();
  }, [pagination]);

  const formatLogAction = React.useCallback((action) => {
    const formatedAction = action.replace(/^(?:.+\s:\s)(.+)$/g, "$1");
    return formatedAction;
  }, []);

  const searchUsers = useDebounce(async (value) => {
    try {
      setIsSearchingOptionsUser(true);
      const json = await customFetch("/admin/searchUsers", {
        body: {
          pesquisa: value || "%%%%",
        },
      });
      if (json.status === 200) {
        setOptionsUser(json.object);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      setIsSearchingOptionsUser(false);
    }
  });

  const SearchLogAreas = React.useCallback(async () => {
    try {
      setIsSearchingOptionsArea(true);
      const json = await customFetch("/admin/searchLogAreas", {
        body: {
          pesquisa: "",
        },
      });
      if (json.status === 200) {
        setOptionsArea(json.object);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      setIsSearchingOptionsArea(false);
    }
  }, [Modal, customFetch]);

  const searchTotalLogRecords = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const json = await customFetch("/admin/searchTotalLogRecords", {
          body: {
            idPessoa: Number(user.value.value.idUsuario),
            dataInicial: initialDate.toISOString(),
            dataFinal: finalDate.toISOStringNextDay(),
            area: area.value ? Number(area.value.value.idLogArea) : 0,
            tamanho: pagination.maxItems,
          },
        });
        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
  }, [area, customFetch, finalDate, initialDate, user, pagination]);

  const searchLogs = React.useCallback(
    (page) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/admin/searchLogs", {
            body: {
              idPessoa: Number(user.value.value.idUsuario),
              dataInicial: initialDate.toISOString(),
              dataFinal: finalDate.toISOStringNextDay(),
              area: area.value ? Number(area.value.value.idLogArea) : 0,
              pagina: page ? Number(page) - 1 : 0,
              tamanho: pagination.maxItems,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [area, customFetch, finalDate, initialDate, user, pagination]
  );

  const handleChangeUser = React.useCallback(
    async (target) => {
      if (logs.length) {
        const confirm = await Modal.confirm(
          "Ao alterar o usuário, os logs deverão ser consultados novamente.<br>Deseja Continuar?"
        );
        if (confirm) {
          clearLogs();
          user.setValue(target);
        }
      } else {
        user.setValue(target);
      }
    },
    [Modal, clearLogs, logs.length, user]
  );

  const handleChangeArea = React.useCallback(
    async (target) => {
      if (logs.length) {
        const confirm = await Modal.confirm(
          "Ao alterar a área, os logs deverão ser consultados novamente.<br>Deseja Continuar?"
        );
        if (confirm) {
          clearLogs();
          area.setValue(target);
        }
      } else {
        area.setValue(target);
      }
    },
    [Modal, area, clearLogs, logs.length]
  );

  const handleChangeInitialDate = React.useCallback(
    async ({ target }) => {
      const newValue = target.value;
      if (logs.length) {
        const confirm = await Modal.confirm(
          "Ao alterar a data inicial, os logs deverão ser consultados novamente.<br>Deseja Continuar?"
        );
        if (confirm) {
          clearLogs();
          initialDate.setValue(newValue);
        }
      } else {
        initialDate.setValue(newValue);
      }
    },
    [Modal, clearLogs, initialDate, logs.length]
  );

  const handleChangeFinalDate = React.useCallback(
    async ({ target }) => {
      const newValue = target.value;
      if (logs.length) {
        const confirm = await Modal.confirm(
          "Ao alterar a data final, os logs deverão ser consultados novamente.<br>Deseja Continuar?"
        );
        if (confirm) {
          clearLogs();
          finalDate.setValue(newValue);
        }
      } else {
        finalDate.setValue(newValue);
      }
    },
    [Modal, clearLogs, finalDate, logs.length]
  );

  const onPageChange = React.useCallback(
    async (page) => {
      try {
        setIsSearchingLogs(true);
        const jsonLogs = await searchLogs(page);
        if (jsonLogs.status === 200) {
          setLogs(jsonLogs.object);
        } else if (jsonLogs === 500) {
          Modal.error(jsonLogs.message, jsonLogs.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        setIsSearchingLogs(false);
      }
    },
    [Modal, searchLogs]
  );

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (isValid(user, initialDate, finalDate)) {
        try {
          setIsSearchingLogs(true);
          if (logs.length) {
          } else {
            const jsonLogs = await searchLogs();
            if (jsonLogs.status === 200) {
              const jsonRecords = await searchTotalLogRecords();
              if (jsonRecords.status === 200) {
                setLogs(jsonLogs.object);
                pagination.setTotalRecords(jsonRecords.object.total);
              } else if (jsonRecords.status === 500) {
                Modal.error(jsonRecords.message, jsonRecords.object);
              }
            } else if (jsonLogs.status === 500) {
              Modal.error(jsonLogs.message, jsonLogs.object);
            }
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          setIsSearchingLogs(false);
        }
      }
    },
    [Modal, finalDate, initialDate, logs, pagination, searchLogs, searchTotalLogRecords, user]
  );

  React.useEffect(() => {
    SearchLogAreas();
  }, []); // eslint-disable-line

  return (
    <div className={`container ${styles.container}`}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.userContainer}>
          <label className={`label`}>Usuário</label>
          <Select
            placeholder="Pesquise um usuário..."
            options={optionsUser.map((option) => {
              return { value: { ...option }, label: `${option.idUsuario} | ${option.nome}` };
            })}
            value={user.value}
            onChange={handleChangeUser}
            onInputChange={(value) => searchUsers(value)}
            onBlur={user.onBlur}
            error={user.error}
            noOptionsMessage={() => "Nenhum usuário encontrado"}
            loadingMessage={() => "Buscando..."}
            isLoading={isSearchingOptionsUser}
            isDisabled={isSearchingLogs}
          />
        </div>
        {/* <div className={styles.systemContainer}>
          <div>
            <label htmlFor="logDescription" className="label">
              Descrição do Log
            </label>
            <Input
              type="text"
              placeholder="Digite a descrição do log"
              id="logDescription"
              value={logDescription.value}
              onChange={logDescription.onChange}
              disabled={isSearchingLogs}
            />
          </div>
          <div>
            <label htmlFor="trackedSystem" className="label">
              Sistema Trackeado
            </label>
            <Select
              placeholder="Selecione o que deseja buscar os logs"
              id="trackedSystem"
              options={[]}
              value={system.value}
              onChange={system.onChange}
              isLoading={isSearchingSystemOptions}
              isDisabled={isSearchingLogs}
            />
          </div>
        </div> */}
        <div className={styles.dataContainer}>
          <div>
            <label className={`label`}>Área do Sistema (Opcional)</label>
            <Select
              placeholder="Selecione uma área do sistema"
              options={optionsArea.map((option) => {
                return { value: { ...option }, label: option.area };
              })}
              value={area.value}
              onChange={handleChangeArea}
              noOptionsMessage={() => "Nenhuma área encontrada"}
              loadingMessage={() => "Buscando..."}
              isLoading={isSearchingOptionsArea}
              isDisabled={isSearchingLogs}
            />
          </div>
          <fieldset className={styles.dateFields}>
            <div>
              <label className={`label`}>Data Inicial</label>
              <Input
                type="date"
                value={initialDate.value}
                error={initialDate.error}
                onChange={handleChangeInitialDate}
                onBlur={initialDate.onBlur}
                disabled={isSearchingLogs}
              />
            </div>
            <div>
              <label className={`label`}>Data Final</label>
              <Input
                type="date"
                value={finalDate.value}
                error={finalDate.error}
                onChange={handleChangeFinalDate}
                onBlur={finalDate.onBlur}
                disabled={isSearchingLogs}
              />
            </div>
          </fieldset>
        </div>
        <div className={styles.buttonContainer}>
          <Button disabled={isSearchingLogs || logs.length}>
            {isSearchingLogs ? "Consultando Logs" : "Consultar Logs"}
          </Button>
        </div>
      </form>
      <section className={styles.logsSection}>
        {logs.length && !isSearchingLogs ? (
          <Table
            containerClassName={styles.TableLogsContainer}
            title={`Logs de Usuário<br>${user.value.value.nome}`}
            responsiveType="HeaderOnLeft">
            <THead>
              <Tr>
                <Th>Área</Th>
                <Th>Ação</Th>
                <Th>Data</Th>
              </Tr>
            </THead>
            <TBody>
              {logs.map((log, index) => {
                return (
                  <Tr key={index}>
                    <Td heading="Área">{log.logarea.area}</Td>
                    <Td heading="Ação">{formatLogAction(log.log.log)}</Td>
                    <Td heading="Data">{formatDate(log.log.data, "dd/MM/yy às hh:mm:ss")}</Td>
                  </Tr>
                );
              })}
            </TBody>
          </Table>
        ) : isSearchingLogs ? (
          <div className={styles.searchingLogsLoadingContainer}>
            <Circle size={100} />
          </div>
        ) : null}
        {logs.length ? (
          <Paginate
            maxItems={pagination.maxItems}
            totalRecords={pagination.totalRecords}
            currentPage={pagination.currentPage}
            setCurrentPage={pagination.setCurrentPage}
            onPageChange={onPageChange}
            isDisabled={isSearchingLogs}
          />
        ) : null}
      </section>
    </div>
  );
}
