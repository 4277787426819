import React from "react";

import { IContractSellers, IContractSupervisors } from "../../types";
import { IUseSelectOptions } from "../../components/hooks/types";
import { Select } from "../../../../components/Form";
import { useDebounce } from "../../../../hooks/async";
import { PageType } from "../types";
import { useAuth, useModal } from "../../../../hooks/contexts";

import styles from "./ContractSupervisors.module.css";

interface Props {
  data: IContractSupervisors;
  sellers?: {
    data: IContractSellers;
    options: IUseSelectOptions["sellerOptions"];
  };
  supervisorOptions?: IUseSelectOptions["supervisorOptions"];
  page: PageType;
  permissions?: string[];
}

const defaultSupervisorOptions: IUseSelectOptions["supervisorOptions"] = {
  options: [],
  search: async () => {},
  loading: false,
};

const isDisabled = (page: PageType, permissions: string[]) => {
  switch (page) {
    case PageType.GERENTE:
      return false;
    case PageType.COMERCIAL:
      return !permissions.includes("CONTRATO_SOLICITACAO_ALTERA_SUPERVISOR");
    default:
      return true;
  }
};

export function ContractSupervisors({
  data,
  sellers,
  supervisorOptions = defaultSupervisorOptions,
  page,
  permissions = [],
}: Props) {
  const Auth = useAuth();
  const Modal = useModal();

  const { supervisors } = data;

  const searchSupervisorOptionsDebounced = useDebounce(
    supervisorOptions.search
  );

  const handleRemoveSupervisor = (supervisorId: number) => {
    supervisors.setValue((old) =>
      old.filter((i) => i.value.id !== supervisorId)
    );
  };

  React.useEffect(() => {
    if (!supervisors.value.length) {
      const currentSupervisor = supervisorOptions.options.find((i) =>
        i.value.descricao.includes(Auth.user)
      );
      if (currentSupervisor) {
        supervisors.setValue([currentSupervisor]);
      }
    }
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (sellers) {
      if (supervisors.value.length) {
        sellers.options.searchDebounced(
          "",
          supervisors.value.map((i: any) => i.value.id)
        );
      }
    }
  }, [supervisors.value]); // eslint-disable-line

  return (
    <div>
      <div className={styles.fieldsContainer}>
        {!isDisabled(page, permissions) && (
          <>
            <div>
              <label htmlFor="supervisors" className="label">
                Gerentes
              </label>
              <Select
                placeholder="Selecione um gerente para adicionar"
                options={supervisorOptions.options.filter((i) =>
                  supervisors.value && supervisors.value.length
                    ? !supervisors.value.some((j) => j.value.id === i.value.id)
                    : true
                )}
                value={supervisors.value}
                error={supervisors.error}
                onChange={async (value) => {
                  if (sellers && sellers.data.sellers.value.length) {
                    const confirm = await Modal.confirm(
                      "Ao alterar o gerente, os vendedores serão removidos. <br /> Deseja continuar?"
                    );
                    if (confirm) {
                      if (sellers) {
                        sellers.data.sellers.reset();
                      }
                      supervisors.onChange(value);
                    }
                  } else {
                    supervisors.onChange(value);
                  }
                }}
                onInputChange={(value) =>
                  searchSupervisorOptionsDebounced(value)
                }
                isMulti
                isLoading={supervisorOptions.loading}
              />
            </div>
          </>
        )}
      </div>
      <div
        className={styles.listContainer}
        style={{ marginTop: isDisabled(page, permissions) ? "0px" : "" }}
      >
        {supervisors.value && supervisors.value.length ? (
          <ul
            className={styles.supervisorListContainer}
            style={{ height: page !== PageType.GERENTE ? "fit-content" : "" }}
          >
            {supervisors.value.map((supervisor, index) => (
              <li
                key={index}
                style={{
                  backgroundColor: isDisabled(page, permissions)
                    ? "var(--gray-10)"
                    : "",
                }}
              >
                <span>{supervisor.label}</span>
                {!isDisabled(page, permissions) && (
                  <span>
                    <button
                      className={styles.removeSupervisorButton}
                      onClick={() =>
                        handleRemoveSupervisor(supervisor.value.id)
                      }
                    >
                      X
                    </button>
                  </span>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p className={`lineCardMessage`}>
            Nenhum gerente adicionado ao contrato
          </p>
        )}
      </div>
    </div>
  );
}
