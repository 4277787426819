import { useSelect } from "../../../../hooks/form";
import { IContractSupervisors } from "../../types";

export function useContractSupervisors(): IContractSupervisors {
  const supervisors = useSelect({ type: "multiple", required: true });

  const validate = () => {
    return supervisors.validate();
  };

  const reset = () => {
    supervisors.reset();
  };

  return {
    supervisors,
    validate,
    reset,
  };
}
