import React from "react";

import { Button, Input, LinkButton, Select } from "../../../../components/Form";

import { useForm, useSelect } from "../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useChanges, useModal } from "../../../../hooks/contexts";

import { isValid } from "../../../../helpers/validations";

import { Circle } from "../../../../components/Loading";

import styles from "./NewMicro.module.css";

export function NewMicro({ searchTotalMicroRecordsAndMicros, enterpriseOptions }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const { setIsChangesDetected } = useChanges();
  const registeringMicro = useLoading();

  const micro = useForm({ type: "", required: true });
  const microSymbol = useForm({ type: "", required: true });
  const enterprise = useSelect({ type: "single", required: true, emptyMessage: "Selecione uma empresa" });
  const defaultValue = useForm({ type: "number", required: true });
  const maxDiscount = useForm({ type: "number", numberRange: { min: 0, max: 100 }, required: true });

  const clearForm = React.useCallback(() => {
    micro.setValue("");
    micro.setError(null);
    microSymbol.setValue("");
    microSymbol.setError(null);
    enterprise.setValue("");
    enterprise.setError(null);
    defaultValue.setValue("");
    defaultValue.setError(null);
    maxDiscount.setValue("");
    maxDiscount.setError(null);
    setIsChangesDetected(false);
  }, [micro, microSymbol, enterprise, defaultValue, maxDiscount, setIsChangesDetected]);

  const registerMicro = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        registeringMicro.setLoading(true);
        const json = await customFetch("/products/registerAndUpdateMicro", {
          body: {
            descricao: micro.value,
            simbolo: microSymbol.value,
            valorPadrao: Number(defaultValue.value),
            descontoMaximo: Number(maxDiscount.value),
            idEmpresa: enterprise.value.value.idEmpresa,
            ativo: true,
          },
        });
        resolve(json);
      } catch (error) {
        reject(error);
      } finally {
        registeringMicro.setLoading(false);
      }
    });
  }, [customFetch, defaultValue, enterprise, maxDiscount, micro, microSymbol, registeringMicro]);

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (isValid(micro, microSymbol, enterprise, defaultValue, maxDiscount)) {
        try {
          const json = await registerMicro();
          if (json.status === 200) {
            await Modal.success(json.message);
            clearForm();
            searchTotalMicroRecordsAndMicros();
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        }
      }
    },
    [
      micro,
      microSymbol,
      enterprise,
      defaultValue,
      maxDiscount,
      registerMicro,
      Modal,
      clearForm,
      searchTotalMicroRecordsAndMicros,
    ]
  );

  return (
    <div className={`container ${styles.container}`}>
      {!registeringMicro.isLoading ? (
        <>
          <div>
            <LinkButton to="/produtos/micros" buttonStyle="backButton" />
          </div>
          <div>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className={styles.fieldsContainer}>
                <div className={styles.fieldsGroup}>
                  <div>
                    <label htmlFor="micro" className="label">
                      Descrição
                    </label>
                    <Input
                      id="micro"
                      placeholder="Digite o nome do micro"
                      value={micro.value}
                      error={micro.error}
                      onChange={(e) => {
                        micro.onChange(e);
                        setIsChangesDetected(true);
                      }}
                      onBlur={micro.onBlur}
                    />
                  </div>
                  <div>
                    <label htmlFor="microSymbol" className="label">
                      Símbolo
                    </label>
                    <Input
                      id="microSymbol"
                      type="text"
                      placeholder="Digite o símbolo do micro"
                      value={microSymbol.value}
                      error={microSymbol.error}
                      onChange={(e) => {
                        microSymbol.onChange(e);
                        setIsChangesDetected(true);
                      }}
                      onBlur={microSymbol.onBlur}
                    />
                  </div>
                </div>
                <div className={styles.fieldsGroup}>
                  <div>
                    <label htmlFor="defaultValue" className="label">
                      Valor Padrão
                    </label>
                    <Input
                      id="defaultValue"
                      type="number"
                      className={`cleanInputNumber`}
                      placeholder="Digite o valor padrão do micro"
                      value={defaultValue.value}
                      error={defaultValue.error}
                      onChange={(e) => {
                        defaultValue.onChange(e);
                        setIsChangesDetected(true);
                      }}
                      onBlur={defaultValue.onBlur}
                    />
                  </div>
                  <div>
                    <label htmlFor="maxDiscount" className="label">
                      Desconto Máximo (%)
                    </label>
                    <Input
                      id="maxDiscount"
                      type="number"
                      className={`cleanInputNumber`}
                      placeholder="Digite a porcentagem máxima de desconto"
                      value={maxDiscount.value}
                      error={maxDiscount.error}
                      onChange={(e) => {
                        maxDiscount.onChange(e);
                        setIsChangesDetected(true);
                      }}
                      onBlur={maxDiscount.onBlur}
                    />
                  </div>
                  <div>
                    <label htmlFor="enterprise" className="label">
                      Empresa
                    </label>
                    <Select
                      id="enterprise"
                      placeholder="Selecione uma empresa"
                      options={enterpriseOptions}
                      value={enterprise.value}
                      error={enterprise.error}
                      onChange={(value) => {
                        enterprise.onChange(value);
                        setIsChangesDetected(true);
                      }}
                      onBlur={enterprise.onBlur}
                      isSearchable={false}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.submitButtonContainer}>
                <Button>Cadastrar Micro</Button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Cadastrando novo micro</span>
        </div>
      )}
    </div>
  );
}
