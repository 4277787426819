import React, { useMemo } from "react";

import { useForm, useSelect } from "../../../../hooks/form";
import { IContractTable, ITableItem } from "../../types";
import { isValid } from "../../../../helpers/validations";
import { useModal } from "../../../../hooks/contexts";

export function useContractTable(): IContractTable {
  const Modal = useModal();

  const [items, setItems] = React.useState<ITableItem[]>([]);

  const defaultColor = useSelect({ type: "single", required: true });
  const defaultLetter = useForm({ required: true });
  const qtdMarcar = useMemo(() => {
    return items.length > 5 ? 5 : items.length;
  }, [items]); //Quantidade de fórmula que será preciso marcar para gerar o preço médio

  const validate = () => {
    const hasSelectedFormulas = items.some((item) => item.controll.selected);
    const hasFiveSelectedToAveragePrice =
      items.filter((item) => item.controll.selectedToAveragePrice).length ===
      qtdMarcar;
    const hasOneRangeForEachSelectedFormula = items.every((item) => {
      if (!item.controll.selected) return true;
      else return item.faixaPreco.some((range) => range.escolhida);
    });
    if (!hasSelectedFormulas) {
      Modal.error("Selecione pelo menos uma fórmula para a tabela");
      return false;
    } else if (!hasFiveSelectedToAveragePrice) {
      Modal.error(
        "Selecione " + qtdMarcar + " fórmulas para o cálculo do preço médio"
      );
      return false;
    } else if (!hasOneRangeForEachSelectedFormula) {
      Modal.error("Selecione uma faixa de preço para cada fórmula selecionada");
      return false;
    }
    return isValid(defaultColor, defaultLetter);
  };

  const reset = () => {
    setItems([]);
    defaultColor.reset();
    defaultLetter.reset();
  };

  return {
    items: { value: items, setValue: setItems },
    defaultColor,
    defaultLetter,
    qtdMarcar,
    validate,
    reset,
  };
}
