import React, { useRef } from "react";

import { ISolicitation, IUseSolicitationList } from "../hooks/types";
import {
  Button,
  Input,
  InputMultiple,
  Select,
} from "../../../../components/Form";
import { useNavigate } from "react-router-dom";
import { Eye, FileText } from "phosphor-react";
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr,
} from "../../../../components/Data/Table";
import { Circle } from "../../../../components/Loading";
import { Paginate } from "../../../../components/Paginate/Paginate";

import styles from "./List.module.css";
import { ISeller, ISupervisor } from "../../types";
import { isValid } from "../../../../helpers/validations";
import {
  ExcelPermissions,
  ExcelType,
  UseContractExcel,
} from "../../hooks/useContractExcel";

interface Props {
  solicitationList: IUseSolicitationList;
  selectedSolicitation: React.MutableRefObject<ISolicitation | null>;
  contractExcel: UseContractExcel;
}

let temporarySelectedSolicitationToExportExcel: number | null = null;

export function List({
  solicitationList: {
    pagination,
    filter: {
      description,
      solicitationIds,
      status,
      initialDate,
      finalDate,
      sellers,
      supervisors,
      statusOptions,
      sellerOptions,
      supervisorOptions,
    },
    searchSolicitations,
    searchTotalSolicitationRecordsAndSolicitations,
    searchTotalSolicitationRecordsAndSolicitationsDebounced,
    searchingSolicitations,
    solicitationList: [solicitationList],
  },
  selectedSolicitation,
  contractExcel,
}: Props) {
  const navigate = useNavigate();

  const modalExcelType = useRef<HTMLDialogElement>(null);

  React.useEffect(() => {
    selectedSolicitation.current = null;
  }, []); // eslint-disable-line

  return (
    <>
      <div className="container">
        <div className={styles.filtersContainer}>
          <div className={styles.filtersContainer__description}>
            <label htmlFor="description" className="label">
              Descrição
            </label>
            <Input
              id="description"
              placeholder="Digite a descrição"
              value={description.value}
              onChange={(e) => {
                description.onChange(e);
              }}
              autoComplete="off"
            />
          </div>
          <div className={styles.filtersContainer__solicitationIds}>
            <label htmlFor="solicitationIds" className="label">
              Solicitações (Ids)
            </label>
            <InputMultiple
              id="solicitationIds"
              placeholder="Adicione os ids das solicitações que deseja buscar"
              value={solicitationIds}
              validate={(value) => /^\d+$/.test(value)}
            />
          </div>
          <div className={styles.filtersContainer__sellers}>
            <label htmlFor="sellers" className="label">
              Vendedores
            </label>
            <Select
              id="sellers"
              placeholder="Selecione os vendedores que deseja filtrar"
              options={sellerOptions.options}
              value={sellers.value}
              onChange={(value: ISelectOption<ISeller>[]) => {
                sellers.onChange(value);
              }}
              onInputChange={(value) => {
                sellerOptions.searchDebounced(value, []);
              }}
              isMulti
              isLoading={sellerOptions.loading}
            />
          </div>
          <div className={styles.filtersContainer__supervisors}>
            <label htmlFor="supervisors" className="label">
              Supervisores
            </label>
            <Select
              id="supervisors"
              placeholder="Seleciones os supervisores que deseja filtrar"
              options={supervisorOptions.options}
              value={supervisors.value}
              onChange={(value: ISelectOption<ISupervisor>[]) => {
                supervisors.onChange(value);
              }}
              isMulti
              isLoading={supervisorOptions.loading}
            />
          </div>
          <div className={styles.filtersContainer__initialDate}>
            <label htmlFor="initialDate" className="label">
              Data Inicial
            </label>
            <Input
              id="initialDate"
              type="date"
              value={initialDate.value}
              onChange={(value) => {
                initialDate.onChange(value);
              }}
              error={initialDate.error}
            />
          </div>
          <div className={styles.filtersContainer__finalDate}>
            <label htmlFor="finalDate" className="label">
              Data Final
            </label>
            <Input
              id="finalDate"
              type="date"
              value={finalDate.value}
              onChange={(value) => {
                finalDate.onChange(value);
              }}
              error={finalDate.error}
            />
          </div>
          <div className={styles.filtersContainer__status}>
            <label htmlFor="status" className="label">
              Status
            </label>
            <Select
              id="status"
              options={statusOptions}
              value={status.value}
              onChange={(value) => {
                status.onChange(value);
              }}
              isClearable={false}
              isSearchable={false}
            />
          </div>
          <div className={styles.filtersContainer__buttonSearch}>
            <Button
              onClick={() => {
                if (
                  isValid(
                    description,
                    solicitationIds,
                    status,
                    initialDate,
                    finalDate,
                    sellers,
                    supervisors
                  )
                )
                  searchTotalSolicitationRecordsAndSolicitationsDebounced(
                    description.value,
                    status.value?.value,
                    initialDate.toISOString(),
                    finalDate.toISOStringNextDay(),
                    sellers.value.map((item) => item.value.id),
                    supervisors.value.map((item) => item.value.id),
                    solicitationIds.value.map((item) => Number(item.value))
                  );
              }}
              style={{ width: "100%" }}
            >
              Buscar Solicitações
            </Button>
          </div>
        </div>
        <span className="separator" />
        {!contractExcel.exportingExcel ? (
          <>
            {solicitationList.length && !searchingSolicitations ? (
              <>
                <Table title={"Solicitações"} className={styles.table}>
                  <THead>
                    <Tr>
                      <Th width={".625rem"}>ID Solicitação</Th>
                      <Th width={""}>Descrição Contrato</Th>
                      <Th width={"15.625rem"}>Status Atual</Th>
                      <Th width={"6.25rem"}>Nº Contrato</Th>
                      <Th width={""} children={undefined} />
                      <Th width={""} children={undefined} />
                    </Tr>
                  </THead>
                  <TBody>
                    {solicitationList.map((solicitation, index) => (
                      <Tr key={index}>
                        <Td heading={"ID Solicitação"}>
                          {solicitation.idSolicitacao}
                        </Td>
                        <Td heading={"Descrição Contrato"}>
                          {solicitation.descricaoContrato}
                        </Td>
                        <Td
                          heading={"Status Atual"}
                          style={{ textAlign: "center" }}
                        >
                          {solicitation.nomeStatus}
                        </Td>
                        <Td
                          heading={"Nº Contrato"}
                          style={{ textAlign: "center" }}
                        >
                          {solicitation.contrato?.idContrato || "Desconhecido"}
                        </Td>
                        <Td
                          heading="Exportar Excel"
                          width="6.25rem"
                          data-option
                        >
                          <Button
                            type="button"
                            className={styles.seeRequestButton}
                            onClick={() => {
                              const permissionsAllowed =
                                contractExcel.getExcelPermissions();

                              if (permissionsAllowed.permissions.length > 1) {
                                temporarySelectedSolicitationToExportExcel =
                                  solicitation.idSolicitacao;
                                modalExcelType.current?.show();
                              } else if (
                                permissionsAllowed.permissions.length === 1
                              ) {
                                contractExcel.exportExcel(
                                  solicitation.idSolicitacao,
                                  permissionsAllowed.type!!
                                );
                              }
                            }}
                            data-option-button
                          >
                            <FileText weight="fill" />
                          </Button>
                        </Td>
                        <Td
                          heading="Ver Solicitação"
                          width="6.25rem"
                          data-option
                        >
                          <Button
                            type="button"
                            variant="edit"
                            className={styles.seeRequestButton}
                            onClick={() => {
                              selectedSolicitation.current = solicitation;
                              navigate("visualizar");
                            }}
                            data-option-button
                          >
                            <Eye weight="fill" />
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </TBody>
                </Table>
              </>
            ) : searchingSolicitations ? (
              <div className={`loadingContainer ${styles.loadingContainer}`}>
                <Circle size={100} />
              </div>
            ) : (
              <p className={`lineCardMessage`}>
                Nenhuma solicitação encontrada
              </p>
            )}
            <Paginate
              classNameContainer={styles.paginationContainer}
              maxItems={pagination.maxItems}
              totalRecords={pagination.totalRecords}
              currentPage={pagination.currentPage}
              setCurrentPage={pagination.setCurrentPage}
              onPageChange={(page) => {
                if (
                  isValid(
                    description,
                    status,
                    initialDate,
                    finalDate,
                    sellers,
                    supervisors
                  )
                )
                  searchSolicitations(
                    page - 1,
                    description.value,
                    status.value?.value,
                    initialDate.toISOString(),
                    finalDate.toISOStringNextDay(),
                    sellers.value.map((item) => item.value.id),
                    supervisors.value.map((item) => item.value.id)
                  );
              }}
            />
          </>
        ) : (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
          </div>
        )}
      </div>
      <dialog
        className={`modal ${styles.modalExcelType}`}
        onClose={() => (temporarySelectedSolicitationToExportExcel = null)}
        ref={modalExcelType}
      >
        <div className="modalContent">
          <div className={styles.modalExcelType__buttonsContainer}>
            {contractExcel.verifyPermission(ExcelPermissions.CLIENTE) && (
              <Button
                className={styles.modalExcelType__buttonsContainer__button}
                onClick={() => {
                  contractExcel.exportExcel(
                    temporarySelectedSolicitationToExportExcel!!,
                    ExcelType.MODELO_CLIENTE
                  );
                  modalExcelType.current?.close();
                }}
              >
                Modelo Cliente
              </Button>
            )}
            {contractExcel.verifyPermission(ExcelPermissions.ANALITICO) && (
              <Button
                className={styles.modalExcelType__buttonsContainer__button}
                onClick={() => {
                  contractExcel.exportExcel(
                    temporarySelectedSolicitationToExportExcel!!,
                    ExcelType.MODELO_ANALITICO
                  );
                  modalExcelType.current?.close();
                }}
              >
                Modelo Analítico
              </Button>
            )}
            {contractExcel.verifyPermission(ExcelPermissions.JURIDICO) && (
              <Button
                className={styles.modalExcelType__buttonsContainer__button}
                onClick={() => {
                  contractExcel.exportExcel(
                    temporarySelectedSolicitationToExportExcel!!,
                    ExcelType.MODELO_JURIDICO
                  );
                  modalExcelType.current?.close();
                }}
              >
                Modelo Jurídico
              </Button>
            )}
          </div>
          <Button
            className={styles.modalExcelType__buttonCancel}
            variant="danger"
            onClick={() => {
              modalExcelType.current?.close();
            }}
          >
            Cancelar
          </Button>
        </div>
      </dialog>
    </>
  );
}
