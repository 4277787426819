import { useState, useCallback, useMemo, useEffect } from "react";
import { useDate, useSelect } from "../../../../../hooks/form";
import {
  Contratado,
  Exigencia,
  FormaPagamento,
  GestorContrato,
  IUseAdicionarAtualizarHook,
  Objeto,
  PenalidadeContratual,
  PeriodicidadePagamento,
  Status,
} from "./types";
import { useCustomFetch, useDebounce, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { isValid } from "../../../../../helpers/validations";
import { IContrato, IUseContrato } from "../../hooks/types";
import { formatDate } from "../../../../../helpers/format";
import { useNavigate } from "react-router-dom";

interface IProps {
  contratosData: IUseContrato;
}

const labelStatus = (status: Status) => {
  switch (status) {
    case "A":
      return "Ativo";
    case "I":
      return "Não renovado (ambas as partes)";
    case "E":
      return "Não renovado pelo contratante";
    case "O":
      return "Não renovado pelo contratado";
    case "R":
      return "Rescisão pelo contratante";
    case "X":
      return "Rescisão pelo contratado";
  }
};

export function useAdicionarAtualizarHook({ contratosData }: IProps): IUseAdicionarAtualizarHook {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const insertingOrUpdatingContrato = useLoading();

  const contratado = useSelect<Contratado>({
    type: "single",
    required: true,
  });
  const objetoContrato = useSelect<Objeto>({
    type: "single",
    required: true,
  });
  const periodicidadePagamento = useSelect<PeriodicidadePagamento>({
    type: "single",
    required: true,
  });
  const formaPagamento = useSelect<FormaPagamento>({
    type: "single",
    required: true,
  });
  const exigenciaPagamento = useSelect<Exigencia>({ type: "single", required: true });
  const gestorContrato = useSelect<GestorContrato>({ type: "single", required: true });
  const penalidadesContratuais = useSelect<PenalidadeContratual>({
    type: "single",
    required: true,
  });
  const dataEnvio = useDate({ required: false });
  const dataRecebimentoDoc = useDate({ required: false });
  const inicioVigencia = useDate({ required: true });
  const fimVigencia = useDate({ required: true });
  const status = useSelect<Status>({ required: true, type: "single", defaultValue: { value: "A", label: "Ativo" } });

  const [contratadoOptions, setContratadoOptions] = useState<ISelectOption<Contratado>[]>([]);
  const [objetoContratoOptions, setObjetoContratoOptions] = useState<ISelectOption<Objeto>[]>([]);
  const [periodicidadePagamentoOptions, setPeriodicidadePagamentoOptions] = useState<
    ISelectOption<PeriodicidadePagamento>[]
  >([]);
  const [formaPagamentoOptions, setFormaPagamentoOptions] = useState<ISelectOption<FormaPagamento>[]>([]);
  const [gestorContratoOptions, setGestorContratoOptions] = useState<ISelectOption<GestorContrato>[]>([]);
  const [penalidadesContratuaisOptions, setPenalidadesContratuaisOptions] = useState<
    ISelectOption<PenalidadeContratual>[]
  >([]);
  const [exigenciaPagamentoOptions, setExigenciaPagamentoOptions] = useState<ISelectOption<Exigencia>[]>([]);
  const statusOptions = useMemo<ISelectOption<Status>[]>(
    () => [
      {
        label: "Ativo",
        value: "A",
      },
      {
        label: "Não renovado (ambas as partes)",
        value: "I",
      },
      {
        label: "Não renovado pelo contratante",
        value: "E",
      },
      {
        label: "Não renovado pelo contratado",
        value: "O",
      },
      {
        label: "Rescisão pelo contratante",
        value: "R",
      },
      {
        label: "Rescisão pelo contratado",
        value: "X",
      },
    ],
    []
  );

  const searchingContratadoOptions = useLoading();
  const searchingFormaPagamentoOptions = useLoading();
  const searchingGestorContratoOptions = useLoading();
  const searchingPenalidadesContratuaisOptions = useLoading();
  const searchingObjetoContratoOptions = useLoading();
  const searchingPeriodicidadePagamentoOptions = useLoading();
  const searchingExigenciaOptions = useLoading();

  const carregarDados = useCallback(
    (selectedContrato: IContrato) => {
      contratado.setValue({
        value: selectedContrato.contratado,
        label: `${selectedContrato.contratado.nomeContratado} | CNPJ: ${
          selectedContrato.contratado.cnpjContratado ? selectedContrato.contratado.cnpjContratado : "Não Informado"
        }`,
      });
      objetoContrato.setValue({
        value: selectedContrato.objeto,
        label: selectedContrato.objeto.nomeObjeto,
      });
      periodicidadePagamento.setValue({
        value: selectedContrato.periodicidadePagto,
        label: selectedContrato.periodicidadePagto.nomePeriodicidade,
      });
      formaPagamento.setValue({
        value: selectedContrato.formaPagto,
        label: selectedContrato.formaPagto.nomeFormaPagto,
      });
      exigenciaPagamento.setValue({
        value: selectedContrato.exigencia,
        label: selectedContrato.exigencia.nomeExigencia,
      });
      gestorContrato.setValue({
        value: selectedContrato.gestor,
        label: `${selectedContrato.gestor.idUsuario} | ${selectedContrato.gestor.nome}`,
      });
      penalidadesContratuais.setValue({
        value: selectedContrato.penalidade,
        label: selectedContrato.penalidade.nomePenalidade,
      });
      dataEnvio.setValue(selectedContrato.dataEnvioDoc ? formatDate(selectedContrato.dataEnvioDoc, "yyyy-MM-dd") : "");
      dataRecebimentoDoc.setValue(
        selectedContrato.dataRecebimentoDoc ? formatDate(selectedContrato.dataRecebimentoDoc, "yyyy-MM-dd") : ""
      );
      inicioVigencia.setValue(formatDate(selectedContrato.dataInicialVigencia, "yyyy-MM-dd"));
      fimVigencia.setValue(formatDate(selectedContrato.dataFinalVigencia, "yyyy-MM-dd"));
      status.setValue({
        value: selectedContrato.status,
        label: labelStatus(selectedContrato.status),
      });
    },
    [
      contratado,
      dataEnvio,
      dataRecebimentoDoc,
      exigenciaPagamento,
      fimVigencia,
      formaPagamento,
      gestorContrato,
      inicioVigencia,
      objetoContrato,
      penalidadesContratuais,
      periodicidadePagamento,
      status,
    ]
  );

  const searchContratadoOptions = useCallback(
    async (descricao = "") => {
      try {
        searchingContratadoOptions.setLoading(true);
        const json = (await customFetch("/juridico/searchContratadosPaginated", {
          body: {
            pagina: 0,
            tamanho: 100,
            descricao: descricao,
            status: "A",
          },
        })) as DefaultFetchResponse<Contratado[]>;
        if (json.status === 200) {
          const options = json.object.map((contratado) => ({
            label: `${contratado.nomeContratado} | CNPJ: ${
              contratado.cnpjContratado ? contratado.cnpjContratado : "Não Informado"
            }`,
            value: contratado,
          }));
          setContratadoOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setContratadoOptions([]);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        searchingContratadoOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingContratadoOptions]
  );

  const searchContratadoOptionsDebounced = useDebounce(searchContratadoOptions);

  const searchFormaPagamentoOptions = useCallback(async () => {
    try {
      searchingFormaPagamentoOptions.setLoading(true);
      const json = (await customFetch("/juridico/searchFormasPagamento", {
        body: {
          pagina: 0,
          tamanho: 0,
          descricao: "",
          status: "A",
        },
      })) as DefaultFetchResponse<FormaPagamento[]>;
      if (json.status === 200) {
        const options = json.object.map((formaPagto) => ({
          label: formaPagto.nomeFormaPagto,
          value: formaPagto,
        }));
        setFormaPagamentoOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setFormaPagamentoOptions([]);
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      searchingFormaPagamentoOptions.setLoading(false);
    }
  }, [Modal, customFetch, searchingFormaPagamentoOptions]);

  const searchGestorContratoOptions = useCallback(
    async (descricao = "") => {
      if (descricao.length < 3) return;
      try {
        searchingGestorContratoOptions.setLoading(true);
        const json = (await customFetch("/juridico/searchUsuarios", {
          body: {
            pagina: 0,
            tamanho: 100,
            descricao: descricao,
            status: "A",
          },
        })) as DefaultFetchResponse<GestorContrato[]>;
        if (json.status === 200) {
          const options = json.object.map((gestor) => ({
            label: `${gestor.idUsuario} | ${gestor.nome}`,
            value: gestor,
          }));
          setGestorContratoOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setGestorContratoOptions([]);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        searchingGestorContratoOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingGestorContratoOptions]
  );

  const searchGestorContratoOptionsDebounced = useDebounce(searchGestorContratoOptions);

  const searchPenalidadesContratuaisOptions = useCallback(async () => {
    try {
      searchingPenalidadesContratuaisOptions.setLoading(true);
      const json = (await customFetch("/juridico/searchPenalidadesPaginated", {
        body: {
          pagina: 0,
          tamanho: 0,
          descricao: "",
          status: "A",
        },
      })) as DefaultFetchResponse<PenalidadeContratual[]>;
      if (json.status === 200) {
        const options = json.object.map((penalidade) => ({
          label: penalidade.nomePenalidade,
          value: penalidade,
        }));
        setPenalidadesContratuaisOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setPenalidadesContratuaisOptions([]);
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      searchingPenalidadesContratuaisOptions.setLoading(false);
    }
  }, [Modal, customFetch, searchingPenalidadesContratuaisOptions]);

  const searchObjetoContratoOptions = useCallback(async () => {
    try {
      searchingObjetoContratoOptions.setLoading(true);
      const json = (await customFetch("/juridico/searchObjetosPaginated", {
        body: {
          pagina: 0,
          tamanho: 0,
          descricao: "",
          status: "A",
        },
      })) as DefaultFetchResponse<Objeto[]>;
      if (json.status === 200) {
        const options = json.object.map((objeto) => ({
          label: objeto.nomeObjeto,
          value: objeto,
        }));
        setObjetoContratoOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setObjetoContratoOptions([]);
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      searchingObjetoContratoOptions.setLoading(false);
    }
  }, [Modal, customFetch, searchingObjetoContratoOptions]);

  const searchPeriodicidadePagamentoOptions = useCallback(async () => {
    try {
      searchingPeriodicidadePagamentoOptions.setLoading(true);
      const json = (await customFetch("/juridico/searchPeriodicidadesPagtoPaginated", {
        body: {
          pagina: 0,
          tamanho: 0,
          descricao: "",
          status: "A",
        },
      })) as DefaultFetchResponse<PeriodicidadePagamento[]>;
      if (json.status === 200) {
        const options = json.object.map((periodicidade) => ({
          label: periodicidade.nomePeriodicidade,
          value: periodicidade,
        }));
        setPeriodicidadePagamentoOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setPeriodicidadePagamentoOptions([]);
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      searchingPeriodicidadePagamentoOptions.setLoading(false);
    }
  }, [Modal, customFetch, searchingPeriodicidadePagamentoOptions]);

  const searchExigenciaOptions = useCallback(async () => {
    try {
      searchingExigenciaOptions.setLoading(true);
      const json = (await customFetch("/juridico/searchExigenciasPaginated", {
        body: {
          pagina: 0,
          tamanho: 0,
          descricao: "",
          status: "A",
        },
      })) as DefaultFetchResponse<Exigencia[]>;
      if (json.status === 200) {
        const options = json.object.map((exigencia) => ({
          label: exigencia.nomeExigencia,
          value: exigencia,
        }));
        setExigenciaPagamentoOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setExigenciaPagamentoOptions([]);
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      searchingExigenciaOptions.setLoading(false);
    }
  }, [Modal, customFetch, searchingExigenciaOptions]);

  const insertOrUpdateContrato = useCallback(
    async (idContrato = 0) => {
      if (
        !isValid(
          contratado,
          objetoContrato,
          periodicidadePagamento,
          formaPagamento,
          exigenciaPagamento,
          gestorContrato,
          penalidadesContratuais,
          dataEnvio,
          dataRecebimentoDoc,
          inicioVigencia,
          fimVigencia,
          status
        )
      )
        return;
      try {
        insertingOrUpdatingContrato.setLoading(true);
        const json = await customFetch(`/juridico/insertAndUpdateContrato`, {
          body: {
            idContrato: idContrato,
            idContratado: contratado.value?.value.idContratado,
            idObjeto: objetoContrato.value?.value.idObjeto,
            idPeriodicidadePagto: periodicidadePagamento.value?.value.idPeriodicidade,
            idFormaPagto: formaPagamento.value?.value.idFormaPagto,
            idExigenciaPagto: exigenciaPagamento.value?.value.idExigencia,
            idGestor: gestorContrato.value?.value.idUsuario,
            idPenalidade: penalidadesContratuais.value?.value.idPenalidade,
            dataEnvioDoc: dataEnvio.value ? dataEnvio.toISOString() : undefined,
            dataRecebimentoDoc: dataRecebimentoDoc.value ? dataRecebimentoDoc.toISOString() : undefined,
            dataInicialVigencia: inicioVigencia.toISOString(),
            dataFinalVigencia: fimVigencia.toISOString(),
            status: status.value?.value,
          },
        });
        if (json.status === 200) {
          contratosData.searchs.searchContratosTotalRecordsAndContratosDebounced();
          await Modal.success(json.message);
          navigate("/contratos-fornecedor-cliente/contrato");
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error: any) {
        Modal.error(error.message, error.object);
      } finally {
        insertingOrUpdatingContrato.setLoading(false);
      }
    },
    [
      Modal,
      customFetch,
      dataEnvio,
      dataRecebimentoDoc,
      exigenciaPagamento,
      fimVigencia,
      inicioVigencia,
      insertingOrUpdatingContrato,
      objetoContrato,
      penalidadesContratuais,
      periodicidadePagamento,
      formaPagamento,
      gestorContrato,
      contratado,
      status,
      contratosData,
      navigate,
    ]
  );

  useEffect(() => {
    searchContratadoOptions();
    searchFormaPagamentoOptions();
    searchPenalidadesContratuaisOptions();
    searchObjetoContratoOptions();
    searchPeriodicidadePagamentoOptions();
    searchExigenciaOptions();
  }, []); // eslint-disable-line

  return {
    carregarDados,
    interactions: {
      insertOrUpdateContrato,
      insertingOrUpdatingContrato: insertingOrUpdatingContrato.isLoading,
    },
    options: {
      contratadoOptions: {
        options: contratadoOptions,
        searching: searchingContratadoOptions.isLoading,
        search: searchContratadoOptions,
        searchDebounced: searchContratadoOptionsDebounced,
      },
      objetoContratoOptions: {
        options: objetoContratoOptions,
        searching: searchingObjetoContratoOptions.isLoading,
        search: searchObjetoContratoOptions,
      },
      periodicidadePagamentoOptions: {
        options: periodicidadePagamentoOptions,
        searching: searchingPeriodicidadePagamentoOptions.isLoading,
        search: searchPeriodicidadePagamentoOptions,
      },
      formaPagamentoOptions: {
        options: formaPagamentoOptions,
        searching: searchingFormaPagamentoOptions.isLoading,
        search: searchFormaPagamentoOptions,
      },
      gestorContratoOptions: {
        options: gestorContratoOptions,
        searching: searchingGestorContratoOptions.isLoading,
        search: searchGestorContratoOptions,
        searchDebounced: searchGestorContratoOptionsDebounced,
      },
      penalidadesContratuaisOptions: {
        options: penalidadesContratuaisOptions,
        searching: searchingPenalidadesContratuaisOptions.isLoading,
        search: searchPenalidadesContratuaisOptions,
      },
      exigenciaPagamentoOptions: {
        options: exigenciaPagamentoOptions,
        searching: searchingExigenciaOptions.isLoading,
        search: searchExigenciaOptions,
      },
      statusOptions: {
        options: statusOptions,
      },
    },
    form: {
      contratado,
      objetoContrato,
      periodicidadePagamento,
      formaPagamento,
      exigenciaPagamento,
      gestorContrato,
      penalidadesContratuais,
      dataEnvio,
      dataRecebimentoDoc,
      inicioVigencia,
      fimVigencia,
      status,
    },
  };
}
