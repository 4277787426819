import React from "react";

import styles from "./Historical.module.css";
import { LinkButton } from "../../../../../../components/Form";
import { Timeline, TimelineContent, TimelineDot, TimelineItem } from "../../../../../../components/Data/Timeline";
import { useModal } from "../../../../../../hooks/contexts";
import { useCustomFetch, useLoading } from "../../../../../../hooks/async";
import { formatDate } from "../../../../../../helpers/format";
import { Circle } from "../../../../../../components/Loading";
import { usePreviousRoute } from "../../../../../../hooks/navigation";

export function Historical({ selectedExpense }) {
  const backUrl = usePreviousRoute();

  const Modal = useModal();
  const customFetch = useCustomFetch();

  const searchingHistory = useLoading();

  const [history, setHistory] = React.useState([]);
  const searchExpenseHistory = React.useCallback(async () => {
    try {
      searchingHistory.setLoading(true);
      const json = await customFetch("/rdv/searchExpenseHistory", {
        body: {
          codigoEmpresa: selectedExpense.codigoEmpresa,
          idDespesa: selectedExpense.idDespesa,
        },
      });
      if (json.status === 200) {
        setHistory(json.object);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setHistory([]);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingHistory.setLoading(false);
    }
  }, [Modal, customFetch, searchingHistory, selectedExpense]);

  React.useEffect(() => {
    searchExpenseHistory();
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.navigationContainer}>
        <LinkButton to={backUrl} buttonStyle="backButton" />
        <h2>Despesa nº: {selectedExpense.idDespesa}</h2>
        <div></div>
      </div>
      <span className={styles.separator}></span>
      <div className={styles.historyContainer}>
        {history.length && !searchingHistory.isLoading ? (
          <Timeline className={styles.timelineContainer}>
            {history.map((item, index) => (
              <TimelineItem key={index}>
                <TimelineDot />
                <TimelineContent>
                  <p dangerouslySetInnerHTML={{ __html: item.descricao }}></p>
                  <time
                    style={{
                      marginTop: "15px",
                      borderLeft: "2px solid var(--gray-8)",
                      borderRight: "2px solid var(--gray-8)",
                      padding: "0 2px",
                    }}>
                    {formatDate(item.data, "Month, dd de yyyy")}
                  </time>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        ) : searchingHistory.isLoading ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
            <span className="loadingMessage">Buscando histórico</span>
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhum histórico registrado</p>
        )}
      </div>
    </div>
  );
}
