import React from "react";
import { Route, Routes } from "react-router-dom";

import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { useForm } from "../../../../../hooks/form";

import { SupervisorList } from "./SupervisorList/SupervisorList";
import { NewSupervisor } from "./NewSupervisor/NewSupervisor";

export function Supervisors({ selectedPriceTable }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const filteredSupervisors = useForm({ type: "", required: false });

  const [supervisors, setSupervisors] = React.useState([]);

  const searchingSupervisors = useLoading();

  const searchSupervisors = React.useCallback(
    async (description = "") => {
      if (description === "" && filteredSupervisors.value.length) filteredSupervisors.reset();
      try {
        searchingSupervisors.setLoading(true);
        const json = await customFetch("/products/searchSupervisorsOfPriceTable", {
          body: {
            idTabela: Number(selectedPriceTable.tabela.idTabela),
            descricao: description,
          },
        });
        if (json.status === 200) {
          setSupervisors(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setSupervisors([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingSupervisors.setLoading(false);
      }
    },
    [Modal, customFetch, filteredSupervisors, searchingSupervisors, selectedPriceTable]
  );

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <SupervisorList
              selectedPriceTable={selectedPriceTable}
              filteredSupervisors={filteredSupervisors}
              searchSupervisors={searchSupervisors}
              searchingSupervisors={searchingSupervisors}
              supervisors={supervisors}
            />
          }
        />
        <Route
          path="novo"
          element={<NewSupervisor selectedPriceTable={selectedPriceTable} searchSupervisors={searchSupervisors} />}
        />
      </Routes>
    </div>
  );
}
