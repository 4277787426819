import React from "react";

declare global {
  interface IUseLoading {
    isLoading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  }
}

/**
 * Hook para definir os estados de um loading
 */
const useLoading = (): IUseLoading => {
  const [isLoading, setIsLoading] = React.useState(false);

  return {
    isLoading,
    setLoading: setIsLoading,
  };
};

export { useLoading };
