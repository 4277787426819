import React, { useEffect, useMemo } from "react";

import { Circle } from "../../../../components/Loading";
import { Button, LinkButton } from "../../../../components/Form";

import styles from "./Grupos.module.css";
import { UseGruposPermissoes } from "../hooks/useGruposPermissoes";
import { Pencil } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import { ITableData, Table } from "../../../../components/Data/XTable";

interface Props {
  dadosGruposPermissoes: UseGruposPermissoes;
}

export default function Grupos({ dadosGruposPermissoes }: Props) {
  const navigate = useNavigate();

  const { listaGrupos, buscandoGrupos } = dadosGruposPermissoes.dadosGrupos;

  const tableGrupos = useMemo<ITableData>(() => {
    const data = listaGrupos.map((grupo) => {
      return {
        ...grupo,
        editarPermissoes: (
          <>
            <Button
              type="button"
              variant="edit"
              onClick={() => {
                dadosGruposPermissoes.setSelectedGrupo(grupo);
                navigate("atualizar-permissoes");
              }}
              data-option-button
            >
              Permissões
            </Button>
          </>
        ),
        editarGrupo: (
          <>
            <Button
              className={styles.editGrupoButton}
              type="button"
              variant="edit"
              onClick={() => {
                dadosGruposPermissoes.setSelectedGrupo(grupo);
                navigate("atualizar");
              }}
              data-option-button
            >
              <Pencil weight="fill" />
            </Button>
          </>
        ),
      };
    });

    return {
      columns: [
        {
          title: "ID",
          objectName: "id",
          isSorted: false,
          style: { width: ".625rem" },
        },
        {
          title: "Grupo Permissões",
          objectName: "nome",
          isSorted: false,
        },
        {
          title: "Status",
          objectName: "status",
          isSorted: false,
          formatting: (value: string) => (value === "A" ? "Ativo" : "Inativo"),
          style: { width: ".625rem" },
        },
        {
          title: "",
          objectName: "editarPermissoes",
          isSorted: false,
          style: { width: "5rem" },
        },
        {
          title: "",
          objectName: "editarGrupo",
          isSorted: false,
          style: { width: "5rem" },
        },
      ],
      data: data,
    };
  }, [dadosGruposPermissoes, listaGrupos, navigate]);

  useEffect(() => {
    dadosGruposPermissoes.setSelectedGrupo(null);
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <nav className={styles.navigationContainer}>
        <LinkButton to={"novo"}>Novo Grupo</LinkButton>
      </nav>
      <span className="separator" />
      {!buscandoGrupos ? (
        <>
          {listaGrupos.length > 0 ? (
            <Table tableData={tableGrupos} className={styles.table} />
          ) : (
            <div className="lineCardMessage">
              Nenhum Grupo de Permissões Encontrado
            </div>
          )}
        </>
      ) : (
        <div className="loadingContainer">
          <Circle size={100} />
        </div>
      )}
    </div>
  );
}
