import React from "react";
import { UNSAFE_NavigationContext as NavigationContext, useLocation } from "react-router-dom";

import { useModal, useChanges } from "../contexts";

export function useBlocker(blocker, when = true) {
  const { navigator } = React.useContext(NavigationContext);

  React.useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

export function usePrompt(message, when = true) {
  const Modal = useModal();
  const Changes = useChanges();

  const blocker = React.useCallback(
    async (tx) => {
      const allowNavigate = await Modal.confirm(message);
      if (allowNavigate) {
        Changes.reset();
        tx.retry();
      }
    },
    [message] // eslint-disable-line
  );

  useBlocker(blocker, when);
}

export function usePreviousRoute() {
  const location = useLocation();
  const pathname = location.pathname;
  const previousPathname = pathname.split("/").slice(0, -1).join("/");
  return previousPathname;
}
