import React from "react";
import { Route, Routes } from "react-router-dom";
import { IBank, List } from "./List/List";
import { NewBank } from "./NewBank/NewBank";
import { UpdateBank } from "./UpdateBank/UpdateBank";
import { useCustomFetch, useLoading } from "../../../hooks/async";
import { useModal } from "../../../hooks/contexts";
import { usePagination } from "../../../hooks/pagination";

export function FinanceBank() {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const [bankList, setBankList] = React.useState<IBank[]>([]);
  const [selectedBank, setSelectedBank] = React.useState<IBank | null>(null);

  const searchingBanks = useLoading();

  const pagination = usePagination(10);

  const searchBanksPromise = React.useCallback(
    (page = 1) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/finance/searchBanksPaginated", {
            body: {
              id: 0,
              paginacao: {
                paginaNumero: page,
                paginaTamanho: pagination.maxItems,
              },
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const searchTotalBankRecordsPromise = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const json = await customFetch("/finance/searchTotalBankRecords", {});
        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
  }, [customFetch]);

  const searchBanks = React.useCallback(
    async (page: number = 1) => {
      try {
        searchingBanks.setLoading(true);
        const json = (await searchBanksPromise(page)) as DefaultFetchResponse<IBank[]>;
        if (json.status === 200) {
          setBankList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setBankList([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingBanks.setLoading(false);
      }
    },
    [Modal, searchBanksPromise, searchingBanks]
  );

  const searchTotalBankRecordsAndBanks = React.useCallback(async () => {
    try {
      searchingBanks.setLoading(true);
      const json = (await searchBanksPromise()) as DefaultFetchResponse<IBank[]>;
      if (json.status === 200) {
        const records = (await searchTotalBankRecordsPromise()) as DefaultFetchResponse<string>;
        if (records.status === 200) {
          setBankList(json.object);
          pagination.setTotalRecords(Number(records.object));
        } else if (records.status === 500) {
          Modal.error(records.message, records.object);
        } else {
          setBankList([]);
        }
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setBankList([]);
        pagination.reset();
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      pagination.setCurrentPage(1);
      searchingBanks.setLoading(false);
    }
  }, [Modal, pagination, searchBanksPromise, searchTotalBankRecordsPromise, searchingBanks]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <List
            bankList={{ value: bankList, setValue: setBankList }}
            searchBanks={searchBanks}
            searchTotalBankRecordsAndBanks={{
              search: searchTotalBankRecordsAndBanks,
              isSearching: searchingBanks.isLoading,
            }}
            pagination={pagination}
            setSelectedBank={setSelectedBank}
          />
        }
      />
      <Route path="novo" element={<NewBank searchTotalBankRecordsAndBanks={searchTotalBankRecordsAndBanks} />} />
      <Route
        path="atualizar"
        element={<UpdateBank searchBanks={() => searchBanks(pagination.currentPage)} selectedBank={selectedBank} />}
      />
    </Routes>
  );
}
