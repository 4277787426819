import React, { ReactNode } from "react";
import styles from "./styles.module.css";

interface Props {
  children: ReactNode;
}

export default function CommissionBallRoot({ children }: Props) {
  return <div className={styles.commissionBallRoot}>{children}</div>;
}
