import React from "react";
import { Route, Routes } from "react-router-dom";
import { Lista } from "./Lista/Lista";
import { useExigencia } from "./hooks/useExigencia";
import { AdicionarAtualizar } from "./AdicionarAtualizar/AdicionarAtualizar";

export function ContratosFCExigencia() {
  const exigenciaData = useExigencia();

  return (
    <Routes>
      <Route path="/" element={<Lista exigenciaData={exigenciaData} />} />
      <Route path="nova" element={<AdicionarAtualizar tipo="insert" exigenciaData={exigenciaData} />} />
      <Route
        path="atualizar"
        element={
          <AdicionarAtualizar
            tipo="update"
            selectedExigencia={exigenciaData.selectedExigencia.value}
            exigenciaData={exigenciaData}
          />
        }
      />
    </Routes>
  );
}
