import React from "react";

import { isValid } from "../../../../../../helpers/validations";

import { Button, LinkButton, Select } from "../../../../../../components/Form";
import { useCustomFetch, useDebounce, useLoading } from "../../../../../../hooks/async";
import { useModal } from "../../../../../../hooks/contexts";
import { useSelect } from "../../../../../../hooks/form";

import styles from "./NewEnterprise.module.css";
import { Circle } from "../../../../../../components/Loading";

export function NewEnterprise({ selectedPriceTable, searchEnterprises: searchEnterprisesInPriceTable }) {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const enterprise = useSelect({ type: "single", required: true, emptyMessage: "Selecione uma empresa" });

  const searchingEnterprises = useLoading();
  const registeringEnterprise = useLoading();

  const [enterpriseOptions, setEnterpriseOptions] = React.useState([]);

  const clearForm = React.useCallback(() => {
    enterprise.reset();
    setEnterpriseOptions([]);
  }, [enterprise]);

  const searchEnterprises = React.useCallback(
    async (description = "") => {
      searchingEnterprises.setLoading(true);
      try {
        const json = await customFetch("/products/searchEnterprisesToAddInPriceTable", {
          body: {
            idTabela: Number(selectedPriceTable.tabela.idTabela),
            descricao: description,
          },
        });
        if (json.status === 200) {
          const options = json.object.map((option) => {
            return {
              value: { ...option },
              label: `${option.empresa.idEmpresa} - ${option.empresa.nomeEmpresa}`,
            };
          });
          setEnterpriseOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setEnterpriseOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingEnterprises.setLoading(false);
      }
    },
    [Modal, customFetch, searchingEnterprises, selectedPriceTable]
  );

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (isValid(enterprise)) {
        try {
          registeringEnterprise.setLoading(true);
          const json = await customFetch("/products/registerAndUpdateEnterpriseOfPriceTable", {
            body: {
              idTabela: Number(selectedPriceTable.tabela.idTabela),
              idEmpresa: Number(enterprise.value.value.empresa.idEmpresa),
              status: true,
            },
          });
          if (json.status === 200) {
            await Modal.success(json.message);
            searchEnterprisesInPriceTable();
            clearForm();
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          registeringEnterprise.setLoading(false);
        }
      }
    },
    [
      enterprise,
      registeringEnterprise,
      customFetch,
      selectedPriceTable,
      Modal,
      searchEnterprisesInPriceTable,
      clearForm,
    ]
  );

  const { current: searchEnterpriseDebounced } = React.useRef(
    useDebounce((description) => {
      searchEnterprises(description);
    })
  );

  return (
    <div>
      {!registeringEnterprise.isLoading ? (
        <>
          <div>
            <LinkButton to="/produtos/tabela-de-precos/atualizar/empresas" buttonStyle="backButton" />
          </div>
          <div className={styles.formContainer}>
            <form onSubmit={onSubmit}>
              <div className={styles.enterpriseContainer}>
                <div>
                  <label htmlFor="enterprise" className="label">
                    Empresa
                  </label>
                  <Select
                    id="enterprise"
                    placeholder="Selecione uma empresa para adicionar"
                    options={enterpriseOptions}
                    value={enterprise.value}
                    error={enterprise.error}
                    onChange={enterprise.onChange}
                    onBlur={enterprise.onBlur}
                    onInputChange={(value) => {
                      searchEnterpriseDebounced(value);
                    }}
                    isLoading={searchingEnterprises.isLoading}
                  />
                </div>
              </div>
              <div className={styles.submitButtonContainer}>
                <Button>Adicionar Empresa</Button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className={`loadingContainer`}>
          <Circle size={100} />
          <span className={`loadingMessage`}>Adicionando empresa à tabela</span>
        </div>
      )}
    </div>
  );
}
