import { useCallback, useEffect, useState } from "react";
import { useModal } from "../../../hooks/contexts";
import { useCustomFetch, useLoading } from "../../../hooks/async";

function getFilenameFromResponse(response: any) {
  const contentDisposition = response.headers.get("Content-Disposition");
  if (contentDisposition) {
    const parts = contentDisposition.split(";");
    const filename = parts[1].split("=")[1].replaceAll('"', "");
    return filename;
  }
  return "unknown.xlsx"; // Default filename if not provided
}

export enum ExcelType {
  MODELO_CLIENTE = "C",
  MODELO_JURIDICO = "J",
  MODELO_ANALITICO = "A",
}

export const ExcelPermissions = {
  CLIENTE: "VER_SOLICITACAO_CONTRATO_FORMULAS_CLIENTE",
  ANALITICO: "VER_SOLICITACAO_CONTRATO_FORMULAS_ANALITICA",
  JURIDICO: "VER_SOLICITACAO_CONTRATO_FORMULAS_JURIDICO",
};

export default function useContractExcel() {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const [permissions, setPermissions] = useState<string[]>([]);

  const exportingExcel = useLoading();

  const getExcelPermissions = useCallback(() => {
    const excelPermissions = [
      ExcelPermissions.JURIDICO,
      ExcelPermissions.ANALITICO,
      ExcelPermissions.CLIENTE,
    ];

    const permissionsAllowed = permissions.filter((permission) =>
      excelPermissions.includes(permission)
    );

    let type: ExcelType | null = null;

    if (permissionsAllowed.length === 1) {
      switch (permissionsAllowed[0]) {
        case ExcelPermissions.JURIDICO:
          type = ExcelType.MODELO_JURIDICO;
          break;
        case ExcelPermissions.ANALITICO:
          type = ExcelType.MODELO_ANALITICO;
          break;
        case ExcelPermissions.CLIENTE:
          type = ExcelType.MODELO_CLIENTE;
          break;
      }
    }

    return {
      permissions: permissionsAllowed,
      type,
    };
  }, [permissions]);

  const verifyPermission = useCallback(
    (permission: string) => {
      return permissions.includes(permission);
    },
    [permissions]
  );

  const exportExcel = useCallback(
    async (solicitationId: number, type: ExcelType) => {
      try {
        exportingExcel.setLoading(true);

        const response = await fetch(
          `/contracts/exportSolicitationExcel/${solicitationId}?modeloLayout=${type}`,
          { method: "GET" }
        );
        debugger;
        if (response.ok) {
          const filename = getFilenameFromResponse(response);
          const blob = await response.blob();
          const urlDownload = URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.href = urlDownload;
          anchor.download = filename;
          anchor.click();

          URL.revokeObjectURL(urlDownload);
          anchor.parentNode?.removeChild(anchor);
        } else {
          try {
            const json = await response.json();
            throw new Error(json.message);
          } catch (error: any) {
            throw new Error(error.message || "Erro ao baixar o arquivo");
          }
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        exportingExcel.setLoading(false);
      }
    },
    [Modal, exportingExcel]
  );

  const getPermissionsPromise = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const json = (await customFetch(
          "/contracts/commercialSearchUserPermissions",
          {
            method: "GET",
          }
        )) as DefaultFetchResponse<
          { idPermissao: number; nomePermissao: string }[]
        >;
        if (json.status === 200) {
          setPermissions(
            json.object.map((permission) => permission.nomePermissao.trim())
          );
        }
        resolve(json);
      } catch (error: any) {
        reject(error.message);
      }
    });
  }, [customFetch]);

  useEffect(() => {
    getPermissionsPromise();
  }, []); // eslint-disable-line

  return {
    verifyPermission,
    getExcelPermissions,
    exportExcel,
    permissions,
    exportingExcel: exportingExcel.isLoading,
  };
}

export type UseContractExcel = ReturnType<typeof useContractExcel>;
