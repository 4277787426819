import React, { useCallback, useState, useMemo, useEffect } from "react";

import { usePagination } from "../../../../hooks/pagination";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { IObjeto, IUseObjetos, SearchObjetosResponse } from "./types";
import { Button } from "../../../../components/Form";
import { useNavigate } from "react-router-dom";
import { Pencil } from "phosphor-react";

import styles from "../Lista/Lista.module.css";
import { ITableData } from "../../../../components/Data/XTable";

export function useObjetos(): IUseObjetos {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const [selectedObjeto, setSelectedObjeto] = useState<IObjeto | null>(null);

  const pagination = usePagination(0);
  const searchingObjetos = useLoading();

  const [objetos, setObjetos] = useState<IObjeto[]>([]);

  const tableData: ITableData = useMemo(() => {
    const data = objetos.map((objeto) => ({
      ...objeto,
      editar: (
        <Button
          type="button"
          variant="edit"
          className={styles.editButton}
          onClick={() => {
            setSelectedObjeto(objeto);
            navigate("atualizar");
          }}
          data-option-button>
          <Pencil weight="fill" />
        </Button>
      ),
    }));
    return {
      columns: [
        {
          title: "ID",
          objectName: "idObjeto",
          isSorted: false,
          style: { width: ".625rem" },
        },
        {
          title: "OBJETO",
          objectName: "nomeObjeto",
          isSorted: false,
        },
        {
          title: "",
          objectName: "editar",
          isSorted: false,
          style: { width: "5rem" },
        },
      ],
      data,
    };
  }, [objetos, navigate]);

  const searchObjetosPaginatedPromise = useCallback(
    (descricao: string) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/juridico/searchObjetosPaginated", {
            body: {
              pagina: 0,
              tamanho: 0,
              descricao: descricao,
              status: "",
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch]
  );

  const searchObjetosPaginated = useCallback(
    async (descricao: string) => {
      try {
        searchingObjetos.setLoading(true);
        const json = (await searchObjetosPaginatedPromise(descricao)) as SearchObjetosResponse;
        if (json.status === 200) {
          setObjetos(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setObjetos([]);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        searchingObjetos.setLoading(false);
      }
    },
    [Modal, searchObjetosPaginatedPromise, searchingObjetos]
  );

  useEffect(() => {
    searchObjetosPaginated("");
  }, []); // eslint-disable-line

  return {
    selectedObjeto: {
      value: selectedObjeto,
      setValue: setSelectedObjeto,
    },
    data: {
      objetos,
      tableData,
    },
    searchs: {
      pagination,
      searchingObjetos: searchingObjetos.isLoading,
      searchObjetosPaginated,
    },
  };
}
