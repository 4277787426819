import React from "react";
import { IItem } from "../CommissionTableItems";
import {
  ICommissionTable,
  ISearchCommissionTablesResponse,
} from "../../CommissionTables";
import { Button, LinkButton, Select } from "../../../../../../components/Form";
import { useSelect } from "../../../../../../hooks/form";
import {
  useCustomFetch,
  useDebounce,
  useLoading,
} from "../../../../../../hooks/async";
import { useModal } from "../../../../../../hooks/contexts";

import styles from "./CopyRange.module.css";
import { isValid } from "../../../../../../helpers/validations";
import { Circle } from "../../../../../../components/Loading";
import { useNavigate } from "react-router-dom";

interface IFormula {
  idFormulado: number;
  descricaoFormulado: string;
  dataFormulado: string;
  usuarioCadastro: string;
  situacaoFormulado: string;
  tipoFormulado: number;
}

interface ISearchFormulasResponse {
  response: number;
  message: string;
  status: number;
  object: IFormula[];
}

interface ICopyRangeResponse {
  response: number;
  message: string;
  status: number;
  object: any;
}

interface ICopyRangeProps {
  selectedTable: ICommissionTable | null;
  selectedItem: IItem | null;
  searchTotalItemsRecordsAndItems: (tableId: number) => void;
}

export function CopyRange({
  selectedItem,
  selectedTable,
  searchTotalItemsRecordsAndItems,
}: ICopyRangeProps) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const tableToSendCopy = useSelect({
    type: "single",
    required: true,
    defaultValue: {
      label: `${selectedTable?.idTabela} | ${selectedTable?.descricaoTabela}`,
      value: selectedTable,
    },
  });
  const formulas = useSelect({
    type: "multiple",
    required: true,
    defaultValue: [],
  });
  const [copyPeoples, setCopyPeoples] = React.useState<boolean>(true);

  const [tableOptions, setTableOptions] = React.useState<
    { label: string; value: ICommissionTable }[]
  >([]);
  const [formulaOptions, setFormulaOptions] = React.useState<
    { label: string; value: IFormula }[]
  >([]);

  const isAllFormulasSelected = React.useMemo(() => {
    return (
      formulas.value.length > 0 &&
      formulas.value.length === formulaOptions.length
    );
  }, [formulaOptions.length, formulas.value.length]);

  const searchingTableOptions = useLoading();
  const searchingFormulaOptions = useLoading();
  const copyingRange = useLoading();

  const searchTableOptions = React.useCallback(
    async (description: string = "") => {
      try {
        searchingTableOptions.setLoading(true);
        const json = (await customFetch(
          "/commissions/searchCommissionTablesPaginated",
          {
            body: {
              pagina: 0,
              tamanho: 100,
              descricao: description,
            },
          }
        )) as ISearchCommissionTablesResponse;
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            label: `${option.idTabela} | ${option.descricaoTabela}`,
            value: option,
          }));
          setTableOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setTableOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingTableOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingTableOptions]
  );

  const searchTableOptionsDebounced = useDebounce(searchTableOptions);

  const searchFormulas = React.useCallback(
    async (copiedTableId: number, description: string = "") => {
      try {
        searchingFormulaOptions.setLoading(true);
        const json = (await customFetch(
          "/commissions/searchCommissionTableFormulas",
          {
            body: {
              idTabela: copiedTableId,
              descricao: description,
            },
          }
        )) as ISearchFormulasResponse;
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            label: `${option.idFormulado} | ${option.descricaoFormulado}`,
            value: option,
          }));
          setFormulaOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setFormulaOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingFormulaOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingFormulaOptions]
  );

  const handleClickCopyRange = async () => {
    if (isValid(formulas, tableToSendCopy)) {
      const confirm = await Modal.confirm("Deseja mesmo copiar está faixa?");
      if (confirm) {
        try {
          copyingRange.setLoading(true);
          const json = (await customFetch(
            "/commissions/copyCommissionTableRange",
            {
              body: {
                formulaSerReplicada: selectedItem?.idFormulado,
                idTabela: selectedTable?.idTabela,
                tabelaASerReplicada: (
                  tableToSendCopy.value as unknown as {
                    label: string;
                    value: ICommissionTable;
                  }
                ).value.idTabela,
                replicarPara: (
                  formulas.value as unknown as {
                    label: string;
                    value: IFormula;
                  }[]
                ).map((item) => item.value.idFormulado),
                replicarPessoas: copyPeoples,
              },
            }
          )) as ICopyRangeResponse;
          if (json.status === 200) {
            searchTotalItemsRecordsAndItems(Number(selectedTable?.idTabela));
            await Modal.success(json.message);
            navigate("/comissoes/adubo/tabelas-de-comissoes/itens");
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          copyingRange.setLoading(false);
        }
      }
    }
  };

  React.useEffect(() => {
    searchTableOptions();
    searchFormulas(selectedTable?.idTabela || 0);
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.navContainer}>
        <LinkButton
          to="/comissoes/adubo/tabelas-de-comissoes/itens"
          buttonStyle="backButton"
          children={undefined}
          variant={undefined}
          className={undefined}
          classNameContainer={undefined}
          disabled={undefined}
        />
        <h2 className={styles.navContainer__title}>
          {selectedTable?.descricaoTabela} - Fórmula{" "}
          {selectedItem?.descricaoFormulado}
        </h2>
        <div></div>
      </div>
      <div>
        <div className={styles.rangeContainer}>
          <div>
            <p className={styles.itemTitle}>{}</p>
            <span className={styles.itemSeparator}></span>
            <div className={styles.commissionsContainer}>
              {selectedItem?.itensComissaoTabela.map(
                (commission, commissionIndex) => (
                  <div
                    className={`${styles.commissionBlock}`}
                    key={commissionIndex}
                  >
                    <p>
                      {selectedItem.tipoComissao}. {commission.perMaximo}
                    </p>
                    <div className={styles.ball}>
                      <div style={{ backgroundColor: commission.rgb }}></div>
                      <span style={{ backgroundColor: commission.rgb }}></span>
                    </div>
                    <p>Com. {commission.comissaoValor}</p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <div>
          {!copyingRange.isLoading ? (
            <div className={styles.fieldsContainer}>
              <div>
                <label htmlFor="" className="label">
                  Tabela Destino
                </label>
                <Select
                  placeholder={"Selecione uma tabela para enviar a cópia"}
                  options={tableOptions}
                  value={tableToSendCopy.value}
                  onChange={tableToSendCopy.onChange}
                  onInputChange={(value) => {
                    searchTableOptionsDebounced(value);
                  }}
                  onBlur={tableToSendCopy.onBlur}
                  defaultValue={undefined}
                  error={tableToSendCopy.error}
                  isLoading={searchingTableOptions.isLoading}
                />
              </div>
              <div>
                <label htmlFor="" className="label">
                  Fórmulas a Copiar
                  <button
                    className={styles.btnSelectAllFormulas}
                    onClick={() => {
                      if (isAllFormulasSelected) {
                        formulas.reset();
                      } else {
                        formulas.setValue(
                          JSON.parse(JSON.stringify(formulaOptions))
                        );
                      }
                    }}
                    disabled={
                      searchingFormulaOptions.isLoading ||
                      formulaOptions.length === 0
                    }
                  >
                    {isAllFormulasSelected
                      ? "Remover Todas"
                      : "Selecionar Todas"}
                  </button>
                </label>
                <Select
                  placeholder={"Selecione as fórmulas que deseja copiar"}
                  options={formulaOptions.filter(
                    (option) =>
                      !(
                        formulas.value as unknown as {
                          label: string;
                          value: IFormula;
                        }[]
                      ).some(
                        (i) => i.value.idFormulado === option.value.idFormulado
                      )
                  )}
                  value={formulas.value}
                  onChange={formulas.onChange}
                  onBlur={formulas.onBlur}
                  defaultValue={undefined}
                  error={formulas.error}
                  isMulti={true}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  isLoading={searchingFormulaOptions.isLoading}
                />
              </div>
              <div className={styles.fieldsContainer__copyPeoplesField}>
                <label className="label">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setCopyPeoples(e.target.checked);
                    }}
                    checked={copyPeoples}
                  />
                  <span>Copiar Pessoas da Faixa</span>
                </label>
              </div>
              <div className={styles.fieldsContainer__copyButton}>
                <Button onClick={handleClickCopyRange}>Copiar</Button>
              </div>
            </div>
          ) : (
            <div className={`loadingContainer ${styles.loadingContainer}`}>
              <Circle size={100} />
              <span className="loadingMessage">Copiando Faixa de Comissão</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
