import React from "react";
import { Route, Routes } from "react-router-dom";
import { Lista } from "./Lista/Lista";
import { useObjetos } from "./hooks/useObjetos";
import { AdicionarAtualizar } from "./AdicionarAtualizar/AdicionarAtualizar";

export function ContratosFCObjetos() {
  const objetosData = useObjetos();

  return (
    <Routes>
      <Route path="/" element={<Lista objetosData={objetosData} />} />
      <Route path="nova" element={<AdicionarAtualizar tipo="insert" objetosData={objetosData} />} />
      <Route
        path="atualizar"
        element={
          <AdicionarAtualizar
            tipo="update"
            selectedObjeto={objetosData.selectedObjeto.value}
            objetosData={objetosData}
          />
        }
      />
    </Routes>
  );
}
