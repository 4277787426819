import React from "react";
import { Route, Routes } from "react-router-dom";

import { Index } from "./Index/Index";
import { UpdatePercents } from "./UpdatePercents/UpdatePercents";

import useCommissionPercent from "./hooks/useCommissionPercent";

export function CommissionsCommissionPercent() {
  const data = useCommissionPercent();

  return (
    <Routes>
      <Route path="/" element={<Index rootData={data} />} />
      <Route
        path="atualizar-percentuais"
        element={<UpdatePercents rootData={data} />}
      />
    </Routes>
  );
}
