import React from "react";
import { Route, Routes } from "react-router-dom";
import { Lista } from "./Lista/Lista";
import { usePeriodicidadesPagto } from "./hooks/usePeriodicidadesPagto";
import { AdicionarAtualizar } from "./AdicionarAtualizar/AdicionarAtualizar";

export function ContratosFCPeriodicidadesPagto() {
  const periodicidadesPagtoData = usePeriodicidadesPagto();

  return (
    <Routes>
      <Route path="/" element={<Lista periodicidadesPagtoData={periodicidadesPagtoData} />} />
      <Route
        path="nova"
        element={<AdicionarAtualizar tipo="insert" periodicidadesPagtoData={periodicidadesPagtoData} />}
      />
      <Route
        path="atualizar"
        element={
          <AdicionarAtualizar
            tipo="update"
            selectedPeriodicidadePagto={periodicidadesPagtoData.selectedPeriodicidadePagto.value}
            periodicidadesPagtoData={periodicidadesPagtoData}
          />
        }
      />
    </Routes>
  );
}
