import React, { useCallback, useState, useMemo, useEffect } from "react";

import { usePagination } from "../../../../hooks/pagination";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { IPenalidade, IUsePenalidades, SearchPenalidadesResponse } from "./types";
import { Button } from "../../../../components/Form";
import { useNavigate } from "react-router-dom";
import { Pencil } from "phosphor-react";

import styles from "../Lista/Lista.module.css";
import { ITableData } from "../../../../components/Data/XTable";

export function usePenalidades(): IUsePenalidades {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const [selectedPenalidade, setSelectedPenalidade] = useState<IPenalidade | null>(null);

  const pagination = usePagination(0);
  const searchingPenalidades = useLoading();

  const [penalidades, setPenalidades] = useState<IPenalidade[]>([]);

  const tableData: ITableData = useMemo(() => {
    const data = penalidades.map((penalidade) => ({
      ...penalidade,
      editar: (
        <Button
          type="button"
          variant="edit"
          className={styles.editButton}
          onClick={() => {
            setSelectedPenalidade(penalidade);
            navigate("atualizar");
          }}
          data-option-button>
          <Pencil weight="fill" />
        </Button>
      ),
    }));
    return {
      columns: [
        {
          title: "ID",
          objectName: "idPenalidade",
          isSorted: false,
          style: { width: ".625rem" },
        },
        {
          title: "Penalidade",
          objectName: "nomePenalidade",
          isSorted: false,
        },
        {
          title: "",
          objectName: "editar",
          isSorted: false,
          style: { width: "5rem" },
        },
      ],
      data,
    };
  }, [penalidades, navigate]);

  const searchPenalidadesPaginatedPromise = useCallback(
    (descricao: string) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/juridico/searchPenalidadesPaginated", {
            body: {
              pagina: 0,
              tamanho: 0,
              descricao: descricao,
              status: "",
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch]
  );

  const searchPenalidadesPaginated = useCallback(
    async (descricao: string) => {
      try {
        searchingPenalidades.setLoading(true);
        const json = (await searchPenalidadesPaginatedPromise(descricao)) as SearchPenalidadesResponse;
        if (json.status === 200) {
          setPenalidades(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setPenalidades([]);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        searchingPenalidades.setLoading(false);
      }
    },
    [Modal, searchPenalidadesPaginatedPromise, searchingPenalidades]
  );

  useEffect(() => {
    searchPenalidadesPaginated("");
  }, []); // eslint-disable-line

  return {
    selectedPenalidade: {
      value: selectedPenalidade,
      setValue: setSelectedPenalidade,
    },
    data: {
      penalidades,
      tableData,
    },
    searchs: {
      pagination,
      searchingPenalidades: searchingPenalidades.isLoading,
      searchPenalidadesPaginated,
    },
  };
}
