import React, { useLayoutEffect } from "react";

import { ICommissionTable } from "../../CommissionTables";
import { Button, LinkButton, Select } from "../../../../../../components/Form";
import { useCloneCommissionsToSellers } from "./hooks/useCloneCommissionsToSellers";
import { Circle } from "../../../../../../components/Loading";
import { selectOptionsDifference } from "../../../../../../helpers/List";

import styles from "./CloneCommissionsToSellers.module.css";

interface Props {
  selectedTable: ICommissionTable | null;
}

export function CloneCommissionsToSellers({ selectedTable }: Props) {
  const {
    copiedSeller,
    sellers,
    supervisor,
    searchCopiedSellerOptions,
    searchSellerOptions,
    searchSupervisorOptions,
    cloneCommissions,
  } = useCloneCommissionsToSellers();

  useLayoutEffect(() => {
    searchCopiedSellerOptions.search();
    searchSellerOptions.search();
    searchSupervisorOptions.search();
  }, []); // eslint-disable-line

  return (
    <div className="container">
      {!cloneCommissions.cloning ? (
        <>
          <div className={styles.navContainer}>
            <LinkButton to="/comissoes/adubo/tabelas-de-comissoes/itens" buttonStyle="backButton" />
            <h2>{selectedTable?.descricaoTabela}</h2>
            <div />
          </div>
          <span className="separator" />
          <div className={styles.fieldsContainer}>
            <div>
              <label htmlFor="copiedSeller" className="label">
                Vendedor Copiado
              </label>
              <Select
                id="copiedSeller"
                placeholder="Selecione o vendedor que será copiado"
                options={searchCopiedSellerOptions.options}
                value={copiedSeller.value}
                error={copiedSeller.error}
                onChange={copiedSeller.onChange}
                onInputChange={searchCopiedSellerOptions.searchDebounced}
                isLoading={searchCopiedSellerOptions.searching}
              />
            </div>
            <div>
              <label htmlFor="sellers" className="label">
                Novos Vendedores
              </label>
              <Select
                id="sellers"
                placeholder="Selecione os vendedores que herdarão as comissões do vendedor copiado"
                options={selectOptionsDifference(searchSellerOptions.options, sellers.value)}
                value={sellers.value}
                error={sellers.error}
                onChange={sellers.onChange}
                onInputChange={searchSellerOptions.searchDebounced}
                isLoading={searchSellerOptions.searching}
                isMulti
              />
            </div>
            <div>
              <label htmlFor="supervisor" className="label">
                Novo Supervisor
              </label>
              <Select
                id="supervisor"
                placeholder="Selecione o supervisor que deseja vincular caso seja diferente do vendedor copiado"
                options={searchSupervisorOptions.options}
                value={supervisor.value}
                error={supervisor.error}
                onChange={supervisor.onChange}
                onInputChange={searchSupervisorOptions.searchDebounced}
                isLoading={searchSupervisorOptions.searching}
              />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              className="button"
              onClick={() => {
                cloneCommissions.clone(selectedTable!.idTabela);
              }}>
              Copiar
            </Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <p className="loadingMessage">Copiando comissões do vendedor</p>
        </div>
      )}
    </div>
  );
}
