import React from "react";
import { CaretLeft, CaretRight, CaretDoubleLeft, CaretDoubleRight } from "phosphor-react";

import styles from "./Paginate.module.css";

interface CustomCssProps extends React.CSSProperties {
  [key: string]: any;
}

interface IPaginationProps {
  id?: string;
  classNameContainer?: string;
  defaultColor?: string;
  defaultLightColor?: string;
  totalRecords: number;
  maxItems?: number;
  pageNeighbours?: number;
  currentPage?: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onPageChange: (page: number) => void;
  styleNextPrev?: boolean;
  isDisabled?: boolean;
}

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from: number, to: number, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const Paginate = ({
  id = "",
  classNameContainer,
  defaultColor = "var(--green-2)",
  defaultLightColor = "var(--input-color)",
  totalRecords,
  maxItems = 10,
  pageNeighbours = 1,
  currentPage = 1,
  setCurrentPage,
  onPageChange,
  styleNextPrev = false,
  isDisabled = false,
}: IPaginationProps) => {
  const totalPages = Math.ceil(totalRecords / maxItems);

  const gotoPage = React.useCallback(
    (page: number) => {
      const newPage = Math.max(1, Math.min(page, totalPages));
      if (newPage !== currentPage) {
        setCurrentPage(newPage);
        if (onPageChange) onPageChange(newPage);
      }
    },
    [currentPage, onPageChange, setCurrentPage, totalPages]
  );

  const handleClick = React.useCallback(
    (page: number, event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      gotoPage(page);
    },
    [gotoPage]
  );

  const handleMoveLeft = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      gotoPage(currentPage - pageNeighbours * 2 - 1);
    },
    [currentPage, gotoPage, pageNeighbours]
  );

  const handleMoveRight = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      gotoPage(currentPage + pageNeighbours * 2 + 1);
    },
    [currentPage, gotoPage, pageNeighbours]
  );

  const fetchPageNumbers = React.useCallback(() => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  }, [currentPage, pageNeighbours, totalPages]);

  if (!totalRecords) return null;
  if (totalPages === 1) return null;
  if (maxItems === 0) return null;

  const pages = fetchPageNumbers();

  return (
    <div className={`${styles.container} ${classNameContainer}`} id={id}>
      <nav className={styles.pagination}>
        <ul className={styles.pagesList}>
          {!styleNextPrev && (
            <li>
              <button
                className={`${styles.navigateButton} ${styles.prevPageButton}`}
                style={{ "--default-color": defaultColor } as CustomCssProps}
                type="button"
                onClick={() => gotoPage(currentPage - 1)}
                disabled={isDisabled}>
                <CaretLeft weight="fill" />
              </button>
            </li>
          )}
          {pages.map((page, index) => {
            if (page === LEFT_PAGE) {
              return (
                <li key={index}>
                  <button
                    type="button"
                    className={styles.pageButton}
                    style={
                      { "--default-color": defaultColor, "--default-light-color": defaultLightColor } as CustomCssProps
                    }
                    onClick={handleMoveLeft}
                    disabled={isDisabled}>
                    <CaretDoubleLeft weight="bold" />
                  </button>
                </li>
              );
            }

            if (page === RIGHT_PAGE) {
              return (
                <li key={index}>
                  <button
                    type="button"
                    className={styles.pageButton}
                    style={
                      { "--default-color": defaultColor, "--default-light-color": defaultLightColor } as CustomCssProps
                    }
                    onClick={handleMoveRight}
                    disabled={isDisabled}>
                    <CaretDoubleRight weight="bold" />
                  </button>
                </li>
              );
            }

            return (
              <li key={index}>
                <button
                  className={`${styles.pageButton} ${currentPage === page ? "active" : ""}`}
                  style={
                    { "--default-color": defaultColor, "--default-light-color": defaultLightColor } as CustomCssProps
                  }
                  type="button"
                  onClick={(event) => handleClick(page as number, event)}
                  disabled={isDisabled}>
                  {page}
                </button>
              </li>
            );
          })}
          {!styleNextPrev && (
            <li>
              <button
                className={`${styles.navigateButton} ${styles.nextPageButton}`}
                style={{ "--default-color": defaultColor } as CustomCssProps}
                type="button"
                onClick={() => gotoPage(currentPage + 1)}
                disabled={isDisabled}>
                <CaretRight size={20} weight="fill" />
              </button>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export { Paginate };
