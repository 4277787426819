import React from "react";
import { formatMoney } from "../../../../../helpers/format";
import { usePagination } from "../../../../../hooks/pagination";
import { useDate, useSelect } from "../../../../../hooks/form";
import { DespesasRdv } from "../../components/DespesasRdv";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ViewExpense } from "../ViewExpense/ViewExpense";

export function Refused({ enterpriseOptions }) {
  const navigate = useNavigate();

  const [maxItems, setMaxItems] = React.useState(10);

  const [table, setTable] = React.useState({
    columns: [
      { isSorted: true, objectName: "vendedor", title: "Vendedor" },
      { isSorted: true, objectName: "categoria", title: "Categoria" },
      { isSorted: true, objectName: "nomeEmpresa", title: "Empresa" },
      { isSorted: true, objectName: "valorDespesa", title: "Valor", formatting: formatMoney },
      { isSorted: true, objectName: "valorPago", title: "Valor Pago", formatting: formatMoney },
    ],
    data: [],
  });
  const pagination = usePagination(maxItems);
  const initialDate = useDate({ required: true });
  const finalDate = useDate({ required: true });
  const enterprises = useSelect({ type: "multiple", required: true });
  const [filter, setFilter] = React.useState("");

  const [selectedExpense, setSelectedExpense] = React.useState(null);

  const onClickTableLine = React.useCallback(
    (data) => {
      setSelectedExpense(data);
      navigate("visualizar");
    },
    [navigate]
  );

  React.useEffect(() => {
    setSelectedExpense(null);
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className="container">
            <DespesasRdv
              tipoDespesa={"recusadas"}
              maxItems={{
                value: maxItems,
                setValue: setMaxItems,
              }}
              tableData={table}
              setTableData={setTable}
              onClickTableLine={onClickTableLine}
              pagination={pagination}
              initialDate={initialDate}
              finalDate={finalDate}
              filter={filter}
              setFilter={setFilter}
              enterpriseOptions={enterpriseOptions}
              enterprise={enterprises}
            />
          </div>
        }
      />
      <Route path="visualizar/*" element={<ViewExpense selectedExpense={selectedExpense} setTable={setTable} />} />
    </Routes>
  );
}
