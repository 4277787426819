import React from "react";

import styles from "./Lista.module.css";
import { Input, LinkButton } from "../../../../components/Form";
import { Table } from "../../../../components/Data/XTable";
import { IUseContratados } from "../hooks/types";
import { Paginate } from "../../../../components/Paginate/Paginate";
import { Circle } from "../../../../components/Loading";

interface IProps {
  contratadosData: IUseContratados;
}

export function Lista({ contratadosData }: IProps) {
  const {
    selectedContratado,
    data: { tableData },
    filters: { searchedContratado },
    searchs: {
      pagination,
      searchingContratados,
      searchContratadosPaginated,
      searchContratadosTotalRecordsAndContratadosDebounced,
    },
  } = contratadosData;

  React.useEffect(() => {
    selectedContratado.setValue(null);
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.navContainer}>
        <LinkButton to="/contratos-fornecedor-cliente/contratado/novo" className={styles.navButton}>
          Novo Contratado
        </LinkButton>
      </div>
      <div>
        <div>
          <label htmlFor="searchedContratado" className="label">
            Descrição
          </label>
          <Input
            id="searchedContratado"
            placeholder="Digite a descrição que deseja filtrar"
            value={searchedContratado.value}
            onChange={(e) => {
              searchedContratado.onChange(e);
              searchContratadosTotalRecordsAndContratadosDebounced(e.target.value);
            }}
          />
        </div>
      </div>
      <div className={styles.dataContainer}>
        <div
          className={`${styles.tableContainer} customScrollbar`}
          style={{ display: tableData.data.length && !searchingContratados ? "block" : "none" }}>
          <Table tableData={tableData} />
        </div>
        {searchingContratados ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
          </div>
        ) : !tableData.data.length ? (
          <p className={`lineCardMessage`}>Nenhum Contratado Cadastrado</p>
        ) : null}
      </div>
      {pagination.totalPages > 1 && (
        <div className={styles.paginationContainer}>
          <Paginate
            maxItems={pagination.maxItems}
            totalRecords={pagination.totalRecords}
            currentPage={pagination.currentPage}
            setCurrentPage={pagination.setCurrentPage}
            onPageChange={(page) => {
              searchContratadosPaginated(page - 1, searchedContratado.value);
            }}
          />
          <p>Total de {pagination.totalRecords} registros</p>
        </div>
      )}
    </div>
  );
}
