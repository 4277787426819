import React from "react";
import styles from "./Modal.module.css";

export function Modal({ children, className, open }) {
  return (
    <div className={`${styles.container} ${open ? "show" : ""}`}>
      <div className={`${styles.modal} ${className}`}>{children}</div>
    </div>
  );
}
