import React from "react";
import { Button, Select } from "../../../../components/Form";
import { useSelect } from "../../../../hooks/form";

import styles from "./ValidityRange.module.css";

interface IRange {
  rgb: string;
  margem: number | string;
  selected: boolean;
}

const rangeListMock = [
  { rgb: "rgb(118, 0, 169)", margem: 1, selected: false },
  { rgb: "rgb(0, 0, 0)", margem: 3, selected: false },
  { rgb: "rgb(255, 0, 0)", margem: 5, selected: false },
  { rgb: "rgb(255, 255, 0)", margem: 8, selected: false },
  { rgb: "rgb(0, 128, 0)", margem: 12, selected: false },
  { rgb: "rgb(0, 0, 255)", margem: 16, selected: false },
];

export function CommissionValidityRange() {
  const table = useSelect({ type: "single", required: true });
  const formula = useSelect({ type: "single", required: true });
  const validity = useSelect({ type: "single", required: true });

  const [rangeList, setRangeList] = React.useState<IRange[]>([]);

  const onSelectRange = (rangeReference: IRange, state: boolean) => {
    rangeReference.selected = state;
    setRangeList(JSON.parse(JSON.stringify(rangeList)));
  };

  React.useEffect(() => {
    setRangeList(rangeListMock);
  }, []);

  return (
    <div className="container">
      <div>
        <div className={styles.searchFieldsContainer}>
          <div className={styles.searchFieldsContainer__tableFieldContainer}>
            <label htmlFor="table" className="label">
              Tabela
            </label>
            <Select
              id="table"
              placeholder="Selecione uma tabela"
              value={table.value}
              onChange={table.onChange}
              defaultValue={undefined}
              error={table.error}
              options={[]}
            />
          </div>
          <div>
            <label htmlFor="formula" className="label">
              Fórmula
            </label>
            <Select
              id="formula"
              placeholder="Selecione uma formula"
              value={formula.value}
              onChange={formula.onChange}
              defaultValue={undefined}
              error={formula.error}
              options={[]}
            />
          </div>
          <div>
            <label htmlFor="validity" className="label">
              Vigência
            </label>
            <Select
              id="validity"
              placeholder="Selecione uma vigência"
              value={validity.value}
              onChange={validity.onChange}
              defaultValue={undefined}
              error={validity.error}
              options={[]}
            />
          </div>
        </div>
        <div className={styles.searchButtonContainer}>
          <Button>Buscar Faixas</Button>
        </div>
      </div>
      {rangeList.length ? (
        <div className={styles.rangesMainContainer}>
          <div className={styles.rangeOptionsContainer}>
            <p className={styles.rangeTitle}>Faixas</p>
            <span className={styles.separator}></span>
            <ul className={styles.rangeOptionsContainer__list}>
              {rangeList.map((item, index) => (
                <li key={index}>
                  <label className={styles.ballContainer}>
                    <span
                      className={styles.ballContainer__ball}
                      style={{ borderColor: item.rgb, backgroundColor: item.selected ? item.rgb : undefined }}>
                      <input
                        type="checkbox"
                        checked={item.selected}
                        onChange={({ target: { checked } }) => {
                          onSelectRange(item, checked);
                        }}
                        style={{ visibility: "hidden" }}
                      />
                    </span>
                    <p className={styles.ballContainer__margin}>{item.margem}% de Margem</p>
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.rangesSelectedContainer}>
            <p className={styles.rangeTitle}>Faixas Selecionadas</p>
            <span className={styles.separator}></span>
            {rangeList.filter((range) => range.selected).length ? (
              <ul className={styles.rangesSelectedContainer__list}>
                {rangeList
                  .filter((item) => item.selected)
                  .sort((a, b) => Number(a.margem) - Number(b.margem))
                  .map((item, index) => (
                    <li key={index}>
                      <div className={styles.ballContainer}>
                        <span
                          className={styles.ballContainer__ball}
                          style={{ borderColor: item.rgb, backgroundColor: item.rgb }}
                        />
                        <p className={styles.ballContainer__margin}>{item.margem}% de Margem</p>
                      </div>
                    </li>
                  ))}
              </ul>
            ) : (
              <span className={`lineCardMessage ${styles.customLineCardMessage}`}>Nenhuma faixa selecionada</span>
            )}
          </div>
        </div>
      ) : (
        <span className="lineCardMessage">Busque as faixas para prosseguir</span>
      )}
    </div>
  );
}
