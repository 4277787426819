import React from "react";
import { Button, Input, LinkButton, Select } from "../../../../components/Form";

import styles from "./AdicionarAtualizar.module.css";
import { useAdicionarAtualizar } from "./hooks/useAdicionarAtualizar";
import { Circle } from "../../../../components/Loading";
import { IPenalidade, IUsePenalidades } from "../hooks/types";
import { useNavigate } from "react-router-dom";

interface IProps {
  tipo: "insert" | "update";
  penalidadesData: IUsePenalidades;
  selectedPenalidade?: IPenalidade | null;
}

export function AdicionarAtualizar({ tipo, penalidadesData, selectedPenalidade }: IProps) {
  const navigate = useNavigate();

  const {
    carregarDados,
    form: { nomePenalidade, status },
    selectOptions: { statusOptions },
    interactions: { insertOrUpdatePenalidade, insertingOrUpdatingPenalidade },
  } = useAdicionarAtualizar({ penalidadesData });

  React.useEffect(() => {
    if (tipo === "update" && selectedPenalidade) {
      if (!selectedPenalidade) navigate("/contratos-fornecedor-cliente/penalidades");
      else carregarDados(selectedPenalidade);
    }
  }, []); // eslint-disable-line

  return (
    <div className="container">
      {!insertingOrUpdatingPenalidade ? (
        <>
          <div>
            <LinkButton
              to="/contratos-fornecedor-cliente/penalidades"
              buttonStyle="backButton"
              className={styles.navButton}
            />
          </div>
          <span className="separator" />
          <div className={styles.fieldsContainer}>
            <div>
              <label htmlFor="nomePenalidade" className="label">
                Nome Penalidade
              </label>
              <Input
                id="nomePenalidade"
                placeholder="Digite o nome da penalidade"
                value={nomePenalidade.value}
                error={nomePenalidade.error}
                onChange={nomePenalidade.onChange}
                onBlur={nomePenalidade.onBlur}
              />
            </div>
            {tipo === "update" && (
              <div className={styles.statusContainer}>
                <label htmlFor="status" className="label">
                  Situação
                </label>
                <Select
                  id="status"
                  options={statusOptions}
                  value={status.value}
                  onChange={status.onChange}
                  isClearable={false}
                  isSearchable={false}
                />
              </div>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => {
                if (tipo === "insert") insertOrUpdatePenalidade();
                else insertOrUpdatePenalidade(selectedPenalidade?.idPenalidade);
              }}>
              Concluir
            </Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <p className="loadingMessage">{tipo === "insert" ? "Inserindo Nova Penalidade" : "Atualizando Penalidade"}</p>
        </div>
      )}
    </div>
  );
}
