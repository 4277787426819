import React from "react";

import { Navigate, useNavigate } from "react-router-dom";

import { Button, Input, LinkButton, Select } from "../../../../components/Form";

import { useForm, useSelect } from "../../../../hooks/form";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useChanges, useModal } from "../../../../hooks/contexts";

import { isValid } from "../../../../helpers/validations";

import { Circle } from "../../../../components/Loading";

import styles from "./UpdatePacking.module.css";

export function UpdatePacking({ researchPackagings, selectedPacking, filteredPacking }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const { setIsChangesDetected } = useChanges();
  const updatingPacking = useLoading();
  const navigate = useNavigate();

  const packing = useForm({ type: "", required: true });
  const conversionFactor = useForm({ type: "", required: true });
  const status = useSelect({ type: "single", required: true });

  const optionsStatus = [
    {
      value: true,
      label: "ATIVO",
    },
    {
      value: false,
      label: "INATIVO",
    },
  ];

  const updatePacking = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const json = await customFetch("/products/registerAndUpdatePacking", {
          body: {
            idEmbalagem: selectedPacking.idEnvase,
            descricao: packing.value,
            fatorConversao: Number(conversionFactor.value),
            ativo: status.value.value,
          },
        });
        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
  }, [customFetch, selectedPacking, packing, conversionFactor, status]);

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (isValid(packing, conversionFactor)) {
        try {
          updatingPacking.setLoading(true);
          const json = await updatePacking();
          if (json.status === 200) {
            setIsChangesDetected(false);
            researchPackagings(filteredPacking);
            await Modal.success(json.message);
            navigate("/produtos/embalagens");
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          updatingPacking.setLoading(false);
        }
      }
    },
    [
      packing,
      conversionFactor,
      updatingPacking,
      updatePacking,
      setIsChangesDetected,
      researchPackagings,
      filteredPacking,
      Modal,
      navigate,
    ]
  );

  React.useEffect(() => {
    if (selectedPacking) {
      packing.setValue(selectedPacking.descricaoEnvase || "");
      conversionFactor.setValue(selectedPacking.fatorConversao);
      status.setValue(() => {
        return selectedPacking.situacao ? optionsStatus[0] : optionsStatus[1];
      });
    }
  }, []); //eslint-disable-line

  if (!selectedPacking || !selectedPacking.idEnvase) {
    return <Navigate to="/produtos/embalagens" />;
  }

  return (
    <div className={`container ${styles.container}`}>
      {!updatingPacking.isLoading ? (
        <>
          <div>
            <LinkButton to="/produtos/embalagens" buttonStyle="backButton" />
          </div>
          <div>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className={styles.fieldsContainer}>
                <div className={styles.packingDescriptionContainer}>
                  <label htmlFor="packing" className="label">
                    Descrição
                  </label>
                  <Input id="packing" value={packing.value} readOnly disabled />
                </div>
                <div>
                  <label htmlFor="conversionFactor" className="label">
                    Fator de Conversão
                  </label>
                  <Input
                    className={`cleanInputNumber`}
                    type="number"
                    id="conversionFactor"
                    value={conversionFactor.value}
                    error={conversionFactor.error}
                    onChange={(value) => {
                      conversionFactor.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    onBlur={conversionFactor.onBlur}
                  />
                </div>
                <div>
                  <label htmlFor="status" className="label">
                    Status da Embalagem
                  </label>
                  <Select
                    id="status"
                    options={optionsStatus}
                    value={status.value}
                    onChange={(value) => {
                      status.onChange(value);
                      setIsChangesDetected(true);
                    }}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
              </div>
              <div className={styles.submitButtonContainer}>
                <Button>Atualizar Embalagem</Button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Atualizando Embalagem</span>
        </div>
      )}
    </div>
  );
}
