import React from "react";
import { Button, Input, LinkButton, Select } from "../../../../components/Form";

import styles from "./AdicionarAtualizar.module.css";
import { useAdicionarAtualizar } from "./hooks/useAdicionarAtualizar";
import { Circle } from "../../../../components/Loading";
import { IExigencia, IUseExigencia } from "../hooks/types";
import { useNavigate } from "react-router-dom";

interface IProps {
  tipo: "insert" | "update";
  exigenciaData: IUseExigencia;
  selectedExigencia?: IExigencia | null;
}

export function AdicionarAtualizar({ tipo, exigenciaData, selectedExigencia }: IProps) {
  const navigate = useNavigate();

  const {
    carregarDados,
    form: { nomeExigencia, status },
    selectOptions: { statusOptions },
    interactions: { insertOrUpdateExigencia, insertingOrUpdatingExigencia },
  } = useAdicionarAtualizar({ exigenciaData });

  React.useEffect(() => {
    if (tipo === "update" && selectedExigencia) {
      if (!selectedExigencia) navigate("/contratos-fornecedor-cliente/exigencias");
      else carregarDados(selectedExigencia);
    }
  }, []); // eslint-disable-line

  return (
    <div className="container">
      {!insertingOrUpdatingExigencia ? (
        <>
          <div>
            <LinkButton
              to="/contratos-fornecedor-cliente/exigencias"
              buttonStyle="backButton"
              className={styles.navButton}
            />
          </div>
          <span className="separator" />
          <div className={styles.fieldsContainer}>
            <div>
              <label htmlFor="nomeExigencia" className="label">
                Nome Exigência
              </label>
              <Input
                id="nomeExigencia"
                placeholder="Digite o nome da exigência"
                value={nomeExigencia.value}
                error={nomeExigencia.error}
                onChange={nomeExigencia.onChange}
                onBlur={nomeExigencia.onBlur}
              />
            </div>
            {tipo === "update" && (
              <div className={styles.statusContainer}>
                <label htmlFor="status" className="label">
                  Situação
                </label>
                <Select
                  id="status"
                  options={statusOptions}
                  value={status.value}
                  onChange={status.onChange}
                  isClearable={false}
                  isSearchable={false}
                />
              </div>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => {
                if (tipo === "insert") insertOrUpdateExigencia();
                else insertOrUpdateExigencia(selectedExigencia?.idExigencia);
              }}>
              Concluir
            </Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <p className="loadingMessage">{tipo === "insert" ? "Inserindo Nova Exigência" : "Atualizando Exigência"}</p>
        </div>
      )}
    </div>
  );
}
