import React from "react";
import { Button, LinkButton } from "../../../../components/Form";
import { IContrato, IUseContrato } from "../hooks/types";
import { useNavigate } from "react-router-dom";

import styles from "./ContratoAnexado.module.css";
import { useContratoAnexado } from "./hooks/useContratoAnexado";
import { Circle } from "../../../../components/Loading";

interface IProps {
  selectedContrato: IContrato | null;
  contratosData: IUseContrato;
}

export function ContratoAnexado({ selectedContrato, contratosData }: IProps) {
  const navigate = useNavigate();

  const { file, handleUpload, uploading } = useContratoAnexado({ contratosData });

  const inputFileRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!selectedContrato) {
      navigate("/contratos-fornecedor-cliente/contrato");
    }
  }, []); // eslint-disable-line

  return (
    <div className="container">
      {!uploading ? (
        <>
          <div>
            <LinkButton buttonStyle="backButton" to="/contratos-fornecedor-cliente/contrato" />
          </div>
          <span className="separator" />
          <div>
            <form className={styles.anexarContainer} onSubmit={handleUpload}>
              <input
                type="text"
                name="id"
                value={selectedContrato?.idContrato}
                onChange={() => {}}
                style={{ display: "none" }}
              />
              <div className={styles.inputFileContainer}>
                <input
                  type="file"
                  name="arquivo"
                  ref={inputFileRef}
                  style={{ display: "none" }}
                  onChange={file.onChange}
                />
                <button
                  type="button"
                  className={styles.inputFileContainer__button}
                  onClick={() => inputFileRef.current?.click()}>
                  Selecionar Arquivo
                </button>
                {file.value ? (
                  <p className={styles.inputFileContainer__dadosArquivo}>
                    <span>{file.value?.name}</span>
                  </p>
                ) : (
                  <p>Nenhum Arquivo Selecionado</p>
                )}
              </div>
              <Button type="submit">Salvar</Button>
            </form>
            <div className={styles.arquivoContainer}>
              {selectedContrato?.urlAquivoAssinado ? (
                <a
                  href={selectedContrato?.urlAquivoAssinado}
                  download={"contrato-em-anexo"}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.linkDownload}>
                  Fazer Download do Arquivo em Anexo
                </a>
              ) : (
                <p className={`lineCardMessage`}>Nenhum Arquivo de Contrato Anexado</p>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <p className="loadingMessage">Fazendo Uploading do Arquivo</p>
        </div>
      )}
    </div>
  );
}
