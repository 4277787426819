import React from "react";
import { Route, Routes } from "react-router-dom";
import { useSolicitationList } from "./hooks/useSolicitationList";
import { List } from "./List/List";
import { ISolicitation } from "./types";
import { Analysis } from "./Analysis/Analysis";
import { SolicitationStatus } from "../../types";
import useContractExcel from "../../hooks/useContractExcel";

export function ContractsCommercialSolicitations() {
  const [selectedSolicitation, setSelectedSolicitation] =
    React.useState<ISolicitation | null>(null);

  const solicitationList = useSolicitationList();
  const contractExcel = useContractExcel();

  React.useEffect(() => {
    solicitationList.searchTotalSolicitationRecordsAndSolicitations("", [
      SolicitationStatus.AprovadoCliente,
      SolicitationStatus.Revisado,
    ]);
  }, []); // eslint-disable-line

  return (
    <Routes>
      <Route
        path="/"
        element={
          <List
            solicitationList={solicitationList}
            setSelectedSolicitation={setSelectedSolicitation}
            contractExcel={contractExcel}
          />
        }
      />
      <Route
        path="analise"
        element={
          <Analysis
            selectedSolicitation={selectedSolicitation}
            solicitationList={solicitationList}
            contractExcel={contractExcel}
          />
        }
      />
    </Routes>
  );
}
