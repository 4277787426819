import { isJSONObject } from "../../helpers/JSONVerifier";
import { useAuth } from "../contexts";

interface ICustomRequestInit extends RequestInit {
  body?: any;
}

const useCustomFetch = () => {
  const Auth = useAuth();

  return async (url: string, { method, headers, body, ...options }: ICustomRequestInit): Promise<any> => {
    if (!method) method = "POST";
    if (!headers) headers = { "Content-Type": "application/json" };
    if (!body) body = null;
    else if (isJSONObject(body)) body = JSON.stringify(body);
    try {
      const response = await fetch(url, {
        method,
        headers,
        body,
        ...options,
      });

      const responseType = response.headers.get("content-type");

      let content: { [key: string]: any };
      if (responseType?.includes("application/json")) {
        content = await response.json();
      } else {
        content = {
          status: 200,
          object: await response.blob(),
        };
      }

      if (content.status === 401 || content.message === "Unauthorized") {
        Auth.signOut();
        return { status: 401 };
      }

      return content;
    } catch (error: any) {
      switch (error.message) {
        case "Failed to fetch":
          throw new Error("Sem conexão com a internet!").message;

        default:
          throw error;
      }
    }
  };
};

export { useCustomFetch };
