import React, { useLayoutEffect } from "react";

import { IContractComission } from "../../ContractCommissionsTable";
import { Button, LinkButton, Select } from "../../../../../../components/Form";
import { useCloneCommissionsToSellers } from "./hooks/useCloneCommissionsToSellers";

import styles from "./CloneCommissionsToSellers.module.css";
import { Circle } from "../../../../../../components/Loading";
import { selectOptionsDifference } from "../../../../../../helpers/List";

interface Props {
  selectedContract: IContractComission | null;
}

export function CloneCommissionsToSellers({ selectedContract }: Props) {
  const { copiedSeller, sellers, searchCopiedSellerOptions, searchSellerOptions, cloneCommissions } =
    useCloneCommissionsToSellers();

  useLayoutEffect(() => {
    searchCopiedSellerOptions.search();
    searchSellerOptions.search();
  }, []); // eslint-disable-line

  return (
    <div className="container">
      {!cloneCommissions.cloning ? (
        <>
          <div className={styles.navContainer}>
            <LinkButton to="/comissoes/adubo/comissoes-de-contratos/itens" buttonStyle="backButton" />
            <h2>{selectedContract?.descricao}</h2>
            <div />
          </div>
          <span className="separator" />
          <div className={styles.fieldsContainer}>
            <div>
              <label htmlFor="copiedSeller" className="label">
                Vendedor Copiado
              </label>
              <Select
                id="copiedSeller"
                placeholder="Selecione o vendedor que será copiado"
                options={searchCopiedSellerOptions.options}
                value={copiedSeller.value}
                error={copiedSeller.error}
                onChange={copiedSeller.onChange}
                onInputChange={searchCopiedSellerOptions.searchDebounced}
                isLoading={searchCopiedSellerOptions.searching}
              />
            </div>
            <div>
              <label htmlFor="sellers" className="label">
                Novos Vendedores
              </label>
              <Select
                id="sellers"
                placeholder="Selecione os vendedores que herdarão as comissões do vendedor copiado"
                options={selectOptionsDifference(searchSellerOptions.options, sellers.value)}
                value={sellers.value}
                error={sellers.error}
                onChange={sellers.onChange}
                onInputChange={searchSellerOptions.searchDebounced}
                isLoading={searchSellerOptions.searching}
                isMulti
              />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              className="button"
              onClick={() => {
                cloneCommissions.clone(selectedContract!.idContrato, selectedContract!.empresa.idEmpresa);
              }}>
              Copiar
            </Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <p className="loadingMessage">Copiando comissões do vendedor</p>
        </div>
      )}
    </div>
  );
}
