import { useCallback, useMemo } from "react";
import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { useForm, useSelect } from "../../../../../hooks/form";
import { isValid } from "../../../../../helpers/validations";
import { IUseAdicionarAtualizar, Status, StatusOptions } from "./types";
import { IExigencia, IUseExigencia } from "../../hooks/types";
import { useNavigate } from "react-router-dom";

interface IProps {
  exigenciaData: IUseExigencia;
}

export function useAdicionarAtualizar({ exigenciaData }: IProps): IUseAdicionarAtualizar {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const nomeExigencia = useForm({ required: true });
  const status = useSelect<Status>({ required: true, type: "single", defaultValue: { value: "A", label: "Ativo" } });

  const statusOptions = useMemo<StatusOptions>(
    () => [
      { value: "A", label: "Ativo" },
      { value: "I", label: "Inativo" },
    ],
    []
  );

  const insertingOrUpdatingExigencia = useLoading();

  const carregarDados = useCallback(
    (selectedExigencia: IExigencia) => {
      nomeExigencia.setValue(selectedExigencia.nomeExigencia);
      status.setValue(() => {
        if (selectedExigencia.status === "A") return statusOptions[0];
        else return statusOptions[1];
      });
    },
    [nomeExigencia, status, statusOptions]
  );

  const insertOrUpdateExigencia = useCallback(
    async (id: number = 0) => {
      if (!isValid(nomeExigencia, status)) return;
      const body = {
        idExigencia: id,
        nomeExigencia: nomeExigencia.value,
        status: status.value?.value,
      };
      try {
        insertingOrUpdatingExigencia.setLoading(true);
        const json = await customFetch(`/juridico/insertAndUpdateExigencia`, {
          body,
        });
        if (json.status === 200) {
          exigenciaData.searchs.searchExigenciasPaginated("");
          await Modal.success(json.message);
          navigate("/contratos-fornecedor-cliente/exigencias");
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        insertingOrUpdatingExigencia.setLoading(false);
      }
    },
    [Modal, customFetch, insertingOrUpdatingExigencia, nomeExigencia, status, exigenciaData.searchs, navigate]
  );

  return {
    carregarDados,
    form: {
      nomeExigencia,
      status,
    },
    selectOptions: {
      statusOptions,
    },
    interactions: {
      insertOrUpdateExigencia,
      insertingOrUpdatingExigencia: insertingOrUpdatingExigencia.isLoading,
    },
  };
}
