import React from "react";

import { useCustomFetch, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";

import { LinkButton } from "../../../../../components/Form";
import { Timeline, TimelineItem, TimelineDot, TimelineContent } from "../../../../../components/Data/Timeline";

import { Circle } from "../../../../../components/Loading";
import styles from "./Historical.module.css";
import { Navigate } from "react-router-dom";
import { formatDate } from "../../../../../helpers/format";
import { Collapse } from "../../../../../components/Collapse/Collapse";

const getStatus = (status) => {
  let string = "";

  switch (status) {
    case 2:
      string = "No Comercial, aguardando aprovação.";
      break;

    case 3:
      string = "Reprovado no Comercial.";
      break;

    case 4:
      string = "No Financeiro, aguardando pagamento.";
      break;

    case 5:
      string = "Recusado no Financeiro";
      break;

    case 6:
      string = "Premiação paga.";
      break;

    default:
      string = "Desconhecido.";
      break;
  }

  return string;
};

export function Historical({ selectedRequest, getStatusList, sector, page }) {
  const urlPage = React.useMemo(() => {
    if (page === "general") {
      return "geral";
    } else if (page === "analysisCommercial") {
      return "em-analise-comercial";
    } else if (page === "analysisFinancial") {
      return "em-analise-financeiro";
    } else if (page === "paid") {
      return "pagos";
    } else if (page === "rejected") {
      return "reprovados";
    }
    return "";
  }, [page]);

  const customFetch = useCustomFetch();
  const Modal = useModal();

  const [historyData, setHistoryData] = React.useState([]);

  const searchingHistory = useLoading();

  const searchRequestHistory = React.useCallback(async () => {
    let url = "";
    if (sector === "manager") {
      url = "/premiations/historical/manager/searchRequestHistorical";
    } else if (sector === "commercial") {
      url = "/premiations/historical/commercial/searchRequestHistorical";
    } else if (sector === "financial") {
      url = "/premiations/historical/financial/searchRequestHistorical";
    }

    try {
      searchingHistory.setLoading(true);
      const json = await customFetch(url, {
        body: {
          idSolicitacao: Number(selectedRequest.solicitacoes.idSolicitacao),
          status: getStatusList(),
        },
      });
      if (json.status === 200) {
        setHistoryData(json.object);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingHistory.setLoading(false);
    }
  }, [Modal, customFetch, searchingHistory, selectedRequest, sector, getStatusList]);

  React.useEffect(() => {
    if (selectedRequest !== null) {
      searchRequestHistory();
    }
  }, []); // eslint-disable-line

  if (!selectedRequest) {
    if (sector === "manager") {
      return <Navigate to={`/premiacoes/gerente/rastreabilidade/${urlPage}`} />;
    } else if (sector === "commercial") {
      return <Navigate to={`/premiacoes/comercial/rastreabilidade/${urlPage}`} />;
    } else if (sector === "financial") {
      return <Navigate to={`/premiacoes/financeiro/rastreabilidade/${urlPage}`} />;
    }
  }

  return (
    <div className="container">
      <div className={styles.navigationContainer}>
        <div>
          {sector === "manager" ? (
            <LinkButton to={`/premiacoes/gerente/rastreabilidade/${urlPage}`} buttonStyle="backButton" />
          ) : sector === "commercial" ? (
            <LinkButton to={`/premiacoes/comercial/rastreabilidade/${urlPage}`} buttonStyle="backButton" />
          ) : sector === "financial" ? (
            <LinkButton to={`/premiacoes/financeiro/rastreabilidade/${urlPage}`} buttonStyle="backButton" />
          ) : null}
        </div>
        <h2 className={styles.navigationContainer__header}>
          {selectedRequest.solicitacoes.idSolicitacao} - {selectedRequest.usuario.nome}
        </h2>
        <div></div>
      </div>
      <div className={styles.contentContainer}>
        {historyData.length && !searchingHistory.isLoading ? (
          <div className={styles.collapsesContainer}>
            {historyData.map((premiation, index) => (
              <Collapse
                key={index}
                classNameMainContainer={styles.collapseContainer}
                classNameButton={styles.premiationCollapse}
                label={
                  <div className={styles.collapseInfoContainer}>
                    <p>
                      Premiação: <span>{premiation.premiacao}</span>
                    </p>
                    <p>
                      Status Atual:{" "}
                      <span>{premiation.status ? getStatus(Number(premiation.status.status)) : "Desconhecido."}</span>
                    </p>
                  </div>
                }
                iconColor="var(--gray-2)"
              >
                <Timeline className={styles.timelineContainer}>
                  {premiation.lista.map((item, index) => (
                    <TimelineItem key={index}>
                      <TimelineDot />
                      <TimelineContent>
                        <p dangerouslySetInnerHTML={{ __html: item.historico.descricao }}></p>
                        <time
                          style={{
                            marginTop: "15px",
                            borderLeft: "2px solid var(--gray-8)",
                            borderRight: "2px solid var(--gray-8)",
                            padding: "0 2px",
                          }}
                        >
                          {formatDate(item.historico.dataInsercao, "Month, dd de yyyy")}
                        </time>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </Collapse>
            ))}
          </div>
        ) : searchingHistory.isLoading ? (
          <div className={`loadingContainer`}>
            <Circle size={100} />
            <span className="loadingMessage"></span>
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhum histórico registrado</p>
        )}
      </div>
    </div>
  );
}
