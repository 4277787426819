import React from "react";
import { Button, Input, LinkButton, Select } from "../../../../components/Form";
import { useForm, useSelect } from "../../../../hooks/form";

import styles from "./NewSupplier.module.css";
import { useCustomFetch, useDebounce, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { useNavigate } from "react-router-dom";
import { isValid } from "../../../../helpers/validations";
import { Circle } from "../../../../components/Loading";
import { InputMask } from "../../../../components/Form/InputMask/InputMask";

export function NewSupplier({ searchTotalSupplierRecordsAndSuppliers }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const savingSupplier = useLoading();
  const searchingCities = useLoading();

  const corporateName = useForm({ type: "text", required: true });
  const cnpj = useForm({ type: "text", required: true });
  const address = useForm({ type: "text", required: true });
  const city = useSelect({ type: "single", required: true });

  const [cityOptions, setCityOptions] = React.useState([]);

  const searchCities = React.useCallback(
    async (city = "") => {
      try {
        searchingCities.setLoading(true);
        const json = await customFetch("/rdv/searchCities", {
          body: {
            pagina: 0,
            tamanho: 100,
            idCidade: 0,
            idIBGE: "",
            nomeCidade: city,
            uf: "",
            cep: "",
            cepSecundario: "",
            latitude: 0,
            longitude: 0,
            idPais: 0,
            nomePais: "BRASIL",
          },
        });
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            value: option,
            label: `${option.nomeCidade} | ${option.uf}`,
          }));
          setCityOptions(options);
        } else if (json.status === 500) {
          setCityOptions([]);
          Modal.error(json.message, json.object);
        } else {
          setCityOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingCities.setLoading(false);
      }
    },
    [Modal, customFetch, searchingCities]
  );

  const searchCitiesDebounced = useDebounce(searchCities);

  const onSaveNewSupplier = React.useCallback(async () => {
    if (isValid(corporateName, cnpj, address, city)) {
      try {
        savingSupplier.setLoading(true);
        const json = await customFetch("/rdv/insertAndUpdateSupplier", {
          body: {
            nome: corporateName.value,
            cnpj: cnpj.value,
            endereco: address.value,
            idCidade: Number(city.value.value.idCidade),
            status: "A",
          },
        });
        if (json.status === 200) {
          searchTotalSupplierRecordsAndSuppliers();
          await Modal.success(json.message);
          navigate("/rdv/fornecedores");
        } else {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        savingSupplier.setLoading(false);
      }
    }
  }, [
    Modal,
    address,
    city,
    cnpj,
    corporateName,
    customFetch,
    navigate,
    savingSupplier,
    searchTotalSupplierRecordsAndSuppliers,
  ]);

  React.useEffect(() => {
    searchCities();
  }, []); // eslint-disable-line

  return (
    <div className="container">
      {!savingSupplier.isLoading ? (
        <>
          <div className={`${styles.navContainer}`}>
            <LinkButton to="/rdv/fornecedores" buttonStyle="backButton" />
          </div>
          <div className={`${styles.fieldsContainer}`}>
            <div>
              <label className="label">Razão Social</label>
              <Input
                placeholder="Digite a razão social"
                value={corporateName.value}
                error={corporateName.error}
                onChange={corporateName.onChange}
                onBlur={corporateName.onBlur}
              />
            </div>
            <div>
              <label className="label">CNPJ</label>
              <InputMask
                placeholder="XX.XXX.XXX/XXXX-XX"
                value={cnpj.value}
                error={cnpj.error}
                onChange={cnpj.onChange}
                onBlur={cnpj.onBlur}
                mask={"99.999.999/9999-99"}
              />
            </div>
            <div>
              <label className="label" htmlFor="address">
                Endereço
              </label>
              <Input
                id="address"
                placeholder="Digite o endereço"
                value={address.value}
                error={address.error}
                onChange={address.onChange}
                onBlur={address.onBlur}
              />
            </div>
            <div>
              <label className="label" htmlFor="city">
                Cidade
              </label>
              <Select
                id="city"
                placeholder="Selecione a cidade"
                options={cityOptions}
                value={city.value}
                error={city.error}
                onChange={city.onChange}
                onBlur={city.onBlur}
                onInputChange={(value) => {
                  searchCitiesDebounced(value);
                }}
                isLoading={searchingCities.isLoading}
              />
            </div>
          </div>
          <div className={`${styles.saveButtonContainer}`}>
            <Button onClick={onSaveNewSupplier}>Salvar</Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Salvando novo fornecedor</span>
        </div>
      )}
    </div>
  );
}
