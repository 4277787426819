import React, { useCallback, useState, useMemo } from "react";
import { useCustomFetch, useDebounce, useLoading } from "../../../../hooks/async";
import { usePagination } from "../../../../hooks/pagination";
import {
  SearchContratosResponse,
  IContrato,
  SearchContratosTotalRecordsResponse,
  IUseContrato,
  StatusContrato,
  QtdDiasVencimentoOption,
} from "./types";
import { useModal } from "../../../../hooks/contexts";
import { ITableData } from "../../../../components/Data/XTable";
import { Button } from "../../../../components/Form";

import styles from "../Lista/Lista.module.css";
import { Pencil, File } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../../helpers/format";
import { useDate, useForm, useSelect } from "../../../../hooks/form";

const labelStatus = (status: StatusContrato) => {
  switch (status) {
    case "A":
      return "Ativo";
    case "I":
      return "Não renovado (ambas as partes)";
    case "E":
      return "Não renovado pelo contratante";
    case "O":
      return "Não renovado pelo contratado";
    case "R":
      return "Rescisão pelo contratante";
    case "X":
      return "Rescisão pelo contratado";
    default:
      return "Todos";
  }
};

export function useContrato(): IUseContrato {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const pagination = usePagination(10);

  const [selectedContrato, setSelectedContrato] = useState<IContrato | null>(null);

  const nomeCnpj = useForm({ required: false });
  const qtdDiasVencimento = useForm({ required: false });
  const qtdDiasVencimentoOption = useState<QtdDiasVencimentoOption>(">");
  const statusContrato = useSelect<StatusContrato>({
    type: "single",
    required: false,
    defaultValue: {
      label: "Todos",
      value: "",
    },
  });
  const inicioVigencia = useDate({ required: false });
  const terminoVigencia = useDate({ required: false });

  const statusContratoOptions = useMemo<ISelectOption<StatusContrato>[]>(
    () => [
      {
        label: "Todos",
        value: "",
      },
      {
        label: "Ativo",
        value: "A",
      },
      {
        label: "Não renovado (ambas as partes)",
        value: "I",
      },
      {
        label: "Não renovado pelo contratante",
        value: "E",
      },
      {
        label: "Não renovado pelo contratado",
        value: "O",
      },
      {
        label: "Rescisão pelo contratante",
        value: "R",
      },
      {
        label: "Rescisão pelo contratado",
        value: "X",
      },
    ],
    []
  );

  const [contratos, setContratos] = useState<IContrato[]>([]);

  const tableData: ITableData = useMemo(() => {
    const data = contratos.map((contrato) => ({
      ...contrato,
      nomeContratado: contrato.contratado.nomeContratado,
      cnpjContratado: contrato.contratado.cnpjContratado,
      objetoContrato: contrato.objeto.nomeObjeto,
      periodicidadePagamento: contrato.periodicidadePagto.nomePeriodicidade,
      formaPagamento: contrato.formaPagto.nomeFormaPagto,
      exigenciaPagamento: contrato.exigencia.nomeExigencia,
      gestorContrato: contrato.gestor.nome,
      penalidadesContratuais: contrato.penalidade.nomePenalidade,
      bolinhaStatus: (
        <div className={styles.bolinhaStatusContainer}>
          <span style={{ backgroundColor: contrato.corAlerta }} />
        </div>
      ),
      editar: (
        <div className={styles.optionsContainer}>
          <Button
            type="button"
            variant="edit"
            className={styles.editButton}
            onClick={() => {
              setSelectedContrato(contrato);
              navigate("anexo");
            }}
            data-option-button>
            <File weight="fill" />
          </Button>
          <Button
            type="button"
            variant="edit"
            className={styles.editButton}
            onClick={() => {
              setSelectedContrato(contrato);
              navigate("atualizar");
            }}
            data-option-button>
            <Pencil weight="fill" />
          </Button>
        </div>
      ),
    }));
    return {
      columns: [
        {
          title: "",
          objectName: "bolinhaStatus",
          isSorted: false,
        },
        {
          title: "CONTRATADO",
          objectName: "nomeContratado",
          isSorted: true,
        },
        {
          title: "CNPJ CONTRATADO",
          objectName: "cnpjContratado",
          isSorted: true,
          formatting: (data: string) => (data ? data : "Não Informado"),
        },
        {
          title: "OBJETO DO CONTRATO",
          objectName: "objetoContrato",
          isSorted: true,
          style: { minWidth: "210px" },
        },
        {
          title: "PERIODICIDADE DE PAGAMENTO",
          objectName: "periodicidadePagamento",
          isSorted: true,
          style: { minWidth: "210px" },
        },
        {
          title: "FORMA DE PAGAMENTO",
          objectName: "formaPagamento",
          isSorted: true,
        },
        {
          title: "EXIGÊNCIA",
          objectName: "exigenciaPagamento",
          isSorted: true,
          style: { minWidth: "280px" },
        },
        {
          title: "GESTOR",
          objectName: "gestorContrato",
          isSorted: true,
          style: { minWidth: "500px" },
        },
        {
          title: "PENALIDADE",
          objectName: "penalidadesContratuais",
          isSorted: true,
        },
        {
          title: "DATA ENVIO DOC.",
          objectName: "dataEnvioDoc",
          isSorted: true,
          formatting: (data: string) => (data ? formatDate(data, "dd/MM/yyyy") : "Não Enviado"),
          style: { minWidth: "180px" },
        },
        {
          title: "DATA RECEBIMENTO DOC.",
          objectName: "dataRecebimentoDoc",
          isSorted: true,
          formatting: (data: string) => (data ? formatDate(data, "dd/MM/yyyy") : "Não Recebido"),
          style: { minWidth: "240px" },
        },
        {
          title: "INÍCIO VIGÊNCIA",
          objectName: "dataInicialVigencia",
          isSorted: true,
          formatting: (data: string) => (data ? formatDate(data, "dd/MM/yyyy") : "ERROR"),
        },
        {
          title: "TÉRMINO VIGÊNCIA",
          objectName: "dataFinalVigencia",
          isSorted: true,
          formatting: (data: string) => (data ? formatDate(data, "dd/MM/yyyy") : "ERROR"),
        },
        {
          title: "DIAS PARA VENCIMENTO",
          objectName: "qtdDiasVencimento",
          isSorted: true,
        },
        {
          title: "STATUS",
          objectName: "status",
          isSorted: true,
          formatting: (data: string) => labelStatus(data as StatusContrato),
          style: { minWidth: "280px" },
        },
        {
          title: "ALERTA",
          objectName: "alerta",
          isSorted: true,
          formatting: (data: string) => data ?? "Sem alertas",
          style: { minWidth: "180px" },
        },
        {
          title: "",
          objectName: "editar",
          isSorted: false,
          style: { minWidth: "180px" },
        },
      ],
      data,
    };
  }, [contratos, navigate]);

  const searchingContratos = useLoading();

  const searchContratosPaginatedPromise = useCallback(
    (pagina: number) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/juridico/searchContratosPaginated", {
            body: {
              pagina: pagina,
              tamanho: pagination.maxItems,
              descricao: nomeCnpj.value,
              status: statusContrato.value?.value,
              operadorQtdDias: qtdDiasVencimentoOption[0],
              qtdDiasVencto: qtdDiasVencimento.value !== "" ? Number(qtdDiasVencimento.value) : null,
              dataInicialVigenciaPerIni: inicioVigencia.value ? inicioVigencia.toISOString() : null,
              dataFinalVigenciaPerFim: terminoVigencia.value ? terminoVigencia.toISOString() : null,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [
      customFetch,
      inicioVigencia,
      nomeCnpj.value,
      pagination.maxItems,
      qtdDiasVencimento.value,
      qtdDiasVencimentoOption,
      statusContrato.value?.value,
      terminoVigencia,
    ]
  );

  const searchContratosTotalRecordsPromise = useCallback(
    (pagina: number) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/juridico/searchContratosTotalRecords", {
            body: {
              pagina: pagina,
              tamanho: pagination.maxItems,
              descricao: nomeCnpj.value,
              status: statusContrato.value?.value,
              operadorQtdDias: qtdDiasVencimentoOption[0],
              qtdDiasVencto: qtdDiasVencimento.value !== "" ? Number(qtdDiasVencimento.value) : null,
              dataInicialVigenciaPerIni: inicioVigencia.value ? inicioVigencia.toISOString() : null,
              dataFinalVigenciaPerFim: terminoVigencia.value ? terminoVigencia.toISOString() : null,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [
      customFetch,
      inicioVigencia,
      nomeCnpj.value,
      pagination.maxItems,
      qtdDiasVencimento.value,
      qtdDiasVencimentoOption,
      statusContrato.value?.value,
      terminoVigencia,
    ]
  );

  const searchContratosPaginated = useCallback(
    async (pagina: number) => {
      try {
        searchingContratos.setLoading(true);
        const json = (await searchContratosPaginatedPromise(pagina)) as SearchContratosResponse;
        if (json.status === 200) {
          setContratos(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setContratos([]);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        searchingContratos.setLoading(false);
      }
    },
    [Modal, searchContratosPaginatedPromise, searchingContratos]
  );

  const searchContratosTotalRecordsAndContratos = useCallback(async () => {
    try {
      searchingContratos.setLoading(true);
      const [contratos, records] = (await Promise.all([
        searchContratosPaginatedPromise(0),
        searchContratosTotalRecordsPromise(0),
      ])) as [SearchContratosResponse, SearchContratosTotalRecordsResponse];
      if (contratos.status === 200 && records.status === 200) {
        setContratos(contratos.object);
        pagination.setTotalRecords(records.object.total);
      } else if (contratos.status !== 200) {
        if (contratos.status === 500) {
          Modal.error(contratos.message, contratos.object);
        } else {
          pagination.reset();
          setContratos([]);
        }
      } else {
        if (records.status === 500) {
          Modal.error(records.message, records.object);
        } else {
          setContratos([]);
        }
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      searchingContratos.setLoading(false);
    }
  }, [Modal, pagination, searchContratosPaginatedPromise, searchContratosTotalRecordsPromise, searchingContratos]);

  const searchContratosTotalRecordsAndContratosDebounced = useDebounce(searchContratosTotalRecordsAndContratos);

  return {
    selectedContrato: {
      value: selectedContrato,
      setValue: setSelectedContrato,
    },
    filters: {
      nomeCnpj,
      qtdDiasVencimento: {
        qtd: qtdDiasVencimento,
        option: qtdDiasVencimentoOption,
      },
      periodoVigencia: {
        inicio: inicioVigencia,
        termino: terminoVigencia,
      },
      statusContrato: {
        status: statusContrato,
        options: statusContratoOptions,
      },
    },
    data: {
      contratos,
      tableData,
    },
    searchs: {
      pagination,
      searchingContratos: searchingContratos.isLoading,
      searchContratosPaginated,
      searchContratosTotalRecordsAndContratos,
      searchContratosTotalRecordsAndContratosDebounced,
    },
  };
}
