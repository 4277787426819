import React from "react";

import { useInsertOrDeduct } from "./hooks/useInsertOrDeduct";
import { Button, InputNumber, Select } from "../../../../components/Form";
import { useSelectOptions } from "../hooks/useSelectOptions";
import { Circle } from "../../../../components/Loading";

import styles from "./InsertOrDeduct.module.css";

export function FactoryStockSchedulingInsertOrDeduct() {
  const { product, enterprise, action, quantity, insertOrDeductInventory } = useInsertOrDeduct();

  const { productOptions, enterpriseOptions, actionOptions } = useSelectOptions();

  return (
    <div className="container">
      {!insertOrDeductInventory.loading ? (
        <>
          <div className={styles.fieldsContainer}>
            <div>
              <label htmlFor="product" className="label">
                Produto
              </label>
              <Select
                id="product"
                placeholder="Selecione um produto"
                options={productOptions.options}
                value={product.value}
                error={product.error}
                onInputChange={(value) => productOptions.searchDebounced(value)}
                onChange={product.onChange}
                onBlur={product.onBlur}
                isLoading={productOptions.searching}
              />
            </div>
            <div>
              <label htmlFor="enterprise" className="label">
                Empresa
              </label>
              <Select
                id="enterprise"
                placeholder="Selecione uma empresa"
                options={enterpriseOptions.options}
                value={enterprise.value}
                error={enterprise.error}
                onChange={enterprise.onChange}
                onBlur={enterprise.onBlur}
                isLoading={enterpriseOptions.searching}
              />
            </div>
            <div>
              <label htmlFor="quantity" className="label">
                Quantidade (TN)
              </label>
              <InputNumber
                id="quantity"
                className="cleanInputNumber"
                placeholder="Digite a quantidade"
                value={quantity.value}
                error={quantity.error}
                onChange={quantity.onChange}
                onBlur={quantity.onBlur}
              />
            </div>
            <div className={styles.fieldAction}>
              <label htmlFor="action" className="label">
                Ação Realizada
              </label>
              <Select
                id="action"
                options={actionOptions.options}
                value={action.value}
                onChange={action.onChange}
                isClearable={false}
                isSearchable={false}
              />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button onClick={() => insertOrDeductInventory.handle()}>Concluir</Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Fazendo alterações no estoque</span>
        </div>
      )}
    </div>
  );
}
