import React, { useRef } from "react";

import { Button, LinkButton } from "../../../../components/Form";
import {
  Timeline,
  TimelineContent,
  TimelineDot,
  TimelineItem,
} from "../../../../components/Data/Timeline";
import {
  ISolicitation,
  IUseHistorical,
  IUseSolicitationList,
} from "../hooks/types";
import { formatDate } from "../../../../helpers/format";
import { Navigate } from "react-router-dom";
import { Circle } from "../../../../components/Loading";

import styles from "./Historical.module.css";
import { Analysis } from "../Analysis/Analysis";
import {
  ExcelPermissions,
  ExcelType,
  UseContractExcel,
} from "../../hooks/useContractExcel";

interface Props {
  selectedSolicitation: ISolicitation | null;
  useHistorical: IUseHistorical;
  solicitationList: IUseSolicitationList;
  contractExcel: UseContractExcel;
}

export function Historical({
  selectedSolicitation,
  useHistorical,
  solicitationList,
  contractExcel,
}: Props) {
  const [screen, setScreen] = React.useState<"Historico" | "Detalhes">(
    "Historico"
  );

  const { historicalList, searchSolicitationsHistorical } = useHistorical;

  const modalExcelType = useRef<HTMLDialogElement>(null);

  React.useLayoutEffect(() => {
    if (selectedSolicitation) {
      searchSolicitationsHistorical.search(selectedSolicitation.idSolicitacao);
    }
  }, []); // eslint-disable-line

  if (!selectedSolicitation)
    return <Navigate to="/contratos/historico-solicitacoes" />;

  return (
    <>
      <div className="container">
        {!contractExcel.exportingExcel ? (
          <>
            <div className={styles.navigateContainer}>
              <LinkButton
                to={"/contratos/historico-solicitacoes"}
                buttonStyle="backButton"
              />
              <h2>
                {selectedSolicitation.idSolicitacao} -{" "}
                {selectedSolicitation.descricaoContrato}
              </h2>
              <span />
            </div>
            <span className="separator" />
            <div className={styles.checkButtonScreen}>
              <span
                className={`${styles.checkButtonScreen__indicator} ${
                  screen === "Detalhes"
                    ? styles.checkButtonScreen__indicator_detalhes
                    : ""
                }`}
              />
              <span
                className={`${styles.checkButtonScreen__itemScreen} ${
                  screen !== "Historico"
                    ? styles.checkButtonScreen__itemScreen_inactive
                    : ""
                }`}
                onClick={() => setScreen("Historico")}
              >
                Histórico do Contrato
              </span>
              <span
                className={`${styles.checkButtonScreen__itemScreen} ${
                  screen !== "Detalhes"
                    ? styles.checkButtonScreen__itemScreen_inactive
                    : ""
                }`}
                onClick={() => setScreen("Detalhes")}
              >
                Detalhes do Contrato
              </span>
            </div>
            <span className="separator" />
            <>
              <div
                className={styles.main}
                style={{ display: screen === "Historico" ? "" : "none" }}
              >
                {historicalList.length &&
                !searchSolicitationsHistorical.searching ? (
                  <>
                    <Timeline className={styles.timelineContainer}>
                      {historicalList.map((item, index) => (
                        <TimelineItem key={index} className={undefined}>
                          <TimelineDot color={undefined} />
                          <TimelineContent
                            className={styles.timelineContentContainer}
                          >
                            <p className={styles.timelineAuthorName}>
                              {item.usuario.nome}
                            </p>
                            <p>{item.descricao}</p>
                            <time
                              style={{
                                borderLeft: "2px solid var(--gray-8)",
                                borderRight: "2px solid var(--gray-8)",
                                padding: "0 4px",
                              }}
                            >
                              {formatDate(
                                item.data,
                                "Month, dd de yyyy às hh:mm:ss"
                              )}
                            </time>
                          </TimelineContent>
                        </TimelineItem>
                      ))}
                    </Timeline>
                    <div className={styles.decisionContainer}>
                      <Button
                        variant="neutral"
                        onClick={() => {
                          const permissionsAllowed =
                            contractExcel.getExcelPermissions();
                          if (permissionsAllowed.permissions.length > 1) {
                            modalExcelType.current?.show();
                          } else if (
                            permissionsAllowed.permissions.length === 1
                          ) {
                            contractExcel.exportExcel(
                              selectedSolicitation.idSolicitacao,
                              permissionsAllowed.type!!
                            );
                          }
                        }}
                        className={styles.exportExcelButton}
                      >
                        Exportar Faixa Padrão Para Excel
                      </Button>
                    </div>
                  </>
                ) : searchSolicitationsHistorical.searching ? (
                  <div
                    className={`loadingContainer ${styles.loadingContainer}`}
                  >
                    <Circle size={100} />
                    <span className="loadingMessage" />
                  </div>
                ) : (
                  <p className={`lineCardMessage`}>
                    Nenhum histórico registrado
                  </p>
                )}
              </div>
            </>
            <div style={{ display: screen === "Detalhes" ? "" : "none" }}>
              <Analysis
                selectedSolicitation={selectedSolicitation}
                solicitationList={solicitationList}
              />
            </div>
          </>
        ) : (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
            <span className="loadingMessage" />
          </div>
        )}
      </div>
      <dialog className={`modal ${styles.modalExcelType}`} ref={modalExcelType}>
        <div className="modalContent">
          <div className={styles.modalExcelType__buttonsContainer}>
            {contractExcel.verifyPermission(ExcelPermissions.CLIENTE) && (
              <Button
                className={styles.modalExcelType__buttonsContainer__button}
                onClick={() => {
                  contractExcel.exportExcel(
                    selectedSolicitation.idSolicitacao,
                    ExcelType.MODELO_CLIENTE
                  );
                  modalExcelType.current?.close();
                }}
              >
                Modelo Cliente
              </Button>
            )}
            {contractExcel.verifyPermission(ExcelPermissions.ANALITICO) && (
              <Button
                className={styles.modalExcelType__buttonsContainer__button}
                onClick={() => {
                  contractExcel.exportExcel(
                    selectedSolicitation.idSolicitacao,
                    ExcelType.MODELO_ANALITICO
                  );
                  modalExcelType.current?.close();
                }}
              >
                Modelo Analítico
              </Button>
            )}
            {contractExcel.verifyPermission(ExcelPermissions.JURIDICO) && (
              <Button
                className={styles.modalExcelType__buttonsContainer__button}
                onClick={() => {
                  contractExcel.exportExcel(
                    selectedSolicitation.idSolicitacao,
                    ExcelType.MODELO_JURIDICO
                  );
                  modalExcelType.current?.close();
                }}
              >
                Modelo Jurídico
              </Button>
            )}
          </div>
          <Button
            className={styles.modalExcelType__buttonCancel}
            variant="danger"
            onClick={() => {
              modalExcelType.current?.close();
            }}
          >
            Cancelar
          </Button>
        </div>
      </dialog>
    </>
  );
}
