import React from "react";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { Sidebar } from "./components/Sidebar/Sidebar";
import { Topbar } from "./components/Topbar/Topbar";

import styles from "./Home.module.css";

export function Home() {
  const [menuOpen, setMenuOpen] = React.useState(true);

  const isInitialPage = useMatch({ path: "/", end: true });
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isInitialPage) {
      navigate("/aplicativos");
    }
  });

  return (
    <div className={styles.masterContainer}>
      <Sidebar
        className={`${styles.sidebar} ${menuOpen ? "menuOpen" : ""}`}
        menuMobile={{ menuOpen, setMenuOpen }}
      />
      <div className={`${styles.container} ${menuOpen ? "menuOpen" : ""}`}>
        <Topbar
          className={`${styles.topbar}`}
          openMenuMobile={() => setMenuOpen(!menuOpen)}
        />
        <div className={`${styles.content} ${menuOpen ? "menuOpen" : ""}`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
