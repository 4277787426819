import React from "react";
import { Route, Routes } from "react-router-dom";
import { useModal } from "../../../hooks/contexts";
import { useCustomFetch, useLoading } from "../../../hooks/async";
import { usePagination } from "../../../hooks/pagination";
import { List } from "./List/List";
import { NewUserDocument } from "./NewUserDocument/NewUserDocument";
import { UpdateUserDocument } from "./UpdateUserDocument/UpdateUserDocument";

export interface IUserDocument {
  usuarioDocumentoId: number;
  usuarioId: number;
  usuario: {
    idUsuario: number;
    nome: string;
    usuario: string;
    idSiagri: number;
    idEmpresa: number;
    portalPadrao: number;
    flagSupervisor: any;
    nivelAcesso: number;
    email: string;
    idEmpresaCadastro: number;
    statusUsuario: "A" | "I";
  } | null;
  cpf: string;
  cnpj: string;
  status: "A" | "I";
  dataCriacao: string;
  usuarioCriacaoId: number;
  dataAlteracao: string;
  usuarioAlteracaoId: any;
}

export function FinanceUserDocument() {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const [usersDocumentList, setUsersDocumentList] = React.useState<IUserDocument[]>([]);
  const [selectedUserDocument, setSelectedUserDocument] = React.useState<IUserDocument | null>(null);

  const searchingUsersDocument = useLoading();

  const pagination = usePagination(10);

  const searchUserDocumentsPromise = React.useCallback(
    (page = 1) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/finance/searchUserDocumentsPaginated", {
            body: {
              id: 0,
              paginacao: {
                paginaNumero: page,
                paginaTamanho: pagination.maxItems,
              },
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const searchTotalUserDocumentRecordsPromise = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const json = await customFetch("/finance/searchTotalUserDocumentRecords", {});
        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
  }, [customFetch]);

  const searchUserDocuments = React.useCallback(
    async (page: number = 1) => {
      try {
        searchingUsersDocument.setLoading(true);
        const json = (await searchUserDocumentsPromise(page)) as DefaultFetchResponse<IUserDocument[]>;
        if (json.status === 200) {
          setUsersDocumentList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setUsersDocumentList([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingUsersDocument.setLoading(false);
      }
    },
    [Modal, searchUserDocumentsPromise, searchingUsersDocument]
  );

  const searchTotalUserDocumentRecordsAndUserDocuments = React.useCallback(async () => {
    try {
      searchingUsersDocument.setLoading(true);
      const json = (await searchUserDocumentsPromise()) as DefaultFetchResponse<IUserDocument[]>;
      if (json.status === 200) {
        const records = (await searchTotalUserDocumentRecordsPromise()) as DefaultFetchResponse<string>;
        if (records.status === 200) {
          setUsersDocumentList(json.object);
          pagination.setTotalRecords(Number(records.object));
        } else if (records.status === 500) {
          Modal.error(records.message, records.object);
        } else {
          setUsersDocumentList([]);
        }
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setUsersDocumentList([]);
        pagination.reset();
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      pagination.setCurrentPage(1);
      searchingUsersDocument.setLoading(false);
    }
  }, [Modal, pagination, searchUserDocumentsPromise, searchTotalUserDocumentRecordsPromise, searchingUsersDocument]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <List
            usersDocumentList={{
              value: usersDocumentList,
              setValue: setUsersDocumentList,
            }}
            searchUserDocuments={searchUserDocuments}
            searchTotalUserDocumentRecordsAndUserDocuments={{
              search: searchTotalUserDocumentRecordsAndUserDocuments,
              isSearching: searchingUsersDocument.isLoading,
            }}
            pagination={pagination}
            setSelectedUserDocument={setSelectedUserDocument}
          />
        }
      />
      <Route
        path="novo"
        element={
          <NewUserDocument
            searchTotalUserDocumentRecordsAndUserDocuments={searchTotalUserDocumentRecordsAndUserDocuments}
          />
        }
      />
      <Route
        path="atualizar"
        element={
          <UpdateUserDocument
            selectedUserDocument={selectedUserDocument}
            searchUserDocuments={() => searchUserDocuments(pagination.currentPage)}
          />
        }
      />
    </Routes>
  );
}
