import React from "react";

import { useNavigate } from "react-router-dom";
import { useModal } from "../../../../hooks/contexts";
import { useDebounce } from "../../../../hooks/async";

import { Table, TBody, Td, Th, THead, Tr } from "../../../../components/Data/Table";
import { Input } from "../../../../components/Form";
import { Paginate } from "../../../../components/Paginate/Paginate";
import { formatDate } from "../../../../helpers/format";
import { Button } from "../../../../components/Form";

import { Circle } from "../../../../components/Loading";

import { Pencil, CurrencyDollar } from "phosphor-react";

import styles from "./PackingList.module.css";

export function PackingList({
  pagination,
  searchPackaging,
  searchTotalPackingRecordsAndPackaging,
  searchedPacking,
  packingList,
  setSelectedPacking,
  searchingPackaging,
}) {
  const Modal = useModal();
  const navigate = useNavigate();

  const handleClickEditButton = React.useCallback(
    (packingData) => {
      setSelectedPacking(packingData);
      navigate("atualizar");
    },
    [navigate, setSelectedPacking]
  );

  const handleClickEditCostsButton = React.useCallback(
    (packingData) => {
      setSelectedPacking(packingData);
      navigate("custos");
    },
    [navigate, setSelectedPacking]
  );

  const handlePageChange = React.useCallback(
    async (packing, page) => {
      try {
        searchingPackaging.setLoading(true);
        const json = await searchPackaging(packing, page);
        if (json.status === 200) {
          packingList.setValue(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingPackaging.setLoading(false);
      }
    },
    [Modal, packingList, searchPackaging, searchingPackaging]
  );

  const { current: searchPackingList } = React.useRef(
    useDebounce((packing) => {
      searchTotalPackingRecordsAndPackaging(packing);
    })
  );

  React.useEffect(() => {
    if (!packingList.value.length) searchTotalPackingRecordsAndPackaging();
  }, []); //eslint-disable-line

  return (
    <div className={`container ${styles.container}`}>
      <div className={`${styles.searchContainer}`}>
        <label className="label">Filtrar Embalagens</label>
        <Input
          placeholder="Busque por uma embalagem"
          value={searchedPacking.value}
          onChange={({ target }) => {
            searchedPacking.setValue(target.value);
            searchPackingList(target.value);
          }}
        />
      </div>
      <div className={`${styles.packingListContainer}`}>
        {packingList.value.length && !searchingPackaging.isLoading ? (
          <Table title="Embalagens Cadastradas" className={styles.tablePacking}>
            <THead>
              <Tr>
                <Th>ID</Th>
                <Th>Descrição</Th>
                <Th>Fator de Conversão</Th>
                <Th>Situação</Th>
                <Th>Última modificação</Th>
                <Th></Th>
                <Th></Th>
              </Tr>
            </THead>
            <TBody>
              {packingList.value.map((packing) => {
                return (
                  <Tr key={packing.idEnvase}>
                    <Td data-heading="ID">{packing.idEnvase}</Td>
                    <Td data-heading="Descrição">{packing.descricaoEnvase}</Td>
                    <Td data-heading="Fator de Conversão">{`${packing.fatorConversao}`}</Td>
                    <Td data-heading="Situação">{packing.situacao === "A" ? "Ativo" : "Inativo"}</Td>
                    <Td data-heading="Última modificação">
                      {formatDate(packing.dataUltima, "dd/MM/yyyy às hh:mm:ss")}
                    </Td>
                    <Td data-heading="Editar Embalagem" data-option>
                      <Button
                        className={styles.editPackingButton}
                        onClick={() => {
                          const selectedPacking = {
                            ...packing,
                            situacao: !!(packing.situacao === "A"),
                          };
                          handleClickEditButton(selectedPacking);
                        }}
                        data-option-button>
                        <Pencil weight="fill" />
                      </Button>
                    </Td>
                    <Td data-heading="Editar Custos" data-option>
                      <Button
                        className={styles.editPackingButton}
                        onClick={() => {
                          const selectedPacking = {
                            ...packing,
                            situacao: !!(packing.situacao === "A"),
                          };
                          handleClickEditCostsButton(selectedPacking);
                        }}
                        data-option-button>
                        <CurrencyDollar weight="fill" />
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </TBody>
          </Table>
        ) : searchingPackaging.isLoading ? (
          <div className={styles.searchingPackagingLoadingContainer}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={styles.noPackagingMessage}>Nenhuma embalagem encontrada</p>
        )}
      </div>
      <div>
        <Paginate
          classNameContainer={styles.paginationContainer}
          totalRecords={pagination.totalRecords}
          maxItems={pagination.maxItems}
          currentPage={pagination.currentPage}
          setCurrentPage={pagination.setCurrentPage}
          onPageChange={(page) => handlePageChange(searchedPacking.value, page)}
        />
      </div>
    </div>
  );
}
