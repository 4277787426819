import { useSelect } from "../../../../hooks/form";
import { IContractSellers } from "../../types";

export function useContractSellers(): IContractSellers {
  const sellers = useSelect({ type: "multiple", required: true });

  const validate = () => {
    return sellers.validate();
  };

  const reset = () => {
    sellers.reset();
  };

  return {
    sellers,
    validate,
    reset,
  };
}
