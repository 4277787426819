import React from "react";

import styles from "./Page404.module.css";

export default function Page404() {
  return (
    <div className={styles.errorContainer}>
      <h1 className={styles.title}>404</h1>
      <p className={styles.message}>Oops! Página não encontrada.</p>
      <a href="/" className={styles.backLink}>
        Voltar para a página inicial
      </a>
    </div>
  );
}
