import React from "react";
import { PremiationTypesList } from "./PremiationTypesList/PremiationTypesList";

import styles from "./PremiationTypes.module.css";

import { TPremiationTypesList } from "../GeneralSettings";
import { AddAndUpdatePremiationType } from "./AddAndUpdatePremiationType/AddAndUpdatePremiationType";

interface IPremiationTypesProps {
  premiationTypesList: TPremiationTypesList;
}

export type TCurrentPage = "list" | "new" | "update";

export default function PremiationTypes({ premiationTypesList }: IPremiationTypesProps) {
  const [currentPage, setCurrentPage] = React.useState<TCurrentPage>("list");

  return (
    <div className={styles.container}>
      {currentPage === "list" ? (
        <PremiationTypesList premiationTypesList={premiationTypesList} setCurrentPage={setCurrentPage} />
      ) : currentPage === "new" ? (
        <AddAndUpdatePremiationType setCurrentPage={setCurrentPage} />
      ) : (
        <AddAndUpdatePremiationType setCurrentPage={setCurrentPage} />
      )}
    </div>
  );
}
