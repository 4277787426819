import React from "react";
import { Button, Input, LinkButton, Select } from "../../../../components/Form";

import styles from "./UpdateSupplier.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import { useForm, useSelect } from "../../../../hooks/form";
import { useCustomFetch, useDebounce, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { isValid } from "../../../../helpers/validations";
import { Circle } from "../../../../components/Loading";
import { InputMask } from "../../../../components/Form/InputMask/InputMask";

export function UpdateSupplier({ supplier, searchSuppliers, filteredSupplier }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const updatingSupplier = useLoading();
  const searchingCities = useLoading();

  const corporateName = useForm({ type: "text", required: true });
  const cnpj = useForm({ type: "text", required: true });
  const address = useForm({ type: "text", required: true });
  const city = useSelect({ type: "single", required: true });
  const situation = useSelect({ type: "single", required: true });

  const situationOptions = [
    {
      value: "A",
      label: "ATIVO",
    },
    {
      value: "I",
      label: "INATIVO",
    },
  ];

  const [cityOptions, setCityOptions] = React.useState([]);

  const searchCities = React.useCallback(
    async (city = "") => {
      try {
        searchingCities.setLoading(true);
        const json = await customFetch("/rdv/searchCities", {
          body: {
            pagina: 0,
            tamanho: 100,
            idCidade: 0,
            idIBGE: "",
            nomeCidade: city,
            uf: "",
            cep: "",
            cepSecundario: "",
            latitude: 0,
            longitude: 0,
            idPais: 0,
            nomePais: "BRASIL",
          },
        });
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            value: option,
            label: `${option.nomeCidade} | ${option.uf}`,
          }));
          setCityOptions(options);
        } else if (json.status === 500) {
          setCityOptions([]);
          Modal.error(json.message, json.object);
        } else {
          setCityOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingCities.setLoading(false);
      }
    },
    [Modal, customFetch, searchingCities]
  );

  const searchCitiesDebounced = useDebounce(searchCities);

  const onUpdateSupplier = React.useCallback(async () => {
    if (isValid(corporateName, cnpj, address, city)) {
      try {
        updatingSupplier.setLoading(true);
        const json = await customFetch("/rdv/insertAndUpdateSupplier", {
          body: {
            id: supplier.id,
            nome: corporateName.value,
            cnpj: cnpj.value,
            endereco: address.value,
            idCidade: Number(city.value.value.idCidade),
            status: situation.value.value,
          },
        });
        if (json.status === 200) {
          searchSuppliers(filteredSupplier);
          await Modal.success(json.message);
          navigate("/rdv/fornecedores");
        } else {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        updatingSupplier.setLoading(false);
      }
    }
  }, [
    Modal,
    address,
    city,
    cnpj,
    corporateName,
    customFetch,
    navigate,
    searchSuppliers,
    supplier,
    updatingSupplier,
    situation,
    filteredSupplier,
  ]);

  React.useEffect(() => {
    if (supplier) {
      corporateName.setValue(supplier.nome || "");
      cnpj.setValue(supplier.cnpj || "");
      address.setValue(supplier.endereco || "");
      city.setValue({
        value: {
          idCidade: supplier.idCidade,
          nomeCidade: supplier.nomeCidade,
          uf: supplier.uf,
        },
        label: `${supplier.nomeCidade} | ${supplier.uf}` || "",
      });
      if (supplier.status === "A" || supplier.status === "Ativo") {
        situation.setValue(situationOptions[0]);
      } else {
        situation.setValue(situationOptions[1]);
      }
    }
    searchCities();
  }, []); // eslint-disable-line

  if (!supplier) return <Navigate to={"/rdv/fornecedores"} />;

  return (
    <div className="container">
      {!updatingSupplier.isLoading ? (
        <>
          <div className={`${styles.navContainer}`}>
            <LinkButton to="/rdv/fornecedores" buttonStyle="backButton" />
            <h2>{supplier.razaoSocial}</h2>
            <div></div>
          </div>
          <div className={`${styles.fieldsContainer}`}>
            <div>
              <label className="label" htmlFor="corporateName">
                Razão Social
              </label>
              <Input
                id="corporateName"
                placeholder="Digite a descrição do fornecedor"
                value={corporateName.value}
                error={corporateName.error}
                onChange={corporateName.onChange}
                onBlur={corporateName.onBlur}
              />
            </div>
            <div>
              <label className="label" htmlFor="cnpj">
                CNPJ
              </label>
              <InputMask
                id="cnpj"
                placeholder="XX.XXX.XXX/XXXX-XX"
                value={cnpj.value}
                error={cnpj.error}
                onChange={cnpj.onChange}
                onBlur={cnpj.onBlur}
                mask={"99.999.999/9999-99"}
              />
            </div>
            <div>
              <label className="label" htmlFor="address">
                Endereço
              </label>
              <Input
                id="address"
                placeholder="Digite o endereço"
                value={address.value}
                error={address.error}
                onChange={address.onChange}
                onBlur={address.onBlur}
              />
            </div>
            <div>
              <label className="label" htmlFor="city">
                Cidade
              </label>
              <Select
                id="city"
                placeholder="Selecione a cidade"
                options={cityOptions}
                value={city.value}
                error={city.error}
                onChange={city.onChange}
                onInputChange={(value) => {
                  searchCitiesDebounced(value);
                }}
                onBlur={city.onBlur}
                isLoading={searchingCities.isLoading}
              />
            </div>
            <div>
              <label className="label" htmlFor="situation">
                Situação
              </label>
              <Select
                id="situation"
                options={situationOptions}
                value={situation.value}
                onChange={(value) => {
                  situation.onChange(value);
                }}
                isSearchable={false}
                isClearable={false}
              />
            </div>
          </div>
          <div className={`${styles.saveButtonContainer}`}>
            <Button onClick={onUpdateSupplier}>Salvar</Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Atualizando fornecedor</span>
        </div>
      )}
    </div>
  );
}
