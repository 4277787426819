import React from "react";

import { formatDate, formatMoney } from "../../../../../helpers/format";

import { Button, Input } from "../../../../../components/Form";
import {
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from "../../../../../components/Data/Table";
import { Paginate } from "../../../../../components/Paginate/Paginate";

import { Eye } from "phosphor-react";
import styles from "./RequestsList.module.css";
import { Circle } from "../../../../../components/Loading";
import { useNavigate } from "react-router-dom";

export function RequestsList({
  scrollPosition,
  filteredSolicitation,
  sector,
  page,
  requestList,
  searchTotalRequestRecordsAndRequests,
  searchRequests,
  pagination,
  setSelectedRequest,
}) {
  const navigate = useNavigate();

  const setCurrentScrollPosition = () => {
    scrollPosition.current = window.scrollY;
  };

  React.useEffect(() => {
    window.scrollTo(0, scrollPosition.current);

    setSelectedRequest(null);
    if (!requestList.value.length) searchTotalRequestRecordsAndRequests();
  }, [sector, page]); // eslint-disable-line

  return (
    <div className={`container ${styles.container}`}>
      <div className={styles.filtersContainer}>
        <div>
          <label htmlFor="filteredSolicitation" className="label">
            ID Solicitação
          </label>
          <Input
            id="filteredSolicitation"
            placeholder="Digite o id da solicitação"
            value={filteredSolicitation.value}
            onChange={filteredSolicitation.onChange}
          />
        </div>
        <Button
          className={styles.filtersContainer__btnSearchSolicitation}
          onClick={() => {
            searchTotalRequestRecordsAndRequests();
          }}
        >
          Buscar Solicitações
        </Button>
      </div>
      <span className="separator" />
      <div className={styles.dataContainer}>
        {requestList.value.length && !requestList.isSearching ? (
          <div className={styles.requestsTableContainer}>
            {requestList.value.map((clientItem, index) => {
              return (
                <div className={styles.table} key={index}>
                  <Table
                    title={clientItem.nomeCliente}
                    className={styles.requestsTable}
                  >
                    <THead>
                      <Tr>
                        <Th>ID Solicitação</Th>
                        <Th>Gerente Solicitante</Th>
                        <Th>Valor Solicitado</Th>
                        <Th>Data</Th>
                        <Th></Th>
                      </Tr>
                    </THead>
                    <TBody>
                      {clientItem.premiacoes.map((request, index) => {
                        return (
                          <Tr key={index}>
                            <Td heading="ID Solicitação" width="10rem">
                              {request.solicitacoes.idSolicitacao}
                            </Td>
                            <Td heading="Gerente Solicitante">
                              {request.usuario.nome}
                            </Td>
                            <Td heading="Valor Solicitado" width="180px">
                              {formatMoney(request.solicitacoes.total)}
                            </Td>
                            <Td heading="Data" width="11.25rem">
                              {formatDate(
                                request.solicitacoes.dataInsercao,
                                "dd/MM/yy às hh:mm:ss"
                              )}
                            </Td>
                            <Td
                              heading="Ver Solicitação"
                              width="6.25rem"
                              data-option
                            >
                              <Button
                                type="button"
                                variant="edit"
                                className={styles.seeRequestButton}
                                onClick={() => {
                                  setCurrentScrollPosition();
                                  setSelectedRequest(request);
                                  navigate("historico");
                                }}
                                data-option-button
                              >
                                <Eye weight="fill" />
                              </Button>
                            </Td>
                          </Tr>
                        );
                      })}
                    </TBody>
                  </Table>
                </div>
              );
            })}
          </div>
        ) : requestList.isSearching ? (
          <div
            className={`loadingContainer ${styles.loadingRequestListContainer}`}
          >
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhuma solicitação encontrada</p>
        )}
        <Paginate
          classNameContainer={styles.paginationContainer}
          totalRecords={pagination.totalRecords}
          maxItems={pagination.maxItems}
          currentPage={pagination.currentPage}
          setCurrentPage={pagination.setCurrentPage}
          onPageChange={(page) => searchRequests(page)}
        />
      </div>
    </div>
  );
}
