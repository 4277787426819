import React from "react";
import { Route, Routes } from "react-router-dom";
import Grupos from "./Grupos/Grupos";
import { useGruposPermissoes } from "./hooks/useGruposPermissoes";
import InserirAtualizarGrupo from "./InserirAtualizarGrupo/InserirAtualizarGrupo";
import AtualizarPermissoesGrupo from "./AtualizarPermissoesGrupo/AtualizarPermissoesGrupo";

export default function GruposPermissoes() {
  const dados = useGruposPermissoes();

  return (
    <Routes>
      <Route path="/" element={<Grupos dadosGruposPermissoes={dados} />} />
      <Route
        path="novo"
        element={<InserirAtualizarGrupo dadosGruposPermissoes={dados} />}
      />
      <Route
        path="atualizar"
        element={<InserirAtualizarGrupo dadosGruposPermissoes={dados} />}
      />
      <Route
        path="atualizar-permissoes"
        element={<AtualizarPermissoesGrupo dadosGruposPermissoes={dados} />}
      />
    </Routes>
  );
}
