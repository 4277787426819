import React from "react";
import styles from "./Circle.module.css";

interface ICircle {
  color?: string;
  bgColor?: string;
  size?: number;
}

const Circle = ({ color = "", bgColor = "", size }: ICircle) => {
  const customBoxShadow = `0 ${size ? size * 0.02 : "4"}px 0 0 ${color || "var(--green-6)"}`;

  return (
    <div
      className={styles.spinner}
      style={{
        height: size ? `${size}px` : "200px",
        width: size ? `${size}px` : "200px",
        backgroundColor: bgColor || "transparent",
      }}>
      <div className={styles.inside}>
        <div
          style={{
            boxShadow: customBoxShadow,
            height: size ? `${size * 0.8}px` : "160px",
            width: size ? `${size * 0.8}px` : "160px",
            top: size ? `${size * 0.1}px` : "20px",
            left: size ? `${size * 0.1}px` : "20px",
            transformOrigin: size ? `${size * 0.4}px ${size * 0.41}px` : "80px 82px",
          }}></div>
      </div>
    </div>
  );
};

export { Circle };
