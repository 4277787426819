import React from "react";

import { Routes, Route } from "react-router-dom";

import { usePagination } from "../../../hooks/pagination";
import { useCustomFetch, useLoading } from "../../../hooks/async";
import { useForm } from "../../../hooks/form";
import { useModal } from "../../../hooks/contexts";

import { PackingList } from "./PackingList/PackingList";
import { UpdatePacking } from "./UpdatePacking/UpdatePacking";
import { Costs } from "./Costs/Costs";

export function ProductPackaging() {
  const pagination = usePagination();
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const searchedPacking = useForm({ type: "", required: false });

  const searchingPackaging = useLoading();

  const [packingList, setPackingList] = React.useState([]);
  const [selectedPacking, setSelectedPacking] = React.useState(null);

  const clearPackingFilter = React.useCallback(() => {
    searchedPacking.setValue("");
  }, [searchedPacking]);

  const searchTotalPackingRecords = React.useCallback(
    (group = "") => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/products/searchTotalPackingRecords", {
            body: {
              tamanho: pagination.maxItems,
              descricao: group,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const searchPackaging = React.useCallback(
    async (group = "", page) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/products/searchPackagingPaginated", {
            body: {
              pagina: page ? Number(page) - 1 : 0,
              tamanho: pagination.maxItems,
              descricao: group,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const researchPackagings = React.useCallback(
    async (packing, page) => {
      try {
        searchingPackaging.setLoading(true);
        const json = await searchPackaging(packing, page);
        if (json.status === 200) {
          setPackingList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingPackaging.setLoading(false);
      }
    },
    [Modal, searchPackaging, searchingPackaging]
  );

  const searchTotalPackingRecordsAndPackaging = React.useCallback(
    async (group = "") => {
      if (group === "") {
        clearPackingFilter();
      }
      try {
        searchingPackaging.setLoading(true);
        const jsonGroups = await searchPackaging(group);
        if (jsonGroups.status === 200) {
          const jsonRecords = await searchTotalPackingRecords(group);
          if (jsonRecords.status === 200) {
            setPackingList(jsonGroups.object);
            pagination.setTotalRecords(jsonRecords.object.total);
          } else if (jsonRecords.status === 500) {
            Modal.error(jsonRecords.message, jsonRecords.object);
          } else {
            setPackingList([]);
          }
        } else if (jsonGroups.status === 500) {
          Modal.error(jsonGroups.message, jsonGroups.object);
        } else {
          setPackingList([]);
          pagination.reset();
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        pagination.setCurrentPage(1);
        searchingPackaging.setLoading(false);
      }
    },
    [Modal, clearPackingFilter, pagination, searchPackaging, searchTotalPackingRecords, searchingPackaging]
  );

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <PackingList
              pagination={pagination}
              searchTotalPackingRecords={searchTotalPackingRecords}
              searchPackaging={searchPackaging}
              searchTotalPackingRecordsAndPackaging={searchTotalPackingRecordsAndPackaging}
              searchedPacking={searchedPacking}
              packingList={{
                value: packingList,
                setValue: setPackingList,
              }}
              setSelectedPacking={setSelectedPacking}
              searchingPackaging={searchingPackaging}
            />
          }
        />
        <Route
          path="atualizar"
          element={
            <UpdatePacking
              researchPackagings={(packaging) => researchPackagings(packaging, pagination.currentPage)}
              selectedPacking={selectedPacking}
              filteredPacking={searchedPacking.value}
            />
          }
        />
        <Route path="custos/*" element={<Costs selectedPacking={selectedPacking} />} />
      </Routes>
    </div>
  );
}
