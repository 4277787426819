import React, { useEffect } from "react";

import { Route, Routes } from "react-router-dom";

import { useCustomFetch, useDebounce, useLoading } from "../../../hooks/async";
import { useModal } from "../../../hooks/contexts";
import { usePagination } from "../../../hooks/pagination";

import { RequestsList } from "./RequestsList/RequestsList";
import { useForm, useSelect } from "../../../hooks/form";

export function PremiationsRequestsProof({ sector }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const pagination = usePagination(100);

  const filteredManager = useSelect({ type: "single", required: false });
  const filteredCustomer = useSelect({ type: "single", required: false });
  const filteredSolicitation = useForm({ required: false });

  const [managerOptions, setManagerOptions] = React.useState([]);
  const [customerOptions, setCustomerOptions] = React.useState([]);
  const [requestList, setRequestList] = React.useState([]);

  const searchingManagers = useLoading();
  const searchingCustomers = useLoading();
  const searchingRequests = useLoading();

  const searchManagers = React.useCallback(
    async (manager = "") => {
      try {
        searchingManagers.setLoading(true);
        const json = await customFetch("/premiations/finance/searchManagers", {
          body: {
            nome: manager,
          },
        });
        if (json.status === 200) {
          const options = json.object.map((option) => {
            return {
              value: option,
              label: `${option.idUsuario} | ${option.nome}`,
            };
          });
          setManagerOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setManagerOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingManagers.setLoading(false);
      }
    },
    [Modal, customFetch, searchingManagers]
  );

  const searchManagersDebounced = useDebounce(searchManagers);

  const searchCustomers = React.useCallback(
    async (customer = "") => {
      try {
        searchingCustomers.setLoading(true);
        const json = await customFetch("/premiations/manager/searchClients", {
          body: {
            descricao: customer,
          },
        });
        if (json.status === 200) {
          const options = json.object.map((option) => {
            return {
              value: option,
              label: `${option.cliente.idCliente} | ${option.cliente.razaoSocial}`,
            };
          });
          setCustomerOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setCustomerOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingCustomers.setLoading(false);
      }
    },
    [Modal, customFetch, searchingCustomers]
  );

  const searchCustomersDebounced = useDebounce(searchCustomers);

  const searchTotalRequestRecordsPromise = React.useCallback(() => {
    let url = "";
    if (sector === "manager") {
      url = "/premiations/historical/manager/searchAllRequestRecords";
    } else if (sector === "commercial") {
      url = "/premiations/historical/commercial/searchAllRequestRecords";
    } else if (sector === "financial") {
      url = "/premiations/historical/financial/searchAllRequestRecords";
    }

    return new Promise(async (resolve, reject) => {
      try {
        const json = await customFetch(url, {
          body: {
            tamanho: pagination.maxItems,
            status: ["2", "3", "4", "5", "6"],
            idUsuario: filteredManager.value?.value
              ? Number(filteredManager.value?.value.idUsuario)
              : null,
            idSolicitacao: filteredSolicitation.value
              ? Number(filteredSolicitation.value)
              : null,
            idCliente: filteredCustomer.value?.value
              ? Number(filteredCustomer.value?.value.cliente.idCliente)
              : null,
          },
        });
        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
  }, [
    customFetch,
    filteredCustomer.value,
    filteredManager.value,
    filteredSolicitation.value,
    pagination.maxItems,
    sector,
  ]);

  const searchRequestsPromise = React.useCallback(
    (page = 1) => {
      let url = "";
      if (sector === "manager") {
        url = "/premiations/historical/manager/searchAllRequestsPaginated";
      } else if (sector === "commercial") {
        url = "/premiations/historical/commercial/searchAllRequestsPaginated";
      } else if (sector === "financial") {
        url = "/premiations/historical/financial/searchAllRequestsPaginated";
      }

      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch(url, {
            body: {
              pagina: page - 1,
              tamanho: 100,
              status: ["2", "3", "4", "5", "6"],
              idUsuario: filteredManager.value?.value
                ? Number(filteredManager.value?.value.idUsuario)
                : null,
              idSolicitacao: filteredSolicitation.value
                ? Number(filteredSolicitation.value)
                : null,
              idCliente: filteredCustomer.value?.value
                ? Number(filteredCustomer.value?.value.cliente.idCliente)
                : null,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [
      customFetch,
      filteredCustomer.value,
      filteredManager.value,
      filteredSolicitation.value,
      sector,
    ]
  );

  const searchRequests = React.useCallback(
    async (page) => {
      try {
        searchingRequests.setLoading(true);
        const json = await searchRequestsPromise(page);
        if (json.status === 200) {
          setRequestList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setRequestList([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingRequests.setLoading(false);
      }
    },
    [Modal, searchRequestsPromise, searchingRequests]
  );

  const searchTotalRequestRecordsAndRequests = React.useCallback(async () => {
    try {
      searchingRequests.setLoading(true);
      const jsonRequests = await searchRequestsPromise();
      if (jsonRequests.status === 200) {
        const jsonRecords = await searchTotalRequestRecordsPromise();
        if (jsonRecords.status === 200) {
          setRequestList(jsonRequests.object);
          pagination.setTotalRecords(jsonRecords.object.total);
        } else if (jsonRecords.status === 500) {
          Modal.error(jsonRecords.message, jsonRecords.object);
        } else {
          setRequestList([]);
        }
      } else if (jsonRequests.status === 500) {
        Modal.error(jsonRequests.message, jsonRequests.object);
      } else {
        setRequestList([]);
        pagination.reset();
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      pagination.setCurrentPage(1);
      searchingRequests.setLoading(false);
    }
  }, [
    Modal,
    pagination,
    searchRequestsPromise,
    searchTotalRequestRecordsPromise,
    searchingRequests,
  ]);

  useEffect(() => {
    searchManagers();
  }, []); // eslint-disable-line

  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequestsList
            requestList={{
              value: requestList,
              setValue: setRequestList,
              isSearching: searchingRequests.isLoading,
            }}
            filteredManager={filteredManager}
            filterCustomer={filteredCustomer}
            filteredSolicitation={filteredSolicitation}
            managerOptions={{
              value: managerOptions,
              isSearching: searchingManagers.isLoading,
            }}
            customerOptions={{
              value: customerOptions,
              setValue: setCustomerOptions,
              isSearching: searchingCustomers.isLoading,
            }}
            searchManagers={searchManagers}
            searchCustomers={searchCustomers}
            searchManagersDebounced={searchManagersDebounced}
            searchCustomersDebounced={searchCustomersDebounced}
            searchRequests={searchRequests}
            searchTotalRequestRecordsAndRequests={
              searchTotalRequestRecordsAndRequests
            }
            pagination={pagination}
          />
        }
      />
    </Routes>
  );
}
