import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ApproveAndDisapproveExpense } from "./ApproveAndDisapproveExpense/ApproveAndDisapproveExpense";
import { Historical } from "./Historical/Historical";
import { usePreviousRoute } from "../../../../../hooks/navigation";

export function ViewExpense({ selectedExpense, setTable }) {
  const backUrl = usePreviousRoute();

  if (!selectedExpense) {
    return <Navigate to={backUrl} />;
  }

  return (
    <Routes>
      <Route path="/" element={<ApproveAndDisapproveExpense selectedExpense={selectedExpense} setTable={setTable} />} />
      <Route path="historico" element={<Historical selectedExpense={selectedExpense} />} />
    </Routes>
  );
}
