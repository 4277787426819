import React from "react";
import { Pencil } from "phosphor-react";
import { Table, TBody, Td, Th, THead, Tr } from "../../../../../components/Data/Table/index";
import { Button } from "../../../../../components/Form/index";
import { TPremiationTypesList } from "../../GeneralSettings";
import { TCurrentPage } from "../PremiationTypes";

import styles from "./PremiationTypesList.module.css";

interface IPremiationTypesListProps {
  premiationTypesList: TPremiationTypesList;
  setCurrentPage: React.Dispatch<React.SetStateAction<TCurrentPage>>;
}

export function PremiationTypesList({ premiationTypesList, setCurrentPage }: IPremiationTypesListProps) {
  return (
    <div>
      <div className={styles.navContainer}>
        <Button
          onClick={() => {
            setCurrentPage("new");
          }}
        >
          Novo Tipo Premiação
        </Button>
      </div>
      <Table title={"Tipos de Premiação"} className={styles.table}>
        <THead>
          <Tr>
            <Th width="10px">ID</Th>
            <Th width="">Tipo</Th>
            <Th width="10px">Situação</Th>
            <Th width="12.5rem">Data de Alteração</Th>
            <Th width="10px" children={undefined}></Th>
          </Tr>
        </THead>
        <TBody>
          {premiationTypesList.map((item, index) => (
            <Tr key={index}>
              <Td heading={"ID"}>{item.id}</Td>
              <Td heading={"Tipo"}>{item.tipo}</Td>
              <Td heading={"Situação"}>{item.situacao}</Td>
              <Td heading={"Data de Alteração"}>{item.dataAlteracao}</Td>
              <Td heading={"Editar"} data-option>
                <Button data-option-button className={styles.editButton}>
                  <Pencil weight="fill" />
                </Button>
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    </div>
  );
}
