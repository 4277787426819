import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Button, Input, LinkButton, Select } from "../../../../../components/Form";
import { Circle } from "../../../../../components/Loading";
import { isValid } from "../../../../../helpers/validations";
import { useCustomFetch, useDebounce, useLoading } from "../../../../../hooks/async";
import { useChanges, useModal } from "../../../../../hooks/contexts";
import { useForm, useSelect } from "../../../../../hooks/form";
import { IPriceRange, ISearchTablesFormulasEnterprises, ITableFormulaEnterprise } from "../PricesRange";

import styles from "./UpdatePriceRange.module.css";

interface IInsertNewPriceRangeResponse {
  response: number;
  message: string;
  status: number;
  object: any;
}

interface INewPriceRangeProps {
  searchTablesPromise: (description?: string) => Promise<ISearchTablesFormulasEnterprises>;
  searchFormulasPromise: (description?: string) => Promise<ISearchTablesFormulasEnterprises>;
  searchEnterprisesPromise: (description?: string) => Promise<ISearchTablesFormulasEnterprises>;
  filters: {
    priceRange?: string;
    tableId?: number;
    formulaId?: number;
  };
  researchPriceRanges: (description?: string, tableId?: number, formulaId?: number) => Promise<void>;
  selectedPriceRange: IPriceRange | null;
}

export function UpdatePriceRange({
  searchTablesPromise,
  searchFormulasPromise,
  searchEnterprisesPromise,
  filters,
  researchPriceRanges,
  selectedPriceRange,
}: INewPriceRangeProps) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();
  const { setIsChangesDetected } = useChanges();

  const minPrice = useForm({ type: "number", required: true, numberRange: { min: 0 } });
  const maxPrice = useForm({ type: "number", required: true, numberRange: { min: 0 } });
  const table = useSelect({ type: "single", required: true });
  const formula = useSelect({ type: "single", required: true });
  const enterprise = useSelect({ type: "single", required: true });
  const commissionValue = useForm({ type: "number", required: true, numberRange: { min: 0 } });
  const color = useForm({ type: "text", required: true });
  const situation = useSelect({ type: "single", required: true });

  const searchingTableOptions = useLoading();
  const searchingFormulaOptions = useLoading();
  const searchingEnterpriseOptions = useLoading();
  const updatingPriceRange = useLoading();

  const [tableOptions, setTableOptions] = React.useState<{ label: string; value: ITableFormulaEnterprise }[]>([]);
  const [formulaOptions, setFormulaOptions] = React.useState<{ label: string; value: ITableFormulaEnterprise }[]>([]);
  const [enterpriseOptions, setEnterpriseOptions] = React.useState<{ label: string; value: ITableFormulaEnterprise }[]>(
    []
  );
  const situationOptions = [
    {
      label: "Ativo",
      value: true,
    },
    {
      label: "Inativo",
      value: false,
    },
  ];

  const searchTables = React.useCallback(
    async (description: string = "") => {
      try {
        searchingTableOptions.setLoading(true);
        const json = await searchTablesPromise(description);
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            label: `${option.id} | ${option.descricao}`,
            value: option,
          }));
          setTableOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setTableOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingTableOptions.setLoading(false);
      }
    },
    [Modal, searchTablesPromise, searchingTableOptions]
  );

  const searchFormulas = React.useCallback(
    async (description: string = "") => {
      try {
        searchingFormulaOptions.setLoading(true);
        const json = await searchFormulasPromise(description);
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            label: `${option.id} | ${option.descricao}`,
            value: option,
          }));
          setFormulaOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setFormulaOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingFormulaOptions.setLoading(false);
      }
    },
    [Modal, searchFormulasPromise, searchingFormulaOptions]
  );

  const searchEnterprises = React.useCallback(
    async (description: string = "") => {
      try {
        searchingEnterpriseOptions.setLoading(true);
        const json = await searchEnterprisesPromise(description);
        if (json.status === 200) {
          const options = json.object.map((option) => ({
            label: `${option.id} | ${option.descricao}`,
            value: option,
          }));
          setEnterpriseOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setEnterpriseOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingEnterpriseOptions.setLoading(false);
      }
    },
    [Modal, searchEnterprisesPromise, searchingEnterpriseOptions]
  );

  const searchTablesDebounced = useDebounce(searchTables);

  const searchFormulasDebounced = useDebounce(searchFormulas);

  const searchEnterprisesDebounced = useDebounce(searchEnterprises);

  const handleClickSavePriceRange = async () => {
    if (isValid(minPrice, maxPrice, table, formula, enterprise, commissionValue, color)) {
      try {
        updatingPriceRange.setLoading(true);
        const json = (await customFetch("/commissions/insertAndUpdatePriceRange", {
          body: {
            id: selectedPriceRange?.id,
            idTabela: (table.value as unknown as any).value.id,
            idFormulado: (formula.value as unknown as any).value.id,
            idEmpresa: (enterprise.value as unknown as any).value.id,
            precoMinimo: Number(minPrice.value),
            precoMaximo: Number(maxPrice.value),
            status: (situation.value as unknown as any).value,
            comissaoValor: Number(commissionValue.value),
            rgb: color.value,
          },
        })) as IInsertNewPriceRangeResponse;
        if (json.status === 200) {
          setIsChangesDetected(false);
          await Modal.success(json.message);
          researchPriceRanges(filters.priceRange, filters.tableId, filters.formulaId);
          navigate("/comissoes/foliar/faixas-de-preco");
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        updatingPriceRange.setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    searchTables();
    searchFormulas();
    searchEnterprises();

    if (selectedPriceRange) {
      minPrice.setValue(selectedPriceRange.precoMinimo ? String(selectedPriceRange.precoMinimo) : "");
      maxPrice.setValue(selectedPriceRange.precoMaximo ? String(selectedPriceRange.precoMaximo) : "");
      commissionValue.setValue(selectedPriceRange.comissaoValor ? String(selectedPriceRange.comissaoValor) : "");
      table.setValue(() => ({
        label: selectedPriceRange.tabela.descricao,
        value: {
          id: selectedPriceRange.tabela.idTabela,
          descricao: selectedPriceRange.tabela.descricao,
        },
      }));
      formula.setValue(() => ({
        label: selectedPriceRange.formulado.descricao,
        value: {
          id: selectedPriceRange.formulado.idFormulado,
          descricao: selectedPriceRange.formulado.descricao,
        },
      }));
      enterprise.setValue(() => ({
        label: selectedPriceRange.empresa.descricao,
        value: {
          id: selectedPriceRange.empresa.idEmpresa,
          descricao: selectedPriceRange.empresa.descricao,
        },
      }));
      color.setValue(selectedPriceRange.rgb || "#000000");
      situation.setValue(selectedPriceRange.status === "A" ? situationOptions[0] : situationOptions[1]);
    }
  }, []); // eslint-disable-line

  if (!selectedPriceRange) {
    return <Navigate to={"/comissoes/foliar/faixas-de-preco"} />;
  }

  return (
    <div className="container">
      {!updatingPriceRange.isLoading ? (
        <>
          <div>
            <LinkButton
              to="/comissoes/foliar/faixas-de-preco"
              buttonStyle="backButton"
              children={undefined}
              variant={undefined}
              className={undefined}
              classNameContainer={undefined}
              disabled={undefined}
            />
          </div>
          <div className={styles.fieldsContainer}>
            <div data-grid="minPrice">
              <label htmlFor="minPrice" className="label">
                Preço Mínimo
              </label>
              <Input
                id="minPrice"
                placeholder="Digite o preço mínimo"
                type="number"
                className={`cleanInputNumber`}
                value={minPrice.value}
                error={minPrice.error as unknown as string}
                onChange={(e) => {
                  minPrice.onChange(e);
                  setIsChangesDetected(true);
                }}
                onBlur={minPrice.onBlur}
                onWheel={(event) => {
                  event.preventDefault();
                  event.currentTarget.blur();
                }}
              />
            </div>
            <div data-grid="maxPrice">
              <label htmlFor="maxPrice" className="label">
                Preço Máximo
              </label>
              <Input
                id="maxPrice"
                placeholder="Digite o preço máximo"
                type="number"
                className={`cleanInputNumber`}
                value={maxPrice.value}
                error={maxPrice.error as unknown as string}
                onChange={(e) => {
                  maxPrice.onChange(e);
                  setIsChangesDetected(true);
                }}
                onBlur={maxPrice.onBlur}
                onWheel={(event) => {
                  event.preventDefault();
                  event.currentTarget.blur();
                }}
              />
            </div>
            <div data-grid="commissionValue">
              <label htmlFor="commissionValue" className="label">
                Valor Comissão
              </label>
              <Input
                id="commissionValue"
                placeholder="Digite o valor da comissão"
                type="number"
                className={`cleanInputNumber`}
                value={commissionValue.value}
                error={commissionValue.error as unknown as string}
                onChange={(e) => {
                  commissionValue.onChange(e);
                  setIsChangesDetected(true);
                }}
                onBlur={commissionValue.onBlur}
                onWheel={(event) => {
                  event.preventDefault();
                  event.currentTarget.blur();
                }}
              />
            </div>
            <div data-grid="table">
              <label htmlFor="table" className="label">
                Tabela
              </label>
              <Select
                id="table"
                placeholder="Selecione uma tabela"
                options={tableOptions}
                value={table.value}
                defaultValue={undefined}
                error={table.error}
                onChange={(value: any) => {
                  table.onChange(value);
                  setIsChangesDetected(true);
                }}
                onInputChange={searchTablesDebounced}
                onBlur={table.onBlur}
                isLoading={searchingTableOptions.isLoading}
              />
            </div>
            <div data-grid="formula">
              <label htmlFor="formula" className="label">
                Formulado
              </label>
              <Select
                id="formula"
                placeholder="Selecione um formulado"
                options={formulaOptions}
                value={formula.value}
                defaultValue={undefined}
                error={formula.error}
                onChange={(value: any) => {
                  formula.onChange(value);
                  setIsChangesDetected(true);
                }}
                onInputChange={searchFormulasDebounced}
                onBlur={formula.onBlur}
                isLoading={searchingFormulaOptions.isLoading}
              />
            </div>
            <div data-grid="enterprise">
              <label htmlFor="enterprise" className="label">
                Empresa
              </label>
              <Select
                id="enterprise"
                placeholder="Selecione uma empresa"
                options={enterpriseOptions}
                value={enterprise.value}
                defaultValue={undefined}
                error={enterprise.error}
                onChange={(value: any) => {
                  enterprise.onChange(value);
                  setIsChangesDetected(true);
                }}
                onInputChange={searchEnterprisesDebounced}
                onBlur={enterprise.onBlur}
                isLoading={searchingEnterpriseOptions.isLoading}
              />
            </div>
            <div data-grid="color">
              <label htmlFor="color" className="label">
                Cor da Faixa
              </label>
              <input
                type="color"
                id="color"
                className="inputColor"
                value={color.value}
                onChange={(e) => {
                  color.onChange(e);
                  setIsChangesDetected(true);
                }}
              />
            </div>
            <div data-grid="situation">
              <label htmlFor="situation" className="label">
                Situação
              </label>
              <Select
                id="situation"
                options={situationOptions}
                value={situation.value}
                defaultValue={undefined}
                error={situation.error}
                onChange={(value: any) => {
                  situation.onChange(value);
                  setIsChangesDetected(true);
                }}
                isClearable={false}
                isSearchable={false}
              />
            </div>
            <div data-grid="saveButton">
              <Button onClick={handleClickSavePriceRange}>Salvar</Button>
            </div>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Atualizando Faixa de Preços</span>
        </div>
      )}
    </div>
  );
}
