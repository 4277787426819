import React from "react";

import { IUseSolicitationList } from "../hooks/types";
import { ISolicitation } from "../types";
import { Table, TBody, Th, THead, Tr, Td } from "../../../../../components/Data/Table";
import { Button, Input } from "../../../../../components/Form";
import { useNavigate } from "react-router-dom";
import { Eye } from "phosphor-react";
import { Circle } from "../../../../../components/Loading";
import { Paginate } from "../../../../../components/Paginate/Paginate";

import styles from "./List.module.css";

interface Props {
  solicitationList: IUseSolicitationList;
  setSelectedSolicitation: React.Dispatch<React.SetStateAction<ISolicitation | null>>;
}

export function List({
  solicitationList: {
    pagination,
    filter: { description },
    searchSolicitations,
    searchTotalSolicitationRecordsAndSolicitationsDebounced,
    searchingSolicitations,
    solicitationList: [solicitationList],
  },
  setSelectedSolicitation,
}: Props) {
  const navigate = useNavigate();

  const [_, resetInterval] = React.useState(true);

  React.useEffect(() => {
    const fetchData = () => {
      searchTotalSolicitationRecordsAndSolicitationsDebounced(description.value);
    };

    const newIntervalId = setInterval(fetchData, 60 * 1000);

    return () => clearInterval(newIntervalId);
  }, [_, description.value]); // eslint-disable-line

  React.useEffect(() => {
    setSelectedSolicitation(null);
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.filtersContainer}>
        <div>
          <label htmlFor="description" className="label">
            Descrição
          </label>
          <Input
            id="description"
            placeholder="Digite a descrição"
            value={description.value}
            onChange={(e) => {
              description.onChange(e);
              searchTotalSolicitationRecordsAndSolicitationsDebounced(e.target.value);
            }}
            autoComplete="off"
          />
        </div>
      </div>
      {solicitationList.length && !searchingSolicitations ? (
        <Table title={"Solicitações Aprovadas no Comercial"} className={styles.table}>
          <THead>
            <Tr>
              <Th width={".625rem"}>ID</Th>
              <Th width={""}>Descrição Contrato</Th>
              <Th width={""} children={undefined} />
            </Tr>
          </THead>
          <TBody>
            {solicitationList.map((solicitation, index) => (
              <Tr key={index}>
                <Td heading={"ID"}>{solicitation.idSolicitacao}</Td>
                <Td heading={"Descrição Contrato"}>{solicitation.descricaoContrato}</Td>
                <Td heading="Ver Solicitação" width="6.25rem" data-option>
                  <Button
                    type="button"
                    variant="edit"
                    className={styles.seeRequestButton}
                    onClick={() => {
                      setSelectedSolicitation(solicitation);
                      navigate("analise");
                    }}
                    data-option-button>
                    <Eye weight="fill" />
                  </Button>
                </Td>
              </Tr>
            ))}
          </TBody>
        </Table>
      ) : searchingSolicitations ? (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
        </div>
      ) : (
        <p className={`lineCardMessage`}>Nenhuma solicitação encontrada</p>
      )}
      <Paginate
        classNameContainer={styles.paginationContainer}
        maxItems={pagination.maxItems}
        totalRecords={pagination.totalRecords}
        currentPage={pagination.currentPage}
        setCurrentPage={pagination.setCurrentPage}
        onPageChange={(page) => {
          resetInterval((crr) => !crr);
          searchSolicitations(page - 1, description.value);
        }}
      />
    </div>
  );
}
