import React from "react";

import { Route, Routes } from "react-router-dom";

import { UpdateUser } from "./UpdateUser/UpdateUser";
import { NewUser } from "./NewUser/NewUser";
import { useModal } from "../../../hooks/contexts";
import { useCustomFetch } from "../../../hooks/async";
import { useMemo } from "react";

export function Users() {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const [optionsEnterprise, setOptionsEnterprise] = React.useState([]);
  const [optionsPortal, setOptionsPortal] = React.useState([]);
  const [optionsSupervisor, setOptionsSupervisor] = React.useState([]);

  const [isSearchingOptionsEnterprise, setIsSearchingOptionsEnterprise] = React.useState(false);
  const [isSearchingOptionsPortal, setIsSearchingOptionsPortal] = React.useState(false);
  const [isSearchingOptionsSupervisor, setIsSearchingOptionsSupervisor] = React.useState(false);

  const searchOptionsEnterprise = React.useCallback(async () => {
    try {
      setIsSearchingOptionsEnterprise(true);
      const json = await customFetch("/admin/searchEnterprises", {
        body: {
          pesquisa: "",
        },
      });
      if (json.status === 200) {
        const options = json.object.map((enterprise) => {
          return { value: enterprise, label: `${enterprise.idEmpresa} | ${enterprise.nomeEmpresa}` };
        });
        setOptionsEnterprise(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      setIsSearchingOptionsEnterprise(false);
    }
  }, [Modal, customFetch]);

  const searchOptionsPortal = React.useCallback(async () => {
    try {
      setIsSearchingOptionsPortal(true);
      const json = await customFetch("/admin/searchPortals", {
        body: {
          pesquisa: "",
        },
      });
      if (json.status === 200) {
        const options = json.object.map((portal) => {
          return { value: portal, label: `${portal.idPortal} | ${portal.portalSlug}` };
        });
        setOptionsPortal(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      setIsSearchingOptionsPortal(false);
    }
  }, [Modal, customFetch]);

  const searchOptionsSupervisor = React.useCallback(async () => {
    try {
      setIsSearchingOptionsSupervisor(true);
      const json = await customFetch("/admin/searchSupervisors", {
        method: "GET",
        headers: { Accept: "application/json" },
      });
      if (json.status === 200) {
        const options = json.object.map((supervisor) => {
          return { value: supervisor, label: `${supervisor.id} | ${supervisor.nome}` };
        });
        setOptionsSupervisor(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      setIsSearchingOptionsSupervisor(false);
    }
  }, [Modal, customFetch]);

  // clear userData before render component
  useMemo(() => {
    window.sessionStorage.removeItem("userData");
  }, []);

  React.useEffect(() => {
    searchOptionsEnterprise();
    searchOptionsPortal();
    searchOptionsSupervisor();
    return () => {
      window.sessionStorage.removeItem("userData");
    };
  }, []); //eslint-disable-line

  return (
    <div className={`container`}>
      <Routes>
        <Route
          path="/*"
          element={
            <UpdateUser
              optionsEnterprise={optionsEnterprise}
              optionsPortal={optionsPortal}
              optionsSupervisor={optionsSupervisor}
              isSearchingOptionsEnterprise={isSearchingOptionsEnterprise}
              isSearchingOptionsPortal={isSearchingOptionsPortal}
              isSearchingOptionsSupervisor={isSearchingOptionsSupervisor}
            />
          }
        />
        <Route
          path="novo"
          element={
            <NewUser
              optionsEnterprise={optionsEnterprise}
              optionsPortal={optionsPortal}
              optionsSupervisor={optionsSupervisor}
              isSearchingOptionsEnterprise={isSearchingOptionsEnterprise}
              isSearchingOptionsPortal={isSearchingOptionsPortal}
              isSearchingOptionsSupervisor={isSearchingOptionsSupervisor}
            />
          }
        />
      </Routes>
    </div>
  );
}
