import React, { KeyboardEventHandler } from "react";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import CreatableSelect from "react-select/creatable";
import { MultipleSelectValueType } from "../Select/Select";

import styles from "./InputMultiple.module.css";

interface CustomStyle extends React.CSSProperties {
  [key: string]: any;
}

interface IProps extends StateManagerProps {
  containerId?: string;
  value: IUseSelect<string, "multiple">;
  validate?: (value: string) => boolean;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

export function InputMultiple({ id, containerId, value, validate, ...props }: IProps) {
  const [inputValue, setInputValue] = React.useState("");

  const isFilled = React.useCallback(() => {
    if (Array.isArray(value.value)) return !!value.value?.length;
    else return false;
  }, [value]);

  const classStates = `${props.isSearchable ? "isSearchable" : ""} ${isFilled() ? "isFilled" : ""} ${
    value.error ? "isInvalid" : ""
  } ${props.isDisabled ? "isDisabled" : ""}`.trim();

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (validate && !validate(inputValue)) {
          event.preventDefault();
          return;
        }
        value.setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };

  return (
    <div style={{ "--default-border-color": "#00ff15" } as CustomStyle}>
      <CreatableSelect
        classNamePrefix={`${classStates} ${styles["react-select"]}`}
        components={{
          DropdownIndicator: null,
        }}
        inputId={id}
        id={containerId}
        value={value.value}
        inputValue={inputValue}
        onChange={(newValue) => {
          value.onChange(newValue as MultipleSelectValueType<string>);
        }}
        onInputChange={(newValue) => {
          setInputValue(newValue);
        }}
        onKeyDown={handleKeyDown}
        menuIsOpen={false}
        isMulti
        isClearable
        isSearchable
        {...props}
      />
      {value.error && !props.isDisabled && <span className={styles.error}>{value.error}</span>}
    </div>
  );
}
