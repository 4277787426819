import React from "react";

import { useForm, useSelect } from "../../../../hooks/form";
import { IContractTableAnalysis } from "../../types";
import { isValid } from "../../../../helpers/validations";

export function useContractTableAnalysis(): IContractTableAnalysis {
  const [items, setItems] = React.useState<IContractTableAnalysis["items"]["value"]>([]);

  const defaultColor = useSelect({ type: "single", required: true });
  const defaultLetter = useForm({ required: true });

  const validate = () => {
    return isValid(defaultColor, defaultLetter);
  };

  const reset = () => {
    setItems([]);
    defaultColor.reset();
    defaultLetter.reset();
  };

  return { items: { value: items, setValue: setItems }, defaultColor, defaultLetter, validate, reset };
}
