import React from "react";

import {
  IContractData,
  IContractPremiations,
  IContractTable,
} from "../../types";
import { Input, InputNumber, Select } from "../../../../components/Form";
import { IUseSelectOptions } from "../../components/hooks/types";
import { PageType } from "../types";
import { IUseChanges } from "../../../../hooks/contexts/useChanges";

import styles from "./ContractData.module.css";
import dayjs from "dayjs";

interface Props {
  data: IContractData;
  premiations: IContractPremiations;
  tableItems?: IContractTable["items"];
  tableOptions: IUseSelectOptions["tableOptions"];
  customerOptions: IUseSelectOptions["customerOptions"];
  productGroupOptions?: IUseSelectOptions["productGroupOptions"];
  page: PageType;
  permissions?: string[];
  changesDetected?: IUseChanges;
}

const simpleOptions = [
  { label: "Sim", value: true },
  { label: "Não", value: false },
];

const fieldIsDisabled = (
  page: PageType,
  permissions: string[],
  permission: string
) => {
  switch (page) {
    case PageType.GERENTE:
      return false;
    case PageType.COMERCIAL:
      return !permissions.includes(permission);
    case PageType.CLIENTE:
      if (permission === "CONTRATO_SOLICITACAO_ALTERA_VALOR")
        return !permissions.includes(permission);
      else return true;
    default:
      return true;
  }
};

export function ContractData({
  data,
  premiations,
  tableItems,
  tableOptions,
  customerOptions,
  productGroupOptions,
  page,
  permissions = [],
  changesDetected,
}: Props) {
  const {
    solicitationDate,
    table,
    description,
    customer,
    value,
    averagePrice,
    interestPerMonth,
    deliveryDoc,
    deliveredDoc,
    chargeShipping,
    shippingValue,
    embedShipping,
    dueDate,
    totalQuantity,
    productGroup,
    payPremiation,
    handleChangeProductGroup,
    changeTable: { handle: handleChangeTable },
    changeInterestRelated: { handle: handleChangeInterestRelatedDebounced },
  } = data;

  return (
    <div className={styles.container}>
      <div className={styles.tableFieldsContainer}>
        <div className={styles.field}>
          <label htmlFor="solicitationDate" className="label">
            Solicitação do Contrato
          </label>
          <Input
            id="solicitationDate"
            type="date"
            value={solicitationDate.value}
            error={solicitationDate.error}
            onChange={(e) => {
              changesDetected?.set();
              solicitationDate.onChange(e);
              if (page === PageType.GERENTE || page === PageType.COMERCIAL)
                handleChangeInterestRelatedDebounced({
                  solicitationDate: e.target.value,
                });
            }}
            onBlur={solicitationDate.onBlur}
            disabled={fieldIsDisabled(
              page,
              permissions,
              "CONTRATO_SOLICITACAO_ALTERA_DATA"
            )}
          />
        </div>
        <div className={styles.field}>
          <label htmlFor="productGroup" className="label">
            Grupo do Produto
          </label>
          <Select
            id="productGroup"
            placeholder="Selecione o grupo do produto"
            options={productGroupOptions?.options}
            value={productGroup.value}
            error={productGroup.error}
            onChange={(value) => {
              changesDetected?.set();
              if (tableItems) {
                handleChangeProductGroup(value, tableItems);
                table.reset();
                tableOptions.search("", value.value.id);
              }
            }}
            onBlur={productGroup.onBlur}
            isClearable={false}
            isSearchable={false}
            isDisabled={fieldIsDisabled(page, permissions, "")}
            isLoading={productGroupOptions?.loading}
          />
        </div>
        {page !== PageType.FINANCEIRO && (
          <div
            className={`${styles.field} ${styles.tableFieldsContainer__validityTable}`}
          >
            <label htmlFor="table" className="label">
              Tabela de Vigência
            </label>
            <Select
              id="table"
              placeholder="Selecione uma tabela de vigência"
              options={tableOptions.loading ? [] : tableOptions.options}
              value={table.value}
              error={table.error}
              onInputChange={(value) =>
                tableOptions.searchDebounced(
                  value,
                  productGroup.value?.value.id
                )
              }
              onChange={(value) => {
                changesDetected?.set();
                if (tableItems) handleChangeTable(value, tableItems);
              }}
              onBlur={table.onBlur}
              isLoading={tableOptions.loading}
              isDisabled={
                fieldIsDisabled(page, permissions, "") || !productGroup.value
              }
            />
          </div>
        )}
      </div>
      <div>
        <label htmlFor="description" className="label">
          Descrição do Contrato
        </label>
        <Input
          id="description"
          placeholder={"Digite a descrição do contrato"}
          value={description.value}
          error={description.error}
          onChange={(e) => {
            changesDetected?.set();
            description.onChange(e);
          }}
          onBlur={description.onBlur}
          disabled={fieldIsDisabled(
            page,
            permissions,
            "CONTRATO_SOLICITACAO_ALTERA_DESCRICAO"
          )}
          autoComplete="off"
        />
      </div>
      <div className={styles.customerFieldsContainer}>
        <div className={styles.field}>
          <label htmlFor="customer" className="label">
            Cliente
          </label>
          <Select
            id="customer"
            placeholder="Selecione um cliente"
            value={customer.value}
            error={customer.error}
            onChange={(e) => {
              changesDetected?.set();
              customer.onChange(e);
            }}
            onBlur={customer.onBlur}
            onInputChange={(description) => {
              customerOptions.searchDebounced(description);
            }}
            isDisabled={fieldIsDisabled(
              page,
              permissions,
              "CONTRATO_SOLICITACAO_ALTERA_DADOS_CLIENTE"
            )}
            options={customerOptions.options}
            isLoading={customerOptions.loading}
          />
        </div>
      </div>
      <div className={styles.valueFieldsContainer}>
        <div className={styles.field}>
          <label htmlFor="contractValue" className="label">
            Valor do Contrato
          </label>
          <InputNumber
            id="contractValue"
            placeholder="Digite o valor do contrato"
            className="cleanInputNumber"
            value={value.value}
            error={value.error}
            onChange={(e) => {
              changesDetected?.set();
              value.onChange(e);
            }}
            onBlur={value.onBlur}
            disabled={fieldIsDisabled(
              page,
              permissions,
              "CONTRATO_SOLICITACAO_ALTERA_VALOR"
            )}
            autoComplete="off"
          />
        </div>
        {page !== PageType.GERENTE && (
          <>
            <div className={styles.field}>
              <label htmlFor="averagePrice" className="label">
                Preço Médio
              </label>
              <InputNumber
                id="averagePrice"
                placeholder="Digite o preço médio do contrato"
                className="cleanInputNumber"
                value={averagePrice.value}
                error={averagePrice.error}
                onChange={(e) => {
                  changesDetected?.set();
                  averagePrice.onChange(e);
                }}
                onBlur={averagePrice.onBlur}
                disabled={fieldIsDisabled(
                  page,
                  permissions,
                  "CONTRATO_SOLICITACAO_ALTERA_PRECO_MEDIO"
                )}
                autoComplete="off"
              />
            </div>
            <div className={styles.field}>
              <label htmlFor="totalContractQuantity" className="label">
                Quantidade Média (TN)
              </label>
              <InputNumber
                id="totalContractQuantity"
                placeholder="Digite a quantidade total do contrato"
                className="cleanInputNumber"
                value={totalQuantity.value}
                error={totalQuantity.error}
                onChange={(e) => {
                  changesDetected?.set();
                  totalQuantity.onChange(e);
                }}
                onBlur={totalQuantity.onBlur}
                disabled={
                  fieldIsDisabled(
                    page,
                    permissions,
                    "CONTRATO_SOLICITACAO_ALTERA_QUANTIDADE"
                  ) || true
                }
                autoComplete="off"
              />
            </div>
          </>
        )}
        <div className={styles.field}>
          <label htmlFor="interestPerMonth" className="label">
            Juros a.m (%)
          </label>
          <InputNumber
            id="interestPerMonth"
            placeholder="Digite o juros por mês"
            className="cleanInputNumber"
            value={interestPerMonth.value}
            error={interestPerMonth.error}
            onChange={(e) => {
              changesDetected?.set();
              interestPerMonth.onChange(e);
              if (page === PageType.GERENTE || page === PageType.COMERCIAL)
                handleChangeInterestRelatedDebounced({
                  interestPerMonth: e.target.value,
                });
            }}
            onBlur={interestPerMonth.onBlur}
            disabled={fieldIsDisabled(
              page,
              permissions,
              "CONTRATO_SOLICITACAO_ALTERA_JUROS"
            )}
            autoComplete="off"
          />
        </div>
      </div>
      <div className={styles.documentationFieldsContainer}>
        {![PageType.GERENTE, PageType.CONFERENCIA, PageType.CLIENTE].includes(
          page
        ) && (
          <div>
            <label htmlFor="deliveryDoc" className="label">
              Entrega Documentação
            </label>
            <Input
              id="deliveryDoc"
              type="date"
              value={deliveryDoc.value}
              error={deliveryDoc.error}
              onChange={(e) => {
                changesDetected?.set();
                deliveryDoc.onChange(e);
              }}
              onBlur={deliveryDoc.onBlur}
              disabled={fieldIsDisabled(
                page,
                permissions,
                "CONTRATO_SOLICITACAO_ALTERA_DATA_ENTREGA_DOC"
              )}
            />
          </div>
        )}
        {page !== PageType.GERENTE && (
          <div>
            <label htmlFor="deliveredDocumentation" className="label">
              Documentação Entregue
            </label>
            <Select
              id="deliveredDocumentation"
              options={simpleOptions}
              value={deliveredDoc.value}
              error={deliveredDoc.error}
              onChange={(value) => {
                changesDetected?.set();
                deliveredDoc.onChange(value);
              }}
              onBlur={deliveredDoc.onBlur}
              isClearable={false}
              isSearchable={false}
              isDisabled={fieldIsDisabled(
                page,
                permissions,
                "CONTRATO_SOLICITACAO_ALTERA_ENTREGA_DOC"
              )}
            />
          </div>
        )}
        <div>
          <label htmlFor="payPremiation" className="label">
            Paga Premiação
          </label>
          <Select
            id="payPremiation"
            options={simpleOptions}
            value={payPremiation.value}
            error={payPremiation.error}
            onChange={(value) => {
              changesDetected?.set();
              if (!value?.value) premiations.reset();
              payPremiation.onChange(value);
            }}
            onBlur={payPremiation.onBlur}
            isClearable={false}
            isSearchable={false}
            isDisabled={fieldIsDisabled(
              page,
              permissions,
              "CONTRATO_SOLICITACAO_ALTERA_PREMIACAO"
            )}
          />
        </div>
      </div>
      <div className={styles.freightFieldsContainer}>
        <div>
          <label htmlFor="chargeShipping" className="label">
            Cobrar Frete
          </label>
          <Select
            id="chargeShipping"
            options={simpleOptions}
            value={chargeShipping.value}
            error={chargeShipping.error}
            onChange={(value) => {
              changesDetected?.set();
              if (!value?.value) {
                shippingValue.reset();
                embedShipping.reset();
              }
              chargeShipping.onChange(value);
            }}
            onBlur={chargeShipping.onBlur}
            isClearable={false}
            isSearchable={false}
            isDisabled={fieldIsDisabled(
              page,
              permissions,
              "CONTRATO_SOLICITACAO_ALTERA_FRETE"
            )}
          />
        </div>
        <div>
          <label htmlFor="shippingValue" className="label">
            Valor Frete
          </label>
          <InputNumber
            id="shippingValue"
            className="cleanInputNumber"
            placeholder="Digite o valor do frete"
            value={shippingValue.value}
            error={shippingValue.error}
            onChange={(e) => {
              changesDetected?.set();
              shippingValue.onChange(e);
            }}
            onBlur={shippingValue.onBlur}
            disabled={
              !chargeShipping.value?.value ||
              fieldIsDisabled(
                page,
                permissions,
                "CONTRATO_SOLICITACAO_ALTERA_FRETE"
              )
            }
            autoComplete="off"
          />
        </div>
        <div>
          <label htmlFor="embedShipping" className="label">
            Embutir Valor do Frete
          </label>
          <Select
            id="embedShipping"
            options={simpleOptions}
            value={embedShipping.value}
            error={embedShipping.error}
            onChange={(value) => {
              changesDetected?.set();
              embedShipping.onChange(value);
            }}
            onBlur={embedShipping.onBlur}
            isClearable={false}
            isSearchable={false}
            isDisabled={
              !chargeShipping.value?.value ||
              fieldIsDisabled(
                page,
                permissions,
                "CONTRATO_SOLICITACAO_ALTERA_EMBUTIR_FRETE"
              )
            }
          />
        </div>
      </div>
      <div className={styles.dueDateFieldsContainer}>
        {dueDate.map((date, index, dates) => (
          <div key={index}>
            <label htmlFor={`dueDate${index + 1}`} className="label">
              Vencimento {index + 1}
            </label>
            <Input
              id={`dueDate${index + 1}`}
              type="date"
              value={date.value}
              min={
                dates[index - 1]?.value
                  ? dayjs(dates[index - 1]?.value)
                      .add(1, "day")
                      .format("YYYY-MM-DD")
                  : undefined
              }
              error={date.error}
              onChange={(e) => {
                changesDetected?.set();
                date.onChange(e);
                const datesToChangeInterestRelatedFunction = dueDate.map(
                  (date) => date.value
                );
                datesToChangeInterestRelatedFunction[index] = e.target.value;
                if (!e.target.value) {
                  for (let i = index + 1; i < dates.length; i++) {
                    dates[i].reset();
                    datesToChangeInterestRelatedFunction[i] = "";
                  }
                }
                if (page === PageType.GERENTE || page === PageType.COMERCIAL)
                  handleChangeInterestRelatedDebounced({
                    dueDates: datesToChangeInterestRelatedFunction,
                  });
              }}
              onBlur={date.onBlur}
              disabled={
                fieldIsDisabled(
                  page,
                  permissions,
                  "CONTRATO_SOLICITACAO_ALTERA_VENCIMENTO"
                ) ||
                (dates[index - 1] && !dates[index - 1].value)
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
}
