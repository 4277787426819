import React, { useState } from "react";

import { isValid } from "../../../../helpers/validations";
import { useDate, useForm, useSelect } from "../../../../hooks/form";
import {
  Customer,
  IContractData,
  IContractTable,
  IProductGroup,
  ITable,
  ITableItem,
} from "../../types";
import { useModal } from "../../../../hooks/contexts";
import {
  useCustomFetch,
  useDebounce,
  useLoading,
} from "../../../../hooks/async";
import { InterestCalculationMetrics } from "./types";

export function useContractData(): IContractData {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const tableAbortController = React.useRef(new AbortController());
  const interestAbortController = React.useRef(new AbortController());

  const interestCalculated = React.useState(1);
  const calculatingInterest = useLoading();

  // VALUES TO FORM

  const type = useSelect({
    type: "single",
    required: true,
    defaultValue: {
      label: "Por Margem",
      value: "M",
    },
  });
  const solicitationDate = useDate({ required: true });
  const table = useSelect({ type: "single", required: true });
  const description = useForm({ required: true });
  const customer = useSelect<Customer>({
    type: "single",
    required: true,
  });
  const value = useForm({ required: true });
  const averagePrice = useForm({ required: true });
  const interestPerMonth = useForm({ required: true });
  const deliveryDoc = useDate({ required: true });
  const deliveredDoc = useSelect({
    type: "single",
    required: true,
    defaultValue: {
      label: "Não",
      value: false,
    },
  });
  const chargeShipping = useSelect({
    type: "single",
    required: true,
    defaultValue: {
      label: "Sim",
      value: true,
    },
  });
  const shippingValue = useForm({ required: chargeShipping.value!.value });
  const embedShipping = useSelect({
    type: "single",
    required: chargeShipping.value!.value,
    defaultValue: {
      label: "Não",
      value: false,
    },
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dueDate = [
    useDate({ required: true }),
    useDate({ required: false }),
    useDate({ required: false }),
    useDate({ required: false }),
    useDate({ required: false }),
    useDate({ required: false }),
    useDate({ required: false }),
    useDate({ required: false }),
    useDate({ required: false }),
    useDate({ required: false }),
    useDate({ required: false }),
    useDate({ required: false }),
  ];
  const totalQuantity = useForm({ required: true });
  const productGroup = useSelect({ type: "single", required: true });
  const payPremiation = useSelect({
    type: "single",
    required: true,
    defaultValue: {
      label: "Sim",
      value: true,
    },
  });

  const [interestCalculationMetrics, setInterestCalculationMetrics] =
    useState<InterestCalculationMetrics | null>(null);

  const searchingTableItems = useLoading();
  const searchingInterestCalculationMetrics = useLoading();

  const searchTableItems = React.useCallback(
    async (tableId: number, items: IContractTable["items"]) => {
      tableAbortController.current = new AbortController();
      try {
        searchingTableItems.setLoading(true);
        const json = (await customFetch(
          "/contracts/validityTable/searchValidityTableItems",
          {
            body: {
              idTabelaVigencia: tableId,
            },
            signal: tableAbortController.current.signal,
          }
        )) as DefaultFetchResponse<ITableItem[]>;
        if (json.status === 200) {
          const data = json.object.map((item) => {
            return {
              ...item,
              faixaPreco: item.faixaPreco.map((priceRange) => {
                return {
                  ...priceRange,
                  escolhida: false,
                };
              }),
              precoVenda: 0,
              controll: {
                selected: true,
                selectedToAveragePrice: false,
              },
            };
          });
          items.setValue(data);
          searchingTableItems.setLoading(false);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
          searchingTableItems.setLoading(false);
        } else {
          items.setValue([]);
          searchingTableItems.setLoading(false);
        }
      } catch (error: any) {
        if (error.name === "AbortError") return;
        Modal.error(error);
        searchingTableItems.setLoading(false);
      }
    },
    [Modal, customFetch, searchingTableItems]
  );

  const handleChangeProductGroup = React.useCallback(
    async (
      value: ISelectOption<IProductGroup> | null,
      items: IContractTable["items"]
    ) => {
      if (table.value) {
        const confirm = await Modal.confirm(
          "<p>Esta ação irá limpar todos os dados relacionados à tabela atual</p></br><p>Deseja continuar?</p>"
        );
        if (confirm) {
          table.reset();
          tableAbortController.current.abort();
          items.setValue([]);
          productGroup.onChange(value);
        }
      } else {
        productGroup.onChange(value);
      }
    },
    [Modal, productGroup, table]
  );

  const handleChangeTable = async (
    value: ISelectOption<ITable> | null,
    items: IContractTable["items"]
  ) => {
    if (table.value) {
      const confirm = await Modal.confirm(
        "<p>Esta ação irá limpar todos os dados relacionados à tabela atual</p></br><p>Deseja continuar?</p>"
      );
      if (confirm) {
        table.onChange(value);
        if (value) {
          tableAbortController.current.abort();
          searchTableItems(value?.value?.idTabelaVigencia || 0, items);
          interestPerMonth.setValue(String(value?.value?.jurosMeses));
        } else {
          items.setValue([]);
          interestPerMonth.reset();
        }
      }
    } else {
      table.onChange(value);
      searchTableItems(value?.value?.idTabelaVigencia || 0, items);
      interestPerMonth.setValue(String(value?.value?.jurosMeses));
    }
  };

  const handleChangeInterestRelated = React.useCallback(
    async (values: {
      interestPerMonth?: string;
      solicitationDate?: string;
      dueDates?: string[];
    }) => {
      interestAbortController.current.abort();
      interestAbortController.current = new AbortController();

      const {
        interestPerMonth: interest,
        solicitationDate: solicitation,
        dueDates: dates,
      } = values;

      const body = {
        dataSolicitacao: undefined as string | undefined,
        valorJurosAM: undefined as number | undefined,
        vencimentos: [] as string[],
      };

      if (interest !== undefined) {
        body.valorJurosAM = interest ? Number(interest) : undefined;
        body.dataSolicitacao = solicitationDate.toISOString();
        body.vencimentos = dueDate
          .map((date) => (date.value ? date.toISOString() : ""))
          .filter((date) => date);
      } else if (solicitation !== undefined) {
        body.valorJurosAM = interestPerMonth.value
          ? Number(interestPerMonth.value)
          : undefined;
        body.dataSolicitacao = solicitation.replace(
          /^(\d{4}-\d{2}-\d{2})/,
          "$1T00:00:00.000Z"
        );
        body.vencimentos = dueDate
          .map((date) => (date.value ? date.toISOString() : ""))
          .filter((date) => date);
      } else if (dates !== undefined) {
        body.valorJurosAM = interestPerMonth.value
          ? Number(interestPerMonth.value)
          : undefined;
        body.dataSolicitacao = solicitationDate.toISOString();
        body.vencimentos = dates
          .map((date) =>
            date.replace(/^(\d{4}-\d{2}-\d{2})/, "$1T00:00:00.000Z")
          )
          .filter((date) => date);
      }

      if (
        !body.valorJurosAM ||
        !body.dataSolicitacao ||
        !body.vencimentos.some((date) => date)
      ) {
        interestCalculated[1](1);
        return;
      }

      try {
        calculatingInterest.setLoading(true);
        const json = (await customFetch("/contracts/calcInterest", {
          body,
          signal: interestAbortController.current.signal,
        })) as DefaultFetchResponse<number>;
        if (json.status === 200) {
          interestCalculated[1](json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
          interestCalculated[1](1);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        calculatingInterest.setLoading(false);
      }
    },
    [
      Modal,
      calculatingInterest,
      customFetch,
      dueDate,
      interestCalculated,
      interestPerMonth,
      solicitationDate,
    ]
  );

  const handleChangeInterestRelatedDebounced = useDebounce(
    handleChangeInterestRelated
  );

  const searchInterestCalculationMetric = React.useCallback(async () => {
    const body = {
      dataSolicitacao: solicitationDate.value
        ? solicitationDate.toISOString()
        : undefined,
      valorJurosAM: interestPerMonth.value
        ? Number(interestPerMonth.value)
        : undefined,
      vencimentos: dueDate
        .map((date) => (date.value ? date.toISOString() : ""))
        .filter((date) => date),
    };

    if (
      !body.dataSolicitacao ||
      !body.valorJurosAM ||
      !body.vencimentos.some((date) => date)
    )
      return;

    try {
      searchingInterestCalculationMetrics.setLoading(true);

      const json = (await customFetch(
        "/contracts/searchInterestCalculationMetric",
        {
          body,
        }
      )) as DefaultFetchResponse<InterestCalculationMetrics>;

      if (json.status === 200) {
        setInterestCalculationMetrics(json.object);
      } else {
        Modal.error(json.message, json.object);
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      searchingInterestCalculationMetrics.setLoading(false);
    }
  }, [
    Modal,
    customFetch,
    dueDate,
    interestPerMonth.value,
    searchingInterestCalculationMetrics,
    solicitationDate,
  ]);

  const validate = () => {
    return isValid(
      type,
      solicitationDate,
      table,
      description,
      customer,
      value,
      interestPerMonth,
      deliveredDoc,
      chargeShipping,
      shippingValue,
      embedShipping,
      productGroup,
      payPremiation,
      ...dueDate
    );
  };

  const reset = () => {
    type.reset();
    table.reset();
    description.reset();
    customer.reset();
    value.reset();
    averagePrice.reset();
    interestPerMonth.reset();
    deliveryDoc.reset();
    deliveredDoc.reset();
    chargeShipping.reset();
    shippingValue.reset();
    totalQuantity.reset();
    productGroup.reset();
    payPremiation.reset();
    dueDate.forEach((date) => date.reset());
    const nextMonth = new Date();
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    deliveryDoc.setValue(
      nextMonth.toJSON().replace(/^(\d{4}-\d{2}-\d{2}).+/, "$1")
    );
    const today = new Date();
    solicitationDate.setValue(
      today.toJSON().replace(/^(\d{4}-\d{2}-\d{2}).+/, "$1")
    );
  };

  React.useEffect(() => {
    const nextMonth = new Date();
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    deliveryDoc.setValue(
      nextMonth.toJSON().replace(/^(\d{4}-\d{2}-\d{2}).+/, "$1")
    );
    const today = new Date();
    solicitationDate.setValue(
      today.toJSON().replace(/^(\d{4}-\d{2}-\d{2}).+/, "$1")
    );
  }, []); // eslint-disable-line

  return {
    interestCalculated,
    type,
    solicitationDate,
    table,
    description,
    customer,
    value,
    averagePrice,
    interestPerMonth,
    deliveryDoc,
    deliveredDoc,
    chargeShipping,
    shippingValue,
    embedShipping,
    dueDate,
    totalQuantity,
    productGroup,
    payPremiation,
    handleChangeProductGroup,
    changeTable: {
      handle: handleChangeTable,
      searchingTableItems: searchingTableItems.isLoading,
    },
    changeInterestRelated: {
      handle: handleChangeInterestRelatedDebounced,
      calculatingInterest: calculatingInterest.isLoading,
    },
    interestCalculationMetrics: {
      search: searchInterestCalculationMetric,
      value: interestCalculationMetrics,
      setValue: setInterestCalculationMetrics,
      searching: searchingInterestCalculationMetrics.isLoading,
    },
    validate,
    reset,
  };
}
