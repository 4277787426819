import React from "react";
import { Route, Routes } from "react-router-dom";
import { useModal } from "../../../../hooks/contexts";
import { useCustomFetch } from "../../../../hooks/async";
import { Pending } from "./Pending/Pending";
import { Approved } from "./Approved/Approved";
import { Refused } from "./Refused/Refused";

export function RdvDashboardExpenses() {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const [enterpriseOptions, setEnterpriseOptions] = React.useState([]);

  const searchEnterprises = React.useCallback(async () => {
    try {
      const json = await customFetch("/rdv/searchEnterprises", { body: null });
      if (json.status === 200) {
        const options = json.object.map((item) => {
          return {
            value: item,
            label: item.nomeEmpresa,
          };
        });
        setEnterpriseOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        setEnterpriseOptions([]);
      }
    } catch (error) {
      Modal.error(error);
    }
  }, [Modal, customFetch]);

  React.useEffect(() => {
    searchEnterprises();
  }, []); // eslint-disable-line

  return (
    <Routes>
      <Route path="pendentes/*" element={<Pending enterpriseOptions={enterpriseOptions} />} />
      <Route path="liberadas/*" element={<Approved enterpriseOptions={enterpriseOptions} />} />
      <Route path="recusadas/*" element={<Refused enterpriseOptions={enterpriseOptions} />} />
    </Routes>
  );
}
