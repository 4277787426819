import React from "react";

import styles from "./InputNumber.module.css";

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string | undefined;
}

const InputNumber = ({
  value = "",
  onChange = () => {},
  onBlur = () => {},
  error = "",
  className = "",
  ...props
}: IInputProps) => {
  const [isEditing, setIsEditing] = React.useState(false);

  const toggleEditing = React.useCallback(() => {
    setIsEditing((prevState) => !prevState);
  }, []);

  const toFormatedNumber = React.useCallback((number: string) => {
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (number === "") return number;

    return formatter.format(Number(number));
  }, []);

  return (
    <span className={styles.wrapper}>
      {isEditing ? (
        <input
          {...props}
          className={`${styles.input} ${error ? "isInvalid" : ""} ${
            String(value).length ? "isFilled" : ""
          } ${className}`}
          type={"number"}
          value={value}
          onChange={onChange}
          onBlur={(e) => {
            onBlur(e);
            toggleEditing();
          }}
        />
      ) : (
        <input
          {...props}
          className={`${styles.input} ${error ? "isInvalid" : ""} ${
            String(value).length ? "isFilled" : ""
          } ${className}`}
          type={"text"}
          value={toFormatedNumber(String(value))}
          onChange={onChange}
          onFocus={toggleEditing}
          readOnly
        />
      )}
      {error && <p className={styles.error}>{error}</p>}
    </span>
  );
};

export { InputNumber };
