import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Button, Input, LinkButton, Select } from "../../../../components/Form";
import { Circle } from "../../../../components/Loading";
import { isValid } from "../../../../helpers/validations";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { useForm, useSelect } from "../../../../hooks/form";
import { IPeopleCategory } from "../PeopleCategories";

import styles from "./UpdatePeopleCategory.module.css";

interface INewPeopleCategoryProps {
  searchTotalPeopleCategoriesRecordsAndPeopleCategories: () => void;
  selectedPeopleCategory: IPeopleCategory | null;
}

interface IHandleSubmitNewPeopleCategoryResponse {
  response: number;
  message: string;
  status: number;
  object: any;
}

export function UpdatePeopleCategory({
  searchTotalPeopleCategoriesRecordsAndPeopleCategories,
  selectedPeopleCategory,
}: INewPeopleCategoryProps) {
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();

  const description = useForm({ type: "text", required: true });
  const status = useSelect({ type: "single", required: true });

  const savingNewPeopleCategory = useLoading();

  const categoryStatusOptions = [
    {
      label: "Ativo",
      value: true,
    },
    {
      label: "Inativo",
      value: false,
    },
  ];

  const handleSubmitUpdatePeopleCategory = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isValid(description)) {
      try {
        savingNewPeopleCategory.setLoading(true);
        const json = (await customFetch("/orderPermissions/insertAndUpdatePeopleCategory", {
          body: {
            id: selectedPeopleCategory?.idCategoriaPessoa,
            nome: description.value,
            status: (status.value as unknown as { value: any }).value ? "A" : "I",
          },
        })) as IHandleSubmitNewPeopleCategoryResponse;
        if (json.status === 200) {
          await Modal.success(json.message);
          searchTotalPeopleCategoriesRecordsAndPeopleCategories();
          navigate("/configuracoes/categorias-pessoas");
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        savingNewPeopleCategory.setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    description.setValue(selectedPeopleCategory?.nomeCategoriaPessoa || "");
    if (selectedPeopleCategory?.status === "A") {
      status.setValue(categoryStatusOptions[0]);
    } else {
      status.setValue(categoryStatusOptions[1]);
    }
  }, []); // eslint-disable-line

  if (selectedPeopleCategory === null) {
    return <Navigate to={"/configuracoes/categorias-pessoas"} />;
  }

  return (
    <div className="container">
      {!savingNewPeopleCategory.isLoading ? (
        <>
          <div>
            <LinkButton
              to="/configuracoes/categorias-pessoas"
              buttonStyle="backButton"
              children={null}
              variant={undefined}
              className={undefined}
              classNameContainer={undefined}
              disabled={undefined}
            />
          </div>
          <div className={styles.contentContainer}>
            <form onSubmit={handleSubmitUpdatePeopleCategory} className={styles.formContainer}>
              <div className={styles.formFieldsContainer}>
                <div data-grid="description">
                  <label htmlFor="description" className="label">
                    Descrição
                  </label>
                  <Input
                    id="description"
                    placeholder="Digite a descrição da categoria"
                    value={description.value}
                    error={description.error as unknown as string}
                    onChange={description.onChange}
                    onBlur={description.onBlur}
                  />
                </div>
                <div data-grid="status">
                  <label htmlFor="" className="label">
                    Status da Categoria
                  </label>
                  <Select
                    value={status.value}
                    defaultValue={undefined}
                    error={status.error}
                    onChange={status.onChange}
                    options={categoryStatusOptions}
                    isClearable={false}
                    isSearchable={false}
                  />
                </div>
              </div>
              <div className={styles.submitButtonContainer}>
                <Button>Salvar</Button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Atualizando Categoria</span>
        </div>
      )}
    </div>
  );
}
