import { useCallback, useEffect, useState } from "react";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";

export function useGrupos() {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const [listaGrupos, setListaGrupos] = useState<Grupo[]>([]);

  const buscandoGrupos = useLoading();

  const buscarGrupos = useCallback(async () => {
    try {
      buscandoGrupos.setLoading(true);
      const json = (await customFetch(
        "/admin/buscarGruposPermissoes",
        {}
      )) as DefaultFetchResponse<Grupo[]>;
      if (json.status === 200) {
        setListaGrupos(json.object);
      } else if (json.status === 404) {
        setListaGrupos([]);
      } else {
        Modal.error(json.message, json.object);
      }
    } catch (error: any) {
      Modal.error(error.message);
    } finally {
      buscandoGrupos.setLoading(false);
    }
  }, [Modal, buscandoGrupos, customFetch]);

  useEffect(() => {
    buscarGrupos();
  }, []); // eslint-disable-line

  return {
    listaGrupos,
    buscandoGrupos: buscandoGrupos.isLoading,
    buscarGrupos,
  };
}

export type UseGrupos = ReturnType<typeof useGrupos>;

export type Grupo = {
  id: number;
  nome: string;
  status: "A" | "I";
};
