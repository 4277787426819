import React from "react";

interface IValidateFunction {
  validate: (value: string) => boolean;
  message: string;
}

interface IUseDateProps {
  type?: string;
  required: boolean;
  emptyMessage?: string;
  customValidations?: IValidateFunction[];
}

declare global {
  interface IUseDate {
    value: string;
    setValue: (value: string | ((value: string) => string)) => void;
    error: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
    onChange: ({ target }: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: () => boolean;
    validate: () => boolean;
    /**
     * Retorna uma isoString representando a data contida em value;
     * A isoString irá retornar a data no início do dia (caso value não seja um datetime);
     *
     * @returns 2023-07-18T00:00:00.000Z
     */
    toISOString: () => string;
    /**
     * Retorna uma isoString representando a data seguinte à contida em value;
     * A isoString irá retornar a data no início do dia (caso value não seja um datetime);
     *
     * @returns 2023-07-19T00:00:00.000Z
     */
    toISOStringNextDay: () => string;
    reset: (value?: string | ((value: string) => string)) => void;
  }
}

const useDate = ({ type, required, emptyMessage, customValidations = [] }: IUseDateProps): IUseDate => {
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    if (!type && !required && error) setError("");
  }, [type, required, error]);

  const reset = React.useCallback((value: string | ((value: string) => string) = "") => {
    if (typeof value === "function") {
      setValue((old) => {
        const result = value(old);
        return result;
      });
      setError("");
    } else {
      setValue(value);
      setError("");
    }
  }, []);

  function validate(value: string) {
    if (!type && !required) return true;
    if (value.length === 0 && !required) {
      setError("");
      return true;
    }
    if (value.length === 0) {
      setError(emptyMessage || "Preencha um valor");
      return false;
    } else if (customValidations.length) {
      let isInvalid = false;
      customValidations.forEach((validation) => {
        if (!validation.validate(value)) {
          setError(validation.message);
          isInvalid = true;
        }
      });
      if (isInvalid) return false;
      else {
        setError("");
        return true;
      }
    } else {
      setError("");
      return true;
    }
  }

  function onChange({ target }: React.ChangeEvent<HTMLInputElement>) {
    if (error) validate(target.value);
    setValue(target.value);
  }

  function toISOString() {
    const regex = /^(\d{4})-(\d{2})-(\d{2})$/;

    const today = new Date();

    const regexFinded = value.match(regex);

    if (regexFinded && regexFinded.length) {
      const [receivedYear, receivedMonth, receivedDay] = regexFinded.splice(1).map(Number);

      const receivedTime = new Date(receivedYear, receivedMonth - 1, receivedDay, 0, 0, 0, 0);

      const iso = receivedTime.toISOString();
      return iso.replace(/(\d{4}-\d{2}-\d{2}).+/, "$1T00:00:00.000Z");
    } else {
      const receivedDate = new Date(value);
      receivedDate.setHours(receivedDate.getHours() - today.getTimezoneOffset() / 60);
      return receivedDate.toISOString();
    }
  }

  function toISOStringNextDay() {
    const regex = /^(\d{4})-(\d{2})-(\d{2})$/;

    const today = new Date();

    const regexFinded = value.match(regex);

    if (regexFinded && regexFinded.length) {
      const [receivedYear, receivedMonth, receivedDay] = regexFinded.splice(1).map(Number);

      const receivedTime = new Date(receivedYear, receivedMonth - 1, receivedDay, 0, 0, 0, 0);

      receivedTime.setDate(receivedDay + 1);
      const iso = receivedTime.toISOString();
      return iso.replace(/(\d{4}-\d{2}-\d{2}).+/, "$1T00:00:00.000Z");
    } else {
      const receivedDate = new Date(value);
      receivedDate.setHours(receivedDate.getHours() - today.getTimezoneOffset() / 60);
      return receivedDate.toISOString();
    }
  }

  return {
    value,
    setValue: (value: string | ((value: string) => string)) => {
      if (typeof value === "function") {
        setValue((old) => {
          const result = value(old);
          validate(result);
          return result;
        });
      } else {
        setValue(value);
        validate(value);
      }
    },
    error,
    setError,
    onChange,
    onBlur: () => validate(value),
    validate: () => validate(value),
    toISOString,
    toISOStringNextDay,
    reset,
  };
};

export { useDate };
