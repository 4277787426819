const getFullMonth = (month) => {
  const arrayMonths = [
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "setembro",
    "outubro",
    "novembro",
    "dezembro",
  ];

  return arrayMonths[Number(month) - 1];
};

export function captalizeString(string = "") {
  const arrayString = string.split(" ");

  arrayString.forEach((item, index, array) => {
    const [firstLetter, ...rest] = item;
    array[index] = firstLetter.toUpperCase() + rest.join("");
  });

  return arrayString.join(" ");
}

export function formatDate(date, format) {
  let auxDate = date;
  if (typeof date === "string") {
    auxDate = new Date(date);
  }

  const day = String(auxDate.getDate()).padStart(2, "0");
  const month = String(auxDate.getMonth() + 1).padStart(2, "0");
  const fullYear = String(auxDate.getFullYear());
  const year = fullYear.substring(2);

  const fullMonth = getFullMonth(month);

  const hour = String(auxDate.getHours()).padStart(2, "0");
  const minute = String(auxDate.getMinutes()).padStart(2, "0");
  const second = String(auxDate.getSeconds()).padStart(2, "0");

  const formatedDate = format
    .replace(/dd/g, day)
    .replace(/MM/g, month)
    .replace(/yyyy/g, fullYear)
    .replace(/yy/g, year)
    .replace(/hh/g, hour)
    .replace(/mm/g, minute)
    .replace(/ss/g, second)
    .replace(/month/g, fullMonth)
    .replace(/Month/g, captalizeString(fullMonth));

  return formatedDate;
}

export function formatMoney(money) {
  return Number(money).toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
}

export function formatCNPJ(cnpj) {
  cnpj = cnpj.replace(/\D/g, '');

  if (cnpj.length !== 14) {
    return "";
  }

  return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
}