import React from "react";
import { Button, Input, LinkButton, Select } from "../../../../components/Form";

import styles from "./AdicionarAtualizar.module.css";
import { useAdicionarAtualizar } from "./hooks/useAdicionarAtualizar";
import { Circle } from "../../../../components/Loading";
import { IPeriodicidadePagto, IUsePeriodicidadesPagto } from "../hooks/types";
import { useNavigate } from "react-router-dom";

interface IProps {
  tipo: "insert" | "update";
  periodicidadesPagtoData: IUsePeriodicidadesPagto;
  selectedPeriodicidadePagto?: IPeriodicidadePagto | null;
}

export function AdicionarAtualizar({ tipo, periodicidadesPagtoData, selectedPeriodicidadePagto }: IProps) {
  const navigate = useNavigate();

  const {
    carregarDados,
    form: { nomePeriodicidadePagto, status },
    selectOptions: { statusOptions },
    interactions: { insertOrUpdatePeriodicidadePagto, insertingOrUpdatingPeriodicidadePagto },
  } = useAdicionarAtualizar({ periodicidadesPagtoData });

  React.useEffect(() => {
    if (tipo === "update" && selectedPeriodicidadePagto) {
      if (!selectedPeriodicidadePagto) navigate("/contratos-fornecedor-cliente/periodicidades-pagamento");
      else carregarDados(selectedPeriodicidadePagto);
    }
  }, []); // eslint-disable-line

  return (
    <div className="container">
      {!insertingOrUpdatingPeriodicidadePagto ? (
        <>
          <div>
            <LinkButton
              to="/contratos-fornecedor-cliente/periodicidades-pagamento"
              buttonStyle="backButton"
              className={styles.navButton}
            />
          </div>
          <span className="separator" />
          <div className={styles.fieldsContainer}>
            <div>
              <label htmlFor="nomePeriodicidadePagto" className="label">
                Nome Periodicidade Pagamento
              </label>
              <Input
                id="nomePeriodicidadePagto"
                placeholder="Digite o nome da periodicidade pagamento"
                value={nomePeriodicidadePagto.value}
                error={nomePeriodicidadePagto.error}
                onChange={nomePeriodicidadePagto.onChange}
                onBlur={nomePeriodicidadePagto.onBlur}
              />
            </div>
            {tipo === "update" && (
              <div className={styles.statusContainer}>
                <label htmlFor="status" className="label">
                  Situação
                </label>
                <Select
                  id="status"
                  options={statusOptions}
                  value={status.value}
                  onChange={status.onChange}
                  isClearable={false}
                  isSearchable={false}
                />
              </div>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => {
                if (tipo === "insert") insertOrUpdatePeriodicidadePagto();
                else insertOrUpdatePeriodicidadePagto(selectedPeriodicidadePagto?.idPeriodicidade);
              }}>
              Concluir
            </Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <p className="loadingMessage">
            {tipo === "insert" ? "Inserindo Nova Periodicidade Pagamento" : "Atualizando Periodicidade Pagamento"}
          </p>
        </div>
      )}
    </div>
  );
}
