import React from "react";

import { useAuth, useChanges } from "../hooks/contexts";
import { usePrompt } from "../hooks/navigation";

import { Routes, Route } from "react-router-dom";

import Applications from "../pages/Applications/Applications";
import ApplicationsFrame from "../pages/ApplicationsFrame/ApplicationsFrame";

import { Auth } from "../pages/Auth/Auth";
import { Login } from "../pages/Auth/Login/Login";
import { Private } from "./Private";

import { Admin } from "../pages/Admin/Admin";
import { Users } from "../pages/Admin/Users/Users";
import { Logs } from "../pages/Admin/Logs/Logs";

import { Products } from "../pages/Products/Products";
import { ProductGroups } from "../pages/Products/Groups/Groups";
import { ProductFormulas } from "../pages/Products/Formulas/Formulas";
import { ProductPackaging } from "../pages/Products/Packaging/Packaging";
import { ProductMicros } from "../pages/Products/Micros/Micros";
import { ProductPriceTable } from "../pages/Products/PriceTable/PriceTable";
import { ProductValidityTables } from "../pages/Products/ValidityTables/ValidityTables";
import { ProductReplicateValidity } from "../pages/Products/ReplicateValidity/ReplicateValidity";

import { Commissions } from "../pages/Commissions/Commissions";
import { CommissionsFoliar } from "../pages/Commissions/Foliar/Foliar";
import { CommissionsFertilizer } from "../pages/Commissions/Fertilizer/Fertilizer";
import { CommissionsPricesRange } from "../pages/Commissions/Foliar/PricesRange/PricesRange";
import { CommissionsCommissionPercent } from "../pages/Commissions/Foliar/CommissionPercent/CommissionPercent";
import { CommissionTables } from "../pages/Commissions/Fertilizer/CommissionsTable/CommissionTables";
import { CommissionValidityRange } from "../pages/Commissions/Fertilizer/ValidityRange/ValidityRange";
import { CommissionsReports } from "../pages/Commissions/Reports/Reports";
import { CommissionsReportsByFormula } from "../pages/Commissions/Reports/ByFormula/ByFormula";
import { CommissionsReportsBySeller } from "../pages/Commissions/Reports/BySeller/BySeller";

import { Premiations } from "../pages/Premiations/Premiations";
import { PremiationsManager } from "../pages/Premiations/Manager/Manager";
import { PremiationsManagerRequest } from "../pages/Premiations/Manager/RequestPayment/RequestPayment";
import { PremiationsCommercial } from "../pages/Premiations/Commercial/Commercial";
import { PremiationsCommercialRequests } from "../pages/Premiations/Commercial/PendingRequests/PendingRequests";
import { PremiationsFinancial } from "../pages/Premiations/Financial/Financial";
import { PremiationsFinancialRequests } from "../pages/Premiations/Financial/ApprovedRequests/ApprovedRequests";
import { PremiationsSituationRequestsSituation } from "../pages/Premiations/Situation/RequestsSituation/RequestsSituation";
import { PremiationsRequestsProof } from "../pages/Premiations/RequestsProof/RequestsSituation";
import { PremiationsSituation } from "../pages/Premiations/Situation/Situation";

import { Reports } from "../pages/Reports/Reports";
import { ReportsCommissions } from "../pages/Reports/Commissions/ReportsCommissions";
import { ReportsCommissionsAnalytical } from "../pages/Reports/Commissions/Analytical/ReportsCommissionsAnalytical";

import { Contracts } from "../pages/NewContracts/Contracts";
import { ContractsManager } from "../pages/NewContracts/Manager/Manager";
import { ContractsManagerSolicitation } from "../pages/NewContracts/Manager/Solicitation/Solicitation";
import { ContractsCommercial } from "../pages/NewContracts/Commercial/Commercial";
import { ContractsCommercialSolicitations } from "../pages/NewContracts/Commercial/Solicitations/Solicitations";
import { ContractsFinancial } from "../pages/NewContracts/Financial/Financial";
import { ContractsFinancialSolicitations } from "../pages/NewContracts/Financial/Solicitations/Solicitations";
import { ContractsSolicitationsHistorical } from "../pages/NewContracts/SolicitationsHistorical/SolicitationsHistorical";
import { ContractCommissionsTable } from "../pages/Commissions/Fertilizer/ContractComissionsTable/ContractCommissionsTable";
import { ContractsManagerSolicitationsAwaitingCustomer } from "../pages/NewContracts/Manager/SolicitationsAwaitingCustomer/SolicitationsAwaitingCustomer";

import { ContratosFC } from "../pages/ContratosFC/ContratosFC";
import { ContratosFCContratado } from "../pages/ContratosFC/Contratado/Contratado";
import { ContratosFCContrato } from "../pages/ContratosFC/Contrato/Contrato";
import { ContratosFCExigencia } from "../pages/ContratosFC/Exigencia/Exigencia";
import { ContratosFCPenalidades } from "../pages/ContratosFC/Penalidades/Penalidades";
import { ContratosFCPeriodicidadesPagto } from "../pages/ContratosFC/PeriodicidadesPagto/PeriodicidadesPagto";
import { ContratosFCObjetos } from "../pages/ContratosFC/Objetos/Objetos";

import { Settings } from "../pages/Settings/Settings";
import { SettingsGeneralSettings } from "../pages/Settings/GeneralSettings/GeneralSettings";
import { SettingsOrderPermissions } from "../pages/Settings/OrderPermissions/OrderPermissions";
import { SettingsPeopleCategories } from "../pages/Settings/PeopleCategories/PeopleCategories";
import { SettingsLinkManagerAndSellers } from "../pages/Settings/LinkManagerAndSellers/LinkManagerAndSellers";

import { Rdv } from "../pages/Rdv/Rdv";
import { RdvCategories } from "../pages/Rdv/Categories/Categories";
import { RdvPaymentMethods } from "../pages/Rdv/PaymentMethods/PaymentMethods";
import { RdvSuppliers } from "../pages/Rdv/Suppliers/Suppliers";
import { RdvDashboard } from "../pages/Rdv/Dashboard/Dashboard";
import { RdvDashboardExpenses } from "../pages/Rdv/Dashboard/Expenses/Expenses";

import { Finance } from "../pages/Finance/Finance";
import { FinanceBank } from "../pages/Finance/Bank/Bank";
import { FinancePaymentMethod } from "../pages/Finance/PaymentMethod/PaymentMethod";
import { FinanceUserDocument } from "../pages/Finance/UserDocument/UserDocument";
import { FinanceUserBankAccount } from "../pages/Finance/UserBankAccount/UserBankAccount";

import { Factory } from "../pages/Factory/Factory";
import { FactoryStockScheduling } from "../pages/Factory/StockScheduling/StockScheduling";
import { FactoryStockSchedulingInsertOrDeduct } from "../pages/Factory/StockScheduling/InsertOrDeduct/InsertOrDeduct";
import { FactoryStockSchedulingInventory } from "../pages/Factory/StockScheduling/Inventory/Inventory";
import Page404 from "../pages/Page404/Page404";
import { Nonconformity } from "../pages/Nonconformity/Nonconformity";
import GruposPermissoes from "../pages/Admin/GruposPermissoes/GruposPermissoes";

function NavigationControll({ children }) {
  const { isChangesDetected } = useChanges();

  usePrompt(
    "Você realizou mudanças que ainda não foram salvas!<br>Deseja continuar mesmo assim?",
    isChangesDetected
  );

  return <>{children}</>;
}

export function Router() {
  const AuthContext = useAuth();

  function havePermission(permission) {
    const permissions = AuthContext.permissions;
    return !!permissions.find((item) => item.permissao === permission);
  }

  return (
    <NavigationControll>
      <Routes>
        <Route path="/frame-aplicativos" element={<ApplicationsFrame />} />
        {!AuthContext.signed ? (
          <Route path="/" element={<Auth />}>
            <Route path="login" element={<Login />} />
          </Route>
        ) : (
          <Route path="/" element={<Private />}>
            {/* Applications Routes */}
            <Route path="aplicativos" element={<Applications />} />
            {/* Applications Routes */}

            {/* Admin Routes */}
            {havePermission("MENU_ADMIN") && (
              <Route path="admin" element={<Admin />}>
                <Route path="usuarios/*" element={<Users />} />
                <Route
                  path="grupos-de-permissoes/*"
                  element={<GruposPermissoes />}
                />
                <Route path="logs" element={<Logs />} />
              </Route>
            )}
            {/* Admin Routes */}

            {/* Product Routes */}
            {havePermission("MENU_PRODUTOS") && (
              <Route path="produtos" element={<Products />}>
                {havePermission("MENU_GRUPO") && (
                  <Route path="grupos/*" element={<ProductGroups />} />
                )}
                {havePermission("MENU_FORMULA") && (
                  <Route path="formulas/*" element={<ProductFormulas />} />
                )}
                {havePermission("MENU_MICRO") && (
                  <Route path="micros/*" element={<ProductMicros />} />
                )}
                {havePermission("MENU_EMBALAGEM") && (
                  <Route path="embalagens/*" element={<ProductPackaging />} />
                )}
                {havePermission("MENU_TABELA_PRECO") && (
                  <Route
                    path="tabela-de-precos/*"
                    element={<ProductPriceTable />}
                  />
                )}
                {havePermission("MENU_TABELA_VIGENCIA") && (
                  <Route
                    path="tabelas-de-vigencia/*"
                    element={<ProductValidityTables />}
                  />
                )}
                {havePermission("MENU_REPLICAR_TABELA") && (
                  <Route
                    path="replicar-vigencia/*"
                    element={<ProductReplicateValidity />}
                  />
                )}
              </Route>
            )}
            {/* Product Routes */}

            {/* Commissions Routes */}
            {havePermission("MENU_COMISSAO") && (
              <Route path="comissoes" element={<Commissions />}>
                <Route path="foliar" element={<CommissionsFoliar />}>
                  {havePermission("MENU_FAIXA_PRECO_FOLIAR") && (
                    <Route
                      path="faixas-de-preco/*"
                      element={<CommissionsPricesRange />}
                    />
                  )}
                  <Route
                    path="percentual-comissao/*"
                    element={<CommissionsCommissionPercent />}
                  />
                </Route>
                <Route path="adubo" element={<CommissionsFertilizer />}>
                  {havePermission("VER_MENU_COMISSAO_TABELA") && (
                    <Route
                      path="tabelas-de-comissoes/*"
                      element={<CommissionTables />}
                    />
                  )}
                  <Route
                    path="comissoes-de-contratos/*"
                    element={<ContractCommissionsTable />}
                  />
                  <Route
                    path="faixa-vigencia"
                    element={<CommissionValidityRange />}
                  />
                </Route>
                <Route path="relatorios" element={<CommissionsReports />}>
                  <Route
                    path="por-formula"
                    element={<CommissionsReportsByFormula />}
                  />
                  |
                  <Route
                    path="por-vendedor"
                    element={<CommissionsReportsBySeller />}
                  />
                  |
                </Route>
              </Route>
            )}
            {/* Commissions Routes */}

            {/* Premiations Routes */}
            {havePermission("MENU_NOVA_PREMIACAO") && (
              <Route path="premiacoes" element={<Premiations />}>
                {havePermission("MENU_NOVA_PREMIACAO_GERENTE") && (
                  <Route path="gerente" element={<PremiationsManager />}>
                    <Route
                      path="solicitar"
                      element={<PremiationsManagerRequest />}
                    />
                    <Route
                      path="rastreabilidade"
                      element={<PremiationsSituation />}
                    >
                      <Route
                        path="geral/*"
                        element={
                          <PremiationsSituationRequestsSituation
                            sector="manager"
                            page="general"
                          />
                        }
                      />
                      <Route
                        path="em-analise-comercial/*"
                        element={
                          <PremiationsSituationRequestsSituation
                            sector="manager"
                            page="analysisCommercial"
                          />
                        }
                      />
                      <Route
                        path="em-analise-financeiro/*"
                        element={
                          <PremiationsSituationRequestsSituation
                            sector="manager"
                            page="analysisFinancial"
                          />
                        }
                      />
                      <Route
                        path="pagos/*"
                        element={
                          <PremiationsSituationRequestsSituation
                            sector="manager"
                            page="paid"
                          />
                        }
                      />
                      <Route
                        path="reprovados/*"
                        element={
                          <PremiationsSituationRequestsSituation
                            sector="manager"
                            page="rejected"
                          />
                        }
                      />
                    </Route>
                    <Route
                      path="comprovante/*"
                      element={<PremiationsRequestsProof sector="manager" />}
                    />
                  </Route>
                )}
                {havePermission("MENU_NOVA_PREMIACAO_COMERCIAL") && (
                  <Route path="comercial" element={<PremiationsCommercial />}>
                    <Route
                      path="em-analise-comercial/*"
                      element={<PremiationsCommercialRequests />}
                    />
                    <Route
                      path="rastreabilidade"
                      element={<PremiationsSituation />}
                    >
                      <Route
                        path="geral/*"
                        element={
                          <PremiationsSituationRequestsSituation
                            sector="commercial"
                            page="general"
                          />
                        }
                      />
                      <Route
                        path="em-analise-financeiro/*"
                        element={
                          <PremiationsSituationRequestsSituation
                            sector="commercial"
                            page="analysisFinancial"
                          />
                        }
                      />
                      <Route
                        path="pagos/*"
                        element={
                          <PremiationsSituationRequestsSituation
                            sector="commercial"
                            page="paid"
                          />
                        }
                      />
                      <Route
                        path="reprovados/*"
                        element={
                          <PremiationsSituationRequestsSituation
                            sector="commercial"
                            page="rejected"
                          />
                        }
                      />
                    </Route>
                    <Route
                      path="comprovante/*"
                      element={<PremiationsRequestsProof sector="commercial" />}
                    />
                  </Route>
                )}
                {havePermission("MENU_NOVA_PREMIACAO_FINANCEIRO") && (
                  <Route path="financeiro" element={<PremiationsFinancial />}>
                    <Route
                      path="em-analise-financeiro/*"
                      element={<PremiationsFinancialRequests />}
                    />
                    <Route
                      path="rastreabilidade"
                      element={<PremiationsSituation />}
                    >
                      <Route
                        path="geral/*"
                        element={
                          <PremiationsSituationRequestsSituation
                            sector="financial"
                            page="general"
                          />
                        }
                      />
                      <Route
                        path="em-analise-comercial/*"
                        element={
                          <PremiationsSituationRequestsSituation
                            sector="financial"
                            page="analysisCommercial"
                          />
                        }
                      />
                      <Route
                        path="pagos/*"
                        element={
                          <PremiationsSituationRequestsSituation
                            sector="financial"
                            page="paid"
                          />
                        }
                      />
                      <Route
                        path="reprovados/*"
                        element={
                          <PremiationsSituationRequestsSituation
                            sector="financial"
                            page="rejected"
                          />
                        }
                      />
                    </Route>
                    <Route
                      path="comprovante/*"
                      element={<PremiationsRequestsProof sector="financial" />}
                    />
                  </Route>
                )}
              </Route>
            )}
            {/* Premiations Routes */}

            {/* Rdv Routes */}
            {havePermission("MENU_RDV") && (
              <Route path="rdv" element={<Rdv />}>
                {havePermission("VER_MENU_RDV_DASHBOARD") && (
                  <Route path="dashboard" element={<RdvDashboard />}>
                    <Route
                      path="despesas/*"
                      element={<RdvDashboardExpenses />}
                    />
                  </Route>
                )}
                {havePermission("VER_MENU_RDV_CATEGORIA") && (
                  <Route path="categorias/*" element={<RdvCategories />} />
                )}
                {havePermission("VER_MENU_RDV_FORMA_PAGAMENTO") && (
                  <Route
                    path="formas-de-pagamento/*"
                    element={<RdvPaymentMethods />}
                  />
                )}
                {havePermission("VER_MENU_RDV_FORNECEDORES") && (
                  <Route path="fornecedores/*" element={<RdvSuppliers />} />
                )}
              </Route>
            )}
            {/* Rdv Routes */}

            {/* Nonconformity Routes */}
            <Route path="inconformidades/*" element={<Nonconformity />} />
            {/* Nonconformity Routes */}

            {/* Finance Routes */}
            {havePermission("MENU_FINANCEIRO") && (
              <Route path="financeiro" element={<Finance />}>
                {havePermission("MENU_FINANCEIRO_BANCO") && (
                  <Route path="bancos/*" element={<FinanceBank />} />
                )}
                {havePermission("MENU_FINANCEIRO_FORMA_PAGAMENTO") && (
                  <Route
                    path="formas-de-pagamento/*"
                    element={<FinancePaymentMethod />}
                  />
                )}
                {havePermission("MENU_FINANCEIRO_DOCUMENTO") && (
                  <Route
                    path="documentos/*"
                    element={<FinanceUserDocument />}
                  />
                )}
                {havePermission("MENU_FINANCEIRO_CONTA") && (
                  <Route path="contas/*" element={<FinanceUserBankAccount />} />
                )}
              </Route>
            )}
            {/* Finance Routes */}

            {/* Reports Routes */}
            {havePermission("MENU_RELATORIOS") && (
              <Route path="relatorios" element={<Reports />}>
                {havePermission("MENU_COMISSOES_RELATORIO") && (
                  <Route path="comissoes" element={<ReportsCommissions />}>
                    {havePermission("MENU_COMISSOES_RELATORIO_ANALITICO") && (
                      <Route
                        path="analitico"
                        element={<ReportsCommissionsAnalytical />}
                      />
                    )}
                  </Route>
                )}
              </Route>
            )}
            {/* Reports Routes */}

            {/* Contracts Routes */}
            {havePermission("VER_MENU_CONTRATO") && (
              <Route path="contratos" element={<Contracts />}>
                <Route path="gerente" element={<ContractsManager />}>
                  {havePermission("VER_MENU_SOLICITA_CONTRATO") && (
                    <Route
                      path="solicitacao-de-contrato"
                      element={<ContractsManagerSolicitation />}
                    />
                  )}
                  {havePermission("VER_MENU_SOLICITA_CONTRATO_CLIENTE") && (
                    <Route
                      path="solicitacoes-aguardando-cliente/*"
                      element={
                        <ContractsManagerSolicitationsAwaitingCustomer />
                      }
                    />
                  )}
                </Route>
                <Route path="comercial" element={<ContractsCommercial />}>
                  {havePermission("VER_MENU_SOLICITA_CONTRATO_COMERCIAL") && (
                    <Route
                      path="solicitacoes-pendentes/*"
                      element={<ContractsCommercialSolicitations />}
                    />
                  )}
                </Route>
                <Route path="financeiro" element={<ContractsFinancial />}>
                  {havePermission("VER_MENU_SOLICITA_CONTRATO_FINANCEIRO") && (
                    <Route
                      path="solicitacoes/*"
                      element={<ContractsFinancialSolicitations />}
                    />
                  )}
                </Route>
                {havePermission("VER_MENU_SOLICITA_CONTRATO_HISTORICO") && (
                  <Route
                    path="historico-solicitacoes/*"
                    element={<ContractsSolicitationsHistorical />}
                  />
                )}
              </Route>
            )}
            {/* Contracts Routes */}

            {/* Contratos Fornecedor Cliente Rotas */}
            <Route
              path="contratos-fornecedor-cliente"
              element={<ContratosFC />}
            >
              <Route path="contratado/*" element={<ContratosFCContratado />} />
              <Route path="contrato/*" element={<ContratosFCContrato />} />
              <Route path="exigencias/*" element={<ContratosFCExigencia />} />
              <Route
                path="penalidades/*"
                element={<ContratosFCPenalidades />}
              />
              <Route
                path="periodicidades-pagamento/*"
                element={<ContratosFCPeriodicidadesPagto />}
              />
              <Route path="objetos/*" element={<ContratosFCObjetos />} />
            </Route>
            {/* Contratos Fornecedor Cliente Rotas */}

            {/* Stock Routes */}
            <Route path="fabrica" element={<Factory />}>
              <Route
                path="agendamento-estoque"
                element={<FactoryStockScheduling />}
              >
                <Route
                  path="inserir-abater"
                  element={<FactoryStockSchedulingInsertOrDeduct />}
                />
                <Route
                  path="consultar"
                  element={<FactoryStockSchedulingInventory />}
                />
              </Route>
            </Route>
            {/* Stock Routes */}

            {/* Settings Routes */}
            <Route path="configuracoes" element={<Settings />}>
              <Route path="geral" element={<SettingsGeneralSettings />} />
              <Route
                path="permissoes-pedido"
                element={<SettingsOrderPermissions />}
              />
              <Route
                path="categorias-pessoas/*"
                element={<SettingsPeopleCategories />}
              />
              <Route
                path="vincular-gerente-vendedor"
                element={<SettingsLinkManagerAndSellers />}
              />
            </Route>
            {/* Settings Routes */}

            {/* 404 Route */}
            <Route path="*" element={<Page404 />} />
            {/* 404 Route */}
          </Route>
        )}
      </Routes>
    </NavigationControll>
  );
}
