import React from "react";
import { Button, Input, LinkButton, Select } from "../../../../components/Form";

import styles from "./AdicionarAtualizar.module.css";
import { useAdicionarAtualizarHook } from "./hooks/AdicionarAtualizarHook";
import { Circle } from "../../../../components/Loading";
import { IContrato, IUseContrato } from "../hooks/types";
import { useNavigate } from "react-router-dom";

interface IProps {
  tipo: "insert" | "update";
  contratosData: IUseContrato;
  selectedContrato?: IContrato | null;
}

export function AdicionarAtualizar({ tipo, contratosData, selectedContrato }: IProps) {
  const navigate = useNavigate();

  const { carregarDados, interactions, form, options } = useAdicionarAtualizarHook({ contratosData });

  const { insertOrUpdateContrato, insertingOrUpdatingContrato } = interactions;

  const {
    contratado,
    gestorContrato,
    objetoContrato,
    penalidadesContratuais,
    periodicidadePagamento,
    formaPagamento,
    exigenciaPagamento,
    dataEnvio,
    dataRecebimentoDoc,
    inicioVigencia,
    fimVigencia,
    status,
  } = form;

  const {
    contratadoOptions,
    gestorContratoOptions,
    objetoContratoOptions,
    penalidadesContratuaisOptions,
    periodicidadePagamentoOptions,
    formaPagamentoOptions,
    exigenciaPagamentoOptions,
    statusOptions,
  } = options;

  React.useEffect(() => {
    if (tipo === "update") {
      if (!selectedContrato) navigate("/contratos-fornecedor-cliente/contrato");
      else carregarDados(selectedContrato);
    }
  }, []); // eslint-disable-line

  return (
    <div className="container">
      {!insertingOrUpdatingContrato ? (
        <>
          <div>
            <LinkButton
              to="/contratos-fornecedor-cliente/contrato"
              buttonStyle="backButton"
              className={styles.navButton}
            />
          </div>
          <span className="separator" />
          <div>
            <fieldset className={`fieldset ${styles.dadosContratoContainer}`}>
              <legend className="fieldsetLegend">Dados Contrato</legend>
              <div className={styles.dadosContratoContainer__contratado}>
                <label htmlFor="contratado" className="label">
                  Contratado
                </label>
                <Select
                  id="contratado"
                  placeholder="Selecione o contratado"
                  value={contratado.value}
                  onChange={contratado.onChange}
                  onInputChange={contratadoOptions.searchDebounced}
                  onBlur={contratado.onBlur}
                  error={contratado.error}
                  options={contratadoOptions.options}
                  isLoading={contratadoOptions.searching}
                />
              </div>
              <div className={styles.dadosContratoContainer__gestorContrato}>
                <label htmlFor="gestorContrato" className="label">
                  Gestor do Contrato
                </label>
                <Select
                  id="gestorContrato"
                  placeholder="Digite as 3 primeiras letras do nome do gestor do contrato"
                  value={gestorContrato.value}
                  onChange={gestorContrato.onChange}
                  onInputChange={gestorContratoOptions.searchDebounced}
                  onBlur={gestorContrato.onBlur}
                  error={gestorContrato.error}
                  options={!gestorContratoOptions.searching ? gestorContratoOptions.options : []}
                  isLoading={gestorContratoOptions.searching}
                />
              </div>
              <div>
                <label htmlFor="objetoContrato" className="label">
                  Objeto do Contrato
                </label>
                <Select
                  id="objetoContrato"
                  placeholder="Selecione o objeto do contrato"
                  value={objetoContrato.value}
                  onChange={objetoContrato.onChange}
                  onBlur={objetoContrato.onBlur}
                  error={objetoContrato.error}
                  options={objetoContratoOptions.options}
                  isLoading={objetoContratoOptions.searching}
                  isSearchable={false}
                  isClearable={false}
                />
              </div>
              <div>
                <label htmlFor="penalidadesContratuais" className="label">
                  Penalidades Contratuais
                </label>
                <Select
                  id="penalidadesContratuais"
                  placeholder="Selecione a penalidade contratual"
                  value={penalidadesContratuais.value}
                  onChange={penalidadesContratuais.onChange}
                  onBlur={penalidadesContratuais.onBlur}
                  error={penalidadesContratuais.error}
                  options={penalidadesContratuaisOptions.options}
                  isLoading={penalidadesContratuaisOptions.searching}
                  isSearchable={false}
                  isClearable={false}
                />
              </div>
              <div className={styles.dadosContratoContainer__periodoPagamentoContainer}>
                <div>
                  <label htmlFor="periodicidadePagamento" className="label">
                    Periodicidade Pagamento
                  </label>
                  <Select
                    id="periodicidadePagamento"
                    placeholder="Selecione a periodicidade do pagamento"
                    value={periodicidadePagamento.value}
                    onChange={periodicidadePagamento.onChange}
                    onBlur={periodicidadePagamento.onBlur}
                    error={periodicidadePagamento.error}
                    options={periodicidadePagamentoOptions.options}
                    isLoading={periodicidadePagamentoOptions.searching}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
                <div>
                  <label htmlFor="formaPagamento" className="label">
                    Forma de Pagamento
                  </label>
                  <Select
                    id="formaPagamento"
                    placeholder="Selecione a forma de pagamento"
                    value={formaPagamento.value}
                    onChange={formaPagamento.onChange}
                    onBlur={formaPagamento.onBlur}
                    error={formaPagamento.error}
                    options={formaPagamentoOptions.options}
                    isLoading={formaPagamentoOptions.searching}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
                <div>
                  <label htmlFor="exigenciaPagamento" className="label">
                    Exigência de Pagamento
                  </label>
                  <Select
                    id="exigenciaPagamento"
                    placeholder="Selecione a exigência de pagamento"
                    value={exigenciaPagamento.value}
                    onChange={exigenciaPagamento.onChange}
                    onBlur={exigenciaPagamento.onBlur}
                    error={exigenciaPagamento.error}
                    options={exigenciaPagamentoOptions.options}
                    isLoading={exigenciaPagamentoOptions.searching}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="dataEnvio" className="label">
                  Data do Envio Doc.
                </label>
                <Input
                  id="dataEnvio"
                  type="date"
                  value={dataEnvio.value}
                  onChange={dataEnvio.onChange}
                  onBlur={dataEnvio.onBlur}
                  error={dataEnvio.error}
                />
              </div>
              <div>
                <label htmlFor="dataRecebimentoDoc" className="label">
                  Data do Recebimento Doc.
                </label>
                <Input
                  id="dataRecebimentoDoc"
                  type="date"
                  value={dataRecebimentoDoc.value}
                  onChange={dataRecebimentoDoc.onChange}
                  onBlur={dataRecebimentoDoc.onBlur}
                  error={dataRecebimentoDoc.error}
                />
              </div>
              <div>
                <label htmlFor="inicioVigencia" className="label">
                  Início da Vigência
                </label>
                <Input
                  id="inicioVigencia"
                  type="date"
                  value={inicioVigencia.value}
                  onChange={inicioVigencia.onChange}
                  onBlur={inicioVigencia.onBlur}
                  error={inicioVigencia.error}
                />
              </div>
              <div>
                <label htmlFor="fimVigencia" className="label">
                  Fim da Vigência
                </label>
                <Input
                  id="fimVigencia"
                  type="date"
                  value={fimVigencia.value}
                  onChange={fimVigencia.onChange}
                  onBlur={fimVigencia.onBlur}
                  error={fimVigencia.error}
                />
              </div>
              {tipo === "update" && (
                <div className={styles.statusContainer}>
                  <div className={styles.statusContainer__status}>
                    <label htmlFor="status" className="label">
                      Situação
                    </label>
                    <Select
                      id="status"
                      placeholder="Selecione o status"
                      value={status.value}
                      options={statusOptions.options}
                      onChange={status.onChange}
                      onBlur={status.onBlur}
                      error={status.error}
                      isSearchable={false}
                      isClearable={false}
                    />
                  </div>
                </div>
              )}
            </fieldset>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => {
                if (tipo === "insert") insertOrUpdateContrato();
                else insertOrUpdateContrato(selectedContrato?.idContrato);
              }}>
              Concluir
            </Button>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <p className="loadingMessage">{tipo === "insert" ? "Inserindo Novo Contrato" : "Atualizando Contrato"}</p>
        </div>
      )}
    </div>
  );
}
