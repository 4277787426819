import React from "react";

import styles from "./Applications.module.css";

export default function Applications() {
  return (
    <div className="container">
      <div className={styles.applicationsContainer}>
        <iframe
          className={styles.frame}
          title="aplicativos"
          src="frame-aplicativos"
        />
      </div>
    </div>
  );
}
