import { useMemo, useCallback, useState, useEffect } from "react";
import { useForm, useSelect } from "../../../../../hooks/form";
import { Cidade, IUseAdicionarAtualizarHook, Status, TipoPessoa } from "./types";
import { useCustomFetch, useDebounce, useLoading } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { isValid } from "../../../../../helpers/validations";
import { IContratados, IUseContratados } from "../../hooks/types";
import { useNavigate } from "react-router-dom";

interface IProps {
  contratadosData: IUseContratados;
}

export function useAdicionarAtualizarHook({ contratadosData }: IProps): IUseAdicionarAtualizarHook {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();

  const insertingOrUpdatingContratado = useLoading();

  const contratado = useForm({ required: true });
  const tipoPessoa = useSelect<TipoPessoa>({
    required: true,
    type: "single",
    defaultValue: { value: "F", label: "Pessoa Física" },
  });
  const cpf = useForm({ required: true });
  const cnpj = useForm({ required: true });
  const nomeContato = useForm({ required: true });
  const emailContato = useForm({ required: true });
  const telefoneContato = useForm({ required: true });
  const cep = useForm({ required: true });
  const rua = useForm({ required: true });
  const numero = useForm({ required: true });
  const bairro = useForm({ required: true });
  const cidade = useSelect<Cidade>({ required: true, type: "single" });
  const estado = useForm({ required: true });
  const status = useSelect<Status>({
    required: true,
    type: "single",
    defaultValue: {
      value: "A",
      label: "Ativo",
    },
  });

  const tipoPessoaOptions = useMemo(
    () => [
      { value: "F", label: "Pessoa Física" },
      { value: "J", label: "Pessoa Jurídica" },
    ],
    []
  );

  const statusOptions = useMemo(
    () => [
      { value: "A", label: "Ativo" },
      { value: "I", label: "Inativo" },
    ],
    []
  );

  const [cidadeOptions, setCidadeOptions] = useState<ISelectOption<Cidade>[]>([]);
  const searchingCidadeOptions = useLoading();

  const carregarDados = useCallback(
    (selectedContratado: IContratados) => {
      contratado.setValue(selectedContratado.nomeContratado);
      cnpj.setValue(selectedContratado.cnpjContratado);
      nomeContato.setValue(selectedContratado.nomeContato);
      emailContato.setValue(selectedContratado.emailContato);
      telefoneContato.setValue(selectedContratado.foneContato);
      rua.setValue(selectedContratado.endereco);
      numero.setValue(selectedContratado.numero.toString());
      bairro.setValue(selectedContratado.bairro);
      cep.setValue(selectedContratado.cep);
      cidade.setValue({
        value: selectedContratado.cidade,
        label: `${selectedContratado.cidade.nomeCidade} - ${selectedContratado.cidade.uf} | CEP: ${selectedContratado.cidade.cep}`,
      });
      status.setValue(() => {
        if (selectedContratado.status === "A") {
          return { value: "A", label: "Ativo" };
        } else {
          return { value: "I", label: "Inativo" };
        }
      });
    },
    [bairro, cidade, cnpj, contratado, emailContato, nomeContato, numero, cep, rua, telefoneContato, status]
  );

  const searchCidades = useCallback(
    async (descricao: string) => {
      try {
        searchingCidadeOptions.setLoading(true);
        const json = (await customFetch(`/juridico/searchCidades`, {
          body: { descricao },
        })) as DefaultFetchResponse<Cidade[]>;
        if (json.status === 200) {
          const options = json.object.map((cidade) => ({
            value: cidade,
            label: `${cidade.nomeCidade} - ${cidade.uf} | CEP: ${cidade.cep?.replace(/^(\d{5})(\d{3})$/, "$1-$2")}`,
          }));
          setCidadeOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setCidadeOptions([]);
        }
      } catch (error: any) {
        Modal.error(error.message);
      } finally {
        searchingCidadeOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingCidadeOptions]
  );

  const searchCidadesDebounced = useDebounce(searchCidades);

  const insertOrUpdateContratado = useCallback(
    async (idContratado = 0) => {
      if (
        isValid(contratado, cnpj, nomeContato, telefoneContato, emailContato, cep, rua, numero, bairro, cidade, status)
      ) {
        try {
          insertingOrUpdatingContratado.setLoading(true);
          const json = await customFetch(`/juridico/insertAndUpdateContratado`, {
            body: {
              idContratado,
              nomeContratado: contratado.value,
              cnpjContratado: cnpj.value,
              nomeContato: nomeContato.value,
              foneContato: telefoneContato.value,
              emailContato: emailContato.value,
              endereco: rua.value,
              numero: numero.value,
              cep: cep.value,
              bairro: bairro.value,
              idCidade: cidade.value?.value.idCidade,
              status: status.value?.value,
            },
          });
          if (json.status === 200) {
            if (idContratado === 0)
              contratadosData.searchs.searchContratadosTotalRecordsAndContratadosDebounced(
                contratadosData.filters.searchedContratado.value
              );
            else
              contratadosData.searchs.searchContratadosPaginated(
                contratadosData.searchs.pagination.currentPage - 1,
                contratadosData.filters.searchedContratado.value
              );
            await Modal.success(json.message);
            navigate("/contratos-fornecedor-cliente/contratado");
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error: any) {
          Modal.error(error.message);
        } finally {
          insertingOrUpdatingContratado.setLoading(false);
        }
      }
    },
    [
      Modal,
      bairro,
      cidade,
      cnpj,
      contratado,
      customFetch,
      emailContato,
      insertingOrUpdatingContratado,
      nomeContato,
      numero,
      cep,
      rua,
      telefoneContato,
      status,
      contratadosData,
      navigate,
    ]
  );

  useEffect(() => {
    searchCidades("");
  }, []); // eslint-disable-line

  return {
    carregarDados,
    interactions: {
      insertOrUpdateContratado,
      insertingOrUpdatingContratado: insertingOrUpdatingContratado.isLoading,
    },
    searchs: {
      searchingCidadeOptions: searchingCidadeOptions.isLoading,
      searchCidadesDebounced,
    },
    selectOptions: {
      tipoPessoaOptions,
      cidadeOptions,
      statusOptions,
    },
    form: {
      contratado,
      tipoPessoa,
      cpf,
      cnpj,
      nomeContato,
      emailContato,
      telefoneContato,
      cep,
      rua,
      numero,
      bairro,
      cidade,
      estado,
      status,
    },
  };
}
