import React from "react";

import { ISolicitation } from "../types";
import { Button, LinkButton } from "../../../../../components/Form";
import { useContractData } from "../../../components/hooks/useContractData";
import { useAnalysis } from "./hooks/useAnalysis";
import { ContractConference } from "../../../components/ContractConference/ContractConference";
import { ContractData } from "../../../components/ContractData/ContractData";
import { Circle } from "../../../../../components/Loading";
import { Navigate } from "react-router-dom";
import { useModal } from "../../../../../hooks/contexts";
import { useSelectOptions } from "../../../components/hooks/useSelectOptions";
import { useContractPremiations } from "../../../components/hooks/useContractPremiations";
import { ContractPremiations } from "../../../components/ContractPremiations/ContractPremiations";
import { IUseSolicitationList } from "../hooks/types";

import styles from "./Analysis.module.css";
import { PageType } from "../../../components/types";

interface Props {
  selectedSolicitation: ISolicitation | null;
  solicitationList: IUseSolicitationList;
}

export function Analysis({ selectedSolicitation, solicitationList }: Props) {
  const Modal = useModal();

  const contractData = useContractData();
  const contractPremiations = useContractPremiations();

  const { tableOptions, customerOptions } = useSelectOptions();

  const analysis = useAnalysis({
    contractData,
    contractPremiations,
    solicitationList,
  });

  React.useLayoutEffect(() => {
    if (!selectedSolicitation) return;
    analysis.getSolicitationData(selectedSolicitation.idSolicitacao);
  }, []); // eslint-disable-line

  if (!selectedSolicitation)
    return <Navigate to={"/contratos/financeiro/solicitacoes"} />;

  return (
    <div className="container">
      {!analysis.gettingSolicitationData &&
      !analysis.approvingSolicitation &&
      !analysis.disapprovingSolicitation ? (
        <>
          <div className={styles.navContainer}>
            <div>
              <LinkButton
                to="/contratos/financeiro/solicitacoes"
                buttonStyle="backButton"
                disabled={analysis.gettingSolicitationData}
              />
            </div>
            <h2>
              {selectedSolicitation.idSolicitacao} |{" "}
              {selectedSolicitation.descricaoContrato}
            </h2>
            <div />
          </div>
          <span className="separator" />
          {analysis.solicitationData ? (
            <div className={styles.conferenceContainer}>
              <ContractConference
                pages={[
                  {
                    name: "Dados do contrato",
                    page: (
                      <ContractData
                        data={contractData}
                        premiations={contractPremiations}
                        tableOptions={tableOptions}
                        customerOptions={customerOptions}
                        page={PageType.FINANCEIRO}
                      />
                    ),
                  },
                  contractData.payPremiation.value?.value && {
                    name: "Tipos de Premiação",
                    page: (
                      <ContractPremiations
                        data={contractPremiations}
                        page={PageType.FINANCEIRO}
                      />
                    ),
                  },
                ]}
              />
              <div className={styles.decisionContainer}>
                <Button
                  variant="danger"
                  onClick={async () => {
                    if (
                      !(await Modal.confirm(
                        "Tem certeza que deseja recusar a solicitação?"
                      ))
                    )
                      return;
                    analysis.handleSubmit.disapprove();
                  }}
                >
                  Recusar
                </Button>
                <Button
                  onClick={async () => {
                    if (
                      !(await Modal.confirm(
                        "Tem certeza que deseja aprovar a solicitação?"
                      ))
                    )
                      return;
                    analysis.handleSubmit.approve();
                  }}
                >
                  Aprovar
                </Button>
              </div>
            </div>
          ) : (
            <p className={`lineCardMessage ${styles.withoutDataMessage}`}>
              Nenhum dado foi encontrado
            </p>
          )}
        </>
      ) : analysis.gettingSolicitationData ? (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <p className="loadingMessage">Carregando Dados</p>
        </div>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <p className="loadingMessage">
            {analysis.approvingSolicitation ? "" : "Recusando Solicitação"}
          </p>
        </div>
      )}
    </div>
  );
}
