import React from "react";

import { isValid } from "../../../../../../helpers/validations";
import { formatMoney } from "../../../../../../helpers/format";

import { useNavigate } from "react-router-dom";
import { useChanges, useModal } from "../../../../../../hooks/contexts";
import { useForm, useSelect } from "../../../../../../hooks/form";
import { useCustomFetch, useDebounce, useLoading } from "../../../../../../hooks/async";

import { Table, TBody, Td, Th, THead, Tr } from "../../../../../../components/Data/Table";
import { Button, Input, LinkButton, Select } from "../../../../../../components/Form";

import { Circle } from "../../../../../../components/Loading";

import { Trash } from "phosphor-react";

import styles from "./NewItem.module.css";

export function NewItem({ selectedPriceTable, enterprise, searchItems }) {
  const customFetch = useCustomFetch();
  const Modal = useModal();
  const navigate = useNavigate();
  const { setIsChangesDetected } = useChanges();

  const [step, setStep] = React.useState("details-step");

  const item = useSelect({ type: "single", required: true, emptyMessage: "Selecione um item" });
  const price = useForm({ type: "number", required: true });
  const maximumDiscount = useForm({ type: "number", required: true });
  const profitMargin = useForm({ type: "number", required: true });
  const finalPrice = useForm({ type: "number", required: true });
  const autoCalcPrice = useSelect({ type: "single", required: true });

  const micro = useSelect({ type: "single", required: true });
  const microPrice = useForm({ type: "number", required: true });

  const packing = useSelect({ type: "single", required: true });
  const packingPrice = useForm({ type: "number", required: true });

  const searchingItemOptions = useLoading();
  const searchingMicroOptions = useLoading();
  const searchingPackingOptions = useLoading();
  const registeringNewItemInPriceTable = useLoading();

  const [microList, setMicroList] = React.useState([]);
  const [packingList, setPackingList] = React.useState([]);

  const [itemOptions, setItemOptions] = React.useState([]);

  const [microOptions, setMicroOptions] = React.useState([]);

  const [packingOptions, setPackingOptions] = React.useState([]);

  const autoCalcPriceOptions = React.useMemo(() => {
    return [
      {
        value: true,
        label: "Sim",
      },
      {
        value: false,
        label: "Não",
      },
    ];
  }, []);

  const gotoStepOne = (e) => {
    e.preventDefault();
    setStep("details-step");
  };

  const gotoStepTwo = (e) => {
    e.preventDefault();
    if (isValid(item, price, finalPrice, profitMargin, maximumDiscount, autoCalcPrice)) {
      setStep("micros-step");
    }
  };

  const gotoStepThree = (e) => {
    e.preventDefault();
    if (isValid(item, price, finalPrice, profitMargin, maximumDiscount, autoCalcPrice)) {
      setStep("packaging-step");
    }
  };

  const addMicro = React.useCallback(() => {
    if (isValid(micro, microPrice)) {
      setIsChangesDetected(true);
      setMicroList((old) => {
        const result = [
          ...old,
          {
            ...micro.value,
            preco: microPrice.value,
          },
        ];
        return result.sort(
          (a, b) => Number(a.value.complemento.idComplementar) - Number(b.value.complemento.idComplementar)
        );
      });

      setMicroOptions((old) => {
        return old.filter(
          (item) =>
            Number(item.value.complemento.idComplementar) !== Number(micro.value.value.complemento.idComplementar)
        );
      });

      micro.reset();
      microPrice.reset();
    }
  }, [micro, microPrice, setIsChangesDetected]);

  const removeMicro = React.useCallback(
    (id) => {
      const { preco, ...removedMicro } = microList.find(
        (item) => Number(item.value.complemento.idComplementar) === Number(id)
      );

      setMicroList((old) => {
        return old.filter((item) => Number(item.value.complemento.idComplementar) !== Number(id));
      });

      setMicroOptions((old) => {
        const result = [...old, { ...removedMicro }];

        return result.sort(
          (a, b) => Number(a.value.complemento.idComplementar) - Number(b.value.complemento.idComplementar)
        );
      });
    },
    [microList]
  );

  const addPacking = React.useCallback(() => {
    if (isValid(packing, packingPrice)) {
      setIsChangesDetected(true);
      setPackingList((old) => {
        const result = [
          ...old,
          {
            ...packing.value,
            preco: packingPrice.value,
          },
        ];
        return result.sort((a, b) => Number(a.value.embalagem.idEnvase) - Number(b.value.embalagem.idEnvase));
      });

      setPackingOptions((old) => {
        return old.filter(
          (item) => Number(item.value.embalagem.idEnvase) !== Number(packing.value.value.embalagem.idEnvase)
        );
      });

      packing.reset();
      packingPrice.reset();
    }
  }, [packing, packingPrice, setIsChangesDetected]);

  const removePacking = React.useCallback(
    (id) => {
      const { preco, ...removedPacking } = packingList.find(
        (item) => Number(item.value.embalagem.idEnvase) === Number(id)
      );

      setPackingList((old) => {
        return old.filter((item) => Number(item.value.embalagem.idEnvase) !== Number(id));
      });

      setPackingOptions((old) => {
        const result = [...old, { ...removedPacking }];

        return result.sort((a, b) => Number(a.value.embalagem.idEnvase) - Number(b.value.embalagem.idEnvase));
      });
    },
    [packingList]
  );

  const searchItemsToAddInPriceTable = React.useCallback(
    async (description = "", enterpriseId) => {
      try {
        searchingItemOptions.setLoading(true);
        const json = await customFetch("/products/searchItemsToAddInPriceTable", {
          body: {
            idTabela: Number(selectedPriceTable.tabela.idTabela),
            descricao: description,
            idEmpresa: enterpriseId,
          },
        });
        if (json.status === 200) {
          const options = json.object.map((option) => {
            return {
              value: option,
              label: `${option.formula.idFormulado} | ${option.formula.descricaoFormulado}`,
            };
          });
          setItemOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setItemOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingItemOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingItemOptions, selectedPriceTable]
  );

  const searchMicrosToAddInItem = React.useCallback(
    async (description = "", microList) => {
      try {
        searchingMicroOptions.setLoading(true);
        const json = await customFetch("/products/searchMicrosToAddInItem", {
          body: {
            descricao: description,
            idItem: 0,
          },
        });
        if (json.status === 200) {
          const options = json.object
            .filter((option) => {
              return !microList.some(
                (micro) => micro.value.complemento.idComplementar === option.complemento.idComplementar
              );
            })
            .map((option) => {
              return {
                value: option,
                label: `${option.complemento.idComplementar} | ${option.complemento.descricaoComplementar}`,
              };
            });
          setMicroOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setMicroOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingMicroOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingMicroOptions]
  );

  const searchPackagingToAddInItem = React.useCallback(
    async (description = "", packingList) => {
      try {
        searchingPackingOptions.setLoading(true);
        const json = await customFetch("/products/searchPackagingToAddInItem", {
          body: {
            descricao: description,
            idItem: 0,
          },
        });
        if (json.status === 200) {
          const options = json.object
            .filter((option) => {
              return !packingList.some((packing) => packing.value.embalagem.idEnvase === option.embalagem.idEnvase);
            })
            .map((option) => {
              return {
                value: option,
                label: `${option.embalagem.idEnvase} | ${option.embalagem.descricaoEnvase}`,
              };
            });
          setPackingOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setPackingOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingPackingOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingPackingOptions]
  );

  const { current: searchItemsToAddInPriceTableDebounced } = React.useRef(
    useDebounce((description, enterpriseId) => {
      searchItemsToAddInPriceTable(description, enterpriseId);
    })
  );

  const clearForm = React.useCallback(() => {
    item.reset();
    price.reset();
    maximumDiscount.reset();
    profitMargin.reset();
    finalPrice.reset();
    autoCalcPrice.setValue(autoCalcPriceOptions[0]);
    micro.reset();
    microPrice.reset();
    packing.reset();
    packingPrice.reset();
    setMicroList([]);
    setPackingList([]);
    searchItemsToAddInPriceTable("", enterprise.value.value.idEmpresa);
    searchMicrosToAddInItem("", []);
    searchPackagingToAddInItem("", []);
    setStep("details-step");
    setIsChangesDetected(false);
  }, [
    autoCalcPrice,
    autoCalcPriceOptions,
    enterprise,
    finalPrice,
    item,
    maximumDiscount,
    micro,
    microPrice,
    packing,
    packingPrice,
    price,
    profitMargin,
    searchItemsToAddInPriceTable,
    searchMicrosToAddInItem,
    searchPackagingToAddInItem,
    setIsChangesDetected,
  ]);

  const registerItemInPriceTable = React.useCallback(async () => {
    try {
      registeringNewItemInPriceTable.setLoading(true);
      const json = await customFetch("/products/registerAndUpdateItemOfPriceTable", {
        body: {
          idTabela: selectedPriceTable.tabela.idTabela,
          idEmpresa: enterprise.value.value.idEmpresa,
          idFormulado: item.value.value.formula.idFormulado,
          custo: Number(price.value),
          descontoMaximo: Number(maximumDiscount.value),
          margemLucro: Number(profitMargin.value),
          valorProduto: Number(finalPrice.value),
          modificarPreco: autoCalcPrice.value.value,
          status: true,
          listaComplementos: microList.map((micro) => {
            return {
              idComplemento: micro.value.complemento.idComplementar,
              preco: Number(micro.preco),
              desativar: false,
            };
          }),
          listaEmbalagens: packingList.map((packing) => {
            return {
              idEmbalagem: Number(packing.value.embalagem.idEnvase),
              preco: Number(packing.preco),
              desativar: false,
            };
          }),
        },
      });
      if (json.status === 200) {
        clearForm();
        searchItems("", enterprise.value.value.idEmpresa);
        await Modal.success(json.message);
        navigate("/produtos/tabela-de-precos/atualizar/itens");
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      registeringNewItemInPriceTable.setLoading(false);
    }
  }, [
    Modal,
    autoCalcPrice,
    customFetch,
    maximumDiscount,
    enterprise,
    finalPrice,
    item,
    microList,
    packingList,
    price,
    profitMargin,
    registeringNewItemInPriceTable,
    selectedPriceTable,
    searchItems,
    clearForm,
    navigate,
  ]);

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      if (!isValid(item, price, finalPrice, profitMargin, maximumDiscount, autoCalcPrice)) {
        Modal.error("Os detalhes do item estão incompletos!");
        return;
      }
      if (!packingList.length) {
        Modal.error("É necessário adicionar ao menos 1 (uma) embalagem ao item!");
        return;
      }
      registerItemInPriceTable();
    },
    [
      Modal,
      autoCalcPrice,
      maximumDiscount,
      finalPrice,
      item,
      packingList,
      price,
      profitMargin,
      registerItemInPriceTable,
    ]
  );

  React.useEffect(() => {
    autoCalcPrice.setValue(autoCalcPriceOptions[0]);
    searchItemsToAddInPriceTable("", enterprise.value.value.idEmpresa);
    searchMicrosToAddInItem("", []);
    searchPackagingToAddInItem("", []);
  }, []); // eslint-disable-line

  return (
    <div>
      {!registeringNewItemInPriceTable.isLoading ? (
        <>
          <div>
            <LinkButton to="/produtos/tabela-de-precos/atualizar/itens" buttonStyle="backButton" />
          </div>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.stepsNavigationContainer}>
              <button
                type="button"
                className={`${/details-step|micros-step|packaging-step/.test(step) ? "active" : ""}`}
                onClick={gotoStepOne}
              >
                Detalhes
              </button>
              <button
                type="button"
                className={`${/micros-step|packaging-step/.test(step) ? "active" : ""}`}
                onClick={gotoStepTwo}
              >
                Micros
              </button>
              <button
                type="button"
                className={`${/packaging-step/.test(step) ? "active" : ""}`}
                onClick={gotoStepThree}
              >
                Embalagens
              </button>
            </div>
            {step === "details-step" ? (
              <div>
                <fieldset className={`fieldset ${styles.fieldset}`}>
                  <legend className={`fieldsetLegend`}>Detalhes do Item</legend>
                  <div className={styles.itemDetailsContainer}>
                    <div className={styles.fieldDiv} data-grid="item">
                      <label className="label" htmlFor="item">
                        Item / Fórmula
                      </label>
                      <Select
                        id="item"
                        placeholder="Selecione um item para adicionar"
                        options={itemOptions}
                        value={item.value}
                        error={item.error}
                        onChange={(value) => {
                          item.onChange(value);
                          setIsChangesDetected(true);
                        }}
                        onBlur={item.onBlur}
                        onInputChange={(value) => {
                          searchItemsToAddInPriceTableDebounced(value, Number(enterprise.value.value.idEmpresa));
                        }}
                        isLoading={searchingItemOptions.isLoading}
                      />
                    </div>
                    <div className={styles.fieldDiv} data-grid="price">
                      <label className="label" htmlFor="price">
                        Preço de Custo (R$)
                      </label>
                      <Input
                        id="price"
                        type="number"
                        className={`cleanInputNumber`}
                        placeholder="Digite o custo de produção do item"
                        value={price.value}
                        error={price.error}
                        onChange={(value) => {
                          price.onChange(value);
                          setIsChangesDetected(true);
                        }}
                        onBlur={price.onBlur}
                      />
                    </div>
                    <div className={styles.fieldDiv} data-grid="finalPrice">
                      <label className="label" htmlFor="finalPrice">
                        Preço Final (R$)
                      </label>
                      <Input
                        id="finalPrice"
                        type="number"
                        className={`cleanInputNumber`}
                        placeholder="Digite o preço final do item"
                        value={finalPrice.value}
                        error={finalPrice.error}
                        onChange={(value) => {
                          finalPrice.onChange(value);
                          setIsChangesDetected(true);
                        }}
                        onBlur={finalPrice.onBlur}
                      />
                    </div>
                    <div className={styles.fieldDiv} data-grid="profitMargin">
                      <label className="label" htmlFor="profitMargin">
                        Margem de Lucro (%)
                      </label>
                      <Input
                        id="profitMargin"
                        type="number"
                        className={`cleanInputNumber`}
                        placeholder="Digite a margem de lucro do item"
                        value={profitMargin.value}
                        error={profitMargin.error}
                        onChange={(value) => {
                          profitMargin.onChange(value);
                          setIsChangesDetected(true);
                        }}
                        onBlur={profitMargin.onBlur}
                      />
                    </div>
                    <div className={styles.fieldDiv} data-grid="maximumDiscount">
                      <label className="label" htmlFor="maximumDiscount">
                        Desconto Máximo (%)
                      </label>
                      <Input
                        id="maximumDiscount"
                        type="number"
                        className={`cleanInputNumber`}
                        placeholder="Digite o desconto máximo do item"
                        value={maximumDiscount.value}
                        error={maximumDiscount.error}
                        onChange={(value) => {
                          maximumDiscount.onChange(value);
                          setIsChangesDetected(true);
                        }}
                        onBlur={maximumDiscount.onBlur}
                      />
                    </div>
                    <div className={styles.fieldDiv} data-grid="autoCalcPrice">
                      <label className="label" htmlFor="autoCalcPrice">
                        Calcular Preço Automaticamente
                      </label>
                      <Select
                        id="autoCalcPrice"
                        options={autoCalcPriceOptions}
                        value={autoCalcPrice.value}
                        onChange={(value) => {
                          autoCalcPrice.onChange(value);
                          setIsChangesDetected(true);
                        }}
                        isClearable={false}
                        isSearchable={false}
                      />
                    </div>
                  </div>
                </fieldset>
                <div className={styles.stepNavigateButtonContainer}>
                  <Button type="button" onClick={gotoStepTwo}>
                    Próximo
                  </Button>
                </div>
              </div>
            ) : step === "micros-step" ? (
              <div>
                <fieldset className={`fieldset ${styles.fieldset}`}>
                  <legend className={`fieldsetLegend`}>Micros no Item</legend>
                  <div>
                    <div className={styles.addMicroContainer}>
                      <div>
                        <label className="label" htmlFor="micro">
                          Micro
                        </label>
                        <Select
                          id="micro"
                          placeholder="Selecione um micro para adicionar ao item"
                          options={microOptions}
                          value={micro.value}
                          error={micro.error}
                          onChange={micro.onChange}
                        />
                      </div>
                      <div>
                        <label className="label" htmlFor="microPrice">
                          Preço do Micro
                        </label>
                        <Input
                          id="microPrice"
                          type="number"
                          className={`cleanInputNumber`}
                          placeholder="Digite o preço do micro"
                          value={microPrice.value}
                          error={microPrice.error}
                          onChange={microPrice.onChange}
                        />
                      </div>
                      <div>
                        <Button type="button" onClick={addMicro} className={styles.addButton}>
                          Adicionar
                        </Button>
                      </div>
                    </div>
                    <div className={styles.tableContainer}>
                      {microList.length ? (
                        <Table className={styles.table}>
                          <THead>
                            <Tr>
                              <Th width="2.5rem">ID</Th>
                              <Th>Descricao</Th>
                              <Th width="100px">Preço</Th>
                              <Th></Th>
                            </Tr>
                          </THead>
                          <TBody>
                            {microList.map((micro) => {
                              return (
                                <Tr key={micro.value.complemento.idComplementar}>
                                  <Td heading="ID">{micro.value.complemento.idComplementar}</Td>
                                  <Td heading="Descrição">{micro.value.complemento.descricaoComplementar}</Td>
                                  <Td heading="Preço">{formatMoney(micro.preco)}</Td>
                                  <Td heading="Remover Micro" data-option width="80px">
                                    <Button
                                      type="button"
                                      className={styles.deleteButtonOnTable}
                                      variant="danger"
                                      onClick={() => {
                                        removeMicro(micro.value.complemento.idComplementar);
                                      }}
                                      data-option-button
                                    >
                                      <Trash weight="fill" />
                                    </Button>
                                  </Td>
                                </Tr>
                              );
                            })}
                          </TBody>
                        </Table>
                      ) : (
                        <p className={`lineCardMessage`}>Nenhum micro foi adicionado ao item</p>
                      )}
                    </div>
                  </div>
                </fieldset>
                <div className={styles.stepNavigateButtonContainer}>
                  <Button type="button" variant="danger" onClick={gotoStepOne}>
                    Anterior
                  </Button>
                  <Button type="button" onClick={gotoStepThree}>
                    Próximo
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <fieldset className={`fieldset ${styles.fieldset}`}>
                  <legend className={`fieldsetLegend`}>Embalagens no Item</legend>
                  <div>
                    <div className={styles.addPackingContainer}>
                      <div>
                        <label className="label" htmlFor="packing">
                          Embalagem
                        </label>
                        <Select
                          id="packing"
                          placeholder="Selecione uma embalagem para adicionar ao item"
                          options={packingOptions}
                          value={packing.value}
                          error={packing.error}
                          onChange={packing.onChange}
                        />
                      </div>
                      <div>
                        <label className="label" htmlFor="packingPrice">
                          Preço da Embalagem
                        </label>
                        <Input
                          id="packingPrice"
                          type="number"
                          className={`cleanInputNumber`}
                          placeholder="Digite o preço da embalagem"
                          value={packingPrice.value}
                          error={packingPrice.error}
                          onChange={packingPrice.onChange}
                        />
                      </div>
                      <div>
                        <Button type="button" onClick={addPacking} className={styles.addButton}>
                          Adicionar
                        </Button>
                      </div>
                    </div>
                    <div className={styles.tableContainer}>
                      {packingList.length ? (
                        <Table className={styles.table}>
                          <THead>
                            <Tr>
                              <Th width="2.5rem">ID</Th>
                              <Th>Descricao</Th>
                              <Th width="100px">Preço</Th>
                              <Th></Th>
                            </Tr>
                          </THead>
                          <TBody>
                            {packingList.map((packing) => {
                              return (
                                <Tr key={packing.value.embalagem.idEnvase}>
                                  <Td heading="ID">{packing.value.embalagem.idEnvase}</Td>
                                  <Td heading="Descrição">{packing.value.embalagem.descricaoEnvase}</Td>
                                  <Td heading="Preço">{formatMoney(packing.preco)}</Td>
                                  <Td heading="Remover Embalagem" data-option width="80px">
                                    <Button
                                      type="button"
                                      className={styles.deleteButtonOnTable}
                                      variant="danger"
                                      onClick={() => {
                                        removePacking(packing.value.embalagem.idEnvase);
                                      }}
                                      data-option-button
                                    >
                                      <Trash weight="fill" />
                                    </Button>
                                  </Td>
                                </Tr>
                              );
                            })}
                          </TBody>
                        </Table>
                      ) : (
                        <p className={`lineCardMessage`}>Nenhuma embalagem foi adicionada ao item</p>
                      )}
                    </div>
                  </div>
                </fieldset>
                <div className={styles.stepNavigateButtonContainer}>
                  <Button type="button" variant="danger" onClick={gotoStepTwo}>
                    Anterior
                  </Button>
                  <Button>Concluir</Button>
                </div>
              </div>
            )}
          </form>
        </>
      ) : (
        <div className={`loadingContainer`}>
          <Circle size={100} />
          <span className="loadingMessage">Adicionando novo item à tabela de preços</span>
        </div>
      )}
    </div>
  );
}
