import React from "react";

import { useNavigate } from "react-router-dom";
import { useModal } from "../../../../hooks/contexts";
import { useDebounce } from "../../../../hooks/async";

import { Table, TBody, Td, Th, THead, Tr } from "../../../../components/Data/Table";
import { Input } from "../../../../components/Form";
import { Paginate } from "../../../../components/Paginate/Paginate";
import { formatDate } from "../../../../helpers/format";
import { Button, LinkButton } from "../../../../components/Form";

import { Circle } from "../../../../components/Loading";

import { Pencil } from "phosphor-react";

import styles from "./GroupList.module.css";

export function GroupList({
  pagination,
  searchGroups,
  searchTotalGroupRecordsAndGroups,
  searchedGroup,
  groupList,
  setSelectedGroup,
  searchingGroups,
}) {
  const Modal = useModal();
  const navigate = useNavigate();

  const handleClickEditButton = React.useCallback(
    (groupData) => {
      setSelectedGroup(groupData);
      navigate("atualizar");
    },
    [navigate, setSelectedGroup]
  );

  const handlePageChange = React.useCallback(
    async (group, page) => {
      try {
        searchingGroups.setLoading(true);
        const json = await searchGroups(group, page);
        if (json.status === 200) {
          groupList.setValue(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingGroups.setLoading(false);
      }
    },
    [Modal, groupList, searchGroups, searchingGroups]
  );

  const { current: searchGroupList } = React.useRef(
    useDebounce((group) => {
      searchTotalGroupRecordsAndGroups(group);
    })
  );

  React.useEffect(() => {
    if (!groupList.value.length) searchTotalGroupRecordsAndGroups();
  }, []); //eslint-disable-line

  return (
    <div className={`container ${styles.container}`}>
      <div className={`${styles.buttonContainer}`}>
        <LinkButton to="novo">Novo Grupo</LinkButton>
      </div>
      <div className={`${styles.searchContainer}`}>
        <label className="label">Filtrar Grupos</label>
        <Input
          placeholder="Busque por um grupo"
          value={searchedGroup.value}
          onChange={({ target }) => {
            searchedGroup.setValue(target.value);
            searchGroupList(target.value);
          }}
        />
      </div>
      <div className={`${styles.groupListContainer}`}>
        {groupList.value.length && !searchingGroups.isLoading ? (
          <Table title="Grupos Cadastrados" className={styles.tableGroup}>
            <THead>
              <Tr>
                <Th>ID</Th>
                <Th>Descrição</Th>
                <Th>Unidade</Th>
                <Th>Situação</Th>
                <Th>Última modificação</Th>
                <Th></Th>
              </Tr>
            </THead>
            <TBody>
              {groupList.value.map((group) => {
                return (
                  <Tr key={group.idGrupo}>
                    <Td data-heading="ID">{group.idGrupo}</Td>
                    <Td data-heading="Descrição">{group.descricaoGrupo}</Td>
                    <Td data-heading="Unidade">{group.tipoAbreviadoGrupo || "Indefinido"}</Td>
                    <Td data-heading="Situação">{group.status === "A" ? "Ativo" : "Inativo"}</Td>
                    <Td data-heading="Última modificação">
                      {formatDate(group.dataManutencao, "dd/MM/yyyy às hh:mm:ss")}
                    </Td>
                    <Td data-heading="Editar" data-option>
                      <Button
                        className={styles.editGroupButton}
                        onClick={() => {
                          const selectedGroup = {
                            ...group,
                            status: !!(group.status === "A"),
                          };
                          handleClickEditButton(selectedGroup);
                        }}
                        data-option-button>
                        <Pencil weight="fill" />
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </TBody>
          </Table>
        ) : searchingGroups.isLoading ? (
          <div className={styles.searchingGroupsLoadingContainer}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={styles.noGroupsMessage}>Nenhum grupo encontrado</p>
        )}
      </div>
      <div>
        <Paginate
          classNameContainer={styles.paginationContainer}
          totalRecords={pagination.totalRecords}
          maxItems={pagination.maxItems}
          currentPage={pagination.currentPage}
          setCurrentPage={pagination.setCurrentPage}
          onPageChange={(page) => handlePageChange(searchedGroup.value, page)}
        />
      </div>
    </div>
  );
}
