import React from "react";

import { useCustomFetch, useDebounce, useLoading } from "../../../../../../hooks/async";
import { useModal } from "../../../../../../hooks/contexts";

import { Button, Input, LinkButton } from "../../../../../../components/Form";
import { Table, TBody, Td, Th, THead, Tr } from "../../../../../../components/Data/Table";

import { Circle } from "../../../../../../components/Loading";

import { Trash } from "phosphor-react";

import styles from "./EnterpriseList.module.css";

export function EnterpriseList({
  selectedPriceTable,
  filteredEnterprises,
  searchEnterprises,
  searchingEnterprises,
  enterprises,
}) {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const removingEnterprise = useLoading();

  const { current: searchEnterprisesDebounced } = React.useRef(
    useDebounce((description) => {
      searchEnterprises(description);
    })
  );

  const removeEnterprise = React.useCallback(
    async (enterpriseId, currentStatus) => {
      const confirm = await Modal.confirm("Deseja mesmo remover a empresa desta tabela?");
      if (confirm) {
        try {
          removingEnterprise.setLoading(true);
          const json = await customFetch("/products/registerAndUpdateEnterpriseOfPriceTable", {
            body: {
              idTabela: Number(selectedPriceTable.tabela.idTabela),
              idEmpresa: Number(enterpriseId),
              status: currentStatus,
            },
          });
          if (json.status === 200) {
            Modal.success(json.message);
            searchEnterprises(filteredEnterprises.value);
          } else if (json.status === 500) {
            Modal.error(json.message, json.object);
          }
        } catch (error) {
          Modal.error(error);
        } finally {
          removingEnterprise.setLoading(false);
        }
      }
    },
    [Modal, customFetch, filteredEnterprises, searchEnterprises, selectedPriceTable, removingEnterprise]
  );

  React.useEffect(() => {
    if (!enterprises.length) searchEnterprises();
  }, []); // eslint-disable-line

  return (
    <div>
      <div className={styles.navigationContainer}>
        <LinkButton to="novo">Adicionar Empresa</LinkButton>
      </div>
      <div className={styles.filterContainer}>
        <div>
          <label htmlFor="filteredEnterprises" className="label">
            Filtrar Empresas
          </label>
          <Input
            id="filteredEnterprises"
            placeholder="Digite o nome ou o id da empresa para filtrar"
            value={filteredEnterprises.value}
            onChange={({ target }) => {
              filteredEnterprises.setValue(target.value);
              searchEnterprisesDebounced(target.value);
            }}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        {enterprises.length && !searchingEnterprises.isLoading && !removingEnterprise.isLoading ? (
          <Table title="Empresas na Tabela" className={styles.tableEnterprises}>
            <THead>
              <Tr>
                <Th width="6.25rem">ID</Th>
                <Th>Nome</Th>
                <Th width="80px"></Th>
              </Tr>
            </THead>
            <TBody>
              {enterprises.map((enterprise) => {
                return (
                  <Tr key={enterprise.empresa.idEmpresa}>
                    <Td heading="ID">{enterprise.empresa.idEmpresa}</Td>
                    <Td heading="Nome">{`${enterprise.empresa.nomeEmpresa}`}</Td>
                    <Td heading="Remover Empresa" data-option>
                      <Button
                        className={styles.deleteEnterpriseButton}
                        variant="danger"
                        onClick={() => {
                          removeEnterprise(enterprise.empresa.idEmpresa, false);
                        }}
                        data-option-button
                      >
                        <Trash weight="fill" />
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </TBody>
          </Table>
        ) : searchingEnterprises.isLoading || removingEnterprise.isLoading ? (
          <div className={`loadingContainer`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhuma empresa encontrada</p>
        )}
      </div>
    </div>
  );
}
