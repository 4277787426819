import React from "react";

import { Routes, Route } from "react-router-dom";

import { usePagination } from "../../../hooks/pagination";
import { useCustomFetch, useLoading } from "../../../hooks/async";
import { useForm, useSelect } from "../../../hooks/form";
import { useModal } from "../../../hooks/contexts";

import { FormulaList } from "./FormulaList/FormulaList";
import { NewFormula } from "./NewFormula/NewFormula";
import { UpdateFormula } from "./UpdateFormula/UpdateFormula";

export function ProductFormulas() {
  const pagination = usePagination();
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const searchedFormula = useForm({ type: "", required: false });
  const searchedGroup = useSelect({ type: "single", required: false });
  const searchedStatus = useSelect({ type: "single", required: false });

  const searchingFormulas = useLoading();
  const searchingGroupOptions = useLoading();

  const [formulaList, setFormulaList] = React.useState([]);
  const [selectedFormula, setSelectedFormula] = React.useState(null);

  const [groupOptions, setGroupOptions] = React.useState([]);

  const clearFormulaFilter = React.useCallback(() => {
    searchedFormula.setValue("");
    searchedGroup.setValue("");
    searchedStatus.setValue("");
  }, [searchedFormula, searchedGroup, searchedStatus]);

  const searchGroupOptions = React.useCallback(async () => {
    try {
      searchingGroupOptions.setLoading(true);
      const json = await customFetch("/products/searchGroups", {
        body: {
          descricao: "",
        },
      });
      if (json.status === 200) {
        const options = json.object.map((group) => {
          return {
            value: group,
            label: `${group.idGrupo} | ${group.descricaoGrupo}`,
          };
        });
        setGroupOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingGroupOptions.setLoading(false);
    }
  }, [Modal, customFetch, searchingGroupOptions]);

  const searchTotalFormulaRecords = React.useCallback(
    (formula = "", groupId, status) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/products/searchTotalFormulaRecords", {
            body: {
              tamanho: pagination.maxItems,
              descricao: formula,
              ativo: typeof status === "boolean" ? status : null,
              idGrupo: groupId || null,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const searchFormulas = React.useCallback(
    async (formula = "", groupId, status, page) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/products/searchFormulasPaginated", {
            body: {
              pagina: page ? Number(page) - 1 : 0,
              tamanho: pagination.maxItems,
              descricao: formula,
              ativo: typeof status === "boolean" ? status : null,
              idGrupo: groupId || null,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const researchFormulas = React.useCallback(
    async (formula, groupId, status, page) => {
      try {
        searchingFormulas.setLoading(true);
        const json = await searchFormulas(formula, groupId, status, page);
        if (json.status === 200) {
          setFormulaList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingFormulas.setLoading(false);
      }
    },
    [Modal, searchFormulas, searchingFormulas]
  );

  const searchTotalFormulaRecordsAndFormulas = React.useCallback(
    async (formula = "", groupId, status) => {
      if (formula === "" && groupId === undefined && status === undefined) {
        clearFormulaFilter();
      }
      try {
        searchingFormulas.setLoading(true);
        const jsonFormulas = await searchFormulas(formula, groupId, status);
        if (jsonFormulas.status === 200) {
          const jsonRecords = await searchTotalFormulaRecords(formula, groupId, status);
          if (jsonRecords.status === 200) {
            setFormulaList(jsonFormulas.object);
            pagination.setTotalRecords(jsonRecords.object.total);
          } else if (jsonRecords.status === 500) {
            Modal.error(jsonRecords.message, jsonRecords.object);
          } else {
            setFormulaList([]);
          }
        } else if (jsonFormulas.status === 500) {
          Modal.error(jsonFormulas.message, jsonFormulas.object);
        } else {
          setFormulaList([]);
          pagination.reset();
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        pagination.setCurrentPage(1);
        searchingFormulas.setLoading(false);
      }
    },
    [Modal, clearFormulaFilter, pagination, searchFormulas, searchTotalFormulaRecords, searchingFormulas]
  );

  React.useEffect(() => {
    searchGroupOptions();
  }, []); // eslint-disable-line

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <FormulaList
              pagination={pagination}
              searchTotalFormulaRecords={searchTotalFormulaRecords}
              searchFormulas={searchFormulas}
              searchTotalFormulaRecordsAndFormulas={searchTotalFormulaRecordsAndFormulas}
              searchedFormula={searchedFormula}
              searchedGroup={searchedGroup}
              searchedStatus={searchedStatus}
              groupOptions={groupOptions}
              searchingGroupOptions={searchingGroupOptions}
              formulaList={{
                value: formulaList,
                setValue: setFormulaList,
              }}
              setSelectedFormula={setSelectedFormula}
              searchingFormulas={searchingFormulas}
            />
          }
        />
        <Route
          path="novo"
          element={
            <NewFormula
              searchTotalFormulaRecordsAndFormulas={searchTotalFormulaRecordsAndFormulas}
              groupOptions={groupOptions}
            />
          }
        />
        <Route
          path="atualizar"
          element={
            <UpdateFormula
              researchFormulas={(formula, group, status) =>
                researchFormulas(formula, group, status, pagination.currentPage)
              }
              selectedFormula={selectedFormula}
              groupOptions={groupOptions}
              groupFilters={{
                formula: searchedFormula.value,
                group: searchedGroup.value?.value?.idGrupo,
                status: searchedStatus.value?.value,
              }}
            />
          }
        />
      </Routes>
    </div>
  );
}
