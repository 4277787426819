import { Pencil } from "phosphor-react";
import React from "react";
import { useNavigate } from "react-router-dom";
// import { Table, TBody, Td, Th, THead, Tr } from "../../../../../components/Data/Table";
import { Button, Input, LinkButton } from "../../../../../components/Form";
import { Circle } from "../../../../../components/Loading";
import { Paginate } from "../../../../../components/Paginate/Paginate";
import { formatDate } from "../../../../../helpers/format";
import { useDebounce } from "../../../../../hooks/async";
import { useModal } from "../../../../../hooks/contexts";
import { ICommissionTable, ISearchCommissionTablesResponse } from "../CommissionTables";

import styles from "./CommissionTablesList.module.css";
import { Table } from "../../../../../components/Data/XTable";

interface IPriceTablesListProps {
  maxItems: {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
  };
  searchedCommissionTable: IUseForm;
  commissionTableList: {
    value: ICommissionTable[];
    setValue: React.Dispatch<React.SetStateAction<ICommissionTable[]>>;
  };
  searchingCommissionTables: IUseLoading;
  pagination: IUsePagination;
  searchCommissionTablesPromise: (
    description?: string,
    page?: number,
    maxItems?: number
  ) => Promise<ISearchCommissionTablesResponse>;
  searchTotalCommissionTableRecordsAndCommissionTables: (description?: string, maxItems?: number) => Promise<void>;
  setSelectedTable: React.Dispatch<React.SetStateAction<ICommissionTable | null>>;
}

export function CommissionTablesList({
  maxItems,
  commissionTableList,
  searchCommissionTablesPromise,
  searchTotalCommissionTableRecordsAndCommissionTables,
  searchingCommissionTables,
  searchedCommissionTable,
  pagination,
  setSelectedTable,
}: IPriceTablesListProps) {
  const Modal = useModal();
  const navigate = useNavigate();

  const { current: searchTotalCommissionTableRecordsAndCommissionTablesDebounced } = React.useRef(
    useDebounce(searchTotalCommissionTableRecordsAndCommissionTables)
  );

  const handleClickEditCommissionTable = React.useCallback(
    (item: ICommissionTable) => {
      setSelectedTable(item);
      navigate("itens");
    },
    [navigate, setSelectedTable]
  );

  const tableData = React.useMemo(() => {
    const data = commissionTableList.value.map((item) => {
      return {
        ...item,
        editar: (
          <>
            <Button
              className={styles.editCommissionTableButton}
              type="button"
              variant="edit"
              onClick={() => {
                handleClickEditCommissionTable(item);
              }}
              data-option-button>
              <Pencil weight="fill" />
            </Button>
          </>
        ),
      };
    });
    return {
      columns: [
        {
          title: "ID",
          objectName: "idTabela",
          isSorted: true,
          style: { width: ".625rem" },
        },
        {
          title: "Descrição",
          objectName: "descricaoTabela",
          isSorted: true,
        },
        {
          title: "Status",
          objectName: "situacaoTabela",
          isSorted: true,
          formatting: (value: string) => (value === "S" ? "Ativo" : "Inativo"),
          style: { width: ".625rem" },
        },
        {
          title: "Última Modificação",
          objectName: "dataManutencao",
          isSorted: true,
          formatting: (value: string) => formatDate(value, "dd/MM/yyyy às hh:mm"),
          style: { width: "15rem" },
        },
        {
          title: "",
          objectName: "editar",
          isSorted: false,
          style: { width: "5rem" },
        },
      ],
      data: data,
    };
  }, [commissionTableList.value, handleClickEditCommissionTable]);

  const handlePageChange = async (description: string, page: number, maxItems: number) => {
    try {
      searchingCommissionTables.setLoading(true);
      const json = (await searchCommissionTablesPromise(
        description,
        page,
        maxItems
      )) as ISearchCommissionTablesResponse;
      if (json.status === 200) {
        commissionTableList.setValue(json.object);
        window.scrollTo(0, 0);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingCommissionTables.setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!commissionTableList.value.length) {
      searchTotalCommissionTableRecordsAndCommissionTables("", maxItems.value);
    }
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.navigationContainer}>
        <LinkButton
          to="nova"
          variant={undefined}
          className={undefined}
          classNameContainer={undefined}
          disabled={undefined}>
          Nova Faixa
        </LinkButton>
      </div>
      <div>
        <label htmlFor="" className="label">
          Tabela de Comissão
        </label>
        <Input
          placeholder="Digite o Id ou o Nome da tabela para filtrar"
          value={searchedCommissionTable.value}
          onChange={(e) => {
            searchedCommissionTable.onChange(e);
            searchTotalCommissionTableRecordsAndCommissionTablesDebounced(e.target.value, maxItems.value);
          }}
        />
      </div>
      <div className={styles.contentContainer}>
        {commissionTableList.value.length ? (
          <div className={styles.itemQuantityContainer}>
            <select
              className={styles.itemQuantityContainer__select}
              value={maxItems.value}
              onChange={({ target }) => {
                maxItems.setValue(Number(target.value));
                searchTotalCommissionTableRecordsAndCommissionTables(
                  searchedCommissionTable.value,
                  Number(target.value)
                );
              }}>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <p>resultados por página</p>
          </div>
        ) : null}
        {commissionTableList.value.length && !searchingCommissionTables.isLoading ? (
          <Table tableData={tableData} className={styles.table} />
        ) : searchingCommissionTables.isLoading ? (
          <div className={`loadingContainer ${styles.loadingContainer}`}>
            <Circle size={100} />
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhuma faixa de preços encontrada</p>
        )}
      </div>
      <div>
        <Paginate
          classNameContainer={styles.paginationContainer}
          maxItems={pagination.maxItems}
          totalRecords={pagination.totalRecords}
          currentPage={pagination.currentPage}
          setCurrentPage={pagination.setCurrentPage}
          onPageChange={(page: number) => handlePageChange(searchedCommissionTable.value, page, maxItems.value)}
        />
      </div>
    </div>
  );
}
