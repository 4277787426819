import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, LinkButton } from "../../../../components/Form";
import { Circle } from "../../../../components/Loading";
import { isValid } from "../../../../helpers/validations";
import { useCustomFetch, useLoading } from "../../../../hooks/async";
import { useModal } from "../../../../hooks/contexts";
import { useForm } from "../../../../hooks/form";

import styles from "./NewPeopleCategory.module.css";

interface INewPeopleCategoryProps {
  searchTotalPeopleCategoriesRecordsAndPeopleCategories: () => void;
}

interface IHandleSubmitNewPeopleCategoryResponse {
  response: number;
  message: string;
  status: number;
  object: any;
}

export function NewPeopleCategory({ searchTotalPeopleCategoriesRecordsAndPeopleCategories }: INewPeopleCategoryProps) {
  const Modal = useModal();
  const customFetch = useCustomFetch();
  const navigate = useNavigate();

  const description = useForm({ type: "text", required: true });

  const savingNewPeopleCategory = useLoading();

  const handleSubmitNewPeopleCategory = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isValid(description)) {
      try {
        savingNewPeopleCategory.setLoading(true);
        const json = (await customFetch("/orderPermissions/insertAndUpdatePeopleCategory", {
          body: {
            nome: description.value,
            status: "A",
          },
        })) as IHandleSubmitNewPeopleCategoryResponse;
        if (json.status === 200) {
          await Modal.success(json.message);
          searchTotalPeopleCategoriesRecordsAndPeopleCategories();
          navigate("/configuracoes/categorias-pessoas");
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        savingNewPeopleCategory.setLoading(false);
      }
    }
  };

  return (
    <div className="container">
      {!savingNewPeopleCategory.isLoading ? (
        <>
          <div>
            <LinkButton
              to="/configuracoes/categorias-pessoas"
              buttonStyle="backButton"
              children={null}
              variant={undefined}
              className={undefined}
              classNameContainer={undefined}
              disabled={undefined}
            />
          </div>
          <div className={styles.contentContainer}>
            <form onSubmit={handleSubmitNewPeopleCategory} className={styles.formContainer}>
              <div>
                <label htmlFor="description" className="label">
                  Descrição
                </label>
                <Input
                  id="description"
                  placeholder="Digite a descrição da categoria"
                  value={description.value}
                  error={description.error as unknown as string}
                  onChange={description.onChange}
                  onBlur={description.onBlur}
                />
              </div>
              <div className={styles.submitButtonContainer}>
                <Button>Salvar</Button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <span className="loadingMessage">Salvando Nova Categoria</span>
        </div>
      )}
    </div>
  );
}
