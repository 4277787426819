import React from "react";
import { Route, Routes } from "react-router-dom";
import { useSolicitationList } from "./hooks/useSolicitationList";
import { List } from "./List/List";
import { ISolicitation } from "./types";
import { Analysis } from "./Analysis/Analysis";

export function ContractsFinancialSolicitations() {
  const [selectedSolicitation, setSelectedSolicitation] = React.useState<ISolicitation | null>(null);

  const solicitationList = useSolicitationList();

  React.useEffect(() => {
    solicitationList.searchTotalSolicitationRecordsAndSolicitations();
  }, []); // eslint-disable-line

  return (
    <Routes>
      <Route
        path="/"
        element={<List solicitationList={solicitationList} setSelectedSolicitation={setSelectedSolicitation} />}
      />
      <Route
        path="analise"
        element={<Analysis selectedSolicitation={selectedSolicitation} solicitationList={solicitationList} />}
      />
    </Routes>
  );
}
