import React, { useEffect, useState } from "react";

import styles from "./Applications.module.css";
import { DotsNine } from "phosphor-react";

export default function Applications() {
  const applicationsRef = React.useRef<HTMLDivElement>(null);

  const [showApplications, setShowApplications] = useState(false);

  useEffect(() => {
    function handleOutsideClick(event: MouseEvent) {
      if (
        event.target instanceof HTMLElement &&
        !applicationsRef.current?.contains(event.target)
      ) {
        setShowApplications(false);
        window.removeEventListener("click", handleOutsideClick);
      }
    }

    if (showApplications) window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [showApplications]);

  return (
    <div className={styles.container} ref={applicationsRef}>
      <div
        className={styles.indicator}
        onClick={(event) => {
          event.stopPropagation();
          setShowApplications((current) => !current);
        }}
      >
        <DotsNine size={26} color="var(--gray-4)" />
      </div>
      <div
        className={`${styles.applicationsContainer} customScrollbar`}
        style={{ display: showApplications ? "block" : undefined }}
      >
        <iframe
          className={styles.frame}
          title="aplicativos"
          src="frame-aplicativos"
        />
      </div>
    </div>
  );
}
