import React from "react";

import { Routes, Route } from "react-router-dom";

import { usePagination } from "../../../hooks/pagination";
import { useCustomFetch, useLoading } from "../../../hooks/async";
import { useForm } from "../../../hooks/form";
import { useModal } from "../../../hooks/contexts";

import { MicroList } from "./MicroList/MicroList";
import { NewMicro } from "./NewMicro/NewMicro";
import { UpdateMicro } from "./UpdateMicro/UpdateMicro";
import { Costs } from "./Costs/Costs";

export function ProductMicros() {
  const pagination = usePagination();
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const searchedMicro = useForm({ type: "", required: false });

  const searchingMicros = useLoading();

  const [enterpriseOptions, setEnterpriseOptions] = React.useState([]);

  const [microList, setMicroList] = React.useState([]);
  const [selectedMicro, setSelectedMicro] = React.useState(null);

  const clearMicroFilter = React.useCallback(() => {
    searchedMicro.setValue("");
  }, [searchedMicro]);

  const searchEnterprises = React.useCallback(async () => {
    try {
      const json = await customFetch("/products/searchEnterprises", {
        body: {
          descricao: "",
        },
      });
      if (json.status === 200) {
        const options = json.object.map((option) => {
          return {
            value: option,
            label: `${option.idEmpresa} | ${option.nomeEmpresa}`,
          };
        });
        setEnterpriseOptions(options);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      }
    } catch (error) {
      Modal.error(error);
    }
  }, [Modal, customFetch]);

  const searchTotalMicroRecords = React.useCallback(
    (group = "") => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/products/searchTotalMicroRecords", {
            body: {
              tamanho: pagination.maxItems,
              descricao: group,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const searchMicros = React.useCallback(
    async (group = "", page) => {
      return new Promise(async (resolve, reject) => {
        try {
          const json = await customFetch("/products/searchMicrosPaginated", {
            body: {
              pagina: page ? Number(page) - 1 : 0,
              tamanho: pagination.maxItems,
              descricao: group,
            },
          });
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
    },
    [customFetch, pagination]
  );

  const researchMicros = React.useCallback(
    async (micro, page) => {
      try {
        searchingMicros.setLoading(true);
        const json = await searchMicros(micro, page);
        if (json.status === 200) {
          setMicroList(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingMicros.setLoading(false);
      }
    },
    [Modal, searchMicros, searchingMicros]
  );

  const searchTotalMicroRecordsAndMicros = React.useCallback(
    async (group = "") => {
      if (group === "") {
        clearMicroFilter();
      }
      try {
        searchingMicros.setLoading(true);
        const jsonGroups = await searchMicros(group);
        if (jsonGroups.status === 200) {
          const jsonRecords = await searchTotalMicroRecords(group);
          if (jsonRecords.status === 200) {
            setMicroList(jsonGroups.object);
            pagination.setTotalRecords(jsonRecords.object.total);
          } else if (jsonRecords.status === 500) {
            Modal.error(jsonRecords.message, jsonRecords.object);
          } else {
            setMicroList([]);
          }
        } else if (jsonGroups.status === 500) {
          Modal.error(jsonGroups.message, jsonGroups.object);
        } else {
          setMicroList([]);
          pagination.reset();
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        pagination.setCurrentPage(1);
        searchingMicros.setLoading(false);
      }
    },
    [Modal, clearMicroFilter, pagination, searchMicros, searchTotalMicroRecords, searchingMicros]
  );

  React.useEffect(() => {
    searchEnterprises();
  }, []); // eslint-disable-line

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <MicroList
              pagination={pagination}
              searchTotalMicroRecords={searchTotalMicroRecords}
              searchMicros={searchMicros}
              searchTotalMicroRecordsAndMicros={searchTotalMicroRecordsAndMicros}
              searchedMicro={searchedMicro}
              microList={{
                value: microList,
                setValue: setMicroList,
              }}
              setSelectedMicro={setSelectedMicro}
              searchingMicros={searchingMicros}
            />
          }
        />
        <Route
          path="novo"
          element={
            <NewMicro
              searchTotalMicroRecordsAndMicros={searchTotalMicroRecordsAndMicros}
              enterpriseOptions={enterpriseOptions}
            />
          }
        />
        <Route
          path="atualizar"
          element={
            <UpdateMicro
              researchMicros={(micro) => researchMicros(micro, pagination.currentPage)}
              enterpriseOptions={enterpriseOptions}
              selectedMicro={selectedMicro}
              filteredMicro={searchedMicro.value}
            />
          }
        />
        <Route path="custos/*" element={<Costs selectedMicro={selectedMicro} />} />
      </Routes>
    </div>
  );
}
