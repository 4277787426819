import React from "react";

import { Outlet, Navigate, useNavigate, useMatch } from "react-router-dom";
import { useAuth } from "../../hooks/contexts";

import styles from "./Auth.module.css";

export function Auth() {
  const Auth = useAuth();

  const isLoginPage = useMatch({ path: "/login", end: true });
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!Auth.signed && !isLoginPage) {
      navigate("/login");
    }
  });

  if (Auth.signed) return <Navigate to="/" />;

  return (
    <div className={styles.wrapper}>
      <Outlet />
    </div>
  );
}
