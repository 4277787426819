import React from "react";

declare global {
  interface IUsePagination {
    maxItems: number;
    setMaxItems: React.Dispatch<React.SetStateAction<number>>;
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    totalRecords: number;
    setTotalRecords: React.Dispatch<React.SetStateAction<number>>;
    totalPages: number;
    reset: () => void;
  }
}

const usePagination = (max?: number): IUsePagination => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [maxItems, setMaxItems] = React.useState(max || 10);

  const totalPages = React.useMemo(() => Math.ceil(totalRecords / maxItems), [maxItems, totalRecords]);

  const reset = React.useCallback(() => {
    setCurrentPage(1);
    setTotalRecords(0);
  }, []);

  return {
    maxItems: maxItems,
    setMaxItems,
    currentPage,
    setCurrentPage,
    totalRecords,
    setTotalRecords,
    totalPages,
    reset,
  };
};

export { usePagination };
